import React                               from "react";
import moment                              from "moment";
import Application                         from "@uBehaviour/application";
import T                                   from "@uBehaviour/i18n";
import Display                             from "@uComponents/displayIf";
import DragAndDrop                         from "@cBehaviour/dragAndDrop";
import Planning                            from "@cComponents/planning";
import Tooltip                             from "@cComponents/tooltip";
import Avatar                              from "@cFeatures/user/avatar";
import { MainHeader, SecondaryHeader }     from "./header";
import ICalUrlCopyButton                   from "./iCalUrlCopyButton";
import Item                                from "./item";
import { getPlanningClassname }            from "./lib";
import Selector                            from "./selector";
import { AbsenceTunnel, AssignmentTunnel } from "./tunnel";
import "./team.css";
import Acl from "@universal/behaviour/acl";
import useService from "@universal/behaviour/hooks/useService";
import classNames from "@uLib/classNames";

const Empty = Application.Service.forward(["currentTenant"], ({ currentTenant, date, team = null, agent = null, slim = false, short = false }) => {
  const absenceCreatable = agent && currentTenant.allowAbsenceCreation();
  const acl = useService("acl");
  const canCreate = acl.connectedUserIsAllow("absences", "manage") || acl.connectedUserIsAllow("assignments", "manage");

  const divEmptyClassName = React.useMemo(() => 
    classNames(getPlanningClassname(date, currentTenant))
      .add('bs-planning-empty')
      .addIf('bs-planning-empty-short', short)
      .addIf('bs-planning-empty-slim', slim)
      .addIf('bs-planning-empty-standard', !short && !slim)
      .addIf('bs-planning-empty-creatable', canCreate)
      .build()
  , [short, slim, date, currentTenant, canCreate]);

  if(!canCreate) {
    return (
      <div className={ divEmptyClassName } />
    );
  }

  //Tunnel vers web-admin/src/containers/planning/index.js
  let c = (
    <AssignmentTunnel.Emitter>
    {(emitAssignment) => (
      <DragAndDrop.Droppable allowedTypes={["Assignment", "AssignmentTeam", "AssignmentAgent", "AssignmentEquipment"]} datas={{ type: agent ? "Agent" : "Team", date, team, agent }}>
      {(isDragInProgress, isDropAllowed) => (
        <div 
          className={ classNames(divEmptyClassName).addIf('bs-planning-drop-allowed', isDragInProgress && isDropAllowed) }
          onClick={ absenceCreatable ? null : () => emitAssignment({ date, agent, team }) }
        >
          <span className="fa fa-plus" />
        </div>
      )}
      </DragAndDrop.Droppable>
    )}
    </AssignmentTunnel.Emitter>
  );


  if(absenceCreatable){
    //Tunnel vers web-admin/src/containers/planning/index.js
    const tooltipInfo = (
      <div className="bs-planning-empty-tooltipInfo">
        <Acl.If resource="assignments" action="manage">
          <AssignmentTunnel.Emitter>
          {(emitAssignment) => (
            <div onClick={ () => emitAssignment({ date, agent, team }) }>
              <span className="fa fa-plus" />
              <span><T>planning_team_empty_createAssignment</T></span>
            </div>
          )}
          </AssignmentTunnel.Emitter>
        </Acl.If>
        <Acl.If resource="absences" action="manage">
          <AbsenceTunnel.Emitter>
          {(emitAbsence) => (
            <div onClick={ () => emitAbsence({ date, agent, team }) }>
              <span className="fa fa-spinner" />
              <span><T>planning_team_empty_createAbsence</T></span>
            </div>
          )}
          </AbsenceTunnel.Emitter>
        </Acl.If>
      </div>
    );

    c = (
      <Tooltip placement="bottom" trigger="click" interactive>
        <Tooltip.Subject>
        { c }
        </Tooltip.Subject>
        <Tooltip.Info>
        { tooltipInfo }
        </Tooltip.Info>
      </Tooltip>
    );
  }

  return c;
});

const AgentHeader = ({ agent }) => (
  <SecondaryHeader icon={ agent ? <Avatar user={ agent } size={ 29 }/> : "user" }>
    <div className="bs-planning-team-agentHeader">{ agent ? agent.fullname : <T>planning_agentHeader_teamAssignment</T>}</div>
    { agent ? <ICalUrlCopyButton query={{ $or: [{ "assignment.team": agent.team._id }, { "assignment.agents": agent._id }]}} /> : null }
  </SecondaryHeader>
);

const AbsenceClickable = (Item) => {
  class AbsenceClickable extends React.Component {
    render() {
      const { start, end, absence, agent, currentTenant } = this.props;

      const item = (<Item start={ start } end={ end } absence={ absence } />);

      if(!currentTenant.allowAbsenceCreation()) {
        return item;
      }
      //Tunnel vers web-admin/src/containers/planning/index.js
      return (
        <AbsenceTunnel.Emitter>
        {(emit => (
          <div onClick={ () => emit({ agent, absence }) }>
            { item }
          </div>
        ))}
        </AbsenceTunnel.Emitter>
      );
    }
  }; 

  return Application.Service.forward(["currentTenant"], AbsenceClickable);
};

const AbsenceItem = AbsenceClickable(({ start, end, absence }) => {
  const morning   = moment(start).clone().startOf("day").add(12, "hour").diff(start) > 0;
  const afternoon = moment(start).clone().startOf("day").add(13, "hour").diff(end) < 0;
  return (
    <div className="bs-planning-team-absence">
      <span>
        { absence.nature ? absence.nature : <T>{ "planning_team_absence_" + absence.reason }</T> }
      </span>
      {
        !morning || !afternoon
        ? (
            afternoon
            ? (<><span>&nbsp;</span> <span><T>planning_team_absence_afternoon</T></span> <span>&nbsp;</span></>)
            : (<><span>&nbsp;</span> <span><T>planning_team_absence_morning</T></span> <span>&nbsp;</span></>)
        )
        : null
      }
      { absence.comment && (
        <span className="bs-planning-team-absence-comment">&nbsp;{`- ${absence.comment}`}</span> 
      )
      }
      
    </div>
  );
});
const AbsenceItemShort = AbsenceClickable(({ start, end, absence }) => (
  <div className="bs-planning-team-absence-short">&nbsp;</div>
));

const AgentPlanning = ({ team, start, end, agent, assignments, absences }) => {
  const sup7Days = moment(end).diff(start, 'days') > 7;
  return (
    <>
      <AgentHeader agent={ agent } />
      {
        absences.length
          ? (
              <Planning start={ start } end={ end } cellClassName="bs-team-planning-cell">
                { absences.reduce((acc, absence) => {
                  const start = moment(absence.start);
                  const end   = moment(absence.end);
                  while(start.isBefore(end)){
                    acc.push({
                      start:  start.toDate(),
                      end:    start.clone().add(1, "day").startOf("day").toDate(),
                      absence
                    });
                    start.add(1, "day").startOf("day");
                  }
                  acc[acc.length - 1].end = end.toDate();
                  return acc;
                }, []).filter(absence => absence.end > start && absence.start < end).map((absence, idx) => (
                    <Planning.Event
                      key={ idx }
                      start={ moment(absence.start).toDate() }
                      end={ moment(absence.end).toDate() }
                    >
                    { 
                      sup7Days
                        ? <AbsenceItemShort agent={ agent } start={ absence.start } end={ absence.end } absence={ absence.absence } />
                        : <AbsenceItem agent={ agent } start={ absence.start } end={ absence.end } absence={ absence.absence } />
                    }
                    </Planning.Event>
                ))}
                <Planning.Empty>
                  <Empty agent={agent} team={team} short/>
                </Planning.Empty>
              </Planning>
            )
          : null
      }
      <Planning start={ start } end={ end } cellClassName="bs-team-planning-cell" withEmptyLine>
        {
          assignments.map(assignment => (
            <Planning.Event
              key={ assignment._id }
              start={ moment(assignment.assignment.scheduledFrom).toDate() }
              end={ moment(assignment.assignment.scheduledTo).toDate() }
            >
              <Item.Slim assignment={ assignment } agent={ agent } team={ team }/>
            </Planning.Event>
          ))
        }
        <Planning.Empty>
          <Empty team={ team } agent={ agent } slim={ true }/>
        </Planning.Empty>
      </Planning>
    </>
  );
};

const TeamHeader = ({ team, onClick, displayAgent, agents, selectedAgents, setSelectedAgents }) => (
  <MainHeader icon="users">
    <div className="bs-planning-team-teamHeader">
      <div className="bs-planning-team-teamHeader-title">
        <span>{ team.name }</span>
        <Display.If condition={ team.members.length }>
          <span onClick={ onClick } className={`fa fa-caret-${ displayAgent ? "up" : "down" } bs-planning-team-header-button`} />
        </Display.If>
      </div>
      &nbsp;&nbsp;&nbsp;
      <Display.If condition={ displayAgent }>
        <Selector selected={ selectedAgents } onSelect={ setSelectedAgents } datas={ agents } dataToValue={ agent => agent._id } dataToLabel={ agent => agent.fullname }>
          <span><T>planning_team_selectAgents</T></span>
          <span>{ selectedAgents.length ? `${selectedAgents.length}/${agents.length}` : "" }</span>
        </Selector>
      </Display.If>
    </div>
    <ICalUrlCopyButton query={{ $or: [{ "assignment.team": team._id }, { "assignment.agents": { $in: agents.map(agent => agent._id) }}]}} />
  </MainHeader>
);

const TeamPlanning = ({ start, end, team, teamAssignments, agents, assignments }) => {
  const [displayAgent, setDisplayAgent] = React.useState(false);
  const openAgentView = React.useCallback(() => setDisplayAgent(true), [setDisplayAgent]);
  const closeAgentView = React.useCallback(() => setDisplayAgent(false), [setDisplayAgent]);

  const [selectedAgents, setSelectedAgents] = React.useState([]);
  const filterAgents = React.useCallback((a) => {
    if(!selectedAgents.length) return true;
    return selectedAgents.includes(a.agent._id);
  }, [selectedAgents]);

  const sup7Days = moment(end).diff(start, 'days') > 7;
  if(!displayAgent){
    return (
      <>
        <TeamHeader
          team={ team }
          onClick={ openAgentView }
          displayAgent={ displayAgent }
          agents={ agents.map(a => a.agent) }
          selectedAgents={ selectedAgents }
          setSelectedAgents={ setSelectedAgents }
        />
        <Planning start={ start } end={ end } cellClassName="bs-team-planning-cell" withEmptyLine>
          {
            assignments.map(assignment => (
              <Planning.Event 
                key={assignment._id}
                start={ moment(assignment.assignment.scheduledFrom).toDate() }
                end={ moment(assignment.assignment.scheduledTo).toDate() }
              >
              {
                sup7Days
                  ? <Item.Slim assignment={ assignment } team={ team }/>
                  : <Item.Standart assignment={ assignment } team={ team }/>
              }
              </Planning.Event>
            ))
          }
          <Planning.Empty>
            <Empty team={ team } slim={ sup7Days }/>
          </Planning.Empty>
        </Planning>
      </>
    );
  }
  
  return (
    <>
      <TeamHeader
        team={ team }
        onClick={ closeAgentView }
        displayAgent={ displayAgent }
        agents={ agents.map(a => a.agent) }
        selectedAgents={ selectedAgents }
        setSelectedAgents={ setSelectedAgents }
      />
      {
        agents.filter(filterAgents).concat([{ assignments: teamAssignments, absences: []}]).map(a => (
          <AgentPlanning
            key={ a.agent?._id || "unassigned"}
            start={ start }
            end={ end }
            team={ team }
            agent={ a.agent }
            assignments={ a.assignments }
            absences={ a.absences }
          />
        ))
      }
    </>
  )
};

export default TeamPlanning;