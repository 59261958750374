// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-selectable-wa{

}
.bs-selectable-wa>span{ 
  color: #b3b3b3;
  font-weight: 400;
}
.bs-selectable-wa-openable>span:hover{
  cursor: pointer;
  text-decoration: underline;
}
.bs-selectable-wa>span>span{
  font-size: 12px;
  color: white;
  background-color: #999;
  padding: 2px 4px;
  border-radius: 3px;
}
.bs-selectable-wa>span>span:nth-child(n + 2){
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/simpleSelectable.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;EACE,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,0BAA0B;AAC5B;AACA;EACE,eAAe;EACf,YAAY;EACZ,sBAAsB;EACtB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".bs-selectable-wa{\n\n}\n.bs-selectable-wa>span{ \n  color: #b3b3b3;\n  font-weight: 400;\n}\n.bs-selectable-wa-openable>span:hover{\n  cursor: pointer;\n  text-decoration: underline;\n}\n.bs-selectable-wa>span>span{\n  font-size: 12px;\n  color: white;\n  background-color: #999;\n  padding: 2px 4px;\n  border-radius: 3px;\n}\n.bs-selectable-wa>span>span:nth-child(n + 2){\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
