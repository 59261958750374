import _ from 'lodash'
import { goToIssue as goToIssueAction } from 'client/features/issues/actions'
import moment from 'moment'
import T from 'i18n-react'
import { cloneDeep } from 'lodash'
import queryCompatibilityIssue from "../../lib/filterCompatibilityIssue";
import queryCompatibilityAssignment from "../../lib/filterCompatibilityAssignment";
import application from '../../../app.services';

import { makeStorageUrl } from 'client/lib/file';

const __LANG    = window.__LANG;

class UrlBuilder{
  static create(url){
    return new UrlBuilder(url);
  }
  constructor(url){
    this._url = url;
    this._params = [];
  }
  addParams(params){
    this._params.push(params);
    return this;
  }
  build(){
    return `${this._url}${this._params.length ? "?" : ""}${this._params.join("&")}`;
  }
}

const ordinal = (n, lang) => { //TODO: intégrer dans bs-commin
  if (lang === 'nl')
    return (n === 1 || n === 8 || n >= 20) ? 'ste' : 'de'
  else if (lang === 'en') {
    const s = ['th', 'st', 'nd', 'rd']
    const v = n % 100
    return n + (s[(v - 20) % 10] || s[v] || s[0])
  }
  return n === 1 ? 'er' : 'ème'
}
export const getIssueFromAll = (all = [], issueId) => {
  if (!all || !all[0] || !issueId)
    return
  const issue = all.find((issue) => issue && issue._id === issueId)
  if (issue)
    return cloneDeep(issue)
}

export const getIssueFromAllByBsId = (all = [], bsId) => {
  if (!all || !all[0] || !bsId)
    return
  const issue = all.find((issue) => issue && issue.bsId === bsId)
  if (issue)
    return cloneDeep(issue)
}

export const isAuthorized = (issue, field, type = 'update') =>
  issue && issue.fields && issue.fields[field] && issue.fields[field].indexOf(type) !== -1

export const isSelectedInList = (issueId, issuesSelectedInList, mode) => {
  if (mode === 'selector')
    return issuesSelectedInList.includes(issueId)
  return !issuesSelectedInList.includes(issueId)
}

export const getIssueScheduledDates = (issue) => {
  const dates = []
  if (!issue || !issue.planification.scheduledFrom)
    return dates
  const fromDate = new Date(issue.planification.scheduledFrom).getTime()
  const toDate = new Date(issue.planification.scheduledTo).getTime()
  for (let i = fromDate; i <= toDate; i += 86400000) {
    dates.push(new Date(i))
  }
  return dates
}

export const makeDefaultValorization = (issue) => {
  const valorization = { elements: [], date: new Date() }

  if (issue.planification && issue.planification.users && issue.planification.users[0]) {
    issue.planification.users.forEach(user =>
      valorization.elements.push({
        kind: 'User',
        item: { ...cloneDeep(user), icon: 'user' },
        quantity: 0
      })
    )
  } else if (issue.planification && issue.planification.teams && issue.planification.teams[0]) {
    issue.planification.teams.forEach(team =>
      valorization.elements.push({
        kind: 'User',
        item: { ...cloneDeep(team), icon: 'users' },
        quantity: 0,
        isTeam: true
      })
    )
  }

  if (issue.planification && issue.planification.patrimonies && issue.planification.patrimonies[0]) {
    issue.planification.patrimonies.forEach(patrimony =>
      valorization.elements.push({
        kind: 'Patrimony',
        item: { ...cloneDeep(patrimony), icon: 'wrench' },
        quantity: 0
      })
    )
  }

  if (issue.planification.supplies) {
    issue.planification.supplies.forEach(supply => {
      valorization.elements.push({
        kind: 'Supply',
        item: { ...cloneDeep(supply.item), icon: 'cubes' },
        quantity: supply.quantity || 0
      })
    })
  }

  return valorization
}

export const formatIssueValorizations = (valorizations) =>
  valorizations.items.map(valorization => {
    valorization.elements = valorization.elements.map(element => {
      if (element.kind === 'Team') {
        element.isTeam = element.item.isTeam = true
        element.icon = 'users'
      }
      if (element.kind === 'UserPro' || element.kind === 'Team') {
        element.kind = 'User'
        element.icon = 'user'
      }
      if (element.kind === 'Patrimony') {
        element.icon = 'wrench'
      }
      if (element.kind === 'ExternalService') {
        element.icon = 'flag'
        element.label = element.name
      }
      if (element.item.valorization.type === 'h')
        element.quantity *= 4
      return element
    })
    return valorization
  })

export const getPriorityData = (priority) => {
  const result = {}
  if (priority === 'a_low')
    return result

  result.icon = 'warning'
  result.iconClass = `icon-priority-${priority}`
  result.iconColor = priority === 'j_middle' ? '#ffa500' : '#FF0000'

  return result
}

export const goToIssue = (issue, dispatch) => {
  dispatch(goToIssueAction({ issue }))
}

export const isExpiredDeadline = (issue) => {
  if (issue.state === 'resolved' || issue.state === 'transferred' || issue.state === 'rejected' || issue.state === 'deleted')
    return false

  if (issue.deadline) {
    const today = new Date().getTime()
    const deadline = new Date(issue.deadline).getTime()
    const diff = deadline - today
    if (diff > 0)
      return false
    return true
  }
  return false
}

export const formatSearchFilter = (item) => {
  const search = {}
  if (item.type === 'requestor') {
    search.requestor = {
      firstName: item.firstName,
      lastName: item.lastName,
      type: item.requestorType
    }
  } else if (item.type === 'user') {
    search.ids = item.ids;
    search.requestor = {
      firstName: item.firstName,
      lastName: item.lastName,
    }
    if (!item.firstName && !item.lastName && item.label)
      search.requestor = { firstName: item.label }
  } else if (item.value)
    search[item.type] = item.value

  return search
}

//Generate email templates used for preview in modals

export const emailTemplate = (template, issue, userPro = {}) => {
  if (userPro.admin)
    return {}
  const result = {}
  const templateData = {
    issue: { ...issue  },
    ressource: userPro.resource,
    lang: __LANG,
    userPro: { ...userPro, fullName: `${userPro.firstName} ${userPro.lastName}` },
    customSignature: getCustomSignature(userPro),
    issueUrl: `https://admin.betterstreet.org/issue/${issue.bsId}`,
    comment: ''
  }
  
  try {
    result.subject = _.template(template.subject)(templateData)
    
    result.fromName = _.template(template.fromName)(templateData)
    result.footer = _.template(template.footer)(templateData)
    result.body = _.template(template.body)(templateData)
  } catch (err) {

  }
  return result
}

export const issueIsInBounds = (issue, bounds) => {
  const lng = (issue.longitude - bounds._ne.lng) * (issue.longitude - bounds._sw.lng) < 0
  const lat = (issue.latitude - bounds._ne.lat) * (issue.latitude - bounds._sw.lat) < 0
  return lng && lat
}

export const setMapBoudaries = (map, user) => {
  if (user && user.resource && user.resource.boundariesGeoJSON) {
    window.google.maps.event.trigger(map, 'resize')
    map.data.setStyle((feature) => (
      {
        fillColor: feature.getProperty('fillColor'),
        strokeColor: feature.getProperty('strokeColor'),
        strokeOpacity: feature.getProperty('strokeOpacity'),
        strokeWeight: feature.getProperty('strokeWeight'),
        fillOpacity: feature.getProperty('fillOpacity'),
        stroke: feature.getProperty('stroke'),
        fill: feature.getProperty('fill')
      }
    ))
    map.data.addGeoJson(user.resource.boundariesGeoJSON)
  }
}

export const makeIssueGeoJSONFeature = (issue) => (issue ? {
  type: 'Feature',
  properties: {
    issueId: issue._id,
    icon: `marker_${issue.state.value}`
  },
  geometry: {
    type: 'Point',
    coordinates: [
      issue.longitude, issue.latitude
    ]
  }
} : null)

export const setMentionsHastags = (issue, hashtags, filter = false) => {
  if (!hashtags)
    return []
  const result = hashtags
    .map(hashtag => ({
      ...hashtag, display: `#${hashtag.label} `, id: hashtag._id
    }))

  if (filter && issue && issue.hashtags && issue.hashtags[0])
    return result.filter(hashtag => !issue.hashtags.find(issueHashtag => hashtag._id === issueHashtag._id))

  return result
}

export const stateEmailTemplate = (template, issue, user = {}, state, resource) => {
  if (user.admin)
    return {}
  const result = {}
  if (!resource)
    resource = user.entity || user.commune || {}
  issue = { ...issue, tag: issue.tag.label, state }
  const baseParams = {
    issue,
    __: T.texts,
    i18nCatalog: T.texts,
    lang: __LANG,
    receiverIsFollower: false,
    moment
  }


  /*Set display name (hack)*/
  if (!user.displayName || !user.displayName[__LANG]) {
    user.displayName = {}
    user.displayName[__LANG] = `${user.firstName} ${user.lastName}`
  }

  /*From*/
  result.fromName = _.template(template.fromName)({ ...baseParams, userPro: user })
  result.to = _.template(template.to)({ ...baseParams, receiver: {email:issue.requestor.email} })
  /*Subject*/
  const subjectTemplate = template.subject[issue.tag.key] || template.subject.other
  result.subject = _.template(subjectTemplate)({
    ...baseParams
  })
  /*Introduction*/
  const introTemplate = template.introduction[issue.tag.key] || template.introduction.other
  result.introduction = _.template(introTemplate)({
    ...baseParams
  })
  /*Body*/
  const bodyTemplate = template.body.replace('<p><strong><%= comment %></strong></p>', '')
  result.body = _.template(bodyTemplate)({
    ...baseParams,
    issueIntroduction: result.introduction
  })
  /*Footer*/
  let footerTemplate = user.entity ? template.footerEntity : template.footer
  const customSignature = getCustomSignature(user)

  if (user.admin)
    footerTemplate = template.footerAdmin

  if (!resource.descriptionLabel)
    resource.descriptionLabel = {}

  result.footer = _.template(footerTemplate)({
    ...baseParams,
    userPro: user,
    ressource: resource,
    customSignature
  })
  
  return result
}

const getCustomSignature = (user) => {
  if (user.resource && user.resource.texts && user.resource.texts.emailSignature && user.resource.texts.emailSignature[__LANG])
    return user.resource.texts.emailSignature[__LANG]
}

export const getRamdomLoaderText = () => {
  const texts = [
    'please_wait',
    'please_wait_1',
    'please_wait_2',
    'please_wait_3',
    'please_wait_4',
    'please_wait_5'
  ]

  return T.translate(_.sample(texts))
}

export const formDays = () => {
  const days = []
  for (let i = 1; i <= 7; i++) {
    const date = new Date(2007, 0, i)
    const day = {
      label: moment(date).format('dddd'),
      value: i
    }
    days.push(day)
  }
  return days
}

export const formMonths = () => {
  const months = []
  for (let i = 0; i < 12; i++) {
    const date = new Date(2015, i, 1)
    const month = {
      label: moment(date).format('MMM'),
      _id: i
    }
    months.push(month)
  }
  return months
}

export const fourOrdinals = (n = 4) => {
  const result = []
  for (let i = 1; i <= n; i++) {
    const data = {
      label: `${i} ${ordinal(i, __LANG)}`,
      _id: i
    }
    result.push(data)
  }
  return result
}

export const formatUserNameAndEmail = (user) => `${user.firstName} ${user.lastName} - ${user.email}`

export const initialFormState = (type = null, initialValues) => {
  return {
    ...initialValues,
    type,
    requestor: {
      type: 'userPro'
    },
    recurrence: {
      createInterval: 1,
      haveRecurrence: false,
      endType: 'none',
      repeat: {
        recurrence: 1,
        day: 1,
        dayDate: 1,
        month: 0,
        monthDate: 1,
        dayNumber: 1,
        monthDay: 1
      }
    }
  }
}

export const setPatrimoniesValues = (issue) => {
  if (!issue)
    return null
  if (issue.planification && issue.planification.patrimonies && issue.planification.patrimonies[0]) {
    return _.sortBy(issue.planification.patrimonies.map((patrimony) => ({ label: patrimony.name, icon: 'wrench', value: patrimony._id })), 'label')
  }
}

export const setPatrimoniesValue = (issue) => {
  if (!issue)
    return null
  if (issue.planification && issue.planification.patrimonies && issue.planification.patrimonies[0]) {
    return {
      value: issue.planification.patrimonies.map((patrimony) => ({ ...patrimony, value: patrimony._id })),
      label: issue.planification.patrimonies.filter((patrimony) => patrimony._id).map((patrimony) => patrimony.name).join(', ')
    }
  }
}

export const setPlanningValue = (issue) => {
  if (!issue)
    return null
  if (issue.planification && issue.planification.teams && issue.planification.teams[0] && issue.planification.teams[0]._id) {
    return {
      value: { value: issue.planification.teams[0]._id },
      label: issue.planification.teams[0].name
    }
  }
  if (issue.planification && issue.planification.users && issue.planification.users[0]) {
    return {
      value: issue.planification.users.map((user) => ({ ...user, value: user._id })),
      label: issue.planification.users.filter((user) => user._id).map((user) => `${user.firstName ? user.firstName[0] + '.' : ''}${user.lastName}`).join(', ')
    }
  }
  return null
  //return { value: 'none', label: T.translate('unselect') }
}

export const setPlanningValues = issue => {
  if (!issue || !issue.planification)
    return
  if (issue.planification.users && issue.planification.users[0])
    return _.sortBy(issue.planification.users.map((user) => ({ label: `${user.firstName} ${user.lastName}`, icon: 'user' })), 'label')
  else if (issue.planification.teams && issue.planification.teams[0])
    return _.sortBy(issue.planification.teams.map((team) => ({ label: team.name, icon: 'users' })), 'label')
}

/*Prints*/

export const printList = (options = { type : "issues" }, window) => {
  const querycompatibilityMethod = options.type === "issues" ? queryCompatibilityIssue : queryCompatibilityAssignment;
  let query = options.filters ? querycompatibilityMethod(options.filters) : {};
  let url = UrlBuilder.create(`/print/${options.type}`);
  if (options.oneByPage) {
    url.addParams('oneByPage=true')
  }    
  if (options.selected) {
    if (options.selected.mode === 'all'){
      query[options.type === "assignments" ? "issue" : "_id"] = { $nin: options.selected.issues };
    }else{
      query[options.type === "assignments" ? "issue" : "_id"] = { $in: options.selected.issues };
    }
  }
  if(application.getService('currentTenant').isSelected()){
    url.addParams("tenant=" + application.getService('currentTenant').currentId);
    query.tenant = application.getService('currentTenant').currentId
  }

  url.addParams(`query=${JSON.stringify(query)}`);
  window.open(url.build(), 'EditEvents', 'scrollbars=1,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=yes,width=1024,height=800')
}

export const printIssueWorkOrder = (issue, withPdf, oneByPage) => {
  application.getHelper("print").workOrder({ issue: issue._id ? issue._id : issue }, {}, oneByPage, 50, withPdf);
}

export const getFilterByValue = (field, value) => {
  if (!field || !value)
    return {}
  let attributes = field.customAttributes || field.attributes
  if (field.subs) {
    field.subs.forEach(sub => attributes = attributes.concat(sub.attributes))
  }
  return attributes.find(attribute => attribute.value === value) || {}
}

export const clearFilters = (filters) =>
  Object.keys(filters).reduce((previous, key) => {
    if (filters[key] && ((_.isArray(filters[key]) && filters[key][0]) || _.isNumber(filters[key]) || (_.isString(filters[key]) && filters[key][0] !== 'custom')))
      previous[key] = filters[key]
    return previous
  }, {})

export const presetMatchFilters = (presetValues, filtersValues) => {
  let match = true
  presetValues = clearFilters(presetValues)
  filtersValues = clearFilters(filtersValues)
  if (!_.isEqual(Object.keys(presetValues).sort(), Object.keys(filtersValues).sort()))
    return false

  for (const key in presetValues) {
    const presetValue = presetValues[key]
    const filtersValue = filtersValues[key]
    if (_.isArray(presetValue) && _.isArray(filtersValue)) {
      if (filtersValue && !_.isEqual(presetValue.sort(), filtersValue.sort()))
        match = false
    }

  }
  return match
}

export const printIssue = (issue, window) => {
  let url = UrlBuilder.create(`/print/issue/${issue._id ? issue._id : issue}`)

  if(application.getService('currentTenant').isSelected()){
    url.addParams("tenant=" + application.getService('currentTenant').currentId);
  }
  
  window.open(url.build(), 'EditEvents', 'scrollbars=1,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=yes,width=1024,height=800')
}

export const printIssuePicture = (picture, window) => {  
  const popup = window.open("", 'EditEvents', 'scrollbars=1,directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,resizable=yes,width=1024,height=800')
  popup.document.body.innerHTML = `<iframe style="height: 100%; width: 100%" src="${makeStorageUrl(picture)}" />`
  popup.setTimeout(() => popup.print(), 1000);
}