import React from "react";
import Slot  from "@uComponents/slot";
import Display from"@uComponents/displayIf";

import "./contractable.css";

class Contractable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      isContracted: !!this.props.defaultContracted
    };
  }
  contract = () => {
    this.setState({ isContracted: true });
  }
  relax = () => {
    this.setState({ isContracted: false });
  }
  render(){
    const {
      className: { contracted: contractedClassName, relaxed: relaxedClassName },
      hide
    } = this.props;

    const contracted  = Contractable.Contracted.get(this);
    const relaxed     = Contractable.Relaxed.get(this);

    return hide
      ? <>
          <div className={ `bs-contractable-contracted bs-contractable-${this.state.isContracted ? "display" : "hidden" } ${contractedClassName}` }>
            { contracted(this.relax) }
          </div>
          <div className={ `bs-contractable-contracted bs-contractable-${this.state.isContracted ? "hidden" : "display" } ${relaxedClassName}` }>
            { relaxed(this.contract) }
          </div>
        </>
      : (
        <Display.If condition={ this.state.isContracted }>
          <Display.Then>
            <div className={ contractedClassName }>
            { contracted(this.relax) }
            </div>
          </Display.Then>
          <Display.Else>
            <div className={ relaxedClassName }>
            { relaxed(this.contract) }
            </div>
          </Display.Else>
        </Display.If>
      );
  }
}

Contractable.Contracted = Slot();
Contractable.Relaxed    = Slot();

export default Contractable;