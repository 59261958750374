// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-searchbar {
  width: 100%;
}
.bs-searchbar .bs-input-textBase{
  background: white;
}
.bs-searchbar .bs-input-textBase:focus{
  border-color: rgba(82, 168, 236, 0.8);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);
}`, "",{"version":3,"sources":["webpack://./../common/components/searchbar.css"],"names":[],"mappings":";AACA;EACE,WAAW;AACb;AACA;EACE,iBAAiB;AACnB;AACA;EACE,qCAAqC;EACrC,iFAAiF;AACnF","sourcesContent":["\n.bs-searchbar {\n  width: 100%;\n}\n.bs-searchbar .bs-input-textBase{\n  background: white;\n}\n.bs-searchbar .bs-input-textBase:focus{\n  border-color: rgba(82, 168, 236, 0.8);\n  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(82, 168, 236, 0.6);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
