import React, { FunctionComponent, PropsWithChildren } from 'react';

import './floatingBox.css';

interface FloatingBoxProps {

}

const FloatingBox: FunctionComponent<PropsWithChildren<FloatingBoxProps>> = ({ children }) => (
  <div className='bs-floatingBox'>
    <div className='bs-floatingBox-arrow' />
    { children }
  </div>
);

export default FloatingBox;