import React, { FunctionComponent } from "react";
export {
  default as TabList,
  Header,
  Content,
  Tab
} from './list';

export {
  default as TabRenderer
} from './renderer/default';

import TabListToExtend, {
  Header,
  Content,
  Tab
} from './list';
import Renderer from './renderer/default';
import { TabListProps } from "./type";

const TabList: FunctionComponent<Omit<TabListProps, 'Renderer'>> & {
  Header: typeof Header,
  Content: typeof Content,
  Tab: typeof Tab
} = (props) => {
  return <TabListToExtend {...props} Renderer={ Renderer } />;
};

TabList.Header = Header;
TabList.Content = Content;
TabList.Tab = Tab;

export default TabList;