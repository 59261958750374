import React, { FunctionComponent, PropsWithChildren, ReactNode } from "react";

import Slot from "@uComponents/slot2";
import Scrollbar from "@cComponents/scrollBar";



import "./standart.css";

export const Header = Slot();
export const Content = Slot<ReactNode, { withScrollBar?: boolean }>();
export const Footer = Slot();
export const ActionLeft = Slot();
export const ActionRight = Slot();

type TemplateSlot = {
  Header: typeof Header;
  Content: typeof Content;
  Footer: typeof Footer;
  ActionLeft: typeof ActionLeft;
  ActionRight: typeof ActionRight;
};

const Template: FunctionComponent<PropsWithChildren> & TemplateSlot = ({ children }) => {
  const header = Header.get(children);
  const content = Content.props(children, false, { withScrollBar: false, children: null });
  const footer = Footer.get(children);
  const actionLeft = ActionLeft.get(children);
  const actionRight = ActionRight.get(children);

  const action = React.useMemo(() => {
    if(!actionLeft && !actionRight) {
      return null;
    }
    return (
      <div className="bs-template-modal-standart-action">
      { actionLeft && (
        <div className="bs-template-modal-standart-action-left">
        { actionLeft }
        </div>
      )}
      { actionRight && (
        <div className="bs-template-modal-standart-action-right">
        { actionRight }
        </div>
      )}
      </div>
    );
  }, [actionLeft, actionRight]);

  return (
    <div className="bs-template-modal-standart">
      { header && (
        <div className="bs-template-modal-standart-header">
        { header }
        </div>
      )}
      <div className="bs-template-modal-standart-content">
        { content.withScrollBar ? (
          <Scrollbar>
          { content.children }
          </Scrollbar>
        ) : content.children }
      </div>
      { footer && (
        <div className="bs-template-modal-standart-footer">
        { footer }
        </div>
      )}
      { action }
    </div>
  );
}

Template.Header = Header,
Template.Content = Content,
Template.Footer = Footer;
Template.ActionLeft = ActionLeft;
Template.ActionRight = ActionRight;

export default Template;