import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import T from "@uBehaviour/i18n";
import Slot from "@uComponents/slot2";
import Button from '@cComponents/button';

import './subscriptionOffers.css';
import useOpenCloseToggle from '@universal/behaviour/hooks/useOpenCloseToggle';
import Modal from '@common/components/modal';
import HSForm from '@common/components/HSForm';
import ScrollBar from '@common/components/scrollBar/scrollBar';

type SubscriptionOfferProps = {
  iconName: string;
  unlimited?: boolean;
}

const Title = Slot<ReactNode>();
const Action = Slot<ReactNode>();
const CTA = Button.withStyle(Button.Stylized.BigOrange);

const SubscriptionOffer: FunctionComponent<PropsWithChildren<SubscriptionOfferProps>> & { Title: typeof Title, Action: typeof Action } = ({ 
  iconName, 
  unlimited,
  children 
}) => {
  const title = Title.get(children);
  const action = Action.get(children);

  const nbrProfilesTranslation = React.useMemo(() => {
    return unlimited 
      ? <T>starter_subscription_offer_subTitle_unlimited</T>
      : <T>starter_subscription_offer_subTitle_one_profile</T>
  }, [ unlimited ]);

  return (
    <div className='bs-starter-subscriptionOffer-container'>
      <div className="bs-starter-subscriptionOffer-header">
        <div>
          <i className={`fa fa-${iconName} bs-starter-subscriptionOffer-icon-orange`} />
          { title }
        </div>
        <div className="bs-starter-subscriptionOffer-subTitle-container">
          <i className="fa fa-user" />
          <div><T bind={{ profiles: nbrProfilesTranslation }}>starter_subscription_offer_subTitle</T></div>
        </div>
        <div className="bs-starter-subscriptionOffer-lineBorder" />
      </div>
      <div className='bs-starter-subscriptionOffer-options-container'>
        { children }
      </div>
      { action }
    </div>
  )
}

SubscriptionOffer.Title = Title;
SubscriptionOffer.Action = Action;

const SubscriptionOfferOption: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className='bs-starter-subscriptionOffer-option-container'>
    <i className="fa fa-check" />
    { children }
  </div>
);

const SubscriptionOfferOptionGroup: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className='bs-starter-subscriptionOffer-option-container bs-starter-subscriptionOffer-option-group-container'>
    { children }
    <i className="fa fa-check" />
  </div>
);

const StarIcon: FunctionComponent = () => (
  <i className={`bs-starter-subscriptionOffer-icon-orange fa fa-star-o`} />
);

type AskForDemoButtonProps = {
  onClick: () => void;
}

const AskForDemoButton: FunctionComponent<AskForDemoButtonProps> = ({ onClick }) => (
  <CTA onClick={ onClick }>
    <T>starter_subscription_offer_ask_demo</T>
    <i className="fa fa-arrow-right bs-starter-subscriptionOffer-demo-btn-arrow-right" />
  </CTA>
)

const SubscriptionOffers: FunctionComponent = () => {
  const [displayAskDemoModal, openAskDemoModal, closeAskDemoModal] = useOpenCloseToggle();

  return (
    <>
      <div className="bs-starter-subscriptionsOffer-container">
        <SubscriptionOffer iconName="bicycle">
          <SubscriptionOffer.Title>
            <div>
              <div className="bs-starter-subscriptionOffer-title"><T>starter_subscription_offer_title_starter</T></div>
              <div><T>starter_subscription_offer_subTitle_starter</T></div>
            </div>
          </SubscriptionOffer.Title>
          {/* 5 options starter */}
          {Array.from({ length: 5 }).map((key, idx) => (
            <SubscriptionOfferOption><T>{`starter_subscription_offer_starter_option_${ idx + 1 }`}</T></SubscriptionOfferOption>
          ))}
        </SubscriptionOffer>
        <SubscriptionOffer iconName="car" unlimited>
          <SubscriptionOffer.Title>
            <div>
              <div className="bs-starter-subscriptionOffer-title"><T>starter_subscription_offer_title_standard</T></div>
              <StarIcon />
            </div>
          </SubscriptionOffer.Title>
          {/* Option starter + 13 options standard */}
          <SubscriptionOfferOptionGroup><T>starter_subscription_offer_standard_option_starter_features</T></SubscriptionOfferOptionGroup>
          {Array.from({ length: 13 }).map((key, idx) => (
            <SubscriptionOfferOption><T>{`starter_subscription_offer_standard_option_${ idx + 1 }`}</T></SubscriptionOfferOption>
          ))}
          <SubscriptionOffer.Action>
            <AskForDemoButton onClick={ openAskDemoModal } />
          </SubscriptionOffer.Action>
        </SubscriptionOffer>
        <SubscriptionOffer iconName="rocket" unlimited>
          <SubscriptionOffer.Title>
            <div>
              <div className="bs-starter-subscriptionOffer-title"><T>starter_subscription_offer_title_expert</T></div>
              <div className="bs-starter-subscriptionOffer-expert-stars-container">
                <StarIcon />
                <StarIcon />
                <StarIcon />
              </div>
            </div>
          </SubscriptionOffer.Title>
          {/* Options standard + 6 options expert */}
          <SubscriptionOfferOptionGroup><T>starter_subscription_offer_expert_option_standard_features</T></SubscriptionOfferOptionGroup>
          {Array.from({ length: 6 }).map((key, idx) => (
            <SubscriptionOfferOption><T>{`starter_subscription_offer_expert_option_${ idx + 1 }`}</T></SubscriptionOfferOption>
          ))}
          <SubscriptionOffer.Action>
            <AskForDemoButton onClick={ openAskDemoModal } />
          </SubscriptionOffer.Action>
        </SubscriptionOffer>
      </div>
      {displayAskDemoModal && (
        <Modal.Show close={ closeAskDemoModal } style={{ width: "60vw", height: "70vh" }}>
          <ScrollBar>
            <HSForm id="921782d5-b6a9-4420-b646-41f852f2076f" />
          </ScrollBar>
        </Modal.Show>
      )}
    </>
  );
}

export default SubscriptionOffers;
