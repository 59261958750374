import { fork, takeLatest, put, select, call, takeEvery , delay} from 'redux-saga/effects'
import * as Actions from 'client/features/calendar/actions'
import { getIssueFromAll } from 'client/features/issues/lib'
import * as IssuesActions from 'client/features/issues/actions'
import {
  CurrentUserRequest
} from 'client/features/session/actions'
import RequestSagas from 'client/core/RequestSagas'
import moment from 'moment'
import _, { assign } from 'lodash'
import Timeline from "./timeline";




/*Fetch issues*/
export const getIssuesSagas = new RequestSagas(Actions.IssuesRequest.get, { method: 'GET', endpoint: "/calendar/assignments" })
getIssuesSagas.success = function* (action) {
}
getIssuesSagas.failure = function* () {

}

/*Build period watchers */
const setPeriod = function * () {
  yield put(Actions.setPeriod())
}
export const setPeriodSaga = function * () {
  yield takeLatest([
    Actions.IssuesRequest.get.TYPES.SUCCESS,
    Actions.CALENDAR_SET_MODE,
    Actions.CALENDAR_NAV_DATE,
    Actions.CALENDAR_UPDATE_DAYS
  ], setPeriod)
}

export const checkFetchSaga = function * () {
  yield takeLatest([
    Actions.CALENDAR_NAV_DATE,
    Actions.CALENDAR_SET_DATE,
    Actions.CALENDAR_SET_MODE,
    Actions.INIT_CALENDAR
  ], checkFetch)
}

const checkFetch = function * (action){
    yield delay(500);
    const state             = yield select((state) => state)
    const calendar          = state.calendar;
    const currentTimeline   = calendar.timeline ? Object.setPrototypeOf(calendar.timeline, Timeline.prototype) : new Timeline();
    const requiredTimeline  = new Timeline([{ start: calendar.dates.start, end: calendar.dates.end }]);
    let periods             = requiredTimeline.exclude(currentTimeline).toPeriods();
    if(periods.length > 1){
      periods = [{
        start: periods[0].start,
        end: periods[periods.length - 1].end
      }];
    }
    if(periods.length === 1){
      yield put(Actions.IssuesRequest.get.action({
        payload: { 
          start: moment(periods[0].start).toISOString(),
          end: moment(periods[0].end).toISOString()
        }
      }))
      yield put({
        type: Actions.UPDATE_TIMELINE,
        timeline: currentTimeline.add(new Timeline(periods))
      });
    }
}

const drop = function * (action) {
  const issueId     = action.payload.drag.issue;
  let assignmentId  = action.payload.drag.assignment;
  let confirmationAbsenceUser = false;
  if(action.payload.drop.isUser && !action.payload.drop.remove){
    const absences  = new Timeline(action.payload.drop.assign.leaves);
    const planned   = new Timeline([{ start: action.payload.drop.day.date, end: moment(action.payload.drop.day.date).add(1, "day").toDate() }]);
    const insterserction = planned.intersect(absences);
    if(insterserction.toPeriods().length){
      confirmationAbsenceUser = true;
    }
  }

  const state = yield select((state) => state)
  const issue = getIssueFromAll(state.issues.all, issueId);
  const mode  = state.calendar.mode;
  
  let assignment = null;
  if(!assignmentId){
    if(mode !== "add" && !action.payload.drop.remove && issue.assignments.length){
      assignmentId = issue.assignments[issue.assignments.length - 1]._id;
      assignment = JSON.parse(JSON.stringify(issue.assignments[issue.assignments.length - 1]));
    }
  } else {
    assignment = JSON.parse(JSON.stringify(state.calendar.assignments.find(a => a._id === assignmentId).assignment));
  }
  if(assignment){
    delete assignment._id;
    delete assignment.progress;
  }

  let nextAction = null;  
  if(action.payload.drop.remove){
    if(action.payload.drag.assign.firstName){
      assignment.agents = assignment.agents.filter(a => a !== action.payload.drag.assign._id);
    }else if(action.payload.drag.assign.isPatrimony){
      assignment.necessariesEquipments = assignment.necessariesEquipments.filter(e => action.payload.drag.assign._id);
    } else {
      assignment.team   = [];
    }
    if(!assignment.agents.length && !assignment.team.length && !assignment.necessariesEquipments.length && !assignment.necessariesSupplies.length){
      yield put({ 
        type: "calendarDeleteAssignment",
        issueId,
        assignmentId
      });
      return;
    }else{
      nextAction = {
        type: "calendarUpdateAssignment",
        issueId,
        assignmentId,
        assignment
      };
    }
  }else if(!assignmentId){
    assignment  = {
      scheduledFrom: moment(action.payload.drop.day.start).toISOString(),
      scheduledTo: moment(action.payload.drop.day.end).toISOString(),
    }
    if(action.payload.drop.isUser){
      assignment.agents   = [action.payload.drop.assign._id];
    }else if(action.payload.drop.isPatrimony){
      assignment.necessariesEquipments = [action.payload.drop.assign._id]
    } else {
      assignment.team = [action.payload.drop.assign._id];
    }
    nextAction = {
      type: "calendarCreateAssignment",
      issueId,
      assignment
    };
  }else{    
    assignment.scheduledFrom  = moment(action.payload.drop.day.start).toISOString();
    assignment.scheduledTo    = moment(action.payload.drop.day.end).toISOString();
    
    if(action.payload.drop.assign.firstName && action.payload.drop.assign._id !== "none"){
      assignment.team   = [];
      assignment.agents = [action.payload.drop.assign._id]; //_.uniq(assignment.agents.concat([action.payload.drop.assign._id]));
    }else if(action.payload.drop.isPatrimony){
      assignment.necessariesEquipments = _.uniq(assignment.necessariesEquipments.concat([action.payload.drop.assign._id]));
    } else{
      const id = action.payload.drop.assign._id !== "none" ? action.payload.drop.assign._id : action.payload.drop.assign.team;
      assignment.team   = _.uniq([id]);
      assignment.agents = [];
    }
    nextAction = {
      type: "calendarUpdateAssignment",
      issueId,
      assignmentId,
      assignment
    };
  }

  if(confirmationAbsenceUser){
    yield put({ type: "ISSUE_PLANNING_ABSENCE_CONFIRMATION_DISPLAY", user: action.payload.drop.assign, nextAction: nextAction });
  } else {
    yield put(nextAction);
  }
}

const validateDrop = function * (action) {
  const { updater, data, params } = action;

  yield put({ type: IssuesActions.UPDATE, updater: updater });
  return yield put(IssuesActions.IssueRequest.put.action({
    payload: data,
    params
  }))
}

const validateDropSaga = function * (){
  yield takeLatest([
    "ISSUE_PLANNING_VALIDATE_DROP"
  ], validateDrop);
}

/*Last dropped*/
const lastDropped = function * () {
  yield delay(2000)
  yield put(Actions.removeLastDropped())
}
export const lastDroppedSaga = function * () {
  yield takeLatest([
    Actions.CALENDAR_UPDATE_DAYS
  ], lastDropped)
}

export const dropSaga = function * () {
  yield takeEvery([
    Actions.CALENDAR_DROP
  ], drop)
}

/*Filters*/
const saveFilter = function * () {
  const filters = yield yield select((state) => state.calendar.filters.values)
  yield put(CurrentUserRequest.put.action({ payload: { filters, type: 'calendarFilters' } }))
}

export const setFiltersSagas = function * () {
  yield takeLatest([
    Actions.SET_CALENDAR_FILTERS_VALUE,
    Actions.UNSET_CALENDAR_FILTER
  ], saveFilter)
}

/*Export watchers sagas*/
const sagas = [
  fork(getIssuesSagas.saga),
  fork(setPeriodSaga),
  fork(checkFetchSaga),
  fork(dropSaga),
  fork(validateDropSaga),
  fork(lastDroppedSaga),
  fork(setFiltersSagas),
  // fork(reloadDaysSaga)
]

export default sagas