// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-selectable-list{
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}
.bs-selectable-list-filter {
  width: 23.08%;
  flex-shrink: auto 0 0;
  background: #786577;
  padding: 20px;
  color: white;
}

.bs-selectable-list-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.bs-selectable-list-content {
  flex-grow: 1;
  overflow: hidden;
}

.bs-selectable-item{
  cursor: pointer;
  padding: var(--std-size-inter-element);
}

.bs-selectable-item-selected > * {
  background-color: rgba(232, 75, 36, 0.219) !important;
}

.bs-selectable-list-search-container {
  padding: 10px;
  border-bottom: 1px solid #dddddd;
}`, "",{"version":3,"sources":["webpack://./../common/components/selectableList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,WAAW;AACb;AACA;EACE,aAAa;EACb,qBAAqB;EACrB,mBAAmB;EACnB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,qDAAqD;AACvD;;AAEA;EACE,aAAa;EACb,gCAAgC;AAClC","sourcesContent":[".bs-selectable-list{\n  display: flex;\n  flex-direction: row;\n  height: 100%;\n  width: 100%;\n}\n.bs-selectable-list-filter {\n  width: 23.08%;\n  flex-shrink: auto 0 0;\n  background: #786577;\n  padding: 20px;\n  color: white;\n}\n\n.bs-selectable-list-body {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.bs-selectable-list-content {\n  flex-grow: 1;\n  overflow: hidden;\n}\n\n.bs-selectable-item{\n  cursor: pointer;\n  padding: var(--std-size-inter-element);\n}\n\n.bs-selectable-item-selected > * {\n  background-color: rgba(232, 75, 36, 0.219) !important;\n}\n\n.bs-selectable-list-search-container {\n  padding: 10px;\n  border-bottom: 1px solid #dddddd;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
