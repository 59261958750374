import React, { PureComponent } from 'react'
import T from 'i18n-react'
import FontAwesome from 'react-fontawesome'
import PicturesModal from 'client/features/issues/components/modals/Pictures.jsx'
import RemovePDFModal from 'client/features/issues/components/modals/RemovePDF.jsx'
import Confirmation from 'client/features/common/components/Confirmation.jsx'
import classNames from 'classnames'
import File     from 'client/components/file.jsx';

import noPhoto from "client/assets/img/no-photo.svg"

//TODO: USE overlay-view/components/Medias

class IssueMedias extends PureComponent {

  constructor() {
    super()
    this.displayModal = this.displayModal.bind(this)
    this.removePDF = this.removePDF.bind(this)
    this.displayRemovePDFModal = this.displayRemovePDFModal.bind(this)
    this.state = {
      showModal: false,
      showRemovePDFModal: false,
      currentPicture: null,
      currentPDF: null
    }
  }

  displayModal(currentPicture) {
    if (this.props.noCover)
      return
    this.setState({
      currentPicture,
      showModal: !this.state.showModal
    })
  }

  displayRemovePDFModal(currentPDF) {
    this.setState({
      currentPDF,
      showRemovePDFModal: !this.state.showRemovePDFModal
    })
  }

  removePDF(pdf) {
    this.props.removePDF(pdf)
    this.setState({
      currentPDF: null,
      showRemovePDFModal: false
    })
  }

  render() {
    const {
      updatePicture,
      confirmation,
      errors = {},
      cover,
      noCover,
      pictures = [],
      pdfs = [],
      tag,
      bsId,
      issueId
    } = this.props

    return (
      <div className='c-issue-detail-media u-margin-bottom'>
        {
          this.state.showModal &&
          <PicturesModal
            noCover={ noCover }
            pictures={ pictures }
            tag={ tag }
            bsId={ bsId }
            show={ this.state.showModal }
            onHide={ this.displayModal }
            currentPicture={ this.state.currentPicture }
            updatePicture={ updatePicture }
            issueId={ issueId }
          />
        }
        {
          this.state.showRemovePDFModal &&
          <RemovePDFModal
            show={ this.state.showRemovePDFModal }
            onHide={ this.displayRemovePDFModal }
            currentPDF={ this.state.currentPDF }
            removePDF={ this.removePDF }
          />
        }
        <figure className='droppable cover-picture'>


          <div className={ classNames('c-issue-detail-media__cover', { 'u-hide-for-print': noCover }) } onClick={ this.displayModal.bind(null, cover) }>
            {noCover 
              ? <img src={ noPhoto } />
              : <File 
                  file={ cover }
                  width={cover && cover.height > cover.width ? 320 : 1280}
                  height={cover && cover.height > cover.width ? 320 : 1024}
                  fit={cover && cover.height > cover.width ? "cover" : "contain"}
                  format="png"
                /> 
            }
          </div>


          <div id='issue-thumbnails' className='row-fluid issue-pictures'>
            {
              pictures && pictures.length > 1 && pictures.slice(1).map((picture, index) =>
                <div className='picture-preview' key={ index } onClick={ this.displayModal.bind(null, picture) }>
                  <div className="img-polaroid">
                    <File file={ picture } width={320} height={320} fit={"cover"} />
                  </div>                  
                </div>
              )
            }
            <Confirmation
              data={ confirmation }
              size='2x'
            />
            {
              errors.files ? <p className='error'>{ T.translate('form_validation_accepted_validimage') }</p> : null
            }
          </div>
        </figure>
        <div className='field'>
          <div id='pdf-previews' className='row-fluid'>
            {
              pdfs.map((pdf) =>
                <div className='pdf-preview' key={ pdf._id }>
                  <div className='relative'>
                    <div>
                      <a className='remove-cross' onClick={ this.displayRemovePDFModal.bind(null, pdf) }>
                        <FontAwesome name='times-circle' />
                      </a>
                      <div className="img-polaroid">
                        <File file={ pdf } openable={true} format="png" width={320} height={320} fit={"cover"} />
                      </div>                      
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
        <div className='u-text-align-right u-margin-top-small u-hide-for-print'>
          <a
            className='c-action-link c-action-link--empty'
            onClick={ this.props.showDropOverlay && this.props.showDropOverlay.bind(null, true, true) }
          >
            <span className='c-action-link__value'>
              { T.translate('add_pictures') }
            </span>
          </a>
        </div>
      </div>
    )
  }
}

export default IssueMedias