import React        from "react";
import PropTypes    from "prop-types";
import T            from "@cBehaviour/i18n";

import "./fieldGroup.css";

export default class FieldGroup extends React.Component{
  static propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    type: PropTypes.string.isRequired,
    children:PropTypes.node
  }
  /*
  Trois types possibles : 
    -Full : le groupe de champs prendra tout l'espace disponible
    -FullWrap : le groupe de champs prendra tout l'espace disponible mais créera 2 colonnes de champs dans l'espace
    -Half : le  groupe de champs prendra la moitié de l'espace disponible
  */
  render(){
    const { title, subTitle, type, children } = this.props;
    return (
        <div className={"bs-fieldGroup " + (type === "full" || type==="fullWrap" ? "bs-full" : "bs-half") }>
          <div className="bs-fieldGroup-title">
            <div><T>{title}</T></div>
            {
              subTitle 
              ? <div className="bs-subTitle"><T>{ subTitle }</T></div>
              : null
            }
          </div>
          <div className={"bs-fieldGroup-content " + (type==="fullWrap" ? "bs-wrap" : "")}>{ children }</div>
        </div>
    )
  }
}