import React  from "react";
import T      from "@cBehaviour/i18n";
import "./error_new.css";

const CError = ({ errors = [], children = null }) => {
  const error = (
    <ul className="bs-input-error_new">
      {
        errors.map(error => (
          <li><T bind={ error.original }>{ error.error }</T></li>
        ))
      }
    </ul>
  )
  if(children){
    return (
      <>
        { children }
        { error }
      </>
    )
  }else{
    return error;
  }
}

export default CError;