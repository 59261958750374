import User, { UserPro } from '@universal/types/business/User';
import { Helper } from '../lib/application';
import SessionService from '@universal/services/session';
import ClipboardService from '@common/services/clipboard';
import ApiService from '@universal/services/api';
import MessageService from '@common/services/message';
import I18nService from '@universal/services/i18n';
import Team from '@universal/types/business/Team';
import React from 'react';

const documentaitonLink = "https://files.gitbook.com/v0/b/gitbook-x-prod.appspot.com/o/spaces%2F-LbxFPY9aymgAS2G4f9t%2Fuploads%2FDNfoStogRtDgn7EBDcRy%2FMAJ%20agenda%20Outlook-Betterstreet.mp4?alt=media&token=606da7f9-1d33-4ef4-8b23-d5228e182aa4";

class CalendarHelper extends Helper {
  constructor() {
      super('calendar', ["currentTenant", "session", "api", "configuration"]);
  }

  isUserAllowedToCopyCalendarLink(user : User): user is UserPro {
    const clipboard = this.application.getService<ClipboardService>("clipboard");
    
    if(user.discriminator !== "pro"
      || !clipboard.isEnabled()
    ){
      return false;
    }

    return user.tenants.some(t => t.roles.includes("agent"));
  }

  connectedUserIsAllowedToCopyCalendarLink(): boolean {
    const session = this.application.getService<SessionService>("session");

    return this.isUserAllowedToCopyCalendarLink(session.user);
  }

  copyTeamCalendarLink(team: Team) {
    const query = { $or: [
      { "assignment.team": team._id },
      { "assignment.agents": { $in: team.members }}
    ]};

    return this.copyUserCalendarLinkForQuery(query, "issues");
  }

  copyUserCalendarLink(user : User, serviceName: string = "issues") {
    if(!this.isUserAllowedToCopyCalendarLink(user)){
      return false;
    }

    const query: any = {	$or : [
      { "assignment.agents": user._id }
    ]};

    if (user.team) {
      query.$or.push({ "assignment.team": user.team })
    };

    return this.copyUserCalendarLinkForQuery(query, serviceName);
  }

  copyConnectedUserCalendarLink() {
    const session = this.application.getService<SessionService>("session");

    return this.copyUserCalendarLink(session.user, "sessions");
  }

  private copyUserCalendarLinkForQuery(query: any, serviceName: string) {
    const api = this.application.getService<ApiService>("api");
    const clipboard = this.application.getService<ClipboardService>("clipboard");
    const messageService = this.application.getService<MessageService>("message");
    const i18nService = this.application.getService<I18nService>("i18n");


    api.service(serviceName, "icalUrl").execute(query)
      .then((res: any) => {
        clipboard.copyText(res.url);
        messageService.send("info", i18nService.translate("calendar_link_copied_successful", {
          documentation: React.createElement("a", { href: documentaitonLink, target: "_blanck" }, i18nService.translate("calendar_link_copied_documentation"))
        }));
      });

    return true;
  }
}

export default CalendarHelper;