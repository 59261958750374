// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-statistics-percentage {
  display: flex;
  padding: 48px 0px;
}

.bs-statistics-percentage-item {
  flex-grow: 1;
  font-weight: 400;
}
.bs-statistics-percentage-item>.fa {
  font-size: 2.9rem;
}
.bs-statistics-percentage-item-value {
  font-size: 1.2rem;
  padding-top: 6px;
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/statistics/percentage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".bs-statistics-percentage {\n  display: flex;\n  padding: 48px 0px;\n}\n\n.bs-statistics-percentage-item {\n  flex-grow: 1;\n  font-weight: 400;\n}\n.bs-statistics-percentage-item>.fa {\n  font-size: 2.9rem;\n}\n.bs-statistics-percentage-item-value {\n  font-size: 1.2rem;\n  padding-top: 6px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
