import React      from 'react';
import T          from '@cBehaviour/i18n';
import Button     from "@cComponents/button";
import Radio      from '@cComponents/old/input/radio';

export default ({ ["data-testid"] : dataTestId, ...props }) => {
  return React.createElement(
    Radio.Group,
    props,
    React.createElement(Radio.Value, { value: true  }, (selected) => (<Button.Radio pushed={selected} data-testid={dataTestId ? `${dataTestId}_yes` : null}><T>yes</T></Button.Radio>)),
    React.createElement(Radio.Value, { value: false }, (selected) => (<Button.Radio pushed={selected} data-testid={dataTestId ? `${dataTestId}_no` : null} ><T>no</T></Button.Radio>))
  ); 
}



