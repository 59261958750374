import React from "react";
import Scheduler, { 
  NoDaySelectedError,
  NoOccuranceGeneratedError 
} from "./scheduler";
import T from "@uBehaviour/i18n";
import Display from "@uComponents/displayIf";
import Nudge from "@cComponents/nudge";
import Application from "@uBehaviour/application";

import "./demo.css";
import dayjs from "dayjs";

class Demo extends React.Component{
  calculate(recurrence){
    try {
      const scheduler = Scheduler.factory(recurrence, (date) => this.props.currentTenant.isNotAWorkingDay(date));
      const dates = [];
      const number = this.props.number || 20;
      for(let i = 0; i < number && !scheduler.isEnd(); ++i){
        const date = scheduler.next();
        if(date){
          dates.push(date);
        }
      }
      const issues = dates.map((date, index) => {
        const virtual = {
          scheduledDate: date
        };
        virtual.creationDate = date.clone().subtract(recurrence.eventHorizonInDay, "d");
        if(virtual.creationDate.isBefore(dayjs())){
          virtual.creationDate = dayjs();
        }
        return virtual;
      });
      return issues;
    }catch(e){
      console.error(e);
      return e;
    }
  }
  render(){
    const { recurrence } = this.props;
    if(recurrence.schedulerRule){
      const issues = this.calculate(recurrence);
      return (
        <Display.Switch>
          <Display.Case condition={ issues.length }>
          {() => (
            <div className="bs-recurrence-demo-list">
            {
              issues.map(issue => (
                <div className="bs-recurrence-demo-item">
                  <div className="bs-recurrence-demo-item-weekDay">{ issue.scheduledDate.format("dddd") }</div>
                  <div className="bs-recurrence-demo-item-day">{ issue.scheduledDate.format("DD/MM/YYYY") }</div>
                  <div className="bs-recurrence-demo-item-createdAtLabel"><T>recurrence_demo_createdAt</T></div>
                  <div className="bs-recurrence-demo-item-createdAt">{ issue.creationDate.format("DD/MM/YYYY") }</div>
                </div>
              ))
            }
            </div>
          )}
          </Display.Case>
          <Display.Case  condition={ issues instanceof NoDaySelectedError }>
            <Nudge type="warning">
              <T>reccurence_demo_no_day_selected_error</T>
            </Nudge>
          </Display.Case>
          <Display.Case condition={ issues instanceof NoOccuranceGeneratedError }>
            <Nudge type="warning">
              <T>reccurence_demo_no_occurance_generated</T>
            </Nudge>
          </Display.Case>
          <Display.Default>
            <Nudge type="warning">
              <T>reccurence_demo_error</T>
            </Nudge>
          </Display.Default>
        </Display.Switch>
      );
    }else{
      return null;
    }
  }
}

export default Application.Service.forward(["currentTenant"], Demo);