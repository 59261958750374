import React from 'react'
import classNames from 'classnames'
import T from 'i18n-react'

import { isAuthorized } from 'client/features/issues/lib'

import IssueStateBannerSVG from '../common/IssueStateBannerSVG'
import DropDown from '../../../common/components/DropDown'
import IssueUpdatesWrapper from 'client/features/issues/containers/UpdatesWrapper.jsx'
import IssueWorkorderDropDown from "./WorkOrderStateDropDown";

import Application from "@uBehaviour/application";


const IssueWorkorderState = ({ issue, displayWorkorderStateModal, confirmations = {}, sessionService, acl, currentTenant, assignment }) =>{
  const sUser = sessionService.user;
  const team  = sUser.tenants?.find(t => t.tenant === currentTenant.currentId)?.team;
  const canWrite = isAuthorized(issue, 'workOrderState') && (assignment.team.reduce((canWrite, t) => {    
    return canWrite || team === t;
  }, false) || assignment.agents.reduce((canWrite, a) => {
    return canWrite || sUser._id === a;
  }, false)) || acl.isAllow("issues", "manage", sessionService.user, currentTenant.currentId);
  return (
    <div className='c-issue-state'>
      <DropDown
        renderToggle={ () => <IssueStateBannerSVG
          state={{ key: assignment.progress, value: assignment.progress, label: T.translate(assignment.progress) }}
          label
          big
          disabled={ !canWrite }
        /> }
        renderBody={ (close) =>
          canWrite && <IssueWorkorderDropDown
            updateState={
              (state, e) => {
                e.stopPropagation()
                displayWorkorderStateModal(state, issue, assignment._id)
                close()
              }
            }
            defaultState={ assignment.progress }
          />
        }
        confirmation={ confirmations.workOrderState }
        confirmationSize='2x'
      />
    </div>
  )
}

const IssueWorkorderStateWrapped = IssueUpdatesWrapper(IssueWorkorderState);

export default Application.Service.forward([["session", "sessionService"], "currentTenant", "acl"], IssueWorkorderStateWrapped);