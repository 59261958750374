import React, { FunctionComponent } from 'react';

import './priority.css';
import { useDataTestId } from '@universal/features/dataTestId';

interface PriorityProps {
  priority: number;
}

const Priority: FunctionComponent<PriorityProps> = ({ priority }) => {
  const priorityTestId = `info-${useDataTestId("priority")}`;
  return (
  priority === 1 
    ? null
    : <span className={`fa fa-warning ${priority === 3 ? "bs-issueAndAssignment-priority-moderate" : "bs-issueAndAssignment-priority-urgent"}`} data-testid={ priorityTestId }/>
)};

export default Priority;