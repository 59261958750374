import React        from "react";
import moment       from "moment";
import Form         from "@uBehaviour/form";
import Modal        from "@cComponents/modal";
import T            from "@uBehaviour/i18n";
import Application  from "@uBehaviour/application";
import Input        from "@cComponents/input";
import Field        from "@cComponents/field";
import Button       from "@cComponents/button";
import AreaTitle    from "@cComponents/areaTitle";
import "./printWorkOrder.css";

const PrintWorkOrder = ({ teams, start, end, onClose }) => (
  <Modal.Show style={{ height: "80vh" }} close={ onClose }>
  {(close) => (
    <PrintWorkOrderForm start={ start } end={ end } close={ close } teams={ teams } />
  )}
  </Modal.Show>
);

const PrintWorkOrderForm = Application.Helper.forward(["print"], ({ teams, start, end, print, close }) => {
  const submitHandler = React.useCallback((form, value) => {
    print.calendarWorkOrder(start, end, value.printType, value.teams)
      .then(() => close());
  }, [print, start, end, close]);

  const lStart = moment(start);
  const lEnd   = moment(end).clone().subtract(1, "day");

  return (
    <Form.Simple default={{ printType: "multi", teams: [] }} submit={ submitHandler }>
    {(ctx, value, errors, form) => (
      <div className="bs-planning-form-printWorkOrder">
        <AreaTitle>
        {
          lEnd.format("YYYYMMDD") === lStart.format("YYYYMMDD")
            ? <T bind={{ date: lStart.format("DD/MM/YYYY")}}>planning_form_printWorkorder_printDay</T>
            : <T bind={{ start: lStart.format("DD/MM/YYYY"), end: lEnd.format("DD/MM/YYYY") }}>planning_form_printWorkorder_printPeriod</T>
        }
        </AreaTitle>
        <div>
          <Field.Standart name="printType">
            <Field.Label><T>planning_form_printWorkorder_type_label</T></Field.Label>
            <Field.Input>
              <Input.Radio.Btn inline>
                <Input.Radio.Value value="multi"><T>planning_form_printWorkorder_type_muti</T></Input.Radio.Value>
                <Input.Radio.Value value="one"><T>planning_form_printWorkorder_type_one</T></Input.Radio.Value>
              </Input.Radio.Btn>
            </Field.Input>
          </Field.Standart>
          <Field.Standart name="teams">
            <Field.Label><T>planning_form_printWorkorder_selectTeams_label</T></Field.Label>
            <Field.Input>
              <Input.Checkbox.Btn layout={ <Input.Checkbox.Layout.Table column={3}/> }>
              {
                teams.map(team => (
                  <Input.Checkbox.Value value={ team._id }><span>{ team.name }</span></Input.Checkbox.Value>
                ))
              }
              </Input.Checkbox.Btn>
            </Field.Input>
          </Field.Standart>
        </div>
        <div  className="bs-modal-form-print-calendar-actions">
          <Button.Text onClick={ close }><T>cancel</T></Button.Text>
          <Button.Text onClick={() => form.submit(true) }><T>print</T></Button.Text>
        </div>
      </div>
    )}
    </Form.Simple>
  );
});

export default PrintWorkOrder;