// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tooltip{
  position: absolute;
  z-index: 1000;
}`, "",{"version":3,"sources":["webpack://./../common/features/tooltip.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf","sourcesContent":[".bs-tooltip{\n  position: absolute;\n  z-index: 1000;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
