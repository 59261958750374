// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-contact-item {
  height: 100px;
}
.bs-contact-item .bs-action {
  display: flex;
  justify-content: flex-end;
  color: #27ae60; 
}

.bs-contact-item .bs-contact-content {
  align-items: center;
}

.bs-contact-item .bs-contact-content .bs-contact-title {
  width: 15em;
  margin-right: 10px;
}

.bs-contact-item .bs-contact-content .bs-contact-description {
  width: 30em;
}

.bs-contact-item .bs-contact-content .bs-contact-title>div:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bs-contact-item .bs-contact-content .bs-contact-description-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bs-contact-item .bs-contact-content .bs-action {
  margin-left: 10px;
}


.bs-contact-item .bs-item2-content {
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/entities/contact/item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;;AAGA;EACE,aAAa;AACf","sourcesContent":[".bs-contact-item {\n  height: 100px;\n}\n.bs-contact-item .bs-action {\n  display: flex;\n  justify-content: flex-end;\n  color: #27ae60; \n}\n\n.bs-contact-item .bs-contact-content {\n  align-items: center;\n}\n\n.bs-contact-item .bs-contact-content .bs-contact-title {\n  width: 15em;\n  margin-right: 10px;\n}\n\n.bs-contact-item .bs-contact-content .bs-contact-description {\n  width: 30em;\n}\n\n.bs-contact-item .bs-contact-content .bs-contact-title>div:first-child {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.bs-contact-item .bs-contact-content .bs-contact-description-content {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.bs-contact-item .bs-contact-content .bs-action {\n  margin-left: 10px;\n}\n\n\n.bs-contact-item .bs-item2-content {\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
