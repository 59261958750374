import React from "react";

import Form from "@uBehaviour/form";
import Application from "@uBehaviour/application";
import Input from "@cComponents/input";
import Field from "@cComponents/field";
import T from "@uBehaviour/i18n";
import moment from "moment";
import Button from "@cComponents/button";
import _ from "lodash";
import AreaTitle from "@cComponents/areaTitle";
import Display from "@uComponents/displayIf";
import Modal from "@cComponents/modal";

import "./absence.css";

const validationSchema = {
  schema: {

  }
};
const formatDate = "DD/MM/YYYY";

const DeleteAbsenceValidation = Application.Service.forward(["api", "i18n"], ({ api, i18n, absence, close, closeAll, agent }) => {
  const accept = React.useCallback(() => {
    api.service('absences', 'delete').execute(absence._id)
      .then(() => {
        closeAll();
      });
  }, [absence, closeAll, api]);
  const refuse = React.useCallback(() => {
    close();
  }, [close]);

  const bind = {
    user: agent.fullname,
    reason: (<T>{ "planning_team_absence_" + absence.reason }</T>),
    start: moment(absence.start).format("")
  };
  if(moment(absence.end).diff(absence.start, "day", true) > 1 || (moment(absence.start).hour() !== 0 && moment(absence.end).hour() !== 0)){
    bind.start = moment(absence.start).format(formatDate);
    if(moment(absence.start).hour() !== 0){
      bind.start += ` (${ i18n.translate("absence_formContent_deleteAbsenceValidation_afternoon") })`;
    }
    if(moment(absence.end).hour() !== 0){
      bind.end = `${moment(absence.end).format(formatDate)} (${ i18n.translate("absence_formContent_deleteAbsenceValidation_morning") })`;
    }else{
      bind.end = moment(absence.end).subtract(1, "day").format(formatDate);
    }
  } else {
    bind.date = moment(absence.start).format(formatDate);
    if(moment(absence.start).hour() !== 0){
      bind.date += ` (${ i18n.translate("absence_formContent_deleteAbsenceValidation_afternoon") })`;
    } else if(moment(absence.end).hour() !== 0){
      bind.date += ` (${ i18n.translate("absence_formContent_deleteAbsenceValidation_morning") })`;
    }
  }

  return (
    <div className="bs-planning-form-absence-confirmDelete">
      <AreaTitle><T>absence_formContent_deleteAbsenceValidation_title</T></AreaTitle>
      <div className="bs-planning-form-absence-confirmDelete-body">
        <T bind={ bind }>
        { bind.date ? "absence_formContent_deleteAbsenceValidation_text_date" : "absence_formContent_deleteAbsenceValidation_text_period" }
        </T>
      </div>
      <div className="bs-planning-form-absence-confirmDelete-footer">
        <Button.Text onClick={ refuse }><T>absence_formContent_deleteAbsenceValidation_refuse</T></Button.Text>
        <Button.Text onClick={ accept }><T>absence_formContent_deleteAbsenceValidation_accept</T></Button.Text>
      </div>
    </div>
  );
});

const AbsenceForm = Application.Service.forward(["api", "currentTenant"], ({ currentTenant, api, close, absence, date, agent }) => {
  const [displayDeleteAbsenceValidation, setDisplayDeleteAbsenceValidation] = React.useState(false);
  const deleteAbsence = React.useCallback(() => {
    setDisplayDeleteAbsenceValidation(true);
  }, [setDisplayDeleteAbsenceValidation]);

  const closeDeleteAbsenceValidation = React.useCallback(() => {
    setDisplayDeleteAbsenceValidation(false);
  }, [setDisplayDeleteAbsenceValidation]);

  const submitHandler = React.useCallback((form, value) => {
    const sup1        = moment(value.period.end).diff(value.period.start, "day") > 1;
    const noMorning   = (sup1 && value.periodPrecision.includes("morning")) || (!sup1 && !value.periodPrecision.includes("morning"));
    const noAfternoon = (sup1 && value.periodPrecision.includes("afternoon")) || (!sup1 && !value.periodPrecision.includes("afternoon"));

    if(noMorning){
      value.period.start  = moment(value.period.start).add(12, "hour").toDate();
    }
    if(noAfternoon){
      value.period.end    = moment(value.period.end).subtract(12, "hour").toDate();
    }

    value.start = moment(value.period.start).toISOString();
    value.end   = moment(value.period.end).toISOString();

    delete value.period;
    delete value.periodPrecision;

    if(value.id){
      api.service("absences", "put").execute(value.id, _.omit(value, "id")).then(() => close());
    } else {
      api.service("absences", "post").execute(value).then(() => close());
    }
  }, [close, api]);

  const onChangeHandler = React.useCallback((form, object) => {
    const oldValue  = form.value;
    const oldSup1   = moment(oldValue.period.end).diff(oldValue.period.start, "day") > 1;
    const newSup1   = moment(object.period.end).diff(object.period.start, "day") > 1;
    if(oldSup1 !== newSup1){
      if(oldSup1){
        object.periodPrecision = ["morning", "afternoon"];
      } else {
        object.periodPrecision = [];
      }
    }else if(!newSup1 && !object.periodPrecision.length){
      if(oldValue.periodPrecision[0] === "morning"){
        object.periodPrecision = ["afternoon"];
      }else{
        object.periodPrecision = ["morning"];
      }
    }
    return object;  
  }, []);

  let defaultValue = {};

  if(absence) {
    defaultValue.period = {
      start: moment(absence.start).startOf("day").toDate(),
      end: moment(absence.end).hour() === 0 ? moment(absence.end).toDate() : moment(absence.end).add(1, "day").startOf("day").toDate()
    };
    
    if(moment(absence.end).diff(absence.start, "day", true) > 1){
      defaultValue.periodPrecision = [];
      if(moment(absence.start).hour() !== 0){
        defaultValue.periodPrecision.push("morning");
      }
      if(moment(absence.end).hour() !== 0){
        defaultValue.periodPrecision.push("afternoon");
      }
    } else {
      defaultValue.periodPrecision = ["morning", "afternoon"];
      if(moment(absence.start).hour() !== 0){
        defaultValue.periodPrecision = ["afternoon"];
      }
      if(moment(absence.end).hour() !== 0){
        defaultValue.periodPrecision = ["morning"];
      }
    }

    defaultValue.id       = absence._id;
    defaultValue.reason   = absence.reason;
    defaultValue.user     = absence.user._id;
    defaultValue.tenant   = absence.tenant;
    defaultValue.comment  = absence.comment;
  } else {
    defaultValue = {
      period: {
        start: date.clone().toDate(),
        end: date.clone().add(1, "day").toDate(),
      },
      periodPrecision: ["morning", "afternoon"],
      user: agent._id,
      tenant: currentTenant.currentId
    };
  }

  return (
    <>
      <Form.Simple default={ defaultValue } validator={ validationSchema } onChange={ onChangeHandler } submit={ submitHandler }>
      {(ctx, formValue, errors, form) => {
        const sup1 = moment(formValue.period.end).diff(formValue.period.start, "day") > 1;
        return (
          <>
            <div className="bs-planning-form-absence">
              <AreaTitle><T bind={{ user: agent.fullname }}>absence_formContent_title</T></AreaTitle>
              <div className="bs-planning-form-absence-period">
                <Field.Base name="period">
                  <Field.Label><T>planning_team_absence_period</T></Field.Label>
                  <Field.Input>
                  {(value, set, clear) => (
                    <div className="bs-planning-form-absence-period-input">
                      <Input.Period valueAsObject allDay value={ value } onChange={ set }>
                        <Input.Period.StartLabel>
                          <T>planning_absence_form_input_period_start_label</T>
                        </Input.Period.StartLabel>
                        <Input.Period.EndLabel>
                          <T>planning_absence_form_input_period_end_label</T>
                        </Input.Period.EndLabel>
                      </Input.Period>
                      <Form.Simple.InputAdapter name="periodPrecision">
                      {(value, set, clear) => (
                        <Input.Checkbox.Btn onChange={ set } value={ value } inline={ !sup1 }>
                          <Input.Checkbox.Value value="morning"><T>{ sup1   ? "absence_formContent_period_morning_sup1"   : "absence_formContent_period_morning" }</T></Input.Checkbox.Value>
                          <Input.Checkbox.Value value="afternoon"><T>{ sup1 ? "absence_formContent_period_afternoon_sup1" : "absence_formContent_period_afternoon" }</T></Input.Checkbox.Value>
                        </Input.Checkbox.Btn>
                      )}
                      </Form.Simple.InputAdapter>
                    </div>
                  )}
                  </Field.Input>
                </Field.Base>
              </div>
              <div className="bs-planning-form-absence-reason">
                <Field.Standart name="reason">
                  <Field.Label><T>planning_team_absence_reason</T></Field.Label>
                  <Field.Input>
                    <Input.Select fluid>
                      <Input.Select.Value value="holidays"><T>planning_team_absence_holidays</T></Input.Select.Value>
                      <Input.Select.Value value="recovery"><T>planning_team_absence_recovery</T></Input.Select.Value>
                      <Input.Select.Value value="sickleave"><T>planning_team_absence_sickleave</T></Input.Select.Value>
                      <Input.Select.Value value="other"><T>planning_team_absence_other</T></Input.Select.Value>
                    </Input.Select>
                  </Field.Input>
                </Field.Standart>
              </div>
              <Field.Standart name="comment">
                <Field.Label><T>planning_team_absence_comment</T></Field.Label>
                <Field.Input>
                  <Input.TextArea />
                </Field.Input>
              </Field.Standart>
            </div>
            <div className="bs-absence-form-footer">
              <div>
                <Display.If condition={ absence }>
                  <Button.Text onClick={ deleteAbsence }><T>planning_team_absence_delete</T></Button.Text>
                </Display.If>
              </div>
              <div>
                <Button.Text onClick={ close }><T>planning_team_absence_cancel</T></Button.Text>
                <Button.Text onClick={ () => form.submit()  }><T>planning_team_absence_submit</T></Button.Text>
              </div>
            </div>
          </>
        );
      }}
      </Form.Simple>
      <Display.If condition={ displayDeleteAbsenceValidation }>
        <Modal.Show close={ closeDeleteAbsenceValidation }>
        {closeValidation => (
          <DeleteAbsenceValidation
            close={ closeValidation }
            closeAll={ () =>  { closeValidation(); close(); }}
            absence={ absence }
            agent={ agent }
          />
        )}
        </Modal.Show>
      </Display.If>
    </>
  );
});

export default AbsenceForm;