import { ComponentType, FunctionComponent } from "react";
import { QuantityProps } from "./type";
import { SupplySubject } from "../subjects";
import Supply from "@uTypes/business/Supply";
import { SupplyStatement } from "@uTypes/business/Valorization";
import StatementLayout from "../statementLayout";

interface SupplyStatementProps {
  statement: SupplyStatement;
};
function SupplyStatementComponent(Quantity: ComponentType<QuantityProps>): FunctionComponent<SupplyStatementProps> {
  return ({ statement }) => (
    <StatementLayout statement={ statement } displayCostResource='supplies' displayCostAction='readCost'>
      <StatementLayout.Subject><SupplySubject supply={ statement.supply as Supply } /></StatementLayout.Subject>
      <StatementLayout.Occurence><Quantity quantity={ statement.quantity } unit={ (statement.supply as Supply).valorization.type }/></StatementLayout.Occurence>
    </StatementLayout>
  );
};

export default SupplyStatementComponent;