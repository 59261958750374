import React from 'react';
import Slot from '../slot';
import Layout from '../layout';
import PropTypes from 'prop-types';
import Tool from "@cLib/tool";

import './item.css';
export default class Item extends React.Component {
  static propTypes = {
    type: PropTypes.string,
    className: PropTypes.string
  }
  static Header = Slot();
  static Illustration = Slot();
  static Content = Slot();
  static Complementary = Slot();
  static Footer = Slot();

  render() {
    const header = Item.Header.get(this);
    const illustration = Item.Illustration.get(this);
    const content = Item.Content.get(this);
    const complementary = Item.Complementary.props(this);
    const footer = Item.Footer.get(this);
    let { type, className } = this.props;
    if (!className) {
      className = "";
    }
    if (type) {
      className += " bs-" + type;
    }
    return (
      <Layout.Standart className={"bs-item " + className}>
        <Layout.Standart.Header>
          {header}
        </Layout.Standart.Header>
        <Layout.Standart.Content>
          <Layout.Inline >
            <Layout.Inline.Header className={Tool.mergeString("bs-item-illustration", Item.Illustration.props(this).className)}>
              {
                illustration
                  ? React.cloneElement(illustration, {
                      width: (type === "compact" ? 100 : (type === "line" ? 40 : 160)),
                      height: (type === "compact" ? 100 : (type === "line" ? 40 : 160))
                    })
                  : null
              }
            </Layout.Inline.Header>
            <Layout.Inline.Content className={Tool.mergeString("bs-item-content", Item.Content.props(this).className)}>
              {content}
            </Layout.Inline.Content>
            <Layout.Inline.Footer className={Tool.mergeString("bs-item-complementary", Item.Complementary.props(this).className)}>
              {complementary.children && complementary.children.length ? complementary.children : null}
            </Layout.Inline.Footer>
          </Layout.Inline>
        </Layout.Standart.Content>
        <Layout.Standart.Footer>
          {footer}
        </Layout.Standart.Footer>
      </Layout.Standart>

    )
  }
}