import React, { useState }  from "react";
import Slot                 from "./slot";


import "./tabs.css";

const Tab     = Slot();
const Title   = Slot();
const Header  = Slot();
const Content = Slot();
const TabsImplementation = (props) => {
  const [current, switchTab] = useState(0);
  return (
    <div className="bs-tabs">
      <div className="bs-tabs-buttons">
      {
        props.tabs.map((tab, idx) => tab.header({ selected: current === idx, onClick: () => switchTab(idx) }))
      }
      </div>
      <div className="bs-tabs-content">
      {
        props.tabs.map((tab, idx) => (
          <div className={"bs-tab-old " + (current === idx ? "bs-tab-old-show" : "bs-tab-old-hidden")}>
          { tab.content }
          </div>
        ))
      }
      </div>
    </div>
  )
};
const Tabs = (props) => {
  const tabsProps   = Tab.props({ props }, true);
  const tabsConfig  = tabsProps.map(props => {
    const title = Title.get({ props });
    const header = !title
    ? Header.get({ props })
    : ({ selected, onClick }) => (
      <div className={"bs-button bs-button-text bs-tab-old-button " + (selected ? "bs-tab-old-button-selected" : "bs-tab-old-button-not-selected")} onClick={onClick}>
        { title }
      </div>
    );
    return {
      header:   header,
      content:  Content.get({ props })
    };
  });
  if(!tabsConfig.length) return null;
  return (
    <TabsImplementation tabs={tabsConfig} />
  );
}

Tabs.Tab          = Tab;
Tabs.Tab.Title    = Title;
Tabs.Tab.Header   = Header;
Tabs.Tab.Content  = Content;

export default Tabs;