const URLS = {
  session: {
    auth: '/auth',
    isAuth: '/isAuth',
    config: '/config',
    passwordForgotten: '/password/forgot',
    passwordReset: '/password/reset',
    citizen: {
      auth: '/citizen/auth',
      passwordForgotten: '/citizen/password/forgot',
      passwordReset: '/citizen/password/reset',
    }
  },
  issues: {
    resource: '/issues',
    item: '/issues/:id',
    get: '/issues',
    actions: {
      addFiles: '/issues/:id/addFiles',
      address: '/issues/:id/address',
      archive: '/issues/:id/archive',
      assignments: '/issues/:id/assignments',
      building: '/issues/:id/building',
      calendarIndex: '/issues/:id/calendarIndex',
      contactRequestor: '/issues/:id/contactRequestor',
      cover: '/issues/:id/cover',
      deadline: '/issues/:id/deadline',
      deletePicture: '/issues/:id/deletePicture',
      deletePublicComment: '/issues/:id/deletePublicComment',
      description: '/issues/:id/description',
      display: '/issues/:id/display',
      editPublicComment: '/issues/:id/editPublicComment',
      follow: '/issues/:id/follow',
      hashtags: '/issues/:id/hashtags',
      internalNote: '/issues/:id/internalNote',
      merge: '/issues/:id/merge',
      order: '/issues/:id/order',
      owner: '/issues/:id/owner',
      patrimonies: '/issues/:id/patrimonies',
      patrimony: '/issues/:id/patrimony',
      priority: '/issues/:id/priority',
      publicComment: '/issues/:id/publicComment',
      removePdf: '/issues/:id/removePdf',
      requestor: '/issues/:id/requestor',
      rotatePicture: '/issues/:id/rotatePicture',
      state: '/issues/:id/state',
      supplies: '/issues/:id/supplies',
      tag: '/issues/:id/tag',
      transfer: '/issues/:id/transfer',
      follow: '/issues/:id/follow',
      unFollow: '/issues/:id/unFollow'
    }
  },
  citizen: {
    session: {
      auth: '/citizen/auth'
    }
  },
  buildings: {
    resource: '/buildings',
    item: '/buildings/:id'
  },
  patrimonies: {
    resource: '/patrimonies',
    item: '/patrimonies/:id'
  },
  userPros: {
    resource: '/user-pros',
    item: '/user-pros/:id',
    actions: {
      invite: '/user-pros/:id/invite',
    }
  },
  teams: {
    resource: '/teams',
    item: '/teams/:id',
    actions: {
      addUsers: '/teams/:id/add-users',
      removeUsers: '/teams/:id/remove-users'
    }
  },
  valorizations: {
    resource: '/valorizations',
    item: '/valorizations/:id'
  },
  externalServices: {
    resource: '/external-services',
    item: '/external-services/:id'
  },
  communes: {
    resource: '/communes',
    item: '/communes/:id'
  },
  tenants: {
    tags: {
      resource: '/tags',
      item: '/tags/:tagId'
    },
    transfers: {
      resource: '/transfers',
      item: '/:transferId'
    }
  },
  home: {
    get: '/home'
  },
  weather: '/weather'
}

export default URLS;