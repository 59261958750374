import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class ButtonGroupSelector extends PureComponent {
  render() {
    return (
      <div className={ classNames('o-button-group-selector', this.props.className) }>
        {this.props.options.map((option, key) =>
          <div key={ key } onClick={ option.isSelected ? null : this.props.onSelect.bind(null, option.value) } className={ classNames('o-button-group-selector__item', [option.className], {
            active: option.isSelected
          }) }>
            {option.label}
          </div>
        )}
      </div>
    )
  }
}

ButtonGroupSelector.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape(
    {
      label: PropTypes.string.isRequired,
      isSelected: PropTypes.bool,
      className: PropTypes.string,
      value: PropTypes.any.isRequired
    }
  )).isRequired,
  selectedClass: PropTypes.string,
  className: PropTypes.string,
  onSelect: PropTypes.func.isRequired
}
ButtonGroupSelector.defaultProps = {}

export default ButtonGroupSelector
