import React, { FunctionComponent } from 'react';
import Input from '@cComponents/input';
import { HolidayDay, HolidayDays, getHolidayDaysOfTheYear, getHolidayDayByCountry } from '@uLib/holidayDay';
import useService from '@uBehaviour/hooks/useService';
import T from '@uBehaviour/i18n';
import dayjs from 'dayjs';

interface StandartsProps {
  onChange: (value: HolidayDay[])=> void,
  value: HolidayDay[],
  country: string
}

const Standarts: FunctionComponent<StandartsProps> = ({ onChange, value, country }) => {

  const holidayDayWithDate = React.useMemo(() => Object.values(getHolidayDayByCountry(country))
    .map(name => ({ name, date: getHolidayDaysOfTheYear[name](dayjs())}))
    .sort((a, b) => a.date.toDate().getTime() - b.date.toDate().getTime())
  , [HolidayDays, country]);

  return  (
    <Input.Checkbox.Btn onChange={ onChange } value={ value }>
    {
      holidayDayWithDate.map(holidayDay => (
        <Input.Checkbox.Value key={ holidayDay.name } value={ holidayDay.name }>
          <T bind={{ date: holidayDay.date.format("dddd DD/MM/YYYY")}}>{ `tenant_form_holidayDay_standarts_${holidayDay.name}` }</T>
        </Input.Checkbox.Value>
      ))
    }
    </Input.Checkbox.Btn>
  );
};

export default Standarts;