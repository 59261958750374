import React        from 'react'
import _            from "lodash";
import SelectSupply from "client/components/assignments/selectSupply.jsx";
import fetch        from "client/lib/fetch";

class InputSelectSupply extends React.Component {
  constructor(props) {
    super(props);
    this.state = { supplies: null };
  }

  load(){
    let endpointSupplies = `/supplies?q=${JSON.stringify({ tenant: this.props.tenant, disabled: false })}&sort=${JSON.stringify({ name: 1 })}&limit=100`;

    return fetch(endpointSupplies).then(response => response.json())
            .then(supplies => {
              this.setState({
                supplies
              })
            });
  }

  componentDidMount(){
    this.load()
  }

  render() {
    if(!this.state.supplies) return null;
    return(
      <SelectSupply
        values={ this.props.value } 
        supplies={ this.state.supplies }
        onChange={ this.props.onChange }
        onCreate={ this.props.onCreate }
        onEdit={ this.props.onEdit }
        readOnly={ this.props.readOnly }
      />
    )
  }
}

export default InputSelectSupply;