import usePager from '@uBehaviour/data/hooks/usePager';
import { BusinessEntity } from '@universal/types/technic/Entityable';

interface UsePagerHandlerParams {
  model: string;
  query: Object;
  sort?: Record<string, 1 | -1>;
  load?: Object;
  pageSize?: number;
  loadAll?: boolean;
};

interface UsePagerHandlerReturn<T>{
  datas: Array<BusinessEntity<T>>;
  loadNext: (pageSize?: number) => void;
  allElementsLoaded: boolean;
  reload: (pageSize?: number) => void;
  loading: boolean;
};

function usePagerTyped<T>(params: UsePagerHandlerParams): UsePagerHandlerReturn<T>{
  const result : UsePagerHandlerReturn<T> = usePager(params) as UsePagerHandlerReturn<T>;
  return result;
};


export default usePagerTyped;