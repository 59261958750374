import React, { FunctionComponent } from 'react';
import UserFormat from '@uBusiness/format/user';
import File from "@cComponents/file";

import User from '@uTypes/business/User';
import { BusinessEntity, Loader } from '@uTypes/technic/Entityable';

import './avatar.css';

type AvatarProps = {
  user: BusinessEntity<User, { avatar: Loader }>;
  size?: number;
}

const Avatar: FunctionComponent<AvatarProps> = ({ user, size = 35 }) => (
  <div className="bs-favatar">
    <div className="bs-favatar-illustration" style={{ width: size + "px", height: size + "px", fontSize: (size / 2)+"px" }}>
    {
      user.avatar
        ? <File file={ user.avatar } width={ size } height={ size } fit="cover" />
        : <div className="bs-favatar-illusatrion-text">{ UserFormat.initial(user) }</div>
    }
    </div>
  </div>
);

export default Avatar;