import React        from "react";
import Address      from "@cFeatures/address";
import Title        from "@entities/issueAndAssignment/title";
import Issue        from "@uTypes/business/Issue";
import File         from "@cComponents/file";

import "./tooltip.css";

interface IssuesMapTooltipProps {
  issue: Issue,
};

const Tooltip: React.FunctionComponent<IssuesMapTooltipProps> = ({ issue }) => (
  <div className="bs-issue-mapMarker-tooltip bs-starter-issue-link">
    { issue.files.length ? (<div><File file={ issue.files[0] } width={ 300 } height={ 100 }/></div>) : null }
    <div>
      <Title title={ issue.bsId } category={ issue.category } priority={ issue.priority } issueId={ issue._id } />
      <Address location={ issue.location } />
    </div>
  </div>
);


export default Tooltip;