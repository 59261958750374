import React, { FunctionComponent, PropsWithChildren } from "react";
import { Paginated } from "@cComponents/wizard";
import T             from "@uBehaviour/i18n";
import CTA           from "../../features/cta";
import Template      from "./template";
import PromotionalSubtitle from "./promotionalSubtitle";
import Text, { Style } from "@cComponents/text";
import Slot from "@universal/components/slot2";

import "./index.css";

const Image = Slot();

const PlanningBase: FunctionComponent<PropsWithChildren> & { Image: typeof Image } = ({ children }) => {
  const image = Image.get(children);
  const restChildren = Image.not(children);

  return (
    <Template>
      <Template.Image>{ image }</Template.Image>
      <Template.Icon><span className="fa fa-calendar bs-featuresDiscovery-planning-icon" /></Template.Icon>
      <Template.Title><Text style={ Style.huge.bold }><T>starter_featuresDiscovery_planning_title</T></Text></Template.Title>
      { restChildren }
    </Template>
  );
};
PlanningBase.Image = Image;

const PlanningOrganize: FunctionComponent = () => {
  const focusedText = <Text style={ Style.bold }> <T>starter_featuresDiscovery_planning_dragAndDrop_focus</T> </Text>;

  return (
    <PlanningBase>
      <Image><img src="/images/starter/featuresDiscovery/planning_1.png" alt="Planning"/></Image>
      <Text.Paragraph><T>starter_featuresDiscovery_planning_organize</T></Text.Paragraph>
      <Text.Paragraph><T bind={{ focusedText }}>starter_featuresDiscovery_planning_dragAndDrop</T></Text.Paragraph>
    </PlanningBase>
  );
};


const PlanningAbsence: FunctionComponent = () => {
  const focusedText1 = <Text style={ Style.bold }><T>starter_featuresDiscovery_planning_absence_focus</T></Text>;
  const focusedText2 = <Text style={ Style.bold }><T>starter_featuresDiscovery_planning_availability_focus</T></Text>;

  return (
    <PlanningBase>
      <Image><img src="/images/starter/featuresDiscovery/planning_2.png" alt="Planning"/></Image>
      <div className="bs-featuresDiscovery-content">
        <Text.Paragraph>
          <T bind={{ focusedText1 }}>starter_featuresDiscovery_planning_absence</T>
        </Text.Paragraph>
        <Text.Paragraph>
          <T bind={{ focusedText2 }}>starter_featuresDiscovery_planning_availability</T>
        </Text.Paragraph>
      </div>
    </PlanningBase>
  );
};


const PlanningUpsale: FunctionComponent = () => (
  <PlanningBase>
    <Image><img src="/images/starter/featuresDiscovery/planning_3.png" alt="Planning"/></Image>
    <PromotionalSubtitle>
      <div className="bs-featuresDiscovery-content">      
        <Text>
          <T>starter_featuresDiscovery_planning_upsale</T>
        </Text>
        <span className="bs-featuresDiscovery-content-CTA">
          <CTA.DiscoverOffers.Orange />
        </span>
      </div>     
    </PromotionalSubtitle>
  </PlanningBase>
);

type PlanningProps = {
  close: () => void,
};
const Planning: FunctionComponent<PlanningProps> = ({ close }) => (
  <Paginated close={ close } style={{ width: "920px", height: "540px" }}>
    <Paginated.Step>
      <PlanningOrganize />
    </Paginated.Step>
    <Paginated.Step>
      <PlanningAbsence />
    </Paginated.Step>
    <Paginated.Step>
      <PlanningUpsale />
    </Paginated.Step>
  </Paginated>
);

export default Planning;