import React, { Component } from 'react'
import classNames from 'classnames'
import FontAwesome from 'react-fontawesome'
import _ from 'lodash'

import Cut from 'client/features/common/components/Cut.jsx'

class IssuesFiltersCheckbox extends Component {
    constructor() {
        super()
    }

    onChange() {

    }

    render() {
        const { field = {}, input = {}, setFilter, values = [], uniq, title, actions, forceChecked, labelIcon } = this.props
        let isChecked = forceChecked || false
        if (!forceChecked && !uniq && values)
            isChecked = Array.isArray(values) ? values.indexOf(input.value) >= 0 : values[input.value]
        else if (!forceChecked && values)
            isChecked = values === input.value || values.type === input.value
        
        const id = `f-${field.key}[${input.value}]`
        const fieldKey = field.key
        
        return (
            <div 
                className='o-flag o-flag--small c-filter-field-checkbox u-margin-top-tiny'
                onClick={ setFilter.bind(null, {
                    key: fieldKey,
                    value: input.value, 
                    uniq
                }) }
                title={ title }
            >
                <div className='o-flag__img'>
                    <input
                        type='checkbox'
                        className='u-margin-none'
                        id={ id }
                        checked={ isChecked }
                        onChange={ this.onChange }
                    />
                </div>
                <label className={ classNames('o-flag__body', input.labelClass) }>
                    <span>
                        { input.icon && <span><FontAwesome name={ input.icon } className={ input.iconClass } />&nbsp;</span> }
                    </span>
                    <span className='c-heading-medium'>
                        <Cut string={ input.label } cut={ 28 } more='...'/>
                        {
                            labelIcon && <FontAwesome name={ labelIcon } className='u-margin-left-tiny' />
                        }
                    </span>
                </label>
                {
                    actions && actions[0] && 
                        <span className='u-pull-right' onClick={ (e) => e.stopPropagation() }>
                            {
                                actions.map(action => 
                                    <a
                                        key={ action.label }
                                        onClick={ action.onClick }
                                        title={ action.label }
                                        className='u-margin-left-tiny c-issues-filters__field__action'
                                    >
                                        <FontAwesome name={ action.icon } />
                                    </a>
                                )
                            }
                        </span>
                }
            </div>
        )
    }
}

export default IssuesFiltersCheckbox