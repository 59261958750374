import UrlBuilder  from "@uLib/urlBuilder";

export class IssuePrintBuilder {
  static create(){
    return new IssuePrintBuilder();
  };

  constructor(){
    this._urlBuilder = UrlBuilder.create("/print/issues");
  };

  query(query){
    this._urlBuilder.addParam("query", query);
    return this;
  };

  sort(sort){
    this._urlBuilder.addParam("sort", sort);
    return this;
  };

  offset(offset){
    this._urlBuilder.addParam("offset", offset);
    return this;
  };

  limit(limit){
    this._urlBuilder.addParam("limit", limit);
    return this;
  };

  build(){
    return this._urlBuilder.build();
  }
}

export class AssignmentPrintBuilder {
  static create(){
    return new AssignmentPrintBuilder();
  };

  constructor(){
    this._urlBuilder = UrlBuilder.create("/print/assignments");
  };

  query(query){
    this._urlBuilder.addParam("query", query);
    return this;
  };

  sort(sort){
    this._urlBuilder.addParam("sort", sort);
    return this;
  };

  offset(offset){
    this._urlBuilder.addParam("offset", offset);
    return this;
  };

  limit(limit){
    this._urlBuilder.addParam("limit", limit);
    return this;
  };

  oneByPage(oneByPage){
    this._urlBuilder.addParam("oneByPage", oneByPage ? "true" : "false");
    return this;
  }

  withPdf(withPdf){
    this._urlBuilder.addParam("pdf", withPdf ? "true" : "false");
    return this;
  }

  build(){
    return this._urlBuilder.build();
  }
};

