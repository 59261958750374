import React, { FunctionComponent }          from "react";
import Form           from "@cBehaviour/form";
import T              from "@cBehaviour/i18n"
import Button         from "@cComponents/button";
import CError         from "@cComponents/error";
import Input          from "@cComponents/old/input";
import AuthentificationLayout from '../layout';
import useService from "@uBehaviour/hooks/useService";
import SessionService from "@uServices/session";
import I18nService from "@uServices/i18n";
import Nudge from '@cComponents/nudge';

import "./forgotPassword.css";

interface ForgotPasswordProps {
  goBack: () => void;
}

const ForgotPassword: FunctionComponent<ForgotPasswordProps> = ({ goBack }) => {
  const session = useService<SessionService>("session");
  const i18n = useService<I18nService>("i18n");

  const sendPasswordRecoveryAndGoBack = React.useCallback(({ username }: { username: string }) => {
    return session.recoverPassword(username)
      .then(goBack);
  }, [session]);

  return (
    <AuthentificationLayout>
      <Form.Simple name="forgotPassword" segment={true} submit={ sendPasswordRecoveryAndGoBack }>
      {(data, errors, form) => (
        <div className="bs-forgotPassword">
          <Nudge type="info">
            <T>authentification_recoverPassword_info</T>
          </Nudge>
          <Input.Email name="username">
            { i18n.translate("username") }
          </Input.Email>
          <CError errors={ errors } />
          <div className="bs-forgotPassword-actions">
            <Button.Text onClick={() => form.submit()}>
              <T>recover_account_by_email</T>
            </Button.Text>
            <span onClick={ goBack }>
              <T>authentification_recoverPassword_goBack</T>
            </span>
          </div>
        </div>
      )}
      </Form.Simple>
    </AuthentificationLayout>
  );
};

export default ForgotPassword;
