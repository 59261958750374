// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-input-wa-period{
  display: table;
}

.bs-input-wa-period-row {
  display: table-row;
}

.bs-input-wa-period-row>div{
  display: table-cell;
  vertical-align: bottom;
}

.bs-input-wa-period-row>div:not(:last-child) {
  padding-right: 5px;
}

.bs-input-wa-period-first .bs-select-wa{
  width: 110px;
}

.bs-input-wa-period-row .fa{
  margin-top: 2px;
  vertical-align: bottom;
}
.bs-input-wa-period-row .fa:hover {
  color: rgb(146, 155, 160);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/old/components/input/period.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,eAAe;EACf,sBAAsB;AACxB;AACA;EACE,yBAAyB;EACzB,eAAe;AACjB","sourcesContent":[".bs-input-wa-period{\n  display: table;\n}\n\n.bs-input-wa-period-row {\n  display: table-row;\n}\n\n.bs-input-wa-period-row>div{\n  display: table-cell;\n  vertical-align: bottom;\n}\n\n.bs-input-wa-period-row>div:not(:last-child) {\n  padding-right: 5px;\n}\n\n.bs-input-wa-period-first .bs-select-wa{\n  width: 110px;\n}\n\n.bs-input-wa-period-row .fa{\n  margin-top: 2px;\n  vertical-align: bottom;\n}\n.bs-input-wa-period-row .fa:hover {\n  color: rgb(146, 155, 160);\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
