import React from 'react'

const InputField = ({
    input,
    onClick = () => null,
    initialValue,
    min,
    max,
    containerClassName = 'control-group',
    className = 'u-1of1',
    label,
    autoFocus,
    placeholder,
    type = 'text',
    disabled = false,
    autoComplete,
    meta: { touched, error, warning },
    "data-testid": dataTestId
}) => (
    <div className={ containerClassName }>
        { label ? <label>{ label }</label> : null }
        <div className='controls' onClick = { onClick }>
            <input 
                { ...input }
                className = { className }
                placeholder = { placeholder }
                type = { type }
                autoComplete = { autoComplete }
                disabled = { disabled } 
                defaultValue = { initialValue }
                min={ min }
                max={ max }
                autoFocus={ autoFocus } 
                data-testid={ dataTestId }
            />
            { touched && ((error && <span className='error'>{error}</span>) || (warning && <span>{warning}</span>)) }
        </div>
    </div>
)

export default InputField