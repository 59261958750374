// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-transferredIssueContainer {
  background-color: white;
  width: 90vw;
  margin-top: 15px;
  margin-right: auto;
  margin-left: auto;
  color: #5a6266;
  padding: 10px;
}

.bs-transferredIssueContainer-detail .bs-title {
  display: flex;
  justify-content: space-between;
  font-size: 1.2em;
  font-weight: 600;
}

.bs-transferredIssueContainer-detail .bs-title .bs-button>span {
  margin-right: 5px;
}

.bs-transferredIssueContainer-detail > div {
  padding: 10px 0px;
  border-bottom: 1px solid  #eee;
}

.bs-transferredIssueContainer-detail .bs-files {
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow-x: auto;
}

.bs-transferredIssueContainer-detail .bs-files > div {
  padding: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/applications/external/views/transfer/index.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".bs-transferredIssueContainer {\n  background-color: white;\n  width: 90vw;\n  margin-top: 15px;\n  margin-right: auto;\n  margin-left: auto;\n  color: #5a6266;\n  padding: 10px;\n}\n\n.bs-transferredIssueContainer-detail .bs-title {\n  display: flex;\n  justify-content: space-between;\n  font-size: 1.2em;\n  font-weight: 600;\n}\n\n.bs-transferredIssueContainer-detail .bs-title .bs-button>span {\n  margin-right: 5px;\n}\n\n.bs-transferredIssueContainer-detail > div {\n  padding: 10px 0px;\n  border-bottom: 1px solid  #eee;\n}\n\n.bs-transferredIssueContainer-detail .bs-files {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  overflow-x: auto;\n}\n\n.bs-transferredIssueContainer-detail .bs-files > div {\n  padding: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
