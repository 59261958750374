import React from 'react'
import _ from 'lodash'
import Date from '../../../../common/components/Date'
import IssueLog from '../../common/IssueLog'

const IssueHistoryDay = ({ logs, date, issue, goToIssue }) =>
  <div className='c-issue-history-day' key={ date }>
    <div className='c-issue-history-day__date'>
      { date && <Date date={ parseInt(date) }/> }
    </div>
    <div className='c-issue-history-day__log'>
      {
        logs[date] && logs[date][0] &&
                    _.sortBy(logs[date], 'date').reverse().map((log, idx) =>
                      <IssueLog
                        key={ `${date}_${idx}` }
                        log={ log }
                        issue={ issue }
                        hideDate
                        goToIssue={ goToIssue }
                      />
                    )
      }
    </div>
  </div>

export default IssueHistoryDay