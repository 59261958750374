import React, { PureComponent } from 'react'
import Title from 'client/features/overlay-view/components/Title.jsx'
import T from 'i18n-react'
import RequestorForm from 'client/features/issues/components/details/RequestorForm.jsx'
import ContactRequestorModal from 'client/features/issues/components/modals/ContactRequestor.jsx'
import Confirmation from 'client/features/common/components/Confirmation.jsx'
import classNames from 'classnames'
import { isAuthorized } from 'client/features/issues/lib'

const __CLIENT = window.__CLIENT;

class IssueDetailsRequestor extends PureComponent {

  constructor() {
    super()
    this.displayModal = this.displayModal.bind(this)
    this.contactRequestor = this.contactRequestor.bind(this)
    this.updateRequestor = this.updateRequestor.bind(this)
    this.edit = this.edit.bind(this)
    this.state = {
      showModal: false,
      receiver: null,
      edit: false
    }
  }

  displayModal(receiver) {
    this.setState({
      showModal: !this.state.showModal,
      receiver
    })
  }

  contactRequestor(values, dispatch) {
    this.setState({
      showModal: false,
      receiver: null
    })
    this.props.contactRequestor(values, dispatch)
  }

  updateRequestor(values) {
    this.setState({
      edit: false
    })
    this.props.updateRequestor(values)
  }

  edit() {
    this.setState({
      edit: !this.state.edit
    })
  }

  _renderUser(issue, user, comment, isFollower) {
    const edit = this.props.user.authorizations.issue.editRequestor && !isFollower && this.props.issue.type === 'publicSpace'

    return (
      <div key={ user._id }>
        <div>
          { user.icon && <span><i className={ user.icon } /> </span> }
          { user.firstName } { user.lastName }
          <br />
          {
            user.source &&
            <div>
              { user.source }
            </div>
          }
          {
            isFollower &&
            <div>
              { T.translate('citizen') }
            </div>
          }
          { user.email }
          {
            user.phone &&
            <div>{ user.phone }</div>
          }
          <div
            className={
              classNames('o-flex-layout o-flex-layout--space-between u-margin-top-small u-margin-bottom-small u-hide-for-print',
                { 'u-text-align-right': !edit, 'u-display-block': !edit }
            )
            }
          >
            {
              edit &&
              <span>
                <a className='u-color-grey u-text-italic' onClick={ this.edit }>
                  { T.translate('update_requestor') }
                </a>
                <Confirmation data={ this.props.confirmation }/>
              </span>
            }
            {
              isAuthorized(issue, 'contactRequestor') && user.email !== this.props.user.email &&
              <a className='u-color-grey u-text-align-right u-text-italic' onClick={ this.displayModal.bind(this, user) }>
                <T.span text='contact_by_email' />
              </a>
            }
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { issue, user } = this.props
    return (
      <div className=''>
        {
          this.state.showModal && __CLIENT &&
          <ContactRequestorModal
            show={ this.state.showModal }
            onHide={ this.displayModal }
            initialValues={ { receivers: this.state.receiver ? [this.state.receiver._id || this.state.receiver.email] : [] } }
            issue={ issue }
            user={ user }
            onSubmit={ this.contactRequestor }
          />
        }
        <Title title={ T.translate('issue_details_title_requestor') } />
        <div className='u-letter-box--small'>
          {
            this.state.edit ?
              <RequestorForm
                onSubmit={ this.updateRequestor }
                cancel={ this.edit }
                initialValues={ issue.requestor }
                issue={ issue }
                user={ user }
              />
              :
              this._renderUser(issue, issue.requestor, issue.comment)
          }
          {
            !this.state.edit && issue.followers && issue.followers[0] && issue.followers.map((follower) => this._renderUser(issue, follower, null, true))
          }
        </div>
        {
          issue.internalFollowers && issue.internalFollowers[0] &&
          <div className='u-margin-bottom-small'>
            <Title title={ T.translate('issue_details_title_internal_followers') } />
            {
              issue.internalFollowers.map(internalFollower =>
                <p key={ internalFollower._id } className='u-margin-none'>{ internalFollower.firstName } { internalFollower.lastName }</p>
              )
            }
          </div>
        }
      </div>
    )
  }
}

export default IssueDetailsRequestor