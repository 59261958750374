import List from './list';
import Form from '@entities/issue/newEntry';
import { IssueDetail, IssueDetailManager } from './detail';
export {
  List,
  Form,
  IssueDetail,
  IssueDetailManager
};

export default {
  List,
  Form,
  IssueDetail,
  IssueDetailManager
};