import React, { FunctionComponent } from 'react';
import { highlighter, Manager, Controller } from '@uComponents/highlight';

import './highlight.css';

const Highlighter = highlighter(({ children }) => (
  <span className="bs-highlighted">{ children }</span>
));

interface HighlightedProps {
 children: string
}

const Highlighted: FunctionComponent<HighlightedProps> = ({ children }) => (
  <span><Highlighter>{ children }</Highlighter></span>
)

export {
  Controller,
  Highlighted,
  Manager
};

export default {
  Controller,
  Highlighted,
  Manager
};