import React, { FunctionComponent, PropsWithChildren } from "react";

import "./tiny.css";


type ItemTinyProps = {

}

const ItemTiny: FunctionComponent<PropsWithChildren<ItemTinyProps>> = ({ children }) => {
  return (
    <div className="bs-item-tiny">
      { children }
    </div>
  );
}

export default ItemTiny;