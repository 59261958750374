import React, { FunctionComponent, PropsWithChildren }      from "react";
import useService from "@universal/behaviour/hooks/useService";
import CurrentTenantService from "@universal/services/currentTenant";
import Tenant from "@entities/tenant";
import Layout from "@cComponents/layout";
import T from "@uBehaviour/i18n";
import useOpenCloseToggle from "@uBehaviour/hooks/useOpenCloseToggle";
import classNames from '@uLib/classNames';
import Display from "@uComponents/displayIf";
import UserConnected from "./features/session/userConnected";
import FeaturesDiscovery from "./views/featuresDiscovery";
import Notification from "@entities/notifications/notification";
import { useLocation } from "@uFeatures/router";
import Issue from "@universal/types/business/Issue";
import Help from "@features/help";

import "./header.css";

const SelectCurrentTenant: FunctionComponent = () => {
  const currentTenant = useService<CurrentTenantService>('currentTenant');
  switch(currentTenant.changeMode){
    case "unlimited": return (<Tenant.SelectCurrentTenant.Unlimited />);
    case "limited":   return (<Tenant.SelectCurrentTenant.Limited />);
    default: return null;
  }
}

type HeaderItemProps = { 
  lock?: boolean,
  onClick?: () => void,
};

const Item: FunctionComponent<PropsWithChildren<HeaderItemProps>> = ({ lock, onClick, children }) => {
  const handleClick = React.useCallback(() => { onClick && onClick() }, [onClick]);
  return(
    <div className={ classNames("bs-starter-header-bar-item ").addIf("bs-starter-header-bar-item-locked", !!lock) } onClick={ handleClick }>
      { lock && <span className='fa fa-lock' /> }
      { children }
    </div>
  )
};
const LeftActions: FunctionComponent = () => {
  const [showPlanningWizard, openPlanningWizard, closePlanningWizard] = useOpenCloseToggle();
  const [showStatsWizard, openStatsWizard, closeStatsWizard] = useOpenCloseToggle();
  const [showNewIssueWizard, openNewIssueWizard, closeNewIssueWizard] = useOpenCloseToggle();

  return (
  <>
    <div className="bs-starter-header-bar-items">
      <Item>
        <span className="fa fa-list-ul" />
        <T>starter_header_item_issuesList</T>
      </Item>
      <Item lock onClick={ openPlanningWizard }>
        <span className="fa fa-calendar"/>
        <T>starter_header_item_planning</T>
      </Item>
      <Item lock onClick={ openStatsWizard }>
        <span className="fa fa-line-chart"/>
        <T>starter_header_item_stats</T>
      </Item>
      <Item lock onClick={ openNewIssueWizard }>
        <T>starter_header_item_newIssue</T>
        <span className="fa fa-plus-circle" />
      </Item>
    </div>
    <Display.If condition={ showPlanningWizard }>
      <FeaturesDiscovery.Planning close={ closePlanningWizard } />
    </Display.If>
    <Display.If condition={ showStatsWizard }>
      <FeaturesDiscovery.Statistics close={ closeStatsWizard } />
    </Display.If>
    <Display.If condition={ showNewIssueWizard }>
      <FeaturesDiscovery.NewIssue close={ closeNewIssueWizard } />
    </Display.If>
  </>
  );
};

const RightActions: FunctionComponent = () => {
  const [current, goTo] = useLocation();

  const goToIssue = React.useCallback((issue: Issue) => {
    goTo(`${current}/${issue._id}`);
  }, [current, goTo]);

  return (
  <div className="bs-starter-header-bar-actions">
    <SelectCurrentTenant />
    <Notification onIssueSelected={ goToIssue } />
    <Help />
    <UserConnected />
  </div>
)};

type HeaderProps = {
};
const Header: FunctionComponent<HeaderProps> = () => {
  return (
  <div className="bs-starter-header-bar">
    <Layout.Coreff>
      <Layout.Coreff.Left className="bs-starter-header-bar-left">
        <img src={`/images/logos/betterstreet_color_text_horizontal.svg`} alt="BetterStreet" />
      </Layout.Coreff.Left>
      <Layout.Coreff.Right className="bs-starter-header-bar-right">
        <LeftActions />
        <RightActions />
      </Layout.Coreff.Right>
    </Layout.Coreff>
  </div>
  );
}

export default Header;