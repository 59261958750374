// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-dragAndDrop{

};

.bs-dragAndDrop-draggable:hover {
  cursor: move;
}`, "",{"version":3,"sources":["webpack://./../common/behaviour/dragAndDrop.css"],"names":[],"mappings":"AAAA;;AAEA,CAAA;;AAEA;EACE,YAAY;AACd","sourcesContent":[".bs-dragAndDrop{\n\n};\n\n.bs-dragAndDrop-draggable:hover {\n  cursor: move;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
