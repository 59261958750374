import React, { FunctionComponent } from "react";
import Modal from "@cComponents/modal";
import useHelper from '@uBehaviour/hooks/useHelper';
import GeniallyGuideHelper from "@root/helpers/genially";
import Genially from "@cComponents/genially";

type StarterGuideProps = {
  close: () => void;
}

const guideId = "660bf9b3d4f85e0014d900fc";

const StarterGuide: FunctionComponent<StarterGuideProps> = ({ close }) => {
  const starterGuide = useHelper<GeniallyGuideHelper>("genially");

  const markAsSeenAndCloseGuide = React.useCallback(async() => {
    await starterGuide.markAsSeen(guideId); 
    close()
  }, [starterGuide, close]);

  return (
    <Modal.Show close={ markAsSeenAndCloseGuide } style={{ width :"80vw", maxWidth : "1200px" , maxHeight : ("675px"), height : "calc(80vw * 0.5625)" }} userCanClose>
      <Genially id={ guideId } />
    </Modal.Show> 
  );
}

export default StarterGuide;