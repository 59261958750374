import React          from "react";
import Form           from "@uBehaviour/form";
import Field          from "@cComponents/field";
import Input          from "@cComponents/input";
import T              from "@uBehaviour/i18n";
import Button         from "@cComponents/button";
import Application    from "@uBehaviour/application";
import Display        from "@uComponents/displayIf";
import _              from "lodash";

import "./form.css";

class CampaignForm extends React.Component {
  constructor(props) {
    super(props);
    this._form = React.createRef();
  }
  _preload = (form, data) => {
    return { ..._.omit(data, ["start", "end"]), period: {
      start: data.start,
      end: data.end
    }};
  }
  _presubmit = (form, data) => {
    return { ..._.omit(data, ["period"]),
      start: data.period.start,
      end: data.period.end
    };
  }
  _submit = (form, data) => {
    return (
      data._id
        ? this.props.api.service("campaigns", "put").execute(data._id, _.omit(data, ["_id"]))
        : this.props.api.service("campaigns", "post").execute(data)
      ).then(() => this.props.close());
  }

  render() {
    const value = this.props.data && this.props.data.toPlainText ? this.props.data.toPlainText() : this.props.data;
    return (
      <div className="bs-form-campaign-container">
        <div className="bs-campaign-form">
          <Field.ShortLabelContext prefix="campaign_form">
            <div>
              <Form.Simple 
                ref={ this._form }
                preload={ this._preload }
                value={ value }
                presubmit={ this._presubmit }
                submit={ this._submit }
              >
              {(ctx, value, errors, form) => (
                <>
                  <Field.Short name="title" required>
                    <Input.Text />
                  </Field.Short>
                  <Field.Short name="period" required>
                    <Input.Period withoutHour valueAsObject/>
                  </Field.Short>
                  <Field.Short name="type" required>
                    <Input.Radio.Btn>
                      <Input.Radio.Value value="typeform">
                        <span>TypeForm</span>
                      </Input.Radio.Value>
                    </Input.Radio.Btn>
                  </Field.Short>
                  <Display.If condition={value.type === "typeform"}>
                    <Field.Short name="options.id" required>
                      <Input.Text />
                    </Field.Short>
                  </Display.If>
                  <Field.Short name="country">
                    <Input.Checkbox.Btn inline>
                      <Input.Checkbox.Value value="fr"><T>campaign_form_country_france</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="be"><T>campaign_form_country_belgium</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="lu"><T>campaign_form_country_luxemburg</T></Input.Checkbox.Value>
                    </Input.Checkbox.Btn>
                  </Field.Short>
                  <Field.Short name="roles">
                    <Input.Checkbox.Btn inline>
                      <Input.Checkbox.Value value="admin"><T>campaign_form_role_admin</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="manager"><T>campaign_form_role_manager</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="categoryManager"><T>campaign_form_role_categoryManager</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="agent"><T>campaign_form_role_agent</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="teller"><T>campaign_form_role_teller</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="publicSpaceIssuer"><T>campaign_form_role_publicSpaceIssuer</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="buildingIssuer"><T>campaign_form_role_buildingIssuer</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="equipmentIssuer"><T>campaign_form_role_equipmentIssuer</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="viewer"><T>campaign_form_role_viewer</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="publicSpaceViewer"><T>campaign_form_role_publicSpaceViewer</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="buildingViewer"><T>campaign_form_role_buildingViewer</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="equipmentViewer"><T>campaign_form_role_equipmentViewer</T></Input.Checkbox.Value>
                      <Input.Checkbox.Value value="statistician"><T>campaign_form_role_statistician</T></Input.Checkbox.Value>
                    </Input.Checkbox.Btn>
                  </Field.Short>
                </>
              )}
              </Form.Simple>
            </div>
          </Field.ShortLabelContext>
        </div>
        <div className="bs-campaign-button">
          <Button.Text onClick={() => this._form.current.submit()}>
            <T>save</T>
          </Button.Text>
        </div>        
      </div>
    );
  }
}

export default Application.Service.forward(["api"], CampaignForm);