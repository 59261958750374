import RequestsActions from 'client/core/RequestsActions'
import { action } from 'client/core/actions'


export const StatsRequest = new RequestsActions('STATS')

export const RESET_STATS_FILTERS_VALUES = 'RESET_STATS_FILTERS_VALUES'
export const resetFiltersValues = () =>
  action(RESET_STATS_FILTERS_VALUES)

export const SET_STATS_FILTERS_VALUE = 'SET_STATS_FILTERS_VALUE'
export const setIssuesFiltersValue = (payload) =>
  action(SET_STATS_FILTERS_VALUE, { payload })

export const UNSET_STATS_FILTER_VALUE = 'UNSET_STATS_FILTER_VALUE'
export const unSetFilter = (key, uniq, kill) =>
  action(UNSET_STATS_FILTER_VALUE, { payload: { key, uniq, kill } })