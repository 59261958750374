import React, { PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import _ from 'lodash'
import classNames from 'classnames'
import FontAwesome from 'react-fontawesome'
import moment from 'moment'
import T from 'i18n-react'
import File     from 'client/components/file.jsx';
import { printIssuePicture } from 'client/features/issues/lib'

class IssuePicturesModal extends PureComponent {
  constructor(props) {
    super()
    this.nav = this.nav.bind(this)
    this._renderNav = this._renderNav.bind(this)
    this._renderCurrentPictureInfos = this._renderCurrentPictureInfos.bind(this)
    this.rotatePicture = this.rotatePicture.bind(this)
    this.setCover = this.setCover.bind(this)
    this.displayDelete = this.displayDelete.bind(this)
    this.deletePicture = this.deletePicture.bind(this)
    this.keyPress = this.keyPress.bind(this)
    this.printPicture = this.printPicture.bind(this)
    this.state = {
      currentPicture: props.currentPicture || props.cover,
      showDelete: false
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyPress, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress, false)
  }

  keyPress(event) {
    if (event.keyCode === 39)
      this.nav('next')
    else if (event.keyCode === 37)
      this.nav('previous')
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentPicture && this.state.currentPicture._id === nextProps.currentPicture._id)
      return

    this.setState({
      currentPicture: nextProps.currentPicture || nextProps.cover
    })
  }

  setCurrentPicture(currentPicture) {
    this.setState({
      currentPicture
    })
  }

  nav(side) {
    const indexOfCurrent = _.findLastIndex(this.props.pictures, { _id: this.state.currentPicture._id })
    let currentPicture
    if (side === 'next' && indexOfCurrent === this.props.pictures.length - 1)
      currentPicture = this.props.pictures[0]
    else if (side === 'previous' && indexOfCurrent === 0)
      currentPicture = this.props.pictures[this.props.pictures.length - 1]
    else
      currentPicture = this.props.pictures[side === 'next' ? indexOfCurrent + 1 : indexOfCurrent - 1]
    this.setState({
      currentPicture
    })
  }

  _renderNav(side = 'previous') {
    const icon = side === 'previous' ? 'chevron-left' : 'chevron-right'

    return this.props.pictures && this.props.pictures[1] &&
    <button className={ `c-issue-pictures-modal-gallery__chevron ${side}` } onClick={ this.nav.bind(this, side) }>
      <FontAwesome name={ icon } size='3x'/>
    </button>

  }

  _renderCurrentPictureInfos() {
    const { currentPicture } = this.state
    return (
      <div className='c-picture-infos'>
        {
          currentPicture.user &&
          <span>{ currentPicture.user.label } ({ currentPicture.user.type }) - </span>
        }
        <span>{ moment(currentPicture.date).format('DD MMMM YYYY') }</span>
      </div>
    )
  }

  rotatePicture() {
    const { currentPicture } = this.state
    if (!currentPicture.meta.rotate)
      currentPicture.meta.rotate = 0
    if (currentPicture.meta.rotate >= 270)
      currentPicture.meta.rotate = 0
    else
      currentPicture.meta.rotate += 90

    this.setState({
      currentPicture
    })

    this.props.updatePicture(currentPicture.meta.rotate, currentPicture, 'rotate')
  }

  setCover() {
    this.props.updatePicture('cover', this.state.currentPicture, 'cover')
  }

  deletePicture() {
    this.props.updatePicture('delete', this.state.currentPicture, 'delete')
    this.setState({
      showDelete: false
    })
    this.props.onHide()
  }

  displayDelete() {
    this.setState({
      showDelete: !this.state.showDelete
    })
  }

  printPicture() {
    printIssuePicture(this.props.currentPicture, window)
  }

  render() {
    const { pictures, title, show, onHide, onEnter } = this.props
    const { currentPicture } = this.state

    return (
      <Modal
        show={ show }
        onHide={ onHide }
        onEnter={ onEnter }
        aria-labelledby='contained-modal-title-lg'
        bsSize="large" className='modal-helper'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-lg'>
            <div className='o-flex-layout o-flex-layout--middle o-flex-layout--space-between'>
              <div>{ title }</div>
              <div className='c-pictures-modal__actions'>
                <IssuePicturesModal.Actions showDelete={ this.state.showDelete }
                  isCover={pictures[0].storageId === currentPicture.storageId}
                  rotatePicture={ this.rotatePicture }
                  deletePicture={ this.deletePicture }                   
                  setCover={ this.setCover }
                  displayDelete={ this.displayDelete }
                  printPicture={ this.printPicture }
                />
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className='c-pictures-modal'>
            <div className='c-pictures-modal__list'>
              <div className='c-issue-pictures-modal-list'>
                {
                  pictures.map((picture, index) =>
                    <a key={ index } className={ classNames('c-issue-pictures-modal-list__item', { active: picture._id === currentPicture._id }) } onClick={ this.setCurrentPicture.bind(this, picture) }>
                      <File file={ picture } width={320} height={320} fit={"cover"} />
                      {picture.cover && <span className='c-issue-pictures-modal-list__check'><FontAwesome name='check'/></span>}
                    </a>
                  )
                }
              </div>
            </div>


            <div className='c-pictures-modal__images'>
              <IssuePicturesModal.Gallery
                currentPicture={ currentPicture }
                renderNav={ this._renderNav }
                renderCurrentPictureInfos={ this._renderCurrentPictureInfos }
              />
            </div>

          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

IssuePicturesModal.Actions = ({ showDelete, rotatePicture, deletePicture, isCover, setCover, displayDelete, printPicture }) =>
  <div className='o-flex-layout o-flex-layout--flex-end u-padding-right'>
    <span className='u-margin-right'>
      <button className='c-button u-margin-left-tiny' onClick={ rotatePicture }>
        <FontAwesome name='repeat'/>&nbsp;
        {T.translate('rotate_picture')}
      </button>
      <button
        className='c-button u-margin-left-tiny'
        disabled={ isCover }
        onClick={ setCover }
      >
        {
          isCover ?
            <span>
              <FontAwesome name='check'/>&nbsp;
              <T.span text='issue_cover'/>
            </span>
            :
            <T.span text='set_issue_cover'/>
        }
      </button>
      <button className='c-button u-margin-left-tiny' id='print-issue-picture' onClick={ printPicture }>
        <FontAwesome name='print'/>&nbsp;
        { T.translate('print') }
      </button>
    </span>
    <span className=''>
      {
        showDelete ?
          <button className='c-button c-button--error' onClick={ deletePicture }>
            {T.translate('confirm_delete_picture')}
          </button>
          :
          <button className='c-button' onClick={ displayDelete }>
            <FontAwesome name='trash'/>&nbsp;
            {T.translate('delete_issue_picture')}
          </button>
      }
      {
        showDelete &&
        <a className='c-button u-margin-left-tiny' onClick={ displayDelete }>
          {T.translate('cancel')}
        </a>
      }
    </span>
  </div>


IssuePicturesModal.Gallery = function ({ currentPicture, renderCurrentPictureInfos, renderNav }) {
  return <div className='o-flex-layout o-flex-layout--middle c-issue-pictures-modal-gallery'>
    { renderNav('previous') }
    <div className='o-flex-layout__item o-flex-layout__item--fill u-text-align-center c-issue-pictures-modal-gallery__container'>
      <div>
        { currentPicture ? <File file={ currentPicture } width={1280} height={1024} fit={"contain"} format="png"/> : null }
        <div className='c-issue-pictures-modal-picture__body'>
          { renderCurrentPictureInfos() }
        </div>
      </div>
    </div>
    { renderNav('next') }
  </div>
}

export default IssuePicturesModal
