// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-planning-header-main{
  background: #e2e2e2;
  padding: 6px;
  font-weight: bold;
  border: 1px solid #cecbc8;
  border-bottom-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bs-planning-header-secondary{
  border-bottom: 1px solid #8b8b8b;
  border-left: 1px solid #cecbc8;
  border-right: 1px solid #cecbc8;
  font-size: 14.5px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: bold;
}
.bs-planning-header-secondary>*:nth-child(n + 2),
.bs-planning-header-main>*:nth-child(n + 2) {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/planning/header.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,gCAAgC;EAChC,8BAA8B;EAC9B,+BAA+B;EAC/B,iBAAiB;EACjB,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;AACnB;AACA;;EAEE,gBAAgB;AAClB","sourcesContent":[".bs-planning-header-main{\n  background: #e2e2e2;\n  padding: 6px;\n  font-weight: bold;\n  border: 1px solid #cecbc8;\n  border-bottom-style: none;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.bs-planning-header-secondary{\n  border-bottom: 1px solid #8b8b8b;\n  border-left: 1px solid #cecbc8;\n  border-right: 1px solid #cecbc8;\n  font-size: 14.5px;\n  padding: 5px 10px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  font-weight: bold;\n}\n.bs-planning-header-secondary>*:nth-child(n + 2),\n.bs-planning-header-main>*:nth-child(n + 2) {\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
