import React from 'react';
import Application from '@uBehaviour/application';
import Form from '@uBehaviour/form';
import Field from '@cComponents/field';
import Button from '@cComponents/button';
import Input from '@cComponents/input';
import T from '@uBehaviour/i18n';

import './form.css';

export default Application.Service.forward(["api"], props => { 
  const formRef = React.createRef();
  const [config, setConfig] = React.useState(null);
  
  React.useEffect(() => {
    if (!config) {
      props.api.service("configuration", "get").execute().then(config => setConfig(config));
    }
  });

  return config && (
    <div className="bs-form-configuration-container">
      <Field.ShortLabelContext prefix="configuration_form">
        <Form.Simple 
          ref={formRef}
          value={config}
          submit={(form, value) => props.api.service("configuration", "put").execute(value)}
        >
          <Field.Short name="adminMobileVersion" required><Input.Text /></Field.Short>
          <Field.Short name="citizenMobileVersion" required><Input.Text /></Field.Short>
          <Field.Short name="cguVersion" required><Input.Text /></Field.Short>
        </Form.Simple>
      </Field.ShortLabelContext>
      <div>
        <Button.Text onClick={() => formRef.current.submit()}><T>save</T></Button.Text>
      </div>
    </div>    
  )
});