import React, { FunctionComponent } from 'react';
import Display from './display';

import State from '@uTypes/business/State';
import Text, { Style } from '@cComponents/text';
import T from '@uBehaviour/i18n';

interface ShortProps {
  state: State;
  withText?: boolean;
};

const Short: FunctionComponent<ShortProps> = ({ state, withText = false }) => (
  <Display type="short" color={state.color} height={10} width={62}>
  { 
    withText
      ? <Text style={ Style.tiny }><T>{ state.i18n }</T></Text>
      : null
  }
  </Display>
);

export default Short;