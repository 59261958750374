import * as IssuesActions from 'client/features/issues/actions'
import * as HomeActions from 'client/features/home/actions'
import * as CalendarActions from 'client/features/calendar/actions'
import * as NotificationsActions from 'client/features/notifications/actions'
import { getIssueFromAll } from 'client/features/issues/lib'
import _ from 'lodash'
import { cloneDeep } from 'lodash'
import { List } from 'immutable'
import moment from "moment";

import { registerDefaultReducer, registerInitialstate } from "client/lib/reduxSaga";

export const initialState = {
  all: [],
  list: [],
  assignments: [],
  count: 0,
  forceCount: null,
  listScrollPosition: 0,
  countWithoutFilters: null,
  showNewIssueMasterErrorModal: false,
  showUpdateRecurrenceModal: false,
  currentRecurrencePreview: null,
  currentRecurrenceUpdate: null,
  newIssueUploadFileInProgress: false,
  issuesInMapBounds: null,
  loading: false,
  newInProgress: false,
  loaded: false,
  current: null,
  currentNear: null,
  currentLoading: false,
  CSVExportLoading: false,
  allLoaded: false,
  autocomplete: [],
  uploads: [],
  serviceUploads: [],
  confirmations: {},
  lastIssueUpdated: null,
  fetchOneError: null,
  alerts: [],
  filters: {
    fields: [],
    values: {
      type: []
    },
    hasMore: false
  },
  errors: {},
  selectActive: false,
  selectedIssuesInList: [],
  selectMode: 'selector',
  issuesUpdateInProgress: false
}

const initializeState = () => ({ ...initialState })


registerInitialstate("issues", initializeState);
registerDefaultReducer("issues", function issues(state = initializeState(), action = {}) {
  let { all, currentNear, count, list } = state
  const { payload } = action || {}
  const { options } = payload || {}
  switch (action.type) {

  case "clear_current_issue_on_detail_close":
    state = { ...state };
    state.current = null;
    return state;
  case IssuesActions.UPDATE: 
    const issue = all.find(issue => issue._id === action.updater._id);
    if(issue){
      state = { ...state };
      Object.assign(issue.planification, action.updater);
      issue.lastUpdated = moment().toISOString();
      state.lastIssueUpdated = (new Date()).getTime();
    }
    return state;

  case IssuesActions.REQUEST_ISSUES:
    return {
      ...state,
      list: options.refresh ? [] : state.list,
      filters: {
        ...state.filters,
        sort: options.sort || state.filters.sort
      }
    }

  case IssuesActions.SET_CURRENT_ISSUE:
    return {
      ...state,
      current: action._id,
      showUpdateRecurrenceModal: action._id !== state.current ? false : state.showUpdateRecurrenceModal
    }

    /*case IssuesActions.ISSUE_SET_VALUES:
        return {
            ...state,
            all: setIssueValue(state.all, payload),
            lastIssueUpdated: new Date()
        }
        */
  case IssuesActions.DISPLAY_NEAR_ISSUE:
    currentNear === action._id || !action._id ? currentNear = null : currentNear = action._id
    return {
      ...state,
      currentNear
    }

  case IssuesActions.IssueStarterRequest.get.TYPES.REQUEST:
  case IssuesActions.IssueRequest.get.TYPES.REQUEST:
    return {
      ...state,
      currentLoading: true,
      fetchOneError: null
    }

  case IssuesActions.IssueRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      currentLoading: false,
      all: addIssues(state.all, [payload.issue, ...payload.nears || [], ...payload.groupIssues || []]),
      fetchOneError: null
    }

  case IssuesActions.IssueRequest.get.TYPES.FAILURE:
    return {
      ...state,
      fetchOneError: payload.error
    }

  case IssuesActions.IssueStarterRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      currentLoading: false,
      all: addIssues(state.all, [payload.issue])
    }

  case IssuesActions.IssueStarterRequest.get.TYPES.FAILURE:
    return {
      ...state,
      errors: {
        ...state.errors,
        fetchStater: payload.error
      }
    }

  case IssuesActions.IssueRequest.get.TYPES.FAILURE:
    return {
      ...state,
      currentLoading: false
    }

    /*case IssuesActions.IssueRequest.put.ACTION:
        const issue = { ...getIssueFromAll(state.all, action.params.id), ...payload }
        return {
            ...state,
            all: addIssues(state.all, [issue])
        }
    */
    /*
    case IssuesActions.IssueRequest.put.TYPES.PROGRESS:
        return {
            ...state
        }
*/
  case IssuesActions.IssueRequest.put.TYPES.SUCCESS:
    return {
      ...state,
      all: updateIssue(state.all, payload),
      lastIssueUpdated: new Date().getTime()
    }

  case IssuesActions.ADD_ISSUE_CONFIRMATION:
    return {
      ...state,
      confirmations: addConfirmation(state.confirmations, payload)
    }

  case IssuesActions.REMOVE_ISSUE_CONFIRMATION:
    return {
      ...state,
      confirmations: removeConfirmation(state.confirmations, payload)
    }

  case IssuesActions.SET_ISSUES_HOT_BOUNDS_FILTERS:
    const listData = manageHotBoundsFilters(state, payload)
    return {
      ...state,
      list: listData,
      count: listData.length,
      allLoaded: true
    }

  case IssuesActions.IssuesRequest.get.ACTION:
    return {
      ...state,
      allLoaded: false,
      filters: {
        ...state.filters,
        hasMore: false,
        loading: true
      }
    }

  case IssuesActions.IssuesRequest.get.TYPES.REQUEST:
    return {
      ...state,
      filters: {
        ...state.filters,
        hasMore: false,
        loading: true
      }
    }

  case IssuesActions.IssuesRequest.post.TYPES.REQUEST:
    return {
      ...state,
      newInProgress: true,
      newIssueError: null,
      showNewIssueMasterErrorModal: false
    }

  case IssuesActions.IssuesRequest.post.TYPES.FAILURE:
    return {
      ...state,
      newInProgress: false,
      newIssueError: action.payload.error,
      showNewIssueMasterErrorModal:
                action.payload.error === 'new_issue_slaves_attribution_failed_no_entity_found'
                || action.payload.error === 'new_issue_slaves_attribution_failed_multiple_entities_found'
    }

  case IssuesActions.IssuesRequest.post.TYPES.SUCCESS:
    return {
      ...state,
      newInProgress: false,
      all: addIssues(state.all, [payload.issue]),
      current: payload.live ? state.current : payload.issue._id,
      newIssueError: null,
      showNewIssueMasterErrorModal: false,
      lastIssueUpdated: new Date().getTime()
    }

  case IssuesActions.IssuesRequest.get.TYPES.SUCCESS:
    all = addIssues(state.all, payload.issues)
    list = addIssuesId(state.list, payload.issues)
    count = payload.count >= 0 ? payload.count : state.count
    return {
      ...state,
      count,
      countWithoutFilters: payload.countWithoutFilters || null,
      all,
      list,
      loaded: true,
      allLoaded: count === list.length,
      filters: {
        ...state.filters,
        hasMore: true,
        loading: false
      }
    }

  case IssuesActions.ONLY_SEARCH_FILTERS_VALUE:
    return {
      ...state,
      list: [],
      filters: {
        ...state.filters,
        sort: 'created',
        values: {
          type: null,
          search: state.filters.values.search,
          freeSearch: state.filters.values.freeSearch
        }
      }
    }

  case IssuesActions.IssuesRequest.get.TYPES.FAILURE:
    return {
      ...state,
      all: []
    }


  case IssuesActions.IssuesRequest.put.ACTION:
    return {
      ...state,
      issuesUpdateInProgress: action.actionData && action.actionData.loader ? true : false
    }

  case IssuesActions.IssuesRequest.put.TYPES.SUCCESS:
    const newState = {};
    if (payload.issue){
      newState.all = addIssues(state.all, [payload.issue]);
    }
    return { 
      ...state,
      ...newState,
      issuesUpdateInProgress: false
    };

  case IssuesActions.IssueMatchFilters.get.TYPES.SUCCESS:
    const data = matchFilters(all, list, state.count, state.selectedIssuesInList, payload, payload.actionData.checkIssues)
    return {
      ...state,
      all: data.all,
      list: data.list,
      count: data.count,
      issuesUpdateInProgress: false,
      selectedIssuesInList: data.selectedIssuesInList,
      lastIssueUpdated: new Date().getTime()
    }
  
  case "SET_ASSIGNMENTS_FILTERS_VALUE":
  case IssuesActions.SET_ISSUES_FILTERS_VALUE:
    return {
      ...state,
      list: [],
      filters: {
        ...state.filters,
        loading: true,
        values: setFiltersValues(state.filters.values, payload.key, payload.value, payload.uniq, payload.inputKey),
        searchValue: payload.key === 'search' && payload.searchValue ? payload.searchValue : state.filters.searchValue
      },
      selectedIssuesInList: [],
      selectMode: 'selector'
    }

  case IssuesActions.RESET_ISSUES_FILTERS_VALUES:
    return {
      ...state,
      list: [],
      filters: {
        ...state.filters,
        searchValue: '',
        values: {
          type: null
        },
        forceSearchChange: new Date().getTime()
      }
    }

  case IssuesActions.RESET_ISSUES_FILTERS_SEARCH:
    return {
      ...state,
      list: [],
      filters: {
        ...state.filters,
        searchValue: '',
        values: {
          ...state.filters.values,
          search: null,
          freeSearch: null
        },
        forceSearchChange: new Date().getTime()
      },
      autocomplete: []
    }

  case IssuesActions.LOAD_ISSUES_FILTERS:
    return {
      ...state,
      list: [],
      filters: {
        ...state.filters,
        searchValue: payload.values && payload.values.search ? state.filters.searchValue : '',
        values: cloneDeep(payload.values),
        forceSearchChange: payload.values && payload.values.search ? state.filters.forceSearchChange : new Date().getTime()
      }
    }

  case IssuesActions.UNSET_ISSUES_FILTER_VALUE:
    return {
      ...state,
      list: [],
      filters: {
        ...state.filters,
        values: unsetFilter(state.filters.values, payload.key, payload.uniq, payload.kill)
      }
    }

  case IssuesActions.IssuesAutocompleteRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      autocomplete: payload.result
    }

  case IssuesActions.SET_ISSUES_SEARCH_VALUE:
  case IssuesActions.IssuesAutocompleteRequest.get.ACTION:
    return {
      ...state,
      filters: {
        ...state.filters,
        values: {
          ...state.filters.values,
          search: null,
          freeSearch: null
        },
        searchValue: payload.value,
        forceSearchChange: payload.force ? new Date().getTime() : null
      },
      autocomplete: []
    }

  case CalendarActions.IssuesRequest.get.TYPES.SUCCESS:
  case HomeActions.HomeRequest.get.TYPES.SUCCESS:
  case HomeActions.HomeLastIssuesRequest.get.TYPES.SUCCESS:
  case HomeActions.HomeDeadlineIssuesRequest.get.TYPES.SUCCESS:
  case HomeActions.HomeDeadlineInNextWeekIssuesRequest.get.TYPES.SUCCESS:
  case HomeActions.HomePlannedTodayIssuesRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      all: addIssues(state.all, payload.all || payload.issues)
    }

  case HomeActions.HomeLogsRequest.get.TYPES.SUCCESS:
    return {
      ...state, all: addIssuesFromLogs(state.all, payload.logs)
    }

  case IssuesActions.NewIssueFilesRequest.post.TYPES.REQUEST:
    return {
      ...state,
      newIssueUploadFileInProgress: true
    }
  case IssuesActions.NewIssueFilesRequest.post.TYPES.ERROR:
    return {
      ...state,
      newIssueUploadFileInProgress: false
    }

  case IssuesActions.NewIssueFilesRequest.post.TYPES.SUCCESS:
    return {
      ...state,
      newIssueUploadFileInProgress: false,
      uploads: manageUpload(state.uploads, { percent: 100, progress: 100, callId: payload.files[0].tempId })
    }

  case IssuesActions.NewIssueFilesRequest.post.TYPES.PROGRESS:
    return {
      ...state,
      uploads: manageUpload(state.uploads, payload)
    }

  case IssuesActions.ServiceFilesRequest.post.TYPES.REQUEST:
    return {
      ...state,
      newIssueUploadFileInProgress: true
    }
  case IssuesActions.ServiceFilesRequest.post.TYPES.ERROR:
    return {
      ...state,
      newIssueUploadFileInProgress: false
    }

  case IssuesActions.ServiceFilesRequest.post.TYPES.SUCCESS:
    return {
      ...state,
      newIssueUploadFileInProgress: false,
      serviceUploads: manageUpload(state.serviceUploads, { percent: 100, progress: 100, callId: payload.files[0].tempId })
    }

  case IssuesActions.ServiceFilesRequest.post.TYPES.PROGRESS:
    return {
      ...state,
      serviceUploads: manageUpload(state.serviceUploads, payload)
    }

  case IssuesActions.NEW_ISSUE_SHOW_LOADER:
    return {
      ...state,
      newInProgress: true
    }

  case IssuesActions.NearsRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      all: addIssues(state.all, payload.issues)
    }

  case IssuesActions.ISSUES_LIST_SCROLL_POSITION:
    return {
      ...state,
      listScrollPosition: action.position
    }

  case IssuesActions.HIDE_NEW_ISSUE_MASTER_ERROR_ATTRIBUTION:
    return {
      ...state,
      showNewIssueMasterErrorModal: false
    }

  case IssuesActions.RecurrencePreviewRequest.post.TYPES.SUCCESS:
    return {
      ...state,
      currentRecurrencePreview: action.payload.recurrence
    }

  case IssuesActions.RecurrenceRequest.delete.TYPES.SUCCESS:
    return {
      ...state,
      all: removeRecurrence(state.all, payload)
    }

  case IssuesActions.DISPLAY_UPDATE_RECURRENCE_MODAL:
    return {
      ...state,
      showUpdateRecurrenceModal: !state.showUpdateRecurrenceModal,
      currentRecurrenceUpdate: payload && payload.type ? payload.type : null
    }

  case IssuesActions.IssuesExportCSVRequest.get.TYPES.REQUEST:
    return {
      ...state,
      CSVExportLoading: true
    }

  case IssuesActions.IssuesExportCSVRequest.get.TYPES.FAILURE:
  case IssuesActions.IssuesExportCSVRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      CSVExportLoading: false
    }

  case IssuesActions.RESET_NEW_ISSUE_FORM:
    return {
      ...state,
      resetForm: new Date().getTime()
    }

  case NotificationsActions.NotificationRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      all: payload.notification.issue ? addIssues(state.all, [payload.notification.issue]) : [...state.all]
    }
  case NotificationsActions.NotificationsRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      all: addIssues(state.all, payload.notifications.filter(notification => notification && notification.issue).map(notification => notification.issue))
    }

  case IssuesActions.ISSUES_LIST_ACTIVE_SELECT:
    return {
      ...state,
      selectActive: !state.selectActive,
      selectMode: 'selector',
      selectedIssuesInList: []
    }

  case IssuesActions.ISSUES_LIST_SELECT_ISSUE:
    return {
      ...state,
      selectedIssuesInList: manageSelectedIssue(state.selectedIssuesInList, action.payload)
    }

  case IssuesActions.ISSUES_LIST_UNSELECT_ISSUES:
    return {
      ...state,
      selectMode: 'selector',
      selectedIssuesInList: []
    }

  case IssuesActions.ISSUES_LIST_SELECT_ALL_ISSUES:
    return {
      ...state,
      selectMode: 'all',
      selectedIssuesInList: [],
      unselectedIssuesInList: []
    }

  case IssuesActions.ADD_ISSUE_ALERT:
    return {
      ...state,
      alerts: addAlert(state.alerts, action.payload)
    }

  case IssuesActions.REMOVE_ISSUE_ALERT:
    return {
      ...state,
      alerts: removeAlert(state.alerts, action.payload)
    }

  case IssuesActions.CLEAR_ISSUES:
    return {
      ...state,
      list: [],
      all: []
    }

  case IssuesActions.FORCE_ISSUES_COUNT:
    return {
      ...state,
      forceCount: Number(action.payload.count) ? action.payload.count : null
    }

  case IssuesActions.ISSUES_IN_MAP_BOUNDS:
    return {
      ...state,
      selectMode: 'selector',
      selectedIssuesInList: [],
      issuesInMapBounds: action.payload.issues
    }
  case IssuesActions.GO_TO_ISSUE:
    if(action.payload.issue.issue){
      state = {
        ...state,
        lastAssignment: action.payload.issue._id
      };
    }
    return state;
  case IssuesActions.CLOSE_ISSUE:
    if(state.lastAssignment){
      return {
        ...state,
        lastAssignment: null
      };
    }
    return state;
  default:
    return state
  }
});

const manageSelectedIssue = (selectedIssuesInList, payload) => {
  selectedIssuesInList = cloneDeep(selectedIssuesInList)
  const indexOfIssue = selectedIssuesInList.indexOf(payload.issue)
  if (indexOfIssue === -1)
    selectedIssuesInList.push(payload.issue)
  else
    selectedIssuesInList.splice(indexOfIssue, 1)
  return selectedIssuesInList
}

const addIssuesId = (store = [], issues) => {
  issues.forEach((issue) => {
    if (store.indexOf(issue._id) === -1)
      store.push(issue._id)
  })
  return store.slice(0)
}

const addIssues = (all = [], issues) => {
  if(!issues || issues.assignments) return [];
  issues.forEach((issue) => {
    const indexOfIssue = all.findIndex(item => item._id === issue._id)
    if (indexOfIssue >= 0) {
      if (all[indexOfIssue].logs && !issue.logs)
        issue.logs = cloneDeep(all[indexOfIssue].logs)
      if (all[indexOfIssue].valorizations && all[indexOfIssue].valorizations.items[0] && (!issue.valorizations || !issue.valorizations.items[0]))
        issue.valorizations = cloneDeep(all[indexOfIssue].valorizations)
      all[indexOfIssue] = { ...all[indexOfIssue], ...issue }
    }
    else all.push(issue)
  })
  return all.slice(0)
}

const setIssueValue = (all = [], payload) => {
  const issue = _.find(all, { _id: payload.issue })
  for (const i in payload.values) {
    issue[i] = payload.values[i]
  }
  return cloneDeep(all)
}

const manageHotBoundsFilters = (state, payload) => {
  let { list } = state


  function inBounds(point, bounds) {
    const lng = (point.lng - bounds._ne.lng) * (point.lng - bounds._sw.lng) < 0
    const lat = (point.lat - bounds._ne.lat) * (point.lat - bounds._sw.lat) < 0
    return lng && lat
  }

  list = [...state.all].filter((issue) => {
    const lat = issue.building ? issue.building.latitude : issue.latitude
    const lng = issue.building ? issue.building.longitude : issue.longitude
    return inBounds({ lat, lng }, payload.bounds)
  })

  list = list.map((issue) => issue._id)

  return cloneDeep(list)
}

export const setFiltersValues = (values = {}, key, value, uniq, inputKey) => {
  values = { ...values }
  if (uniq) {
    if (inputKey) {
      if (!values[key]) {
        values[key] = {}
      } else {
        values[key] = values[key] !== "custom" ? { ...values[key] } : { type: "custom" }
      }        
      values[key][inputKey] = value
    } else {
      if (values[key] === value && key !== 'freeSearch')
        values = _.omit(values, key)
      else
        values[key] = value
    }
  } else {
    if (!values[key])
      values[key] = []
    else
      values[key] = [...values[key]]
    const indexOfValue = values[key].indexOf(value)
    if (indexOfValue === -1)
      values[key].push(value)
    else
      values[key].splice(indexOfValue, 1)
  }
  if (key === 'search')
    delete values.freeSearch
  if (key === 'freeSearch')
    delete values.search

  return values
}

const addIssuesFromLogs = (all = [], logs) => {
  logs.forEach((log) => {
    if (log.issue)
      addIssues(all, [log.issue])
  })
  return cloneDeep(all)
}

export const unsetFilter = (values = {}, key, uniq, kill) => {
  values = { ...values }
  if (!uniq)
    values[key] = []
  else {
    delete values[key]
    delete values[kill]
  }
  return { ...values }
}

const manageUpload = (uploads, payload) => {
  uploads = cloneDeep(uploads)
  const existingIndex = _.findLastIndex(uploads, { callId: payload.callId })
  if (existingIndex >= 0)
    uploads[existingIndex] = { ...uploads[existingIndex], progress: payload.percent, percent: payload.percent }
  else
    uploads.push(payload)
  return cloneDeep(uploads)
}

const updateIssue = (all = [], payload = { issue: {} }) => {
  const indexOfIssue = _.findLastIndex(all, { _id: payload.issue._id })
  if (indexOfIssue === -1)
    return [...all]
  all[indexOfIssue] = { ...all[indexOfIssue], ...payload.issue }
  if (payload.logs) {
    const logs = payload.logs
    for (const date in logs) {
      if (all[indexOfIssue].logs && all[indexOfIssue].logs[date]) {
        logs[date].forEach((newLog) => {
          const indexOfNewLog = _.findLastIndex(all[indexOfIssue].logs[date], { _id: newLog._id })
          newLog.newLog = true
          if (indexOfNewLog === -1 && !newLog.removed)
            all[indexOfIssue].logs[date].push(newLog)
          else {
            if (newLog.removed) {
              all[indexOfIssue].logs[date].splice(indexOfNewLog, 1)
              if (!all[indexOfIssue].logs[date][0])
                delete all[indexOfIssue].logs[date]
            } else
              all[indexOfIssue].logs[date][indexOfNewLog] = newLog
          }
        })
      } else if (all[indexOfIssue].logs)
        all[indexOfIssue].logs[date] = logs[date]
    }
    //all[indexOfIssue].logs = [...all[indexOfIssue].logs]
  }
  if (payload.issue.group && payload.issue.group && payload.type === 'state') {
    payload.issue.group.issues.forEach((link) => {
      const linkIssueIndex = _.findLastIndex(all, { _id: link._id })
      if (linkIssueIndex !== -1) {
        const issue = all[linkIssueIndex]
        if (issue.group) {
          issue.group.issues.forEach((duplicate) => {
            if (duplicate._id === payload.issue._id)
              duplicate.state = payload.issue.state
          })
          all[linkIssueIndex] = { ...issue }
        }
      }
    })
  }
  if (payload.data && payload.data.group) {
    payload.data.group.issues.forEach((groupIssue) => {
      const groupIssueIndex = _.findLastIndex(all, { _id: groupIssue._id })
      if (groupIssueIndex !== -1)
        all[groupIssueIndex].group = { ...payload.data.group }
    })
  }

  if (payload.nears)
    all = addIssues(all, payload.nears)

  return cloneDeep(all)
}

const matchFilters = (all, list, count, selectedIssuesInList, payload, issuesToCheck = []) => {
  issuesToCheck.forEach((issueToCheck) => {
    const indexOfIssueInAll = _.findLastIndex(all, { _id: issueToCheck })
    const indexOfIssueInList = list.indexOf(issueToCheck)
    const issueInPayload = payload.issues.find(payloadIssue => payloadIssue._id === issueToCheck)
    if (payload.issues && issueInPayload) {
      if (indexOfIssueInAll === -1)
        all.push(cloneDeep(issueInPayload))
      else if (issueInPayload) {
        const nears = all[indexOfIssueInAll].nears || []
        all[indexOfIssueInAll] = { ...issueInPayload, nears }
      }

      if (indexOfIssueInList === -1) {
        list.push(issueInPayload._id)
        count += 1
      }
    } else if (indexOfIssueInList !== -1 && _.isNumber(indexOfIssueInList)) {
      list.splice(indexOfIssueInList, 1)
      count -= 1
      const indexOfIssueInSelected = selectedIssuesInList.indexOf(issueToCheck)
      if (indexOfIssueInSelected !== -1) {
        selectedIssuesInList.splice(indexOfIssueInSelected, 1)
      }
    }
  })
  return {
    all: cloneDeep(all),
    list: cloneDeep(list),
    selectedIssuesInList: [...selectedIssuesInList],
    count
  }
}

const addConfirmation = (confirmations = {}, payload) => {
  if (!confirmations[payload.issue])
    confirmations[payload.issue] = {}
  confirmations[payload.issue] = { ...confirmations[payload.issue], [payload.type]: payload }
  return { ...confirmations }
}

const removeConfirmation = (confirmations = {}, payload) => {
  if (confirmations[payload.issue]
    && confirmations[payload.issue][payload.type]
    && (!confirmations[payload.issue][payload.type].date || confirmations[payload.issue][payload.type].date === payload.date)) {
    confirmations[payload.issue] = { ...confirmations[payload.issue], [payload.type]: null }
    delete confirmations[payload.issue][payload.type]
  }
  return { ...confirmations }
}

const removeRecurrence = (all, payload) => {
  all.forEach((issue) => {
    if (issue.recurrence && issue.recurrence._id === payload.recurrence._id)
      issue.recurrence = payload.recurrence
  })
  return cloneDeep(all)
}

const addAlert = (alerts, payload) => [...alerts, payload]

const removeAlert = (alerts, payload) => {
  if (!payload || !payload._id)
    return []
  return cloneDeep(alerts).filter(alert => alert._id !== payload._id)
}