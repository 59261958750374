import React from "react";

const createTunnel = () => {
  const Context = React.createContext();

  class Manager extends React.Component {    
    constructor(props){
      super(props);
      this._value = { manager: this };
      this.state = { data: {}};
    }
    transmit(data){
      this._value = { manager: this };
      this.setState({ data });
    }
    clear(){
      this._value = { manager: this };
      this.setState({ data: {} });
    }
    get(){
      return this.state.data;
    }
    render() {
      return React.createElement(Context.Provider, { value: this._value }, this.props.children);
    }
  };

  class Receiver extends React.Component {
    constructor(props){
      super(props);
      this.clear = this.clear.bind(this);
    }
    clear(){
      this.context.manager.clear();
    }
    get(){
      return this.context ? this.context.manager.get() : {};
    }
    render(){
      return this.props.children(this.get(), this.clear);
    }
  };
  Receiver.contextType = Context;

  class Emitter extends React.Component {
    constructor(props){
      super(props);
      this.transmit = this.transmit.bind(this);
    }
    transmit(data){
      this.context.manager.transmit(data);
    }
    render(){
      return this.props.children(this.transmit);
    }
  };
  Emitter.contextType = Context;

  return {
    Manager,
    Receiver,
    Emitter
  };
}

export default createTunnel;
