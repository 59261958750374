import { FunctionComponent } from "react";

import "./genially.css";

type GeniallyProps = {
  id: string
};

const Genially: FunctionComponent<GeniallyProps> = ({ id }) => {
  return (
    <iframe
      className="bs-genially-iframe"
      title="Betterstreet"
      src={ `https://view.genially.com/${id}` }
      type="text/html"
      allowfullscreen="true" 
    />
  );
}

export default Genially;
