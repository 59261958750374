import React    from "react";
import Slot     from "@uComponents/slot";
import moment   from "moment";
import Timeline from "@uLib/timeline";

import "./planning.css";

const Event = Slot();
const Empty = Slot();

const EmptyItem = ({ }) => (<div>&nbsp;</div>);

const dateIterator = (start, end) => {
  const iterator = {};
  iterator[Symbol.iterator] = function*(){
    while(start.isBefore(end)){
      yield start.clone();
      start = start.clone().add(1, "d");
    }
  };
  return iterator;
}

const Planning = ({ start, end, withEmptyLine, children, tableClassName, bodyClassName, rowClassName, cellClassName }) => {
  start = moment(start).startOf("day");
  end   = moment(end).startOf("day");
  const slotComponent = { props: { children }};
  const empty   = Empty.get(slotComponent) ? Empty.get(slotComponent) : React.createElement(EmptyItem);//Empty.get(slotComponent, false, React.createElement(EmptyItem));
  const timelines = [];
  const events = Event.props(slotComponent, true);
  events.forEach(({ start: eStart, end: eEnd }) => {
    if(!(end.isAfter(eStart) && start.isBefore(eEnd))){
      throw new Error(`Event not in interval: Interval(${start.toISOString()}->${end.toISOString()}) Event(${moment(eStart).toISOString()}->${moment(eEnd).toISOString()})`)
    }
  });
  const planningLines  = events
    .map(({ start: eStart, end: eEnd, children: item }) => {
      eStart  = moment(eStart).isBefore(start) ? moment(start).clone() : moment(eStart);
      eEnd    = moment(eEnd).isAfter(end)      ? moment(end).clone()   : moment(eEnd);
      return {
        start: eStart,
        end: eEnd,
        duration: eEnd.diff(eStart),
        item
      };
    })
    .sort(({ start: e1Start, duration: e1Duration }, { start: e2Start, duration: e2Duration }) => {
      const diff = e2Duration - e1Duration;
      if(diff){
        return diff;
      }
      return e2Start.diff(e1Start);
    })
    .map(({ start: eStart, end: eEnd, item }) => {
      eStart = eStart.clone().startOf("day");
      eEnd   = eEnd.format("HHmm") !== "0000" ? eEnd.clone().add(1, "d").startOf("day") : eEnd.clone().startOf("day");
      
      let index = 0;
      const eTimeLine = new Timeline([{ start: eStart.toDate(), end: eEnd.toDate() }]);
      for(; index < timelines.length && timelines[index].intersect(eTimeLine).toPeriods().length !== 0; ++index);
      if(index === timelines.length){
        timelines.push(eTimeLine);
      }else{
        timelines[index] = timelines[index].add(eTimeLine);
      }
      return {
        line: index,
        start: eStart,
        end: eEnd,
        item
      };
    }).sort(({ line: e1Line, start: e1Start }, { line: e2Line, start: e2Start }) => {
      const lineDiff = e1Line - e2Line;
      if(lineDiff){
        return lineDiff;
      }
      return e1Start.diff(e2Start);
    }).reduce((acc, event) => {
      if(acc.length === event.line){
        acc.push([]);
      }
      acc[event.line].push(event);
      return acc;
    }, []);
  
  const dates = [...dateIterator(start, end)];
  if(withEmptyLine){
    planningLines.push([]);
  }
  const _tableClassName  = "bs-common-planning"      + (tableClassName ? " " + tableClassName : "");
  const _bodyClassName   = "bs-common-planning-body" + (bodyClassName ? " " + bodyClassName : "");
  const _rowClassName    = "bs-common-planning-line" + (rowClassName ? " " + rowClassName : "");
  const _cellClassName   = "bs-common-planning-cell" + (cellClassName ? " " + cellClassName : "");
  return (
    <table className={ _tableClassName }>
      <tbody className={ _bodyClassName }>
      {
        planningLines.map((events, index) => {
          let current = 0;
          return React.createElement("tr", { key: index, className: _rowClassName }, dates.reduce((acc, date) => {
            if(current < events.length && events[current].end.isSame(date)){
              ++current;
            }
            if(current < events.length && events[current].start.isSame(date)){
              const { start, end, item } = events[current];
              acc.push(React.createElement("td", { key: date.toISOString(), className: _cellClassName, colSpan: end.diff(start, "day") }, item));
            }else if(current >= events.length || date.isBefore(events[current].start)){
              acc.push(React.createElement("td", { key: date.toISOString(), className: _cellClassName, colSpan: 1 }, empty instanceof Function ? empty(date) : React.cloneElement(empty, { date })));
            }
            return acc;
          }, []));
        })
      }
      </tbody>
    </table>
  )
};

Planning.Event = Event;
Planning.Empty = Empty;

export default Planning;