// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-planning-form-absence-confirmDelete,
.bs-planning-form-absence {
  padding: 20px;
}

.bs-planning-form-absence-period-input {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bs-planning-form-absence-period-input>div:last-child {
  margin-left: 20px;
}


.bs-planning-form-absence-reason {
  width: 300px;
}

.bs-planning-form-absence>*{
  margin-bottom: 20px;
}

.bs-absence-form-footer{
  padding: 10px 15px;
  display: flex;
  background-color: #f5f5f5;
  border-top: 1px solid #ccc;
}
.bs-absence-form-footer>div:last-child {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}
.bs-absence-form-footer>div:last-child>button:last-child{
  margin-left: 5px;
}
.bs-planning-form-absence-confirmDelete-body,
.bs-planning-form-absence-confirmDelete-footer{
  margin-top: 20px;
}
.bs-planning-form-absence-confirmDelete-footer{
  display: flex;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/planning/form/absence.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB;;;AAGA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,0BAA0B;AAC5B;AACA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,gBAAgB;AAClB;AACA;;EAEE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC","sourcesContent":[".bs-planning-form-absence-confirmDelete,\n.bs-planning-form-absence {\n  padding: 20px;\n}\n\n.bs-planning-form-absence-period-input {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.bs-planning-form-absence-period-input>div:last-child {\n  margin-left: 20px;\n}\n\n\n.bs-planning-form-absence-reason {\n  width: 300px;\n}\n\n.bs-planning-form-absence>*{\n  margin-bottom: 20px;\n}\n\n.bs-absence-form-footer{\n  padding: 10px 15px;\n  display: flex;\n  background-color: #f5f5f5;\n  border-top: 1px solid #ccc;\n}\n.bs-absence-form-footer>div:last-child {\n  flex-grow: 1;\n  display: flex;\n  justify-content: flex-end;\n}\n.bs-absence-form-footer>div:last-child>button:last-child{\n  margin-left: 5px;\n}\n.bs-planning-form-absence-confirmDelete-body,\n.bs-planning-form-absence-confirmDelete-footer{\n  margin-top: 20px;\n}\n.bs-planning-form-absence-confirmDelete-footer{\n  display: flex;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
