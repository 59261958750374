import React, { PureComponent } from 'react';
import Application from "@uBehaviour/application";
import Textarea from 'client/features/form/old/components/fields/Textarea.jsx';
 

class IssueDetailsDescription extends PureComponent {
  constructor() {
    super()
    this._textarea = React.createRef();
    this.edit = this.edit.bind(this)
    this.onChange = this.onChange.bind(this)
    this.state = {
      edit: false
    }
  }

  edit() {
    const { edit } = this.state
    this.setState({
      edit: !edit
    })
  }

  onChange(value) {
    if (value !== this.props.value)
      this.props.onChange(value)
  }

  render() {
    const {
      value,
      placeholder = 'add_desc',
      confirmation,
      disabled,
      forceEditMode,
      forceEditModeOnMount,
      onCancel,
      mentions,
      noButtons,
      label
    } = this.props
    return (
      <div className='c-issue-details-description'>
        <Textarea
          label={ label }
          validate={ (value) => value === "" || !value ? [this.props.i18n.translate("empty_field_not_allowed")] : [] }
          onChange={ this.onChange }
          value={ value }
          confirmation={ confirmation }
          placeholder={ placeholder }
          disabled={ disabled }
          forceEditMode={ forceEditMode }
          forceEditModeOnMount={ forceEditModeOnMount }
          onCancel={ onCancel }
          mentions={ mentions }
          noButtons={ noButtons }
          ref={ this._textarea }
        />
      </div>
    )
  }
}

export default Application.Service.forward(["i18n"], IssueDetailsDescription);