import React, { PureComponent } from 'react'
import Loader from 'client/features/common/components/Loader.jsx'
import { getRamdomLoaderText } from 'client/features/issues/lib'

export default class Overlay extends PureComponent {
  constructor() {
    super()
    this.randomText = getRamdomLoaderText()
  }

  render() {
    const { loader, text, info, randomText } = this.props
    return (

      <div className='view-overlay' style={ { zIndex: 1000000 } }>
        <div className='flex-container'>
          <div className='aligned no-result text-center'>
            { loader ? <Loader /> : null }
            <div className='u-font-size-super'>
              { text }
              { randomText && this.randomText }
            </div>
            {
              info &&
              <div className='u-font-size-large'>
                { info }
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}