import State from '../State';

export type States = 'to_do' | 'ongoing' | 'finished';

export const StatesConfiguration: Record<States, State> = {
  to_do: { label: 'to_do', color: '#f77448', i18n: 'assignments_state_to_do', assignable: true, updatable: true },
  ongoing: { label: 'ongoing', color: '#396988', i18n: 'assignments_state_ongoing', assignable: true, updatable: true },
  finished: { label: 'finished', color: '#1fcc36', i18n: 'assignments_state_finished', assignable: true, updatable: true }
};

export default StatesConfiguration;