import React, { FunctionComponent } from 'react';
import { Subscribe } from '@cEntities/subscription';
import { NotificationSubscription, UserPro } from '@universal/types/business/User';
import useService from '@universal/behaviour/hooks/useService';
import SessionService from '@universal/services/session';
import T from '@universal/behaviour/i18n';
import Modal from '@common/components/modal';
import Button from '@common/components/button';

import './starterSubscriptions.css';
import useAsyncValidationWithQuestion from '@universal/behaviour/hooks/useAsyncValidationWithQuestion';
import Scrollbar from '@common/components/scrollBar';

interface StarterSubscriptionProps {
  close: () => void;
}

type NewIssuesNoChannelsAlertProps = {
  accept: () => void;
  reject: () => void;
}

const ButtonNegative = Button.withStyle(Button.Stylized.negative);

const NewIssuesNoChannelsAlert: FunctionComponent<NewIssuesNoChannelsAlertProps> = ({ accept, reject }) => (
  <Modal.Show close={ reject } style={{ width: "35vw", height:"35vh" }}>
    {close => (
      <div className="bs-starter-subscription-alert-container">
        <Scrollbar>
          <i className='fa fa-exclamation-triangle fa-3x' />
          <div className="bs-starter-subscription-alert-title">
            <div><T>subscription_starter_alert_title</T></div>
            <div><T>subscription_starter_alert_subtitle</T></div>
          </div>
          <div><T>subscription_starter_alert_infos</T></div>
        </Scrollbar>
        <div className="bs-starter-subscription-alert-buttons-container">
          <Button.Text onClick={ accept }><T>subscription_starter_alert_submit</T></Button.Text>
          <ButtonNegative onClick={ close } ><T>subscription_starter_alert_cancel</T></ButtonNegative>
        </div>
      </div>
    )}
  </Modal.Show>
)

const StarterSubscription: FunctionComponent<StarterSubscriptionProps> = ({ close }) => {
  const session = useService<SessionService>("session");
  const user = session.user as UserPro;

  const [ warningNoNewIssueChannels, checkNewIssueChannels ] = useAsyncValidationWithQuestion<void>(NewIssuesNoChannelsAlert);
  const submit = React.useCallback( async (subscriptions: NotificationSubscription[]) => {
    const asChannelForNewIssues = subscriptions.some(subscription => subscription.name === "onIssueCreatedAsManager" && subscription.channels.length);
    if (!asChannelForNewIssues && !await checkNewIssueChannels()) {
      return Promise.reject();
    }
    session.updateSubscriptions(subscriptions)
      .then(() => close());
  }, [session, close, checkNewIssueChannels]);

  return (
    <>
      <Subscribe value={ user.personalSettings?.subscriptions || [] } submit={ submit }>
        <Subscribe.Group>
          <b><T>subscription_starter_newIssue</T></b>
          <Subscribe.Subscription name="onIssueCreatedAsManager">
            <T>subscription_starter_onIssueCreated</T>
          </Subscribe.Subscription>
          <Subscribe.Subscription name="onCitizenCommentAsManager">
            <T>subscription_onCitizenCommentAsManager</T>
          </Subscribe.Subscription>
        </Subscribe.Group>
        <Subscribe.Group>
          <b><T>subscription_starter_marketing</T></b>
          <Subscribe.Subscription name="marketingCommunications" withPush={ false }>
            <T>subscription_starter_marketingCommunications</T>
          </Subscribe.Subscription>
        </Subscribe.Group>
      </Subscribe>
      { warningNoNewIssueChannels }
    </>
  );
};

export default StarterSubscription;