import React, { FunctionComponent } from "react";
import T                            from "@uBehaviour/i18n";
import Display                      from "@cComponents/displayIf";
import { Highlighted }              from "@cComponents/highlight";
import GroupComponent               from "@entities/issue/group";
import { BusinessEntity }           from "@universal/types/technic/Entityable";
import Category                     from "@universal/types/business/Category";
import Group                        from "@universal/types/business/issue/Group";
import ObjectId                     from "@universal/types/technic/ObjectId";
import Priority                     from "@entities/issueAndAssignment/priority";
import Deadline                     from './deadline';
import { useDataTestId }            from "@universal/features/dataTestId";

import "./title.css";

interface TitleProps {
  title: string;
  category: BusinessEntity<Category>;
  $followed: boolean;
  priority: number;
  group?: BusinessEntity<Group>;
  issueId: ObjectId;
  deadline?: Date | null;
}


const Title: FunctionComponent<TitleProps> = ({ title, category, $followed, priority, group = null, issueId, deadline = null }) => {  
  // Test IDs
  const numberTestId        = `info-${useDataTestId("bsIdNumber")}`;
  const categoryLabelTestId = `info-${useDataTestId("category")}`;
  const followedIconTestId  = `info-${useDataTestId("followed")}`;
  
  return (
    <div className="bs-issue-item-title">
      <div className="bs-issue-item-title-number" data-testid={numberTestId}><Highlighted>{ title + "" }</Highlighted></div>
      <div className="bs-issue-item-title-category" data-testid={categoryLabelTestId}>{ <T>{ category.label }</T> }</div>
      <Display.If condition={ $followed }>
        <span className="fa fa-star" data-testid={followedIconTestId}/>
      </Display.If>
        <Priority priority={priority} />
      <Display.If condition={ deadline }>
        <Deadline value={deadline} />
      </Display.If>
      { group && group.toPlainText().issues && <GroupComponent group={ group } issueId={ issueId }/> }
    </div>
  );
}

export default Title;