// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-select-base{
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}
.bs-select-base-header {
  height: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bs-select-base-header{
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom: 1px solid #cecbc8;
  font-size: 26px;
  font-weight: bold;
  height: 60px;
}
.bs-select-base-header-input-layout{
  font-size: 16px;
  position: relative;
  flex-grow: 1;
  display: flex;
  justify-content: center;
}
.bs-select-base-header-input-layout>span{
  position: relative;
}
.bs-select-base-header-input-layout input{
  width: 300px;
}
.bs-select-base-header-input-layout .fa{
  position: absolute;
  right: 5px;
  top: 4px;
}

.bs-select-base-content{
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.bs-select-base-footer{
  padding: 20px;
  width: 100%;
}

.bs-select-base-footer-control{
  display: flex !important;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./../common/components/select.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,gCAAgC;EAChC,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd;AACA;EACE,eAAe;EACf,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;AACV;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;AACb;;AAEA;EACE,wBAAwB;EACxB,8BAA8B;AAChC","sourcesContent":[".bs-select-base{\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  overflow: hidden;\n}\n.bs-select-base-header {\n  height: 10%;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.bs-select-base-header{\n  padding: 10px;\n  padding-left: 20px;\n  padding-right: 20px;\n  border-bottom: 1px solid #cecbc8;\n  font-size: 26px;\n  font-weight: bold;\n  height: 60px;\n}\n.bs-select-base-header-input-layout{\n  font-size: 16px;\n  position: relative;\n  flex-grow: 1;\n  display: flex;\n  justify-content: center;\n}\n.bs-select-base-header-input-layout>span{\n  position: relative;\n}\n.bs-select-base-header-input-layout input{\n  width: 300px;\n}\n.bs-select-base-header-input-layout .fa{\n  position: absolute;\n  right: 5px;\n  top: 4px;\n}\n\n.bs-select-base-content{\n  flex-grow: 1;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.bs-select-base-footer{\n  padding: 20px;\n  width: 100%;\n}\n\n.bs-select-base-footer-control{\n  display: flex !important;\n  justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
