import React         from "react";
import T             from "@cBehaviour/i18n";
import Filter        from "@cComponents/filter";
import Input         from "@cComponents/input";
import Collapsable   from "@cComponents/collapsable";

const buildQueryState = (values) => {
    const queries = [];
    if(values.includes("inProgress")){
        queries.push({ deleted: false, ended: false });
    }
    if(values.includes("ended")){
        queries.push({ ended: true });
    }
    if(values.includes("deleted")){
        queries.push({ deleted: true });
    }
    return { $or: queries };
}
const stringifyState = (value) => {
 switch(value){
    case "deleted": return (<T>recurrence_filters_deleted</T>);
    case "ended": return (<T>recurrence_filters_ended</T>);
    case "inProgress": return (<T>recurrence_filters_inProgress</T>);
 }
}

const Filters = (props) => (
  <>
    <Collapsable alwaysOpen>
      <Collapsable.Title><T>recurrence_filters_state</T></Collapsable.Title>
      <Collapsable.Content>
        <Filter.Generic multiple name="state" buildQuery={buildQueryState} stringify={stringifyState}>
          {(values, add, drop, clear) => (
            <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
              <Input.Checkbox.Value value={"deleted"}><T>recurrence_filters_deleted</T></Input.Checkbox.Value>
              <Input.Checkbox.Value value={"ended"}><T>recurrence_filters_ended</T></Input.Checkbox.Value>
              <Input.Checkbox.Value value={"inProgress"}><T>recurrence_filters_inProgress</T></Input.Checkbox.Value>
            </Input.Checkbox.BtnFilter>
          )}
        </Filter.Generic>
      </Collapsable.Content>
    </Collapsable>
  </>
);

export default Filters;