import React        from "react";
import _            from "lodash";
import Data         from "@uBehaviour/data";
import Button       from '@cComponents/button';
import MapLayer     from "@entities/mapLayer";
import T            from "@cBehaviour/i18n";
import Filter       from "@cComponents/filter";
import FilteredList from "@cFeatures/filter/list";
import Input        from "@cComponents/input";
import CurrentTenantService from "@universal/services/currentTenant";
import useService from "@universal/behaviour/hooks/useService";
import MapLayerForm from './form';
import { Link } from "@cFeatures/router";
import { withLink, withModalLinked } from "@cHoc/withLinkAndModalLinked";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar from "@cComponents/searchbar";
import { escapeRegExp } from "@uLib/tool";

const query = (currentTenant: CurrentTenantService) => {
  return currentTenant.currentId ? { tenant: currentTenant.currentId } : undefined;
}
const textSearchBuildQuery = (value: string) => {
  return { name:   { '$regex': escapeRegExp(value), '$options': 'i' } } ;
}
const defaultQuery = () => {
  return [];
}

const LinkedMapLayerItem = withLink(MapLayer.Item);
const ModalizedMapLayerForm = withModalLinked(MapLayerForm);


const MapLayerList = () => {
  const currentTenant = useService<CurrentTenantService>('currentTenant');
  return (
    <>
      <FilteredList default={defaultQuery()}>
        <FilteredList.Filter>
          <MapLayer.Filters />
        </FilteredList.Filter>
        <FilteredList.Header>
          <ListHeaderLayout>
            <ListHeaderLayout.Center>
              <Filter.Generic name="text" buildQuery={value => textSearchBuildQuery(value)} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                {(value, set, clear) => (
                  <Searchbar>
                    <Input.Text focusOnMount className="bs-filter-text" value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                      <T>freesearch</T>
                    </Input.Text>
                  </Searchbar>
                )}
              </Filter.Generic>
            </ListHeaderLayout.Center>
            <ListHeaderLayout.Right>
              <Link to="/new">
                <Button.Stylized.Text><T>add</T></Button.Stylized.Text>
              </Link>
            </ListHeaderLayout.Right>
          </ListHeaderLayout>
        </FilteredList.Header>
        <FilteredList.List>
          {composeQuery => (
            <Data.List model={"MapLayer"} query={composeQuery(query(currentTenant))} sort={{ 'name': 1}}>
              <LinkedMapLayerItem />
            </Data.List>
          )}
        </FilteredList.List>
      </FilteredList>
      <ModalizedMapLayerForm style={{ width: "40vw", height: "90vh" }}/>
    </>
  );
}

export default MapLayerList;