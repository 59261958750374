import List from "./list";
import Form from "./form"

export {
  List,
  Form
};

export default {
  List,
  Form
};
