import React from "react";
import moment from "moment";
import T from "@uBehaviour/i18n";
import Subject from "@entities/assignmentsAndIssues/subject";
import AssignmentState from "@entities/assignments/state";
import IssueState from "@entities/issue/state";
import { Link } from '@cFeatures/router';
import DragAndDrop from "@cBehaviour/dragAndDrop";
import Tooltip from "@cComponents/tooltip";
import AssignmentTooltip from "@entities/assignments/tooltipContent";
import { Highlighted } from "@cComponents/highlight";


import "./item.css";


const AssignmentItemDecorator = (Item) => ({ assignment, equipment, team, agent }) => {
  return (
    <Link to={ `/issue/${ assignment.issue }`} key={ assignment._id }>
      <DragAndDrop.Draggable type={ equipment ? "AssignmentEquipment" : (agent ? "AssignmentAgent" : "AssignmentTeam") } datas={{ equipment, assignment, team, agent }}>
      {(isDragged) => {
        const item = (<Item assignment={ assignment } />);
        if(isDragged){
          return item;
        }
        return (
          <Tooltip placement="right">
            <Tooltip.Subject>
            { item }
            </Tooltip.Subject>
            <Tooltip.Info>
              <AssignmentTooltip id={ assignment._id } />
            </Tooltip.Info>
          </Tooltip>
        )
      }}
      </DragAndDrop.Draggable>
    </Link>
  );
}

const ItemStandart = AssignmentItemDecorator(({ assignment }) => {
  const hours = React.useMemo(() => {
    const start = moment(assignment.assignment.scheduledFrom).format("HH:mm");
    const end = moment(assignment.assignment.scheduledTo).format("HH:mm");

    const fallback = "00:00";

    const hasHour = start !== fallback || end !== fallback;
    if(!hasHour){
      return null;
    }

    return (
      <div  className="bs-planning-assignment-item-standart-hours">
        <span>{start}</span>
        <span>{end}</span>
      </div>
    );
  }, [assignment.assignment.scheduledFrom.toString(), assignment.assignment.scheduledTo.toString()])
  
  return (
    <div className="bs-planning-assignment-item-container-standart">
      <div className="bs-planning-assignment-item-standart">
        <div className="bs-planning-assignment-item-standart-content">
          <div className="bs-planning-assignment-item-standart-title">
            <div className="bs-planning-assignment-item-standart-title-bsIdNumber">{ assignment.bsIdNumber }-{ assignment.assignment.bsIdNumber }</div>
            <div className="bs-planning-assignment-item-standart-title-category"><T>{ assignment.category.label }</T></div>
          </div>
          <Subject equipment={ assignment.equipment } location={ assignment.location } />
          <div className="bs-planning-assignment-item-standart-description">
            <Highlighted>
              { assignment.description }
            </Highlighted>
          </div>
          {
            hours
          }
        </div>
        <div className="bs-planning-assignment-item-standart-state">
          <div className="bs-planning-assignment-item-standart-state-issue">
            <IssueState.Short state={ assignment.issueState } />
          </div>
          <AssignmentState.SelectCompactCommentAndValorize
            assignment={ assignment.assignment }
            mustBeValorized={ assignment.mustBeValorized }
            issue={ assignment.issue }
            tenant={ assignment.tenant }
          />
        </div>
      </div>
    </div>
  );
});

const ItemSlim = AssignmentItemDecorator(({ assignment }) => (
  <div className="bs-planning-assignment-item-container-slim">
    <div className="bs-planning-assignment-item-slim">
      <div className="bs-planning-assignment-item-slim-bsIdNumber">{ assignment.bsIdNumber }-{ assignment.assignment.bsIdNumber }</div>
      <div className="bs-planning-assignment-item-slim-description"> <Highlighted>{ assignment.description }</Highlighted> </div>
      <div className="bs-planning-assignment-item-slim-state">
        <IssueState.Tiny state={ assignment.issueState } />
      </div>
    </div>
  </div>
));

export default {
  Standart: ItemStandart,
  Slim: ItemSlim
};