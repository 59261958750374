
import React                  from 'react'; 
import Log                    from "@entities/log";
import T                      from "@uBehaviour/i18n";

const TeamLog = (props) => (
  <Log.View subject={ props.id } type="Team" createdAt={(team) => team.createdAt} createdBy={(team) => team.createdBy ? team.createdBy : { firstname:'', lastname:'Inconnu'}} text={(team) => team.name}>
    <Log.View.Rule 
      version={2}
      type={"name"}
      title={() => (<T>team_logs_name</T>)}
      textify={ value => value.name }
    />
    <Log.View.Rule 
      version={2}
      type={"disabled"}
      title={(_old, _new) => _new && _new.disabled ? (<T>team_logs_disabled</T>) : (<T>team_logs_enabled</T>)}
    />
    <Log.View.Rule 
      version={2}
      type={"type"}
      title={(_old, _new) => _new && _new.type === "external" ? (<T>team_logs_type_external</T>) : (<T>team_logs_type_internal</T>)}
    />
    <Log.View.Rule 
      version={2}
      type={"valorization"}
      title={() => (<T>team_logs_valorization</T>)}
      textify={ value => value.valorization / 100 + " €" }
    />    
    <Log.View.Rule
      version={2}
      multiple={true}
      entity="User"
      type={"members"}
      title={(els, add) => {
        return add ? (<T>team_logs_members_add</T>) : (<T>team_logs_members_drop</T>)
      }}
      textify={els => els.map(el => el.fullname).join(", ")}
    />
  </Log.View>
);

export default TeamLog;