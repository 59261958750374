import { fork, select, put, takeLatest, call, all } from 'redux-saga/effects'
import {
  HomeRequest,
  HomeWeatherRequest,
  HomeLogsRequest,
  HomeIssueMatch,
  HomeLastIssuesRequest,
  HomeDeadlineIssuesRequest,
  HomeDeadlineInNextWeekIssuesRequest,
  HomePlannedTodayIssuesRequest
} from 'client/features/home/actions'
import fetch from 'isomorphic-fetch'
import RequestSagas from 'client/core/RequestSagas'
import URLS from 'constants/URLS'
import API_URLS from 'common/API_URLS'
/*Fetch Issue*/
export const getHomeSagas = new RequestSagas(HomeRequest.get, { method: 'GET', endpoint: API_URLS.home.get })

export const getLastIssues = new RequestSagas(HomeLastIssuesRequest.get, { method: 'GET', endpoint: API_URLS.issues.get })
export const getDeadlineIssues = new RequestSagas(HomeDeadlineIssuesRequest.get, { method: 'GET', endpoint: API_URLS.issues.get })
export const getNextWeekDeadlineIssues = new RequestSagas(HomeDeadlineInNextWeekIssuesRequest.get, { method: 'GET', endpoint: API_URLS.issues.get })
export const getPlannedTodayIssues = new RequestSagas(HomePlannedTodayIssuesRequest.get, { method: 'GET', endpoint: API_URLS.issues.get })
/*Fetch Weather*/


export const getHomeWeatherSagas = function *(){
  yield all([
    takeLatest(HomeWeatherRequest.get.ACTION, function *(action){
      try{
        const url = 'https://api.openweathermap.org/data/2.5/forecast/daily?' + Object.keys(action.query).reduce((uriQuery, parameterName) => {
          if(uriQuery.length) uriQuery += "&";
          uriQuery += parameterName + "=" + action.query[parameterName];
          return uriQuery;
        }, "");
        const response = yield call(fetch, url, {
          method: "get",
          mode: 'cors'
        });
        const weather = yield call([response, response.json]);
        yield put({ type: HomeWeatherRequest.get.TYPES.SUCCESS, payload: { weather }});
      }catch(err){
        yield put({ type: HomeWeatherRequest.get.TYPES.FAILURE, error: err });
      }
    }),
    takeLatest(HomeWeatherRequest.get.TYPES.SUCCESS, function *(action){
    }),
    takeLatest(HomeWeatherRequest.get.TYPES.FAILURE, function *(action){
    })
  ])
}

/*Fetch Logs*/
export const getHomeLogsSagas = new RequestSagas(HomeLogsRequest.get, { method: 'GET', endpoint: URLS.home.activities })

/*Check issue update match*/
export const getHomeIssueMatch = new RequestSagas(HomeIssueMatch.get, { method: 'GET', endpoint: URLS.home.get })

export const checkUpdate = function* (action) {
  const filters = yield select((state) => state.home.filters)
  yield put(HomeIssueMatch.get.action({ payload: { issue: action.payload.issue._id, ...filters } }))
}

/*Export watchers sagas*/
const sagas = [
  fork(getHomeSagas.saga),
  fork(getHomeWeatherSagas),
  fork(getHomeLogsSagas.saga),
  fork(getHomeIssueMatch.saga),
  fork(getLastIssues.saga),
  fork(getDeadlineIssues.saga),
  fork(getNextWeekDeadlineIssues.saga),
  fork(getPlannedTodayIssues.saga)
]

export default sagas