import React, { PureComponent } from 'react'
import T from 'i18n-react'
import _ from 'lodash'
import enhanceWithClickOutside from 'react-click-outside'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import FontAwesome from 'react-fontawesome'

import Checkbox from 'client/features/issues/components/filters/Checkbox.jsx'
import FilterHead from 'client/features/issues/components/filters/Head.jsx'
import DateFilter from 'client/features/issues/components/filters/DateFilter.jsx'

class Filter extends PureComponent {
  constructor() {
    super()
    this.displayFilters = this.displayFilters.bind(this)
    this.setFilter = this.setFilter.bind(this)
    this.timer = 0
    this.state = {
      isOpen: false
    }
  }

  componentDidMount() {
    const delay = this.props.field && this.props.field.timer || 18000
    this.interval = setInterval(() => {
      this.timer += 1000
      if (this.timer >= delay && this.state.isOpen)
        this.setState({ isOpen: false })
    }, 1000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  handleClickOutside() {
    if (!this.props.closeOnOutSideClick)
      return
    this.setState({ isOpen: false })
  }

  displayFilters() {
    if (this.props.field.fixed)
      return
    this.timer = 0
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  _renderSubCount(values = [], subValues = []) {
    let count = 0
    values.forEach((value) => {
      if (subValues.indexOf(value) !== -1)
        count += 1
    })

    if (!count)
      return ''
    return `${count}/${subValues.length}`
  }

  setFilter(data) {
    this.timer = 0
    this.props.setFilter(data)
  }

  _renderInputs(field) {
    if (field.inputType === 'checkbox' && !field.subs)
      return field.attributes.map((attribute) => {
        /*Quick bugfix: TODO improve this */
        if (field.key === 'client' && !_.isArray(this.props.values[field.key]))
          this.props.values[field.key] = [this.props.values[field.key]]
        /* */
        if (!this.state.isOpen && !field.fixed && ((!this.props.values[field.key] || (this.props.values[field.key].indexOf(attribute.value) === -1)) || this.props.forceHide)) return
        return (
          <div className='u-margin-top-small c-issues-filter__input' key={ attribute.value || attribute.label }>
            <Checkbox
              key={ attribute.value || attribute.label }
              field={ field } input={ attribute }
              setFilter={ this.setFilter }
              values={ this.props.values[field.key] }
              value/>
          </div>
        )
      })
    else if (field.subs)
      return (field.subs.map((sub) => (
        <div className='c-issues-filter__input u-margin-top-small sub-filters' key={ sub.title }>
          {
            ((this.state.isOpen || field.fixed)) &&
            <div className='c-heading-medium u-margin-bottom-tiny o-flex-layout o-flex-layout--space-between'>
              <span className='o-flex-layout__item'>
                <FontAwesome name={ sub.icon } className={ sub.labelClass }/>&nbsp;
                <T.span text={ sub.title } />
              </span>
              <span className='o-flex-layout__item'>
                {
                  this._renderSubCount(this.props.values[field.key], sub.enums)
                }
              </span>
            </div>
          }
          {
            sub.attributes.map((attribute) => {
              if (!this.state.isOpen && !field.fixed && (!this.props.values[field.key] || (this.props.values[field.key].indexOf(attribute.value) === -1))) return;
              return (
                <Checkbox
                  key={ attribute.value || attribute.label }
                  field={ field }
                  input={ attribute }
                  values={ this.props.values[field.key] }
                  setFilter={ this.setFilter }
                />
              )
            })
          }
        </div>
      )))
    else if (field.inputType === 'customDatePicker') {
      return (
        <div>
          {
            field.customAttributes.map((attribute) => {
              if (!this.state.isOpen && !field.fixed && (!this.props.values[field.key] || (this.props.values[field.key] || this.props.values[field.key].type) !== attribute.value)) return
              return (
                <div key={ attribute.value || attribute.label }>
                  <Checkbox
                    field={ field }
                    input={ attribute }
                    uniq={ true }
                    values={ this.props.values[field.key] }
                    setFilter={ this.setFilter }
                  />
                </div>
              )
            })
          }
          {
            this.props.values[field.key] && (this.props.values[field.key] === 'custom' || this.props.values[field.key].type === "custom") &&
              <div>
                <DateFilter
                  field={ field }
                  setFilter={ this.setFilter }
                  unsetFilter={ this.props.unsetFilter }
                />
              </div>
          }
        </div>
      )
    } else if (!this.state.isOpen && !field.fixed) return
  }

  render() {
    const { field, values, modifier, className = { head: {} } } = this.props
    if (!field)
      return <div></div>

    return (
      <div className={ classNames('c-issues-filter', this.state.isOpen ? 'open' : 'closed', { [`c-issues-filter--${modifier}`]: modifier }) }>
        <FilterHead
          field={ field }
          values={ values }
          open={ this.state.isOpen }
          displayFilters={ this.displayFilters }
          isOpen={ this.state.isOpen }
          unsetFilter={ this.props.unsetFilter }
          className={ className.head }
        />
        <div className='c-issues-filter__inputs'>
          { this._renderInputs(field) }
        </div>
      </div>
    )
  }
}

Filter.propTypes = {
  setFilter: PropTypes.func.isRequired,
  unsetFilter: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  field: PropTypes.object,
  className: PropTypes.object,
  modifier: PropTypes.string
}

export default enhanceWithClickOutside(Filter)