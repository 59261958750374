import React, { CSSProperties, FunctionComponent, PropsWithChildren } from 'react';
import State from '@universal/types/business/State';

import "./compact.css";
interface CompactProps {
  state: State
}

const Compact: FunctionComponent<PropsWithChildren<CompactProps>> = ({ state, children }) => (
  <div className="bs-state-compact" style={{ '--state-compact-color': state.color } as CSSProperties}>
  { children }
  </div>
);

export default Compact;