import React, { FunctionComponent, ReactNode }              from "react";
import T                  from "@cBehaviour/i18n";
import Button             from "@cComponents/button";
import DropDown           from "@cComponents/dropDown";
import Modal              from "@cComponents/modal";
import Avatar             from "@cFeatures/user/avatar";

import useService         from "@uBehaviour/hooks/useService";
import SessionService     from "@uServices/session";
import Text from "@common/components/text";
import useLoginAs from "@hooks/useLoginAs";
import ClipboardService from "@common/services/clipboard";
import useHelper from "@universal/behaviour/hooks/useHelper";
import CalendarHelper from "@universal/helpers/calendar";
import User from "@universal/types/business/User";
import useModel from "@universal/behaviour/data/hooks/useModel";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import CommunicationKit from '@features/communicationKit';

const Logout: FunctionComponent = () => {
  const session = useService<SessionService>("session");
  const logout = React.useCallback(() => {
    session.logout();
  }, [session]);
  return (
    <Button.Display onClick={ logout }>
      <Text><T>stdXp_session_userConnected_logout</T></Text>
    </Button.Display>
  );
}
const ButtonSubscription = Modal.withUrlRequestButton(Button.Display);
const Notification: FunctionComponent = () => {
  return (
    <ButtonSubscription displayModalValue="notificationSettings">
      <Text><T>stdXp_session_userConnected_notificationSettings</T></Text>
    </ButtonSubscription>
  );
}

const DisplayAvatar: FunctionComponent = () => {
  const session = useService<SessionService>("session", ["onServiceUpdated"]);
  const [ user, setUser ] = React.useState<BusinessEntity<User,{ avatar: Loader; } > | null>(null);
  const model = useModel("User");

  React.useEffect(() => {
    if (!session.isLogged()) {
      return;
    }
    model.repository.findOne(session.user._id, { avatar: true })
    .then((user: BusinessEntity<User, { avatar: Loader; }>) => setUser(user));
  }, [ model, session, session.isLogged() && session.user._id ]);
  
  if(!user){
    return null;
  }
  return (
    <Avatar user={ user } />
  );
}

const CopyCalendarLink: FunctionComponent = () => {

  const calendarHelper = useHelper<CalendarHelper>("calendar");

  const copyCalendarLink = React.useCallback(() => {
    calendarHelper.copyConnectedUserCalendarLink();
  }, [calendarHelper]);

  return (
    <Button.Display onClick={ copyCalendarLink }>
      <Text><T>stdXp_session_userConnected_copyCalendarLink</T></Text>
    </Button.Display>
  )

}

const UserConnected: React.FunctionComponent = () => {
  const loginAs = useLoginAs();

  const clipboard = useService<ClipboardService>("clipboard");

  const calendarHelper = useHelper<CalendarHelper>("calendar");

  const nodes: ReactNode[] = React.useMemo(() => {
    const elements = [<Notification />, <CommunicationKit />, <Logout />, ...loginAs];

    if(!calendarHelper.connectedUserIsAllowedToCopyCalendarLink()){
      return elements;
    }
    return [<CopyCalendarLink />, ...elements];

  }, [loginAs, clipboard, clipboard.isEnabled()])
  
  return (
    <DropDown.List alignRight={true}>
      <DropDown.List.Main>
        <DisplayAvatar />
      </DropDown.List.Main>
      <DropDown.List.List>
      { 
        nodes.map((node, index) => (
          <div key={ index }>
          { node }
          </div>
        ))
      }
      </DropDown.List.List>
    </DropDown.List>
  );
}

export default UserConnected;