import React, { FunctionComponent } from 'react';
import Context from './context';

interface ControllerProps {
  highlight: null | string;
}

const Controller: FunctionComponent<ControllerProps> = ({ highlight }) => {
  const manager = React.useContext(Context);

  React.useEffect(() => {
    if(manager){
      manager.highlight = highlight;
    }
  }, [manager, highlight]);

  return null;
};

export default Controller;