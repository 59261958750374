
import React, { PureComponent } from 'react'
import _ from "lodash";

class Dropzone extends PureComponent {
    constructor() {
        super()
        
        this.dragEnter  = this.dragEnter.bind(this)
        this.dragLeave  = this.dragLeave.bind(this)
        this.doAction   = _.debounce(this.doAction.bind(this), 0);
        this._enter     = false;
        this._leave     = false;
    }

    doAction(){
      if(this._enter !== this._leave){
        if(this._enter && this.props.onDragEnter){
          this.props.onDragEnter();
        }else if(this._leave && this.props.onDragLeave){
          this.props.onDragLeave();
        }
      }
      this._enter     = false;
      this._leave     = false;
    }

    dragEnter(ev) {
      this._enter = true;
      this.doAction();
    }

    dragLeave(ev) {       
      this._leave = true;
      this.doAction();
    }

    render() {
        const { className = 'u-absolute-fill dropzone' } = this.props

        return (
            <div 
                onDragEnter={ this.dragEnter }
                onDragLeave={ this.dragLeave }
                className={ className }
            >
            {
              this.props.children
            }
            </div>
        )
    }
}


export default Dropzone