import React, { FunctionComponent, PropsWithChildren } from "react";
import T from "@uBehaviour/i18n";
import { BusinessEntity } from "@universal/types/technic/Entityable";
import { useDataTestId } from "@universal/features/dataTestId";
import Slot from "@universal/components/slot2";
import Issue from "@universal/types/business/Issue";

import "./title.css";
import { Loader } from "@universal/types/technic/Entityable";
import Text, { Style } from "@common/components/text";

interface TitleProps {
}

const BsIdNumber = Slot();
const CategoryLabel = Slot();

const Title: FunctionComponent<PropsWithChildren<TitleProps>> & { BsIdNumber: typeof BsIdNumber, CategoryLabel: typeof CategoryLabel, Issue: typeof IssueTitle } = ({ children }) => {
  const numberTestId = `info-${useDataTestId("bsIdNumber")}`;
  const categoryLabelTestId = `info-${useDataTestId("category")}`;

  const bsIdNumber = BsIdNumber.get(children);
  const categoryLabel = CategoryLabel.get(children);

  return (
    <div className="bs-issues-title">
      <Text style={ Style.stdColor.bold } data-testid={ numberTestId }>{bsIdNumber}</Text>
      &nbsp;
      <span className="bs-issues-title-category"><Text style={ Style.stdColor } data-testid={ categoryLabelTestId }>{categoryLabel}</Text></span>
    </div>
  );
};
Title.BsIdNumber = BsIdNumber;
Title.CategoryLabel = CategoryLabel;

type IssueTitleProps = {
  issue: BusinessEntity<Issue, { category: Loader }>;
}
const IssueTitle: FunctionComponent<IssueTitleProps> = ({ issue }) => (
  <Title>
    <Title.BsIdNumber>{issue.bsIdNumber + ""}</Title.BsIdNumber>
    <Title.CategoryLabel><T>{ issue.category.label }</T></Title.CategoryLabel>
  </Title>
);

Title.Issue = IssueTitle;



export default Title;