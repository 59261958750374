import React           from "react";
import _               from "lodash";
import Data            from "@uBehaviour/data";
import Button          from '@cComponents/button';
import Tenant          from "@entities/tenant";
import T               from "@cBehaviour/i18n";
import Filter          from "@cComponents/filter";
import FilteredList    from "@cFeatures/filter/list";
import Input           from "@cComponents/input";
import useService      from "@uBehaviour/hooks/useService";
import SessionService  from "@uServices/session";
import TenantForm      from './form';
import { withLink, withModalLinked } from "@cHoc/withLinkAndModalLinked";
import { Link }        from "@cFeatures/router";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar from "@cComponents/searchbar";
import { escapeRegExp } from "@uLib/tool";
import Acl from "@universal/behaviour/acl";


const query = () => ({});

const textSearchBuildQuery = (value) => ({
  "name.fr": { '$regex': escapeRegExp(value), '$options': 'i' }
});

const defaultQuery = () => [{ name: "client", value: true }];

const LinkedTenantItem = withLink(Tenant.Item);
const ModalizedTenantForm = withModalLinked(TenantForm);

const TenantList = ({ }) => {
  const session = useService<SessionService>('session');
  return (
    <>
      <FilteredList default={defaultQuery()}>
        <FilteredList.Filter>
          <Tenant.Filters user={session.user} />
        </FilteredList.Filter>
        <FilteredList.Header>
          <ListHeaderLayout>
            <ListHeaderLayout.Center>
              <Filter.Generic name="text" buildQuery={value => textSearchBuildQuery(value)} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                {(value, set, clear) => (
                  <Searchbar>
                    <Input.Text focusOnMount className="bs-filter-text" value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                      <T>freesearch</T>
                    </Input.Text>
                  </Searchbar>
                )}
              </Filter.Generic>
            </ListHeaderLayout.Center>
            <ListHeaderLayout.Right>
              <Acl.If resource="tenants" action="manage">
                <Link to="/new">
                  <Button.Stylized.Text><T>add</T></Button.Stylized.Text>
                </Link>
              </Acl.If>
            </ListHeaderLayout.Right>
          </ListHeaderLayout>
        </FilteredList.Header>
        <FilteredList.List>
          {composeQuery => (
            <Data.List model={"Tenant"} query={composeQuery(query())} sort={{ "name.fr": 1 }} load={Tenant.Item.Load}>
              <LinkedTenantItem />
            </Data.List>
          )}
        </FilteredList.List>
      </FilteredList>
      <ModalizedTenantForm style={{ width: "80vw", height: "90vh" }} />
    </>
  );
}

export default TenantList;