import React, { PureComponent } from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'

import SupplyModal from 'client/features/supplies/components/Modal.jsx'
import IssueUpdatesWrapper from 'client/features/issues/containers/UpdatesWrapper.jsx'
import Filter from 'client/features/issues/components/filters/Filter.jsx'
import {
  setCalendarFiltersValue,
  unsetCalendarFilter
} from 'client/features/calendar/actions'

const IssuePlanificationWrapper = (WrappedComponent) => {
  class IssuePlanificationHOC extends PureComponent {
    constructor(props) {
      super(props)
      this.patrimoniesChange    = this.patrimoniesChange.bind(this)
      this.scheduledChange      = this.scheduledChange.bind(this)
      this.planningChange       = this.planningChange.bind(this)
      this.orderChange          = this.orderChange.bind(this)
      this.addSupply            = this.addSupply.bind(this)
      this.editSupply           = this.editSupply.bind(this)
      this.updateSupply         = this.updateSupply.bind(this)
      this.removeSupply         = this.removeSupply.bind(this)
      this.supplyQuantityChange = this.supplyQuantityChange.bind(this)
      this.updateSupplyQuantity = this.updateSupplyQuantity.bind(this)
      this.displaySupplyModal   = this.displaySupplyModal.bind(this)
      this.submitSupply         = this.submitSupply.bind(this)
      this.state                = {
        showSupplyModal: false,
        currentSupply: null,
        supplies: props.issue ? _.sortBy(props.issue.planification.supplies, 'label') : [],
        lastQuantityChange: null
      }
    }

    componentDidUpdate(prevProps) {
      if (this.props.issue && !_.isEqual(prevProps.issue.planification.supplies, this.props.issue.planification.supplies))
        this.setState({ supplies: _.sortBy(this.props.issue.planification.supplies, 'label') })
    }

    patrimoniesChange(patrimonies) {
      this.props.updateIssue('patrimonies', {
        payload: {
          patrimonies: patrimonies.map(patrimony => patrimony._id)
        }
      })
    }

    scheduledChange(from, to) {  
      this.props.updateIssue('schedule', {
        payload: { 
          from,
          to
        }
      })
    }

    planningChange(data, inputLevel) {
      const planning = {}
      let key
      if (inputLevel === 'sub')
        key = 'users'
      else if (inputLevel === 'main')
        key = 'teams'

      planning[key] = data
      this.props.updateIssue('assignments', {
        payload: {
          planning
        }
      })
    }

    orderChange(order, v) {
      this.props.updateIssue('order', {
        payload: {
          order
        }
      })
    }

    addSupply(supply, name) {
      if (!supply)
        return
      if (supply && supply.value === 'add_new')
        return this.displaySupplyModal({ name })
      this.props.updateIssue('addSupply', {
        payload: {
          supply
        }
      })
    }

    editSupply(supply) {
      this.displaySupplyModal(supply)
    }

    updateSupply(supply) {
      if (!supply)
        return
      this.props.updateIssue('editSupply', {
        payload: {
          supply
        }
      })
    }

    removeSupply(supply) {
      this.props.updateIssue('removeSupply', {
        payload: {
          supply
        }
      })
    }

    updateSupplyQuantity(payload) {
      this.props.updateIssue('updateSupplyQuantity', { payload })
    }

    supplyQuantityChange(supply, value, forceQuantity) {
      const { supplies } = this.state
      const now = new Date().getTime()
      const item = supplies.find(item => item.value === supply.value)
      const oldQuantity = item.quantity
      if (forceQuantity)
        item.quantity = value
      else
        item.quantity += value
      if (item.quantity < 0)
        item.quantity = 0
      if (oldQuantity === item.quantity)
        return
      this.setState({ supplies: _.cloneDeep(supplies), lastQuantityChange: now })
      setTimeout(() => {
        if (now === this.state.lastQuantityChange)
          this.updateSupplyQuantity({ supply: supply.value, quantity: item.quantity })
      }, 400)
    }

    displaySupplyModal(currentSupply) {
      this.setState({
        showSupplyModal: !this.state.showSupplyModal,
        currentSupply
      })
    }

    submitSupply(values) {
      if (this.state.currentSupply._id)
        this.updateSupply(values)
      else
        this.addSupply(values)
      this.displaySupplyModal()
    }

        setFilter = (payload) => {
          this.props.dispatch(setCalendarFiltersValue(payload))
        }

        unsetFilter = (payload) => {
          this.props.dispatch(unsetCalendarFilter(payload))
        }

        render() {

          const teamsField = _.cloneDeep(this.props.issues.filters.fields.find(field => field.key === 'teams'))
          if (teamsField)
            teamsField.attributes = teamsField.attributes.filter((attribute) => attribute.value)

          const calendarTeamsFilterValues = _.cloneDeep(this.props.calendarTeamsFilterValues)
          if (calendarTeamsFilterValues && calendarTeamsFilterValues.teams && calendarTeamsFilterValues.teams.indexOf('patrimony') !== -1)
            calendarTeamsFilterValues.teams.splice(calendarTeamsFilterValues.teams.indexOf('patrimony'), 1)

          const TeamsFilter = <Filter
            modifier='calendar'
            key={ 'details-teams' }
            field={ teamsField }
            setFilter={ this.setFilter }
            values={ calendarTeamsFilterValues }
            unsetFilter={ this.unsetFilter }
            forceHide={ true }
            closeOnOutSideClick={ true }
          />

          return (
            <div>
              <WrappedComponent
                patrimoniesChange={ this.patrimoniesChange }
                scheduledChange={ this.scheduledChange }
                planningChange={ this.planningChange }
                addSupply={ this.addSupply }
                editSupply={ this.editSupply }
                updateSupply={ this.updateSupply }
                removeSupply={ this.removeSupply }
                updateSupplyQuantity={ this.updateSupplyQuantity }
                orderChange={ this.orderChange }
                supplyQuantityChange={ this.supplyQuantityChange }
                issueSupplies={ this.state.supplies }
                TeamsFilter={ TeamsFilter }
                { ...this.props }
              />
              {
                this.state.showSupplyModal &&
                <SupplyModal
                  show={ this.state.showSupplyModal }
                  onHide={ this.displaySupplyModal }
                  initialValues={ this.state.currentSupply.item || this.state.currentSupply }
                  user={ this.props.user }
                  onSubmit={ this.submitSupply }
                />
              }
            </div>
          )
        }
  }

  return IssuePlanificationHOC
}


const mapStateToProps = (state) => {
  const { calendar, supplies, session } = state
  return {
    supplies: supplies.all,
    calendarTeamsFilterValues: calendar.filters.values,
    patrimonies: session.patrimonies,
    teams: session.teams
  }
}

export default compose(IssueUpdatesWrapper, connect(mapStateToProps), IssuePlanificationWrapper)