import React                  from 'react'; 
import FieldGroup             from '@components/fieldGroup';
import Input                  from '@cComponents/old/input';
import FInput                 from "@components/input";
import Form                   from '@cBehaviour/form';
import T                      from '@cBehaviour/i18n';
import Button                 from '@cComponents/button';
import Field                  from '@cComponents/old/field';
import FormError              from "@cComponents/formError";
import NewInput               from "@cComponents/input";
import Application            from "@uBehaviour/application";

import './form.css';
import Query from '@universal/lib/query';

class BuildingForm extends React.Component {
  constructor(props){
    super(props);
    this._preload   = this._preload.bind(this);
    this._presubmit = this._presubmit.bind(this);
    this._inputArray = React.createRef();
  }

  _preload(value) {
    value.disabled = !value.disabled;
    if (value.places) value.places = value.places.filter(p=>!p.disabled);
    value.files = value.files ? value.files : [];
    return value;
  }

  _presubmit(value) {
    if (value.externalLink === '') {
      delete value.externalLink;
    }
    value.places = value.places.reduce((places, place) => {
      if (place.externalLink === '') {
        delete place.externalLink;
      }
      places.push(place)
      return places;
    }, []);
    value.disabled = !value.disabled;
    return value;
  }

  _searchTags = (label, alreadySelect) => {
    return this.props.api.service("issues", "tags").execute(Query.joinWithOptimizer(
      { $and: [{ name: { $regex: `.*${label}.*`, $options: 'i' }}, { name: { $nin: alreadySelect }}]},
      { tenant: this.props.currentTenant.currentId }
    ), 
    { name: -1 }, 
    0, 
    15
    ).then(tags => tags.map(t => t.name));
  }

  render() {
    return (
      <Form.Model 
        model={ "Building" }
        observer={ this.props.observer }
        id={ this.props.id ? this.props.id : undefined }
        value={ this.props.value }
        preload={ this._preload }
        presubmit={ this._presubmit }
        ref={ this.props.forwardedRef }
        name={this.props.name}
      >
        {(building, errors) => (
          <FormError errors={ errors }>
            <div className="bs-buildingForm">
              <div className="bs-buildingForm-picture">
                <FieldGroup title="files" type="full">
                  <Field>
                    <Field.Input>
                      <Input.File name="files" width={ 285 } height={285} fit="contain" />
                    </Field.Input>
                  </Field>
                </FieldGroup>  
              </div>
              <div className="bs-buildingForm-content">
                <FieldGroup type="full" title="generals_informations">
                  <Field>
                    <Field.Label><T>name</T></Field.Label>
                    <Field.Input><Input.Text required={true} name="name"></Input.Text></Field.Input>   
                  </Field>
                  <Field>
                    <Field.Label><T>cartographic_external_link</T></Field.Label>
                    <Field.Input><Input.Url name="externalLink"></Input.Url></Field.Input>   
                  </Field>
                  <Field>
                    <Field.Label><T>building_form_tags</T></Field.Label>
                    <Field.Input>
                      <Input.NewToOld.Multiple name="tags">
                        <NewInput.Tag.Selectable dataSource={ this._searchTags } />
                      </Input.NewToOld.Multiple>
                    </Field.Input>
                  </Field>
                  <Field>
                      <Field.Label><T>active_m</T></Field.Label>
                      <Field.Input><FInput.YesNo name="disabled"/></Field.Input>
                  </Field>
                  <Field>
                    <Field.Label><T>location</T></Field.Label>
                    <Field.Input>
                      <Input.NewToOld name="location" required>
                        {(onChange, value) => (
                          <div style={{ height: "330px" }}>
                            <NewInput.AddressMap onChange={onChange} value={value} />
                          </div>
                        )}
                      </Input.NewToOld>
                    </Field.Input>
                  </Field>
                </FieldGroup>                                          
                <FieldGroup type="full" title="link_buildings">
                  <table className="bs-linkBuildings-table">
                    <thead>
                      <tr>
                        <th><T>name</T></th>
                        <th><T>cartographic_external_link</T></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <Input.Array.Simple ref={ this._inputArray } name="places">
                        <Input.Array.Simple.Display>
                          {(place, idx) => (
                              <tr key={ idx }>
                                <td>{ place.name }</td>
                                <td>{ place.externalLink }</td>
                                <td><Button.Icon faClass="pencil" color="green" onClick={ () => this._inputArray.current.edit(idx) } /></td>
                                <td><Button.Icon faClass="close" color="red" onClick={ () => this._inputArray.current.delete(idx) } /></td>
                              </tr>
                            )}
                        </Input.Array.Simple.Display>
                        <Input.Array.Simple.Form>
                          {(object, fnSubmit, fnCancel) => (
                            <>
                              <Input.Hidden name="_id" />
                              <Input.Hidden name="disabled" />
                              <tr>
                                <td><Input.Text name="name" required={true}/></td>
                                <td><Input.Url name="externalLink" /></td>
                                <td><Button.Icon faClass="floppy-o" color="green" onClick={ fnSubmit } /></td>
                                <td><Button.Icon faClass="ban" color="red" onClick={ fnCancel } /></td>
                              </tr>
                            </>
                          )}
                        </Input.Array.Simple.Form>
                      </Input.Array.Simple>
                    </tbody>
                  </table>
                  <Button.Text onClick={ () =>this._inputArray.current.add() } ><T>add</T></Button.Text>
                </FieldGroup> 
              </div>
            </div>
          </FormError>
        )}        
      </Form.Model> 
    )
  }
}
export default Application.Service.forward(["currentTenant", "api"], React.forwardRef((props, ref) => {
  return (<BuildingForm { ...props } forwardedRef={ ref }/>)
}))