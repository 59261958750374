// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-item-selected {
  display: flex;
  border-radius: var(--std-radius);
  background-color: var(--std-color-gray-light);
  padding: var(--std-padding-input);
  align-items: center;
  line-height: 1;
}

.bs-item-selected-content {
  flex-basis: 0;
  flex-grow: 1;
}

.bs-item-selected-actions {
  display: flex;
  gap: var(--std-size-inter-element);
  padding: 0px var(--std-size-inter-element);
  
}
.bs-item-selected-actions>* {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--std-font-size-tiny);
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  height: 16px;
  width: 16px;
}
.bs-item-selected-actions>*:hover {
  cursor: pointer;
}
.bs-item-selected-actions .fa {
  font-size: var(--std-font-size-tiny);
}
.bs-item-selected-actions>*:hover {
  background-color: rgb(255, 255, 255 / 80%);
}`, "",{"version":3,"sources":["webpack://./../common/components/item/selected.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gCAAgC;EAChC,6CAA6C;EAC7C,iCAAiC;EACjC,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,kCAAkC;EAClC,0CAA0C;;AAE5C;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,oCAAoC;EACpC,kBAAkB;EAClB,oCAAoC;EACpC,YAAY;EACZ,WAAW;AACb;AACA;EACE,eAAe;AACjB;AACA;EACE,oCAAoC;AACtC;AACA;EACE,0CAA0C;AAC5C","sourcesContent":[".bs-item-selected {\n  display: flex;\n  border-radius: var(--std-radius);\n  background-color: var(--std-color-gray-light);\n  padding: var(--std-padding-input);\n  align-items: center;\n  line-height: 1;\n}\n\n.bs-item-selected-content {\n  flex-basis: 0;\n  flex-grow: 1;\n}\n\n.bs-item-selected-actions {\n  display: flex;\n  gap: var(--std-size-inter-element);\n  padding: 0px var(--std-size-inter-element);\n  \n}\n.bs-item-selected-actions>* {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: var(--std-font-size-tiny);\n  border-radius: 50%;\n  background-color: rgb(255, 255, 255);\n  height: 16px;\n  width: 16px;\n}\n.bs-item-selected-actions>*:hover {\n  cursor: pointer;\n}\n.bs-item-selected-actions .fa {\n  font-size: var(--std-font-size-tiny);\n}\n.bs-item-selected-actions>*:hover {\n  background-color: rgb(255, 255, 255 / 80%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
