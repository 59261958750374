import React, { PureComponent } from 'react'
import T from 'i18n-react'
import IssueSmallListItem from 'client/features/issues/components/lists/SmallItem.jsx'
import Title from 'client/features/overlay-view/components/Title.jsx'
import Loader from 'client/features/common/components/Loader.jsx'

class IssueGroup extends PureComponent {
    constructor(props) {
        super()
    }

    render() {
        const { issues, loading } = this.props
        if (!loading && !issues[0])
            return (<div></div>)
        return (
            <div className='u-hide-for-print'>
                <Title
                    title={ T.translate('issue_duplicated_links') }
                />
                <div id='filters-result' className='u-letter-box--small'>
                    {
                        loading && !issues[0] ?
                            <div style={ { marginBottom: '70px' } }><Loader small/></div>
                            :
                            issues.map((issue, index) => issue &&
                                <IssueSmallListItem
                                    issue={ issue }
                                    key={ `${index}-${issue._id}` }
                                    unlink={ this.props.unlink }
                                    goToIssue={ this.props.goToIssue }
                                    className='u-margin-right-none u-margin-left-none'
                                />
                            )
                    }
                </div>
            </div>
        )
    }
}

export default IssueGroup