import React, { Component } from 'react'
import FontAwesome from 'react-fontawesome'
import Confirmation from 'client/features/common/components/Confirmation.jsx'
import PropTypes from 'prop-types'
import T from 'i18n-react'
import _ from 'lodash'
import moment from 'moment'
import classNames from 'classnames'

class IssueDetailsField extends Component {
  constructor(props) {
    super()
    this._renderColumns = this._renderColumns.bind(this)
    this._renderValue = this._renderValue.bind(this)
    this._renderField = this._renderField.bind(this)
    this._renderLabel = this._renderLabel.bind(this)
    this.onFieldChange = this.onFieldChange.bind(this)
    this.edit = this.edit.bind(this)
    if (props.forceEditMode)
      this.forceEdit = true
    this.state = {
      edit: props.forceEditMode || props.forceEditModeOnMount || false,
      disabled: props.disabled,
      readOnly: props.readOnly
    }
  }

  isAuthorized(action = 'read') {
    return true;
  }

  getError = () => {
    const errors = this.props.form && this.props.form.errors
    const error = this.props.error

    if (error)
      return error
    if (!errors)
      return
    const fieldName = this.props.field.name
    if (this.props.field && fieldName && (this.props.form.touched[fieldName] || this.props.form.submitCount > 0))
      return errors[fieldName]
  }

  edit(edit, callback) {
    if (this.state.disabled && !this.state.edit)
      return
    if (!_.isBoolean(edit))
      edit = !this.state.edit
    if (!edit && this.props.onCancel)
      this.props.onCancel(this.props.input ? this.props.input.value : this.props.value)
    if (edit && this.props.onEdit)
      this.props.onEdit()
    this.setState({ edit, error: null }, () => {
      if (callback && _.isFunction(callback))
        return callback()
    })
  }

  _renderValues() {
    const { values, hideValues } = this.props
    if (hideValues)
      return
    return (
      <span>
        {
          values.map((value, index) =>
            <span className='label u-margin-right-tiny u-margin-bottom-xtiny' key={ index }>
              {
                value.icon && <FontAwesome name={ value.icon } className='u-margin-right-xtiny' />
              }
              { value.label }{ /*&nbsp;
                          <FontAwesome name='remove' />*/}
            </span>
          )
        }
      </span>
    )
  }

  _renderLabel() {
    let value = this.props.input ? this.props.input.value : this.props.value
    if (!value && this.props.field && this.props.field.value)
      value = this.props.field.value
    if (this.props.values)
      return this._renderValues()
    if (!value && this.props.forcePlaceHolder && !this.state.disabled && !this.props.hideValues)
      return this.props.forcePlaceHolder
    if (!value && this.props.placeholder && !this.state.disabled && !this.props.hideValues)
      return <span className='u-hide-for-print'>{ T.translate(this.props.placeholder) }</span>
    if (!value)
      return

    if (_.isNumber(value) && this.props.isPrice)
      return `${value} €`
    if (_.isString(value) || _.isNumber(value))
      return value
    if (_.isDate(value))
      return moment(value).format('DD/MM/YYYY' + (this.props.withHour ? " HH:mm" : ""))
    return value.label
  }

  _renderValue() {
    const error = this.getError()
    if ((!this.state.disabled || this.state.readOnly) && (this.state.edit || this.forceEdit))
      return (
        <div>
          { this._renderField() }
          { error && <div className='error'>{ error }</div> }
        </div>
      )
    let { value } = this.props
    if (!value && this.props.field && this.props.field.value)
      value = this.props.field.value

    if (_.isNumber(value))
      value = value.toString()
    return (
      <div
        className={ classNames('c-action-link', {
          'c-action-link--muted': this.props.muted || this.state.readOnly,
          'c-action-link--empty': (!value && this.props.placeholder && !this.state.disabled),
          disabled: this.state.disabled })
        }
        onClick={ this.edit }
      >
        <div className='c-action-link__value'>
          { this._renderLabel() }
        </div>
        {
          !this.state.disabled && !this.props.hideIndicator &&
          <div className='c-action-link__indicators u-hide-for-print'>
            <FontAwesome name='caret-right' className='c-action-link__indicator'/>
            <FontAwesome name='pencil' className='c-action-link__indicator' />
          </div>
        }
        <div className='c-action-link__confirmation'>
          <Confirmation data={ this.props.confirmation } />
        </div>
        {
          error && !this.props.columns && <div className='error'>{ error }</div>
        }
      </div>

    )
  }

  _renderColumns() {
    const { labelIcon, hideLabel, large, rightedLabel } = this.props
    const classes = this.props.classNames || {}

    const error = this.getError()
    return (
      <div className='c-issue-detail-field c-issue-detail-field--columns'>
        <div className='o-layout o-layout--middle'>
          {
            !hideLabel &&
            <div className={ classes.containerLeft ? classes.containerLeft : 'o-layout__item u-1of4' }>
              <div
                className={ classNames('c-issue-detail-field__label', {
                  'c-issue-detail-field__label-large': large,
                  'u-text-align-right': rightedLabel
                }) }
              >
                {
                  this.props.labelClickable ?
                    <a onClick={ this.edit }>
                      {
                        labelIcon && <FontAwesome name={ labelIcon } className='u-margin-right-tiny'/>
                      }
                      { T.translate(this.props.label) }
                    </a>
                    :
                    <span>
                      {
                        labelIcon && <FontAwesome name={ labelIcon } className='u-margin-right-tiny'/>
                      }
                      { T.translate(this.props.label) }:
                    </span>
                }

              </div>
            </div>
          }
          <div
            className={
              classNames(classes.containerRight, {
                'o-layout__item u-3of4': !classes.containerRight,
                'u-font-size-large': large
              })
            }
          >
            <div
              className={ classNames('c-issue-detail-field__content', {
                'u-font-size-large': large
              }) }
            >
              { this._renderValue() }
            </div>
            {
              error && <div className='error'>{ error }</div>
            }
          </div>
        </div>
      </div>
    )
  }

  render() {
    if (!this.isAuthorized('read'))
      return <span></span>
    if (this.props.columns)
      return this._renderColumns()
    return this._renderValue()
  }
}

IssueDetailsField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string
}

export default IssueDetailsField