// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-input-date.bs-not-fluid{
  width: min-content;
}

.bs-input-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid;
  padding-left: 19px;
}

.bs-input-date input,
.bs-input-hour input {
  flex-grow: 1;
  border-radius: 0px !important;
  height: auto !important;
  border: none !important;
  background: none !important;
  margin: 0 !important;
  padding: 0px !important;
  box-shadow: none !important;
  color: #2c3e50 !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
  outline: none;
  max-width: 110px;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/date.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,wBAAwB;EACxB,kBAAkB;AACpB;;AAEA;;EAEE,YAAY;EACZ,6BAA6B;EAC7B,uBAAuB;EACvB,uBAAuB;EACvB,2BAA2B;EAC3B,oBAAoB;EACpB,uBAAuB;EACvB,2BAA2B;EAC3B,yBAAyB;EACzB,2BAA2B;EAC3B,0BAA0B;EAC1B,aAAa;EACb,gBAAgB;AAClB","sourcesContent":[".bs-input-date.bs-not-fluid{\n  width: min-content;\n}\n\n.bs-input-date {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  border-bottom: 1px solid;\n  padding-left: 19px;\n}\n\n.bs-input-date input,\n.bs-input-hour input {\n  flex-grow: 1;\n  border-radius: 0px !important;\n  height: auto !important;\n  border: none !important;\n  background: none !important;\n  margin: 0 !important;\n  padding: 0px !important;\n  box-shadow: none !important;\n  color: #2c3e50 !important;\n  font-weight: 500 !important;\n  font-size: 1rem !important;\n  outline: none;\n  max-width: 110px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
