import React from 'react'
import T from 'i18n-react'
import FontAwesome from 'react-fontawesome'

const getIcon = (action) => {
    if (action === 'success')
        return 'check-circle-o'
    else if (action === 'request')
        return 'spinner'
}

const getClassName = (action) => {
    if (action === 'success')
        return 'green'
    return ''
}

const Confirmation = ({ data, size }) => {
    if (!data) return null
    return (
        <span className='c-confirmation'>
            { data.message ? T.translate(data.message) : null }
            <FontAwesome
                name={ getIcon(data.action) }
                className={ getClassName(data.action) }
                spin={ data.action === 'request' }
                size={ size }
            />
        </span>
    )
}
export default Confirmation