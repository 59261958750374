import React            from "react";
import _                from "lodash";
import Data             from "@uBehaviour/data";
import moment           from "moment";
import Display          from "@cComponents/displayIf";
import T                from "@cBehaviour/i18n";
import Application      from "@uBehaviour/application";
import RessourceManager,{ File } from "./ressourceManager";

import "./book.css";

const BookChildComponent  = React.createContext();

class Book extends React.Component{
  static Promises = [];
  state = {
    ready: false
  }
  constructor(props){
    super(props);
    this.print = _.debounce(this.print.bind(this), 0);
    this._ressourceManager = React.createRef();
  }
  print(){
    this._ressourceManager.current.ready();
  }
  _onRessourceLoaded = () => {
    this.setState({ ready: true });
  }
  componentDidUpdate(){
    if(this.state.ready){
      setTimeout(() => {
        document.title = this.props.title;
        window.print();
        setTimeout(window.close, 100);
      }, 1000);
    }
  }
  renderHeader(currentTenant, session){
    return (
      <div className="bs-page-header">
        <div>
          <Display.If condition={currentTenant.current.informations.pictures.header}>
          {() => (
            <Data.One id={currentTenant.current.informations.pictures.header} model={"File"}>
              {(file) => React.createElement(File, { file: file, height: 40 })}
            </Data.One>
          )}
          </Display.If>
        </div>
        <div className="bs-page-header-printer">Imprimé par: <b>{ session.user.fullname}</b> le <b>{moment().format("DD/MM/YYYY")}</b></div>
      </div>
    );
  }
  renderFooter(application){
    return null;
  }
  renderHidden(){
    return (
      <div className="bs-print-overlay">
        <div>
          <h1>
            <Display.If condition={ this.state.ready }>
              <Display.Then>
                <T>print_ready</T>
              </Display.Then>
              <Display.Else>
                <T>print_wait</T>
              </Display.Else>
            </Display.If>
          </h1>
        </div>
      </div>
    )
  }
  renderPageNoBreak(currentTenant, session){
    const Component = (props) => {
      return props.children;
    }
    return (
      <>
        { this.renderHidden() }
        <BookChildComponent.Provider value={ Component }>
          <table className="bs-print">
            <thead><tr><td><div className="bs-print-header-space">&nbsp;</div></td></tr></thead>
            <tbody><tr><td><div className="bs-print-content">{ this.props.children }</div></td></tr></tbody>
            <tfoot><tr><td><div className="bs-print-footer-space">&nbsp;</div></td></tr></tfoot>
          </table>
          <div className="bs-print-header">{ this.renderHeader(currentTenant, session) }</div>
          <div className="bs-print-footer">{ this.renderFooter(currentTenant, session) }</div>
        </BookChildComponent.Provider>
      </>
    )
  }
  renderPageBreak(currentTenant, session){
    const Component = (props) => (
      <div className="bs-print-page">
        <div className="bs-print-page-header">{ this.renderHeader(currentTenant, session) }</div>
        <div className="bs-print-page-content">{ props.children }</div>
        <div className="bs-print-page-footer">{ this.renderFooter(currentTenant, session) }</div>
      </div>
    );
    return (
      <>
        { this.renderHidden() }
        <BookChildComponent.Provider value={ Component }>
          { this.props.children }
        </BookChildComponent.Provider>
      </>
    )
  }
  render(){
    return React.createElement(RessourceManager, { onLoaded: this._onRessourceLoaded, ref: this._ressourceManager },
      React.createElement(Application.Service, { name: ["currentTenant", "session"] }, ([currentTenant, session]) => {
        if(!currentTenant.current) return null;
        if(this.props.breakBetweenArticle){
          return this.renderPageBreak(currentTenant, session);
        } else {
          return this.renderPageNoBreak(currentTenant, session);
        }
      })
    );
  }
}

const Article = (props) => React.createElement(BookChildComponent.Consumer, {}, Component => React.createElement(Component, {}, props.children));


Book.Article      = Article;
export default Book;