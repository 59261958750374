import React  from "react";
import Slot   from "@uComponents/slot";

export default class Hiddenable extends React.Component {
  static Title    = Slot();
  static Content  = Slot();

  constructor(props) {
    super(props);
    this.state = {
      isOpen: !!this.props.open
    }
    this._containerRef = React.createRef();
  }

  _switchOpenState() {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  get height() {
    return this._containerRef.current.clientHeight;
  }

  isOpen() {
    return this.state.isOpen;
  }

  render() {
    const title   = Hiddenable.Title.get(this);
    const content = Hiddenable.Content.get(this);

    return (
      <div className='u-padding-small' ref={this._containerRef}>
        <h3 onClick={ () => this._switchOpenState() } className='u-cursor-pointer'>
          <span className={'u-margin-right-tiny fa ' + (this.state.isOpen ? 'fa-caret-down' : 'fa-caret-right') } />
          { title }
        </h3>
        { this.state.isOpen && content }
      </div>
    )
  }
}