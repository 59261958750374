import React           from "react";
import _               from "lodash";
import Data            from "@uBehaviour/data";
import Button          from '@cComponents/button';
import Campaign        from "@entities/campaign";
import { Link }        from "@cFeatures/router";
import T               from "@cBehaviour/i18n";
import Filter          from "@cComponents/filter";
import FilteredList    from "@cFeatures/filter/list";
import Input           from "@cComponents/input";
import { withLink, withModalLinked } from "@cHoc/withLinkAndModalLinked";
import CampaignForm    from './form';
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar       from "@cComponents/searchbar";
import { escapeRegExp } from "@uLib/tool";

const textSearchBuildQuery = (value) => {
  return { title: { '$regex': escapeRegExp(value), '$options': 'i' } };
};

const query = () => {
  return null;
}

const defaultQuery = () => {
  return [];
};

const LinkedCampaignItem = withLink(Campaign.Item);
const ModalizedCampaignForm = withModalLinked(CampaignForm);

const CampaignList = ({ }) => {
  return (
    <>
      <FilteredList default={defaultQuery()}>
        <FilteredList.Filter>
          <Campaign.Filters />
        </FilteredList.Filter>
        <FilteredList.Header>
          <ListHeaderLayout>
            <ListHeaderLayout.Center>
              <Filter.Generic name="text" buildQuery={textSearchBuildQuery} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                {(value, set, clear) => (
                  <Searchbar>
                    <Input.Text focusOnMount className="bs-filter-text" value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                      <T>freesearch</T>
                    </Input.Text>
                  </Searchbar>
                )}
              </Filter.Generic>
            </ListHeaderLayout.Center>
            <ListHeaderLayout.Right>
              <Link to="/new">
                <Button.Stylized.Text><T>add</T></Button.Stylized.Text>
              </Link>
            </ListHeaderLayout.Right>
          </ListHeaderLayout>
        </FilteredList.Header>
        <FilteredList.List>
          {composeQuery => (
            <Data.List model={"Campaign"} query={composeQuery(query())} sort={{ 'start': -1 }}>
              <LinkedCampaignItem />
            </Data.List>
          )}
        </FilteredList.List>
      </FilteredList>
      <ModalizedCampaignForm style={{ width: "80vw", height: "90vh" }} />
    </>
  );
};

export default CampaignList;