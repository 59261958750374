import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import toDic        from "@cLib/toDic";
import useService   from '@uBehaviour/hooks/useService';
import ApiService           from '@uServices/api';
import CurrentTenantService from '@uServices/currentTenant';
import RepositoryService    from '@uServices/repository';
import { combinate }        from '@uLib/query';
import Equipment            from "@entities/equipment";

const textify = ((equipment) => equipment.name)

const EquipmentFilterDisplay: React.FunctionComponent = ({ values, add, drop, clear, query }) => {
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const repository = useService<RepositoryService>("repository");
  
  const usedQuery = React.useMemo(() => combinate("$and",
    { tenant: currentTenant.currentId },
    values.length ? { _id: { $nin: values.map(v => v._id) }} : null,
    query
  ), [currentTenant.currentId, values, query]);

  const value = React.useMemo(() => values.map(v => repository.get("Equipment").key.extract(v)), [values, repository]);
  const sort  = React.useMemo(() => ({ name: 1 }), [])

  const triggerChange = React.useCallback((vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }, [add, drop]);
  const filterQuery   = React.useCallback(value => ({ name: { '$regex': value, '$options': 'i' } }), []);

  return (
    <Collapsable>
      <Collapsable.Title><T>issue_filter_equipments</T></Collapsable.Title>
      <Collapsable.Content>
        <Input.Selectable 
          value={ value }
          onChange={ triggerChange }
          model={ repository.get("Equipment") }
          load={ Equipment.Item.load }
          query={ usedQuery }
          sort={ sort }
          textify={ textify }
          filterQuery={ filterQuery }
        >
          <Equipment.Item />
        </Input.Selectable>
      </Collapsable.Content>
    </Collapsable>
  );
};

const buildQuery = (values) => (
  { $or: [
    { "equipment": { $in: values.map(v => v._id) } },
    { "assignments.necessariesEquipments": { $in: values.map(v => v._id) }},
    { "assignment.necessariesEquipments": { $in: values.map(v => v._id) }} 
  ]}
);
const stringify = (value) => (<><T>issue_filter_equipments</T> : {value.name}</>);

type EquipmentFilterType = {
  query?: string
};

const EquipmentFilter: React.FunctionComponent<EquipmentFilterType> = ({ query }) => {
  const api = useService<ApiService>("api");
  const hydrate = React.useCallback(values => 
    api.service("equipments", "get")
    .execute({_id: { $in: values }}, null, null, values.length)
    .then(equipments => toDic(values, v => v, (v) => equipments.find(e => e._id === v)))
  , [api]);

  return (
    <Filter.Generic 
      deshydrate={value => value._id}
      hydrate={ hydrate }
      multiple
      name="equipments"
      buildQuery={ buildQuery }
      stringify={ stringify }
    >
      {(values, add, drop, clear) => (
        <EquipmentFilterDisplay values={ values } add={ add } drop={ drop } clear={ clear } query={ query }/>
      )}
    </Filter.Generic>
  )
};

export default EquipmentFilter;