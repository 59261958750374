import React                  from "react";
import moment                 from "moment";
import Application            from '@uBehaviour/application';
import Format                 from "@cBusiness/format";
import Display                from "@cComponents/displayIf";
import Map                    from "@cComponents/map/index";
import { Ressource, Image, Pdf} from "./ressourceManager";
import T                      from "@uBehaviour/i18n";
import Period                 from "@cComponents/period";
import isEmptyAssignment      from "@uBusiness/lib/issues/isEmptyAssignment"
import "./components.css";

const BlockTitle = (props) => (
  <div>
    <Display.If condition={props.concernsBuilding }>
      <Display.Then>
        <span className="fa fa-building" />
      </Display.Then>
      <Display.Else>
        <span className="fa fa-road" />
      </Display.Else>
    </Display.If>&nbsp;
    <b>{`${props.bsIdNumber}`}</b>&nbsp;
    { props.category.toUpperCase() }
  </div>
);
const BlockManager = (props) => (
  <Display.If condition={ props.manager }>
  {() => (
    <div>
      <Display.If condition={props.withManagerLabel}>Gestionnaire:&nbsp;</Display.If>
      <b>{ Format.User.shortFullname(props.manager)}&nbsp;<span className="fa fa-user" /></b>
    </div>
  )}
  </Display.If>
);
const BlockCreated = ({ createdAt, createdBy, requestor }) => (
  <>
    <div>
      Créé le&nbsp;
      <b>{ moment(createdAt).format("DD/MM/YYYY") }</b>
      &nbsp;par&nbsp;
      <b>{ Format.User.longFullname(createdBy) }</b>
    </div>
    <Display.If condition={ requestor.type !== "userPro"}>
      <div>
        Demandeur&nbsp;:&nbsp;
        <b>{ Format.User.longFullname(requestor) }</b>
      </div>
      <Display.If condition={ requestor.email }>
        <div>
          Email&nbsp;:&nbsp;
          <b>{ requestor.email }</b>
        </div>
      </Display.If>
      <Display.If condition={ requestor.phone }>
        <div>
          Téléphone&nbsp;:&nbsp;
          <b>{ requestor.phone }</b>
        </div>
      </Display.If>
    </Display.If>
  </>
);
const BlockSubject = (props) => (
  <Display.If condition={ props.issue.equipment }>
    <Display.Then>
      <BlockDescriptionEquipment equipment={ props.issue.equipment }/>
    </Display.Then>
    <Display.Else>
      <BlockDescriptionLocation location={ props.issue.location }/>
    </Display.Else>
  </Display.If>
)
const BlockDescriptionEquipment = (props) => (
  <Display.If condition={ props.equipment }>
    <div>
      <span className="fa fa-wrench" />&nbsp;{ props.equipment.name }
      {props.equipment.location && ( <BlockDescriptionLocation location={ props.equipment.location}/> )}
    </div>
  </Display.If>
);
const BlockDescriptionLocation = (props) => (
  <Display.If condition={props.location && props.location.address }>
  {() => (
    <div>
      <span className="fa fa-eye" />&nbsp;
      <Display.If condition={  props.location && props.location.building }>
        {() => (
          <>
            <b>
              { props.location.building.name }
              { props.location.place ? " / " + props.location.building.places.find(p => p._id === props.location.place).name : ""}
            </b>
            &nbsp;
          </>
        )}
      </Display.If>
      <Display.If condition={ props.location.road && (props.location.road.code || props.location.road.km)}>
        {() => (
          <>
            <b>
            { 
              (props.location.road.code ? props.location.road.code : "") +
              (props.location.road.code && props.location.road.km ? " " : "") +
              (props.location.road.km ? `(bk: ${props.location.road.km})` : "")
            }
            </b>
            &nbsp;
          </>
        )}
      </Display.If>
      { Format.Location.address(props.location) }
    </div>
  )}
  </Display.If>
);
const BlockDescriptionMap = (props) => (
  <Display.If condition={props.location && props.location.position && props.withMap}>
  {() => {
    return  (
      <div className="bs-print-issue-description-map">
        <Ressource>
        {loaded => (
          <Map type={ Map.Static } height="180" width="1200" zoom="16" onLoaded={ loaded }>
            <Map.Shape type={ props.location.position.type } geometry={ props.location.position } icon={`${process.env.PUBLIC_URL}/images/maps/generic_open.png` } />
          </Map>
        )}
        </Ressource>
      </div>
    )
  }} 
  </Display.If>
);
const BlockDescriptionAssignmentDate = (props) => (
  <Display.If condition={ props.assignment.scheduledFrom || (props.assignment.deadline && props.withDeadline) }>
    <p>
      <div className="bs-print-issue-description-text-assignmentDate">
        <Display.If condition={ props.assignment.scheduledFrom }>
          <div className="bs-print-issue-description-text-assignmentDate-scheduled" >
            <span>Planification&nbsp;:&nbsp;</span>
            <span><Period start={ props.assignment.scheduledFrom } end={ props.assignment.scheduledTo } /></span>
          </div>
        </Display.If>
        <Display.If condition={ props.assignment.deadline && props.withDeadline }>
          <div className="bs-print-issue-description-text-assignmentDate-deadline">
            <div>
              Échéance:&nbsp;
              <b>{ moment(props.assignment.deadline).format("DD/MM/YYYY")}</b>
            </div>
          </div>
        </Display.If>
      </div>
    </p>
  </Display.If>
);
const BlockDescriptionAssignment = (props) => (
  <>
    <BlockDescriptionAssignmentDate withDeadline={ props.withDeadline } assignment={ props.assignment } />
    <Display.If condition={ props.assignment.team.length}>
      <p>
        Assigné à:&nbsp;
        <b>{ props.assignment.team.map(t => t.name).join(" & ") }</b>
      </p>
    </Display.If>
    <Display.If condition={ props.assignment.agents.length}>
      <p>
        Assigné à:&nbsp;
        <b>{ props.assignment.agents.map(a => Format.User.longFullname(a)).join(" & ") }</b>
      </p>
    </Display.If>
    <Display.If condition={ props.assignment.workInstructions }>
      <p>
        Consignes:&nbsp;
        <b>{ props.assignment.workInstructions }</b>
      </p>
    </Display.If>
    <Display.If condition={ props.assignment.necessariesEquipments && props.assignment.necessariesEquipments.length }>
      <p>
        Équipements nécessaires:&nbsp;
        <b>{ props.assignment.necessariesEquipments.map(e => e.name).join(", ") }</b>
      </p>
    </Display.If>
    <Display.If condition={ props.assignment.necessariesSupplies && props.assignment.necessariesSupplies.length }>
      <p>
        Fournitures nécessaires:&nbsp;
        <b>{ props.assignment.necessariesSupplies.map(s => `${s.supply.name}${s.quantity ? ` (${s.quantity} ${s.supply.valorization?.type})` : ""}`).join(", ") }</b>
      </p>
    </Display.If>
  </>
);
const BlockDescriptionContent = (props) => {
  const assignments = props.issue.assignment ? [props.issue.assignment] : props.issue.assignments;
  return (
    <>
      <BlockDescriptionMap withMap={props.withMap} location={ props.issue.location } />
      <p><b><T>state</T></b>&nbsp;:&nbsp;<T>{ props.issue.state }</T></p>
      <p>{ props.issue.description }</p>
      <Display.If condition={ assignments.length && !isEmptyAssignment(assignments[0].toPlainText()) }>
        <p><b><T>assignments</T></b></p>
          { assignments.map((assignment, idx) => (
            <div className="bs-print-issue-description-content-assignments">
              <BlockDescriptionAssignment  withDeadline={ props.withDeadline } assignment={ assignment } />
              {(idx > 0 && idx < assignments.length - 1) && <div className="bs-print-issue-description-content-assignments-separator"></div> }
            </div>
          ))}
      </Display.If>      
    </>
  )
}

const BlockDescriptionIllustration = Application.forward(["configuration"], [["file", "fileHelper"]], (props) => (
  <Display.If condition={ props.withIllustration  && props.file }>
  {() => (
    <div className="bs-print-issue-description-illustration">
      <Image src={ `${props.fileHelper.makeStorageUrl(props.file, {
        fit: "inside",
        width: 250,
        height: 500,
        format:"png"
      })}`} />
    </div>
  )}
  </Display.If>
));

const BlockDescriptionText = (props) => (
  <div className="bs-print-issue-description-text">
    <div className="bs-print-issue-description-text-header">
      <div className="bs-print-issue-description-text-header-left">
        <BlockTitle 
          concernsBuilding={ props.issue.location && props.issue.location.building }
          bsIdNumber={ props.issue.bsIdNumber }
          category={ props.issue.category.label.fr }
        />
        <Display.If condition={ props.subjectInHeader }>
          <BlockSubject issue={ props.issue } />
        </Display.If>
      </div>
      <div className="bs-print-issue-description-text-header-right">
        <BlockCreated 
          createdAt={ props.issue.createdAt }
          createdBy={ props.issue.createdBy }
          requestor={ props.issue.requestor }
        />
        <BlockManager
          withManagerLabel={ props.withManagerLabel }
          manager={ props.issue.manager }
        />
      </div>
    </div>
    <Display.If condition={ !props.subjectInHeader }>
      <BlockSubject issue={ props.issue } />
    </Display.If>
    <BlockDescriptionContent
      issue={ props.issue }
      withMap={ props.withMap }
      withDeadline={ props.withDeadline }
    />
  </div>
);

const BlockDescription = (props) => (
  <div className="bs-print-issue-description">
    <BlockDescriptionIllustration
      file={ props.illustration }
      withIllustration={ props.withIllustration && props.illustration }
    />
    <BlockDescriptionText
      issue={ props.issue }
      subjectInHeader={ !props.withIllustration || (props.withIllustration && !props.illustration) }
      withManagerLabel={ props.withManagerLabel }
      withMap={ props.withMap }
      withDeadline={ props.withDeadline }
    />
  </div>
);

const milimetreVersPixel = (longueurEnMm, dpi) => {
  const longeurEnPouce = longueurEnMm / 25.4;
  return Math.floor(longeurEnPouce * dpi);
};

const hauteurPagemm     = 297;
const largeurPagemm     = 210;
const dpi               = 300;

const hauteurMaxImage = milimetreVersPixel(hauteurPagemm / 2, dpi);
const largeurMaxImage = milimetreVersPixel(largeurPagemm, dpi);

const BlockPictures = Application.forward(["configuration"], [["file", "fileHelper"]], (props) => {
  const images      = props.files.filter(file => file.mimeType.startsWith("image") && file.storageId);
  //props.files.filter(file => file.mimeType === "application/pdf");
  const nbrByLines  = Math.ceil(Math.sqrt(images.length));
  const idxMax     = images.length - images.length % nbrByLines;
  const nbrLines    = Math.ceil(images.length / nbrByLines);
  const maxHeight   = Math.floor(hauteurMaxImage / nbrLines);
  return (
    <div className="bs-print-issue-list-image">
      <div>
        {
            images.map((file, idx) => {
              let maxWidth = nbrByLines;
              if(idx >= idxMax){
                maxWidth = images.length % nbrByLines;                  
              }
              let width = Math.floor(largeurMaxImage / maxWidth);
              return (
                <div key={file.storageId} className="bs-print-issue-image" style={{ "width": `calc(100% / ${maxWidth})`, "height": `calc(100% / ${nbrLines})`}}>
                  <Image src={ `${props.fileHelper.makeStorageUrl(file, {
                      fit: "contain",
                      width: width,
                      height: maxHeight,
                      format:"png"
                    })}`} 
                  />
                </div>
              )
            })
        }
      </div>
    </div>
  );
});
const hauteurPdf = milimetreVersPixel(hauteurPagemm, dpi);
const largeurPdf = milimetreVersPixel(largeurPagemm, dpi);

const BlockPdf = (props) => {
  if(!props.withPdf) return null;
  const pdfs = props.files.filter(file => file.mimeType === "application/pdf" && file.storageId);
  if(!pdfs.length) return null;
  return (
    <div className="bs-print-issue-list-pdfs">
    {
      pdfs.map(pdf => (
        <Pdf file={ pdf } width={ largeurPdf } height={ hauteurPdf } />
      ))
    }
    </div>
  )
};

export {
  BlockDescription,
  BlockPictures,
  BlockPdf
}