import React from "react";

import "./number.css";

const Number = (props) => {
  let component = null;
  if(props.children){
    component = props.children(props.datas);
  }else{
    component = (<span>{ props.datas }</span>)
  }
  return (
    <div style={{ color: props.color }} className="bs-statistics-number">{ component }</div>
  );
};

export default Number;