class BsString {
  static escapeSpecialsCharacters(string) {
    if (!string)
      return ''

    const result = []

    string = string.toLowerCase()

    const values = string.split(' ')
    values.forEach(function (value) {
      const a = '[aâàä]'
      const e = '[eéèêë]'
      const o = '[oôòøö]'
      const u = '[uùûü]'
      const i = '[iïî]'
      const c = '[cç]'
      for (let index = value.length; index >= 0; index--) {
        const letter = value[index]
        if (letter && a.indexOf(letter) !== -1) {
          value = replaceAt(value, index, a)
        } else if (letter && e.indexOf(letter) !== -1) {
          value = replaceAt(value, index, e)
        } else if (letter && o.indexOf(letter) !== -1) {
          value = replaceAt(value, index, o)
        } else if (letter && u.indexOf(letter) !== -1) {
          value = replaceAt(value, index, u)
        } else if (letter && i.indexOf(letter) !== -1) {
          value = replaceAt(value, index, i)
        } else if (letter && c.indexOf(letter) !== -1) {
          value = replaceAt(value, index, c)
        }
      }
      result.push(new RegExp(value, 'ig'))
    })
    function replaceAt(string, index, replace) {
      return string.substring(0, index) + replace + string.substring(index + 1)
    }
    return result
  }
}

export default BsString