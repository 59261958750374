import React from "react";
import Application from "@uBehaviour/application";
import _ from 'lodash';
import Display from "@uComponents/displayIf";
import moment from "moment";
import T from "@uBehaviour/i18n";

import "./weather.css";

const WeatherDay = ({ icon, date, tempMin, tempMax }) => (
  <div className="bs-weather-day">
    <img className="bs-weather-day-icon" src={ "http://openweathermap.org/img/wn/" + icon + "@4x.png" } />
    <div className="bs-weather-day-info">
      <div>
        <div className="bs-weather-day-title">
          { _.capitalize(moment(date).format('dddd')) }
        </div>
        <div className="bs-weather-day-temp">
          <span>Min: </span>
          <span className='bs-weather-day-temp-item bs-temp-min'>
            <T bind={{ temperature: Math.round(tempMin) }}>home_weather_temperature</T>
          </span>
          <span>Max: </span>
          <span className='bs-weather-day-temp-item bs-temp-max'>
            <T bind={{ temperature: Math.round(tempMax) }}>home_weather_temperature</T>
          </span>
        </div>
      </div>
    </div>
  </div>
);


const Weather = Application.Service.forward(["weather"], ({ weather }) => (
  <Application.Listen services={[ weather ]}>
  {() => (
    <div className="bs-home-box">
      <div className="bs-home-box-title"><T>home_weather</T></div>
      <div className="bs-weather-list">
        <Display.If condition={ weather.has() }>
        {() => weather.datas.slice(0, 5).map(data => (
          <WeatherDay key={data.dt} icon={data.weather[0].icon} date={moment(data.dt * 1000)} tempMin={data.temp.min} tempMax={data.temp.max} />
        ))}
        </Display.If>
      </div>
    </div>
  )}
  </Application.Listen>
));

export default Weather;