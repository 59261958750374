import noPhoto from "@root/assets/no_photo_little_all_tags.png"

const __CONFIG = window.__CONFIG;

function makeStorageUrl(file, params = null) {
  if (!file || !file.storageId) {
    return noPhoto;
  }
  let paramsString = "";
  if (params){
    if (params.fit) {
      paramsString = paramsString + `f_${params.fit}`;
    }
    if (params.width) {
      paramsString = paramsString + `${paramsString.length ? "," : ""}w_${params.width}`;
    }
    if (params.height) {
      paramsString = paramsString + `${paramsString.length ? "," : ""}h_${params.height}`;
    }
    if (file.meta && file.meta.rotate) {
      paramsString = paramsString + `${paramsString.length ? "," : ""}r_${file.meta.rotate}`;
    }
  }   
  return `${__CONFIG.fileStorage.url}/${paramsString}${ params && Object.keys(params).length ? "/" : ""}${file.storageId}${params && params.format ? "." + params.format : ""}`;
}

export {
  makeStorageUrl
}