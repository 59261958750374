import React, { FunctionComponent } from 'react';
import _ from 'lodash';

interface MoneyProps {
  value: number;
  unit?: string;
}
const Money: FunctionComponent<MoneyProps> = ({ value, unit = '€' }) => {
  const money = React.useMemo(() => ((value / 100).toFixed(2) + '').replace('.', ','), [value]);
  if(_.isNil(value)){
    return (
      <span>
        <span>-,--</span>
        <span>{ unit }</span>
      </span>
    );
  }
  return (
    <span>
      <span>{ money }</span>
      <span>{ unit }</span>
    </span>
  );
};

export default Money;