import React, { ComponentClass, FormEvent, FunctionComponent, PropsWithChildren, ReactNode } from "react";
import useService from "@universal/behaviour/hooks/useService";
import SessionService from "@universal/services/session";
import Text, { Paragraph, Style } from "@cComponents/text";
import T from "@universal/behaviour/i18n";
import Input from "@cComponents/input";
import { Checkbox, CheckboxProps } from "semantic-ui-react";
import inputWithStarterDesign, { InputUniqueValueProps } from "@hoc/inputWithStarterDesign";
import ConfigurationService from "@universal/services/configuration";
import Template from "@components/starterTemplate";
import Flow                 from '@cComponents/flow';
import Columns from "@common/components/layout/columns";
import Form from "@universal/behaviour/form";
import cButton from "@cComponents/button";
import ApiService from "@universal/services/api";
import { withIfElseCallback } from "@universal/hoc/withIfElse";
import MessageService from "@common/services/message";
import I18nService from "@universal/services/i18n";

import './activation.css';

const TextInput = inputWithStarterDesign(Input.Text as ComponentClass<InputUniqueValueProps<string>>);

const InputSelect = inputWithStarterDesign(Input.Select as ComponentClass<InputUniqueValueProps<string>>);

const Button = cButton.withStyle(cButton.Stylized.orange.big.fluid.round);

type LabelProps = {
  mandatory?: boolean;
}
const Label: FunctionComponent<PropsWithChildren<LabelProps>> = ({ mandatory = false, children }) => (
  <div className="bs-starterActivation-label">
    { children }
    { mandatory && <Text style={ Style.super.orange }>*</Text> }
  </div>
);

type FieldProps = {
  label: ReactNode;
  mandatory?: boolean;
}
const Field: FunctionComponent<PropsWithChildren<FieldProps>> = ({ label, mandatory = false, children }) => (
  <div className="bs-starterActivation-field">
    <Label mandatory={ mandatory }>{ label }</Label>
    { children }
  </div>
);

type FormValue = {
  email: string;
  siren: string;
  phone: string;
  civility: string;
  lastname: string;
  firstname: string;
};


const regexEmail = /.+@.+/;
const validateEmail = (email: string) => {
  return email.length > 0 && regexEmail.test(email);
};

const luhnValidation = (value: string) => {
  let sum = 0;
  for (let i = 0; i < value.length; i++) {
    let digit = parseInt(value[i]);
    if (i % 2 === 1) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
  }
  return sum % 10 === 0;
};

const regexSiren = /^[0-9]{9}$/;
const validSirenNotEmpty = (siren: string) => siren.length > 0;
const validSirenFormat = (siren: string) => regexSiren.test(siren);
const validSiren = (siren: string) => validSirenNotEmpty(siren) && validSirenFormat(siren) && luhnValidation(siren);



type EmailProps = {
  onValidate: () => void;
  value: Pick<FormValue, "email">;
}
const Email: FunctionComponent<EmailProps> = ({ onValidate, value }) => {
  const configuration = useService<ConfigurationService>("configuration");
  const session = useService<SessionService>("session");
  const emailValid = React.useMemo(() => validateEmail(value.email), [value.email]);
  
  const [accepted, setAccepted] = React.useState<boolean>(false);
  
  const onCheckboxChange = React.useCallback((_: FormEvent<HTMLInputElement>, { checked }: CheckboxProps ) => {
    setAccepted(!!checked);
  }, [setAccepted]);

  const generalConditionLink = React.useMemo(() => (
    <a href={configuration.get("starterConditionGeneral") } target="_blank" rel="noreferrer">
      <T>starterActivation_email_generalConditions</T>
    </a>
  ), [configuration]);

  const connect = React.useMemo(() => (
    <Text style={ Style.orange.bold } onClick={() => session.logout() }>
      <T>starterActivation_email_connect</T>
    </Text>
  ), [session]);

  return (
    <div className="bs-activationStarter-email">
      <Label mandatory>
        <Text style={ Style.bold.large }>
          <T>starterActivation_email_title</T>
        </Text>
      </Label>
      <Paragraph style={ Style.justify }>
        <T>starterActivation_email_advice</T>
      </Paragraph>
      <Paragraph style={ Style.justify }>
        <T>starterActivation_email_confirmation</T>
      </Paragraph>
      <Form.Simple.InputAdapter name="email">
      {(email, setEmail) => (
        <TextInput value={ email } onChange={ setEmail } valid={ emailValid } icon="user" />
      )}
      </Form.Simple.InputAdapter>
      <div className="bs-activationStarter-email-acceptGeneralConditions">
        <Checkbox onChange={ onCheckboxChange } checked={ accepted } />
        <Text style={ Style.justify }>
          <T bind={{ generalConditionLink, space: (<>&nbsp;</>) }}>starterActivation_email_acceptGeneralConditions</T>
        </Text>
      </div>
      <div className="bs-activationStarter-email-action">
        <Button onClick={ onValidate } disabled={ !emailValid || !accepted }>
          <T>starterActivation_email_validate</T>
        </Button>
      </div>
      <div className="bs-activationStarter-email-alreadyHaveAnAccount">
        <Paragraph style={ Style.center }>
          <T bind={{ connect }}>starterActivation_email_alreadyHaveAnAccount</T>
        </Paragraph>
      </div>
    </div>
  );
};
type StepMailProps = {
  flow: Flow;
  value: Pick<FormValue, "email">;
}
const StepMail: FunctionComponent<StepMailProps> = ({ flow, value }) => {
  const nextStep = React.useCallback(() => {
    flow.next();
  }, [flow]);
  return (<Email onValidate={ nextStep } value={ value }/>);
};

type Error = {
  path: string;
  error: string;
  original: any;
}

type ErrorProps = {
  errors: Error[];
}

const Error: FunctionComponent<ErrorProps> = ({ errors }) => (
  <ul className="bs-activationStarter-error">
    { errors.map((error, index) => (
      <li key={ index }>
        <Text style={ Style.colorError }>
          <T bind={ error.original }>{ error.error }</T>
        </Text>
      </li>
    ))}
  </ul>
);

const ErrorIfLength = withIfElseCallback(Error, null, ({ errors }) => errors.length > 0);


type AlmostCreatedProps = {
  validate: () => void;
  value: FormValue;
}
const AlmostCreated: FunctionComponent<AlmostCreatedProps> = ({ validate, value }) => {
  return (
    <>
      <Columns className="bs-activationStarter-columns">
        <Columns.Column className="bs-activationStarter-column">
          <Field mandatory label={ <T>starterActivation_almostCreated_siren</T> }>
            <Form.Simple.Adapter name="siren">
            {(errors: Error[], siren: string, setSiren: (value: string) => void) => (
              <>
                <TextInput value={ siren } onChange={ setSiren } />
                <ErrorIfLength errors={ errors }/>
              </>
            )}
            </Form.Simple.Adapter>
          </Field>
        </Columns.Column>
        <Columns.Column className="bs-activationStarter-column">
          <Field mandatory label={ <T>starterActivation_almostCreated_phone</T> }>
            <Form.Simple.Adapter name="phone">
            {(errors: Error[], phone: string, setTelephon: (value: string) => void) => (
              <>
                <TextInput value={ phone } onChange={ setTelephon } />
                <ErrorIfLength errors={ errors }/>
              </>
            )}
            </Form.Simple.Adapter>
          </Field>
        </Columns.Column>
      </Columns>
      <div className="bs-activationStarter-almostCreated-advice">
        <Text style={ Style.bold }>
          <T>starterActivation_almostCreated_infoTechnicContact</T>
        </Text>
        <Paragraph>
          <T>starterActivation_almostCreated_advice</T>
        </Paragraph>
      </div>
      <Field mandatory label={ <T>starterActivation_almostCreated_civility</T> }>
        <Form.Simple.Adapter name="civility" >
        {(errors: Error[], civility: string, setCivility: (value: string) => void) => (
          <>
            <InputSelect value={ civility } onChange={ setCivility } fluid>
              <Input.Select.Value value="female">
                <T>starterActivation_almostCreated_civility_female</T>
              </Input.Select.Value>
              <Input.Select.Value value="male">
                <T>starterActivation_almostCreated_civility_male</T>
              </Input.Select.Value>
            </InputSelect>
            <ErrorIfLength errors={ errors }/>
          </>
        )}
        </Form.Simple.Adapter>
      </Field>
      <Columns className="bs-activationStarter-columns">
        <Columns.Column className="bs-activationStarter-column">
          <Field mandatory label={ <T>starterActivation_almostCreated_contactName</T> }>
            <Form.Simple.Adapter name="lastname">
            {(errors: Error[], lastname: string, setLastName: (value: string) => void) => (
              <>
                <TextInput value={ lastname } onChange={ setLastName } />
                <ErrorIfLength errors={ errors }/>
              </>
            )}
            </Form.Simple.Adapter>
          </Field>
        </Columns.Column>
        <Columns.Column className="bs-activationStarter-column">
          <Field mandatory label={ <T>starterActivation_almostCreated_contactFirstName</T> }>
            <Form.Simple.Adapter name="firstname">
            {(errors: Error[], firstname: string, setFirstName: (value: string) => void) => (
              <>
                <TextInput value={ firstname } onChange={ setFirstName } />
                <ErrorIfLength errors={ errors }/>
              </>
            )}
            </Form.Simple.Adapter>
          </Field>
        </Columns.Column>
      </Columns>
      <div className="bs-activationStarter-almostCreated-send">
        <Button onClick={ validate }>
          <T>starterActivation_almostCreated_validate</T>
        </Button>
      </div>
    </>
  );
};

const AlmostCreatedHeader: FunctionComponent = ({}) => (
  <div>
    <Paragraph style={ Style.center.bold }>
      <T>starterActivation_almostCreated_title</T>
    </Paragraph>
    <Paragraph style={ Style.center }>
      <T>starterActivation_almostCreated_informationMissing</T>
    </Paragraph>
  </div>
);

type StepAlmostCreatedProps = {
  flow: Flow;
  submit: (force?: boolean) => Promise<void>;
  value: FormValue;
}
const StepAlmostCreated: FunctionComponent<StepAlmostCreatedProps> = ({ value, submit, flow }) => {
  const submitForm = React.useCallback(() => {
    submit(true).then(() => {
      flow.next();
    });
  }, [flow]);
  return (<AlmostCreated validate={ submitForm } value={ value }/>);
};

type FinalizeProps = {
  value: FormValue;
  resend: () => void;
  gotoStart: () => void;
}

const Finalize: FunctionComponent<FinalizeProps> = ({ value, resend, gotoStart }) => {
  const email = React.useMemo(() => (<Text style={ Style.orange }>{ value.email }</Text>), [value]);

  const resendMail = React.useMemo(() => (
    <Text style={ Style.orange.bold } onClick={ resend }>
      <T>starterActivation_finalize_resendMail</T>
    </Text>
  ), [resend]);

  const updateEmail = React.useMemo(() => (
    <Text style={ Style.orange.bold } onClick={ gotoStart }>
      <T>starterActivation_finalize_updateEmail</T>
    </Text>
  ), [gotoStart]);
  
  return (
    <>
      <Text.Paragraph style={ Style.center.bold }>
        <T>starterActivation_finalize_lastStep</T>
      </Text.Paragraph>
      <div className="bs-activationStarter-finalize-envelope">
        <img src="/images/starter/envelope.png" alt="Enveloppe" />
      </div>
      <Text.Paragraph>
        <T bind={{ email }}>starterActivation_finalize_emailSendInfo</T>
      </Text.Paragraph>
      <Text.Paragraph style={ Style.bold }>
        <T>starterActivation_finalize_confirmEmail</T>
      </Text.Paragraph>
      <div className="bs-activationStarter-finalize-bottom">
        <Text.Paragraph style={ Style.center }>
          <T bind={{ resendMail }}>starterActivation_finalize_resendMailInfo</T>
        </Text.Paragraph>
        <Text.Paragraph style={ Style.center }>
          <T bind={{ updateEmail }}>starterActivation_finalize_updateEmailInfo</T>
        </Text.Paragraph>
      </div>
    </>
  );
}

type StepFinalizeProps = {
  value: FormValue;
  submit: (force?: boolean) => Promise<void>;
  flow: Flow;
}
const StepFinalize: FunctionComponent<StepFinalizeProps> = ({ value, submit, flow }) => {
  const gotoStart = React.useCallback(() => {
    flow.goTo(0);
  }, [flow]);
  const message = useService<MessageService>("message");
  const i18n = useService<I18nService>("i18n");

  const resend = React.useCallback(() => {
    submit(true).then(() => message.send("info", i18n.translate("starterActivation_resend_password_confirmation"))
  )}, [submit, message]);

  return (<Finalize value={ value } gotoStart={ gotoStart } resend={ resend } />);
};

const getDefaultValue = <T extends { email: string, siren: string }>(user: T) => ({
  email: user.email,
  siren: user.siren,
  phone: '',
  civility: '',
  lastname: '',
  firstname: ''
});

const validation = {
  schema: {
    email: "email",
    siren: { type: "string", length: 9 },
    phone: { type: "string", min: 1 },
    civility: { type: "string", enum: ["female", "male"] },
    lastname: { type: "string", min: 1 },
    firstname: { type: "string", min: 1 }
  },
  rules: [
    (value: FormValue) => {
      if(!validSiren(value.siren)) {
        return { path: "siren", "error": "starterActivation_almostCreated_siren_luhnInvalid" };
      }
    }
  ]
};

const Activation = ({}) => {
  const user = useService<SessionService>("session").user;
  const api = useService<ApiService>("api");
  const submit = React.useCallback((form, value) => {
    return api.service("starterActivation", "sendConfirmEmail").execute(value);
  }, [api]);

  return (
    <Template>
      <Form.Simple default={ getDefaultValue(user) } submit={ submit } validator={ validation }>
      {(context, value, errors, form, submit) => (
        <Flow animate>
          <Flow.Step>
          {(context, flow) => (
            <div className="bs-activationStarter-activation">
              <StepMail flow={ flow } value={ value }/>
            </div>
          )}
          </Flow.Step>
          <Flow.Step>
          {(context, flow, active) => (
            <>
              { active && <Template.Header><AlmostCreatedHeader /></Template.Header>}
              <div className="bs-activationStarter-activation">
                <StepAlmostCreated flow={ flow } value={ value } submit={ submit }/>
              </div>
            </>
          )}
          </Flow.Step>
          <Flow.Step>
          {(context, flow) => (
            <div className="bs-activationStarter-activation">
              <StepFinalize flow={ flow } value={ value } submit={ submit }/>
            </div>
          )}
          </Flow.Step>
        </Flow>
      )}
      </Form.Simple>
    </Template>
  )
};

export default Activation;