// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issueAndAssignment-priority-moderate {
  color: #ff9800;
}

.bs-issueAndAssignment-priority-urgent {
  color: #c0392b;
}
`, "",{"version":3,"sources":["webpack://./src/entities/issueAndAssignment/priority.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".bs-issueAndAssignment-priority-moderate {\n  color: #ff9800;\n}\n\n.bs-issueAndAssignment-priority-urgent {\n  color: #c0392b;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
