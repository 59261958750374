import React, { FunctionComponent } from "react";
import File from '@cComponents/file';
import FileType from '@uTypes/business/File';
import Entityable from '@uTypes/technic/Entityable';
import useGet from '@uBehaviour/data/hooks/useGet';
import { Grid } from 'semantic-ui-react';

import './serviceStatementFiles.css';

interface SubjectFilesProps {
  files: Entityable<FileType>[];
}

const ServiceStatementFiles: FunctionComponent<SubjectFilesProps> = ({ files }) => {
  const filesEntities = useGet<FileType>("File", files.map(f => ({ _id: f })));
  return (
    <Grid.Row width={16}>
      <Grid.Column width={16}>
        <div className="bs-valorizations-serviceStatement-files">
        { filesEntities?.length && (
          filesEntities.map(f => (
            <File key={f._id} file={ f } fit="cover" height={120} width={120} openable />
          ))
        )}
        </div>
      </Grid.Column>
    </Grid.Row>
  )
}

export default ServiceStatementFiles;