import React                  from "react";
import Data                   from "@uBehaviour/data";
import T                      from "@uBehaviour/i18n";
import moment                 from "moment";
import Pages                  from "./components/pages";
import Filters                from "./components/filters";
import Location               from "@uLib/location";
import Application            from "@uBehaviour/application";
import File                   from "@cComponents/file";
import Display                from '@uComponents/displayIf';
import Query                  from '@uLib/query';
import useService             from "@uBehaviour/hooks/useService";
import usePager from '@uBehaviour/data/hooks/usePager';

import "./teamsCalendar.css";

class AgentCollector{
  constructor(agent, globalCollector){
    this._teamCollector = globalCollector.getTeam(agent.team);  
  }

  addAssignment(assignment){
    this._teamCollector.addAssignment(assignment);
  }
}
class TeamCollector{
  constructor(team){
    this._team = team;
    this._assignments = [];  
  }

  get name(){
    return this._team.name;
  }

  addAssignment(assignment){
    this._assignments.push(assignment);
  }

  get(maxDay){
    const tabs = [];
    const pushLine = () => {
      const line = [];
      for(let i = 0; i < maxDay; i++){
        line.push(null);
      }
      tabs.push(line);
    };
    pushLine();
    this._assignments.sort((a1, a2) => a2.nbr - a1.nbr).forEach(assignment => {
      let found = false;
      let index = 0;
      while(index < tabs.length && !found){
        let i = 0;
        for(; i < assignment.nbr && !tabs[index][assignment.startIndex + i]; ++i);
        if(i === assignment.nbr){
          for(i = 0; i < assignment.nbr; ++i){
            tabs[index][assignment.startIndex + i] = assignment;
          }
          found = true;
        }else{
          ++index;
        }
      }
      if(!found){
        pushLine();
        for(let i = 0; i < assignment.nbr; ++i){
          tabs[tabs.length - 1][assignment.startIndex + i] = assignment;
        }
      }
    });
    return {
      team: this._team,
      tabs: tabs
    };
  }
}

class GlobalCollector{
  constructor(){
    this._teamCollectors  = {};
    this._agentCollectors = {};
  }
  getAgent(agent){
    if(!this._agentCollectors[agent._id]){
      this._agentCollectors[agent._id] = new AgentCollector(agent, this);
    }
    return this._agentCollectors[agent._id];
  }
  getTeam(team){
    if(!this._teamCollectors[team._id]){
      this._teamCollectors[team._id] = new TeamCollector(team);
    }
    return this._teamCollectors[team._id];
  }
  addAssignment(assignment){
    assignment.datas.assignment.team.forEach(team => {
      this.getTeam(team).addAssignment(assignment);
    });
    assignment.datas.assignment.agents.forEach(agent => {
      this.getAgent(agent).addAssignment(assignment);
    });
  }
  get(maxDay){
    return Object.values(this._teamCollectors).sort((u1, u2) => { return u1.name.localeCompare(u2.name) }).map(collector => {
      return collector.get(maxDay)
    });
  }
}



const TeamCalendar = (props) => {
  const currentTenant = useService("currentTenant");
  const start = moment(props.start);
  const end   = moment(props.end);
  const defaultQuery = { "assignment.scheduledFrom": { $lt: props.end }, "assignment.scheduledTo":   { $gt: props.start } };
  const query = Query.joinWithOptimizer((props.query ? JSON.parse(props.query) : defaultQuery), { tenant: currentTenant.currentId });

  const { datas: assignments, allElementsLoaded } = usePager({
    model: "Assignment",
    query : query,
    load: {
      'assignment.agents': { team: true },
      'assignment.team': { "members": true },
      "location.building": true,
      "equipment": true
    },
    loadAll: true
  });
  
  if(!allElementsLoaded) {
    return null;
  }

  const collector = new GlobalCollector(start, end);
  assignments.forEach(assignment => {
    const a = {
      start: moment(assignment.assignment.scheduledFrom) >= start ? moment(assignment.assignment.scheduledFrom) : start,
      end: moment(assignment.assignment.scheduledTo) <= end ? moment(assignment.assignment.scheduledTo) : end,
      datas: assignment
    };
    a.startIndex = Math.floor(moment.duration(a.start.diff(start)).asDays());;
    a.nbr = Math.ceil(moment.duration(a.end.diff(a.start)).asDays());
    collector.addAssignment(a);
  });
  const datas = collector.get(Math.ceil(moment.duration(end.diff(start)).asDays()));
  
  return (
    <Pages type="landscape">
      <div className="bs-print-overlay">
        <div>
          <h1>
            <T>print_ready</T>
          </h1>
        </div>
      </div>
      <table className="bs-table-main">
        <thead>
          <Header start={start} end={end}/>
        </thead>
        <thead>
          <Filters filters={props.filters} />
        </thead>
        <tbody>
          <div>
            <TableTeams start={start} end={end}>
            {
              datas.map((data, index) => (
                <Team data={ data } isEven={ !(index % 2)}/>
              ))
            }
            </TableTeams>
          </div>
        </tbody>
      </table>
    </Pages>
  );
}

const Header = Application.Service.forward(["currentTenant"], ({ start, end, currentTenant }) => {
  let dateHeader = "";
  if(start.month() !== end.month()){
    if(start.year() !== end.year()){
      dateHeader = start.format('MMM YYYY') + " - " + end.format('MMM YYYY');
    }else{
      dateHeader = start.format('MMM') + " - " + end.format('MMM YYYY');
    }
  }else{
    dateHeader = start.format('MMMM YYYY');
  }
  
  return (
    <Data.Query model="File" query={{ _id: currentTenant.current.informations.pictures.logo }}>
    {(file) => {
      window.setTimeout(() => {
        window.print();
        window.close();
      }, 200);
      return (
        <div className="bs-teams-header">
          <div className="bs-teams-header-file"><File file={ file.length ? file[0] : null } width={32} height={32} fit="contains"/></div>
          <div className="bs-teams-header-infos">
            <div><T>teams_planning</T></div>
            <div className="bs-teams-header-date">{ dateHeader.charAt(0).toUpperCase() + dateHeader.slice(1) }</div>
            <div className="bs-teams-header-week">{ `Semaine ${start.format('W')}` }</div>
            <div className="bs-teams-header-printdate">{ `Imprimé le ${moment().format('DD/MM/YYYY')} à ${moment().format('HH:mm')}` }</div>
          </div>          
        </div>
      );
    }}
    </Data.Query>
  )
});

class TableTeams extends React.Component{
  constructor(props) {
    super(props);

    const { start, end } = this.props;
    this._days  = [];
    let day     = start.clone();
    while(day < end){
      this._days.push(day);
      day = day.clone().add(1, "day");
    }
  }
  componentDidMount(){
    const styles = `
      .bs-table-teams td{
        width: ${ 1 / (this._days.length + 1) * 100}%
      }
    `;
    const styleSheet      = document.createElement("style");
    styleSheet.type       = "text/css";
    styleSheet.innerText  = styles;
    document.head.appendChild(styleSheet);
  }
  render(){
    const { children } = this.props;
    return (
      <table className="bs-table-teams">
        <thead>
          <tr>
            <td><T>teams</T></td>
            { 
              this._days.map(day => (
                <td><span>{ day.format("dddd")}</span><br/><span>{ day.format("D") }</span></td>
              ))
            }
          </tr>
        </thead>
        <tbody>
        { children }
        </tbody>
      </table>
    )
  }
}

const Team = ({ data, isEven }) => {
  return (
    <>
    {
      data.tabs.map((tab, index) => {
        let first = index === 0 ? (<td className="bs-table-teams-row-header" rowSpan={"" + data.tabs.length }>{ data.team.name }</td>) : null;
        return (
          <tr className={ `bs-table-teams-row-${ isEven ? "even" : "odd" } bs-table-teams-row-${index}` }>
            { first }
            {
              tab.map(tab => (
                <td>
                { tab ? <Assignment assignment={ tab.datas } team={data.team} /> : null }
                </td>
              ))
            }
          </tr>
        )
      })
    }
    </>
  )
}

const Assignment = ({ assignment, team }) => {
  return (
    <div className="bs-table-teams-assignment">
      <div className="bs-table-teams-header">
        <div className={ `fa fa-exclamation-triangle ${ assignment.priority === 1 ? "bs-table-agents-priority-normal" : assignment.priority === 3 ? "bs-table-agents-priority-middle" : "bs-table-agents-priority-urgent"}`} />
        <div className={"bs-table-teams-state-" + assignment.state}>{assignment.bsIdNumber}</div>
        <div>{`${assignment.assignment.team.length ? team.members.length : assignment.assignment.agents.length} agent(s)`}</div>
      </div>
      <Display.If condition={assignment.deadline}>
        <div className={ `bs-table-teams-deadline ${assignment.state !== "resolved" && moment(assignment.deadline).isBefore(moment()) ? "bs-table-teams-deadline-late" : ""}`}>
          <span><T>planning_print_deadline_for</T>&nbsp;</span>
          <span>{ moment(assignment.deadline).format("DD/MM/YYYY") }</span>
        </div>
      </Display.If>    
      <div className="bs-table-teams-address">
      {
        assignment.equipment
          ? assignment.equipment.name
          : assignment.location.building
            ? assignment.location.building.name
            : Location.getLabelFromAddress(assignment.location.address)
      }
      </div>
      <div>
      {
        assignment.description
      }
      </div>
    </div>
  );
}

export default TeamCalendar;