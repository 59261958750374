import React         from "react";
import T             from "@cBehaviour/i18n";
import Filter        from "@cComponents/filter";
import Input         from "@cComponents/input";
import Collapsable   from "@cComponents/collapsable";

export default (props) => (
  <>
    <Collapsable alwaysOpen>
      <Collapsable.Title><T>active_m</T></Collapsable.Title>
      <Collapsable.Content>
        <Filter.Generic multiple name="disabled" buildQuery={values => ({ "disabled": { $in: values } })} stringify={value => (<T>{!value ? "active_m" : "inactive_m"}</T>)}>
          {(values, add, drop, clear) => (
            <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
              <Input.Checkbox.Value value={false}><T>yes</T></Input.Checkbox.Value>
              <Input.Checkbox.Value value={true}><T>no</T></Input.Checkbox.Value>
            </Input.Checkbox.BtnFilter>
          )}
        </Filter.Generic>
      </Collapsable.Content>
    </Collapsable>
  </>
)