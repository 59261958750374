// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-input-duration{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bs-input-duration>.bs-input-numeric{
  width: 60px;
}
.bs-input-duration>.bs-select{
  margin-left: 10px;
  width: 130px;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/duration.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;AACA;EACE,iBAAiB;EACjB,YAAY;AACd","sourcesContent":[".bs-input-duration{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.bs-input-duration>.bs-input-numeric{\n  width: 60px;\n}\n.bs-input-duration>.bs-select{\n  margin-left: 10px;\n  width: 130px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
