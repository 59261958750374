// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-modal-form-print-calendar{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bs-modal-form-print-calendar-body{
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  overflow: hidden;
}
.bs-modal-form-print-calendar-body>div:first-child{
  display: flex;
  justify-content: space-between;
}
.bs-modal-form-print-calendar-separator{
  border-bottom: 1px solid #999;
  margin: 10px 25px;
}
.bs-modal-form-print-calendar .bs-field>div:first-child{
  font-weight: bold;
}
.bs-modal-form-print-calendar-teams{
  overflow: hidden;
  flex-grow: 1;
  flex-shrink: 1;
}

.bs-modal-form-print-calendar-actions{
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-top: 1px solid #ccc;
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/planning/form/printCalendar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;AACA;EACE,aAAa;EACb,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,6BAA6B;EAC7B,iBAAiB;AACnB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,0BAA0B;AAC5B","sourcesContent":[".bs-modal-form-print-calendar{\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n.bs-modal-form-print-calendar-body{\n  padding: 15px;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  flex-shrink: 1;\n  overflow: hidden;\n}\n.bs-modal-form-print-calendar-body>div:first-child{\n  display: flex;\n  justify-content: space-between;\n}\n.bs-modal-form-print-calendar-separator{\n  border-bottom: 1px solid #999;\n  margin: 10px 25px;\n}\n.bs-modal-form-print-calendar .bs-field>div:first-child{\n  font-weight: bold;\n}\n.bs-modal-form-print-calendar-teams{\n  overflow: hidden;\n  flex-grow: 1;\n  flex-shrink: 1;\n}\n\n.bs-modal-form-print-calendar-actions{\n  padding: 10px 15px;\n  display: flex;\n  justify-content: space-between;\n  background-color: #f5f5f5;\n  border-top: 1px solid #ccc;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
