// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-file-gallery-others {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.bs-file-gallery-others-item,
.bs-file-gallery {
  margin: 2px;
}

.bs-file-gallery-clickable:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../common/components/file/gallery.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;AACjB;;AAEA;;EAEE,WAAW;AACb;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".bs-file-gallery-others {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n}\n\n.bs-file-gallery-others-item,\n.bs-file-gallery {\n  margin: 2px;\n}\n\n.bs-file-gallery-clickable:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
