import React    from "react";
import Slot     from "./slot";
import Action   from "./action";
import Tooltip  from './tooltip';
import T        from '@uBehaviour/i18n';
import _        from "lodash";

import "./item.css";

class ItemActions extends React.Component{
  state = { open: false};
  constructor(props){
    super(props);
    this._update            = _.debounce(this._update.bind(this), 0);
    this._actions           = {};
  }
  register(id, icon, tooltip, handler, premise, dataTestId){
    this._actions[id]   = { icon, tooltip, handler, premise, dataTestId };
    this._update();
  }
  _update(){
    this.forceUpdate();
  }
  _switch(){
    this.setState({ open: !this.state.open });
  }
  _close = () => {
    this.setState({ open: false });
  }
  render(){
    if(!Object.keys(this._actions).length){
      return React.createElement(Action.Context.Provider, { value: this }, this.props.children);
    }
    const actionsId = Object.keys(this._actions).filter(id => this._actions[id].premise());
    if(!actionsId.length){
      return null;
    }
    let children = null;
    if(this.state.open){
      children= (
        <div  className="bs-item2-actions-container">
        { 
          actionsId.map((id, idx) => (
            <Tooltip key={ id } defaultVisible={idx === actionsId.length-1}>
              <Tooltip.Subject>
                <div 
                  key={ id }
                  onClick={ (ev) => { ev.stopPropagation(); ev.preventDefault(); this._actions[id].handler(); }} 
                  className={ `fa fa-${ this._actions[id].icon } fa-2x`} 
                  data-testid={ this._actions[id].dataTestId }
                />
              </Tooltip.Subject>
              <Tooltip.Info style={{}}>
                { this._actions[id].tooltip }
              </Tooltip.Info>
            </Tooltip>
          ))
        }
        </div>
      );
    }
    return (
      <div className="bs-item2-actions">
        <Tooltip>
          <Tooltip.Subject>
          <div className="bs-item2-actions-open-btn" onClick={(ev) => { ev.stopPropagation(); ev.preventDefault(); this._switch(); }}><span className="fa fa-ellipsis-v fa-2x" /></div>
          </Tooltip.Subject>
          <Tooltip.Info>
            <T>item_actions_tooltip</T>
          </Tooltip.Info>
        </Tooltip>
        { children }
      </div>
    )
  }
}

const Item = (props) => {
  const className    = props.className ? props.className : "bs-standart";
  const illustration = Item.Illustration.get({ props });
  const header       = Item.Content.Header.get({ props });
  const body         = Item.Content.Body.get({ props });
  const footer       = Item.Content.Footer.get({ props });
  const meta         = Item.Meta.get({ props });
  const actions      = Item.Actions.get({ props });
  
  const content      = !header && !body && !footer
    ? (<div className="bs-item2-content">{ Item.Content.get({ props }) }</div>)
    : (
        <div className="bs-item2-content bs-item2-content-parted">
          <div className="bs-item2-content-header">{header}</div>
          <div className="bs-item2-content-body">{body}</div>
          <div className="bs-item2-content-footer">{footer}</div>
        </div>
      )
  return (
    <div className={"bs-item2 " + className}>
      <div className="bs-item2-container">
        { illustration  ? (<div className="bs-item2-illustration">{ illustration }</div>) : null }
        { content }
        { meta          ? (<div className="bs-item2-meta">{ meta }</div>) : null }
      </div>
      { actions ? (<ItemActions>{ actions }</ItemActions>) : null}
    </div>
  )
}
Item.Illustration   = Slot();
Item.Content        = Slot();
Item.Content.Header = Slot();
Item.Content.Body   = Slot();
Item.Content.Footer = Slot();
Item.Meta           = Slot();
Item.Actions        = Slot();

export default Item;