// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tenant-form-holidayDays{
  display: flex;
  flex-direction: row;
}
.bs-tenant-form-holidayDays>div {
  flex-grow: 1;
  width: 50%;
}
.bs-tenant-form-holidayDays>div:nth-last-child(n) {
  margin-right: 5px;
}
.bs-tenant-form-holidayDays>div:nth-child(n+2) {
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/entities/tenant/holidayDays/holidayDays.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,UAAU;AACZ;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".bs-tenant-form-holidayDays{\n  display: flex;\n  flex-direction: row;\n}\n.bs-tenant-form-holidayDays>div {\n  flex-grow: 1;\n  width: 50%;\n}\n.bs-tenant-form-holidayDays>div:nth-last-child(n) {\n  margin-right: 5px;\n}\n.bs-tenant-form-holidayDays>div:nth-child(n+2) {\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
