// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-home-list{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}
.bs-home-list-title{
  display: flex;
  flex-direction: row;
}
.bs-home-list-title>span:first-child{
  flex-grow: 1;
}
.bs-home-list-title>span:last-child{
  background-color: #aaa;
  color: #fff;
  padding: 2px 4px;
  font-size: 13px;
  font-weight: 400;
  border-radius: 4px;
}
.bs-home-list>div:last-child{
  flex-grow: 1;
  overflow: hidden;
}
.bs-home-list-viewport{
  padding: 12px;
}

.bs-home-list-date{
  text-align: center;
}
.bs-home-list .bs-home-list-date:nth-child(n + 2){
  margin-top: 15px;;
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/home/list.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,sBAAsB;EACtB,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,aAAa;AACf;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".bs-home-list{\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n  height: 100%;\n}\n.bs-home-list-title{\n  display: flex;\n  flex-direction: row;\n}\n.bs-home-list-title>span:first-child{\n  flex-grow: 1;\n}\n.bs-home-list-title>span:last-child{\n  background-color: #aaa;\n  color: #fff;\n  padding: 2px 4px;\n  font-size: 13px;\n  font-weight: 400;\n  border-radius: 4px;\n}\n.bs-home-list>div:last-child{\n  flex-grow: 1;\n  overflow: hidden;\n}\n.bs-home-list-viewport{\n  padding: 12px;\n}\n\n.bs-home-list-date{\n  text-align: center;\n}\n.bs-home-list .bs-home-list-date:nth-child(n + 2){\n  margin-top: 15px;;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
