import React from 'react'
import classNames from 'classnames'
import T from 'i18n-react'
import ISSUE from 'common/ISSUE'

const IssueWorkorderDropDown = ({ defaultState, updateState }) =>
<div className='c-dropdown-menu'>
  <span className='c-dropdown-menu__arrow'></span>
  <div className='c-dropdown-menu__body c-dropdown-menu__body--states'>
    {
      ISSUE.workOrderStates.enum.filter(state => state !== defaultState).map(state =>
        <div className={ classNames('c-dropdown-menu__item') } key={ state }>
          <a
            className={ classNames('u-display-block u-color-white u-padding-tiny', `u-bg-${state}`) }
            onClick={ updateState.bind(null, state) }
          >
            { T.translate(state) }
          </a>
        </div>
      )
    }
  </div>
</div>


export default IssueWorkorderDropDown;