import Display from '@uComponents/displayIf';
import useOpenCloseToggle from '@universal/behaviour/hooks/useOpenCloseToggle';
import React, { FunctionComponent } from 'react';
import Button from './button';
import useService from '@universal/behaviour/hooks/useService';
import BusinessNotificationService from '@universal/services/businessNotification';
import FocusBlur from '@common/components/focusBlur';
import List from './list';
import FloatingBox from '@cComponents/floatingBox';
import Issue from '@universal/types/business/Issue';

import './notification.css';
import ApiService from '@universal/services/api';
import { BusinessEntity } from '@universal/types/technic/Entityable';

interface NotificationProps {
  onIssueSelected: (issue: BusinessEntity<Issue>) => void;
}

const Notification: FunctionComponent<NotificationProps> = ({ onIssueSelected }) => {
  const [isOpen, open, close, toggle] = useOpenCloseToggle();
  const notificationService = useService<BusinessNotificationService>('businessNotification', ['onNotificationChange']);
  const api = useService<ApiService>('api');

  const notifications = notificationService.get();

  const nbrNotSeen = React.useMemo(() => {
    return notifications.reduce((notSeen, notification) => notSeen + (notification.seen ? 0 : 1), 0);
  }, [notifications]);

  const markReadCloseAndOpenIssue = React.useCallback((issue: BusinessEntity<Issue>) => {
    close();
    onIssueSelected(issue);
    api.service('notifications', 'read').execute(issue._id);
  }, [onIssueSelected, close, api]);

  const markSeenAndToggleNotification = React.useCallback(() => {
    if(nbrNotSeen){
      api.service('notifications', 'seen').execute();
    }
    toggle();
  }, [toggle, nbrNotSeen, api]);


  return (
    <div className='bs-notification'>
      <Button onClick={ markSeenAndToggleNotification } notificationNotSeen={ nbrNotSeen }/>
      <Display.If condition={ isOpen }>
        <FocusBlur hasFocus onBlur={ close } className='bs-notification-dropdown'>
          <FloatingBox>
            <List onIssueSelected={ markReadCloseAndOpenIssue } />
          </FloatingBox>
        </FocusBlur>
      </Display.If>
    </div>
  );
};

export default Notification;