import React from 'react'
import TextareaField from 'client/features/form/old/components/TextareaField.jsx'
import CheckboxField from 'client/features/form/old/components/CheckboxField.jsx'
import InputField from 'client/features/form/old/components/InputField.jsx'
import DraggableModalDialog from 'client/features/issues/components/modals/DraggableModalDialog'
import { Field } from 'redux-form'
import { Modal } from 'react-bootstrap'
import T from 'i18n-react'
import _ from 'lodash'

const IssueContactModal = ({
  show,
  onHide,
  template,
  submit,
  to,
  title,
  copyEmail,
  customTo,
  requiredMessage,
  fallback,
  footerActions
}) => (
  <Modal
    dialogComponentClass={ DraggableModalDialog }
    show={ show }
    onHide={ onHide }
    bsSize="large" className='modal-helper'
  >
    <Modal.Header closeButton className='u-cursor-move' className='modal-helper'>
      <Modal.Title>{ T.translate(title) }</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {
        fallback ?
          <T.p text={ fallback }/>
          :
          <div>
            <div className='row-fluid'>
              <div className='span1'>
                <strong>{ T.translate('from') }:</strong>
              </div>
              <div className='span11'>
                { template.fromName }
              </div>
            </div>
            <div className='row-fluid'>
              <div className='span1'>
                <strong>{ T.translate('to') }:</strong>
              </div>
              <div className='span11'>
                {
                  customTo &&
                  <Field component={ InputField } name='to' />
                }
                {
                  !customTo && _.isString(to) &&
                                        to
                }
                {
                  !customTo && _.isArray(to) &&
                                        to.map((receiver, index) => <div className='label-email-to' key={ index }>{ receiver }</div>)
                }
              </div>
            </div>
            <hr />
            <div className='row-fluid'>
              <div className='span1'>
                <strong>{ T.translate('mail_object') }:</strong>
              </div>
              <div className='span11'>
                { template.subject }
              </div>
            </div>
            <div className='row-fluid'>
              <div className='span12'>
                <strong>{ T.translate(requiredMessage ? 'form_comment' : 'mail_your_comment') }</strong>
                <Field name='message' component={ TextareaField }/>
              </div>
            </div>
            <div className='row-fluid'>
              <div className='span12' dangerouslySetInnerHTML={ { __html: template.body } } />
            </div>
            <div className='row-fluid'>
              <div className='span12' dangerouslySetInnerHTML={ { __html: template.footer } } />
            </div>
          </div>
      }
    </Modal.Body>
    <Modal.Footer>
      <div className='u-pull-left' style={{ marginLeft: "15px" }}>
        {
          footerActions && footerActions.map((Action, index) =>
            <div key={ index }>
              { Action }
            </div>
          )
        }
        {
          copyEmail && !fallback &&
          <Field
            name='emailCopy'
            component={ CheckboxField }
            label={ T.translate('send_me_a_copy') }
            style={ { display: 'inline-block', float: 'left', minHeight: 0 } }
            initialValues= { true }
          />
        }
      </div>
      <button className='button-a' type='button' onClick={ onHide }>
        { T.translate('cancel') }
      </button>
      <button className='button-a orange' type='submit' onClick={ submit }>
        { T.translate('Ok') }
      </button>
    </Modal.Footer>
  </Modal>
)

export default IssueContactModal