import React, { Component } from 'react'
import T from 'i18n-react'
import FontAwesome from 'react-fontawesome'
import ISSUE from 'common/ISSUE'
 

class FilesOverlay extends Component {

  constructor() {
    super()
    this._input               = React.createRef();
    this.displayFilesBrowser  = this.displayFilesBrowser.bind(this)
    this.inputChange          = this.inputChange.bind(this)
    this._onDrop              = this._onDrop.bind(this);
    this._dropArea            = React.createRef();
  }

  displayFilesBrowser() {
    this._input.current.click()
  }

  inputChange() {
    this.props.onDrop([...this._input.current.files])
  }
  _onDrop(ev) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    if (ev.dataTransfer && ev.dataTransfer.files && this.props.onDrop) {
      this.props.onDrop([...ev.dataTransfer.files]);
    }
  }
  componentDidMount(){
    this._dropArea.current.addEventListener("drop", this._onDrop);
    this._dropArea.current.addEventListener("dragover", ev => ev.preventDefault());
  }

  render() {
    const { showButton, close, text = 'drag_your_file', accept = ISSUE.files.accepted, multiple = true } = this.props

    return (
      <div className='view-dropzone' ref={ this._dropArea }>
        <div className='flex-container'>
          <div className='aligned no-result text-center'>
            <FontAwesome
              name='camera'
              size='4x'
              style={ { verticalAlign: 'middle', marginRight: '20px' } }
            />
            <FontAwesome
              name='file-pdf-o'
              size='4x'
              style={ { verticalAlign: 'middle' } }
            />
            <br />
            <span style={ { fontSize: '22px' } }>
              { T.translate(text) }
            </span>

            { <input
              ref={ this._input }
              type='file'
              accept={ accept }
              style={{ display: 'none' }}
              data-multiple-caption="{count} files selected"
              multiple={ multiple }
              onChange={ this.inputChange }
            />}
            {
              showButton &&
              <div style={ { marginTop: 12 } }>
                <a className='button-a orange' onClick={ this.displayFilesBrowser }>{ T.translate('click_here_to_select_files') }</a>
                <a className='button-a' onClick={ close }>{ T.translate('cancel') }</a>
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

export default FilesOverlay