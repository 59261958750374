import { Service }        from "@uLib/application";

export default class CampaignService extends Service{
  constructor(){
    super("campaign", ["session", "api", "acl"]);
    this._onUserLogginOrLoggout  = this._onUserLogginOrLoggout.bind(this);
    this._current = [];
  }
  start(){
    return this.waitReady(["session", "api", "acl"]).then(([session, api]) => {
      session.onServiceUpdated.addListener({
        handleEvent: this._onUserLogginOrLoggout
      });

      if(!this._isAllow()){
        return;
      }
      
      return this._prepare(api);
    });
  }
  _isAllow() {
    const session = this.application.getService("session");

    return session.isLogged() 
      && !session.isUserProWithoutTenant() 
      && session.user.discriminator == "pro";
  }
  _prepare(api){
    return api.service("campaigns", "current").execute().then(campaigns => {
      this._current = campaigns;
      this.triggerUpdate(this._current);
    });
  }
  _onUserLogginOrLoggout(){
    if(this._isAllow()){
      return this._prepare( this.application.getService("api"));
    }else{
      this._current = [];
      this.triggerUpdate(this._current);
    }
  }
  has(){
    return !!this._current.length;
  }
  get current(){
    return this._current.slice();
  }
  inform(campaign, responseId){
    const api = this.application.getService("api");
    return api.service("campaigns", "inform").execute(campaign, responseId)
      .then(() => this._prepare(api))
      .then(() => true);
  }
}