import React, { FunctionComponent } from 'react';
import { Grid } from 'semantic-ui-react';
import Money from '@cComponents/money';

interface CostProps {
  unitCost: number;
  cost: number;
}
const Cost: FunctionComponent<CostProps> = ({ unitCost, cost }) => {
  return (
    <>
      <Grid.Column width="3" textAlign='center'><Money value={ unitCost } /></Grid.Column>
      <Grid.Column width="3" textAlign='center'><Money value={ cost } /></Grid.Column>
    </>
  );
};

export default Cost;