import React from 'react'
import Field from 'client/features/form/old/components/fields/Field.jsx'
import FontAwesome from 'react-fontawesome'
import ReactSuperSelect from 'react-super-select'
import PropTypes from 'prop-types'
import T from 'i18n-react'
import BsString from 'client/lib/BsString'
import _ from 'lodash'
 

class IssueDetailsSelectField extends Field {
  constructor(props) {
    super(props)
    this._superSelect = React.createRef();
    this.suggestionsFilter = this.suggestionsFilter.bind(this)
    this.inputType = 'select'
  }

  onFieldChange(value) {
    if (this.props.onChange)
      return this.props.onChange(value)
    else if (this.props.form && this.props.form.setFieldValue)
      return this.props.form.setFieldValue(this.props.field.name, value)
    this.props.input.onChange(value)
  }

  suggestionsFilter(option, index, collection, searchTerm) {
    if (option.forceShow)
      return true
    const regexps = BsString.escapeSpecialsCharacters(searchTerm)
    let match = true
    regexps.forEach(regexp => {
      if (!option.label.match(regexp))
        match = false
    })
    return match
  }


  _renderField() {
    const {
      noResultsString,
      searchChange,
      clearSearchOnSelection = false,
      customClass,
      addNew,
      closeOnSelectedOptionClick = true,
      field = {},
      fieldPlaceholder,
      initialValue
    } = this.props;
    let value = {};
    if (initialValue && !this.props.value && (!this.props.input || !this.props.input.value)) {
      this.onFieldChange({ ...initialValue, name: initialValue.label, id: initialValue.value })
    }      
    if (this.props.value) {
      value = this.props.value;
    }
    if (this.props.input && this.props.input.value) {
      value = this.props.input.value;
    }
    const options = _.cloneDeep(this.props.options)
    if (addNew) {
      options.unshift({
        value: 'add_new',
        label: <span>
          <FontAwesome name='plus'/> { T.translate('create') } {this._superSelect.current && <i>{ this._superSelect.current.state.searchString }</i>}
        </span>,
        forceShow: true
      })
    }

    return (
      <div className='c-field-select'>
        <ReactSuperSelect
          ref={ this._superSelect }
          placeholder={ fieldPlaceholder }
          dataSource={
            options
              .filter((option) => !value || option.value !== value.value)
              .map((option) => ({ ...option, name: option.label, id: option.value }))
          }
          initialValue={ value && (value.value || value.value === null) ? { ...value, name: value.label, id: value.value } : undefined }
          onChange={ this.onFieldChange }
          searchable={ true }
          clearable={ false }
          openOnMount={ !this.props.closedOnMount }
          focusOnMount={ true }
          onCloseDropdown={ this.edit.bind(null, false) }
          noResultsString={ noResultsString || T.translate('no_result') }
          searchPlaceholder={ `${T.translate('search')}...` }
          customFilterFunction={ this.suggestionsFilter }
          onSearchInputChange={ searchChange }
          clearSearchOnSelection={ clearSearchOnSelection }
          customClass={ customClass }
          closeOnSelectedOptionClick={ closeOnSelectedOptionClick }
        />
        {
          this.props.showCross &&
            <FontAwesome
              name='remove'
              style={ { fontSize: '14px', paddingLeft: '4px', cursor: 'pointer' } }
              onClick={ this.edit.bind(null, false) }
            />
        }
        { this.props.meta && this.props.meta.touched && ((this.props.meta.error && <p className='error'>{this.props.meta.error}</p>)) }
      </div>
    )
  }
}

IssueDetailsSelectField.propTypes = {
  options: PropTypes.array,
  value: PropTypes.object
}

export default IssueDetailsSelectField