import React, { Component } from 'react'
import T from 'i18n-react'
import _ from 'lodash'
import m from 'match-string'

import style from 'client/features/common/styles/react-mentions'

import Confirmation from 'client/features/common/components/Confirmation.jsx'
import { Mention, MentionsInput } from 'react-mentions'

class IssueInternalNote extends Component {

  constructor(props) {
    super(props)
    this._input = React.createRef();
    this._parent  = React.createRef();
    this.buildPlaceholder = this.buildPlaceholder.bind(this)
    this.change = this.change.bind(this)
    this.clear = this.clear.bind(this)
    this.newMention = this.newMention.bind(this)
    this.addMention = this.addMention.bind(this)
    this.addTag = this.addTag.bind(this)
    this.submit = this.submit.bind(this)
    this.focus = this.focus.bind(this)
    this.blur = this.blur.bind(this)
    this.state = {
      value: '',
      valuePlainText: '',
      mentionned: [],
      hashtags: [],
      focused: false
    }
  }
  _correctStyle(){
    if(this._parent.current){
      window.setTimeout(() => {
        if(this._parent.current.children.length > 3){
          if(!this._changed){
            const boiteBizarre = this._parent.current.children.item(0).children.item(0).children.item(0).children.item(0);
            if(boiteBizarre.offsetHeight > 90){
              const top = this._parent.current.children.item(3).style.top;
              const mentionTop = parseInt(top.substr(0, top.length - 2));
              this._parent.current.children.item(3).style.top = (mentionTop - boiteBizarre.offsetHeight + 90) + "px";
              this._changed = true;
            }
          }else{
            this._changed = false;
          }
        }
      }, 0);
    }
  }
  componentDidMount() {
    const defaultValue = this.getDefaultValue();
    const observerChild = new MutationObserver(() => {
      const diff = this._top !== this._parent.current.children.item(3).style.top;
      this._top = this._parent.current.children.item(3).style.top;
      if(diff){
        this._correctStyle();
      }
    });
    const observerParent = new MutationObserver(() => {
      if(this._parent.current.children.item(3)){
        this._top = this._parent.current.children.item(3).style.top;
        this._correctStyle();
        observerChild.observe(this._parent.current.children.item(3), { attributes: true });
      }
    });
    observerParent.observe(this._parent.current, { childList: true });
    if (defaultValue) {
      this.setState({
        memo: defaultValue,
        defaultValue,
        mentionned: [{ id: this.props.issue.owner._id }]
      })
    }
  }
  componentDidUpdate() {    
    // this._correctStyle();
  }

  getDefaultValue() {
    if (!this.props.issue.currentUser.isOwner && this.props.issue.owner)
      return `@[@${this.props.issue.owner.firstName} ${this.props.issue.owner.lastName}](user:${this.props.issue.owner._id})`
    return ''
  }

  buildPlaceholder() {
    let placeholder = T.translate('issue_details_add_note')
    if (!this.props.issue.currentUser.isOwner && this.props.issue.owner)
      placeholder += ` @${this.props.issue.owner.firstName} ${this.props.issue.owner.lastName}`
    return placeholder
  }

  newMention() {
    this.setState({
      value: this.state.value += ' @'
    })
    if (this._input.current)
      this._input.current.focus()
  }

  change(event, value, valuePlainText, mentionned) {
    if (!this.props.issue.currentUser.isOwner) {
      const regex = new RegExp(this.getDefaultValue().replace(/[.*+?^${}()|[\]\\]/g, '\\$&'))
      if (!regex.test(value)) {
        value = `${this.getDefaultValue()} ${value && value[0] ? value : ''}`
        this._input.current.blur()
      }
    }
    this.setState({ value, valuePlainText }, () => {
      if (this._input.current)
        this._input.current.focus()
    })
  }

  renderUserSuggestion(user, search, highlightedDisplay) {
    return <span>{ highlightedDisplay }</span>
  }

  renderTagSuggestion(tag, search, highlightedDisplay) {
    return <span>{ highlightedDisplay }</span>
  }

  clear() {
    this.setState({
      value: '',
      valuePlainText: '',
      mentionned: [{ id: this.props.issue.owner._id }],
      hashtags: []
    })
  }

  focus() {
    const { value } = this.state
    this.setState({
      focused: true,
      value: value || `${this.getDefaultValue()} `
    })
  }

  blur() {
    this.setState({
      focused: false
    })
  }

  submit() {
    const { valuePlainText } = this.state
    const mentionned = this.state.mentionned

    this.props.submit(valuePlainText, mentionned)
    this.clear()
  }

  addMention(id, mention) {
    const { mentionned } = this.state
    const existing = mentionned.some(data => data.id === id)
    if (!existing)
      mentionned.push({ id })
    this.setState({ mentionned })
  }

  addTag(id, tag) {
    const { hashtags } = this.state
    const existing = hashtags.some(data => data.id === id)
    if (!existing)
      hashtags.push({ id, label: tag.trim().replace('#', '') })
    this.setState({ hashtags })
  }

  render() {
    const {
      colleagues = [],
      confirmation,
      user,
      issue
    } = this.props

    const mentionsData = colleagues
      .filter((user) => !_.find(this.state.mentionned, { id: user._id }))
      .map((user) => ({ ...user, display: `@${user.firstName} ${user.lastName} `, id: user._id }))
    const creatorIsMentionned = issue.userPro && mentionsData.some(user => user._id === issue.userPro._id)
    if (!creatorIsMentionned && issue.userPro)
      mentionsData.unshift({ ...issue.userPro, display: `@${issue.userPro.firstName} ${issue.userPro.lastName} `, id: issue.userPro._id })
    return (
      <div className='c-note-container u-margin-top u-margin-bottom u-margin-right-small u-margin-left-small u-hide-for-print' ref={ this._parent } style={{ position: "relative" }}>
        <div className='u-high c-field-mentions' ref={ this._parent }>
          <MentionsInput
            value={ this.state.value }
            onChange={ this.change }
            style={ { ...style, control: { ...style.control, height: "90px" } } }
            markup='@[__display__](__type__:__id__)'
            placeholder={ this.buildPlaceholder() }
            inputRef={ this._input }
            onFocus={ this.focus }
            suggestionsPortalHost={this._parent.current}
            //onBlur={ this.blur }
          >
            <Mention
              type='user'
              trigger='@'
              data={
                user.authorizations.issue.internalNoteMentions ?
                  _.sortBy(mentionsData, 'firstName') : []
              }
              renderSuggestion={ this.renderUserSuggestion }
              onAdd={ this.addMention }
            />
            <Mention
              type='tag'
              trigger='#'
              data={
                user.resource && user.resource.hashtags && user.resource.hashtags.map(hashtag => ({ ...hashtag, display: `#${hashtag.label} `, id: hashtag._id }))
              }
              renderSuggestion={ this.renderTagSuggestion }
              onAdd={ this.addTag }
            />
          </MentionsInput>
        </div>
        {
          (this.state.value || this.state.focused) &&
          <div className='u-margin-top-small u-text-align-right' style={ { marginTop: 4 } }>
            {
              user.authorizations.issue.internalNoteMentions &&
              <a className='c-button u-small u-margin-right-tiny' onClick={ this.newMention }>
                { T.translate('issue_details_add_user_to_note') }
              </a>
            }
            <a className='c-button u-small u-margin-right-tiny' onClick={ this.clear }>
              { T.translate('cancel') }
            </a>
            <a className='c-button c-button--brand u-small' onClick={ this.submit }>
              { T.translate('form_submit') }
            </a>
          </div>
        }
        <div className='text-righted'>
          <Confirmation data={ confirmation } />
        </div>
      </div>

    )
  }
}

/*
const mentionStyle = {
    backgroundColor: '#cee4e5'
}
*/

export default IssueInternalNote