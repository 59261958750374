/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent } from 'react';

import { Route } from '@cFeatures/router';

import Settings from '@views/settings';
import Body from './body.tsx';
import Menu from "@cComponents/menu";

import useService from '@universal/behaviour/hooks/useService';
import HttpService from '@common/services/http';
import Modal from '@common/components/modal';
import StandartSubscription from '@entities/notifications/standartSubscription';
import Release from '@common/entities/release';

const ConnectedLogo: FunctionComponent<{}> = ({}) => {
  const http = useService<HttpService>("http", ['onServiceUpdated']);
  
  const logo = React.useMemo(() => {
    if(http.hasRequestInProgress()){
      return (
        <img alt="BetterStreet" src={`/images/logos/betterstreet_color_spinner.svg`} style={{
          width: "40px",
          height: "40px",
        }} />
      )
    } else {
      return (
        <img alt="BetterStreet" src={`/images/logos/betterstreet_color.svg`} style={{
          width: "40px",
          height: "40px",
        }} />
      );
    }
  }, [http.hasRequestInProgress()]);

  return logo;
};

const SettingsComponent: FunctionComponent = ({}) => (
  <Body>
      <Menu.Section>
        <Menu.Item path="/settings">
        {() => (<ConnectedLogo />)}
        </Menu.Item>
      </Menu.Section>
      <Route path="" component={ Settings } />
      <Modal.UrlRequest displayModalValue="notificationSettings"  style={{ width: "800px", height: "700px", padding: "20px" }}>
      { (close) => ( <StandartSubscription close={ close } /> )}
      </Modal.UrlRequest>
      <Release.AutoList application="admin" />
  </Body>
);

export default SettingsComponent;
