import React, { ComponentType, FunctionComponent } from 'react';
import ObjectId from "@uTypes/technical/ObjectId";
import Resource from '@universal/types/business/Resource';
import RepositoryService from "@uServices/repository";
import useService from '@uBehaviour/hooks/useService';
import { BusinessEntity, Loader } from '@universal/types/technic/Entityable';
import Flatten from '@uTypes/technic/Flatten';

type WaitedProps<T, L extends Loader> = {
  data: BusinessEntity<T, L> | T | null;
}

type ReturnedProps<Props, KeyName extends string> = Flatten<Omit<Props, "data"> & { [K in KeyName]: ObjectId }>;

type ResourceName = keyof Resource;

function withData <Name extends ResourceName, L extends Loader, Props, KeyName extends string>(Component: ComponentType<Props extends WaitedProps<Resource[Name], L> ? Props : never>, modelName: ResourceName, keyName: KeyName, newKey: string = "new", load?: L): FunctionComponent<ReturnedProps<Props, KeyName>> {
  type T = Resource[typeof modelName];

  return ({[keyName || "id"]: id, ...rest}) => {
    const [data, setData] = React.useState<BusinessEntity<T, L> | null | undefined>();
    const repositoryService = useService<RepositoryService>("repository");
    React.useEffect(() => {
      if ([null, newKey].includes(id)){
        setData(null);
        return;
      }
      const model = repositoryService.get(modelName);
      model.repository.findOne(id, load)
        .then((data: BusinessEntity<T, L> | null) => setData(data));
    }, [ repositoryService, id]);

    if (data === undefined) {
      return null;
    }

    return <Component {...(rest as Props)} data={ data } />
  }
}

export default withData;