import React, { FunctionComponent } from 'react';

import User, { isUserBetterstreet, isUserCitizen, isUserCollaborator, isUserPro, isUserTechnical } from '@universal/types/business/User';

type UserIconProps = {
  user: User;
};

const UserIcon: FunctionComponent<UserIconProps> = ({ user }) => {
  let icon = "";

  if(isUserPro(user)) {
    icon = "university";
  } else if(isUserCollaborator(user)) {
    icon = "address-card-o";
  } else if(isUserCitizen(user)) {
    icon = "user";
  } else if(isUserTechnical(user) || isUserBetterstreet(user)) {
    icon = "desktop";
  } else {
    icon = "user-secret";
  }

  return (<span className={`fa fa-${ icon }`} />);
};

export default UserIcon;