// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-formLayout-main {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  box-shadow:0 1px 1px rgba(0, 0, 0, 0.05);  
  background: white; 
  max-height: inherit; 
}

.bs-formLayout-main .bs-formLayout-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 43px;
  background-color: #f5f5f5;
  font-size: 1.2em;
  padding:0px 10px;
  border-bottom: 1px solid #dddddd;
  color: #2c3e50;
  font-weight: 600;
}
.bs-formLayout-main .bs-formLayout-footer {
  background-color: #f5f5f5;
  padding:10px 10px;
  border-top: 1px solid #dddddd;
  display: flex;
  justify-content: flex-end;
}

.bs-formLayout-actions{
  background: #ffffff;
}
.bs-formLayout-content{
  display: flex;
  flex-direction: row;
}

.bs-formLayout-content > *:first-child{
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.bs-formLayout-rightContent{
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/components/formLayout.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,wCAAwC;EACxC,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,gCAAgC;EAChC,cAAc;EACd,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,6BAA6B;EAC7B,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,cAAc;EACd,aAAa;AACf;;AAEA;EACE,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;AACd","sourcesContent":[".bs-formLayout-main {\n  border: 1px solid #dddddd;\n  box-sizing: border-box;\n  box-shadow:0 1px 1px rgba(0, 0, 0, 0.05);  \n  background: white; \n  max-height: inherit; \n}\n\n.bs-formLayout-main .bs-formLayout-title {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  height: 43px;\n  background-color: #f5f5f5;\n  font-size: 1.2em;\n  padding:0px 10px;\n  border-bottom: 1px solid #dddddd;\n  color: #2c3e50;\n  font-weight: 600;\n}\n.bs-formLayout-main .bs-formLayout-footer {\n  background-color: #f5f5f5;\n  padding:10px 10px;\n  border-top: 1px solid #dddddd;\n  display: flex;\n  justify-content: flex-end;\n}\n\n.bs-formLayout-actions{\n  background: #ffffff;\n}\n.bs-formLayout-content{\n  display: flex;\n  flex-direction: row;\n}\n\n.bs-formLayout-content > *:first-child{\n  flex-grow: 1;\n  flex-shrink: 1;\n  flex-basis: 0;\n}\n\n.bs-formLayout-rightContent{\n  width: 25%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
