import React, { FunctionComponent, PropsWithChildren } from 'react';
import Context, { IManager } from './context';
import Event from '@uLib/event';

interface ManagerProps {
  highlight?: string | null;
}

interface ManagerState{
  highlight: string | null;
}

class Manager extends React.Component<PropsWithChildren<ManagerProps>, ManagerState> implements IManager {
  private _onHighlightChange: Event;

  constructor(props){
    super(props);
    this._onHighlightChange = new Event();
    this.state = {
      highlight: null
    };
  }

  get onHighlightChange(): Event {
    return this._onHighlightChange;
  }

  isHighlight(): boolean {
    return !!this.highlight;
  }

  get highlight() : string| null {
    return this.state.highlight;
  }

  set highlight(highlight: string | null){
    if(highlight !== this.state.highlight){
      this.setState({ highlight });
    }
  }

  shouldComponentUpdate(nextProps: Readonly<React.PropsWithChildren<ManagerProps>>): boolean {
    if(nextProps.highlight !== undefined && nextProps.highlight !== this.highlight){
      this.highlight = nextProps.highlight;
    }
    return true;
  }

  render(){
    const { children } = this.props;
    const { highlight } = this.state;
    return (
      <Context.Provider value={ this }>
      { children }
      </Context.Provider>
    );
  }
}

export default Manager;