import React from 'react'
import Textarea from 'react-textarea-autosize'
import FocusBlur from "@cComponents/focusBlur";

let TextareaField
if(!window.navigator.vendor.includes("Apple")){
  TextareaField = ({ input, className, disabled = false, id, label, placeholder, meta: { touched, error, warning } }) => (
    <div
        className='o-form-element u-padding-vertical-tiny'
    >
        { 
            label && 
                <label className='c-label'>
                    { label }
                </label> 
        }
        <Textarea
            { ...input }
            id={ id }
            className={ className ? `c-field ${className}` : 'c-field' }
            placeholder={ placeholder }
            disabled={ disabled }
        />
        { 
            (
                (touched && error && 
                    <span className='c-hint c-hint--static c-hint--error'>{ error }</span>) 
                    || 
                    (warning && <span className='c-hint'>{ warning }</span>)
            )
        }
    </div>
  )
}else{
  TextareaField = class TextareaFieldClass extends React.Component{ 
    constructor(props) {
      super(props);
      this._textarea = React.createRef();
      this._onChange = this._onChange.bind(this);
      this._onFocus = this._onFocus.bind(this);
      this._onBlur = this._onBlur.bind(this);
    }
    _onChange(event) {
      this.props.input.onChange(event);
    }
    _onFocus(event){
      this._focus = true;
    }
    _onBlur(event){
      this._focus = false;
    }
    componentDidUpdate(){
      if(this._focus){
        window.setTimeout(() => {
          this._textarea.current.focus();
        }, 20);
      }
    }
    render(){
      const { input, className, disabled = false, id, label, placeholder, meta: { touched, error, warning } } = this.props;
      return (
          <FocusBlur
              className='o-form-element u-padding-vertical-tiny'
              onFocus={ this._onFocus }
              onBlur={ this._onBlur }
          >
              { 
                  label && 
                      <label className='c-label'>
                          { label }
                      </label> 
              }
              <textarea
                  ref={ this._textarea }
                  onChange={ this._onChange }
                  id={ id }
                  className={ className ? `c-field ${className}` : 'c-field' }
                  placeholder={ placeholder }
                  disabled={ disabled }
                  value={ input.value }
                  onKeyDown={ this._onKeyDown }
              />
              { 
                  (
                      (touched && error && 
                          <span className='c-hint c-hint--static c-hint--error'>{ error }</span>) 
                          || 
                          (warning && <span className='c-hint'>{ warning }</span>)
                  )
              }
          </FocusBlur>
      )
    }
  }
}
export default TextareaField