import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Mention, MentionsInput } from 'react-mentions'

import style from 'client/features/common/styles/react-mentions'

const __SERVER = window.__SERVER;
 


class MentionsFields extends Component {
    constructor(props) {
        super(props)
        this._mention = React.createRef();
        this.onChange = this.onChange.bind(this)
        this.state = {
            value: props.value ? `${props.value.trim()} ` : ''
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.props.updateValue && nextProps.value && nextProps.value !== this.state.value)
            this.setState({ value: nextProps.value })
    }
    

    onChange(event, value, valuePlainText, mentionned) {
        this.setState({
            value: valuePlainText
        })
        if (this.props.onChange)
            this.props.onChange(valuePlainText)
    }

    render() {
        const {
            placeholder,
            autoFocus = false,
            mentions = [],
            singleLine = false
        } = this.props

        if (__SERVER)
            return <span></span>

        return (
            <MentionsInput
                autoFocus={ autoFocus }
                value={ this.state.value }
                onChange={ this.onChange }
                markup='@[__display__](__type__:__id__)'
                style={ style }
                placeholder={ placeholder }
                ref={ this._mention }
                singleLine={ singleLine }
            >
                {
                    mentions.map(mention =>
                        <Mention
                            key={ mention.type }
                            { ...mention }
                        />
                    )
                }
            </MentionsInput>
        )
    }
}

MentionsFields.propTypes = {
    mentions: PropTypes.array.isRequired,
    autoFocus: PropTypes.bool,
    placeholder: PropTypes.string
}

export default MentionsFields