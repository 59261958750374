// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-features-layout-listHeader-container {
  align-items: center;
  gap: var(--std-size-inter-element)
}

.bs-features-layout-listHeader-column {
  display: flex;
  align-items: center;
  gap: var(--std-size-inter-element)
}

.bs-features-layout-listHeader-right {
  justify-content: flex-end;
}
`, "",{"version":3,"sources":["webpack://./../common/features/layout/listHeader.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB;AACF;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".bs-features-layout-listHeader-container {\n  align-items: center;\n  gap: var(--std-size-inter-element)\n}\n\n.bs-features-layout-listHeader-column {\n  display: flex;\n  align-items: center;\n  gap: var(--std-size-inter-element)\n}\n\n.bs-features-layout-listHeader-right {\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
