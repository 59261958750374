import {
  IsAuthRequest,
  LoginRequest,
  LogoutRequest,
  MagicLoginRequest,
  CurrentUserRequest,
  PasswordRequest,
  SESSION_DISABLE_CONFIRMATION,
  ResetPasswordRequest,
  SESSION_HASHTAGS_UPDATED,
  ADD_USER_CONFIRMATION,
  REMOVE_USER_CONFIRMATION,
  UPDATE_USER_ATTRIBUTE
} from 'client/features/session/actions'
import cookies from "client/lib/cookie"
import { UserRequest } from 'client/features/users/actions'
import * as IssuesActions from 'client/features/issues/actions'
import _ from 'lodash'
import jwt from '../../lib/jwt';
import { registerDefaultReducer, registerInitialstate } from "client/lib/reduxSaga";
import application from '@root/app.services';

const tokenName = application.getService("session").tokenName;
const user = cookies.get(tokenName) ? jwt.decode(cookies.get(tokenName)) : null
export const initialState = {
  user: user ? user : {},
  loaded: false,
  loggingIn: !!user,
  confirmations: {
    requestPassword: false
  },
  requestPasswordLoading: false,
  resetPasswordUser: null,
  error: null,
  owners: [],
  whoIsOnlineLoading: false,
  whoIsOnline: [],
  notifications: [],
  [tokenName]: null
}

const initializeState = () => Object.assign({}, initialState)

registerInitialstate("session", initializeState);
registerDefaultReducer("session", function session(state = initializeState(), action = {}) {
  switch (action.type) {
  case "switch_tenant":
    return { ...state, currentClient: action.payload };

  case IsAuthRequest.get.TYPES.SUCCESS:
    return { ...state, loggingIn: true, user: action.payload.user, loaded: true, error: null }

  case IsAuthRequest.get.TYPES.FAILURE:
    return { ...state, loggingIn: false, user: null, loaded: true, error: null }

  case LoginRequest.post.TYPES.SUCCESS:
    return state;

  case LoginRequest.post.TYPES.FAILURE:
    return { ...state, loggingIn: false, user: null, loaded: true, error: action.payload.message }

  case MagicLoginRequest.post.TYPES.FAILURE:
    return { ...state, loggingIn: false, user: null, loaded: true, error: action.payload.error }

  case MagicLoginRequest.post.TYPES.SUCCESS:
    return { ...state, loggingIn: false, user: null, loaded: true, error: null }

  case LogoutRequest.get.TYPES.REQUEST:
    return { ...state, loggingIn: false, user: null, loaded: true, error: null }

  case CurrentUserRequest.put.TYPES.SUCCESS:
    return { ...state, user: action.payload.user }

  case PasswordRequest.post.TYPES.REQUEST:
    return {
      ...state,
      requestPasswordLoading: true
    }

  case PasswordRequest.post.TYPES.FAILURE:
    return {
      ...state,
      requestPasswordLoading: false
    }

  case SESSION_HASHTAGS_UPDATED:
    return {
      ...state,
      user: {
        ...state.user,
        resource: {
          ...state.user.resource,
          hashtags: action.payload.hashtags
        }
      }
    }

  case PasswordRequest.post.TYPES.ERROR:
    return {
      ...state,
      requestPasswordLoading: false
    }

  case PasswordRequest.post.TYPES.SUCCESS:
    return {
      ...state,
      requestPasswordLoading: false,
      confirmations: {
        ...state.confirmations,
        requestPassword: true
      }
    }

  case ResetPasswordRequest.post.TYPES.REQUEST:
    return {
      ...state,
      resetPasswordLoading: true
    }

  case ResetPasswordRequest.post.TYPES.FAILURE:
    return {
      ...state,
      resetPasswordLoading: false
    }


  case IssuesActions.SaveIssuesFiltersRequest.post.TYPES.SUCCESS:
  case IssuesActions.SaveIssuesFiltersRequest.put.TYPES.SUCCESS:
  case IssuesActions.SaveIssuesFiltersRequest.delete.TYPES.SUCCESS:
    return {
      ...state,
      user: {
        ...state.user,
        issuesFilters: action.payload.issuesFilters
      }
    }

  case SESSION_DISABLE_CONFIRMATION:
    return {
      ...state,
      confirmations: {
        ...state.confirmations,
        [action.confirmation]: false
      }
    }

  case UserRequest.put.TYPES.SUCCESS:
    return updateUserInTeam(state, action.payload)

  case ADD_USER_CONFIRMATION:
    return {
      ...state,
      confirmations: addConfirmation(state.confirmations, action.payload)
    }

  case REMOVE_USER_CONFIRMATION:
    return {
      ...state,
      confirmations: removeConfirmation(state.confirmations, action.payload)
    }

  case UPDATE_USER_ATTRIBUTE:
    return {
      ...state,
      user: setUserAttribute(state.user, action.payload)
    }

  default:
    return state
  }
});

/*TODO: improve session data updates */
const updateUserInTeam = (state, payload) => {
  const teams = _.cloneDeep(state.teams)
  const teamIndex = state.teams.findIndex((team) => team._id === payload.user.team)
  const userIndex = teams[teamIndex].users.findIndex((user) => user._id === payload.user._id)
  teams[teamIndex].users[userIndex] = payload.user
  return {
    ...state,
    teams
  }
}

/*Confirmations*/
const addConfirmation = (confirmations = {}, payload) => {
  confirmations[payload.type] = payload
  return { ...confirmations }
}

const removeConfirmation = (confirmations = {}, payload) => {
  delete confirmations[payload.type]
  return { ...confirmations }
}

/*User attributes*/
const setUserAttribute = (user, payload) => {
  user = _.cloneDeep(user)
  _.set(user, payload.key, payload.value)
  return user
}