import React from 'react';
import T     from 'i18n-react';

import './selectEquipments.css';

const EquipmentItem = ({ equipment, toggleEquipmentSelection, selected}) => {
  const toggleEquipmentSelectionBind = React.useCallback((e) => {
    e.stopPropagation();
    toggleEquipmentSelection(equipment);
  }, [toggleEquipmentSelection, equipment]);

  return (
    <div className={`bs-selectEquipment-body-scrollable-item${ selected ? " selected" : ""}`} onClick={ toggleEquipmentSelectionBind }>
      <span aria-hidden="true" class="fa fa-wrench fa-2x"/>
      <div>{equipment.name}</div>
    </div>
  );
};
class SelectModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { selectedEquipments: this.props.selected ? this.props.selected : [] };
    this._inputSearch = React.createRef();
  }

  componentDidMount() {
    window.setTimeout(() => this._inputSearch.current.focus(), 0);
  }

  toggleEquipmentSelection = (equipment) => { 
    this.setState(({ selectedEquipments }) => {
      if(selectedEquipments.includes(equipment)){
        return { selectedEquipments: selectedEquipments.filter(selectedEquipment => selectedEquipment._id !== equipment._id) };
      }else{
        return { selectedEquipments: selectedEquipments.concat([equipment]) };
      }      
    });
  }

  validSelection = () => {
    if(this.props.onChange)  this.props.onChange(this.state.selectedEquipments);
    if(this.props.close)     this.props.close();
  }

  render() {
    return (
      <div className='bs-selectEquipment-content'>
        <div className='bs-selectEquipment-header'>
          <h4 className='bs-selectEquipment-header-title'>{ T.translate('equipments') }</h4>
          <div className='bs-selectEquipment-header-input-layout'>
            <span>
              <input ref={ this._inputSearch } className='bs-selectEquipment-header-input' type="text" placeholder={ T.translate("search") } onChange={(ev) => this.props.onFilterChange ? this.props.onFilterChange(ev.target.value) : null}/>
              <span className="fa fa-search"></span>
            </span>
          </div>
        </div>
        <div className='bs-selectEquipment-body-scrollable'>
        {
          this.props.equipments.map(equipment => {
            return (<EquipmentItem equipment={ equipment } toggleEquipmentSelection={ this.toggleEquipmentSelection } selected={ this.state.selectedEquipments.findIndex(selectedEquipment => selectedEquipment._id === equipment._id) !== -1 }/>)
          })
        }
        </div>
        <div className='bs-selectEquipment-footer'>
          <div className="bs-button bs-button-cancel" onClick={ this.props.close }>{ T.translate('cancel') }</div>
          <div className="bs-button bs-button-save" onClick={ this.validSelection }>{ T.translate('confirm_update') }</div>
        </div>
      </div>
    
    );
  }
}

export default SelectModal;