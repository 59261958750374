import React, {
  ReactNode,
  FunctionComponent
} from "react";
import T  from "@uBehaviour/i18n";
import dayjs from 'dayjs';
import { SchedulerRule as SchedulerRuleInterface } from '@uTypes/business/Recurrence';

interface SchedulerRuleProps {
  schedulerRule: SchedulerRuleInterface;
  eventHorizonInDay: number;
}

interface Bind { 
  discriminator?: string;
  jump?: number;
  dayOfMonth?: number;
  dayOfWeek?: string;
  nth?: number;
  adNum?: ReactNode;
  month?: string;
}

const getBind = (schedulerRule : SchedulerRuleInterface, jump: number = 0) => {
  const bind: Bind = {
    jump: jump,
    discriminator: schedulerRule.discriminator
  };
  switch(schedulerRule.discriminator){
    case "monthlyFixedDay":
      bind.dayOfMonth = schedulerRule.dayOfMonth;
      break;
    case "monthlyRelativeDay":
      bind.adNum      = <sup>{ schedulerRule.nth === 1 ? <T>add_num_1</T> : schedulerRule.nth === 2 ? <T>add_num_2</T> : <T>add_num_rest</T> }</sup>;
      bind.nth        = schedulerRule.nth;
      bind.dayOfWeek  = dayjs().day(schedulerRule.dayOfWeek).format("dddd");
      break;
    case "weekly":
      bind.dayOfWeek  = schedulerRule.dayOfWeek.map(dayOfWeek => dayjs().day(dayOfWeek).format("dddd")).join(", ");
      break;
    case "yearlyFixedDay":
      bind.dayOfMonth = schedulerRule.dayOfMonth;
      bind.month      = dayjs().month(schedulerRule.month).format("MMMM");
      break;
    case "yearlyRelativeDay":
      bind.adNum      = <sup>{ schedulerRule.nth === 1 ? <T>add_num_1</T> : schedulerRule.nth === 2 ? <T>add_num_2</T> : <T>add_num_rest</T> }</sup>;
      bind.nth        = schedulerRule.nth;
      bind.dayOfWeek  = dayjs().day(schedulerRule.dayOfWeek).format("dddd")
      bind.month      = dayjs().month(schedulerRule.month).format("MMMM");
      break;
    case "jump":
      bind.jump = schedulerRule.jump;
    // eslint-disable-next-line no-fallthrough
    default:
      Object.assign(bind, getBind(schedulerRule.rule, bind.jump));
  }
  return bind;
}

const SchedulerRule: FunctionComponent<SchedulerRuleProps> = ({ schedulerRule, eventHorizonInDay }) => {
  const bind = getBind(schedulerRule);
  ++bind.jump;
  return (
    <>
      <div><T bind={bind}>{"recurrence_schedulerRule_" + bind.discriminator}</T></div>
      <div><T bind={{ eventHorizonInDay }}>recurrence_event_horizon_in_day</T></div>
    </>
  )
}

export default SchedulerRule;