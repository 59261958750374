import React from 'react'
import T from 'i18n-react'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { Field } from 'redux-form'
import SelectField from 'client/features/form/old/components/fields/Select.jsx'
import FontAwesome from 'react-fontawesome'
import InputField from 'client/features/form/old/components/InputField.jsx'
import Nears from 'client/features/issues/components/form/Nears.jsx'
import PropTypes from 'prop-types'

import icon from 'client/assets/images/tags/generic_open.png';

const Map = withGoogleMap((props) => 
    <GoogleMap
        defaultZoom={ props.defaultZoom }
        center={ { lat: props.marker.latitude, lng: props.marker.longitude } }
        onIdle={ props.onIdle }
        options={ props.options }
    >
        <Marker 
            position={ { lat: props.marker.latitude, lng: props.marker.longitude } }
            icon={ props.marker.icon }
        />
        {
            props.currentNear && <Marker position={ { lat: props.currentNear.latitude, lng: props.currentNear.longitude } } icon={ props.currentNear.marker }/>
        }
    </GoogleMap>
)


const getMapPosition = (resource, data) => {
    if (data && data.latitude && data.longitude)
        return {
            lat: Number(data.latitude),
            lng: Number(data.longitude)
        }
    return {
        lat: Number(resource.geolocation.latitude),
        lng: Number(resource.geolocation.longitude)
    }
}

const IssueFormPlace = ({ options, value = {}, name, change, resource, nears, goToIssue, hideMap, hideNears, placeholder, hideMapIfNoLocation, sub, initialValue, mapHeight = 572 }) => (
    <div>
        <div className='u-margin-bottom-small'>
            <Field
                component={ SelectField }
                name={ name }
                onChange={ change }
                options={ options }
                forceEditMode
                hideCross
                closedOnMount
                fieldPlaceholder={ T.translate(placeholder) }
            />
        </div>
        {
            sub && value && value.value &&
                    <div className='o-layout'>
                        <div className='o-layout__item u-1of12'>
                            <FontAwesome name='level-up' className='sub-arrow rotate-90'/>
                        </div>
                        <div className='o-layout__item u-10of12'>
                            {
                                sub.options && sub.options[0] ?
                                    <Field
                                        component={ SelectField }
                                        name='place'
                                        value={
                                            sub && sub.value
                                        }
                                        initialValue={sub.initialValue}
                                        onChange={ sub.change }
                                        options={ sub.options }
                                        forceEditMode
                                        hideCross
                                        closedOnMount
                                        fieldPlaceholder={ T.translate(sub.placeholder) }
                                    />
                                    :
                                    <Field
                                        name='locationInfo'
                                        component={ InputField }
                                        autoComplete="off"
                                        placeholder={ T.translate(sub.placeholder) }
                                    />
                            }
                            
                        </div>
                    </div>
        }
        {
            (!hideMap && (!hideMapIfNoLocation || value.latitude)) &&
                    <section>
                        <div className='u-position-relative' style={ { height: `${mapHeight}px` } }>
                            <div className='c-text-over-map'>
                                { value.address }
                            </div>
                            <Map
                                defaultZoom={ 15 }
                                containerElement={
                                    <div style={ { height: '100%' } } />
                                }
                                mapElement={
                                    <div style={ { height: '100%' } } />
                                }
                                center={ getMapPosition(resource, value) }
                                draggable={ false }
                                marker={ {
                                    latitude: getMapPosition(resource, value).lat,
                                    longitude: getMapPosition(resource, value).lng,
                                    icon: icon
                                } }
                            />
                        </div>
                        {
                            nears && nears[0] && !hideNears &&
                                <Nears 
                                    goToIssue={ goToIssue }
                                    nears={ nears }
                                    title='new_nears_building'
                                />
                        }
                    </section>
        }
    </div>
)

IssueFormPlace.propTypes = {
    change: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string.isRequired,
    resource: PropTypes.object.isRequired,
    goToIssue: PropTypes.func,
    hideMap: PropTypes.bool,
    hideMapIfNoLocation: PropTypes.bool,
    name: PropTypes.string,
    nears: PropTypes.array,
    sub: PropTypes.object,
    value: PropTypes.object
}

export default IssueFormPlace