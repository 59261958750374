import React from 'react'
import { isAuthorized } from 'client/features/issues/lib'


const AuthorizationsWrapper = ({ fields, children, authorizations = {} }) => {
    let isOK = false
    for (const authorization in authorizations) {
        if (!isOK)
            isOK = isAuthorized({ fields }, authorization, authorizations[authorization])
    }
    if (!isOK || !children || !authorizations || !fields)
        return <span></span>
    return children
}

export default AuthorizationsWrapper