// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-userAvatar{
  display: flex;
  align-items: center;
}
.bs-userAvatar>*:first-child{
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./../common/features/userAvatar.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-userAvatar{\n  display: flex;\n  align-items: center;\n}\n.bs-userAvatar>*:first-child{\n  margin-right: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
