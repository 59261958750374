// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen {
  .bs-app {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  } 
}`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;IACZ,aAAa;IACb,gBAAgB;EAClB;AACF","sourcesContent":["@media screen {\n  .bs-app {\n    width: 100vw;\n    height: 100vh;\n    overflow: hidden;\n  } \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
