// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-template-modal-standart {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.bs-template-modal-standart-action,
.bs-template-modal-standart-header,
.bs-template-modal-standart-footer {
  flex-basis: auto;
  flex-shrink: 0;
  flex-grow: 0;

  background-color: var(--std-color-gray-light);
}

.bs-template-modal-standart-header {
  padding: var(--std-large-size-inter-element);
}

.bs-template-modal-standart-action,
.bs-template-modal-standart-footer {
  padding: var(--std-size-inter-element);
}

.bs-template-modal-standart-content {
  overflow: hidden;
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
}

.bs-template-modal-standart-action {
  display: flex;
}

.bs-template-modal-standart-action-right,
.bs-template-modal-standart-action-left {
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  gap: var(--std-size-inter-element);
}

.bs-template-modal-standart-action-left {
  justify-content: flex-start;
}

.bs-template-modal-standart-action-right {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./../common/components/template/modal/standart.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;;AAEA;;;EAGE,gBAAgB;EAChB,cAAc;EACd,YAAY;;EAEZ,6CAA6C;AAC/C;;AAEA;EACE,4CAA4C;AAC9C;;AAEA;;EAEE,sCAAsC;AACxC;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,cAAc;EACd,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;;EAEE,aAAa;EACb,cAAc;EACd,YAAY;EACZ,aAAa;EACb,2BAA2B;EAC3B,kCAAkC;AACpC;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".bs-template-modal-standart {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.bs-template-modal-standart-action,\n.bs-template-modal-standart-header,\n.bs-template-modal-standart-footer {\n  flex-basis: auto;\n  flex-shrink: 0;\n  flex-grow: 0;\n\n  background-color: var(--std-color-gray-light);\n}\n\n.bs-template-modal-standart-header {\n  padding: var(--std-large-size-inter-element);\n}\n\n.bs-template-modal-standart-action,\n.bs-template-modal-standart-footer {\n  padding: var(--std-size-inter-element);\n}\n\n.bs-template-modal-standart-content {\n  overflow: hidden;\n  flex-basis: 0;\n  flex-shrink: 1;\n  flex-grow: 1;\n}\n\n.bs-template-modal-standart-action {\n  display: flex;\n}\n\n.bs-template-modal-standart-action-right,\n.bs-template-modal-standart-action-left {\n  flex-basis: 0;\n  flex-shrink: 1;\n  flex-grow: 1;\n  display: flex;\n  justify-content: flex-start;\n  gap: var(--std-size-inter-element);\n}\n\n.bs-template-modal-standart-action-left {\n  justify-content: flex-start;\n}\n\n.bs-template-modal-standart-action-right {\n  justify-content: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
