import React, { ComponentType, FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import Context from './context';
import { escapeRegExp } from '@uLib/tool';
import { Listener } from '@universal/lib/event';
import useForceUpdate from '@uBehaviour/hooks/useForceUpdate';

interface HighlightedProps {
  children: string;
  caseSensitive?: boolean;
}
type HighlightedType = FunctionComponent<HighlightedProps>;

type HighlighterType = (Component: ComponentType<PropsWithChildren<unknown>>) => HighlightedType;

const highlighter: HighlighterType = (Component) => {
  const Highlighted: HighlightedType = ({ children: text, caseSensitive = false }) => {
    const manager = React.useContext(Context);
    const forceUpdate = useForceUpdate();

    React.useEffect(() => {
      if(manager){
        const listener = new Listener(() => {
          forceUpdate();
        });
        manager.onHighlightChange.addListener(listener);
        return () => {
          manager.onHighlightChange.removeListener(listener);
        };
      }
    }, [manager, forceUpdate]);

    const searchedRegEx = React.useMemo<false | RegExp>(() => {
      if(!manager?.isHighlight()){
        return false;
      }
      return new RegExp(`${ escapeRegExp(manager.highlight) }`, !caseSensitive ? 'ig' : 'g');
    }, [manager?.highlight]);

    const content: Array<string | ReactElement> = React.useMemo(() => {
      if(!searchedRegEx) {
        return (<>{ text }</>);
      }

      const match = [...text.matchAll(searchedRegEx)].map(m => m[0]);
      return text.split(searchedRegEx)
        .reduce<Array<string | ReactElement>>((content, text, index, texts) => {
          if(text){
            content.push(<React.Fragment key={ text }>{ text }</React.Fragment>);
          }
          if(index < texts.length - 1){
            content.push(<Component key={ `${ manager.highlight }_${ index }` }>{ match[index] }</Component>);
          }
          return content;
        }, []);
    }, [Component, text, searchedRegEx]);

    return (
      <>{ content }</>
    );
  };
  return Highlighted;
}

export default highlighter;