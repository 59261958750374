import React, { ComponentType, FunctionComponent } from 'react';
import _ from 'lodash';
import classNames from '@universal/lib/classNames';

import './inputWithStarterDesign.css';

export type InputUniqueValueProps<Type> = {
  value: Type;
  onChange: (value: Type) => void;
}

export type InputMultipleValuesProps<Type> = {
  values: Type[];
  onChange: (values: Type[], valueUpdated: Type, added: boolean) => void;
}

type InputProps<Type> = InputUniqueValueProps<Type> | InputMultipleValuesProps<Type>;

type InputWithStarterDesignProps = {
  icon?: string;
  valid?: boolean | null;
}
    

function inputWithStarterDesign<Type>(Component: ComponentType<InputProps<Type>>): FunctionComponent<InputProps<Type> & InputWithStarterDesignProps> {
  return (props) => {
    const { icon, valid } = props;

    const inputClassname = React.useMemo(() => 
      classNames('bs-starter-input')
        .addIf('bs-starter-input-valid', !!valid)
        .addIf('bs-starter-input-invalid', !_.isNil(valid) && !valid)
    , [valid]);

    const displayIcon = React.useMemo(() => {
      if(!icon){
        return (<span className="bs-starter-input-icon" />);
      }
      return <span className={`bs-starter-input-icon fa fa-${icon}`} />;
    }, [icon]);

    const displayValid = React.useMemo(() => {
      if(_.isNil(valid)){
        return (<span className="bs-starter-input-validation"/>);
      }
      return valid
        ? <span className="bs-starter-input-validation fa fa-check" />
        : <span className="bs-starter-input-validation fa fa-times" />;
    }, [valid]);

    const ref = React.useRef(null);
    const focusOnInput = React.useCallback(() => {
      if(ref.current){
        ref.current.focus();
      }
    }, [ref]);

    return (
      <div className={ inputClassname } onClick={ focusOnInput }>
        { displayIcon }
        <Component { ...props } ref={ ref }/>
        { displayValid }
      </div>
    );
  };
};

export default inputWithStarterDesign;