let loaded = false;
let resolve = null;
const promise = new Promise(_resolve => {
  resolve = _resolve;
});
window.onload = function(){
  loaded = true;
  resolve();
};
export default {
  isLoaded: () => loaded,
  waitLoadingPromise: promise,
  init: () => {

  }
};