import React, { FunctionComponent }    from "react";

import UserForm, { stdOnChange, stdSubmit, getValidator } from "@entities/user/form";
import Modal from "@common/components/modal";
import Form from "@universal/behaviour/form";
import ModalTemplate from "@cComponents/template/modal/standart";

import { UserPro, UserCollaborator, UserDiscriminators, isUserPro } from "@universal/types/business/User";
import { BusinessEntity, isEntity } from "@universal/types/technic/Entityable";
import withMouseEventStopped from "@common/hoc/withMouseEventStopped";
import Button from "@cComponents/button";
import T from "@universal/behaviour/i18n";
import Text, { Style } from "@common/components/text";
import useService from "@universal/behaviour/hooks/useService";
import CurrentTenant from "@root/services/currentTenant";
import ApiService from "@universal/services/api";
import _ from "lodash";
import FormError from "@common/components/formError";
import RepositoryService from "@universal/services/repository";
import I18nService from "@universal/services/i18n";

type UserType = UserPro | UserCollaborator;

type UserFormProps = {
  close: () => void;
  data: BusinessEntity<UserType> | UserType;
}

const ValidateButton = withMouseEventStopped(Button.Stylized.positive);

const titleStyle = Style.large.bold.black;

const UserFormView: FunctionComponent<UserFormProps> = ({ close, data }) => {

  const currenTenant = useService<CurrentTenant>("currentTenant");

  const api = useService<ApiService>("api");
  const repository = useService<RepositoryService>("repository");
  const i18n = useService<I18nService>("i18n");

  const validator = React.useMemo(() => getValidator(repository, i18n), [ repository, i18n ]);

  const user = React.useMemo<UserType>(() => {
    let user = data;
    if(isEntity(data)){
      user = data.toPlainText();
    }
    if(user){
      if(isUserPro(user)){
        user = _.pick(user, ["_id", "discriminator", "firstname", "lastname", "avatar", "authentificationProviders", "settings", "valorization", "tenants"]);
      } else {
        user = _.pick(user, ["_id", "discriminator", "firstname", "lastname", "avatar", "authentificationProviders", "roles", "limitations", "disabled"]);
      }

      if(user.authentificationProviders.length){
        if(user.authentificationProviders[0].username.includes("@")){
          user.authentificationProviders[0].type = "email";
        } else {
          user.authentificationProviders[0].type = "username";
        }
      } else {
        user.authentificationProviders = [{ type: "none" }];
      }
    } else {
      user = {
        discriminator: UserDiscriminators.pro,
        authentificationProviders: [{ type: "email", discriminator: "username" }],
        settings: {
          nbrDisplayedDays: 0

        },
        valorization: 0,
        canValorizeForOthers: false,
        tenants: currenTenant.currentId
          ? [{ tenant: currenTenant.currentId, roles: ["agent"], buildings: { ids: [], tags: [] }, tablet: { allowed: false }, disabled: false, canValorizeForOthers: false }]
          : [],
      };
    }
    return user;
  }, []);

  const onUserFormChange = React.useMemo(() => stdOnChange(currenTenant), [currenTenant]);

  const userFullname = data?._id ? `${data.firstname} ${data.lastname}` : (<T>user_form_new</T>);

  const submit = React.useCallback(stdSubmit(api, close), [api, close]);
  
  return (
    <Form.Simple value={ user } validator={ validator } onChange={ onUserFormChange } submit={ submit }>
    {(ctx, user: UserPro | UserCollaborator, errors, form, submit) => (
      <ModalTemplate>
        <ModalTemplate.Header>
          <Text style={ titleStyle }>
            <T bind={{ user: userFullname}}>user_form_title</T>
          </Text>
        </ModalTemplate.Header>
        <ModalTemplate.Content withScrollBar>
          <UserForm user={ user }/>
        </ModalTemplate.Content>
        <ModalTemplate.Footer>
          <FormError errors={ errors?.global }/>
        </ModalTemplate.Footer>
        <ModalTemplate.ActionRight>
          <ValidateButton onClick={ submit }>
            <T>user_form_submit</T>
          </ValidateButton>
        </ModalTemplate.ActionRight>
      </ModalTemplate>
    )}
    </Form.Simple>
  );
}

const ModalUserForm: FunctionComponent<UserFormProps> = ({ close, data }) => (
  <Modal.Show close={ close } style={{ width: "80vw", height: "90vh" }}>
  {(close) => (
    <UserFormView close={ close } data={ data } />
  )}
  </Modal.Show>
);

export default ModalUserForm;