import React       from "react";
import Action      from "@cComponents/action";
import T           from "@uBehaviour/i18n";
import Nudge       from "@cComponents/nudge";
import useHelper from "@universal/behaviour/hooks/useHelper";

import './actions.css';


const IcalLinkAction = ({ team }) => {
  const calendarHelper = useHelper("calendar");

  const getIcalLink = React.useCallback(() => {
    calendarHelper.copyTeamCalendarLink(team.toPlainText());
  }, [team, calendarHelper]);

  return (
    <Action 
      id="copy_team_calendar_link"
      icon="copy"
      onClick={ getIcalLink }>
        <Action.Tooltip>
          <div className="bs-teams-action-tooltip-container">
            <Nudge>
              <T>copy_team_calendar_link</T>
            </Nudge>
          </div>
        </Action.Tooltip>
    </Action>
  );
}

export default IcalLinkAction;