import React, { FunctionComponent, PropsWithChildren }      from "react";
import FocusBlur  from "@cComponents/focusBlur";
import Header from './header';
import "./body.css";

const Body: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <FocusBlur.Context className="bs-starter-body">
    <div className="bs-starter-content">
    { children }
    </div>
    <Header />
  </FocusBlur.Context>
);
export default Body;