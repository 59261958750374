import React from "react";
import Avatar from "@cFeatures/user/avatar";
import Format from '@uBusiness/format';

import "./userAvatar.css";

const UserAvatar = ({ user }) => (
  <div className="bs-userAvatar">
    <span>{ Format.User.shortFullname(user) }</span>
    <Avatar user={ user } />
  </div>
);
export default UserAvatar;