import Event from './event';

type IsSame<T> = (v1: T, v2: T) => boolean;

class Observable<T = any> {
  private value: T;
  private _onUpdate: Event;
  private isSame: IsSame<T>;
  constructor(value: T, isSame: IsSame<T> = (v1, v2) => v1 === v2){
    this.value = value;
    this.isSame = isSame;
    this._onUpdate = new Event();
  }

  get onUpdate(): Event{
    return this._onUpdate;
  }

  get(): T{
    return this.value;
  }

  set(value: T){
    if(!this.isSame(this.value, value)){
      const old = this.value;
      this.value = value;
      this._onUpdate.trigger(old, this.value);
    }
  }
}

export default Observable;