/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent } from 'react';

import { Switch, Route, Redirect } from '@cFeatures/router';

import Home from './views/home';
import Assignments from './views/assignments';
import Issues from './views/issues';
import Recurrences from './views/recurrences';
import Planning from './views/planning';
import Statistics from './views/statistics';
import Settings from '@views/settings';
import Body from './body';
import Menu from "@cComponents/menu";
import Acl from '@uBehaviour/acl';
import T from '@uBehaviour/i18n';

import useService from '@universal/behaviour/hooks/useService';
import CurrentTenant from '@root/services/currentTenant';
import Display from '@common/components/displayIf';
import HttpService from '@common/services/http';
import Modal from '@common/components/modal';
import StandartSubscription from '@entities/notifications/standartSubscription';
import Print from './features/print';
import useAcl from '@uBehaviour/hooks/useAcl';
import SessionService from '@universal/services/session';
import Release from '@common/entities/release';
import Campaign from '@root/entities/campaign';

const Root: FunctionComponent<{}> = ({}) => {
  const currentTenant = useService<CurrentTenant>("currentTenant");
  const allowedToSeeHomepage = useAcl("tenants", "withHomePage");
  const allowedToSeeAgentsList = useAcl("assignments", "viewMine");

  if(!currentTenant.isSelected()){
    return (<Redirect to='/settings' />);
  }

  if(allowedToSeeHomepage){
    return (<Redirect to='/home' />);
  }

  if(allowedToSeeAgentsList){
    return (<Redirect to='/assignments' />);
  }

  return (<Redirect to='/issues' />);
}


interface HeaderStandartProps {

}

const ConnectedLogo: FunctionComponent<{}> = ({}) => {
  const http = useService<HttpService>("http", ['onServiceUpdated']);
  
  const logo = React.useMemo(() => {
    if(http.hasRequestInProgress()){
      return (
        <img alt="BetterStreet" src={`/images/logos/betterstreet_color_spinner.svg`} style={{
          width: "40px",
          height: "40px",
        }} />
      )
    } else {
      return (
        <img alt="BetterStreet" src={`/images/logos/betterstreet_color.svg`} style={{
          width: "40px",
          height: "40px",
        }} />
      );
    }
  }, [http.hasRequestInProgress()]);

  return logo;
};

const HeaderStandart: FunctionComponent<HeaderStandartProps> = ({}) => {
  const currentTenant = useService<CurrentTenant>("currentTenant");
  
  return (
    <>
      <Menu.Section>
        <Menu.Item path="/">
        {() => (<ConnectedLogo />)}
        </Menu.Item>
      </Menu.Section>
      <Menu.Section>
        <Acl.If action="withHomePage" resource="tenants">
          <Menu.Button path="/home" icon="home">
            <T>views_header_home</T>
          </Menu.Button>
        </Acl.If>
        <Acl.If action="viewMine" resource="assignments" >
          <Menu.Button path="/assignments" icon="user">
            <T>views_header_assignments</T>
          </Menu.Button>
        </Acl.If>
        <Menu.Button path="/issues" icon="list-ul">
          <T>views_header_issues</T>
        </Menu.Button>
        <Acl.If action="seePlanning" resource="issues">
          <Menu.Button path="/planning" icon="calendar">
            <T>views_header_planning</T>
          </Menu.Button>
        </Acl.If>
        <Acl.If action="read" resource="statistics" >
          <Menu.Button path="/statistics" icon="line-chart">
            <T>views_header_statistics</T>
          </Menu.Button>
        </Acl.If>
        <Acl.If action="manage" resource="recurrences">
          <Menu.Button path="/recurrences" icon="clock-o">
            <T>views_header_recurrences</T>
          </Menu.Button>
        </Acl.If>
        <Acl.If action="create" resource="issues" >
          <Display.If condition={ currentTenant.isSelected() }>
            <Menu.Button icon='plus-circle' ToModalized={ Issues.Form } style={{ width: '70vw', height: '90vh' }}>
              <T>views_header_createIssue</T>
            </Menu.Button>
          </Display.If>
        </Acl.If>
        <Acl.If resource="tenants" action="configure">
          <Menu.Button path="/settings" icon="cog">
            <T>views_header_settings</T>
          </Menu.Button>
        </Acl.If>
      </Menu.Section>
    </>
  );
}

interface StdExpProps {

}

const Routes: FunctionComponent = () => {
  const currentTenant = useService<CurrentTenant>("currentTenant");
  const session       = useService<SessionService>("session");

  const allowedToSeeHomepage    = useAcl("tenants", "withHomePage");
  const allowedToSeePlanning    = useAcl("issues", "seePlanning");
  const allowedToSeeStatistics  = useAcl("statistics", "read");
  const allowedToSeeRecurrences = useAcl("recurrences", "manage");
  const allowedToSeeAssignments = useAcl("assignments", "viewMine");
  const allowedToSeeSettings    = useAcl("tenants", "configure");
  
  const home = React.useMemo(() => {
    if(!currentTenant.isSelected() || !allowedToSeeHomepage) return null;

    return <Route path='/home' component={ Home } />; 
  }, [currentTenant.currentId, session.userId, allowedToSeeHomepage]);

  const issuesList = React.useMemo(() => {
    if(!currentTenant.isSelected()) return null;
    
    return (<Route path='/issues' component={ Issues.List } />); 
  }, [currentTenant.currentId, session.userId]);

  const assignments = React.useMemo(() => {
    if(!currentTenant.isSelected() || !allowedToSeeAssignments) return null;

    return (<Route path='/assignments' component={ Assignments } />);
  }, [currentTenant.currentId, session.userId, allowedToSeeAssignments]);

  const planning = React.useMemo(() => {
    if(!currentTenant.isSelected() || !allowedToSeePlanning) return null;

    return (<Route path='/planning' component={ Planning } />); 
  }, [currentTenant.currentId, session.userId, allowedToSeePlanning]);

  const recurrences = React.useMemo(() => {
    if(!currentTenant.isSelected() || !allowedToSeeRecurrences) return null;
    
    return (<Route path='/recurrences' component={ Recurrences } />); 
  }, [currentTenant.currentId, session.userId, allowedToSeeRecurrences]);
  
  const statistics = React.useMemo(() => {
    if(!currentTenant.isSelected() || !allowedToSeeStatistics) return null;

    return (<Route path='/statistics' component={ Statistics } />); 
  }, [currentTenant.currentId, session.userId, allowedToSeeStatistics]);
  
  const settings = React.useMemo(() => {
    if(!allowedToSeeSettings) return null;

    return (<Route path='/settings' component={ Settings } />); 
  }, [currentTenant.currentId, session.userId, allowedToSeeSettings]);

  return (
    <Switch>
      { home }
      { assignments }
      { issuesList }
      { planning }
      { statistics }
      { recurrences }
      { settings }
      <Route path='' component={ Root } />
    </Switch>
  );
}

const StdExp: FunctionComponent<StdExpProps> = ({}) => (
  <Body>
    <Campaign.Manager />
    <Issues.IssueDetailManager>
      <HeaderStandart />
      <Routes />
      <Modal.UrlRequest displayModalValue="notificationSettings"  style={{ width: "800px", height: "700px", padding: "20px" }}>
      { (close) => ( <StandartSubscription close={ close } /> )}
      </Modal.UrlRequest>
      <Release.Next />
      <Release.AutoList application="admin" />
    </Issues.IssueDetailManager>
  </Body>
);


const PreApplication: FunctionComponent<StdExpProps> = ({}) => (
  <Switch>
    <Route path="/print" component={ Print } />
    <Route path="" component={ StdExp } />
  </Switch>
);

export default PreApplication;
