import React, { FunctionComponent, PropsWithChildren, ReactNode, RefObject } from 'react';
import Slot       from '@uComponents/slot2';

import './responsiveParts.css';

type ResponsivePartsProps = {
  direction?: "vertical" | "horizontal";
}
type PartProps = {
  noReturn?: boolean;
  noSmallScreen?: boolean;
}
type NavButtonProps = { 
  direction: "up" | "down" | "left" | "right";
  onClick: () => void
}

const NavButton: FunctionComponent<NavButtonProps> = ({ direction, onClick }) => (
  <div className={ `bs-layout-responsiveParts-navigationButton bs-layout-responsiveParts-navigationButton-${ direction }` } onClick={ onClick } >
    <span className={`fa fa-chevron-${ direction }`} />
  </div>
);

const Part = Slot<ReactNode, PartProps>();

const createBlink = (time: number, containerRef: RefObject<HTMLDivElement>, className: string) => {
  let id: number | null = null;
  return () => {
    if(!containerRef.current){
      return;
    }
    if(id){
      clearTimeout(id);
    }
    const elements = containerRef.current.getElementsByClassName(className);
    for(const element of elements){
      element.classList.add('bs-layout-responsiveParts-navigation-button-animation');
    }
    id = setTimeout(() => {
      for(const element of elements){
        element.classList.remove('bs-layout-responsiveParts-navigation-button-animation');
      }
    }, time);
  }
}
type ResponsivePartProps = PartProps & {
  direction: "vertical" | "horizontal";
  previous: (element: HTMLElement) => void;
  next: (element: HTMLElement) => void;
}
const ResponsivePart: FunctionComponent<PropsWithChildren<ResponsivePartProps>> = ({ direction, noReturn, noSmallScreen, next, previous, children}) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const nextVisible = React.useCallback(() => {
    if(!ref.current){
      return;
    }
    next(ref.current);
  }, [ref, next]);

  const previousVisible = React.useCallback(() => {
    if(!ref.current){
      return;
    }
    previous(ref.current);
  }, [ref, previous]);

  return (
    <div ref={ ref } className={ `bs-layout-responsivePart ${noSmallScreen ? "bs-layout-responsivePart-notAllowSmallScreen" : "bs-layout-responsivePart-allowSmallScreen" }` }>
      { children }
      { noReturn !== true && <NavButton direction={ direction === "vertical" ? 'up' : "left" } onClick={ previousVisible }/> }
      <NavButton direction={ direction === "vertical" ? 'down' : "right" } onClick={ nextVisible }/>
    </div>
  )
}

const ResponsiveParts: FunctionComponent<PropsWithChildren<ResponsivePartsProps>> & { Part: typeof Part }= ({ direction = "vertical", children }) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const partsProps = Part.props(children, true);

  const blink = React.useMemo(() => createBlink(2000, containerRef, 'bs-layout-responsiveParts-navigationButton'), [containerRef]);

  const previous = React.useCallback((element: HTMLElement) => {
    if(!containerRef.current){
      return;
    }
    let target = element;
    for(let el of containerRef.current.children){
      if(el === element){
        break;
      }
      if(el.classList.contains('bs-layout-responsivePart-allowSmallScreen'))
      target = el as HTMLElement;
    }
    if(!target){
      return;
    }
    if(direction === 'vertical') {
      containerRef.current.scrollTop = target.offsetTop;
    } else {
      containerRef.current.scrollLeft = target.offsetLeft;
    }
    blink();
  }, [containerRef, direction]);
  
  const next = React.useCallback((element: HTMLElement) => {
    if(!containerRef.current){
      return;
    }
    let target = element;
    const children = Array.from(containerRef.current.children).reverse();
    for(let el of children){
      if(el === element){
        break;
      }
      if(el.classList.contains('bs-layout-responsivePart-allowSmallScreen'))
      target = el as HTMLElement;
    }
    if(!target){
      return;
    }
    if(direction === 'vertical') {
      containerRef.current.scrollTop = target.offsetTop;
    } else {
      containerRef.current.scrollLeft = target.offsetLeft;
    }
    blink();
  }, [containerRef, direction]);

  React.useEffect(() => {
    if(!containerRef.current){
      return;
    }
    blink();
  }, []);
  return (
    <div className={ `bs-layout-responsiveParts bs-layout-responsiveParts-${ direction }` } ref={ containerRef } onScroll={ blink }>
    {
      partsProps.map(({ noSmallScreen, noReturn, children: part }: PropsWithChildren<PartProps>, index: number) => (
        <ResponsivePart key={ index } direction={ direction } noSmallScreen={ noSmallScreen } noReturn={ noReturn } next={ next } previous={ previous }>
          { part }
        </ResponsivePart>
      ))
    }
    </div>
)};

ResponsiveParts.Part = Part;

export default ResponsiveParts;

