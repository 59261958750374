import React        from 'react';
import Wizard       from "@cComponents/wizard"; 
import Display      from "@uComponents/displayIf";
import Button       from "@cComponents/button";
import T            from '@cBehaviour/i18n';
import Form         from './form';
import Layout       from '@cComponents/layout';
import Papa         from 'papaparse';
import Nudge        from '@cComponents/nudge';
import Application  from "@uBehaviour/application";
import Scrollbar    from "@cComponents/scrollBar";
import Input        from "@cComponents/input";

import "./import.css";

class DisplayBuilding extends React.Component {
  constructor(props) {
    super(props);
    this._form = React.createRef();
    this._ready = false;
    this.state = {
      location: null,
      disabled: false
    }
  }
  _loadAddress(){
    this._ready = false;
    if(["", ","].includes(this.props.building.address.trim())){
      this._ready = true;
      return this.setState({
        location: null
      });
    }else{
      this.setState({ disabled: true });
      this.props.googleMap.getPositionFromAddress(this.props.building.address)
        .then(position => {
          const addressP = position ? this.props.googleMap.getAddressFromPosition(position) : Promise.resolve(null);
          addressP.then(address => {
            this._ready = true;
            window.setTimeout(() => this.setState({ disabled: false }), 2500);
            return this.setState({
              location: {
                position: position,
                address: address
              }
            })
          });
        });
    }
  }
  componentDidMount() {
    this._loadAddress();
  }
  componentDidUpdate(prevProps){
    if(prevProps.building !== this.props.building){
      this._loadAddress();
    }
  }
  shouldComponentUpdate(nextProps){
    if(nextProps.building !== this.props.building){
      this._ready = false;
    }
    return true;
  }

  render() {
    const { wizard, building, index } = this.props;
    return (
      !this._ready
        ? <div>Loading</div>
        : (
          <Layout.Standart stretch={true}>
            <Layout.Standart.Header>
              <div className="bs-display-building-title">
                <Nudge>
                  <div>{`Nom d'entré : ${building.name}`}</div>
                  <div>{`Adresse d'entrée : ${building.address}`}</div>
                </Nudge>
              </div>
            </Layout.Standart.Header>
            <Layout.Standart.Content>
              <Form 
                ref={this._form}
                name={"building" + index}
                value={{
                  name: building.name,
                  location: this.state.location,
                  places:[],
                  tags: []
                }} 
              />            
            </Layout.Standart.Content>
            <Layout.Standart.Footer>
                <div className="bs-buildings-import-actions">
                  <Button.Text disabled={this.state.disabled} onClick={() => wizard.previous()}><T>previous</T></Button.Text>
                  <Button.Text disabled={this.state.disabled} onClick={() => { this.props.passBuilding(building); wizard.next() }}><T>pass</T></Button.Text>
                  <Button.Text disabled={this.state.disabled} onClick={() => this._form.current.submit(true).then(() => { this.props.addBuilding(building); wizard.next() }).catch(() => null)}><T>validate</T></Button.Text>
                </div>                
            </Layout.Standart.Footer>
          </Layout.Standart>  
        )   
    )
  }
} 

const DisplayBuildingWithGoogleService = Application.Service.forward([["google-map", "googleMap"]], DisplayBuilding)

class Import extends React.Component {
  constructor(props) {
    super(props);
    this._extractFromCsv = this._extractFromCsv.bind(this);
    this._passBuilding = this._passBuilding.bind(this);
    this._addBuilding = this._addBuilding.bind(this);
    this._passedBuildings = [];
    this._addedBuildings = [];
    this.state = {
      buildings: null,
      parsingError: null,
      extractionErrors: null,
      encoding: "ISO-8859-15"
    }
  }

  _extractFromCsv(ev) {
    Papa.parse(ev.target.files[0], {
      header: false,
      skipEmptyLines: true,
      encoding: this.state.encoding,
      complete: result => {
        if (result.data.length) {
          this.setState({
            buildings: result.data.slice(5).reduce((buildings, building) => {
              if (building[2].length) {
                buildings.push({
                  name:building[2],
                  address: building[7]
                })
              }              
              return buildings;
            }, [])})
        } else if (result.errors.length) {
          this.setState({extractionErrors: result.errors})
        } else {
          this.forceUpdate()
        }
      },
      error: (error, file) => this.setState({
        parsingError: error
      })
    });
  }

  _passBuilding(building) {
    if(!this._addedBuildings.includes(building) && !this._passedBuildings.includes(building)){
      this._passedBuildings.push(building);
    }
  }

  _addBuilding(building) {
    const idx = this._passedBuildings.indexOf(building);
    if(idx !== -1){
      this._passedBuildings.splice(idx, 1);
    }
    if(!this._addedBuildings.includes(building)){
      this._addedBuildings.push(building);
    }
  }

  render() {
    return (
      <Wizard close={this.props.onClose} style={{ width: "80vw", height: "90vh" }}>
        <Wizard.Step>
          {(context, wizard) => {
            return (
              <Layout.Standart stretch={true}>
                <Layout.Standart.Header>
                  <div className="bs-buildings-import-title"><T>buildins_import</T></div>
                </Layout.Standart.Header>
                <Layout.Standart.Content>
                  <div className="bs-buildings-import-body">
                    <div className="bs-building-import-label"><T>building_import_label</T> *</div>
                    <input type="file" accept=".csv" onChange={this._extractFromCsv} />
                    <div className="bs-building-encoding-label"><T>equipment_encoding_label</T></div>
                    <Input.Radio.Btn inline value={ this.state.encoding } onChange={(input) => this.setState({encoding: input})}>
                      <Input.Radio.Value value="ISO-8859-15"><div>ISO-8859-15 (format windows)</div></Input.Radio.Value>
                      <Input.Radio.Value value="UTF-8"><div>UTF-8</div></Input.Radio.Value>
                    </Input.Radio.Btn>   
                    <Display.If condition={this.state.buildings}>
                    {() => (
                      <div className="bs-buildings-import-body-extracted">
                        {this.state.buildings.length 
                          ? <T bind={{length: this.state.buildings.length}}>building_import_extracted_success</T>
                          : <T>building_import_extracted_no_buildings</T>
                        }
                      </div>
                    )}                    
                    </Display.If>
                    <Display.If condition={this.state.parsingError}>
                      <div className="bs-buildings-import-body-error">{this.state.parsingError}</div>
                    </Display.If>
                    <Display.If condition={this.state.extractionErrors}>
                    {()=> (
                      <div className="bs-buildings-import-body-error">
                        <ul>
                          {this.state.extractionErrors.map(error => (<li><T bind={{...error, row: error.row ? error.row : 1}}>building_import_extraction_error</T></li>))}
                        </ul>
                      </div>
                    )}
                    </Display.If>
                  </div> 
                </Layout.Standart.Content>
                <Layout.Standart.Footer>
                  <div className="bs-buildings-import-actions">
                    <Button.Text onClick={this.props.onClose}><T>cancel</T></Button.Text>
                    <Button.Text disabled={!this.state.buildings} onClick={() => wizard.next()}><T>start_integration</T></Button.Text>
                  </div>
                </Layout.Standart.Footer>
              </Layout.Standart>      
            )
          }}
        </Wizard.Step>
        {this.state.buildings && this.state.buildings.length && this.state.buildings.reduce((acc, building, index) => {
          acc.push(
            <Wizard.Step key={ "form" + index}>
              {(context, wizard) => (<DisplayBuildingWithGoogleService index={index} building={building} wizard={wizard} addBuilding={this._addBuilding} passBuilding={this._passBuilding} />)}
            </Wizard.Step>
          );
          return acc;
        }, [])}
        <Wizard.Step>
          {(context, wizard) => (
              <Layout.Standart stretch={true}>
                <Layout.Standart.Header>
                  <div className="bs-buildings-import-title"><T>buildins_import</T></div>
                </Layout.Standart.Header>
                <Layout.Standart.Content>
                  <Scrollbar>
                    <div className="bs-buildings-import-body">
                      <div className="bs-building-import-label"><T>building_import_file_end</T></div>
                      <div className="bs-buildings-import-abstract-container">
                        <div><T>added_buildings</T></div>
                        <ul>
                          {this._addedBuildings.map(building => (<li>{`${building.name} (${building.address})`}</li>))}
                        </ul>
                      </div>
                      <div className="bs-buildings-import-abstract-container">
                        <div><T>passed_buildings</T></div>
                        <ul>
                          {this._passedBuildings.map(building => (<li>{`${building.name} (${building.address})`}</li>))}
                        </ul>
                      </div>
                    </div>
                  </Scrollbar>
                </Layout.Standart.Content>
                <Layout.Standart.Footer>
                <div className="bs-buildings-import-actions">
                  <Button.Text onClick={() => wizard.previous()}><T>previous</T></Button.Text>
                  <Button.Text onClick={this.props.onClose}><T>end_integration</T></Button.Text>
                </div>                
                </Layout.Standart.Footer>
              </Layout.Standart>
          )}
        </Wizard.Step>
      </Wizard>
    )
  }
}

export default Import;