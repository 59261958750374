import React, { FunctionComponent, PropsWithChildren } from 'react';
import T from "@uBehaviour/i18n";
import { Paragraph, Text, Style } from '@common/components/text';
import cButton from "@cComponents/button";
import Form from '@universal/behaviour/form';
import Input from '@common/components/input';
import { Checkbox } from 'semantic-ui-react';
import useService from '@universal/behaviour/hooks/useService';
import SessionService from '@universal/services/session';
import ApiService from '@universal/services/api';
import ScrollBar from '@cComponents/scrollBar/scrollBar';

import './unsubscribe.css';

type StepProps = {
  next: () => void;
  cancel: () => void;
}

const ConfirmButton = cButton.withStyle(cButton.Stylized.orange.big.fluid.round);
const CancelButton = cButton.withStyle(cButton.Stylized.white.big.fluid.round);

export const StepValidation: FunctionComponent<StepProps> = ({ next, cancel }) => {
  const promotedText2 = React.useMemo(() => (<Text style={ Style.bold.orange }><T>starter_unsubscribe_validation_text2_promoted</T></Text>), []);
  return (
    <div className="bs-unsubscribe-step-container">
      <div className='bs-unsubscribe-validation-image-container'>
        <img src='/images/starter/envelope_sad.png' alt='logo betterstreet starter' />
      </div>
      <Paragraph style={ Style.black.large.bold.center }>
        <T>starter_unsubscribe_validation_title</T>
      </Paragraph>
      <Paragraph style={ Style.black.center }>
        <T>starter_unsubscribe_validation_text1</T>
      </Paragraph>
      <Paragraph style={ Style.black.center }>
        <T bind={{ promotedText:  promotedText2}}>starter_unsubscribe_validation_text2</T>
      </Paragraph>
      <div className="bs-unsubscribe-step-buttons-container">
        <ConfirmButton onClick={ next }><T>starter_unsubscribe_validation_confirm</T></ConfirmButton>
        <CancelButton onClick={ cancel }><T>starter_unsubscribe_validation_cancel</T></CancelButton>
      </div>
    </div>
  );
}

const ReasonTextPromoted: FunctionComponent = () => (
  <Text style={ Style.black.bold }><T>starter_unsubscribe_reason_text_promoted</T></Text>
);

const reasons = ["notInterested", "alreadyHaveSTManagementProduct", "alreadyHaveCitizenIssuesManagementProduct", "tooManyEmail"];


type FormValueProps = {
  reasons: string[];
  noCitizenEmail: boolean;
}

export const StepReason: FunctionComponent<StepProps> = ({ next, cancel }) => {
  const apiService = useService<ApiService>("api");

  const checkboxValues = React.useMemo(() => reasons.map(reason => (
    <Input.Checkbox.Value key={ reason } value={ reason }>
    {(selected: boolean) => (
      <div className='bs-unsubscribe-reason-checkboxValue-container'>
        <Checkbox checked={ selected }/>
        <Text style={ Style.black.center }><T>{ `starter_unsubscribe_reason_checkboxValue_${reason}` }</T></Text>
      </div>
    )}
    </Input.Checkbox.Value>
  )), []);

  const submit = React.useCallback((form, { reasons, noCitizenEmail }: FormValueProps) => {
    apiService.service("starterActivation", "unsubscribe").execute({ reasons, noCitizenEmail: !!noCitizenEmail })
      .then(next);
  }, [ apiService, next ]);
  const confirm = React.useCallback((form) => form.submit(true), []);

  return (
      <Form.Simple submit={ submit }>
      {(context, value, errors, form, submit) => (
        <div className="bs-unsubscribe-step-container">
          <div className='bs-unsubscribe-step-title-container'>
            <Paragraph style={ Style.black.large.bold.center }>
              <T>starter_unsubscribe_reason_title</T>
            </Paragraph>
          </div>
          <Paragraph style={ Style.black.center }>
            <T bind={{ promoted: <ReasonTextPromoted /> }}>starter_unsubscribe_reason_text</T>
          </Paragraph>
          <Form.Simple.InputAdapter name="reasons">
            {(value, set, clear) => {
              if (!value) {
                value = [];
              }
              return (
                <Input.Checkbox 
                  value={value} 
                  onChange={ set }
                  noFrame
                >
                  { checkboxValues }
                </Input.Checkbox>
              )
            }}
          </Form.Simple.InputAdapter>
          <div className='bs-unsubscribe-noCitizenEmail-info'>
            <Paragraph style={ Style.black.justify }>
              <T>starter_unsubscribe_noCitizenEmail_info</T>
            </Paragraph>
            <Form.Simple.InputAdapter name="noCitizenEmail">
            {(value, set) => (
              <Input.Radio value={ value } onChange={ set } noFrame>
                <Input.Radio.Value value={ !value }>
                  <div className='bs-unsubscribe-noCitizenEmail'>
                    <Checkbox checked={ value }/>
                    <Text style={ Style.black.bold }><T>starter_unsubscribe_noCitizenEmail</T></Text>
                  </div>
                </Input.Radio.Value>
              </Input.Radio>
            )}
            </Form.Simple.InputAdapter>
          </div>
          <div className="bs-unsubscribe-step-buttons-container">
            <ConfirmButton onClick={ () => confirm(form) }><T>starter_unsubscribe_reason_confirm</T></ConfirmButton>
            <CancelButton onClick={ cancel }><T>starter_unsubscribe_reason_cancel</T></CancelButton>
          </div>
        </div>
      )}
      </Form.Simple>
  )
}

const ConfirmationTextPromoted: FunctionComponent = () => (
  <Text style={ Style.black.bold }><T>starter_unsubscribe_confirmation_text_confirm_promoted</T></Text>
);

type StepConfirmationProps = {
  confirm?: () => void;
}
export const StepConfirmation: FunctionComponent<StepConfirmationProps> = ({ confirm }) => {
  const sessionService = useService<SessionService>("session");
  
  const mail = React.useMemo(() => {
    return (<Text style={ Style.orange }>{ sessionService.user.email }</Text>);
  }, [ sessionService ]);

  return (
    <div className="bs-unsubscribe-step-container">
      <div className='bs-unsubscribe-step-title-container'>
        <Paragraph style={ Style.black.large.bold.center }>
          <T>starter_unsubscribe_confirmation_title</T>
        </Paragraph>
      </div>
      <Paragraph style={ Style.black.center }>
        <T bind= {{ mail, promoted: <ConfirmationTextPromoted /> }}>starter_unsubscribe_confirmation_text_confirm</T>
      </Paragraph>
      <Paragraph style={ Style.black.center }>
        <T>starter_unsubscribe_confirmation_text_change_opinion</T>
      </Paragraph>
      { confirm &&
        <div className="bs-unsubscribe-step-buttons-container">
          <ConfirmButton onClick={ confirm }><T>starter_unsubscribe_confirmation_button_confirm</T></ConfirmButton>
        </div>
      }
    </div>
  )
}

const Unsubscribe: FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
  <div className="bs-unsubscribe-container">
    <ScrollBar>
      <div className='bs-unsubscribe-content'>
        <div className='bs-unsubscribe-logo-container'>
          <img src='/images/logos/betterstreet_starter_color_text_horizontal2.svg' alt='logo betterstreet starter' />
        </div>
          { children } 
      </div>
    </ScrollBar>
  </div>
  );
}

export default Unsubscribe;