import React from "react";

class Pages extends React.Component{
  componentDidMount() {
    if(this.props.type === "landscape"){
      const styles = `
      @page {
        size: landscape;
        margin: 0.1in;
      }
      `;
      const styleSheet      = document.createElement("style");
      styleSheet.type       = "text/css";
      styleSheet.innerText  = styles;
      document.head.appendChild(styleSheet);
    }
  }
  render(){
    return this.props.children;
  }
}

export default Pages;