import React                from "react";

import Input                from "@cComponents/input";
import Field                from "@cComponents/field";
import T                    from "@uBehaviour/i18n";
import Button               from "@cComponents/button";

import './defaultBulkUpdateForm.css';

const DeadLineForm = ({ submit, close }) => {
  const [deadline, setDeadline] = React.useState(null);

  const lSubmit = React.useCallback(() => {
    submit(deadline).then(() => close());
  }, [submit, close, deadline]);

  return (
    <div className="bs-issue-list-bulkUpdate-form">
      <div className="bs-issue-list-bulkUpdate-form-header">
        <T>issue_list_groupDeadline_modal_title</T>
      </div>
      <div className="bs-issue-list-bulkUpdate-form-content">
        <Field.Display>
          <Field.Label><T>issue_list_groupDeadline_deadline</T></Field.Label>
          <Field.Input><Input.Date value={ deadline } onChange={(deadline) => setDeadline(deadline)}/></Field.Input>
        </Field.Display>
      </div>
      <div className="bs-issue-list-bulkUpdate-form-footer">
        <Button.Text onClick={ close }><T>issue_list_group_form_modal_cancel</T></Button.Text>
        <Button.Text onClick={ lSubmit }><T>issue_list_group_form_modal_submit</T></Button.Text>
      </div>
    </div>
  );
}

export default DeadLineForm;