import React        from 'react';
import T            from '@cBehaviour/i18n';
import Item         from '@cComponents/item';
import Title        from '@cComponents/title';
import Actions      from './actions';
import Display      from '@uComponents/displayIf';
import File         from '@cComponents/file';

import './item.css';

const ReleaseItem = (props) => {
  const mapLayer =  props.data;
  return (
  <Item className="bs-item-standard bs-mapLayer-item">
    <Item.Illustration>
      <img src={mapLayer.iconUrl} width={100} height={100} />
    </Item.Illustration>
    <Item.Content.Header>
      <Title>{ mapLayer.name }</Title>
    </Item.Content.Header>
    <Item.Content.Body>
      <div>
        <span><T>mapLayer_item_serviceUrl</T> : </span>
        <span>{mapLayer.serviceUrl} </span>
      </div>
    </Item.Content.Body>
    <Item.Content.Footer>
      <div>
        <span><T>mapLayer_item_type</T> : </span>
        <span>{mapLayer.type}</span>
      </div>   
    </Item.Content.Footer>
    <Item.Meta>

    </Item.Meta>
    <Item.Actions>
      <Actions mapLayer={ mapLayer }/>
    </Item.Actions>
  </Item>
)};

export default ReleaseItem;