import React        from 'react';
import T            from '@cBehaviour/i18n'; 
import Display      from '@cComponents/displayIf';
import Item         from '@cComponents/item';
import Layout       from '@cComponents/layout';
import Title        from '@cComponents/title';
import Money        from '@cComponents/money';

import './item.css';



export default (props) => {
    const supply = props.data;

    const getValorizationType = (valorizationType) => {
        switch(valorizationType) {
            case 'kg':
                return <T>kilogramme</T>;
            case 'pc':
                return <T>piece</T>;
            case 'l': 
                return <T>liter</T>;
            case 'other':
                return <T>other_mesure</T>
            default:
                return <T>unknown</T>
        }
    }

    return (
        <Item type="line" className="bs-supply-item">
            <Item.Content>
                <Layout.Inline className="bs-supply-content">
                    <Layout.Inline.Header className="bs-supply-title">
                        <Title>{supply.name}</Title>
                    </Layout.Inline.Header>
                    <Layout.Inline.Content className="bs-supply-description">
                        <div className="bs-supply-description-content">
                            <span><T>external_reference</T> : </span>
                            <span>{
                                supply.sku 
                                    ? supply.sku 
                                    : <T>undefined</T>
                                }</span>
                        </div>
                    </Layout.Inline.Content>
                    <Layout.Inline.Footer className="bs-action">
                        <div>
                            <span><T>cost</T> : </span>
                            <Display.If condition={supply.valorization && supply.valorization.type && supply.valorization.cost !== 0}>
                                <Display.Then>
                                    {()=> (
                                        <span>
                                            <Money value={ supply.valorization.cost } />
                                            <span>&nbsp;/&nbsp;</span>
                                            <span>
                                                {
                                                    getValorizationType(supply.valorization.type)
                                                }
                                            </span>
                                        </span>
                                    )}
                                </Display.Then>
                                <Display.Else>
                                    <T>none</T>
                                </Display.Else>
                            </Display.If>
                        </div>
                    </Layout.Inline.Footer>
                </Layout.Inline>
            </Item.Content>
        </Item>    
    );
}