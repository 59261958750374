import React from 'react'
import classNames from 'classnames'
import PartialHTML from 'client/features/common/components/PartialHTML.jsx'

const renderBsId = (bsId, bsIdNumber, full, highlight, freeSearch) => full ? 
    <PartialHTML content={ bsId } highlight={ highlight } freeSearch={ freeSearch } inline/>
    : 
    <PartialHTML content={ bsIdNumber } highlight={ highlight } freeSearch={ freeSearch } inline/>


const IssueId = (props) => {
    const { bsId, bsIdNumber, full, short, highlight, freeSearch } = props
    return (
        <span className={ classNames('c-issue-id', { 'c-issue-id--short': short }) }>
            { !short && <span>{ renderBsId(bsId, bsIdNumber, full, highlight, freeSearch) }</span> }
            { short && <span><PartialHTML content={ bsIdNumber } highlight={ highlight } freeSearch={ freeSearch } inline/></span>}
        </span>
    )
}

export default IssueId
