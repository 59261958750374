// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-filter-tag-line {
  font-size: 1.2em;
  padding:5px;
  font-weight: 450;
  color: rgb(94, 103, 108);
  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);
  margin-bottom: 12px;
  opacity: 0.8;
  transition: all 0.3;
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/entities/filters/tag.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;EAChB,wBAAwB;EACxB,4EAA4E;EAC5E,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".bs-filter-tag-line {\n  font-size: 1.2em;\n  padding:5px;\n  font-weight: 450;\n  color: rgb(94, 103, 108);\n  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);\n  margin-bottom: 12px;\n  opacity: 0.8;\n  transition: all 0.3;\n  border-radius: 3px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
