import React, { ComponentType, FunctionComponent, PropsWithChildren } from "react";
import Modal from '@cComponents/modal';
import { Route, Link } from '@cFeatures/router';
import ObjectId from "@universal/types/technic/ObjectId";
import Flatten from "@universal/types/technic/Flatten";
import _ from 'lodash';


type LinkedProps<Type> = {
  data: Type;
}
type ExtractDataToUrlType<Type extends {_id: ObjectId }> = (data: Type) => string;

type PropsMaybeWithChildren<Props> = Props & PropsWithChildren<Props>;

export function withLink<Props extends LinkedProps<Type>, Type  extends {_id: ObjectId }>
  (Component: ComponentType<PropsMaybeWithChildren<Props>>, extractDataToUrl: ExtractDataToUrlType<Type> = (data) => data._id): FunctionComponent<PropsMaybeWithChildren<Props>> {
  return (props) => (
    <Link to={  `/${extractDataToUrl(props.data)}` }>
      <Component { ...props }>
      { props.children }
      </Component>
    </Link>
  );
}

type ToModalizedProps = {
  id: ObjectId;
  close: () => void;
}

type ModalizedProps = {
  style?: React.CSSProperties;
}


export function withModalLinked<Props extends ToModalizedProps>
  (Component: ComponentType<PropsMaybeWithChildren<Props>>): FunctionComponent<Flatten<PropsMaybeWithChildren<Omit<Props, keyof ToModalizedProps>> & ModalizedProps>>{
  return (props) => (
    <Route path="/:id" hasJunction={ false }>
    {({ id }: { id: ObjectId }, goToParentPath: () => void) => (
      <Modal.Show close={ goToParentPath } style={ props.style }>
      {(close) => (
        <Component { ..._.omit(props, ['style', 'children']) } id={ id } close={ close }>
        { props.children }
        </Component>
      )}
      </Modal.Show>
    )}
    </Route>
  );
}