import React, { Component } from 'react'
import T from 'i18n-react'
import ISSUE from 'common/ISSUE'
import classNames from 'classnames'

class IssueStatesDropdown extends Component {

  updateState(state, issue, event) {
    event.stopPropagation()
    this.props.updateState(state, issue)
  }

  render() {
    const statesNode = ISSUE.states.enum.map((state) => {
      if (this.props.defaultState && state === this.props.defaultState)
        return
      return (
        <div className={ classNames('c-dropdown-menu__item') } key={ state }>
          <a
            className={ classNames('u-display-block', 'u-padding-tiny', `bg-${state}`) }
            onClick={ this.updateState.bind(this, state, this.props.issue) }
          >
            { T.translate(state) }
          </a>
        </div>
      )
    })
    return (
      <div className='c-dropdown-menu'>
        <span className='c-dropdown-menu__arrow'></span>
        <div className='c-dropdown-menu__body c-dropdown-menu__body--states'>
          { statesNode }
        </div>
      </div>
    )
  }
}

export default IssueStatesDropdown