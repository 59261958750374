import React from 'react';
import useService from './useService';
import CurrentTenantService from "../../services/currentTenant";
import useForceUpdate from './useForceUpdate';
import { Listener } from '../../lib/event';

const useCurrentTenant = (): CurrentTenantService => {
  const currentTenantService = useService("currentTenant");
  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    const listener = new Listener(() => {
      forceUpdate();
    });

    currentTenantService.onServiceUpdated.addListener(listener);
    return () => {
      currentTenantService.onServiceUpdated.removeListener(listener);
    };
  }, [currentTenantService]);

  return currentTenantService as CurrentTenantService;
};

export default useCurrentTenant;
