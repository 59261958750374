// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-state-list {
  position: relative;
}

.bs-state-list-content {
  background-color: white;
  padding: 6px;
  box-shadow: 0 0 1px rgba(44,62,80,.6), 0 5px 10px -3px rgba(44,62,80,.4);
}

.bs-state-list-arrow{
  border-bottom: 10px solid #fff;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  right: 4px;
  top: -8px;
}

.bs-state-list-item {
  color:white;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--state-list-item-color);
  width: 140px;
  height: 28px;
}

.bs-state-list-item:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../common/features/state/select.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,uBAAuB;EACvB,YAAY;EACZ,wEAAwE;AAC1E;;AAEA;EACE,8BAA8B;EAC9B,mCAAmC;EACnC,oCAAoC;EACpC,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,8CAA8C;EAC9C,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".bs-state-list {\n  position: relative;\n}\n\n.bs-state-list-content {\n  background-color: white;\n  padding: 6px;\n  box-shadow: 0 0 1px rgba(44,62,80,.6), 0 5px 10px -3px rgba(44,62,80,.4);\n}\n\n.bs-state-list-arrow{\n  border-bottom: 10px solid #fff;\n  border-left: 10px solid transparent;\n  border-right: 10px solid transparent;\n  position: absolute;\n  right: 4px;\n  top: -8px;\n}\n\n.bs-state-list-item {\n  color:white;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: var(--state-list-item-color);\n  width: 140px;\n  height: 28px;\n}\n\n.bs-state-list-item:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
