import React        from 'react';
import T            from '@cBehaviour/i18n';
import Item         from '@cComponents/item';
import Layout       from '@cComponents/layout';
import Title        from '@cComponents/title';

import './item.css';

export default (props) => {
    const contact = props.data;
    return (
        <Item type="line" className="bs-contact-item">
            <Item.Content>
                <Layout.Inline className="bs-contact-content">
                    <Layout.Inline.Header className="bs-contact-title">
                        <Title>{contact.name}</Title>
                    </Layout.Inline.Header>
                    <Layout.Inline.Content className="bs-contact-description">
                        <div className="bs-contact-description-content">
                            <span><T>emails</T> : </span>
                            <span>{contact.emails.join(", ")}</span>
                        </div>
                    </Layout.Inline.Content>
                    <Layout.Inline.Footer className="bs-action">
                        <div>
                            <span><T>active_m</T> : </span>
                            <span>{contact.disabled ? <T>no</T> : <T>yes</T>}</span>
                        </div>
                    </Layout.Inline.Footer>
                </Layout.Inline>
            </Item.Content>
        </Item>    
    );
}