import * as StatisticsActions from 'client/features/statistics/actions'

import { setFiltersValues, unsetFilter } from 'client/features/issues/reducers' /*Todo: feature filter?!*/
import { buildStats } from 'client/features/statistics/lib'

import { registerDefaultReducer, registerInitialstate } from "client/lib/reduxSaga";

export const initialState = {
  loading: false,
  filters: {
    fields: [],
    values: {
      type: null,
      period: 'month'
    },
    data: {
      dates: {}
    }
  }
}

const initializeState = () => ({ ...initialState })

registerInitialstate("statistics", initializeState);
registerDefaultReducer("statistics", function statistics(state = initializeState(), action = {}) {
  const { payload } = action
  switch (action.type) {

  case StatisticsActions.SET_STATS_FILTERS_VALUE:
    return {
      ...state,
      list: [],
      filters: {
        ...state.filters,
        values: setFiltersValues(state.filters.values, payload.key, payload.value, payload.uniq, payload.inputKey)
      }
    }

  case StatisticsActions.RESET_STATS_FILTERS_VALUES:
    return {
      ...state,
      filters: {
        ...state.filters,
        values: {
          type: null,
          period: 'month'
        }
      }
    }

  case StatisticsActions.UNSET_STATS_FILTER_VALUE:
    return {
      ...state,
      filters: {
        ...state.filters,
        values: unsetFilter(state.filters.values, payload.key, payload.uniq, payload.kill)
      }
    }

  case StatisticsActions.StatsRequest.get.TYPES.REQUEST:
    return {
      ...state,
      loading: true
    }

  case StatisticsActions.StatsRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      loading: false,
      data: buildStats(payload)
    }

  default:
    return state
  }
});

