import React, { ChangeEvent, FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import Slot from '@universal/components/slot2';

import './select.css';
import Key from '@universal/lib/model';
import T from '@universal/behaviour/i18n';
import ScrollBar from '@common/components/scrollBar/scrollBar';
import useI18n from '@universal/behaviour/hooks/useI18n';
import Button from '@common/components/button';
import { Grid } from 'semantic-ui-react';


interface SelectProps<T> {
  datas: T[],
  selected: T[];
  onChange: (selected: T[]) => void;
  getFilteredData: (data: T) => string;
  stopSelection: () => void;
  dataKey: Key;
}

interface SelectedElement<T> {
  data: T;
  selected: boolean;
}

type SelectType<T> = FunctionComponent<PropsWithChildren<SelectProps<T>>>;


function Select<T>(){
  const Title = Slot();
  const Item = Slot<(data: T, selected: boolean, toggleSelection: (data: T) => void) => ReactNode>();

  const SelectComponent: SelectType<T> =  ({ datas, selected, dataKey: key, onChange, children, getFilteredData, stopSelection }: PropsWithChildren<SelectProps<T>>) => {
    const [stateDatas, setStateDatas] = React.useState<SelectedElement<T>[]>([]);

    React.useEffect(() => {
      setStateDatas(() => datas.map(data => ({
        data,
        selected: !!selected.find(d => key.equals(d, data))
      })));
    }, [datas, selected, setStateDatas]);

    const toggleSelection = React.useCallback((data: T) => {
      setStateDatas((stateDatas) => {
        const d = stateDatas.find(d => key.equals(d.data, data));
        if(d){
          d.selected = !d.selected;
        }
        return stateDatas.slice();
      });
    }, [setStateDatas]);

    const inputSearch = React.createRef<HTMLInputElement>();

    const inputPlaceHolder = useI18n('components_select_search');

    React.useEffect(() => {
      inputSearch.current.focus();
    }, []);

    const [filter, setFilter] = React.useState(/.*/);
    const changeFilter = React.useCallback(({ target: { value: filter }}: ChangeEvent<HTMLInputElement>) => {
      if(filter){
        setFilter(new RegExp(`.*${filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')}.*`, 'i'));
      }else{
        setFilter(/.*/);
      }
    }, [setFilter]);

    const validSelection = React.useCallback(() => {
      onChange(stateDatas.filter(sd => sd.selected).map(sd => sd.data));
      stopSelection();
    }, [onChange, stopSelection, stateDatas]);

    const usedDatas = React.useMemo(() => stateDatas.filter(sd => filter.test(getFilteredData(sd.data))), [stateDatas, filter, getFilteredData]);

    const title = Title.get(children);
    const item = Item.get(children);

    return (
      <div className='bs-select-base'>
        <div className='bs-select-base-header'>
          <div className='bs-select-base-header-title'>{ title }</div>
          <div className='bs-select-base-header-input-layout'>
            <input
              ref={ inputSearch }
              className='bs-select-base-header-input'
              type='text'
              placeholder={ inputPlaceHolder }
              onChange={ changeFilter }
            />
            <span className="fa fa-search" />
          </div>
        </div>
        <ScrollBar>
          <div className='bs-select-base-content'>
          {
            usedDatas.map(sd => item(sd.data, sd.selected, toggleSelection))
          }
          </div>
        </ScrollBar>
        <div className='bs-select-base-footer'>
          <Grid>
            <Grid.Row>
              <Grid.Column width={ 12 } />
              <Grid.Column width={4} className='bs-select-base-footer-control'>
                <Button.Text color="#a09696" onClick={ stopSelection }>
                  <T>components_select_cancel</T>
                </Button.Text>
                <Button.Text onClick={ validSelection }>
                  <T>components_select_valid</T>
                </Button.Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
    );
  };

  return Object.assign(SelectComponent, { Title, Item });
}

export default Select;
