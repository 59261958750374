import React from 'react'
import FontAwesome from 'react-fontawesome'

const IssueListItemSelector = ({ selectActive, select, issueId, selected }) =>
    <div onClick={ (e) => e.stopPropagation() }>
        {
            selectActive &&
            <div className='c-issue__selector' onClick={ select && select.bind(null, issueId) }>
                <FontAwesome name={ selected ? 'check-square-o' : 'square-o' } size='lg'/>
            </div>
        }
    </div>

export default IssueListItemSelector