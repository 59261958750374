import React        from 'react';
import T            from "@cBehaviour/i18n";    
import Item         from '@cComponents/item';
import Button       from '@cComponents/button';
import Application  from "@uBehaviour/application";

import Title        from '@cComponents/title';

import './item.css';

export default Application.Helper.forward([["users", "usersHelper"]], props => {
    const user = props.data;
    return (
        <Item className="bs-user-citizenItem">
            <Item.Content.Header>   
                <Title>{user.fullname}</Title>
            </Item.Content.Header>
            <Item.Content.Body>
                <div>
                    <span><T>email</T> : </span>
                    <span>{ user.email }</span>
                </div>
                <div>
                    <span><T>emailValidated</T> : </span>
                    <span>{ user.emailValidated ? <T>yes</T> : <T>no</T> }</span>
                </div>
            </Item.Content.Body>
            <Item.Content.Footer>
              <div>
                <span><T>anonymized</T> : </span>
                <span>{ user.anonymized ? <T>yes</T> : <T>no</T> }</span>
              </div>
            </Item.Content.Footer>
            <Item.Actions>
                {user.authentificationProviders && user.authentificationProviders[0].discriminator === "username" && (
                    <Button.Text onClick={(ev) => { ev.stopPropagation(); props.usersHelper.sendCitizenInvitation(user) }}><T>invite</T></Button.Text>
                )}
            </Item.Actions>
        </Item>
    );
});
