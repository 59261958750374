import React, { Component } from 'react'
import T from 'i18n-react'
import Date from '../../../common/components/Date'
import _ from 'lodash'
import classNames from 'classnames'
import MatchAndLinkBsId from './MatchAndLinkBsId.jsx'
import Avatar from 'client/features/common/components/Avatar.jsx'

class IssueLog extends Component {
  /*TODO: REFACTOR*/
  render() {
    const { log, goToIssue = () => null } = this.props
    let logClass = 'date pull-left'
    log.newState ? logClass += ` ${ log.newState}` : logClass += ' no-state'

    let multipleNode

    if (log.changes) {
      multipleNode = log.changes.map((mergedLog) =>
        <div key={ mergedLog._id } className='c-issue-log__multiple u-margin-bottom-tiny'>
          <div>
            {
              mergedLog.title && (
                <div className='c-text-small'>
                  { mergedLog.title }
                </div>
              )
            }

            {
              (mergedLog.old || mergedLog.new) && (
                <div>
                  { mergedLog.old } &gt; <strong>{ mergedLog.new }</strong>
                </div>
              )
            }
            {
              mergedLog.comment && (
                <div>
                  {
                    mergedLog.comment && (
                      <div className={
                        classNames(
                          'c-issue-log-comment',
                          {
                            'c-issue-log-comment--highlight': mergedLog.highlightComment
                          }
                        )
                      }>
                        <MatchAndLinkBsId string={ mergedLog.comment } goToIssue={ goToIssue } issue={ this.props.issue }/>
                      </div>
                    )
                  }
                </div>
              )
            }
          </div>
        </div>
      )
    }

    return (
      <article
        className={
          classNames('c-card__item', 'c-issue-log', 'o-media', 'o-media--small'/*, { 'c-issue-log--comment': (!multipleNode && log.comment) }*/)
        }
      >
        { !this.props.hideDate &&
        <div className='o-media__img'>
          <Date date={ log.date } displayHour={ false } />
        </div>
        }
        <div className='o-media__img'>
          <Avatar
            label={ log.userInitials }
            size='u-medium'
            img={ log.avatar }
          />
        </div>

        <div className='o-media__body'>
          <h4 className='c-heading-small u-margin-top-none'>
            <MatchAndLinkBsId string={ log.title } goToIssue={ goToIssue } issue={ this.props.issue }/>
            { /*<span dangerouslySetInnerHTML={ { __html: log.title } } /> */}
          </h4>
          <div className={ classNames('c-issue-log__content') }>
            {
              multipleNode
            }
          </div>
          <div className='c-heading-tiny c-issue-log__header u-color-grey'>
            { T.translate('by') } { log.user } @ <Date date={ log.date } format='HH:mm' />
          </div>
        </div>
      </article>
    )
  }
}

export default IssueLog