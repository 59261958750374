import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import toDic        from "@cLib/toDic";
import Application  from '@uBehaviour/application';
import User         from "@entities/user";

const roles             = ["admin", "manager", "categoryManager"];
const ManagerFilter = Application.Service.forward(["currentTenant"], ({ currentTenant }) => (
  <>
    <Collapsable alwaysOpen>
      <Collapsable.Title><T >filter_manager_roles</T></Collapsable.Title>
      <Collapsable.Content>
        <Filter.Generic multiple name="roles" buildQuery={values => ({ tenants: { $elemMatch: { tenant: currentTenant.currentId, roles: { $in: values }}}})}>
          {(values, add, drop, clear) => (
            <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
            {
              roles.map(role => (<Input.Checkbox.Value key={role} value={role}><T>{`filter_manager_${role}`}</T></Input.Checkbox.Value>))
            }
            </Input.Checkbox.BtnFilter>
          )}
        </Filter.Generic>
      </Collapsable.Content>
    </Collapsable>
    <Collapsable alwaysOpen>
      <Collapsable.Title><T >filter_manager_active</T></Collapsable.Title>
      <Collapsable.Content>
        <Filter.Generic multiple name="disabled" buildQuery={values => ({ "tenants.disabled": { $in: values } })}>
        {(values, add, drop, clear) => (
          <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
            <Input.Checkbox.Value value={false}><T>yes</T></Input.Checkbox.Value>
            <Input.Checkbox.Value value={true}><T>no</T></Input.Checkbox.Value>
          </Input.Checkbox.BtnFilter>
        )}
        </Filter.Generic>
      </Collapsable.Content>
    </Collapsable>
  </>
));

export default Application.Service.forward(["api", "currentTenant", "repository"], props => (
  <Filter.Generic 
    deshydrate={value => value._id}
    hydrate={values => props.api.service("users", "get").execute({_id: {$in: values }}, null, null, values.length).then(managers => toDic(managers, "_id"))}
    multiple
    name="managers"
    buildQuery={values => ({ "manager": { $in: values.map(v => v._id) } })}
    stringify={value => { return (<><T>issue_filter_managers</T> : {value.fullname}</>)}}
  >
    {(values, add, drop, clear) => {
      let query = { tenants: { $elemMatch: { disabled: false, tenant: props.currentTenant.currentId, roles: { $in: ["categoryManager", "manager", "admin"] }}} };
      if(values.length){
        query = { $and: [query, { _id: { $nin: values.map(v => v._id) }}]};
      }
      return (
        <Collapsable>
          <Collapsable.Title><T>issue_filter_managers</T></Collapsable.Title>
          <Collapsable.Content>
            <Input.Selectable 
              value={ values.map(v => props.repository.get("User").key.extract(v))}
              onChange={(vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }}
              model={ props.repository.get("User") }
              load={{ avatar:true }}
              query={ query }
              sort={{ fullname: 1 }}
              textify={ user => user.fullname }
              filterQuery={ value => ({ fullname: { '$regex': value, '$options': 'i' } }) }
              defaultFilter={[{ name: 'disabled', value: false, }]}
            >
              <Input.Selectable.Filter><ManagerFilter /></Input.Selectable.Filter>
              <Input.Selectable.Item><User.Item /></Input.Selectable.Item>
            </Input.Selectable>
          </Collapsable.Content>
        </Collapsable>
      )
    }}
  </Filter.Generic>
));