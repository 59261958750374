import React                               from "react";
import { Link, Route } from '@cFeatures/router';
import moment                              from "moment";
import md5                                 from 'md5';
import Application                         from "@uBehaviour/application";
import Data                                from "@uBehaviour/data";
import T                                   from "@uBehaviour/i18n";
import DragAndDrop                         from "@cBehaviour/dragAndDrop";
import Display                             from "@cComponents/displayIf";
import FFilter                             from '@cFeatures/filter';
import Scrollbar                           from "@cComponents/scrollBar";
import Input                               from "@cComponents/input";
import Contractable                        from "@cComponents/contractable";
import Filter                              from "@cComponents/filter";
import CPlanning                           from "@cComponents/planning";
import AssignmentItem                      from "@entities/assignments/item";
import AssignmentFilters                   from "@entities/assignments/filters";
import Datas                               from "./datas";
import Team                                from "./team";
import SelectDate                          from "./selectDate";
import { getPlanningClassname }            from "./lib";
import PrintCalendar                       from "./form/printCalendar";
import PrintWorkOrder                      from "./form/printWorkorder";
import { MainHeader, SecondaryHeader }     from "./header";
import Selector                            from "./selector";
import Item                                from "./item";
import ICalUrlCopyButton                   from "./iCalUrlCopyButton";
import _                                   from "lodash";
import { AbsenceTunnel, AssignmentTunnel } from "./tunnel";
import AbsenceForm                         from "./form/absence";
import Modal                               from "@cComponents/modal";
import AssignmentForm                      from "@entities/assignments/form";
import Button                              from "@cComponents/button";
import AreaTitle                           from "@cComponents/areaTitle";
import Query                               from '@uLib/query';
import useAsyncValidationWithQuestion      from '@uBehaviour/hooks/useAsyncValidationWithQuestion';
import Search                              from "@entities/assignmentsAndIssues/search";
import Criterion                           from "@universal/lib/filter";
import Highlight                           from "@common/components/highlight";
import useService                          from "@universal/behaviour/hooks/useService";
import Acl                                 from "@universal/behaviour/acl";
import usePager                            from "@universal/behaviour/data/hooks/usePager";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar                           from "@cComponents/searchbar";
import { IssueDetail }                     from "../issues";

import "./planning.css";
import Text, { Style } from "@common/components/text";


const AssignmentItemShort = ({ data }) => {
  const acl = useService("acl");
  const canCreate = acl.connectedUserIsAllow("absences", "manage") || acl.connectedUserIsAllow("assignments", "manage");

  if(!canCreate){
    return (
      <Link to={ `/issue/${ data.issue }`} key={ data._id }>
        <AssignmentItem.Short data={ data } />
      </Link>
    );
  }
  return (
    <Link to={ `/issue/${ data.issue }`} key={ data._id }>
      <DragAndDrop.Draggable type="Assignment" datas={{ assignment: data }}>
      {(isDragged) =>  (
        <AssignmentItem.Short data={ data } noTooltip={ isDragged }/>
      )}
      </DragAndDrop.Draggable>
    </Link>
  );
};

const periodHandler3Days = (date) => ({ 
  start: moment(date).startOf("day").subtract(1, "days").toDate(),
  end: moment(date).startOf("day").add(2, "days").toDate(),
});
periodHandler3Days.type = "day";
periodHandler3Days.discriminator = "3days";

const periodHandler5Days = (date) =>({
  start: moment(date).startOf("week").toDate(),
  end: moment(date).startOf("week").add(5, "days").toDate()
});
periodHandler5Days.type = "week";
periodHandler5Days.discriminator = "workWeek";

const periodHandlerWeekly = (date) =>({
  start: moment(date).startOf("week").toDate(),
  end: moment(date).startOf("week").add(7, "days").toDate()
});
periodHandlerWeekly.type = "week";
periodHandlerWeekly.discriminator = "fullWeek";

const periodHandlerMonthly = (date) =>({
  start: moment(date).startOf("month").toDate(),
  end: moment(date).startOf("month").add(1, "months").toDate()
});
periodHandlerMonthly.type = "month";
periodHandlerMonthly.discriminator = "fullMonth";

const SelectPeriodType = ({ value, onChange }) => {
  const extractType = React.useCallback((value) => value.discriminator, []);
  return (
    <div className="bs-planning-selectPeriodType">
      <Input.Select value={ value } onChange={ onChange } comparableValue={ extractType } postSelect={value => value} fluid textAlign="center">
        <Input.Select.Value value={ periodHandler3Days }>
          <T bind={{ nbrDays: 3 }}>planning_header_selectPeriodDuration_days</T>
        </Input.Select.Value>
        <Input.Select.Value value={ periodHandler5Days }>
          <T bind={{ nbrDays: 5 }}>planning_header_selectPeriodDuration_days</T>
        </Input.Select.Value>
        <Input.Select.Value value={ periodHandlerWeekly }>
          <T>planning_header_selectPeriodDuration_week</T>
        </Input.Select.Value>
        <Input.Select.Value value={ periodHandlerMonthly }>
          <T>planning_header_selectPeriodDuration_month</T>
        </Input.Select.Value>
      </Input.Select>
    </div>
)};

const ButtonCheckboxStyle = Button.Stylized.small.transparent.fluid;
const TextBold = Style.standard.black.bold;
const TextNormal = Style.standard.black;
const ButtonCheckbox = ({ pushed, children }) => {
  return (
    <ButtonCheckboxStyle>
      <Text style={ pushed ? TextBold : TextNormal }>{ children }</Text>
    </ButtonCheckboxStyle>
  );
};
const Checkbox = Input.Checkbox.withButton(ButtonCheckbox);

const StateFilter = ({ acceptedStates, onChange }) => (
  <Checkbox value={ acceptedStates } onChange={ onChange } inline className="bs-planning-stateFilter">
    <Input.Checkbox.Value value="to_do">
      <T>planning_header_filterState_toDo</T>
    </Input.Checkbox.Value>
    <Input.Checkbox.Value value="ongoing">
      <T>planning_header_filterState_ongoing</T>
    </Input.Checkbox.Value>
    <Input.Checkbox.Value value="finished">
      <T>planning_header_filterState_finished</T>
    </Input.Checkbox.Value>
  </Checkbox>
);


const List = ({ tenantId }) => {
  const [displayFilter, setDisplayFilter] = React.useState(false);

  const assignmentQuery = {};

  const unscheduledFilter =  React.useMemo(() => ([
    { name: "onlyPlanned", value: 1, excludes: [] },
    { name: "workOrderState", value: "to_do", excludes: [] },
    { name: "workOrderState", value: "ongoing", excludes: [] }
  ]), []);
  
  const pendingWorkOrdersFilter  = React.useMemo(() => (
    ["to_do", "ongoing"].map(state => ({ name: "workOrderState", value: state, excludes: [] }))
  ), []);

  const finishedWorkOrdersFilter = React.useMemo(() => (
    [{ name: "workOrderState", value: "finished", excludes: [] }]
  ), []);

  const defaultFilters = React.useMemo(() => ([
    {
      name: <T>planning_filter_unscheduled</T>,
      values: unscheduledFilter,
      _id: "see_all_unscheduled"
    },
    {
      name: <T>planning_filter_pending_workOrders</T>,
      values: pendingWorkOrdersFilter,
      _id: "see_pending_workOrders",
    },
    {
      name: <T>planning_filter_finished_workOrders</T>,
      values: finishedWorkOrdersFilter,
      _id: "see_finished_workOrders"
    },
  ]), [finishedWorkOrdersFilter, pendingWorkOrdersFilter, unscheduledFilter]);

  return (
    <Filter.Aggregator default={ unscheduledFilter }>
      <Contractable hide className={{ 
        contracted: "bs-planning-contractable-contracted", 
        relaxed: "bs-planning-contractable-relaxed"
      }}>
        <Contractable.Contracted>
        { relax => (
          <div>
            <div className="bs-planning-contracted-button" onClick={() => relax() }>
              <span className="fa fa-bars" />
              <span className="fa fa-chevron-right" />
            </div>
          </div>
        )}
        </Contractable.Contracted>
        <Contractable.Relaxed>
        { contract => (
          <div className="bs-planning-contractable">
            <div className="bs-planning-list-header">
              <div>
                <Filter.Subject>
                {composeQuery => (
                  <Data.Count model="Assignment" query={ Query.joinWithOptimizer(composeQuery(assignmentQuery), { tenant: tenantId })}>
                  {(nbrAssignments) => (
                    <T bind={{ nbrAssignments }}>planning_list_nbrAssignments</T>
                  )}
                  </Data.Count>
                )}
                </Filter.Subject>
                <div className="bs-planning-relaxed-button" onClick={() => contract() }>
                  <span className="fa fa-chevron-left" />
                  <span className="fa fa-bars" />
                </div>
              </div>
              <div>
                <Filter.Generic
                  multiple
                  name="onlyPlanned"
                  stringify={() => <T>planning_list_onlyPlanned</T>}
                  buildQuery={ v => ({ "assignment.scheduledFrom": null }) }
                >
                {(value, add, drop, clear) => (
                  <Input.Checkbox.BtnFilter value={ value ? value : [] } onChange={(values, value) => values.length ? add(value) : clear()}>
                    <Input.Checkbox.Value value={1}><T>planning_list_onlyPlanned</T></Input.Checkbox.Value>
                  </Input.Checkbox.BtnFilter>
                )}
                </Filter.Generic>
                <div className="bs-planning-switchFilterList" onClick={() => setDisplayFilter(!displayFilter)}>
                {
                  displayFilter
                    ? <T>planning_list_seeList</T>
                    : <T>planning_list_seeFilters</T>
                }
                </div>
              </div>
            </div>
            <div className="bs-planning-contractable-list">
              <div className={`bs-planning-list-filter bs-planning-list-${displayFilter ? "display" : "hidden"}`}>
                <div className="bs-planning-list-filter-header">
                  <Scrollbar>
                    <div className="bs-planning-list-filter-header-viewport">
                      <Filter.Bulk />
                      <FFilter.Manager type="calendar" defaultFilters={ defaultFilters } title={<T>planning_myFilters</T>} />
                    </div>
                  </Scrollbar>
                </div>
                <div className="bs-planning-list-filter-filters">
                  <Scrollbar>
                    <div className="bs-planning-list-filter-content">
                      <AssignmentFilters />
                    </div>
                  </Scrollbar>
                </div>
              </div>
              <div className={`bs-planning-list-list bs-planning-list-${displayFilter ? "hidden" : "display"}`}>
                <Filter.Subject>
                {composeQuery => (
                  <Scrollbar.ListController>
                    <Data.List model="Assignment" load={ AssignmentItem.Short.load } query={ Query.joinWithOptimizer(composeQuery(assignmentQuery), { tenant: tenantId }) } sort={{ createdAt: -1 }}>
                    {assignment => (
                      <AssignmentItemShort data={ assignment } />
                    )}
                    </Data.List>
                  </Scrollbar.ListController>
                )}
                </Filter.Subject>
              </div>
            </div>
          </div>
        )}
        </Contractable.Relaxed>
      </Contractable>
    </Filter.Aggregator>
  )
};

const EquipmentsPlanning = Application.Service.forward(['currentTenant'], ({ start, end, datas, selectedEquipments, onEquipmentSelected, currentTenant }) => {
  return (
    <>
      <MainHeader icon="wrench">
        <span><T>planning_equipment_header</T></span>
        <Data.Query model="Equipment" query={{ disabled: false, tenant: currentTenant.currentId }} sort={{ name: 1 }} limit={ 1000 }>
        {(datas) => (
          <Selector selected={ selectedEquipments } onSelect={ onEquipmentSelected } datas={ datas} dataToValue={ equipment => equipment._id } dataToLabel={ equipment => equipment.name }>
            <span><T>planning_equipment_selectLabel</T></span>
            <span>{ selectedEquipments.length ? `${selectedEquipments.length}/${datas.length}` : "" }</span>
          </Selector>
        )}
        </Data.Query>
      </MainHeader>
      {
        datas.map(({ equipment, assignments }) => (
          <>
            <SecondaryHeader icon="wrench">
              <span className="bs-planning-equipment-header">{ equipment.name }</span>
              <ICalUrlCopyButton query={{ "assignment.necessariesEquipments": equipment._id }} />
            </SecondaryHeader>
            <CPlanning start={ start } end={ end } cellClassName="bs-team-planning-cell" withEmptyLine>
              {
                assignments.map(assignment => (
                  <CPlanning.Event 
                    key={assignment._id}
                    start={ moment(assignment.assignment.scheduledFrom).toDate() }
                    end={ moment(assignment.assignment.scheduledTo).toDate() }
                  >
                    <Item.Slim assignment={ assignment } equipment={ equipment }/>
                  </CPlanning.Event>
                ))
              }
              <CPlanning.Empty>
              {(date) => (
                <DragAndDrop.Droppable  allowedTypes={["Assignment", "AssignmentTeam", "AssignmentAgent", "AssignmentEquipment"]} datas={{ type: "Equipment", date, equipment }}>
                {(isDragInProgress, isDropAllowed) => (
                  <div className={ getPlanningClassname(date, `bs-planning-empty bs-planning-empty-slim ${isDragInProgress && isDropAllowed ? "bs-planning-drop-allowed" : ""}`, currentTenant) }/>
                )}
                </DragAndDrop.Droppable>
              )}
              </CPlanning.Empty>
            </CPlanning>
          </>
        ))
      }
    </>
  );
});



const getAssignment = (
  { type: sourceType, datas: { assignment, agent: sourceAgent, team: sourceTeam, equipment: sourceEquipment } }, 
  { datas: { type: targetType, date, agent: targetAgent, team: targetTeam, equipment: targetEquipment }}
) => {
  const newAssignment = Object.assign({}, JSON.parse(JSON.stringify(assignment.toPlainText().assignment)));

  if(newAssignment.scheduledFrom && newAssignment.scheduledTo){
    newAssignment.scheduledTo   = date.clone().add(moment(newAssignment.scheduledTo).diff(moment(newAssignment.scheduledFrom)), "milliseconds");
    newAssignment.scheduledFrom = date.clone();
  }else{
    newAssignment.scheduledFrom = date.clone();
    newAssignment.scheduledTo   = newAssignment.scheduledFrom.clone().add(1, "d");
  }

  switch(sourceType){
    case "Assignment": break;
    case "AssignmentTeam":
        newAssignment.team = [];
      break;
    case "AssignmentAgent":
      const idxAgent = newAssignment.agents.indexOf(sourceAgent._id);
      if(idxAgent !== -1){
        newAssignment.agents.splice(idxAgent, 1);
      }
      break;
    case "AssignmentEquipment":
      const idxEquipment = newAssignment.necessariesEquipments.indexOf(sourceEquipment._id);
      if(idxEquipment !== -1){
        newAssignment.necessariesEquipments.splice(idxEquipment, 1);
      }
      break;
    default: throw new Error(`Unknow sourceType : ${sourceType}`);
  }

  switch(targetType){
    case "Team":
      newAssignment.team    = [targetTeam._id];
      newAssignment.agents  = [];
      break;
    case "Agent":
      newAssignment.team    = [];
      newAssignment.agents.push(targetAgent._id);
      break;
    case "Equipment":
      newAssignment.necessariesEquipments.push(targetEquipment._id);
      break;
    default: throw new Error(`Unknow targetType : ${targetType}`);
  }
  
  return newAssignment;
};

const cleanAssignment = ({ type: sourceType, datas: { assignment, agent: sourceAgent, team: sourceTeam, equipment: sourceEquipment } }) => {
  const newAssignment = Object.assign({}, assignment.toPlainText().assignment);
  switch(sourceType){
    case "AssignmentTeam":
        newAssignment.team = [];
      break;
    case "AssignmentAgent":
      const idxAgent = newAssignment.agents.indexOf(sourceAgent._id);
      if(idxAgent !== -1){
        newAssignment.agents.splice(idxAgent, 1);
      }
      break;
    case "AssignmentEquipment":
      const idxEquipment = newAssignment.necessariesEquipments.indexOf(sourceEquipment._id);
      if(idxEquipment !== -1){
        newAssignment.necessariesEquipments.splice(idxEquipment, 1);
      }
      break;
    default: throw new Error(`Unknow sourceType : ${sourceType}`);
  }
  return newAssignment;
}


const WarningAbsence = ({ accept, reject, children }) => (
  <Modal.Show close={ reject } userCanClose={ false }>
    {(close) => (
      <div className="bs-planning-warningAbsence">
        <div className="bs-planning-warningAbsence-title"><AreaTitle><T>planning_warningAbsence_title</T></AreaTitle></div>
        <div className="bs-planning-warningAbsence-content">
          { children }
        </div>
        <div className="bs-planning-warningAbsence-footer">
          <Button.Text onClick={ close }><T>planning_warningAbsence_cancel</T></Button.Text>
          <Button.Text onClick={ accept }><T>planning_warningAbsence_accept</T></Button.Text>
        </div>
      </div>
    )}
  </Modal.Show>
);
const WarningAgentAbsence = ({ data, accept, reject }) => (
  <WarningAbsence accept={ accept } reject={ reject }>
    <T bind={{ user: data.fullname }}>planning_warningAbsence_label_agent</T>
  </WarningAbsence>
);
const WarningTeamAbsence = ({ accept, reject }) => (
  <WarningAbsence accept={ accept } reject={ reject }>
    <T>planning_warningAbsence_label_team</T>
  </WarningAbsence>
);

const WarningDeadline = ({ data: assignment, accept, reject }) => (
  <Modal.Show close={ reject } userCanClose={ false }>
  {(close) => (
    <div className="bs-planning-warningDeadline">
      <div className="bs-planning-warningDeadline-title"><AreaTitle><T>planning_warningDeadline_title</T></AreaTitle></div>
      <div className="bs-planning-warningDeadline-content"><T bind={{ deadline: moment(assignment.deadline).format("DD/MM/YYYY") }}>planning_warningDeadline_label</T></div>
      <div className="bs-planning-warningDeadline-footer">
        <Button.Text onClick={ close }><T>planning_warningDeadline_cancel</T></Button.Text>
        <Button.Text onClick={ accept }><T>planning_warningDeadline_accept</T></Button.Text>
      </div>
    </div>
  )}
  </Modal.Show>
);

const WarningNotAWorkingDay = ({ data: periodsNotWorked, accept, reject }) => (
  <Modal.Show close={ reject } userCanClose={ false }>
  {(close) => (
    <div className="bs-planning-warningNotAWorkingDay">
      <div className="bs-planning-warningNotAWorkingDay-title"><AreaTitle><T>planning_warningNotAWorkingDay_title</T></AreaTitle></div>
      <div className="bs-planning-warningNotAWorkingDay-content">
        <T>planning_warningNotAWorkingDay_label1</T>
        <ul>
        {
          periodsNotWorked.map(({ start, end }) => (
            <li>
              <T bind={{ start: start.format("DD/MM/YYYY"), end: end.clone().subtract(1, "day").format("DD/MM/YYYY") }}>
              { start.isSame(end.clone().subtract(1, "day"), "day") ? "planning_warningNotAWorkingDay_label_oneDay" : "planning_warningNotAWorkingDay_label_period" }
              </T>
            </li>
          ))
        }
        </ul>
        <T>planning_warningNotAWorkingDay_label2</T>
      </div>
      <div className="bs-planning-warningNotAWorkingDay-footer">
        <Button.Text onClick={ close }><T>planning_warningNotAWorkingDay_cancel</T></Button.Text>
        <Button.Text onClick={ accept }><T>planning_warningNotAWorkingDay_accept</T></Button.Text>
      </div>
    </div>
  )}
  </Modal.Show>
);

const EquipmentNotAvailableAssignments = ({ assignments }) => (
  <ul>
  {
    assignments.map(assignment => ( 
      <li key={ assignment.bsId }>
        <T bind={ assignment }>planning_warningEquipment_alreadyUserOnAssignment</T>
      </li>
    ))
  }
  </ul>
);
const EquipmentNotAvailable = ({ equipment, assignments }) => (
  <>
    <span><b>{ equipment.name }</b> : </span>
    <ul>
      <EquipmentNotAvailableAssignments assignments={ assignments } />
    </ul>
  </>
);

const WarningEquipmentUnavailable = ({ data: equipmentsUnvailable, accept, reject }) => {
  const { datas: equipments } = usePager({
    model: "Equipment",
    query: { _id: { $in: equipmentsUnvailable.map(eu => eu.equipment) }},
    loadAll: true
  });
  if(!equipments.length){
    return null;
  }

  return (
    <Modal.Show close={ reject } userCanClose={ false }>
    {(close) => (
      <div className="bs-planning-warningEquipmentUsedOnOtherAssignment">
        <div className="bs-planning-warningEquipmentUsedOnOtherAssignment-title">
          <AreaTitle><T>planning_warningEquipment_already_used_title</T></AreaTitle>
        </div>
        <div className="bs-planning-warningEquipmentUsedOnOtherAssignment-content">
          <span>
            <T>planning_warningEquipment_already_used_label</T>
          </span>
          <ul>
          { 
            equipmentsUnvailable.map(({ equipment, assignments }) => (
              <li key={ equipment }>
                <EquipmentNotAvailable equipment={ equipments.find(e => e._id === equipment) } assignments={ assignments } />
              </li>
            ))
          }
          </ul>
          <span className="bs-planning-warningEquipmentUsedOnOtherAssignment-confirm">
            <T>planning_warningEquipment_areYouSure</T>
          </span>
        </div>

        <div className="bs-planning-warningEquipmentUsedOnOtherAssignment-footer">
          <Button.Text onClick={ close }>
            <T>planning_warningEquipment_cancel</T>
          </Button.Text>
          <Button.Text onClick={ () => { accept(); close(); } }>
            <T>planning_warningEquipment_accept</T>
          </Button.Text>
        </div>
      </div>
    )}
    </Modal.Show>
  );
};

const isMovmement = (currentAssignment, newAssignment) => {
  return !(newAssignment.scheduledFrom.isSameOrAfter(currentAssignment.scheduledFrom) && newAssignment.scheduledFrom.isBefore(currentAssignment.scheduledTo)
    && newAssignment.scheduledTo.isAfter(currentAssignment.scheduledFrom) && newAssignment.scheduledTo.isSameOrBefore(currentAssignment.scheduledTo));
};

const ConfirmAndCreateAssignment = ({ date, agent, team, clear, validate }) => {
  const [valid, setValid] = React.useState(false);

  React.useEffect(() => {
    validate(date, agent, team, clear).then(() => {
      setValid(true);
    });
  }, [])


  if(!valid) {
    return null;
  }

  return (
    <Modal.Show close={ clear } style={{ width: "70vw", height: "90vh" }}>
    {(close) => (
      <AssignmentForm close={ close } date={ date } agent={ agent } team={ team } />
    )}
    </Modal.Show>
  );
}

const Planning = ({ api, filter, currentTenant, composeQuery }) => {
  const [warningAgentAbsence, confirmScheduleOnAgentAbsence] = useAsyncValidationWithQuestion(WarningAgentAbsence);
  const [warningTeamAbsence, confirmScheduleOnTeamAbsence] = useAsyncValidationWithQuestion(WarningTeamAbsence);

  const [warningDeadline, confirmScheduleBeyondDeadline] = useAsyncValidationWithQuestion(WarningDeadline);
  const [warningNotAWorkingDay, confirmScheduleOnNotAWorkingDay] = useAsyncValidationWithQuestion(WarningNotAWorkingDay);
  const [warningEquipmentUnavailable, confirmAssignEquipmentUnavailable] = useAsyncValidationWithQuestion(WarningEquipmentUnavailable);

  const searchAbsences = React.useCallback(async(agents, start, end) => (
    await api.service('absences', 'searchByPeriods').execute([{
      start: start,
      end: end
    }], agents, currentTenant.currentId
  )), [api, currentTenant]);

  const confirmAbsenceAgent = React.useCallback(async(agent, { start, end }) => {
    if(!agent) {
      return true;
    }
    const absences = await searchAbsences([agent], start, end);

    if(absences.length && !await confirmScheduleOnAgentAbsence(agent)){
      return false;
    }
    return true;
  }, [confirmScheduleOnAgentAbsence, searchAbsences]);


  const confirmAbsenceTeam = React.useCallback(async(team, { start, end }) => {
    if(!team || !team.members?.length) {
      return true;
    }
    const agents = team.members;
    const absences = await searchAbsences(agents, start, end);

    const allAgentsInTeamAbsent = agents.every((agent) => absences.map(a => a.user).includes(agent));
    if(allAgentsInTeamAbsent && !await confirmScheduleOnTeamAbsence(team)){
      return false;
    }
    return true;
  }, [confirmScheduleOnTeamAbsence, searchAbsences]);

  const confirmNotWorkingDay = React.useCallback(async(from, to) => {
    let date = from.clone();
    const notWorkingDayPeriods = [];
    let start = null;
    
    while(date.toDate() < to.toDate()){
      if(currentTenant.isNotAWorkingDay(date)){
        if(!start){
          start = date.clone();
        }
      } else {
        if(start){
          notWorkingDayPeriods.push({ start, end: date.clone() });
          start = null;
        }
      }
      date.add(1, "day");
    }
    if(start){
      notWorkingDayPeriods.push({ start, end: date.clone() });
      start = null;
    }
    if(notWorkingDayPeriods.length && !await confirmScheduleOnNotAWorkingDay(notWorkingDayPeriods)){
      return false;
    }

    return true;
  }, [confirmScheduleOnNotAWorkingDay, currentTenant]);

  const confirmScheduleNewAssignment = React.useCallback(async(date, agent, team, clear) => {
    if(!date){
      clear && clear();
      return false;
    }

    if(!await confirmNotWorkingDay(date, date.clone().add(1, "day"))){
      clear && clear();
      return false;
    };

    // Absences
    const scheduledFrom = date.clone();
    const scheduledTo   = scheduledFrom.clone().add(1, "day");

    const confirmAbsences = !!agent
      ? await confirmAbsenceAgent(agent.toPlainText(), { start: scheduledFrom, end: scheduledTo })
      : await confirmAbsenceTeam(team.toPlainText(),   { start: scheduledFrom, end: scheduledTo });

    if(!confirmAbsences){
      clear && clear();
      return false;
    }

    return confirmAbsences;
  }, [confirmAbsenceAgent, confirmAbsenceTeam]);
  
  const onDrop = React.useCallback(async (source, target) => {
    try{
      let newAssignment;
      const currentAssignment = source.datas.assignment;
      
      if(target.datas.type === "DELETE"){
        newAssignment = cleanAssignment(source);
      }else{
        newAssignment = getAssignment(source, target);

        //Confirmation planification sur absence agent
        if(target.datas.agent){
          const absences = await searchAbsences([target.datas.agent], newAssignment.scheduledFrom, newAssignment.scheduledTo);
          if(absences.length && !await confirmScheduleOnAgentAbsence(target.datas.agent)) {
            return;
          }
        //Confirmation planification sur absence équipe  
        } else if(target.datas.team && target.datas.team.members.toPlainText().length){
          const members = target.datas.team.members.toPlainText();
          const absences = await searchAbsences(members, newAssignment.scheduledFrom, newAssignment.scheduledTo);
          const absentAgents =  absences.map(a => a.user);
          const allAgentsInTeamAbsent = members.every((agent) => absentAgents.includes(agent));
          if(allAgentsInTeamAbsent && !await confirmScheduleOnTeamAbsence(target.datas.agent)) {
            return;
          }
        }

        //Confirmation planification au delà de la deadline
        if(currentAssignment.deadline
            && currentAssignment.deadline <= newAssignment.scheduledFrom.toDate()
            && !await confirmScheduleBeyondDeadline(currentAssignment)
        ){
          return;
        }

        //Confirmation planification sur jour non travaillée
        if(!await confirmNotWorkingDay(newAssignment.scheduledFrom, newAssignment.scheduledTo)){
          return;
        }

        //Confirmation disponibilité de l'équipement sur l'assignation
        let testEquipmentAvaibility = target.datas.type === "Equipment"
          ? [target.datas.equipment._id]
          : [];
        
        if(isMovmement(currentAssignment.assignment, newAssignment)){
          testEquipmentAvaibility = testEquipmentAvaibility.concat(currentAssignment.assignment.necessariesEquipments.toPlainText());
        }
        
        if(testEquipmentAvaibility.length){
          const unavailableEquipments = await api.service("equipments", "isAvailable").execute(
            testEquipmentAvaibility,
            newAssignment.scheduledFrom,
            newAssignment.scheduledTo,
            [newAssignment._id]
          );
          if(!unavailableEquipments.available && !await confirmAssignEquipmentUnavailable(unavailableEquipments.equipments)){
            return;
          }
        }
      }
      api.service("issues", "setAssignment").execute(currentAssignment.issue, currentAssignment._id, _.omit(newAssignment, "_id", "bsIdNumber", "progress"));
    }catch(e){

    }
  }, [
    api,
    confirmScheduleBeyondDeadline,
    confirmScheduleOnAgentAbsence,
    confirmScheduleOnTeamAbsence,
    confirmAssignEquipmentUnavailable,
    searchAbsences,
    confirmNotWorkingDay,
  ]);
  const [current, setCurrent] = React.useState(moment());

  const [periodHandler, setPeriodHandler] = React.useState(() => periodHandler5Days);
  
  const { start, end } = React.useMemo(() => periodHandler(current), [current, periodHandler]);

  const [acceptedStates, setAcceptedStates] = React.useState([]);
  const statesFilterHandler = React.useCallback(assignment => {
    if(!acceptedStates.length){
      return true;
    }
    return acceptedStates.includes(assignment.assignment.progress);
  }, [acceptedStates]);

  const query = composeQuery();
  const assignmentFilter = React.useMemo(() => {
    return Criterion.factory("Assignment", query || {});
  }, [JSON.stringify(query)]);

  const searchFilterHandler = React.useCallback(assignment => {
    return assignmentFilter.match(assignment);
  }, [assignmentFilter]);

  const assignmentFilterHandler = React.useCallback((assignment) => {
    return statesFilterHandler(assignment) && searchFilterHandler(assignment);
  }, [statesFilterHandler, searchFilterHandler]);

  const [acceptedTeams, setAcceptedTeams] = React.useState([]);
  const teamsFilterHandler = React.useCallback(team => {
    if(!acceptedTeams.length){
      return true;
    }
    return acceptedTeams.includes(team._id);
  }, [acceptedTeams, setAcceptedTeams]);

  const setAcceptedTeamsHandler = React.useCallback(teams => {
    setAcceptedTeams(teams);
    filter.storeForPlanning(teams);
  }, [setAcceptedTeams, filter]);

  React.useEffect(() => {
    filter.getForPlanning().then(teams => {
      setAcceptedTeams(teams);
    });
  }, []);


  const [acceptedEquipments, setAcceptedEquipments] = React.useState([]);
  const equipmentsFilterHandler = React.useCallback(equipment => {
    if(!acceptedEquipments.length){
      return true;
    }
    return acceptedEquipments.includes(equipment._id);
  }, [acceptedEquipments, setAcceptedEquipments]);

  const [printWorkorderPeriod, setPrintWorkorderPeriod] = React.useState(null);
  const openPrintWorkorder   = React.useCallback((start, end) => setPrintWorkorderPeriod({ start, end }), [setPrintWorkorderPeriod]);
  const closePrintWorkorder  = React.useCallback(() => setPrintWorkorderPeriod(null), [setPrintWorkorderPeriod]);

  const [printCalendarPeriod, setPrintCalendarPeriod] = React.useState(null);
  const openPrintCalendar   = React.useCallback((start, end) => setPrintCalendarPeriod({ start, end }), [setPrintCalendarPeriod]);
  const closePrintCalendar  = React.useCallback(() => setPrintCalendarPeriod(null), [setPrintCalendarPeriod]);

  return (
  <>
    <AbsenceTunnel.Manager>
      <AssignmentTunnel.Manager>
        <DragAndDrop.Manager onDrop={ onDrop }>
        {(dragAndDropManager) => (
          <div className={`bs-planning${ dragAndDropManager.isDragInProgress ? " bs-planning-drop-forbidden" : ""}`}>
            <DragAndDrop.Droppable className="bs-planning-issues" allowedTypes={["AssignmentTeam", "AssignmentAgent", "AssignmentEquipment"]} datas={{ type: "DELETE" }}>
              <List tenantId={currentTenant.currentId} />
            </DragAndDrop.Droppable>
            <Highlight.Manager>
              <Datas 
                start={ start }
                end={ end }
                hash={ md5(JSON.stringify({ acceptedStates, acceptedTeams, acceptedEquipments, query })) }
                assignmentFilter={ assignmentFilterHandler }
                teamFilter={ teamsFilterHandler }
                equipmentFilter={ equipmentsFilterHandler }
              >
              {({ teamsAndAgents, equipments, start, end }) => {
                const sup7Days = moment(end).diff(start, 'days') > 7;
                return (
                  <div className="bs-planning-assignments">
                    <div className="bs-planning-assignments-header">
                      <ListHeaderLayout>
                        <ListHeaderLayout.Left className="bs-planning-assignments-header-left">
                          <div style={{ width: "120px" }}>
                            <Data.Query model="Team" query={{ disabled: false, tenant: currentTenant.currentId }} sort={{ name: 1 }} limit={ 1000 }>
                            {(datas) => (
                              <Selector selected={ acceptedTeams } onSelect={ setAcceptedTeamsHandler } datas={ datas.concat([{ _id: "equipment", name: (<T>planning_team_selectEquipment</T>)}]) } dataToValue={ team => team._id } dataToLabel={ team => team.name }>
                                <span><T>planning_team_selectLabel</T></span>
                                <span>{ acceptedTeams.length ? `${acceptedTeams.length}/${datas.length + 1}` : "" }</span>
                              </Selector>
                            )}
                            </Data.Query>
                          </div>
                          <div>
                            <StateFilter acceptedStates={ acceptedStates } onChange={ setAcceptedStates } />
                          </div>
                        </ListHeaderLayout.Left>
                        <ListHeaderLayout.Center>
                          <Searchbar>
                            <Search issueIdPath="issue" assignmentsPath="assignment" fluid/>
                          </Searchbar>
                        </ListHeaderLayout.Center>
                        <ListHeaderLayout.Right className="bs-planning-assignments-header-right">
                          <div className="bs-planning-assignments-header-selectDate">
                            <SelectDate type={ periodHandler.type } current={ current } onDateSelected={date => setCurrent(moment(date)) } />
                            <span className="fa fa-print" onClick={() => openPrintWorkorder(start, end) } />
                            <span className="fa fa-calendar-check-o" onClick={() => openPrintCalendar(start, end) } />
                          </div>
                          <div>
                            <SelectPeriodType value={ periodHandler } onChange={value => setPeriodHandler(() => value)} />
                          </div>
                        </ListHeaderLayout.Right>
                      </ListHeaderLayout>
                    </div>
                    <div className="bs-planning-assignments-body">
                      <div>
                        <CPlanning start={ start } end={ end } withEmptyLine tableClassName="bs-planning-assignments-body-custom-table" rowClassName="bs-planning-assignments-body-custom-row">
                          <CPlanning.Empty>
                          {(date) => (
                            <div className={getPlanningClassname(date, "bs-planning-assignments-body-header", currentTenant)}>
                            { 
                              sup7Days
                                ? <><div>{date.format("dd")}</div><div>{date.format("DD")}</div></>
                                : <div> 
                                    <div className="bs-planning-assignments-body-header-holidayDay">
                                      <span>
                                      { currentTenant.isAnHolidayDay(date) ? <T>planning_header_holidayDay</T> : null }
                                      </span>
                                    </div>
                                    <div className="bs-planning-assignments-body-header-withButton">
                                      <span>{ date.format("dddd DD/MM") }</span>
                                      <span className="fa fa-print" onClick={() => openPrintWorkorder(date.clone(), date.clone().add(1, "day")) } />
                                    </div>
                                  </div>
                            }
                            </div>
                          )}
                          </CPlanning.Empty>
                        </CPlanning>
                      </div>
                      <div>
                        <Scrollbar>
                          <Display.If condition={ !!printWorkorderPeriod }>
                          {() => (
                            <PrintWorkOrder teams={ teamsAndAgents.map(data => data.team) } onClose={ closePrintWorkorder} start={ printWorkorderPeriod?.start } end={ printWorkorderPeriod?.end }/>
                          )}
                          </Display.If>
                          <Display.If condition={ !!printCalendarPeriod }>
                          {() => (
                            <PrintCalendar onClose={ closePrintCalendar} start={ printCalendarPeriod?.start } end={ printCalendarPeriod?.end }/>
                          )}
                          </Display.If>
                          { 
                            teamsAndAgents.map(data => (
                              <Team 
                                key={ data.team._id }
                                team={ data.team }
                                agents={ data.agents }
                                teamAssignments={ data.teamAssignments }
                                assignments={ data.assignments }
                                start={ start }
                                end={ end }
                              />
                            ))
                          }
                          <Acl.If resource="equipments" action="read">
                            <Display.If condition={ !acceptedTeams.length || acceptedTeams.includes("equipment") }>
                              <EquipmentsPlanning datas={ equipments } start={ start } end={ end } selectedEquipments={ acceptedEquipments } onEquipmentSelected={ setAcceptedEquipments } tenantId={currentTenant.currentId}/>
                            </Display.If>
                          </Acl.If>
                        </Scrollbar>
                      </div>
                    </div>
                  </div>
                );
              }}
              </Datas>
            </Highlight.Manager>
            {/* Tunnel depuis web-admin/src/containers/planning/team.js */}
            <AbsenceTunnel.Receiver>
            {({ agent, date, absence }, clear) => (
              <Display.If condition={ agent }>
                <Modal.Show close={ clear } style={{ width: "40vw" }}>
                {(close) => (
                  <AbsenceForm agent={ agent } absence={ absence } date={ date } close={ close } />
                )}
                </Modal.Show>
              </Display.If>
            )}
            </AbsenceTunnel.Receiver>
            {/* Tunnel depuis web-admin/src/containers/planning/team.js */}
            <AssignmentTunnel.Receiver>
            {({ date, agent, team }, clear) => (
                <Display.If condition={ date }>
                  <ConfirmAndCreateAssignment agent={ agent } team={ team } date={ date } clear={ clear } validate={ confirmScheduleNewAssignment } />
                </Display.If>
              )
            }
            </AssignmentTunnel.Receiver>
            { warningAgentAbsence }
            { warningTeamAbsence }
            { warningDeadline }
            { warningNotAWorkingDay }
            { warningEquipmentUnavailable }
          </div>
        )}
        </DragAndDrop.Manager>      
      </AssignmentTunnel.Manager>
    </AbsenceTunnel.Manager>
    <Route path="/issue/:id" component={ IssueDetail } />
  </>
  )
};

const ConnectedPlanning = Application.forward(["api", "currentTenant"], ["filter"], Planning);

const SearchablePlanning = (props) => {
  return (
    <Filter.Aggregator>
      <Filter.Subject>
      {composeQuery => (
        <ConnectedPlanning {...props} composeQuery={composeQuery}/>
      )}
      </Filter.Subject>
    </Filter.Aggregator>
  );
}
export default SearchablePlanning;

