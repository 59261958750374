import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import _ from 'lodash';

import Slot from '@uComponents/slot2';

import { TabProps, TabContentChildren, TabHeaderChildren, TabListProps, TabEvent, TabListControl } from './type';

export const Header = Slot<TabHeaderChildren>();
export const Content = Slot<TabContentChildren>();
export const Tab = Slot<TabContentChildren | TabHeaderChildren, TabProps>();

const createTabEvent = (currentShowedId: string | null, toShowId: string, control: TabListControl): TabEvent => {
  let isDefaultPrevented = false;
  return {
    currentShowedId,
    toShowId,
    isDefaultPrevented: () => isDefaultPrevented,
    preventDefault: () => {
      isDefaultPrevented = true;
    },
    control
  };
}

const TabList: FunctionComponent<PropsWithChildren<TabListProps>> = ({ children, onTabChange, add, setControl, Renderer }) => {
  const tabs  = Tab.props(children, true);

  const [displayIndex, setDisplayIndex] = React.useState(0);

  const tabListControl = React.useMemo(() => {
    const tabListControl = {
      tabsIds: tabs.map(t => t.id),
      activate: (id: string) => {
        const index = tabs.findIndex(t => t.id === id);
        if (index === -1) {
          throw new Error(`Tab with id ${id} not found`);
        }

        const e = createTabEvent(
          displayIndex < tabs.length ? tabs[displayIndex].id : null,
          id,
          tabListControl
        );

        if (onTabChange) {
          onTabChange(e);
        }

        tabs.forEach((tab, i) => {
          if(i === index) {
            if(tab.onShow) {
              tab.onShow?.(e, tabs[index].id);
            }
            if(tab.onDisplayChange) {
              tab.onDisplayChange?.(e, tabs[index].id);
            }
          }
          
          if(i === displayIndex) {
            if(tab.onHide) {
              tab.onHide?.(e, tabs[displayIndex].id);
            }
            if(tab.onDisplayChange) {
              tab.onDisplayChange?.(e, tabs[displayIndex].id);
            }
          }
        });

        if (e.isDefaultPrevented()) {
          return false;
        }

        setDisplayIndex(index);

        return true;
      },

      first: () => {
        if(!tabs.length){
          return false;
        }
        return tabListControl.activate(tabs[0].id);
      },

      next: () => {
        const nextIndex = displayIndex + 1;
        if (nextIndex >= tabs.length) {
          return false;
        }

        return tabListControl.activate(tabs[nextIndex].id);
      },

      previous: () => {
        const previousIndex = displayIndex - 1;
        if (previousIndex < 0) {
          return false;
        }

        return tabListControl.activate(tabs[previousIndex].id);
      },

      last: () => {
        if(!tabs.length){
          return false;
        }
        return tabListControl.activate(tabs[tabs.length - 1].id);
      }
    };

    if(setControl){
      setControl(tabListControl);
    }

    if(displayIndex >= tabs.length){
      tabListControl.last();
    }

    return tabListControl;
  }, [JSON.stringify(tabs.map(t => t.id)), setControl]);

  const tabsCalculatedIntermediary = React.useMemo(() => {
    return tabs.filter(tab => {
      return Header.get(tab.children) !== null && Content.get(tab.children) !== null;
    }).map((tab) => {
      const header = Header.get(tab.children) as TabHeaderChildren;
      const content = Content.get(tab.children) as TabContentChildren;
      return {
        id: tab.id,
        activate: () => tabListControl.activate(tab.id),
        header,
        content,
      }
    });
  }, [tabs]);

  const tabsCalculated = React.useMemo(() => {
    return tabsCalculatedIntermediary.map((tab, index) => {
      return {
        ...tab,
        active: index === displayIndex
      };
    });
  }, [tabsCalculatedIntermediary, displayIndex]);

  return <Renderer add={ add } tabs={tabsCalculated} tabListControl={tabListControl} />;
}

export const useTabControl = (ids: string[]) => {

  const context = React.useMemo(() => ({ selectLast: false, selectFirst: false, control: null }), []) as { 
    selectLast: boolean,
    selectFirst: boolean,
    select: string | null,
    control: TabListControl | null
  };

  const setControl = React.useCallback((control: TabListControl) => {
    if(!_.difference(ids, control.tabsIds).length){
      if(context.selectLast){
        control.last();
        context.selectLast = false;
      }else if(context.selectFirst){
        control.first();
        context.selectFirst = false;
      }else if(context.select){
        control.activate(context.select);
        context.select = null;
      }
    }

    context.control = control;
  }, [JSON.stringify(ids), context]);

  const deferSelectLast = React.useCallback(() => {
    context.selectLast = true;
  }, [context]);

  const deferSelectFirst = React.useCallback(() => {
    context.selectFirst = true;
  }, [context]);

  const deferSelect = React.useCallback((id: string) => {
    context.select = id;
  }, [context]);

  const selectFirst = React.useCallback(() => {
    if(context.control){
      context.control.first();
    }
  }, [context]);

  const selectLast = React.useCallback(() => {
    if(context.control){
      context.control.last();
    }
  }, [context]);

  const selectNext = React.useCallback(() => {
    if(context.control){
      context.control.next();
    }
  }, [context]);

  const selectPrevious = React.useCallback(() => {
    if(context.control){
      context.control.previous();
    }
  }, [context]);

  const select = React.useCallback((id: string) => {
    if(context.control){
      context.control.activate(id);
    }
  }, [context]);

  return [{ deferSelectLast, deferSelectFirst, deferSelect, selectFirst, selectLast, selectNext, selectPrevious, select }, setControl] as const;
}


export default TabList;