// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-areaTitle {
  padding: 5px;
  border-bottom: 2px solid #cecbc8;
  font-size: 1.2em;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./../common/components/areaTitle.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gCAAgC;EAChC,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".bs-areaTitle {\n  padding: 5px;\n  border-bottom: 2px solid #cecbc8;\n  font-size: 1.2em;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
