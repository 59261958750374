export default {
  "type": "FeatureCollection",
  "features": [
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.345202744007111,
              48.971210035461105
            ],
            [
              4.345275163650513,
              48.97108502529314
            ],
            [
              4.345344901084899,
              48.971113196625964
            ],
            [
              4.3454039096832275,
              48.97101107546877
            ],
            [
              4.345572888851166,
              48.97106037536388
            ],
            [
              4.345538020133972,
              48.97111143591812
            ],
            [
              4.345623850822449,
              48.97113256440806
            ],
            [
              4.345543384552002,
              48.971245249536366
            ],
            [
              4.345433413982391,
              48.97120827475669
            ],
            [
              4.345446825027466,
              48.97123468531641
            ],
            [
              4.345409274101257,
              48.9712505316455
            ],
            [
              4.345358312129974,
              48.97132272041433
            ],
            [
              4.345441460609436,
              48.97134560951433
            ],
            [
              4.3454065918922415,
              48.97138610558858
            ],
            [
              4.345674812793731,
              48.97146709763843
            ],
            [
              4.345709681510925,
              48.97142660162996
            ],
            [
              4.3457284569740295,
              48.97143716580925
            ],
            [
              4.345808923244476,
              48.971417798145474
            ],
            [
              4.34583306312561,
              48.97146709763843
            ],
            [
              4.345757961273193,
              48.97148646528302
            ],
            [
              4.345924258232117,
              48.97160267099262
            ],
            [
              4.345916211605072,
              48.971738243978145
            ],
            [
              4.345741868019104,
              48.971667816499206
            ],
            [
              4.345690906047821,
              48.97158682477543
            ],
            [
              4.345637261867523,
              48.971597388920785
            ],
            [
              4.345626533031464,
              48.97157978201063
            ],
            [
              4.345551431179046,
              48.971588585466485
            ],
            [
              4.345575571060181,
              48.97156569647803
            ],
            [
              4.345425367355347,
              48.97152520054966
            ],
            [
              4.345438778400421,
              48.971560414402276
            ],
            [
              4.3454039096832275,
              48.971569217861564
            ],
            [
              4.345352947711945,
              48.97162203858454
            ],
            [
              4.345192015171051,
              48.97158506408432
            ],
            [
              4.3452805280685425,
              48.97142660162996
            ],
            [
              4.34521347284317,
              48.971410755356814
            ],
            [
              4.3452832102775565,
              48.9712945491999
            ],
            [
              4.345320761203766,
              48.971313916911534
            ],
            [
              4.345352947711945,
              48.971280463586716
            ],
            [
              4.34533953666687,
              48.97127166007646
            ],
            [
              4.345358312129974,
              48.97124701023945
            ],
            [
              4.345202744007111,
              48.971210035461105
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.344736039638519,
              48.97201995289902
            ],
            [
              4.344797730445862,
              48.971806910674324
            ],
            [
              4.345047175884247,
              48.971845645691985
            ],
            [
              4.345041811466217,
              48.97187205591403
            ],
            [
              4.34510350227356,
              48.97188085931828
            ],
            [
              4.345052540302277,
              48.97205692707672
            ],
            [
              4.344736039638519,
              48.97201995289902
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.345331490039825,
              48.97206573044833
            ],
            [
              4.345358312129974,
              48.971967132597605
            ],
            [
              4.345425367355347,
              48.97199178207857
            ],
            [
              4.345457553863525,
              48.97191607291971
            ],
            [
              4.3456292152404785,
              48.97194952581803
            ],
            [
              4.345610439777374,
              48.9719812180168
            ],
            [
              4.345846474170685,
              48.972016431547345
            ],
            [
              4.345808923244476,
              48.97214496072276
            ],
            [
              4.345572888851166,
              48.972120311317546
            ],
            [
              4.345551431179046,
              48.97218017413766
            ],
            [
              4.345382452011108,
              48.9721572854208
            ],
            [
              4.3454039096832275,
              48.972078055165966
            ],
            [
              4.345331490039825,
              48.97206573044833
            ]
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "LineString",
        "coordinates": [
          [
            4.345202744007111,
            48.970840286169484
          ],
          [
            4.344843327999115,
            48.971417798145474
          ],
          [
            4.345033764839172,
            48.971625559964075
          ],
          [
            4.345301985740662,
            48.97173648329238
          ],
          [
            4.346152245998383,
            48.97187205591403
          ],
          [
            4.346490204334259,
            48.97194072242592
          ]
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Point",
        "coordinates": [
          4.344926476478577,
          48.97173120123472
        ]
      }
    },
    {
      "type": "Feature",
      "properties": {},
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              4.345961809158324,
              48.97189670544201
            ],
            [
              4.346138834953308,
              48.97189670544201
            ],
            [
              4.346138834953308,
              48.97199706410863
            ],
            [
              4.345961809158324,
              48.97199706410863
            ],
            [
              4.345961809158324,
              48.97189670544201
            ]
          ]
        ]
      }
    }
  ]
};