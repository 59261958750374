import React, { 
  useState,
  useEffect
}                     from "react";
import T              from "@cBehaviour/i18n";
import Filter         from "@cComponents/filter";
import Input          from "@cComponents/input";
import Collapsable    from "@cComponents/collapsable";
import Acl            from "@uBehaviour/acl";
import { CountryList as countries } from "@uTypes/business/Country";
import Application    from "@uBehaviour/application";
import { UserCollaboratorRoles, UserProEmailRoles, UserDiscriminators } from '@uTypes/business/User';

import './filters.css';


const roles             = Object.values(UserProEmailRoles).filter(role => !['starter'].includes(role));;
const collaboratorRoles = Object.values(UserCollaboratorRoles);
const discriminators    = Object.values(UserDiscriminators).filter(discriminator => !['citizen', "betterstreet"].includes(discriminator));

const buildFilterQueryDisabled = values => {
  return { $or: [
    { discriminator: { $ne: "pro" }, disabled: { $in: values }},
    { discriminator: "pro", "tenants.disabled": { $in: values }}
  ]};
}

export default Application.forward(["repository", "currentTenant", "api"], [], (props) => {
  const [skills, setSkills] = useState([]);
  useEffect(() => {
    if(props.currentTenant.isSelected()){
      props.api.service('users', 'skills').execute(props.currentTenant.currentId).then(res => {
        if (skills.length !== res.length){
          setSkills(res.map(v => ({
            _id: v
          })));
        }
      });
    }else{
      setSkills([]);
    }
  }, [props.currentTenant.currentId]);

  return (
    <>
      <Acl.If resource="users" action="manageCollaborators">
        <Collapsable alwaysOpen>
          <Collapsable.Title><T>users_filter_discriminator</T></Collapsable.Title>
          <Collapsable.Content>
            <Filter.Generic multiple name="discriminator" buildQuery={values => ({ "discriminator": { $in: values } })} stringify={value => (<><T>users_filter_discriminator</T>: <T>{"users_filter_discriminator_" + value}</T></>)}>
              {(values, add, drop, clear) => (
                <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
                  {
                    discriminators.map(discriminator => (<Input.Checkbox.Value key={discriminator} value={discriminator}><T>{"users_filter_discriminator_" + discriminator}</T></Input.Checkbox.Value>))
                  }
                </Input.Checkbox.BtnFilter>
              )}
            </Filter.Generic>
          </Collapsable.Content>
        </Collapsable>
        <Collapsable alwaysOpen>
          <Collapsable.Title><T>users_filter_collaborator_role</T></Collapsable.Title>
          <Collapsable.Content>
            <Filter.Generic multiple name="collaborator_roles" buildQuery={values => ({ "roles": { $in: values } })} stringify={value => (<><T>users_filter_discriminator</T>: <T>{"users_filter_collaborator_role_" + value}</T></>)}>
              {(values, add, drop, clear) => (
                <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
                  {
                    collaboratorRoles.map(role => (<Input.Checkbox.Value key={role} value={role}><T>{"users_filter_collaborator_role_" + role}</T></Input.Checkbox.Value>))
                  }
                </Input.Checkbox.BtnFilter>
              )}
            </Filter.Generic>
          </Collapsable.Content>
        </Collapsable>
        <Collapsable alwaysOpen>
          <Collapsable.Title><T>users_filter_country</T></Collapsable.Title>
          <Collapsable.Content>
            <Filter.Generic multiple name="geographicalAreas" buildQuery={values => ({ "geographicalAreas": { $in: values } })} stringify={country => <T>{"users_country_" + country}</T>}>
              {(values, add, drop, clear) => (
                <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
                {
                  ((props.user && (props.user.limitations?.geographicalAreas && props.user.limitations.geographicalAreas.length ? props.user.limitations.geographicalAreas : null)) || countries).map(c => (<Input.Checkbox.Value key={c} value={c}><T>{"tenant_country_" + c}</T></Input.Checkbox.Value>))
                }
                </Input.Checkbox.BtnFilter>
              )}
            </Filter.Generic>
          </Collapsable.Content>
        </Collapsable>
      </Acl.If>
      <Collapsable alwaysOpen>
        <Collapsable.Title><T >roles</T></Collapsable.Title>
        <Collapsable.Content>
          <Filter.Generic multiple name="roles" buildQuery={values => ({ "tenants.roles": { $in: values } })} stringify={value => (<><T>role</T>: <T>{`users_filter_pro_role_${value}`}</T></>)}>
            {(values, add, drop, clear) => (
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
                {
                  roles.map(role => (<Input.Checkbox.Value key={role} value={role}>
                    <T>{`users_filter_pro_role_${role}`}</T>
                  </Input.Checkbox.Value>))
                }
              </Input.Checkbox.BtnFilter>
            )}
          </Filter.Generic>
        </Collapsable.Content>
      </Collapsable>
      <Collapsable alwaysOpen>
        <Collapsable.Title><T>user_filter_skill</T></Collapsable.Title>
        <Collapsable.Content>
          <Filter.Generic multiple name="skills" buildQuery={values => ({skills:{$in:values.map(v => v._id)}})} stringify={value => { return (<><T>user_filter_skill</T> : {value._id}</>)}}>
            {(values, add, drop, clear) => {
              const filteredValues = skills.filter(skill => !values.includes(skill));
              return (
                <Input.Selectable 
                  source={ () => Promise.resolve(filteredValues) }
                  value= { values }
                  onChange={(vIds, values, isAdded) => isAdded ? add(values) : drop(values) }
                  textify={ val => val._id }
                >
                  {val => {
                    return <div className="bs-user-filters-skill-item">{val._id}</div>
                  }}
                </Input.Selectable>
              )
            }}
          </Filter.Generic>
        </Collapsable.Content>
      </Collapsable>
      <Collapsable alwaysOpen>
        <Collapsable.Title><T>active_m</T></Collapsable.Title>
        <Collapsable.Content>
          <Filter.Generic multiple name="disabled" buildQuery={ buildFilterQueryDisabled } stringify={value => (<T>{!value ? "active_m" : "inactive_m"}</T>)}>
            {(values, add, drop, clear) => (
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
                <Input.Checkbox.Value value={false}><T>yes</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value={true}><T>no</T></Input.Checkbox.Value>
              </Input.Checkbox.BtnFilter>
            )}
          </Filter.Generic>
        </Collapsable.Content>
      </Collapsable>
      <Collapsable alwaysOpen>
        <Collapsable.Title><T>tablet_access</T></Collapsable.Title>
        <Collapsable.Content>
          <Filter.Generic multiple name="tabletAllowed" buildQuery={values => ({ "tenants.tablet.allowed": { $in: values } })} stringify={value => (<T>{!value ? "without_tablet_access" : "tablet_access"}</T>)}>
            {(values, add, drop, clear) => (
              <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
                <Input.Checkbox.Value value={true}><T>yes</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value={false}><T>no</T></Input.Checkbox.Value>
              </Input.Checkbox.BtnFilter>
            )}
          </Filter.Generic>
        </Collapsable.Content>
      </Collapsable>
    </>
  );
});