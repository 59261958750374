import React, { Component } from 'react'

class Cut extends Component {
    render() {
        const { cut, more } = this.props
        let { string } = this.props
        if (string && cut && string.length > cut) {
            string = string.substring(0, cut)
            if (more)
                string += more
        }
        return (
            <span className={ this.props.className }>{string}</span>
        )
    }
}

export default Cut