import React            from "react";
import Display          from "@uComponents/displayIf";
import Slot             from "@uComponents/slot";
import ScrollBar        from '@cComponents/scrollBar';
import md5              from "md5";
import Text             from "./text";
import { escapeRegExp } from "@wLib/tool";
import _                from "lodash";
import FocusBlur        from "@cComponents/focusBlur";


export default class Select extends React.Component {
  static Display = Slot();
  static Value = Slot();
  constructor(props) {
    super(props);
    this._display              = React.createRef();
    this.switch                = this.switch.bind(this);
    this.open                  = this.open.bind(this);
    this.close                 = this.close.bind(this);
    this._onBlur               = this._onBlur.bind(this);
    this._onLabelClick         = this._onLabelClick.bind(this);
    this.state                 = {
      open: !!this.props.open,
      search: ""
    };
    if ((this.props.comparableValue && !this.props.postSelect) || (!this.props.comparableValue && this.props.postSelect)) {
      throw new Error("Specify both functions at the same time (comparableValue, postSelect)");
    }
  }

  get label() {
    const values = Select.Value.props(this, true);
    let selectedValue = null;
    if (this.props.value !== undefined) {
      const value = this.props.comparableValue
        ? this.props.comparableValue(this.props.value)
        : this.props.value;
      selectedValue = values.find(val => val.value === value);
    }
    if (!selectedValue) return "";
    return selectedValue.label;
  }
  get value(){
    const values = Select.Value.props(this, true);
    let selectedValue = null;
    if (this.props.value !== undefined) {
      const value = this.props.comparableValue
        ? this.props.comparableValue(this.props.value)
        : this.props.value;
      selectedValue = values.find(val => val.value === value);
    }
    if (!selectedValue) return null;
    return selectedValue.value;
  }
  _selectionChange(value) {
    if (this.props.postSelect) {
      value = this.props.postSelect(value.value, value.label);
    } else {
      value = value.value;
    }
    if (this.props.onChange) {
      this.props.onChange(value);
    }
    this.close();
  }
  isOpen() {
    return this.state.open;
  }
  open() {
    if (!this.isOpen()) {
      this.switch();
    }
  }
  close() {
    if (this.isOpen()) {
      this.switch();
    }
  }
  switch() {
    this.setState({ open: !this.state.open });
  }
  _onBlur() {
    if(this.isOpen()){
      this.close();
    }
  }
  _onLabelClick(ev){
    this.switch();
  }
  componentDidMount() {
    if (this.props.value && this.props.initialize) {
      this.props.initialize(this.props.comparableValue ? this.props.comparableValue(this.props.value) : this.props.value);
    }
  }
  render() {
    const display = Select.Display.get(this);
    let fnDisplay = null;

    if (display instanceof Function) {
      fnDisplay = display;
    } else if (!display) {
      fnDisplay = (value, open) => {
        return (
          <div className="bs-select-wa-display-default">
            <div>{ value && value.label ? value.label : (value && value.children ? value.children : "")}</div>
            <span className={ `fa fa-caret-${ open ? "up" : "down" }` }/>
          </div>
        );
      }
    } else {
      fnDisplay = (value, open) => {
        return React.cloneElement(display, { value: value ? value.label : "", open: open });
      }
    }
    const values = Select.Value.props(this, true);
    let selectedValue = null;
    if (this.props.value !== undefined) {
      const value = this.props.comparableValue
        ? this.props.comparableValue(this.props.value)
        : this.props.value;
      selectedValue = values.find(val => val.value === value);
    }
    const regexp = new RegExp(`.*${ escapeRegExp(this.state.search) }.*`, "i"); 
    const search = !this.props.searchable || !this.state.search
      ? () => true
      : v => regexp.test(v.children);
    const limit       = this.props.searchable ? 5 : 6;
    const scrollable  = (values.length > limit && selectedValue) || (values.length > (limit - 1) && ! selectedValue);
    return (
      <FocusBlur className="bs-select-wa" onBlur={ this._onBlur }>
        <div className="bs-select-wa-display" onClick={ this._onLabelClick }>{fnDisplay(selectedValue, this.isOpen())}</div>
        <Display.If condition={this.state.open}>
          <div className={ "bs-select-wa-values-container" + (scrollable ? " bs-select-wa-values-container-scrollable" : "" ) }>
            <Display.If condition={ this.props.searchable }>
              <div className="bs-select-wa-values-container-search">
                <Text value={ this.state.search } onChange={ v => { this.setState({ search: v}) }}>
                  Chercher...
                </Text>
                <div><span className="fa fa-search" /></div>
              </div>
            </Display.If>
            <div className="bs-select-wa-values-content">
              <ScrollBar>
              {
                values.filter(value => value.value !== this.props.value && search(value)).map(value => {
                  const key = md5(JSON.stringify(value.value));
                  return React.createElement("div", {
                    key: key,
                    className: "bs-select-wa-values-item",
                    onClick: () => this._selectionChange(value)
                  }, value.children ? value.children : value.label);
                }, [])
              }
              </ScrollBar>
            </div>
          </div>
        </Display.If>
      </FocusBlur>
    )
  }
}