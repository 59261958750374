import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import Application  from '@uBehaviour/application';
import { Key }      from "@uLib/model";
import Criterion    from '@uLib/filter';

import './localities.css';

export default Application.Service.forward(["api", "currentTenant"], ({ currentTenant, api }) => { 
  const [elements, setElements] = React.useState([]);
  const currentTenantCountry = currentTenant.current.country;
  
  React.useEffect(() => {
    let p = null;
    if (currentTenantCountry === "fr") {
      p = api.service("issues", "localities").execute(currentTenant.currentId);
    } else {
      p = api.service("issues", "zipCodes").execute(currentTenant.currentId);
    }
    p.then(results => setElements(results.filter((value) => !!value).map(v => ({ name: v }))));
  }, [currentTenantCountry, currentTenant.currentId]);

  const hydrate = React.useMemo(() => {
    const mapHydrate = elements.reduce((map, value) => { map.set(value.name, value); return map; }, new Map());
    return () => mapHydrate;
  }, [elements]);

  const deshydrate = React.useCallback((value) => value.name, []);

  const buildQuery = React.useMemo(() => {
    return currentTenantCountry === "fr"
      ? (values) => ({ "location.address.locality": { $in: values.map(v => v.name) }})
      : (values) => ({ "location.address.zip": { $in: values.map(v => v.name) } })
  }, [currentTenantCountry]);

  const stringify = React.useMemo(() => {
    return currentTenantCountry === "fr"
      ? (value) => (<><T>issue_filter_localities</T> : {value.name}</>)
      : (value) => (<><T>issue_filter_postal_codes</T> : {value.name}</>);
  }, [currentTenantCountry]);

  const title = React.useMemo(() => {
    return currentTenantCountry === 'fr'
      ? (<T>issue_filter_localities</T>)
      : (<T>issue_filter_postal_codes</T>);
  }, [currentTenantCountry]);

  const dataSource = React.useMemo(() => {
    return (query, sort, page, pageSize) => {
      let usedElements = elements;
      if(query){
        const filter = Criterion.factory(null, query);
        usedElements = usedElements.filter(data => filter.match(data));
      }
      if(sort){
        //TODO si besoin
      }
      return Promise.resolve(usedElements.slice(page * pageSize, (page + 1) * pageSize));
    };
  }, [elements]);

  const key = React.useMemo(() => new Key(["name"]), []);

  const filterQuery = React.useCallback((value) => ({ name:  { '$regex': value, '$options': 'i' }} ), []);

  const Item = React.useCallback((data) => (<div className="bs-filter-locality-line">{ data.name }</div>), []);

  return (
    <Filter.Generic 
      deshydrate={ deshydrate }
      hydrate={ hydrate }
      multiple
      name="localities"
      buildQuery={ buildQuery }
      stringify={ stringify }
    >
      {(values, add, drop, clear) => (
        <Collapsable>
          <Collapsable.Title>{ title }</Collapsable.Title>
          <Collapsable.Content>
            <Input.Selectable 
              value={ values }
              onChange={(vIds, values, isAdded) => isAdded ? add(values) : drop(values) }
              source={ dataSource }
              pageSize={ elements.length }
              objectKey={ key }
              textify={ val => val.name }
              filterQuery={ filterQuery }
            >
              { Item }
            </Input.Selectable>
          </Collapsable.Content>
        </Collapsable>
      )}
    </Filter.Generic>
  );
});