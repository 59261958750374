import { fork, put, takeEvery, call, delay } from 'redux-saga/effects'
import cookies from "client/lib/cookie"

import {
  IsAuthRequest,
  LoginRequest,
  LogoutRequest,
  MagicLoginRequest,
  CurrentUserRequest,
  PasswordRequest,
  ResetPasswordRequest,
  addUserConfirmation,
  ADD_USER_CONFIRMATION,
  removeUserConfirmation
} from 'client/features/session/actions'
import RequestSagas from 'client/core/RequestSagas'
import URLS from 'constants/URLS'
import application from '@root/app.services'

const tokenName = application.getService("session").tokenName;

/*isAuth: check active session.*/
const AuthSagas = new RequestSagas(IsAuthRequest.get, { method: 'GET', endpoint: URLS.session.isAuth })
export function* isomorphicAuth(req) {
  if (req.currentUser) yield put(IsAuthRequest.get.actions.success({ user: req.currentUser }))
  else yield put(IsAuthRequest.get.actions.failure({ error: 'session_expired_or_nonexistent', code: 401, method: 'isomorphic' }))
}

/*Login*/
const LoginSagas = new RequestSagas(LoginRequest.post, { method: 'POST', endpoint: '/authweb' })
LoginSagas.success = function* (action) {
  cookies.set(tokenName, action.payload.session[tokenName], {
    path: "/",
    domain: window.location.hostname
  });
  window.location.href = action.payload && action.payload.redirect ? action.payload.redirect : URLS.home.index
}
LoginSagas.failure = function* (action) {
}

/*Request new password*/
const RequestPasswordSagas = new RequestSagas(PasswordRequest.post, { method: 'POST', endpoint: URLS.session.requestPassword })

/*Reset new password*/
const ResetPasswordSagas = new RequestSagas(ResetPasswordRequest.post, { method: 'POST', endpoint: URLS.session.updatePassword })
ResetPasswordSagas.success = function* (action) {
  cookies.set(tokenName, action.payload[tokenName], {
    path: "/",
    domain: window.location.hostname
  });
  window.location.href = URLS.home.show
}
/*Logout*/
const LogoutSagas = new RequestSagas(LogoutRequest.get, { method: 'GET', endpoint: URLS.session.logout })
LogoutSagas.start = function* () {
  cookies.remove(tokenName)
  cookies.remove(tokenName, {
    path: "/",
    domain: window.location.hostname
  });
  cookies.remove('tenant')
  cookies.remove("tenant", {
    path: "/",
    domain: window.location.hostname
  });
}

/*Magic Login*/
const MagicLoginSagas = new RequestSagas(MagicLoginRequest.post, { method: 'POST', endpoint: URLS.session.magicLogin })
MagicLoginSagas.success = function* () {
}

/*Update current user*/
const UpdateCurrentUserSagas = new RequestSagas(CurrentUserRequest.put, { method: 'PUT', endpoint: URLS.session.updateCurrentUser })
UpdateCurrentUserSagas.success = function* (action) {

}


/*Confirmations*/
const manageConfirmation = function * (action) {
  if (action.payload.action === 'request')
    return
  const time = 3000
  yield delay(time)
  yield put(removeUserConfirmation(action.payload))
}

const catchConfirmation = function * (action) {
  yield takeEvery(ADD_USER_CONFIRMATION, manageConfirmation)
}

const manageSuccessConfirmation = function * (action) {
  if (action.payload.live)
    return
  const confirmation = {
    action: 'success',
    date: new Date().getTime(),
    type: action.payload.type
  }
  yield put(addUserConfirmation(confirmation))
}

const manageRequestConfirmation = function * (action) {
  const confirmation = {
    action: 'request',
    type: action.payload.type
  }
  yield put(addUserConfirmation(confirmation))
}

export const updateUserConfirmationsSaga = function * () {
  yield takeEvery(CurrentUserRequest.put.TYPES.SUCCESS, manageSuccessConfirmation)
}

export const issuesRequestConfirmationsSaga = function * () {
  yield takeEvery(CurrentUserRequest.put.ACTION, manageRequestConfirmation)
}

/*Export watchers sagas*/
const sagas = [
  fork(AuthSagas.saga),
  fork(MagicLoginSagas.saga),
  fork(LoginSagas.saga),
  fork(LogoutSagas.saga),
  fork(UpdateCurrentUserSagas.saga),
  fork(RequestPasswordSagas.saga),
  fork(ResetPasswordSagas.saga),
  fork(updateUserConfirmationsSaga),
  fork(issuesRequestConfirmationsSaga),
  fork(catchConfirmation)
]


export default sagas
