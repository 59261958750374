// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-state-compact {
  color: white;
  padding: 8px 7px;
  border-radius: 5px;
  background-color: var(--state-compact-color);
  line-height: normal;
  width: min-content;
}`, "",{"version":3,"sources":["webpack://./../common/features/state/compact.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,4CAA4C;EAC5C,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".bs-state-compact {\n  color: white;\n  padding: 8px 7px;\n  border-radius: 5px;\n  background-color: var(--state-compact-color);\n  line-height: normal;\n  width: min-content;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
