import React        from 'react';
import Address      from '@cFeatures/address';
import File         from '@cComponents/file';
import T            from '@cBehaviour/i18n';
import Item         from '@cComponents/item';
import Layout       from '@cComponents/layout';
import Title        from '@cComponents/title';

import './item.css';

const Places = ({ places }) => (
  <div>
    <span>
      <T>link_buildings</T>
      <span>&nbsp;:&nbsp;</span>
    </span>    
    <span>
    { 
      places.filter(place => !place.disabled).map((place,idx) => (
        <span key={place._id}>
          { idx > 0 ? ", " : ""}
          { place.name }
        </span>
      ))
    }
    </span>
  </div>
)

const BuildingItem = ({ data: { files, name, places, location }}) => (
  <Item type="compact" className="bs-building-item">
    <Item.Illustration>
        <File file={ files && files.length > 0 ? files[0] : null } width={100} height={100} fit="cover" />
    </Item.Illustration>
    <Item.Content>                
        <Layout.Standart className="bs-building-content" stretch>
          <Layout.Standart.Header>
            <Title>{name}</Title>
          </Layout.Standart.Header>
          <Layout.Standart.Content className="bs-building-description">
          {
            places.length && places.some(p=>!p.disabled)
            ? (<Places places={ places } />)
            : null
          }
          </Layout.Standart.Content>
          <Layout.Standart.Footer className="bs-action">
              <Address location={ location } />
          </Layout.Standart.Footer>
      </Layout.Standart>
    </Item.Content>
  </Item>
);

BuildingItem.Load = {
  files: 1
};
BuildingItem.load = BuildingItem.Load;


export default BuildingItem;