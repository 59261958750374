// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-notifications-list {
  height: 50vh;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  overflow: hidden;
}

.bs-notifications-list-header{
  flex: 0 0 auto;
  border-bottom: 1px solid #cecbc8;
  display: flex;
  align-items: center;
}

.bs-notifications-list-header-title {
  font-weight: bold;
  font-size: 16px;
  padding: 6px 12px;
  flex-grow: 1;
}

.bs-notifications-list-header-readAll {
  color: #2980b9;
  font-size: 12px;
  padding: 6px 12px;
}

.bs-notifications-list-header-readAll:hover {
  cursor: pointer;
  text-decoration: underline;
}

.bs-notifications-list-items{
  flex-grow: 1;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/entities/notifications/list.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,gCAAgC;EAChC,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,eAAe;EACf,iBAAiB;EACjB,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".bs-notifications-list {\n  height: 50vh;\n  display: flex;\n  flex-direction: column;\n  background-color: #FFFFFF;\n  overflow: hidden;\n}\n\n.bs-notifications-list-header{\n  flex: 0 0 auto;\n  border-bottom: 1px solid #cecbc8;\n  display: flex;\n  align-items: center;\n}\n\n.bs-notifications-list-header-title {\n  font-weight: bold;\n  font-size: 16px;\n  padding: 6px 12px;\n  flex-grow: 1;\n}\n\n.bs-notifications-list-header-readAll {\n  color: #2980b9;\n  font-size: 12px;\n  padding: 6px 12px;\n}\n\n.bs-notifications-list-header-readAll:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}\n\n.bs-notifications-list-items{\n  flex-grow: 1;\n  overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
