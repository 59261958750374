import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import Display        from '@uComponents/displayIf';      
import InputPeriod    from "@cComponents/input/period";
import moment         from "moment";

const buildQueryPeriod = (value) => {
  let period;
  
  switch(value.type){
    case "today":
      period = { $gte: moment().startOf("day").toISOString(), $lt: moment().startOf("day").add(1, "day").toISOString() };
      break;
    case "tomorrow":
      period = { $gte: moment().startOf("day").add(1, "day").toISOString(), $lt: moment().startOf("day").add(2, "day").toISOString() };
      break;
    case "last_7_days":
      period = { $gte: moment().startOf("day").subtract(8, "day").toISOString(), $lt: moment().startOf("day").toISOString() };
      break;
    case "last_30_days":
      period = { $gte: moment().startOf("day").subtract(30, "day").toISOString(), $lt: moment().startOf("day").toISOString() };
      break;
    case "next_7_days":
      period = { $gte: moment().startOf("day").add(1, "day").toISOString(), $lt: moment().startOf("day").add(8, "day").toISOString() };
      break;
    case "custom_date":
      if(value.start && value.end){
        period = { $gte: moment(value.start).toISOString(), $lt: moment(value.end).toISOString() };
      }else{
        period = null;
      }
      break;
  }
  return period;
}

const stringifyPeriod = (value) => {
  if(value.type !== "custom_date"){
    return (<T>{`issue_filter_${value.type}`}</T>)
  }else{
    if(value.start && value.end){
      return (<T bind={{ start: moment(value.start).format("DD/MM/YYYY"), end: moment(value.end).subtract(1, "day").format("DD/MM/YYYY")}}>statistics_custom_date_stringify</T>)
    }else{
      return (<T>statistics_custom_date</T>)
    }
  }
}

const CreatedAt = () => {
  return (
    <Filter.Generic
      deshydrate={({ type, start, end }) => ({ type, start: start ? moment(start).toISOString(): null, end: end ? moment(end).toISOString() : null }) }
      hydrate={ 
        values => values.reduce((map, value) => { 
          map.set(value, { type: value.type, start: value.start ? moment(value.start).toDate() : null, end: value.end ? moment(value.end).toDate() : null });
          return map
        }, new Map())
      }
      name="createdAt"
      buildQuery={value => ({createdAt: buildQueryPeriod(value)})}
      stringify={value => (<><T>issue_filter_created_at_created</T> : { stringifyPeriod(value) }</>)}
    >
      {(value, set, clear) => (
        <Collapsable>
          <Collapsable.Title><T>issue_filter_created_at</T></Collapsable.Title>
          <Collapsable.Content>
            <Input.Radio.BtnFilter onChange={(value) => { set({ type: value }); }} value={value && value.type}>
              <Input.Radio.Value value={"today"}><T>issue_filter_today</T></Input.Radio.Value>
              <Input.Radio.Value value={"last_7_days"}><T>issue_filter_last_7_days</T></Input.Radio.Value>
              <Input.Radio.Value value={"last_30_days"}><T>issue_filter_last_30_days</T></Input.Radio.Value>
              <Input.Radio.Value value={"custom_date"}><T>issue_filter_custom_date</T></Input.Radio.Value>
            </Input.Radio.BtnFilter>
            <Display.If condition={ value && value.type === "custom_date"}>
              <div>
                <InputPeriod
                  allDay
                  start={value && value.start }
                  end={value && value.end }
                  onChange={ (start, end) =>  set({ type: "custom_date", start, end }) }
                >
                <InputPeriod.StartLabel>
                  <T>issue_filter_createdAt_input_period_start_label</T>
                </InputPeriod.StartLabel>
                <InputPeriod.EndLabel>
                  <T>issue_filter_createdAt_input_period_end_label</T>
                </InputPeriod.EndLabel>
                </InputPeriod>
              </div>
            </Display.If>
          </Collapsable.Content>
        </Collapsable>
      )}
    </Filter.Generic>
  )
}

export default CreatedAt;