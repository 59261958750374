// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-dropdown{
  position: relative;
}

.bs-dropdown>.bs-content {
  position: absolute;
  z-index: 1;
  top: 100%;
}
.bs-dropdown>.bs-content.bs-content-maxWith {
  min-width: 100%;
  width: max-content;
}
.bs-dropdown>.bs-content.bs-right {
  right: 0px;
}
.bs-dropdown>.bs-content.bs-left {
  left: 0px;
}
.bs-dropdown>.bs-content.bs-open {
  
}
.bs-dropdown>.bs-content.bs-close {
  display: none;
}

.bs-button-dropdown {
  font-size: 0.9rem;
  color: #999;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding: 0 24px;
  height: 100%;
}

.bs-button-dropdown .bs-listable {
  background-color: white;
}

.bs-button-dropdown .bs-listable .bs-listable-item:hover {
  background-color: rgb(248, 248, 248);
}

.bs-button-dropdown>.bs-menu {
  height: 100%;
  display: flex;
  align-items: center;
}
.bs-button-dropdown:hover {
  cursor: pointer;
  background-color: rgb(240, 240, 240);
}`, "",{"version":3,"sources":["webpack://./../common/components/dropDown.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;AACX;AACA;EACE,eAAe;EACf,kBAAkB;AACpB;AACA;EACE,UAAU;AACZ;AACA;EACE,SAAS;AACX;AACA;;AAEA;AACA;EACE,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,WAAW;EACX,sBAAsB;EACtB,uBAAuB;EACvB,kBAAkB;EAClB,eAAe;EACf,YAAY;AACd;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,eAAe;EACf,oCAAoC;AACtC","sourcesContent":[".bs-dropdown{\n  position: relative;\n}\n\n.bs-dropdown>.bs-content {\n  position: absolute;\n  z-index: 1;\n  top: 100%;\n}\n.bs-dropdown>.bs-content.bs-content-maxWith {\n  min-width: 100%;\n  width: max-content;\n}\n.bs-dropdown>.bs-content.bs-right {\n  right: 0px;\n}\n.bs-dropdown>.bs-content.bs-left {\n  left: 0px;\n}\n.bs-dropdown>.bs-content.bs-open {\n  \n}\n.bs-dropdown>.bs-content.bs-close {\n  display: none;\n}\n\n.bs-button-dropdown {\n  font-size: 0.9rem;\n  color: #999;\n  flex-direction: column;\n  justify-content: center;\n  position: relative;\n  padding: 0 24px;\n  height: 100%;\n}\n\n.bs-button-dropdown .bs-listable {\n  background-color: white;\n}\n\n.bs-button-dropdown .bs-listable .bs-listable-item:hover {\n  background-color: rgb(248, 248, 248);\n}\n\n.bs-button-dropdown>.bs-menu {\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n.bs-button-dropdown:hover {\n  cursor: pointer;\n  background-color: rgb(240, 240, 240);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
