import React from 'react'
import T from 'i18n-react'
import Fontawesome from 'react-fontawesome'

const IssueStateBannerNoLabelSVG = ({ state, label, micro, workOrderState }) => (
    <svg className={ `c-issue-state c-issue-state--${state.value}` } width={ (micro) ? '32px' : '64px' } height={ micro ? '4px' : '8px' } viewBox='0 0 64 8'>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <polygon className='c-issue-state__rectangle' fill='#D8D8D8' points='0 0 70 0 70 8 8 8'></polygon>
            {
                state.value !== 'resolved' && workOrderState && workOrderState.value === 'finished' &&
                    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' x='75%' y='-2' viewBox='0 0 24 24' fill='none' stroke='#ffffff' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'><polyline points='20 6 9 17 4 12'></polyline></svg>
            }
            {
                state.value !== 'resolved' && workOrderState && workOrderState.value === 'ongoing' &&
                    <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' x='75%' y='-2' viewBox='0 0 24 24' fill='none' stroke='#ffffff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'><circle cx='12' cy='12' r='1'></circle><circle cx='19' cy='12' r='1'></circle><circle cx='5' cy='12' r='1'></circle></svg>
            }
        </g>
    </svg>
)

const IssueStateBannerLabelSVG = ({ state, label, big, caret, workOrderState, disabled }) => (
    <svg className={ `c-issue-state c-issue-state--${state.value}` } width={ big ? '150px' : '120px' } height={ big ? '28px' : '23px' } viewBox='0 0 115 23' version='1.1'>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
            <g id='state'>
                <polygon className='c-issue-state__rectangle' id='Shape' fill='#D8D8D8' fillRule='nonzero' points='0 0 120 0 120 23 15 23'></polygon>
                {
                    state.value !== 'resolved' && workOrderState && workOrderState.value === 'finished' &&
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='18' x='18' y='3' viewBox='0 0 24 24' fill='none' stroke='#ffffff' strokeWidth='3' strokeLinecap='round' strokeLinejoin='round'><polyline points='20 6 9 17 4 12'></polyline></svg>
                }
                {
                    state.value !== 'resolved' && workOrderState && workOrderState.value === 'ongoing' &&
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='18' x='18' y='3' viewBox='0 0 24 24' fill='none' stroke='#ffffff' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'><circle cx='12' cy='12' r='1'></circle><circle cx='19' cy='12' r='1'></circle><circle cx='5' cy='12' r='1'></circle></svg>
                }
                <text id='Open' fontFamily='Open Sans, Helvetica' x='88%' y='15' textAnchor='end' fontSize='12' fill='#FFFFFF'>
                    { state.label }
                </text>
                {caret && !disabled && <text className='caret' fontSize='7' x='105' y='14' fill='#ffffff'>&#9660;</text>}
            </g>
        </g>
    </svg>
)



const IssueStateBannerSVG = ({ state, label, big, micro, caret = true, workOrderState, disabled }) => {
    if (label){
        return <IssueStateBannerLabelSVG state={ state } big caret={ caret } workOrderState={ workOrderState } disabled={ disabled }/>
    }
    return <IssueStateBannerNoLabelSVG state={ state } micro={ micro } workOrderState={ workOrderState } disabled={ disabled }/>
}

export default IssueStateBannerSVG
