import React from 'react'
import T from 'i18n-react'
import moment from 'moment'
import PartialHTML from 'client/features/common/components/PartialHTML.jsx'

const IssueListDates = ({ issue, user, noLastAction, highlight }) => (
  <div className='c-issue-dates'>
    <span>
      { T.translate('created_at') }&nbsp;
      { moment(issue.created).fromNow() }&nbsp;
      { T.translate('by').toLowerCase() }&nbsp;
    </span>

    <PartialHTML
      content={ issue.requestor.labelShort }
      forceMatch={ `${issue.requestor.firstName || ''} ${issue.requestor.lastName || ''}` }
      highlight={ highlight } noUrl='true' inline='true'
    />

    <br />
    {
      issue.lastUpdater && issue.lastUpdater.firstName && issue.lastUpdater.firstName[0] ?
        <span className='last-update'>

          {
            (user && (!noLastAction && issue.lastAction && T.translate(issue.lastAction) && !user.buildingProfile && !user.publicSpaceProfile)) &&
            <span>
              { T.translate(issue.lastAction)[0].toUpperCase() }{ T.translate(issue.lastAction).substr(1).toLowerCase() }&nbsp;
            </span>
          }
          <span>
            { issue.lastUpdated ? moment(issue.lastUpdated).fromNow() : null }&nbsp;
          </span>
          <span>
            { T.translate('by').toLowerCase() }&nbsp;
          </span>

          <span className='user'>
            { issue.lastUpdater.labelShort }
          </span>

        </span>
        : null
    }
  </div>
)

export default IssueListDates