import StringBuilder from "@uLib/stringBuilder";
import moment from "moment";

export const getPlanningClassname = (date, className, currentTenant) => {
  if(!currentTenant){
    currentTenant = className;
    className = null;
  }
  return StringBuilder.create(" ")
            .add(className, !!className)
            .add("bs-planning-header-today", date.format("YYYYMMDD") === moment().format("YYYYMMDD"))
            .add("bs-planning-header-dayNotWorked", currentTenant.isNotAWorkingDay(date))
            .build();
}