// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-addableList-container {
  position: relative;
  margin-bottom: 5px;
  padding: 5px;
  border: 1px solid #DDD;
  border-radius: 4px;
  box-shadow: 0px 1px 8px 0px #c4c4c4;
}

.bs-addableList-container-delete {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 15px;
  width: 15px;
  transform: translate(50%, -50%);
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.9);
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 1px 8px 0px black;
}

.bs-addableList-container-delete:hover {
  cursor: pointer;
}


.bs-addableList-add {
  width: 100%;
  border: 1px solid grey;
  border-radius: var(--std-radius);
  display: flex;
  justify-content: center;
  padding: var(--std-padding);
}

.bs-addableList-add:hover {
  cursor: pointer;
}

.bs-addableList-add-disabled {
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./../common/components/addableList.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;EAClB,mCAAmC;AACrC;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,YAAY;EACZ,WAAW;EACX,+BAA+B;EAC/B,kBAAkB;EAClB,0CAA0C;EAC1C,eAAe;EACf,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,iCAAiC;AACnC;;AAEA;EACE,eAAe;AACjB;;;AAGA;EACE,WAAW;EACX,sBAAsB;EACtB,gCAAgC;EAChC,aAAa;EACb,uBAAuB;EACvB,2BAA2B;AAC7B;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".bs-addableList-container {\n  position: relative;\n  margin-bottom: 5px;\n  padding: 5px;\n  border: 1px solid #DDD;\n  border-radius: 4px;\n  box-shadow: 0px 1px 8px 0px #c4c4c4;\n}\n\n.bs-addableList-container-delete {\n  position: absolute;\n  top: 0px;\n  right: 0px;\n  height: 15px;\n  width: 15px;\n  transform: translate(50%, -50%);\n  border-radius: 50%;\n  background-color: rgba(255, 255, 255, 0.9);\n  font-size: 12px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  box-shadow: 0px 1px 8px 0px black;\n}\n\n.bs-addableList-container-delete:hover {\n  cursor: pointer;\n}\n\n\n.bs-addableList-add {\n  width: 100%;\n  border: 1px solid grey;\n  border-radius: var(--std-radius);\n  display: flex;\n  justify-content: center;\n  padding: var(--std-padding);\n}\n\n.bs-addableList-add:hover {\n  cursor: pointer;\n}\n\n.bs-addableList-add-disabled {\n  opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
