import React  from "react";
import _      from "lodash";
import Display from "@uComponents/displayIf";
import Scrollbar from "@cComponents/scrollBar";
import FocusBlur from "@cComponents/focusBlur";
import moment     from "moment";



const regExHour = /((2[0-3])|([0-1][0-9])):([0-5][0-9])/;
const keyRegEx  = /[0-9]/;

const h24s30 = function*(){
  for(let i = 0; i < 24; ++i){
    for(let j = 0; j < 2; ++j){
      let ret = "";
      if(i < 10) ret += "0";
      ret += (i + ":");
      if(j === 0) ret += "00";
      else ret += "30";
      yield ret;
    }
  }
}



class Hour extends React.Component{
  constructor(props){
    super(props);
    this.state                = { cursor: 0, displaySelect: false };
    this._input               = React.createRef();
    this._onKeyDown           = this._onKeyDown.bind(this);
    this._onChange            = this._onChange.bind(this);
    this._listenerCloseSelect = this._listenerCloseSelect.bind(this);
    this.switchSelectDisplay  = this.switchSelectDisplay.bind(this);
    this.componentDidUpdate   = _.debounce(this.componentDidUpdate.bind(this), 0);
  }
  get value(){
    return this._input.current.value;
  }
  _onKeyDown(ev){
    let cursor = ev.currentTarget.selectionStart;

    if(ev.key.length === 1 && !(keyRegEx.test(ev.key) && cursor < 5)){
      ev.preventDefault();
      return;
    }
    if(ev.key.length > 1 && ["Backspace", "Delete"].indexOf(ev.key) === -1){
      return;
    }

    let value = ev.currentTarget.value;
    let key = ev.key;
    if(key === "Backspace"){
      if(cursor === 0){
        return;
      }
      key = "0";
      cursor -= 1;
    } else if(key ===  "Delete"){
      key = "0";
    } else if(cursor === 0 && key === "2" && parseInt(value.charAt(1)) > 3){
      key = "20";
    }

    if(cursor === 2){
      ++cursor;
    }
    value = value.substring(0, cursor) + key + value.substring(cursor + key.length);

    if(ev.key !== "Backspace"){
      ++cursor;
      if(cursor === 2){
        ++cursor;
      }
    } else if(cursor === 3){
      --cursor;
    }
    
    if(regExHour.test(value)){
      this._onChange(value);
      this.setState({ cursor })
    }else{
      ev.preventDefault();
      return;
    }
  }
  _listenerCloseSelect(){
    this.closeSelect();
  }
  componentDidUpdate(){
    if(this._input.current){
      this._input.current.setSelectionRange(this.state.cursor, this.state.cursor);
    }
  }
  _onChange(value){
    if(regExHour.test(value) && this.props.onChange){
      this.props.onChange(value);
    }
  }
  isSelectOpened(){
    return this.state.displaySelect;
  }
  openSelect(){
    if(!this.isSelectOpened()){
      this.switchSelectDisplay();
    }
  }
  closeSelect(){
    if(this.isSelectOpened()){
      this.switchSelectDisplay();
    }
  }
  switchSelectDisplay(){
    this.setState({ displaySelect: !this.state.displaySelect });
  }
  render(){
    let { value = "00:00" } = this.props;
    if(value instanceof Date){
      value = moment(value).format("HH:mm");
    }
    return (
      <FocusBlur onBlur={ this._listenerCloseSelect }>
        <div className="bs-input-wa-hour">
          <div>
            <div>
              <input ref={ this._input } type="text" onKeyDown={ this._onKeyDown } value={ value }/>
              <span className="fa fa-caret-down" onClick={ (ev) => { this.switchSelectDisplay(); }}/>
            </div>
            <Display.If condition={ this.isSelectOpened() }>
              <div>
                <Scrollbar>
                {
                  Array.from(h24s30()).map(v => (
                    <div className="bs-input-wa-hour-select-value" key={ v } onClick={() => { this._onChange(v); this.closeSelect(); }}><div>{ v }</div></div>
                  ))
                }
                </Scrollbar>
              </div>
            </Display.If>
          </div>
        </div>
      </FocusBlur>
    );
  }
};

export default Hour;