const style = {
  control: {
    height: "100px"
  },
  highlighter: {
    // display: "none"
  },
  input: {
    backgroundColor: 'white',
    display: 'block',
    width: '100%',
    fontSize: '1em',
    fontWeight: 400,
    lineHeight: 1.5,
    height: 'inherit',
    resize: 'none',
    padding: '8px 6px',
    // position: "relative",
  },
  suggestions: {
    list: {
      backgroundColor: 'white',
      border: '1px solid #cecbc8',
      fontSize: 12,
      borderRadius: '3px',
      minWidth: '130px'
    },
    item: {
      padding: '6px 8px',
      //borderBottom: '1px solid rgba(0,0,0,0.2)',
      '&focused': {
        backgroundColor: 'rgb(236, 236, 236)',
      },
      color: '#6b7378'
    }
  }
}

export default style