import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import enhanceWithClickOutside from 'react-click-outside'
import Confirmation from 'client/features/common/components/Confirmation.jsx'
const __CLIENT = window.__CLIENT;

class DropDown extends Component {
    constructor(props){
        super(props)
        this.state = {
            open: false
        }
        this._toggleState = this._toggleState.bind(this)
        this.close = this.close.bind(this)
        this.listener = null
    }

    handleClickOutside() {
        this.setState({ open: false })
    }

    _toggleState(e){
        e.preventDefault()
        e.stopPropagation()
        this.setState({
            open: !this.state.open
        })
    }

    close() {
        this.setState({
            open: false
        })
    }

    render() {
        if (!__CLIENT)
            return <div></div>

        const { confirmationSize, arrow, overlay, className } = this.props

        return (
            <div className={ classNames('o-dropdown', className, { opened: this.state.open, 'o-dropdown--large': this.props.large }) }>
                { 
                    this.state.open && overlay &&
                        <div className='o-dropdown__overlay' onClick={ this.close }/>
                }
                { this.state.open && arrow && <span className='c-dropdown-menu__arrow u-position-absolute' style={ { bottom: -12, left: 20, zIndex: 100 } }/> }
                <Confirmation data={ this.props.confirmation } size={ confirmationSize }/>
                <div className={ classNames('o-dropdown__toggle', this.props.toggleClassName, { 'u-bg-page-bg': this.state.open }) } onClick={ this._toggleState }>
                    { this.props.renderToggle(this.state.open) }
                </div>
                <span className={ classNames('o-dropdown__body', this.props.bodyClassName, { 'u-margin-top-small': arrow }) } onScroll={ this.props.onScroll }>
                    { this.props.renderBody(this.close) }
                </span>
            </div>
        )
    }
}

DropDown.propTypes = {
    //renderToggle: PropTypes.func.isRequired,
    renderBody: PropTypes.func.isRequired,
    bodyClassName: PropTypes.string,
    toggleClassName: PropTypes.string,
    className: PropTypes.string
}

export default enhanceWithClickOutside(DropDown)
