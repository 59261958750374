import React, { CSSProperties, FunctionComponent } from 'react';
import HSForm from '@common/components/HSForm';
import Modal from '@common/components/modal';
import Scrollbar from '@common/components/scrollBar';

import './hsForm.css';

type HsFormProps = {
  goToParentPath: () => void;
  params: {
    id: string;
    width?: string;
  }
};
const HsForm: FunctionComponent<HsFormProps> = ({ goToParentPath, params: { id, width = "800"}}) => (
  <div className="bs-unauthenticated-hsForm-container">
    <Modal.Show close={ goToParentPath } style={{ width: width && `${ width }px`, maxHeight: "80vh", overflow: "hidden" }}>
    {(close) => (
      <Scrollbar>
        <div className="bs-unauthenticated-hsForm-content">
          <HSForm id={ id } onFormSubmit={ close }/>
        </div>
      </Scrollbar>
    )}
    </Modal.Show>
  </div>
);

export default HsForm;