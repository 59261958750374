// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-favatar {
    display: flex;
    flex-direction: row;
}

.bs-favatar-illustration {
    border-radius: 50%;
    overflow: hidden;
    background-color: #7c7c7c;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.bs-favatar-illusatrion-text {
    font-weight: bold;
    color: white;
    line-height: 1;
}`, "",{"version":3,"sources":["webpack://./../common/features/user/avatar.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,cAAc;AAClB","sourcesContent":[".bs-favatar {\n    display: flex;\n    flex-direction: row;\n}\n\n.bs-favatar-illustration {\n    border-radius: 50%;\n    overflow: hidden;\n    background-color: #7c7c7c;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.bs-favatar-illusatrion-text {\n    font-weight: bold;\n    color: white;\n    line-height: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
