import React from 'react'
import T from 'i18n-react'
import IssueSmallListItem from 'client/features/issues/components/lists/SmallItem.jsx'

const IssueFormNears = ({ displayNearIssue = () => null, goToIssue, nears, title }) => (
    <div className='u-margin-top'>
        <T.p text={ title } className='c-heading-medium' />
        <div>
            {
                nears.map((issue) => 
                    <IssueSmallListItem
                        issue={ issue }
                        key={ issue._id }
                        goToIssue={ goToIssue }
                        onMouseEnter={ displayNearIssue }
                        onMouseLeave={ displayNearIssue }
                        nearFull
                        showDistance
                        lg={ true }
                        className='u-margin-right-none u-margin-left-none'
                    />
                )
            }
        </div>
    </div>
)

export default IssueFormNears
                            