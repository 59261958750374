import React from 'react';
import Application from '@uBehaviour/application';
import RessourceManager, { File } from './ressourceManager';
import Window from '@cComponents/window';
import Data   from "@uBehaviour/data";

import './background.css';

class Background extends React.Component {
  constructor(props) {
    super(props);
    this._ressourceManager = React.createRef();
    this._internal         = React.createRef();
  }
  componentDidMount() {
    this._ressourceManager.current.ready();
  }
  _onRessourceLoaded = () => {
    this._internal.current.ready();
  }
  render(){
    return (
      <RessourceManager ref={ this._ressourceManager } onLoaded={ this._onRessourceLoaded}>
        <div className="bs-background">
          <div className="bs-background-header">
            { this.props.file ? <File file={ this.props.file } width={200} height={200} fit="contains"/> : null }
          </div>
          <div className="bs-background-content">
            <Window.Import ref={ this._internal }>
            { this.props.children }
            </Window.Import>
          </div>
        </div>
      </RessourceManager>
    );
  }
}

export default Application.Service.forward(["currentTenant"], ({ currentTenant, children }) => (
  <Data.Query model="File" query={{ _id: currentTenant.current.informations.pictures.logo }}>
  {(file) => (
    <Background file={file.length ? file[0] : null }>{ children }</Background>
  )}
  </Data.Query>
));