import React from 'react';
import Background from './background';
import Window from '@cComponents/window';

import './statistics.css';

const Statistics = ({}) => (
  <Background>
    <div className="bs-print-statistic">
      <Window.Import.Part className="bs-print-statistic-title" name="title"/>
      <Window.Import.Part className="bs-print-statistic-content" name="graphic"/>
      <Window.Import.Part className="bs-print-statistic-filter" name="filters"/>
    </div>
  </Background>
);

export default Statistics;