// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-print-statistic{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px;
}
.bs-print-statistic-filter{
  margin-top: 20px;;
}`, "",{"version":3,"sources":["webpack://./src/print/statistics.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".bs-print-statistic{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 40px;\n}\n.bs-print-statistic-filter{\n  margin-top: 20px;;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
