import React        from 'react';
import Data         from "@uBehaviour/data";
import T            from "@cBehaviour/i18n";    
import Formater     from "@cBusiness/format";
import Display      from '@cComponents/displayIf';
import Item         from '@cComponents/old/item';
import Layout       from '@cComponents/layout';
import Title        from '@cComponents/title';

import './item.css';

export default (props) => {
    const team = props.data;
    return (
        <Item className="bs-team-item" type="compact">
            <Item.Content>
                <Layout.Standart className="bs-team-content">
                    <Layout.Standart.Header className="bs-team-title">
                        <Title>{team.name}</Title>
                    </Layout.Standart.Header>
                    <Layout.Standart.Content className="bs-team-description">
                        <div>
                            <span><T>type</T> : </span>
                            <span>{team.type === "internal" ? <T>internal</T> : <T>external</T> }</span>
                        </div>
                    </Layout.Standart.Content>
                    <Layout.Standart.Footer className="bs-action">
                        <Display.If condition={team.members.length}>
                            <div>
                                <span><T>members</T> : </span>
                                <span>
                                    { team.members.map((user, idx) => React.createElement(
                                        Data.Synchronizer, {
                                            entity: user,
                                            key: user.id
                                        }, (user) => (idx !== 0 ? ", " : "") + Formater.User.longFullname(user))                                
                                    )}
                                </span>
                            </div>               
                        </Display.If>
                    </Layout.Standart.Footer>
     
                   
                </Layout.Standart>
            </Item.Content>
        </Item>
    );
}