import Standart from './standart';
import Short from './short';
import Tiny from './tiny';
import createSelect, { SelectProps, List } from './select';
import Compact from './compact';

export type SelectPropsType = SelectProps;

export default {
  Compact: createSelect(Compact),
  List,
  Standart,
  Short,
  Tiny,
  Select: createSelect(Standart)
};