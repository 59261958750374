import Manager from "./manager";
import Filters from "./filters";
import Form   from "./form";
import Item   from "./item";

export default {
  Filters,
  Form,
  Item,
  Manager
};