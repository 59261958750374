const REGEXP = {
  validations: {
    email: /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})+$/,
    userName: /^[a-zA-Z '"_\-.\u00E0-\u00FC]{2,}$/,
    nickname: /^[a-zA-Z0-9_\-./]{5,}$/,
    password: /^[a-zA-Z0-9]{6,}$/,
    url: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
  },
  path: {
    params: /\/:([a-z0-9]*)/gi
  },
  hashtag: /#([a-z0-9áàâäãåçéèêëíìîïñóòôöõúùûüýÿæœÁÀÂÄÃÅÇÉÈÊËÍÌÎÏÑÓÒÔÖÕÚÙÛÜÝŸÆŒ_-]*)/igm,
  mongoId: /^[0-9a-fA-F]{24}$/
}

module.exports = Object.freeze(REGEXP)