import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import _ from 'lodash'
import T from 'i18n-react'

import SelectField from 'client/features/form/old/components/fields/Select.jsx'
import ContactModal from 'client/features/issues/components/modals/Contact.jsx'

class IssueDetailsOwner extends PureComponent {
  constructor(props) {
    super()
    this.onChange = this.onChange.bind(this)
    this.displayModal = this.displayModal.bind(this)
    this.submit = this.submit.bind(this)
    this.state = {
      owner: props.owner,
      showModal: false
    }
  }

  onChange(owner) {
    if (!owner || (this.props.owner && owner._id === this.props.owner._id)) return
    this.setState({
      owner,
      showModal: true
    })
    this.props.change('owner', owner)
    //this.props.dispatch(IssueOwnerChange(owner._id))
  }

  displayModal() {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  submit() {
    this.setState({
      showModal: false
    })
    this.props.submit({
      test: '1'
    })
  }

  render() {
    const { owner, options, template, confirmation, disabled, user } = this.props

    return (
      <div className='c-field-owner'>
        <SelectField
          value={
            owner && { ...owner, value: owner._id, label: `${owner.firstName} ${owner.lastName}` }
          }
          label='owner'
          columns
          onChange={ this.onChange }
          options={
            _.sortBy(options.map((option) => ({ ...option, label: `${option.firstName} ${option.lastName}`, value: option._id })), 'label')
          }
          confirmation={ confirmation }
          classNames={ {
            containerLeft: 'o-layout__item u-1of3',
            containerRight: 'o-layout__item u-2of3'
          } }
          disabled={ disabled }
        />
        {
          this.state.showModal &&
          <form>
            <ContactModal
              show={ this.state.showModal }
              onHide={ this.displayModal }
              title='update_owner'
              template={ template }
              submit={ this.submit }
              fallback={ this.state.owner && this.state.owner._id === user._id && T.translate('change_owner_to_me') }
              to={
                this.state.owner ?
                  `${this.state.owner.firstName} ${this.state.owner.lastName} ${ this.state.owner.email ? `(${this.state.owner.email})` : '' }` : ''
              }
            />
          </form>
        }
      </div>
    )
  }
}

export default IssueDetailsOwner = reduxForm({
  form: 'issueOwner'
})(IssueDetailsOwner)