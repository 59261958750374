
const mergeString = function(){
    return [...arguments].reduce((cns, cn) => cns + (cn && cn.length ? (cns.length ? " " : "") + cn : ""), "");
};

const truncLongString = function(string, size) {
    return string.length > size 
        ? string.substring(0, size) + '...'
        : string;
}

export default {
    mergeString,
    truncLongString
};