import React from 'react'

const OverlayViewsTitle = ({ title, className = '', right, small, smallBreak = false }) => (
  <h5 className={ `c-issue-detail-title o-flex-layout o-flex-layout--middle ${className}` }>
    <div className='o-flex-layout__item o-flex-layout__item--fill'>
      { title }
      {
        small && smallBreak && <br />
      }
      {
        small && <small style={ { fontSize: 10 } }> { small }</small>
      }
    </div>
    {
      right && (
        <div className='o-flex-layout__item'>
          { right }
        </div>
      )
    }
  </h5>
)

export default OverlayViewsTitle