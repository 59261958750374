import React  from 'react';
import T      from '@uBehaviour/i18n';

import './item.css';

const PriorityItem = ({ type }) => (
  <span>
    <span className={`fa fa-warning bs-priority-${type}`}/>
    <span className='bs-priority-item-label'><T>{`priority_item_${type}`}</T></span>
  </span>
);

export default PriorityItem;
