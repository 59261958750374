import React              from "react";
import { ResponsiveBar } from '@nivo/bar';
const NewIssueInTimeBar = (props) => {
  return (
    <ResponsiveBar
      data={props.datas}
      margin={{ top: 25, right: 0, bottom: 55, left: 35 }}
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      axisTop={null}
      axisRight={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      colors={{ datum: 'data.color' }}
      tooltip={props => (
        <div style={{ backgroundColor: "#FFFFFF", padding: "4px", borderRadius: "4px", border: "1px solid rgba(0, 0, 0, 0.1)"}}>
          {props.data.label}: <strong>{props.value}</strong>
        </div>
      )}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 90,
      }}
    />
  );
}

export default NewIssueInTimeBar;
