const setEnums = (CONSTANT) => {
  for (const key in CONSTANT) {
    const items = CONSTANT[key].items
    if (items) {
      CONSTANT[key].enum = items.filter(item => item.value).map(item => item.value)
    }
  }
}
const ISSUE = {
  query: {
    defaultLimit: 18
  },
  types: {
    items: [
      { value: 'publicSpace', icon: 'map-marker' },
      { value: 'building', icon: 'building' },
      { value: 'patrimony', icon: 'wrench' }
    ]
  },
  requestorTypes: ['citizen', 'internal', 'userPro', 'bsCitizen'],
  priorities: {
    items: [
      { value: 'a_low' },
      { value: 'j_middle', icon: 'warning', color: '#ffa500' },
      { value: 'z_high', icon: 'warning', color: '#ff0000' }
    ]
  },
  states: {
    items: [
      { value: 'open', color: '#f77448' },
      { value: 'in_progress', color: '#6b98b5' },
      { value: 'planned', color: '#5dc9e6' },
      { value: 'resolved', color: '#89cc1f' },
      { value: 'transferred', color: '#e78fa5' },
      { value: 'deleted', color: '#a09696' },
      { value: 'rejected', color: '#aaaa5a' }
    ],
    default: 'open'
  },
  activeStates: {
    items: [
      { value: 'open', color: '#f77448' },
      { value: 'in_progress', color: '#6b98b5' },
      { value: 'planned', color: '#5dc9e6' },
    ]
  },
  workOrderStates: {
    items: [
      { value: 'to_do', color: '#f77448' },
      { value: 'ongoing', color: '#396988' },
      { value: 'finished', color: '#1fcc36' }
    ],
    default: 'to_do'
  },
  defaultQuery: {
    archived: { $ne: true }
  },
  updateTypes: [
    'state',
    'tag'
  ],
  tags: {
    publicSpace: ['tree', 'dripping', 'waste', 'deposit', 'lighting', 'grafitti', 'flood', 'furniture', 'pothole', 'pollution', 'water' , 'road', 'signs', 'playground', 'suggestion', 'congratulation'],
    building: ['heating', 'electricity', 'festivity', 'external_infrastructure', 'furnitures', 'painting', 'plumbing', 'doors', 'roof', 'miscellaneous'],
    patrimony: ['repair', 'maintenance', 'miscellaneous']
  },
  files: {
    accepted: 'image/gif,image/jpeg,image/png,image/jpg,application/pdf,image/JPG,image/JPEG'
  },
  recurrenceUpdates: [
    'tag',
    'files',
    'building',
    'address',
    'description'
  ]
}

setEnums(ISSUE)

module.exports = Object.freeze(ISSUE)