import React, { FunctionComponent }      from 'react';
import Location   from '@common/lib/location';
import LocationType from '@uTypes/business/Location';
import { Highlighted } from '@cComponents/highlight';


import './address.css';
import 'font-awesome/css/font-awesome.min.css';


interface AddressProps {
  withIcon?: boolean;
  location: LocationType;
  "data-testid"?: string;
}
const Address: FunctionComponent<AddressProps> = ({ withIcon = true, location, "data-testid": dataTestId }) => (
  <span className='bs-address' data-testid={ dataTestId }>
    { withIcon ? <span className="fa fa-map-marker" /> : null}
    <Highlighted>{ Location.getLabelFromAddress(location.address) }</Highlighted>
  </span>
);

export default Address;

