import React          from 'react';
import FieldGroup     from '@components/fieldGroup';
import Input          from '@cComponents/old/input';
import Form           from '@cBehaviour/form';
import FInput         from '@components/input';
import T              from '@cBehaviour/i18n';
import Button         from '@cComponents/button';
import Field          from '@cComponents/old/field';
import FormError      from "@cComponents/formError";

import './form.css';

class ContactForm extends React.Component {

  constructor(props){
    super(props);
    this._inputArray = React.createRef();
  }

  _preload(val) {
    val.disabled = !val.disabled;
    val.emails = val.emails.map(email => {return{value:email}});
    return val;
  }

  _presubmit(val) {
    val.disabled = !val.disabled;
    val.emails = val.emails.map(email => email.value);
    return val;
  }

  render() {
    return (
      <Form.Model 
        id={ this.props.id }
        model={ "Contact" }
        preload={this._preload}
        presubmit={this._presubmit}
        ref={ this.props.forwardedRef }
        observer={ this.props.observer } 
      >
        {(contact, errors) => {
          return (
            <FormError errors={ errors }>
              <div className="bs-form-contact">
                <FieldGroup type="full" title="generals_informations">
                  <Field>
                    <Field.Label><T>name</T></Field.Label>
                    <Field.Input><Input.Text required={true} name="name" /></Field.Input>
                  </Field>
                  <Field>
                    <Field.Label><T>description</T></Field.Label>
                    <Field.Input><Input.Textarea name="description" /></Field.Input>
                  </Field>
                  <Field>
                    <Field.Label><T>active_m</T></Field.Label>
                    <Field.Input><FInput.YesNo name="disabled"/></Field.Input>
                  </Field>
                </FieldGroup>     
                <FieldGroup type="full" required={true} title="emails" subTitle="emails_fieldGroup_requirements" >
                  <table className="bs-contactEmails-table">
                    <tbody>
                      <Input.Array.Simple ref={ this._inputArray } name="emails">
                        <Input.Array.Simple.Display>
                          {(email, idx) => (
                              <tr key={ idx }>
                                <td>{ email.value }</td>
                                <td><Button.Icon faClass="pencil" color="green" onClick={ () => this._inputArray.current.edit(idx) } /></td>
                                <td><Button.Icon faClass="close" color="red" onClick={ () => this._inputArray.current.delete(idx) } /></td>
                              </tr>
                            )}
                        </Input.Array.Simple.Display>
                        <Input.Array.Simple.Form>
                          {(object, fnSubmit, fnCancel) => (
                            <tr>
                              <td><Input.Email name="value" /></td>
                              <td><Button.Icon faClass="floppy-o" color="green" onClick={ fnSubmit } /></td>
                              <td><Button.Icon faClass="ban" color="red" onClick={ fnCancel } /></td>
                            </tr>
                          )}
                        </Input.Array.Simple.Form>
                      </Input.Array.Simple>
                    </tbody>
                  </table>
                  <Button.Text onClick={ () => this._inputArray.current.add() } ><T>add</T></Button.Text>
                </FieldGroup>
              </div>
            </FormError>
          )}
        }
      </Form.Model>
    )
  }
}
export default React.forwardRef((props, ref) => {
  return (<ContactForm { ...props } forwardedRef={ ref }/>)
})