import React, { FunctionComponent, MouseEventHandler } from 'react';
import SelectBase from '@cComponents/select';
import Equipment from '@universal/types/business/Equipment';
import usePager from '@universal/behaviour/data/hooks/usePager';
import useCurrentTenant from '@universal/behaviour/hooks/useCurrentTenant';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import useKey from '@universal/behaviour/data/hooks/useKey';
import T from '@universal/behaviour/i18n';
import Query from '@uTypes/technic/Query';

import './select.css';

interface ItemProps {
  equipment: BusinessEntity<Equipment>;
  toggleSelection: (equipment: BusinessEntity<Equipment>) => void;
  selected: boolean;
}
const Item: FunctionComponent<ItemProps> = ({ equipment, toggleSelection, selected}) => {

  const stopEventPropagationAndToggleSelection = React.useCallback<MouseEventHandler<HTMLDivElement>>((e) => {
    e.stopPropagation();
    toggleSelection(equipment);
  }, [toggleSelection, equipment]);

  const className = React.useMemo(() => `bs-select-equipmentItem${ selected ? " bs-select-equipmentItem-selected" : ""}`, [selected]);

  return (
    <div className={ className } onClick={ stopEventPropagationAndToggleSelection }>
      <span aria-hidden="true" className="fa fa-wrench fa-2x"/>
      <div>{ equipment.name }</div>
    </div>
  );
};

const Select = SelectBase<BusinessEntity<Equipment>>();

interface SelectEquipmentProps {
  onChange: (selected: BusinessEntity<Equipment>[]) => void;
  selected: BusinessEntity<Equipment>[];
  query?: null | Query<Equipment>;
  stopSelection: () => void;
}

const SelectEquipment: FunctionComponent<SelectEquipmentProps> = ({ onChange, selected, stopSelection, query = null }) => {
  const currentTenant = useCurrentTenant();
  const key = useKey("Equipment");

  if(!query){
    query = { tenant: currentTenant.currentId, disabled: false };
  }

  const { datas: equipments } = usePager<Equipment>({ model: "Equipment", query, sort: { name: 1 }, loadAll: true });

  const getFilteredData = React.useCallback((equipment: Equipment) => equipment.name, []);

  return (
    <Select datas={ equipments } getFilteredData={ getFilteredData } onChange={ onChange } selected={ selected } dataKey={ key } stopSelection={ stopSelection }>
      <Select.Title>
        <T>equipments_select_title</T>
      </Select.Title>
      <Select.Item>
      {(equipment, selected, toggleSelection) => (
        <Item equipment={ equipment } toggleSelection={ toggleSelection } selected={ selected }/>
      )}
      </Select.Item>
    </Select>
  )
}


export default SelectEquipment;