// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-nbrCategory-container{
  font-size: 14px;
  display: flex;
  gap: 2px;
  padding: 2px;
  background-color: rgba(0,0,0, 0.1);
  color: #666;
}
.bs-nbrCategory{
  display: flex;
  background-color: rgba(0,0,0, 0.05);
  padding: 2px;
  align-items: center;
}
.bs-nbrCategory-selected{
  background-color: #E74B22;
  color: #FFF;
}
.bs-nbrCategory:hover{ 
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/views/settings/category/list.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,QAAQ;EACR,YAAY;EACZ,kCAAkC;EAClC,WAAW;AACb;AACA;EACE,aAAa;EACb,mCAAmC;EACnC,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,yBAAyB;EACzB,WAAW;AACb;AACA;EACE,eAAe;AACjB","sourcesContent":[".bs-nbrCategory-container{\n  font-size: 14px;\n  display: flex;\n  gap: 2px;\n  padding: 2px;\n  background-color: rgba(0,0,0, 0.1);\n  color: #666;\n}\n.bs-nbrCategory{\n  display: flex;\n  background-color: rgba(0,0,0, 0.05);\n  padding: 2px;\n  align-items: center;\n}\n.bs-nbrCategory-selected{\n  background-color: #E74B22;\n  color: #FFF;\n}\n.bs-nbrCategory:hover{ \n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
