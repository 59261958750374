// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-location {
  position: relative;
  min-height:180px;
}

.bs-location .bs-location-address {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 0;
  left: 0;
  background-color: rgba(255,255,255, 0.8);
  width: 100%;
}


/*.bs-location .gmnoprint {
  display: none;
}

.bs-location .gm-style-cc {
  display: none;
} */`, "",{"version":3,"sources":["webpack://./src/components/location.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,MAAM;EACN,OAAO;EACP,wCAAwC;EACxC,WAAW;AACb;;;AAGA;;;;;;GAMG","sourcesContent":[".bs-location {\n  position: relative;\n  min-height:180px;\n}\n\n.bs-location .bs-location-address {\n  position: absolute;\n  display: flex;\n  justify-content: flex-end;\n  top: 0;\n  left: 0;\n  background-color: rgba(255,255,255, 0.8);\n  width: 100%;\n}\n\n\n/*.bs-location .gmnoprint {\n  display: none;\n}\n\n.bs-location .gm-style-cc {\n  display: none;\n} */"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
