import React        from 'react';
import T            from '@cBehaviour/i18n';
import Item         from '@cComponents/item';
import Title        from '@cComponents/title';
import MultiLines   from "@cComponents/multilines";
import Display      from '@uComponents/displayIf';
import Actions      from "./actions";
import moment       from "moment";

import './item.css';

const ReleaseItem = (props) => (
  <Item className="bs-item-standard bs-release-item">
    <Item.Illustration>
      <span className="fa fa-gitlab" />
    </Item.Illustration>
    <Item.Content.Header>
      <Title><T bind={{ date: moment(props.data.releaseDate).format("DD/MM/YYYY")}}>release_publish_at</T></Title>
      <Display.If condition={ props.data.openToPublic }>
        <Display.Then>
          <div className="bs-release-state bs-release-published"><T>release_published</T></div>
        </Display.Then>
        <Display.Else>
          <div className="bs-release-state bs-release-not-published"><T>release_not_published</T></div>
        </Display.Else>
      </Display.If>
    </Item.Content.Header>
    <Item.Content.Body>
      <MultiLines>{ props.data.description }</MultiLines>
    </Item.Content.Body>
    <Item.Content.Footer>
      <div>
        <span><T>release_newFeatures_numbers</T>&nbsp;:&nbsp;</span>
        <span>{ props.data.newFeatures.length }, </span>
      </div>
      <div>
        <span><T>release_bugFixed_numbers</T>&nbsp;:&nbsp;</span>
        <span>{ props.data.bugsFixed.length }</span>
      </div>
    </Item.Content.Footer>
    <Item.Meta>

    </Item.Meta>
    <Item.Actions>
      <Actions release={ props.data }/>
    </Item.Actions>
  </Item>
);

export default ReleaseItem;