import React from "react";


import data from "./testPrintData";


let Leaflet = null;
class TestPrint extends React.Component {
  constructor(props){
    super(props);
    Leaflet = window.L;
    this._container = React.createRef();
  }
  componentDidMount(){
    this._map = Leaflet.map(this._container.current, { 
      zoomControl: false,
      maxZoom: 21
    });
    const geoJSONLayer = Leaflet.geoJSON().addTo(this._map);
    Leaflet.geoJSON(data).addTo(geoJSONLayer);
    const geoJSONBounds = geoJSONLayer.getBounds();
    
    this._map.fitBounds(geoJSONBounds);
    const mapBounds = this._map.getBounds();
    const googleUrl = this.getUrl(mapBounds.getCenter(), this._map.getZoom(), this._map.getSize());
    
    Leaflet.imageOverlay(googleUrl, mapBounds).on('load', () => window.print()).addTo(this._map);

  }
  getUrl(center, zoom, size){
    return `https://maps.googleapis.com/maps/api/staticmap?center=${center.lat + "," + center.lng}&zoom=${zoom}&size=${size.x + "x" + size.y}&key=${window.google.apiKey}`;
  }
  render(){
    return (
      <div ref={ this._container } style={{ height: "640px", width: "640px"}}></div>
    );
  }
};

export default TestPrint;