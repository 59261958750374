import React from "react";

class Typeform extends React.Component {
  constructor(props){
    super(props);
    this._container = React.createRef();
  }
  componentDidMount(){
    const { id } = this.props;
    const p = new Promise((resolve, reject) => {
      this._script = document.createElement("script");
      this._script.src = "//embed.typeform.com/next/embed.js";
      this._script.onload = () => resolve(window.tf);
      this._script.onerror = reject;
      document.head.appendChild(this._script);
    });
    p.then(tf => {
      tf.createWidget(id, {
        container: this._container.current,
        onReady: () => {
          const div = this._container.current.querySelector("div");
          div.style.height = "100%";
          div.style.width = "100%";
          const iframe = div.querySelector("iframe");
          iframe.style.height = "100%";
          iframe.style.width = "100%";
        },
        onSubmit: ({ responseId }) => {
          this.props.onSubmit(responseId);
        }
      });
    });
  }
  componentWillUnmount(){
    document.head.removeChild(this._script);
  }
  render(){
    return (
      <div ref={this._container} style={{ height: "100%" }}/>
    )
  }
}

export default Typeform;