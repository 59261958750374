import { UserRequest } from 'client/features/users/actions'
import { registerDefaultReducer, registerInitialstate } from "client/lib/reduxSaga";

const initialState = {
  all: []
}

const initializeState = () => Object.assign({}, initialState)


registerInitialstate("users", initializeState);
registerDefaultReducer("users", function users(state = initializeState(), action = {}) {
  switch (action.type) {


  default:
    return state
  }
});