// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-starter-body{
  display: flex;
  flex-direction: column-reverse;
  background: #f2eeeb;
  color: #222222;
  flex-grow: 1;
  overflow: hidden;
  height: 100%;
}
.bs-starter-body>.bs-starter-content{
  flex-grow: 1;
  overflow: hidden;
}
`, "",{"version":3,"sources":["webpack://./src/applications/starter/body.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":[".bs-starter-body{\n  display: flex;\n  flex-direction: column-reverse;\n  background: #f2eeeb;\n  color: #222222;\n  flex-grow: 1;\n  overflow: hidden;\n  height: 100%;\n}\n.bs-starter-body>.bs-starter-content{\n  flex-grow: 1;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
