import React from "react";

import "./percentage.css";

const Percentage = (props) => {
  const total = props.datas.reduce((total, item) => total + item.value, 0);
  return (
    <div className="bs-statistics-percentage">
    {
      props.datas.map(item => {
        return (
          <div  className="bs-statistics-percentage-item">
            <div className={"fa " + item.icon} />
            <div  className="bs-statistics-percentage-item-value">{ total ? Math.round(item.value / total * 100) : 0 }%</div>
          </div>
        );
      })
    }
    </div>
  );
}

export default Percentage;