import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import toDic        from "@cLib/toDic";
import Query        from '@uLib/query';
import Application  from '@uBehaviour/application';

import './tag.css';

export default Application.Service.forward(["api", "currentTenant"], ({ api, currentTenant }) => (
  <Filter.Generic
    deshydrate={value => value.name}
    hydrate={values => toDic(values, v => v, v => ({ _id: v, name: v }))} 
    multiple
    name="tags"
    buildQuery={values => ({ "tags": { $in: values.map(v => v.name) } })}
    stringify={value => { return (<><T>issue_filter_tags</T> : {value.name}</>)}}
  >
    {(values, add, drop, clear) => {
      let query = {};
      if(values.length){
        query = { name: { $nin: values.map(v => v.name) }};
      }
      return (
        <Collapsable>
          <Collapsable.Title><T>issue_filter_tags</T></Collapsable.Title>
          <Collapsable.Content>
            <Input.Selectable 
              value={ values }
              onChange={(vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }}
              source={ (query, sort, offset, pageSize) => api.service("issues", "tags").execute(Query.joinWithOptimizer(query, { tenant: currentTenant.currentId }), sort, offset, pageSize) }
              query={ query }
              sort={{ name: 1 }}
              pageSize={ 25 }
              textify={ tag => tag.name }
              filterQuery={ value => ({ name: { '$regex': value, '$options': 'i' } }) }
            >
              {data => (<div className="bs-filter-tag-line">{data.name}</div>)}
            </Input.Selectable>
          </Collapsable.Content>
        </Collapsable>
      )
    }}
  </Filter.Generic>
));