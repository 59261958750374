import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import toDic        from "@cLib/toDic";
import useService   from '@uBehaviour/hooks/useService';
import { strFirstCharUpperCase } from '@uLib/tool';

import './type.css';

const types = [{
  _id: "publicSpace",
  query: { equipment: null, "location.building": null }, 
  label: "issue_filter_type_publicSpace",
  spanClass: "fa fa-map-marker",
}, {
  _id: "building",
  query: { "location.building": { $ne: null }, "equipment": null }, 
  label: "issue_filter_type_building",
  spanClass: "fa fa-building-o"
}, {
  _id: "equipment",
  query: { equipment: { $ne: null }}, 
  label: "issue_filter_type_equipment",
  spanClass: "fa fa-wrench"
}];

export default () => {
  const acl = useService("acl");
  const usedTypes = types.filter(type => acl.connectedUserIsAllow("issues", `read${strFirstCharUpperCase(type._id)}`));
  if(usedTypes.length < 2){
    return null;
  }
  return (
    <Filter.Generic
      deshydrate={value => value._id}
      hydrate={values => toDic(values, v => v, (v) => types.find(t => t._id === v))}
      multiple
      name="types"
      buildQuery={values => ({ $or: values.map(v => v.query) })}
      stringify={value => (<T>{value.label}</T>)}
    >
      {(values, add, drop, clear) => (
        <Collapsable alwaysOpen>
          <Collapsable.Title><T>issue_filter_type</T></Collapsable.Title>
          <Collapsable.Content>
            <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
              {
                usedTypes.map(type => (
                  <Input.Checkbox.Value key={ type.label } value={ type }>
                    <span><span className={ "bs-filter-icon " + type.spanClass } /><T>{ type.label }</T></span>
                  </Input.Checkbox.Value>
                ))
              }
            </Input.Checkbox.BtnFilter>
          </Collapsable.Content>
        </Collapsable>
      )}
    </Filter.Generic>
);
}