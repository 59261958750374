import React from "react";
import { Component } from "react";

class StatedImplementation extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      value: props.value
    };
    this._lastProps = new Date();
    this._lastState = new Date();
    this._onChange  = this._onChange.bind(this);
  }
  _onChange(value){
    this.setState({ value: value });
    this._lastState = new Date();
    if(this.props.onChange){
      this.props.onChange(value);
    }
  }
  isSame(v1, v2){
    return this.props.isEquals ? this.props.isEquals(v1, v2) : v1 === v2;
  }
  shouldComponentUpdate(props){
    if(!this.isSame(props.value, this.props.value)){
      this._lastProps = new Date();
    }
    return true;
  }
  render(){
    const props = Object.assign({}, this.props, {
      value: this._lastState > this._lastProps ? this.state.value : this.props.value,
      onChange: this._onChange
    });
    return React.cloneElement(this.props.children, props);
  }
}

const Stated = (Component, equalsHandler) => (props) => {
  return React.createElement(StatedImplementation, Object.assign({}, props, { isEquals: equalsHandler }), React.createElement(Component, props));
}

const NullableImplementation = (props) => {
  return React.createElement("div", { className: "bs-input-wa-nullable" }, 
    React.cloneElement(props.children, props),
    React.createElement("span", { className: "fa fa-times", onClick: () => props.onChange ? props.onChange(null) : null })
  );
}
const Nullable = Component => props => {
  return React.createElement(NullableImplementation, props, React.createElement(Component, props));
}

export default {
  Nullable,
  Stated
};