// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-form-tenant-inputs {
  background: white;
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.bs-form-tenant-pictures{
  display: flex;
  justify-content: space-between;
}
.bs-form-tenant-pictures .bs-form-layout-label{
  text-align: center;
}

.bs-form-tenant-adminIssuesPublicByDefault-subLabel {
  font-size: 12px;
  font-style: italic;
}

.bs-form-tenant-information-readonly {
  color: var(--std-color-text);
  line-height: 1;
}

.bs-tenants-form-postalCode {
  display: flex;
  align-items: center;
  gap: 5px;
}`, "",{"version":3,"sources":["webpack://./src/entities/tenant/form.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,eAAe;EACf,aAAa;EACb,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,4BAA4B;EAC5B,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV","sourcesContent":[".bs-form-tenant-inputs {\n  background: white;\n  padding: 0 10px;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-between;\n}\n\n.bs-form-tenant-pictures{\n  display: flex;\n  justify-content: space-between;\n}\n.bs-form-tenant-pictures .bs-form-layout-label{\n  text-align: center;\n}\n\n.bs-form-tenant-adminIssuesPublicByDefault-subLabel {\n  font-size: 12px;\n  font-style: italic;\n}\n\n.bs-form-tenant-information-readonly {\n  color: var(--std-color-text);\n  line-height: 1;\n}\n\n.bs-tenants-form-postalCode {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
