import React    from 'react';
import ReactDOM from 'react-dom'

const open = (src, title, mode, onReady) => {
  const _win = window.open(src, title, mode);
  const _readyHandler = () => {
    onReady(_win.bsElements, _win);
  };
  _win.addEventListener('bsReady', () => {
      _win.document.title = title;
    _readyHandler()
  });
  if(_win.bsReady){
    _readyHandler();
  }
}
class Export extends React.Component {
  componentDidMount() {
    open(this.props.src, this.props.title, this.props.mode, this._ready);
  }
  _ready = (element, window) => {
    ReactDOM.createPortal(this.props.children, element);
  }
  render(){
    return null;
  }
}
const ImportContext = React.createContext();

class Import extends React.Component {
  constructor(props){
    super(props);
    this._parts = {};
  }
  addPart(name, ref){
    this._parts[name] = ref;
  }
  ready() {
    window.ready = true;
    window.bsElements = Object.keys(this._parts).reduce((acc, partName) => {
      acc[partName] = this._parts[partName].current;
      return acc;
    }, {});
    const event = new Event('bsReady');
    window.dispatchEvent(event);
  }
  render(){
    return (
      <ImportContext.Provider value={ this }>
        { this.props.children }
      </ImportContext.Provider>
    )
  }
}
Import.Part = ({ name, className }) => {
  const ref = React.createRef();
  return (
    <ImportContext.Consumer>
    {(_import) => {
      _import.addPart(name, ref);
      return (<div className={ className } ref={ ref }/>);
    }}
    </ImportContext.Consumer>
  );
};

export default {
  open,
  Export,
  Import
}