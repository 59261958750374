// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tabs{
  display: flex;
  flex-direction: column;
}
.bs-tabs-buttons{
  display: flex;
  flex-direction: row;
}
.bs-tabs-button>.bs-tab-old-button{
  flex-grow: 1;
}
.bs-tabs-buttons>.bs-tab-old-button:nth-child(n + 2){
  margin-left: 1px;
}
.bs-tab-old-button-not-selected{
  background-color: rgba(200, 200, 200, 1);
}
.bs-tabs-content{
  flex-grow: 1;
  overflow: hidden;
}
.bs-tab-old{
  overflow: hidden;
  height: 100%;
}
.bs-tab-old>*{
  height: 100%;
}
.bs-tab-old-hidden{
  display: none;
}`, "",{"version":3,"sources":["webpack://./../common/components/tabs.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,gBAAgB;AAClB;AACA;EACE,wCAAwC;AAC1C;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,gBAAgB;EAChB,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;AACf","sourcesContent":[".bs-tabs{\n  display: flex;\n  flex-direction: column;\n}\n.bs-tabs-buttons{\n  display: flex;\n  flex-direction: row;\n}\n.bs-tabs-button>.bs-tab-old-button{\n  flex-grow: 1;\n}\n.bs-tabs-buttons>.bs-tab-old-button:nth-child(n + 2){\n  margin-left: 1px;\n}\n.bs-tab-old-button-not-selected{\n  background-color: rgba(200, 200, 200, 1);\n}\n.bs-tabs-content{\n  flex-grow: 1;\n  overflow: hidden;\n}\n.bs-tab-old{\n  overflow: hidden;\n  height: 100%;\n}\n.bs-tab-old>*{\n  height: 100%;\n}\n.bs-tab-old-hidden{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
