import React from "react";
import T from "@uBehaviour/i18n";

const type = {
  weekly: "weekly",
  monthlyFixedDay: "monthly",
  monthlyRelativeDay: "monthly",
  yearlyFixedDay: "yearly",
  yearlyRelativeDay: "yearly",

};

const getFinalSchedulerRule = (schedulerRule) => schedulerRule.rule ? getFinalSchedulerRule(schedulerRule.rule) : schedulerRule;
const Recurrence = ({ recurrence }) => recurrence ? (
  <span>
    <span className="fa fa-repeat" />
    &nbsp;
    <T>{ "issue_item_recurrence_" + type[getFinalSchedulerRule(recurrence.schedulerRule).discriminator]}</T>
  </span>
) : null;

export default Recurrence;
