import RequestsActions from 'client/core/RequestsActions'
import { action } from 'client/core/actions'

export const HomeRequest = new RequestsActions('HOME', { excludedMethods: ['post', 'put', 'delete'] })
export const HomeLastIssuesRequest = new RequestsActions('HOME_LAST_ISSUES', { excludedMethods: ['post', 'put', 'delete'] })
export const HomePlannedTodayIssuesRequest = new RequestsActions('HOME_PLANNED_TODAY_ISSUES', { excludedMethods: ['post', 'put', 'delete'] })
export const HomeDeadlineInNextWeekIssuesRequest = new RequestsActions('HOME_NEXT_WEEK_DEADLINE_ISSUES', { excludedMethods: ['post', 'put', 'delete'] })
export const HomeDeadlineIssuesRequest = new RequestsActions('HOME_DEADLINE_ISSUES', { excludedMethods: ['post', 'put', 'delete'] })
export const HomeWeatherRequest = new RequestsActions('HOME_WEATHER', { excludedMethods: ['post', 'put', 'delete'] })
export const HomeLogsRequest = new RequestsActions('HOME_LOGS', { excludedMethods: ['post', 'put', 'delete'] })
export const HomeIssueMatch = new RequestsActions('HOME_ISSUE_MATCH', { excludedMethods: ['post', 'put', 'delete'] })

/*Filters*/
export const SET_HOME_FILTERS = 'SET_HOME_FILTERS'
export const setHomeFilter = (key, value) => action(SET_HOME_FILTERS, { key, value })

/*Clear logs*/
export const CLEAR_HOME_LOGS = 'CLEAR_HOME_LOGS'
export const clearLogs = () => action(CLEAR_HOME_LOGS)