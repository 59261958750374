import React        from 'react';
import Item         from '@cComponents/old/item';
import Title        from '@cComponents/title';

import './item.css';

export default (props) => {
  const campaign = props.data;
  return (
    <Item type="compact" className="bs-campaign-item">
      <Item.Content>
        <Title>{campaign.title}</Title>
      </Item.Content>
    </Item>
  );
}