import fetch from 'isomorphic-fetch'
import request from 'superagent'
import { eventChannel, END } from 'redux-saga'
import REGEXP from 'common/REGEXP'
import T from 'i18n-react'
import _ from 'lodash';

const __CONFIG = window.__CONFIG;

let APP_VERSION
let application = null;
export const setApplication = (app) => {
  application = app;
}

export const fetchApi = (endpoint, params = {}, moreHeaders = []) => {
  const configuration = application.getService('configuration');
  return application.getService('session').getToken().then(bsToken => {
    const headers = new Headers()
    headers.append('X-Requested-With', 'XMLHttpRequest')
    headers.append('src', 'back-office')
    headers.append('authorization', `Bearer ${bsToken}`);

    if(application.getService('currentTenant').isSelected()){
      headers.append("tenant", application.getService('currentTenant').currentId);
    }
  
    if (moreHeaders[0]) {
      moreHeaders.forEach(header => headers.append(header.key.toLowerCase(), header.value))
    }
    const options = {
      method: 'GET',
      mode: 'cors',
      cache: 'default',
      headers: headers
    }
  
    endpoint = `${configuration.get('api_uri_v1')}${endpoint}`
  
    endpoint += '?'
    for (const i in params) {
      endpoint += `${i}=${!_.isObject(params[i]) ? params[i] : JSON.stringify(params[i])}&`
    }
  
    return fetch(endpoint, options)
      .then(response => response.json().then(json => ({ json, response })))
      .then(
        response => ({ response }),
        error => ({ error })
      )
  });
}

export const callApi = (endpoint, method, payload, files, entity, actionData, progress = false, query, apiVersion) =>
  eventChannel(emit => {
    const configuration = application.getService('configuration');
    application.getService('session').getToken().then(bsToken => {
      if (query) {
        endpoint += '?'
        for (const i in query) {
          endpoint += `${i}=${!_.isObject(query[i]) ? query[i] : JSON.stringify(query[i])}&`
        }
      }
      if (method === 'GET' && payload) {
        endpoint += '?'
        for (const i in payload) {
          endpoint += `${i}=${!_.isObject(payload[i]) ? payload[i] : JSON.stringify(payload[i])}&`
        }
      }
      const host = apiVersion === 2 ? configuration.get('api_uri') : configuration.get('api_uri_v1')
      const apiRequest = !endpoint.startsWith('http')
      const url = apiRequest ? `${host}${endpoint}` : endpoint
      const req = request[method.toLowerCase()](url)


      if(apiRequest){
        req.set('X-Requested-With', 'XMLHttpRequest')
          .set('src', 'back-office')
          .set('lang', (localStorage.getItem('lang') || 'fr'))
        if (!files || !files[0])
          req.set('Content-Type', 'application/json')
        if (bsToken)
          req.set('authorization', `Bearer ${bsToken}`)
      }
      if(application.getService('currentTenant').isSelected()){
        req.set("tenant", application.getService('currentTenant').currentId);
      }
      if (!files)
        req.send(payload)


      if (files) {
        for (const i in files) {
          req.attach('files', files[i])
        }
        for (const i in payload) {
          req.field(i, payload[i])
        }
      }
      req.on('progress', function (e) {
        if (progress)
          emit(entity.progress({ percent: e.percent, ...payload }))
      })
      req.then((response) => {
        //if (!checkAppVersion(response.headers['betterstreet-version']))
        //  return appOutdated()
        emit(entity.success({ ...response.body, actionData }))
        emit(END)
      })
      req.catch((error) => {
        if (error.response && error.response.body && error.response.body.error === 'session_expired')
          return sessionExpired()
        if (error.response && error.response.body && error.response.body.error === 'app_version_outdated')
          return appOutdated()
        emit(entity.failure(error.response && error.response.body))
        emit(END)
      })
    })
    return () => {}
  })
  

export const makeEndpoint = (endpoint, params = {}, replace) => {
  // let matches
  if(params._id){
    params.id = params._id;
  }
  Object.keys(params).forEach(key => {
    endpoint = endpoint.replace(`:${key}`, params[key]);
  });

  // while (matches = REGEXP.path.params.exec(endpoint)) {
  //   const match = matches[1]
  //   let param = params[match]
  //   if (!param && match === 'id') param = params._id
  //   if (param) endpoint = endpoint.replace(`:${match}`, param)
  //   //else if (__DEV) console.warn(`Make endpoint: param ${match} not found.`)
  // }
  if (replace && endpoint.match(/\*\*/))
    endpoint = endpoint.replace(/\*\*/, replace)
  return endpoint
}

const sessionExpired = () => {
  alert(T.translate('session_expired'))
  return window.location.reload(true)
}

const appOutdated = () => {
  alert(T.translate('reload_alert'))
  return window.location.reload(true)
}

const checkAppVersion = (receivedVersion) => {
  if (!APP_VERSION)
    return APP_VERSION = receivedVersion
  return APP_VERSION === receivedVersion
}