import React, { Component } from 'react'
import T from 'i18n-react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import CheckboxField from 'client/features/form/old/components/CheckboxField.jsx'
import SelectField from 'client/features/form/old/components/fields/Select.jsx'
import Textarea from 'client/features/form/old/components/fields/Textarea.jsx'
import Application from '@uBehaviour/application';
import Acl from '@universal/behaviour/acl'

class IssueDuplicateModal extends Component {

    commentChange(value) {
        this.props.change('overwrite.description', value)
    }
    
    render() {
        const { show, onHide, initialValues, tags, mentions } = this.props
        
        return (
            <Modal show={ show } onHide={ onHide } aria-labelledby='contained-modal-title-lg' bsSize="large" className='modal-helper'>
                <form onSubmit={ this.props.handleSubmit }>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            { T.translate('duplicate') }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={ { overflow: 'visible' } }>
                        <Field
                            name='overwrite.tag'
                            component={ SelectField }
                            value={ { ...initialValues.overwrite.tag, label: initialValues.overwrite.tag.label, value: initialValues.overwrite.tag._id } }
                            options={
                                tags.map((tag) => ({ ...tag, label: tag.label, value: tag._id })) 
                            }
                        />
                        <div style={ { marginBottom: 55 } }>
                            <Field
                                name='overwrite.description'
                                defaultValue={ initialValues.overwrite.description }
                                component={ Textarea }
                                mentions={ mentions }
                                forceEditMode
                                mentionsChange={ this.commentChange.bind(this) }
                                noButtons
                            />
                        </div>
                        <p>
                            { T.translate('issue_duplicate_explanation') }
                        </p>
                        <Acl.If resource="issues" action="link">
                          <div style={{ paddingLeft: "15px" }}>
                            <Field
                                name='grouped'
                                component={ CheckboxField }
                                label={ T.translate('issue_duplicate_link_new_issue') }
                                initialValues={ initialValues.grouped }
                            />
                          </div>
                        </Acl.If>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className='button-a' onClick={ onHide }>
                            { T.translate('cancel') }
                        </button>
                        <button type='submit' className='button-a orange'>
                            { T.translate('duplicate') }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}

const selector = formValueSelector('issueDuplicate')

const mapStateToProps = (state) => (
    { 
        formValues: {
            grouped: selector(state, 'grouped'),
        }
    }
)

const validate = values => {
    const errors = {};
    if (Object.keys(values).length && !values.overwrite?.description || values.overwrite?.description.lenght === 0) {
        errors.overwrite = {
            description: "Ce champ ne peut pas être vide"
        }
    }
    return errors;
}

export default connect(mapStateToProps)(IssueDuplicateModal = reduxForm({
    form: 'issueDuplicate',
    validate 
})(IssueDuplicateModal))

