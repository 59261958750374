import React  from "react";
import Button from "@cComponents/button";
import T      from "@uBehaviour/i18n";
import useService from "@uBehaviour/hooks/useService";
import ConfigurationService from "@uServices/configuration";
import Text from "@common/components/text";

const DiscoverStdExp: React.FunctionComponent = () => {
  const CTA = Button.withStyle(Button.Stylized.BigOrange);
  const configuration = useService<ConfigurationService>("configuration");

  const openPromotionalLink = React.useCallback(() => window.open(configuration.get("discoverLink")), [configuration]);
  
  return (
    <CTA onClick={ openPromotionalLink }>
      <Text><T>starter_cta_discoverStdExp</T></Text>
    </CTA>
  );
}

export default DiscoverStdExp;
