// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-common-planning {
  width: 100%;
  table-layout: fixed;
}

.bs-common-planning-line{

}`, "",{"version":3,"sources":["webpack://./../common/components/planning.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;;AAEA","sourcesContent":[".bs-common-planning {\n  width: 100%;\n  table-layout: fixed;\n}\n\n.bs-common-planning-line{\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
