// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-overlay-view-address-external-link {
  color: #27ae60;
  text-decoration: underline;
}
.bs-overlay-view-address-external-link:hover {
  color: #27ae60
}
.bs-overlay-view-address-external-link:active {
  color: #2cc68e
}
.bs-address-address>span:first-child{
    margin-right: 2px;
}`, "",{"version":3,"sources":["webpack://./src/old/features/overlay-view/components/address.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,0BAA0B;AAC5B;AACA;EACE;AACF;AACA;EACE;AACF;AACA;IACI,iBAAiB;AACrB","sourcesContent":[".bs-overlay-view-address-external-link {\n  color: #27ae60;\n  text-decoration: underline;\n}\n.bs-overlay-view-address-external-link:hover {\n  color: #27ae60\n}\n.bs-overlay-view-address-external-link:active {\n  color: #2cc68e\n}\n.bs-address-address>span:first-child{\n    margin-right: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
