import moment from 'moment'
import T from 'i18n-react'
import ISSUE from 'common/ISSUE'

const buildIssuesTypesComparision = (data) => {
  const result = [
    { icon: 'map-marker', key: 'publicSpace' },
    { icon: 'building', key: 'building' },
    { icon: 'wrench', key: 'equipment' }
  ]
  const total = data.publicSpace + data.building + data.equipment;
  result[0].value = Math.round(data.publicSpace / total * 100);
  result[1].value = Math.round(data.building / total * 100);
  result[2].value = Math.round(data.equipment / total * 100);
  return result
}

const buildIssuesRequestorsComparision = (data) => {
  const result = [
    { icon: 'address-card-o', key: 'citizen' },
    { icoMoon: 'library', key: 'internal' }
  ]
  const total = data.reduce((a, b) => a + b.count, 0);
  const citizenData = data.find(i => i._id === 'citizen')
  result[0].value = citizenData ? Math.round(citizenData.count / total * 100) : 0
  result[1].value = data.find(i => i._id === 'pro') ? 100 - result[0].value : 0
  return result
}

const buildIssuesDevicesComparison = (data) => {
  const result = [
    { icon: 'laptop', key: 'web' },
    { icon: 'mobile-phone', key: 'mobile' }
  ]
  const webData = data.find(i => i._id === 'web')
  result[0].value = webData ? Math.round(webData.count / (data[0].count + (data[1] ? data[1].count : 0)) * 100) : 0
  result[1].value = data.find(i => i._id === 'mobile') ? 100 - result[0].value : 0
  return result
}

const buildBarChart = (chartData, options = {}) => {
  const { orientation } = options
  const isHorizontalChart = orientation === 'h' || orientation === 'horizontal'
  if (isHorizontalChart)
    chartData = chartData.slice(0,8)
  const x = isHorizontalChart ? chartData.map(d => d.count) : Object.keys(chartData)
  const y = isHorizontalChart 
    ? chartData.map(
        d => d.name 
          ? d.name.length > 18 ? d.name.substring(0,18) + "... " : d.name + " "
          : d._id.length > 18 ? d._id.substring(0,18) + "... " : d._id + " "
      )
    : Object.values(chartData)
  const total = x.reduce((a, b) => a + b, 0)

  const dataOptions = {
    type: 'bar',
    marker: { color: '#2E8B96' },
    x,
    y,
    isEmpty: !y.some(value => value !== 0),
    orientation: isHorizontalChart ? 'h' : null
  }

  const data = [dataOptions]
  const layout = {
    annotations: x.map((value, index) => { 

      return ({
        x: value,
        y: y[index],
        text: isHorizontalChart ? `${Math.round((value / total) * 100)}%` : y[index] || '',
        xanchor: isHorizontalChart ? 'left' : 'center',
        yanchor: isHorizontalChart ? 'right' : 'bottom',
        showarrow: false,
        showlegend: !isHorizontalChart
      })
    })
  }
  const haveData = y.some(value => value !== 0)
  const chart = { data, layout, haveData }
  return chart
}

const buildValorizationBarChart = (datas, filters) => {
  const chartData = {};
  if (!datas || !datas.length) {
    return chartData;
  }
  
  if (filters) {
    const customDiff = filters.end.diff(filters.start);
    for (let i = new Date(filters.start).getTime(); i < new Date(filters.end).getTime(); i += 86400000) {
      const key = getTimesKey(i, filters.period, customDiff)
      if (!chartData[key])
        chartData[key] = {}
    }
    datas.forEach(data => {
      const key = getTimesKey(new Date(data.date || data._id), filters.period, customDiff)
      if (chartData[key] !== undefined) {
        if (!chartData[key][data.type]) {
          chartData[key][data.type] = 0;
        } 
        chartData[key][data.type] += data.cost
      }      
    })
  } else {
    datas.forEach(data => {
      if (!chartData[data.name]) {
        chartData[data.name] = {};
      }
      if (!chartData[data.name][data.type]) {
        chartData[data.name][data.type] = data.cost;
      } else {
        chartData[data.name][data.type] += data.cost
      }
    })
  }

  const values = [{
    type:'User',
    color:"#ff9800",
    name: "Agents"
  }, {
    type:'Team',
    color:"#f77448",
    name: "Equipes"
  }, {
    type:"Patrimony",
    color: "#2980b9",
    name: "Equipements"
  }, {
    type:"Supply",
    color: "#27ae60",
    name:"Fournitures"
  }, {
    type:"ExternalService",
    color:"#9c27b0",
    name: "Prestations externes"
  }];

  const xs = Object.keys(chartData);
  const datasOptions = values.map(value => {
  const ys = [];
   xs.forEach(x => {
      if (chartData[x][value.type]) {
        ys.push(chartData[x][value.type]);
      } else {
        ys.push(0);
      }
    });
    return {
      type: 'bar',
      marker: { color: value.color },
      x: xs,
      y: ys.map(y => Math.round(y)),
      isEmpty:false,
      name: value.name
    }
  });

  const data = datasOptions;
  const layout = {barmode: 'stack'};
  const haveData = true;
  const chart = { data, layout, haveData };
  return chart
}

const buildPieChart = (chartData, options = {}) => {
  const values = chartData.map(d => d.count)
  const labels = chartData.map(d => d._id === "externalservice" ? "Service Externe" : T.translate(d._id))

  const shortValues = options.shortFilteredValues ?
    Object.keys(chartData)
      .filter(value => !options.shortFilteredValues.includes(value))
      .map((k) => this.chartsData.issuesStates[k])
    : null
  const shortLabels = options.shortFilteredValues ?
    Object.keys(chartData)
      .filter(value => !options.shortFilteredValues.includes(value))
      .map((k) => T.translate(k))
    : null

  const data = [
    {
      type: 'pie',
      values,
      labels,
      shortValues,
      shortLabels,
      hoverinfo: 'none',
      showlegend: false,
      marker: { colors: options.colors, shortColors: options.shortColors },
      textinfo: 'label+percent+value',
      hoverinfo: 'label+percent+value',
      textposition: 'outside',
      // hole: .5,
      isEmpty: !values[0]
    }
  ]

  return { data, haveData: values[0] }
}

const buildInTimeChart = (datas, filters) => {
  const chartData = {}
  const customDiff = filters.end.diff(filters.start);
  if (!datas || !datas.length)
    return chartData
  for (let i = new Date(filters.start).getTime(); i < new Date(filters.end).getTime(); i += 86400000) {
    const key = getTimesKey(i, filters.period, customDiff)
    if (!chartData[key])
      chartData[key] = 0
  }
  datas.forEach(data => {
    const key = getTimesKey(new Date(data.date || data._id), filters.period, customDiff)
    if (chartData[key] !== undefined)
      chartData[key] += data.count
  })
  return buildBarChart(chartData)
}

const getTimesKey = (date, filter, customDiff) => {
  if (filter === 'week' || filter === 'currentWeek' || (filter === 'custom' && customDiff && customDiff < 86400000 * 31))
    return moment(new Date(moment(new Date(date)).startOf('day')).getTime()).format('dd DD MMM')
  else if (filter === 'month' || filter === 'currentMonth')
    return moment(new Date(moment(new Date(date)).startOf('day')).getTime()).format('dd DD')
  return moment(new Date(moment(new Date(date)).startOf('month')).getTime()).format('MMM YY')
}



const getDatesQueryAttributes = (type, dates) => {
  let now = new Date()
  let end
  if (type && !type.type) {
    switch (type) {
      case 'week':
        return {
          start: moment().subtract(1, 'week').startOf('week'),
          end: moment(now).subtract(1, 'week').endOf('week')
        }
  
      case 'month':
        const start = moment().subtract(1, 'month').startOf('month')
        return {
          start,
          end: moment(start).endOf('month')
        }
  
      case '3months':
        return {
          start: moment().subtract(3, 'month').startOf('month'),
          end: moment().set('date', 1).set('hour', 0).startOf('month')
        }
  
      case '6months':
        return {
          start: moment().subtract(6, 'month').startOf('month'),
          end: moment().set('date', 1).set('hour', 0).set('minute', 0).set('second', 0)
        }
      case 'currentMonth':
        return {
          start: moment().startOf('month'),
          end: moment(now).endOf('day')
        }
  
      case 'currentWeek':
        return {
          start: moment().startOf('week'),
          end: moment(now).endOf('day')
        }
      default:
        return {
          start: new Date(2012, 0, 1),
          end: moment().endOf('day')
        }
    }
  } else if(type && type.type === "custom") {
    if (dates && dates.gte)
    now = moment(dates.gte).startOf('day')
    else
      now = new Date(2012, 0, 1)
    if (dates && dates.lte)
      end = moment(dates.lte).endOf('day')
    else
      end = moment().endOf('day')

    if (!dates.gte && !end) {
      now = new Date(2012, 0, 1)
      end = moment().set('date', 1).endOf('day')
    }
    return {
      start: now,
      end: end
    }
  } else {
    return {
      start: new Date(2012, 0, 1),
      end: moment().endOf('day')
    }
  }
}

export const buildStats = (data) => {
  data.filters = data.actionData.filters
  delete data.actionData
  data.filters = {
    ...data.filters,
    ...getDatesQueryAttributes(data.filters.period, data.filters.period ? data.filters.period : null)
  }
  
  const result = {
    count: {
      newIssues: data.newIssuesByDay.reduce((accumulator, currentValue) => accumulator + currentValue.count, 0),
      resolvedIssues: data.issuesResolvedSince.length ? data.issuesResolvedSince[0].count : 0,
      notResolvedIssuesSinceMoreTwoMonths: data.issuesOpenedSince2Month.length ? data.issuesOpenedSince2Month[0].count : 0,
      timeForResolveIssue: data.issuesResolutionDuration.length ? Math.round(moment.duration(data.issuesResolutionDuration[0].average, "milliseconds").asDays()) : 0
    },
    comparison: {
      issuesTypes: buildIssuesTypesComparision(data.issuesType[0]),
      issuesRequestors: buildIssuesRequestorsComparision(data.issuesRequestor),
      issuesDevices: buildIssuesDevicesComparison(data.issuesDevice)
    },
    charts: {
      issuesInTime: buildInTimeChart(data.newIssuesByDay, data.filters),
      issuesStates: buildPieChart(data.issuesStates, {
        colors: data.issuesStates.map(s => ISSUE.states.items.find(i => i.value === s._id).color)
      }),
      tags: {
        publicSpace: buildBarChart(data.tagsPublicSpace, { orientation: 'h' }),
        building: buildBarChart(data.tagsBuilding, { orientation: 'h' }),
        patrimony: buildBarChart(data.tagsPatrimony, { orientation: 'h' })
      },
      citizens: buildBarChart(data.citizens, { orientation: 'h' }),
      buildings: buildBarChart(data.buildings, { orientation: 'h' }),
      patrimonies: buildBarChart(data.patrimonies, { orientation: 'h' }),
      valorizations: {
        inTime: buildValorizationBarChart(data.valorizationsInTime, data.filters),
        tags: buildValorizationBarChart(data.valorizationsByTag),
        totalByKinds: buildPieChart(data.valorizationsByType)
      }
    },
    start: moment(data.filters.start),
    end: moment(data.filters.end)
  }
  return result
}