// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-field{
  margin-bottom: 12px;
}
.bs-field-content>div:first-child {
  font-size: 14px;

}
.bs-field-content-row>div:first-child{
  margin-bottom: 2.5px;
}

.bs-field-table {
  display: table;
  border-spacing: var(--std-large-size-inter-element);
}

.bs-field-table>.bs-field {
  display: table-row-group
}

.bs-field-content-column {
  display: table-row;
}
.bs-field-content-column>div{
  display: table-cell;
  width: min-content;
}

.bs-field-error{
  color: red;
}

.bs-field-label {
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./../common/components/field.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,eAAe;;AAEjB;AACA;EACE,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,mDAAmD;AACrD;;AAEA;EACE;AACF;;AAEA;EACE,kBAAkB;AACpB;AACA;EACE,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-field{\n  margin-bottom: 12px;\n}\n.bs-field-content>div:first-child {\n  font-size: 14px;\n\n}\n.bs-field-content-row>div:first-child{\n  margin-bottom: 2.5px;\n}\n\n.bs-field-table {\n  display: table;\n  border-spacing: var(--std-large-size-inter-element);\n}\n\n.bs-field-table>.bs-field {\n  display: table-row-group\n}\n\n.bs-field-content-column {\n  display: table-row;\n}\n.bs-field-content-column>div{\n  display: table-cell;\n  width: min-content;\n}\n\n.bs-field-error{\n  color: red;\n}\n\n.bs-field-label {\n  font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
