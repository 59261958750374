import React, { FunctionComponent, PropsWithChildren } from "react";
import { Paginated } from "@cComponents/wizard";
import T             from "@uBehaviour/i18n";
import CTA           from "../../features/cta";
import Template      from "./template";
import Text, { Style } from "@cComponents/text";
import Slot          from "@uComponents/slot2";
import PromotionalSubtitle from "./promotionalSubtitle";

import "./index.css";

const Image = Slot();

const EquipmentBase: FunctionComponent<PropsWithChildren> & { Image: typeof Image } = ({ children }) => {
  const image = Image.get(children);
  const restChildren = Image.not(children);

  return (
    <Template>
      <Template.Image>{ image }</Template.Image>
      <Template.Icon><span className="fa fa-car bs-featuresDiscovery-equipment-icon" /></Template.Icon>
      <Template.Title><Text style={ Style.huge.bold }><T>starter_featuresDiscovery_equipment_title</T></Text></Template.Title>
      { restChildren }
    </Template>
  );
};
EquipmentBase.Image = Image;

const EquipmentManage: FunctionComponent = () => {
  const focusedText1 = <Text style={ Style.bold }><T>starter_featuresDiscovery_equipment_manage_focus_1</T></Text>;
  const focusedText2 = <Text style={ Style.bold }><T>starter_featuresDiscovery_equipment_manage_focus_2</T></Text>;

  return (
    <EquipmentBase>
      <Image><img src="/images/starter/featuresDiscovery/equipment_2.png" alt="Gestion d'équipement"/></Image>
      <div className="bs-featuresDiscovery-content">
        <Text>
          <T bind={{ focusedText1, focusedText2 }}>starter_featuresDiscovery_equipment_manage</T>
        </Text>
      </div>
    </EquipmentBase>
  );
};

const EquipmentGeolocation: FunctionComponent = () => {
  const focusedText = <Text style={ Style.bold }> <T>starter_featuresDiscovery_equipment_recurrence_focus</T> </Text>;

  return (
    <EquipmentBase>
      <Image><img src="/images/starter/featuresDiscovery/equipment_1.png" alt="Gestion d'équipement"/></Image>
      <Text.Paragraph style={ Style.bold }><T>starter_featuresDiscovery_equipment_geolocation</T></Text.Paragraph>

      <Text.Paragraph><T bind={{ focusedText }}>starter_featuresDiscovery_equipment_recurrence</T></Text.Paragraph>
    </EquipmentBase>
  );
};
 
const EquipmentUpsale: FunctionComponent = () => (
  <EquipmentBase>
    <Image><img src="/images/starter/featuresDiscovery/equipment_3.png" alt="Gestion d'équipement"/></Image>
    
    <PromotionalSubtitle>
      <div className="bs-featuresDiscovery-content">
        <Text>
          <T>starter_featuresDiscovery_equipment_upsale</T>
        </Text>
        <span className="bs-featuresDiscovery-content-CTA">
          <CTA.DiscoverOffers.Orange />
        </span>
      </div>
    </PromotionalSubtitle>
  </EquipmentBase>
);

type EquipmentProps = {
  close: () => void,
};
const Equipment: FunctionComponent<EquipmentProps> = ({ close }) => (
  <Paginated close={ close } style={{ width: "920px", height: "540px" }}>
    <Paginated.Step>
      <EquipmentManage />
    </Paginated.Step>
    <Paginated.Step>
      <EquipmentGeolocation />
    </Paginated.Step>
    <Paginated.Step>
      <EquipmentUpsale />
    </Paginated.Step>
  </Paginated>
);

export default Equipment;