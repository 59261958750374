// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-starter-CTA-discoverOffers {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: var(--std-size-inter-element);
}`, "",{"version":3,"sources":["webpack://./src/applications/starter/features/cta/discoverOffers.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,6BAA6B;EAC7B,kCAAkC;AACpC","sourcesContent":[".bs-starter-CTA-discoverOffers {\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  gap: var(--std-size-inter-element);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
