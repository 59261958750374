import React, { useState } from "react";
import Form                from "@uBehaviour/form";
import Field               from "@cComponents/field";
import Input               from "@cComponents/input";
import T                   from "@uBehaviour/i18n";
import Scrollbar           from "@cComponents/scrollBar";
import Button              from "@cComponents/button";
import List                from "@cComponents/list";
import Application         from "@uBehaviour/application";
import Display             from "@cComponents/displayIf";
import Url                 from "@cComponents/url";
import Slot                from "@uComponents/slot";
import File                from "@cComponents/file";
import Time                from "@cComponents/time";
import MultiLines          from "@cComponents/multilines";
import moment              from "moment";
import { useLocation, useRoute } from "@cFeatures/router";
import { concernAllApplications } from '@uLib/release';

import "./form.css";

const releaseItemSubmit = (releaseHelper, release, type) => {
  return (context, value) => releaseHelper["submit" + type](release, value);
}

const ReleaseItemForm = props => {
  const releaseItemFormRef = React.createRef();
  const value = props.data ? props.data.toPlainText() : {
    title: "",
    description: "",
    files: [],
    links: [],
    target: {
      platform: "all",
      applications: ["admin", "citizen", "starter"]
    }
  };
  const onChange = (form, value) => {
    value.target.applications = value.target.applications.includes("all") ? ["admin", "citizen", "starter"] : value.target.applications;
    return value;
  }
  return (
    <Field.ShortLabelContext prefix="release_item_form">
      <Input.File.DropArea>
        <div className="bs-form-release-item">
          <div className="bs-form-release-item-control">
            <Button.Icon faClass="times" onClick={ () => { props.close() }} />
            <Button.Icon faClass="check" onClick={ () => { releaseItemFormRef.current.submit().then(() => props.close()) } } />
          </div>
          <Form.Simple ref={releaseItemFormRef} onChange={onChange} submit={ releaseItemSubmit(props.releaseHelper, props.release, props.type) } value={ value }>
            <Field.Short name="title"><Input.Text /></Field.Short>
            <Field.Short name="description"><Input.TextArea /></Field.Short>
            <Field.Short name="target.platform">
              <Input.Radio.Btn>
                <Input.Radio.Value value="web"><T>release_item_form_target_platform_web</T></Input.Radio.Value>
                <Input.Radio.Value value="mobile"><T>release_item_form_target_platform_mobile</T></Input.Radio.Value>
                <Input.Radio.Value value="all"><T>release_item_form_target_platform_all</T></Input.Radio.Value>
              </Input.Radio.Btn>
            </Field.Short>
            <Field.Short name="target.applications">
              <Input.Checkbox.Btn>
                <Input.Checkbox.Value value="admin"><T>release_item_form_target_application_admin</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value="citizen"><T>release_item_form_target_application_citizen</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value="starter"><T>release_item_form_target_application_starter</T></Input.Checkbox.Value>
                <Input.Checkbox.Value value="all"><T>release_item_form_target_application_all</T></Input.Checkbox.Value>
              </Input.Checkbox.Btn>
            </Field.Short>
            <Field.Short name="links" multiple={ true }>
              <Input.Array.Simple default={{ label: "", url: "" }}>
                <Input.Array.Simple.Display>
                {(value, edit, drop, idx) => (
                  <div key={idx} className="bs-form-release-item-url">
                    <div><Url label={value.label} url={value.url} /></div>
                    <Button.Icon onClick={drop} faClass="trash" />
                    <Button.Icon onClick={edit} faClass="pencil"/>
                  </div>
                )}
                </Input.Array.Simple.Display>
                <Input.Array.Simple.Form>
                {(submit, cancel, value, idx, _new) => {
                  return (
                    <div className="bs-form-release-item-url-form">
                      <Form.Simple.InputAdapter name="label">
                      {(value, set) => (
                        <Input.Text value={ value } onChange={ v => set(v) }>Label</Input.Text>
                      )}
                      </Form.Simple.InputAdapter>
                      <Form.Simple.InputAdapter name="url">
                      {(value, set) => (
                        <Input.Text value={ value } onChange={ v => set(v) }>Url</Input.Text>
                      )}
                      </Form.Simple.InputAdapter>
                      <Display.If condition={!_new}>
                        <Button.Icon onClick={cancel} faClass="times" />
                      </Display.If>
                      <Button.Icon onClick={() => { submit(value) }} faClass="check"/>
                    </div>
                  );
                }}
                </Input.Array.Simple.Form>
              </Input.Array.Simple>
            </Field.Short>
            <Field.Short name="files" multiple={ true }><Input.File inline/></Field.Short>
          </Form.Simple>
        </div>
      </Input.File.DropArea>
    </Field.ShortLabelContext>
  );
};

const ReleaseItemDisplay = (props) => (
  <div className="bs-form-release-item">
    <div className="bs-form-release-item-control">
      { props.moveBack && <Button.Icon faClass="arrow-up"   onClick={ props.moveBack } /> }
      { props.moveForward && <Button.Icon faClass="arrow-down" onClick={ props.moveForward } /> }
      <Button.Icon faClass="trash" onClick={(e) => e.detail >= 2 ? props.delete() : {}} />
      <Button.Icon faClass="pencil" onClick={() => props.edit()} />
    </div>
    <div className="bs-form-release-item-title">
      <h4 className="bs-form-release-item-title-text">{ props.data.title }</h4>
      <div className="bs-form-release-item-title-targets">
        <Display.If condition={props.data.target.platform !== "all"}>
          <div className="bs-form-release-item-title-target"><T>{ "release_item_form_target_platform_" + props.data.target.platform }</T></div>
        </Display.If>
        <Display.If condition={!concernAllApplications(props.data.target.applications)}>
          {props.data.target.applications.map(application => (
            <div className="bs-form-release-item-title-target"><T>{ "release_item_form_target_application_" + application }</T></div>
          ))}          
        </Display.If>
      </div>
    </div>
    <div className="bs-form-release-item-content">
      <MultiLines>{props.data.description}</MultiLines>
      <p className="bs-form-release-item-content-url">
      {
        props.data.links.map(link => (<Url key={link.url} url={link.url} label={link.label} />))
      }
      </p>
      <div className="bs-form-release-item-files">
      {
        props.data.files.map(file => (<File key={file._id} file={file} height={ 100 } width={ 100 }/>))
      }
      </div>
    </div>
  </div>
);

const ReleaseItem = ({ release, type, data, idx, length, releaseHelper}) => {
  const [edit, changeEdit] = useState(false);

  const moveBack = React.useCallback(type === "NewFeatures"
    ? () => releaseHelper.moveFeatureBack(release, idx)
    : () => releaseHelper.moveBugFixedBack(release, idx)
  , [releaseHelper, release, type, idx]);

  const moveForward = React.useCallback(type === "NewFeatures"
    ? () => releaseHelper.moveFeatureForward(release, idx)
    : () => releaseHelper.moveBugFixedForward(release, idx)
  , [releaseHelper, release, type, idx]);

  const _delete = React.useCallback(type === "NewFeatures"
    ? () => releaseHelper.removeNewFeature(release, data._id)
    : () => releaseHelper.removeBugFixed(release, data._id)
  , [releaseHelper, release, type]);

  return (
    <Display.If condition={edit}>
      <Display.Then>
        <ReleaseItemForm
          data={ data }
          type={ type }
          releaseHelper={ releaseHelper }
          release={ release }
          close={ () => changeEdit(false) }
        />
      </Display.Then>
      <Display.Else>
        <ReleaseItemDisplay 
          edit={() => changeEdit(true)}
          delete={ _delete }
          moveForward={  idx < length - 1 ? moveForward : null }
          moveBack={ idx > 0 ? moveBack : null }
          data={ data }
        />
      </Display.Else>
    </Display.If>
  );
}

const ReleaseForm = (props) => {
  const [, setLocation] = useLocation();
  const route = useRoute();
  const releaseSubmit = React.useCallback((context, value) => {
    return props.releaseHelper.submit(value).then(release => {
      if(!value._id){
        setLocation(`${route}/${ release._id }`);
      }
      return release;
    });
  }, []);

  const form = React.createRef();
  return (
    <div className="bs-release-form">
      <div className="bs-release-form-control">
        <Button.Icon faClass="times" onClick={ () => { props.close() }} />
        <Button.Icon faClass="check" onClick={ () => { form.current.submit().then(() => { if(props.value._id) props.close() }) } } />
      </div>
      <Field.ShortLabelContext prefix="release_form">
        <div>
          <Form.Simple ref={ form } value={ props.value } submit={ releaseSubmit }>
            <Field.Short name="description"><Input.TextArea /></Field.Short>
            <Field.Short name="releaseDate"><Input.DateTime /></Field.Short>
            <Field.Short name="serviceDowntime"><Input.TimeDuration /></Field.Short>
          </Form.Simple>
        </div>
      </Field.ShortLabelContext>
    </div>
  );
}

const ReleaseView = (props) => (
  <div className="bs-release-view">
    <div className="bs-release-view-control">
      <Button.Icon faClass="pencil" onClick={ props.edit } />
    </div>
    <div className="bs-release-view-title">
      <h2 className="bs-release-view-title-text"><span><T bind={{ date: moment(props.data.releaseDate).format("DD/MM/YYYY")}}>release_publish_at</T></span></h2>
      <div>
        <div>
          <span><T>release_form_label_releaseDate</T></span>
          <span><Time.DateTime date={props.data.releaseDate} /></span>
        </div>
        <div>
          <span><T>release_form_label_serviceDowntime</T></span>
          <span><Time.Duration duration={props.data.serviceDowntime} /></span>
        </div>
      </div>
    </div>
    <MultiLines>{ props.data.description }</MultiLines>
  </div>
)

const Release = (props) => {
  const [edit, setEdit] = useState(false);
  if(!props.data._id){
    return (
      <ReleaseForm 
        ref={ props.releaseForm } 
        releaseHelper={ props.releaseHelper } 
        value={ props.data } 
        navigator={props.navigator}
      />
    )
  }
  if(edit){
    return (
      <ReleaseForm
        releaseHelper={ props.releaseHelper } 
        value={ props.data } 
        navigator={props.navigator} 
        close={()=> setEdit(false)}
      />
    );
  }else{
    return (
      <ReleaseView data={ props.data } edit={() => setEdit(true)} />
    )
  }
}
const Title = Slot();

const ItemsTitle = (props) => (
  <div className="bs-form-release-items-title">
    <h2 className="bs-form-release-items-title-text">{ props.children }</h2>
    <Display.If condition={!props.inAdd}>                             
      <Button.Icon faClass="plus" onClick={ () => { props.add(true) }}/>
    </Display.If>    
  </div>
);

const ItemList = (props) => {
  const [add, set] = useState(false);
  const scrollbar = React.createRef();
  const setAdd = (value) => {
    if(value){
      scrollbar.current.position = 0;
    }
    set(value);
  };
  return (
    <div className="bs-form-release-items-content">
      <ItemsTitle inAdd={add} add={setAdd}>
      { Title.get({ props })}
      </ItemsTitle>
      <div className="bs-form-release-items-list">
        <div>
          <Scrollbar ref={scrollbar}>
            <Display.If condition={add}>
              <ReleaseItemForm 
                type={ props.type }
                releaseHelper={ props.helper }
                release={ props.release }
                close={() => set(false)}
              />
            </Display.If>
            <List datas={ props.datas }>
              <ReleaseItem
                type={ props.type }
                releaseHelper={ props.helper }
                release={ props.release }
              />
            </List>
          </Scrollbar>
        </div>
      </div>
    </div>
  )
}

export default Application.forward(["navigator"], [["release", "releaseHelper"]], (props) => {
  const value = props.data && props.data.toPlainText ? props.data.toPlainText() : props.data;
  return (
    <div className="bs-form-release-container">
      <div>
        <Release releaseHelper={ props.releaseHelper } data={ value } navigator={props.navigator}/>
      </div>
      <Display.If condition={ value.newFeatures && value.bugsFixed }>
        <div className="bs-form-release-items-container">
          <ItemList type="NewFeatures" helper={props.releaseHelper} release={ value._id } datas={ props.data.newFeatures }>
            <Title><T>release_form_newFeatures</T></Title>
          </ItemList>
          <ItemList type="BugsFixed" helper={props.releaseHelper} release={ value._id } datas={ props.data.bugsFixed }>
            <Title><T>release_form_bugsFixed</T></Title>
          </ItemList>
        </div>
      </Display.If>
    </div>
  );
});