import React, { PureComponent }              from 'react'
import moment                                from 'moment'
import { emailTemplate, isAuthorized }       from 'client/features/issues/lib'
import T                                     from 'i18n-react'
import Owner                                 from 'client/features/issues/components/details/Owner.jsx'
import Transfer                              from 'client/features/issues/components/details/Transfer.jsx'
import RecurrenceTitle                       from 'client/features/issues/components/common/RecurrenceTitle.jsx'
import IssueDataWrapper                      from 'client/features/issues/containers/IssueDataWrapper.jsx'
import Priority                              from './Priority'
import Display                               from './Display'
import MandatoryValorization                 from './MandatoryValorization'
import IDate                                 from "@cComponents/input/date";
import Tool                                  from "client/features/form/components/inputs/tool";
import Field                                 from "@wComponents/field";
import Application from '@uBehaviour/application';

const InputDate = Tool.Stated(Tool.Nullable(IDate), (d1, d2) => (d1 && d2 && d1.getTime() === d2.getTime()) || (!d1 && !d2));
const DisplayDate = (props) => {
  if(!props.date) return (<span />);
  return (<span>{`Le ${moment(props.date).format("DD/MM/YYYY")}`}</span>);
}
class IssueDetailsData extends PureComponent {

  render() {
    const { issue, session, ownerChange, colleagues, transferChange, deadlineChange, confirmations = {}, priorityChange, acl, api } = this.props

    return (
      <div className='c-issue-data o-layout'>
        <div className='o-layout__item u-1of2-wide'>
          {
            isAuthorized(issue, 'owner', 'read') &&
            <Owner
              owner={ issue.owner }
              options={
                colleagues.map((user) => ({ ...user, label: `${user.firstName} ${user.lastName}`, value: user._id, id: user._id, name: `${user.firstName} ${user.lastName}` }))
              }
              template={ emailTemplate(session.emailsTemplates.updateOwner, issue, session.user) }
              onSubmit={ ownerChange }
              confirmation={ confirmations.owner }
              disabled={ !isAuthorized(issue, 'owner', "update") }
              user={ session.user }
            />
          }

          {
            isAuthorized(issue, 'priority', 'read') &&
            <Priority
              confirmation={ confirmations.priority }
              edit={ isAuthorized(issue, 'priority') }
              priority={ issue.priority.value }
              onClick={ priorityChange }
            />
          }

          {
            isAuthorized(issue, 'deadline', 'read') &&
            <div className='u-margin-top-small'>
              <Field label={ T.translate("deadline") }>
                { 
                  isAuthorized(issue, 'deadline')
                    ? <InputDate fluid nullable value={ issue.deadline ? new Date(issue.deadline) : null } onChange={ deadlineChange } />
                    : <DisplayDate date={ issue.deadline ? new Date(issue.deadline) : null } />
                }
              </Field>
              {
                issue.recurrence &&
                <div className='o-layout o-layout--middle o-layout--right u-margin-top-small'>
                  <div className='o-layout__item u-2of3-tablet'>
                    <a onClick={ this.props.displayRecurrenceModal }>
                      <RecurrenceTitle recurrence={ issue.recurrence } showInfo infoAtTheLine/>
                    </a>
                  </div>
                </div>
              }
            </div>
          }
        </div>
        <div className='o-layout__item u-1of2-wide'>
          {
            isAuthorized(issue, 'transfer', 'read') &&
            <Transfer
              currentIssueState={ issue.state.value }
              transfer={ issue.transfer }
              options={ session.user.resource && session.user.resource.transfers ? [
                { value: 'none', label: T.translate('unselect'), _id: 'none' },
                ...session.user.resource.transfers.filter(transfer => !transfer.disabled)
                  .concat(issue.type === "publicSpace" 
                    ? session.user.resource.tenantTransfers 
                      ? session.user.resource.tenantTransfers
                      : []
                    : []).map((transfer) => (
                  {
                    ...transfer,
                    label: transfer.name,
                    value: transfer._id
                  }
                ))
              ] : [] }
              template={ emailTemplate(session.emailsTemplates.transferIssue, issue, session.user) }
              onSubmit={ transferChange }
              confirmation={ confirmations.transfer }
              disabled={ !isAuthorized(issue, 'transfer') }
              initialValues={ {
                changeState: true,
                emailCopy: true
              } }
            />
          }
          {
            isAuthorized(issue, 'display', 'read') &&
            <div className='u-margin-top-small'>
              <Display
                display={ issue.display }
                type={ issue.type }
                edit={ isAuthorized(issue, 'display') }
                confirmation={ confirmations.display }
                displayChange={ this.props.displayChange }
              />
            </div>
          }
          {
            acl.connectedUserIsAllow("issues", "read") &&
            <div className='u-margin-top-small'>
              <MandatoryValorization
                mustBeValorized={ issue.mustBeValorized }
                edit={ acl.connectedUserIsAllow("issues", "manage") }
                mustBeValorizedChange={ (mustBeValorized) => this.props.api.service("issues", "put").execute(issue._id, { mustBeValorized }) }
              />
            </div>
          }
        </div>
      </div>
    )
  }
}

const IssueDetailsDataWithServices = Application.Service.forward(["acl", "api"], IssueDetailsData)

export default IssueDataWrapper(IssueDetailsDataWithServices)