import React, { FunctionComponent, PropsWithChildren } from "react";
import useHelper from "@uBehaviour/hooks/useHelper";
import PrintHelper from "@universal/helpers/print";

type PrintButtonProps = {
  url: string;
}

const PrintButton: FunctionComponent<PrintButtonProps & PropsWithChildren> = ({ url, children }) => {
  const printHelper = useHelper<PrintHelper>("print");
  const print = React.useCallback(() => printHelper.stdPrint(url), [url]);

  return (
    <div onClick={ print }>
      { children }
    </div>
  );
}

export default PrintButton;