import React, { PureComponent } from 'react'
import T from 'i18n-react'
import FontAwesome from 'react-fontawesome'
import Form from 'client/features/issues/components/details/PublicMessageForm.jsx'
import { Modal } from 'react-bootstrap'
import Title from 'client/features/overlay-view/components/Title.jsx'
import classNames from 'classnames'
import I18n from "@uBehaviour/i18n";

const FormModal = ({ show, onSubmit, cancel, initialValues }) => (
  <Modal show={show}>
    <Modal.Header>
      <Modal.Title><I18n>comment_title</I18n></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <small>
        <I18n>comment_warning</I18n>
      </small>
      <Form
        onSubmit={ onSubmit }
        cancel={ cancel }
        initialValues={ initialValues }
      />
    </Modal.Body>
  </Modal>
);

class IssuePublicMessages extends PureComponent {

  constructor() {
    super()
    this.displayNew = this.displayNew.bind(this)
    this.displayEditComment = this.displayEditComment.bind(this)
    this.displayDeleteModal = this.displayDeleteModal.bind(this)
    this.deleteComment = this.deleteComment.bind(this)
    this.editComment = this.editComment.bind(this)
    this.addComment = this.addComment.bind(this)
    this.state = {
      addNew: false,
      currentEdit: null,
      showModal: false,
      deleteMessage: null
    }
  }

  displayNew() {
    this.setState({
      addNew: !this.state.addNew
    })
  }

  displayEditComment(currentEdit) {
    this.setState({
      currentEdit
    })
  }

  displayDeleteModal(deleteMessage) {
    this.setState({
      deleteMessage: deleteMessage,
      showModal: !this.state.showModal
    })
  }

  deleteComment(comment) {
    this.setState({
      deleteMessage: null,
      showModal: false
    })
    this.props.deletePublicComment(comment)
  }

  editComment(values) {
    this.setState({
      currentEdit: null
    })
    this.props.editPublicComment(values)
  }

  addComment(values) {
    this.setState({
      addNew: false
    })
    this.props.addPublicComment(values)
  }

  _renderComment(comment, user) {
    return (
      <div className='c-issue-detail-public-message__comment o-flex-layout' key={ comment._id }>
        <div className='o-flex-layout__item o-flex-layout__item--fill'>
          {
            <FormModal
              show={this.state.currentEdit === comment._id}
              onSubmit={ this.editComment }
              cancel={ this.displayEditComment }
              initialValues={ { message: comment.message, comment: comment._id } }
            />
          }
          {
              <div className='c-issue-detail-public-message__message c-issue-log-comment'>
                { comment.message }
              </div>
          }
        </div>
        <div className='o-flex-layout__item'>
          {
            (comment.userPro && this.state.currentEdit !== comment._id) &&
            <div className=''>
              {
                comment.userPro === user._id &&
                <a
                  className='u-margin-right-tiny'
                  title={ T.translate('click_here_to_edit') }
                  onClick={ this.displayEditComment.bind(this, comment._id) }
                >
                  <FontAwesome name='pencil' style={ { lineHeight: '26px' } }/>
                </a>
              }
              <a
                className=''
                title={ T.translate('remove') }
                onClick={ this.displayDeleteModal.bind(this, comment) }
              >
                <FontAwesome name='remove' style={ { lineHeight: '26px' } }/>
              </a>
            </div>
          }
        </div>
      </div>
    )
  }

  render() {
    const { edit, comments, display, user } = this.props
    return (
      <div className={ classNames('c-public-message', { 'u-hide-for-print': !comments[0] }) }>
        {
          !display && <div className='c-public-message__overlay' title={ T.translate('issue_update_state_required_display') } />
        }
        <Title title={ T.translate('public_comment_title') } />

        <div className='c-public-message__content'>
          {
            comments.map((comment) => this._renderComment(comment, user))
          }
        </div>
        <div className='c-public-message__footer u-letter-box--small u-hide-for-print'>
          {
            edit && !this.state.addNew &&
            <div className='c-action-link c-action-link--empty u-text-align-right u-display-block'>
              <a className='c-action-link__value' onClick={ this.displayNew }>
                <T.span text='add_public_comment' />
              </a>
            </div>
          }
          <FormModal
            show={this.state.addNew}
            onSubmit={ this.addComment }
            cancel={ this.displayNew }
          />
        </div>

        <Modal show={ this.state.showModal } onHide={ this.displayDeleteModal } bsSize="large" className='modal-helper'>
          <Modal.Header closeButton>
            <Modal.Title>{ T.translate('remove_comment') }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <T.p text='confirmation_remove_comment' />
            <blockquote>
              <em>{ this.state.deleteMessage ? this.state.deleteMessage.message : null }</em>
            </blockquote>
          </Modal.Body>
          <Modal.Footer>
            <button
              className='button-a' type='button' onClick={ this.displayDeleteModal }>
              { T.translate('cancel') }
            </button>
            <button
              className='button-a orange' type='submit' onClick={ this.deleteComment.bind(null, this.state.deleteMessage) }>
              { T.translate('Ok') }
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default IssuePublicMessages