import { call, fork, put, takeEvery, delay } from 'redux-saga/effects'
import { flashes, FLASH } from 'client/features/flashes/actions'
const __SERVER = window.__SERVER;


export function* flash(action) {
  if (__SERVER) return
  const { content } = action
  content.date = new Date()
  yield put(flashes.add(content))
  yield delay(8000)
  yield put(flashes.hide(content))
}

function* flashesSaga() {
  yield takeEvery(FLASH, flash)
}

/*Export watchers sagas*/
const sagas = [
  fork(flashesSaga)
]

export default sagas