import ObjectId from "./ObjectId"
interface Entity<T>{
  id: ObjectId;
  toPlainText: () => T;
  dispose: () => void;
}
export type Loader  = object | boolean;

type GetLoader<Loaded, Property> = Property extends keyof Loaded
  ? Loaded[Property] extends Loader
    ? Loaded[Property]
    : void
  : void;


type BusinessEntityExtractor<Entity, Loaded extends Loader | void> = {
  [property in keyof Entity]: Entity[property] extends Entityable<infer SubEntity>
    ? (GetLoader<Loaded, property> extends Loader
      ? BusinessEntity<SubEntity, GetLoader<Loaded, property>>
      : Entity[property])
    : Entity[property]
}

export type BusinessEntity<T, Loaded extends Loader | void = void> = BusinessEntityExtractor<T, Loaded> & Entity<T>;

export const isEntity = <T>(entity: BusinessEntity<T> | T): entity is BusinessEntity<T> => {
  return !!(entity as BusinessEntity<T>)?.toPlainText;
};

type Entityable<T> = BusinessEntity<T> | ObjectId;

export default Entityable;