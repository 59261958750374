import React         from "react";
import T             from "@cBehaviour/i18n";
import Filter        from "@cComponents/filter";
import Input         from "@cComponents/input";
import Collapsable   from "@cComponents/collapsable";
import Application   from "@uBehaviour/application";

const types = [ "mvt", "xyz" ];

export default Application.forward(["repository", "currentTenant"], [], (props) => (
  <>
    <Collapsable alwaysOpen>
      <Collapsable.Title><T>mapLayer_list_filter_transparent</T></Collapsable.Title>
      <Collapsable.Content>
        <Filter.Generic multiple name="transparent" buildQuery={values => ({ "transparent": { $in: values } })} stringify={value => (<T>{!value ? "mapLayer_list_filter_opaque" : "mapLayer_list_filter_transparent"}</T>)}>
          {(values, add, drop, clear) => (
            <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
              <Input.Checkbox.Value value={true}><T>yes</T></Input.Checkbox.Value>
              <Input.Checkbox.Value value={false}><T>no</T></Input.Checkbox.Value>
            </Input.Checkbox.BtnFilter>
          )}
        </Filter.Generic>
      </Collapsable.Content>
    </Collapsable>
    <Collapsable alwaysOpen>
      <Collapsable.Title><T>mapLayer_list_filter_type</T></Collapsable.Title>
      <Collapsable.Content>
        <Filter.Generic multiple name="type" buildQuery={values => ({ "type": { $in: values } })} stringify={value => (<><T>mapLayer_list_filter_type</T>: {value} </>)}>
          {(values, add, drop, clear) => (
            <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
              {
                types.map(type => (<Input.Checkbox.Value key={type} value={type}><div>{type}</div></Input.Checkbox.Value>))
              }
            </Input.Checkbox.BtnFilter>
          )}
        </Filter.Generic>
      </Collapsable.Content>
    </Collapsable>
  </>
));