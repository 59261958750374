// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-starter-input {
  display: flex;
  align-items: center;
  border: 1px solid #00000044;
  border-radius: 9px;
  height: 35px;
  background-color: white;
  padding: 20px 0px;
}
.bs-starter-input-valid{
  border-color: #5CBC72;
}
.bs-starter-input-valid .bs-starter-input-validation{
  color: #5CBC72;
}
.bs-starter-input-invalid{
  border-color: #bc5c5c;
}
.bs-starter-input-invalid .bs-starter-input-validation{
  color: #bc5c5c;
}
.bs-starter-input
.bs-starter-input input:focus,
.bs-starter-input input {
  border: none !important;
  box-shadow: none !important;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  background-color: white;
}

.bs-starter-input>*:nth-child(2){
  flex-grow: 1;
}

.bs-starter-input>.bs-starter-input-validation,
.bs-starter-input>.bs-starter-input-icon {
  width: 70px;
  font-size: 22px;
  text-align: center;
  flex-shrink: 0;
  flex-grow: 0;
}
@media screen and (max-width: 768px) {
  .bs-starter-input {
    padding: 5px 0px;
  }
}`, "",{"version":3,"sources":["webpack://./src/hoc/inputWithStarterDesign.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,iBAAiB;AACnB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;EACE,qBAAqB;AACvB;AACA;EACE,cAAc;AAChB;AACA;;;EAGE,uBAAuB;EACvB,2BAA2B;EAC3B,WAAW;EACX,YAAY;EACZ,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,WAAW;EACX,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,YAAY;AACd;AACA;EACE;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".bs-starter-input {\n  display: flex;\n  align-items: center;\n  border: 1px solid #00000044;\n  border-radius: 9px;\n  height: 35px;\n  background-color: white;\n  padding: 20px 0px;\n}\n.bs-starter-input-valid{\n  border-color: #5CBC72;\n}\n.bs-starter-input-valid .bs-starter-input-validation{\n  color: #5CBC72;\n}\n.bs-starter-input-invalid{\n  border-color: #bc5c5c;\n}\n.bs-starter-input-invalid .bs-starter-input-validation{\n  color: #bc5c5c;\n}\n.bs-starter-input\n.bs-starter-input input:focus,\n.bs-starter-input input {\n  border: none !important;\n  box-shadow: none !important;\n  margin: 0px;\n  padding: 0px;\n  font-size: 14px;\n  background-color: white;\n}\n\n.bs-starter-input>*:nth-child(2){\n  flex-grow: 1;\n}\n\n.bs-starter-input>.bs-starter-input-validation,\n.bs-starter-input>.bs-starter-input-icon {\n  width: 70px;\n  font-size: 22px;\n  text-align: center;\n  flex-shrink: 0;\n  flex-grow: 0;\n}\n@media screen and (max-width: 768px) {\n  .bs-starter-input {\n    padding: 5px 0px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
