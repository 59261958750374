import React, { FunctionComponent } from 'react';
import FieldGroup from '@components/fieldGroup';
import Input from '@cComponents/input';
import T from '@uBehaviour/i18n';

interface WorkingDaysProps {
  value: Number[];
  onChange: (value: Number[]) => void;
}

const WorkingDays: FunctionComponent<WorkingDaysProps> = ({ value, onChange }) => {

  return (
    <FieldGroup subTitle="tenant_form_workingDay_weekWorkingDays" type="full">
      <Input.Checkbox.Btn value={ value } onChange={ onChange }>
        <Input.Checkbox.Value value={1}>
          <T>tenant_form_workingDay_weekWorkingDays_monday</T>
        </Input.Checkbox.Value>
        <Input.Checkbox.Value value={2}>
          <T>tenant_form_workingDay_weekWorkingDays_tuesday</T>
        </Input.Checkbox.Value>
        <Input.Checkbox.Value value={3}>
          <T>tenant_form_workingDay_weekWorkingDays_wednesday</T>
        </Input.Checkbox.Value>
        <Input.Checkbox.Value value={4}>
          <T>tenant_form_workingDay_weekWorkingDays_thursday</T>
        </Input.Checkbox.Value>
        <Input.Checkbox.Value value={5}>
          <T>tenant_form_workingDay_weekWorkingDays_friday</T>
        </Input.Checkbox.Value>
        <Input.Checkbox.Value value={6}>
          <T>tenant_form_workingDay_weekWorkingDays_saturday</T>
        </Input.Checkbox.Value>
        <Input.Checkbox.Value value={0}>
          <T>tenant_form_workingDay_weekWorkingDays_sunday</T>
        </Input.Checkbox.Value>
      </Input.Checkbox.Btn>
    </FieldGroup>
  );
};

export default WorkingDays;