import React              from "react";
import { ResponsiveBar } from '@nivo/bar';


const NewIssueInTimeBar = (props) => {
  return (
    <ResponsiveBar
      data={props.datas ? props.datas : []}
      margin={{ top: 25, right: 0, bottom: 25, left: 150 }}
      padding={0.3}
      layout="horizontal"
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={{ datum: 'data.color' }}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
      }}
      axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      tooltip={(props) => {return (
        <div style={{ backgroundColor: "#FFFFFF", padding: "4px", borderRadius: "4px", border: "1px solid rgba(0, 0, 0, 0.1)"}}>
          {props.data.label}: <strong>{props.value}</strong>
        </div>
    )}}
    />
  );
}

export default NewIssueInTimeBar;