import React        from "react";
import Application  from "@uBehaviour/application";

import "./iCalUrlCopyButton.css";

const ICalUrlCopyButton = Application.Service.forward(["api", "i18n", "message"], ({ api, i18n, message, query }) => {
  const copyInClipboardUrl = React.useCallback(() => {
    api.service("issues", "icalUrl").execute(query)
      .then(({ url }) => {
          navigator.clipboard.writeText(url);
          message.send("info", i18n.translate("planning_team_linkCopied"))
      });
  }, [api, i18n, message, query]);
  return (
    <span className="fa fa-copy bs-iCalUrlCopyButton" title={ i18n.translate("planning_team_copyLink")} onClick={ copyInClipboardUrl }/>
  );
});

export default ICalUrlCopyButton;