import React from "react";
import Form from "@uBehaviour/form/simple";
import FormContent from "./form/content";
import useService from "@universal/behaviour/hooks/useService";
import ApiService from "@universal/services/api";
import AclService from "@universal/services/acl";
import SessionService from "@universal/services/session";
import CError from '@common/components/error';
import ScrollBar from '@common/components/scrollBar/scrollBar';

import './form.css';

interface IssueFormProps {

}
const IssueValidatorSchema = {
  description: {
    type:"string",
    required:true,
    empty: false
  },
  category:{
    type: "string",
    required: true
  },
  requestor: {
    type: "object",
    properties: {
      firstname: { type: "string", optional: false },
      lastname: { type: "string", optional: false },
      email: { type: "string", optional: true },
      number: { type: "string", optional: true }
      
    }
  }
}

const formValidator = {
  schema: IssueValidatorSchema,
  rules:[
    (value) => value.discriminator === "publicSpace" && (!value.location?.address?.locality || !value.location?.position) ? { path:"location", error:"issue_form_mandatory_location" } : null,
    (value) => value.discriminator === "building" && !value.location?.building ? { path:"location", error:"issue_form_mandatory_building" } : null,
    (value) => value.discriminator === "equipment" && !value.equipment ? { path:"equipment", error:"issue_form_mandatory_equipment" } : null
  ]
}

const IssueForm = React.forwardRef<Form, IssueFormProps>(({}, ref) => {
  const apiService = useService<ApiService>("api");
  const aclService = useService<AclService>("acl");
  const sessionService = useService<SessionService>("session");

  const submitNewIssue = React.useCallback((form, value) => 
    apiService.service("issues", "post").execute(value)
  , [apiService]);

  const defaultIssue = React.useMemo(() => {
    const defaultIssue = {
      requestor: {
        type: "userPro",
        firstname: sessionService.user.firstname,
        lastname: sessionService.user.lastname,
        email: sessionService.user.email
      },
      files: [],
      description: "",
      discriminator: null
    };

    if(aclService.connectedUserIsAllow("issues", "createOnPublicSpace")){
      defaultIssue.discriminator = "publicSpace";
    } else if(aclService.connectedUserIsAllow("issues", "createOnBuilding")){
      defaultIssue.discriminator = "building";
    } else if(aclService.connectedUserIsAllow("issues", "createOnEquipment")){
      defaultIssue.discriminator = "equipment";
    } else {
      throw new Error("Can't create");
    }

    return defaultIssue;
  }, [aclService])

  return (
    <Form ref={ ref } submit={ submitNewIssue } default={ defaultIssue } onChange={ FormContent.onChange } validator={formValidator}>
    {(ctx, value, errors, submit) => (
      <div className="bs-issue-newEntry-form-container">
        <ScrollBar viewPortClassName='bs-issue-newEntry-form-content'>
          <FormContent value={ value } />
        </ScrollBar>
        <CError errors={errors.global} />
      </div>
    )}
    </Form>
  )
});

export default IssueForm;