import React from 'react';
import T from 'i18n-react'
import FontAwesome from 'react-fontawesome'

const Recurrence = ({ issue, onlyIcon = false }) => (
  <span>
    <FontAwesome
      name='repeat'
      title={ T.translate('recurrence') }
    />&nbsp;
    { onlyIcon ? null : <T.span text={ issue.recurrence.type } /> }
  </span>
);

export default Recurrence;