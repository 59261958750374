import React  from "react";
import { v4 as uuid }   from "uuid";

import "./HSForm.css";

const script  = document.createElement("script");
script.type   = "text/javascript";
script.src    = 'https://js.hsforms.net/forms/v2.js';
window.document.head.appendChild(script);

class HSForm extends React.Component {
  constructor(props){
    super(props);
    this._ref = React.createRef();
    this._id = "_" + uuid();
  }
  componentDidMount(){
    window.hbspt.forms.create({
      portalId: "4886148",
      formId: this.props.id,
      cssClass: this.props.cssClass,
      submitButtonClass: this.props.submitButtonClass,
      onFormSubmitted: this.props.onFormSubmitted,
      target: "#" + this._id
    });
  }
  render(){
    return React.createElement("div", { className: "bs-hsform" }, React.createElement("div", {
      ref: this._ref,
      id: this._id
    }));
  }
}

export default HSForm;