// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-category-item {
  height: 100px;
  padding: 10px;
}

.bs-category-item .bs-category-description .bs-default-transfer {
  width:60%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.bs-category-item .bs-category-description>div:not(:last-child) {
  margin-bottom: 5px;
}

.bs-category-item .bs-action {
  display: flex;
  justify-content: space-between;
  color: #27ae60; 
}

.bs-category-item .bs-action .bs-redirection-infos {
  width: 35em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/entities/category/item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;AACf;;AAEA;EACE,SAAS;EACT,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".bs-category-item {\n  height: 100px;\n  padding: 10px;\n}\n\n.bs-category-item .bs-category-description .bs-default-transfer {\n  width:60%;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}\n\n.bs-category-item .bs-category-description>div:not(:last-child) {\n  margin-bottom: 5px;\n}\n\n.bs-category-item .bs-action {\n  display: flex;\n  justify-content: space-between;\n  color: #27ae60; \n}\n\n.bs-category-item .bs-action .bs-redirection-infos {\n  width: 35em;\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
