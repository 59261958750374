import React from 'react';
import Event from '@uLib/event';

export interface IManager {
  get onHighlightChange(): Event;
  isHighlight: () => boolean;
  highlight: null | string;
}

const Context = React.createContext<null | IManager>(null);

export default Context;