import React from 'react'
import PartialHTML from 'client/features/common/components/PartialHTML.jsx'
import classNames from 'classnames'

const IssueListDescription = ({ description, highlight, freeSearch, large = false, wrap, cut = '100' }) => (
    <div className='c-issue__description'>
        <div className={ classNames('c-issue-description', { 'c-issue-description--short': !large, 'c-issue-description--large': large, 'c-issue-description--wrap': wrap }) }>
            <PartialHTML content={ description && description.replace(/[\r\n]/g, ' ') } highlight={ highlight } freeSearch={ freeSearch }/>
        </div>
    </div>

)

export default IssueListDescription