import React, { ComponentType, FunctionComponent, PropsWithChildren } from "react";
import FilterType from "@uTypes/technic/FilterType";


interface WithFactoryElement<P> {
  test: (props: P) => boolean;
  component: ComponentType<Partial<P>>;
}

function withFactory<P>(rules: WithFactoryElement<P>[]): FunctionComponent<PropsWithChildren<P>> {
  return ({ children, ...props }) => {
    const findedRule = React.useMemo(() => rules.find(rule => rule.test(props as P)), [ props ]);
    return React.useMemo(() => {
      if(!findedRule){
        return null;
      }
      return React.createElement(findedRule.component, props as Partial<P>, children);
    }, [findedRule, children]);
  }
}

export default withFactory;