import React, { FunctionComponent, MouseEvent, PropsWithChildren } from 'react';
import Url from '@uTypes/technic/Url';

import './externalLink.css';

interface ExternalLinkProps {
  url: Url;
  "data-testid"?: string
}

const ExternalLink: FunctionComponent<PropsWithChildren<ExternalLinkProps>> = ({ children, url, "data-testid": dataTestId  }) => {
  const stopPropagation = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);
  return (
    <a href={url} onClick={ stopPropagation } target='_blank' rel='noopener noreferrer' className='bs-external-link' data-testid={ dataTestId }>
      { children }
    </a>
  );
};

export default ExternalLink;
