// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-state-display {
  width: var(--state-display-width);
  height: var(--state-display-height);
  display: flex;
  flex-direction: row;
}

.bs-state-display > div:first-child,
.bs-state-display > div:last-child {
  height: 100%;
}
.bs-state-display > div:first-child {
  width: var(--state-display-triangle-width);
  border-top-width: var(--state-display-triangle-height);
  border-top-style: solid;
  border-left     : var(--state-display-triangle-width) solid transparent;
  border-top-color: var(--state-display-color);
}
.bs-state-display > div:last-child {
  background-color: var(--state-display-color);
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: white;
}
.bs-state-display > div:last-child {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bs-state-display-standart > div:last-child > *:first-child {
  flex-grow: 1;
  text-align: right;
}
.bs-state-display-standart > div:last-child > * {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./../common/features/state/display.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,mCAAmC;EACnC,aAAa;EACb,mBAAmB;AACrB;;AAEA;;EAEE,YAAY;AACd;AACA;EACE,0CAA0C;EAC1C,sDAAsD;EACtD,uBAAuB;EACvB,uEAAuE;EACvE,4CAA4C;AAC9C;AACA;EACE,4CAA4C;EAC5C,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,YAAY;EACZ,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB","sourcesContent":[".bs-state-display {\n  width: var(--state-display-width);\n  height: var(--state-display-height);\n  display: flex;\n  flex-direction: row;\n}\n\n.bs-state-display > div:first-child,\n.bs-state-display > div:last-child {\n  height: 100%;\n}\n.bs-state-display > div:first-child {\n  width: var(--state-display-triangle-width);\n  border-top-width: var(--state-display-triangle-height);\n  border-top-style: solid;\n  border-left     : var(--state-display-triangle-width) solid transparent;\n  border-top-color: var(--state-display-color);\n}\n.bs-state-display > div:last-child {\n  background-color: var(--state-display-color);\n  flex-grow: 1;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  color: white;\n}\n.bs-state-display > div:last-child {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.bs-state-display-standart > div:last-child > *:first-child {\n  flex-grow: 1;\n  text-align: right;\n}\n.bs-state-display-standart > div:last-child > * {\n  margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
