import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import toDic        from "@cLib/toDic";
import Application  from "@uBehaviour/application";

export default Application.Service.forward(["session"], props => (
  <Filter.Generic
  multiple
  name="followed"
  buildQuery={value => (value.length === 1 ? { followers: { [value[0] ? "$in": "$nin"]: [props.session.user._id]}} : {})}
  stringify={value => (<T>{ value ? "issue_filter_followed_followed" : "issue_filter_followed_notFollowed" }</T>)}
>
  {(values, add, drop, clear) => (
    <Collapsable>
      <Collapsable.Title><T>issue_filter_followed</T></Collapsable.Title>
      <Collapsable.Content>
        <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values} inline>
          <Input.Checkbox.Value value={true}><T>issue_filter_followed_yes</T></Input.Checkbox.Value>
          <Input.Checkbox.Value value={false}><T>issue_filter_followed_no</T></Input.Checkbox.Value>
        </Input.Checkbox.BtnFilter>
      </Collapsable.Content>
    </Collapsable>
  )}
</Filter.Generic>
));