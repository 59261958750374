import React, { FunctionComponent } from "react";
import Subject from "./subject";

interface ServiceSubjectProps {
  name: string;
  del?: null | (() => void);
  update?: null | (() => void);
};
const ServiceSubject: FunctionComponent<ServiceSubjectProps> = ({ name, del = null, update = null }) => (
  <Subject icon="flag" del={ del } update={ update }>{ name }</Subject>
);

export default ServiceSubject;