// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-form-inline-layout{
    display: table;
    width: 100%;
    border-collapse: separate;
    border-spacing: 10px;
}
.bs-form-inline-layout .bs-form-layout-row{
    display: table-row;
}

.bs-form-inline-layout .bs-form-layout-row.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.bs-form-inline-layout .bs-form-layout-cell{
    display: table-cell;
    vertical-align: middle;
}

.bs-form-inline-layout .bs-form-layout-cell.bs-form-layout-cell-label {
    text-align: justify;
    padding-right: 25px;
    color: #2c3e50;
}

.bs-form-inline-layout .bs-form-layout-cell.bs-form-layout-cell-input.bs-dispose-right {
    display: flex;
    justify-content: flex-end;
}




`, "",{"version":3,"sources":["webpack://./../common/components/layout/form/inline.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,yBAAyB;IACzB,oBAAoB;AACxB;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,oBAAoB;IACpB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,yBAAyB;AAC7B","sourcesContent":[".bs-form-inline-layout{\n    display: table;\n    width: 100%;\n    border-collapse: separate;\n    border-spacing: 10px;\n}\n.bs-form-inline-layout .bs-form-layout-row{\n    display: table-row;\n}\n\n.bs-form-inline-layout .bs-form-layout-row.disabled {\n    pointer-events: none;\n    opacity: 0.6;\n}\n\n.bs-form-inline-layout .bs-form-layout-cell{\n    display: table-cell;\n    vertical-align: middle;\n}\n\n.bs-form-inline-layout .bs-form-layout-cell.bs-form-layout-cell-label {\n    text-align: justify;\n    padding-right: 25px;\n    color: #2c3e50;\n}\n\n.bs-form-inline-layout .bs-form-layout-cell.bs-form-layout-cell-input.bs-dispose-right {\n    display: flex;\n    justify-content: flex-end;\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
