// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-release-next{
  background-color: rgba(39, 174, 96, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}
.bs-release-next>span:first-child{
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.bs-release-next>span:last-child{
  padding: 5px;;
}
.bs-release-next>span:last-child:hover{
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../common/features/release/next.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,YAAY;AACd;AACA;EACE,eAAe;AACjB","sourcesContent":[".bs-release-next{\n  background-color: rgba(39, 174, 96, 1);\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  height: 30px;\n}\n.bs-release-next>span:first-child{\n  flex-grow: 1;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n}\n.bs-release-next>span:last-child{\n  padding: 5px;;\n}\n.bs-release-next>span:last-child:hover{\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
