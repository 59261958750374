import React, { FunctionComponent, PropsWithChildren } from "react";

import "./searchbar.css";

const Searchbar: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className="bs-searchbar">
    { children }
  </div>
);

export default Searchbar;
