import React from "react";
import T  from "@uBehaviour/i18n";
import Period from "@cComponents/period";
import Slot from "@uComponents/slot";
import Display from "@uComponents/displayIf";

import "./assignmentTooltip.css";

const Subject = (props) => {
  const title   = Subject.Title.get({ props });
  const content = Subject.Content.get({ props });
  return (
    <Display.If condition={ props.condition }>
      <div className="bs-assignmentTooltip-subject">
        <div className="bs-assignmentTooltip-subject-title">{ title }</div>
        <div className="bs-assignmentTooltip-subject-content">{ content }</div>
      </div>
    </Display.If>
  )
};
Subject.Title   = Slot();
Subject.Content = Slot();

const Item = ({ icon, children }) => (
  <div className="bs-assignmentTooltip-item">
    { icon ? <span className={`fa fa-${icon}`} /> : null }
    <span>{ children }</span>
  </div>
);

const Supply = ({ supply: { supply, quantity } }) => (
  <><span>{ supply.name }</span>&nbsp;:&nbsp;<span>{ quantity } { supply.valorization.type }</span></>
);
const isEmpty = (assignment) => {
  return !((assignment.scheduledFrom && assignment.scheduledTo)
          || (assignment.team.length || assignment.agents.length)
          || assignment.necessariesEquipments.length
          || assignment.necessariesSupplies.length
          || assignment.workInstructions)
};

const AssignmentTooltip = ({ assignment }) => (
  <Display.If condition={!isEmpty(assignment)}>
    <div className="bs-assignmentTooltip-container">
      <Subject condition={ assignment.scheduledFrom && assignment.scheduledTo }>
        <Subject.Title><T>assignment_assignmentTooltip_period</T></Subject.Title>
        <Subject.Content><Period start={ assignment.scheduledFrom } end={ assignment.scheduledTo } /></Subject.Content>
      </Subject>
      <Subject condition={ assignment.team.length || assignment.agents.length }>
        <Subject.Title><T>assignment_assignmentTooltip_assignedTo</T></Subject.Title>
        <Subject.Content>
        {
          assignment.team.length
            ? assignment.team.map(team => (<Item key={ team._id } icon="users">{ team.name }</Item>))
            : assignment.agents.map(agent => (<Item key={ agent._id } icon="user">{ agent.fullname }</Item>))
        }
        </Subject.Content>
      </Subject>
      <Subject condition={ assignment.necessariesEquipments.length }>
        <Subject.Title><T>assignment_assignmentTooltip_equipments</T></Subject.Title>
        <Subject.Content>
        {
          assignment.necessariesEquipments.map(equipment => (<Item key={ equipment._id } icon="wrench">{ equipment.name }</Item>))
        }
        </Subject.Content>
      </Subject>
      <Subject condition={ assignment.necessariesSupplies.length }>
        <Subject.Title><T>assignment_assignmentTooltip_supplies</T></Subject.Title>
        <Subject.Content>
        {
          assignment.necessariesSupplies.map(s => (
            <Item key={ s.supply._id } icon="archive">
              <Supply supply={ s } />
            </Item>
          ))
        }
        </Subject.Content>
      </Subject>
      <Subject condition={ assignment.workInstructions }>
        <Subject.Title><T>assignment_assignmentTooltip_workInstructions</T></Subject.Title>
        <Subject.Content>{ assignment.workInstructions }</Subject.Content>
      </Subject>
    </div>
  </Display.If>
);

AssignmentTooltip.load = (origin) => ({
  [`${origin}.team`] : 1,
  [`${origin}.agents`] : 1,
  [`${origin}.necessariesEquipments`] : 1,
  [`${origin}.necessariesSupplies.supply`] : 1
});

export default AssignmentTooltip;