import React                from "react";
import dayjs                from "dayjs";
import Application          from "@uBehaviour/application";

const DateCompoent = (props) => {
  return moment(props.date).format("DD/MM/YYYY");
}
const Times = (props) => {

}
const DateTime = (props) => {
  return dayjs(props.date).format("DD/MM/YYYY à HH:mm");
}
const Period = (props) => {

}
const Duration = Application.forward(["i18n"], [], (props) => {
  return dayjs.duration(props.duration, "hours").locale(props.i18n.currentLanguage.bs).humanize();
});
const _export = {
  Date: DateCompoent,
  Times: Times,
  DateTime: DateTime,
  Period: Period,
  Duration: Duration
}
export default _export;