import React from 'react';
import UpdatePasswordComponent from '@cEntities/user/updatePassword';
import AuthentificationLayout from '../layout';
import useService from '@universal/behaviour/hooks/useService';
import SessionService from '@universal/services/session';

const UpdatePassword = () => {
  const session = useService<SessionService>('session');

  const updateMyPassword = React.useCallback(( password: string ) => {
    return session.updateMyPassword(password);
  }, [session]);
  return (
    <AuthentificationLayout>
      <UpdatePasswordComponent updatePassword={ updateMyPassword }/>
    </AuthentificationLayout>
  )
};

export default UpdatePassword;