import React from 'react'
import Draggable from 'react-draggable'
import { Modal } from 'react-bootstrap'

class DraggableModalDialog extends React.Component {
    constructor() {
        super()
        this.handleStart = this.handleStart.bind(this)
        this.handleDrag = this.handleDrag.bind(this)
        this.handleStop = this.handleStop.bind(this)
    }

    handleStart() {
    }
    handleDrag() {
    }
    handleStop() {
    }
  
    render() {
        return (
            <Draggable 
                handle='.modal-title'
                onStart={ this.handleStart }
                onDrag={ this.handleDrag }
                onStop={ this.handleStop }
            >
                <Modal.Dialog { ...this.props } />
            </Draggable>
        )
    }
}

export default DraggableModalDialog