// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-cursor-updatable:hover{
  cursor: url("@cAssets/images/edit.png"), pointer;
}`, "",{"version":3,"sources":["webpack://./../common/assets/css/cursor-update.css"],"names":[],"mappings":"AAAA;EACE,gDAAgD;AAClD","sourcesContent":[".bs-cursor-updatable:hover{\n  cursor: url(\"@cAssets/images/edit.png\"), pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
