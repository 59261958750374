import { fork, join, all } from 'redux-saga/effects'
import flashesSaga from 'client/features/flashes/sagas'
import sessionSaga from 'client/features/session/sagas'
import usersSaga from 'client/features/users/sagas'
import calendarSaga from 'client/features/calendar/sagas'
import homeSaga from 'client/features/home/sagas'
import issuesSaga from 'client/features/issues/sagas'
import statisticsSaga from 'client/features/statistics/sagas'
import notificationsSaga from 'client/features/notifications/sagas'
import { sagas as mySagas } from "client/lib/reduxSaga"

export const root = function* () {
  const sagas = [
    ...sessionSaga,
    ...flashesSaga,
    ...usersSaga,
    ...homeSaga,
    ...calendarSaga,
    ...issuesSaga,
    ...statisticsSaga,
    ...notificationsSaga,
    ...mySagas
  ]
  yield all(sagas)
}

export const waitAll = (sagas = [], req) => function* genTasks() {
  const tasks = yield sagas.map(([saga]) => fork(saga, { ...req, isomorphic: true }))
  if (tasks && tasks[0])
    yield join(...tasks)
}