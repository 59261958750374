// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-section-footer {
  background-color: rgb(245, 245, 245);
  border-top-color: rgb(221, 221, 221);
  border-top-style: solid;
  border-top-width: 1px;
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./../common/components/section/footer.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,oCAAoC;EACpC,uBAAuB;EACvB,qBAAqB;EACrB,aAAa;AACf","sourcesContent":[".bs-section-footer {\n  background-color: rgb(245, 245, 245);\n  border-top-color: rgb(221, 221, 221);\n  border-top-style: solid;\n  border-top-width: 1px;\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
