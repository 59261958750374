import React, { Component } from 'react'
import T from 'i18n-react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import InputField from 'client/features/form/old/components/InputField.jsx'
import TextareaField from 'client/features/form/old/components/TextareaField.jsx'
import SelectField from 'client/features/form/old/components/SelectField.jsx'
const __LANG    = window.__LANG;


const validate = values => {
    const errors = { valorization: {} }
    if (!values.name)
        errors.name = T.translate('form_validation_requiredValue')
    if (!values.valorization || !values.valorization.type)
        errors.valorization.type = T.translate('form_validation_requiredValue')
    //if (values.valorization && values.valorization.type)
    //    errors.valorization.cost = T.translate('form_validation_requiredValue')
        
    return errors
}
class SupplyModal extends Component {
    descriptionChange(value) {
        this.props.change('overwrite.description', value)
    }

    render() {
        const { show, onHide, initialValues = {}, user } = this.props
        
        return (
            <Modal show={ show } onHide={ onHide } bsSize="large" className='modal-helper'>
                <form onSubmit={ this.props.handleSubmit }>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            { T.translate('supply') }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={ { overflow: 'visible' } }>
                        <div className='o-layout'>
                            <div className='o-layout__item u-6of12-tablet'>
                                <Field
                                    label={ T.translate('name') }
                                    name='name'
                                    className='c-field'
                                    initialValue={ initialValues.name }
                                    component={ InputField }
                                    autoFocus='true'
                                />
                            </div>
                            <div className='o-layout__item u-6of12-tablet'>
                                <Field
                                    label={ T.translate('external_reference') }
                                    name='sku'
                                    className='c-field'
                                    initialValue={ initialValues.name }
                                    component={ InputField }
                                />
                            </div>
                            <div className='o-layout__item u-6of12-tablet'>
                                <Field
                                    name='valorization.type'
                                    label={ T.translate('valorization_unit') }
                                    component={ SelectField }
                                    className='c-field u-bg-none bs-supplyType-formModal'
                                    value={ null }
                                    initialValue={ initialValues.valorization && initialValues.valorization.type } 
                                    options={
                                        user.resource.valorization.suppliesTypes.map(type => ({ ...type, label: type.labels[__LANG] }))
                                    }
                                />
                            </div>
                            <div className='o-layout__item u-6of12-tablet'>
                                <Field
                                    label={ T.translate('cost_by_unit') }
                                    type='number'
                                    name='valorization.cost'
                                    className='c-field'
                                    initialValue={ initialValues.valorization && initialValues.valorization.cost }
                                    component={ InputField }
                                />
                            </div>
                            <div className='o-layout__item u-12of12-tablet'>
                                <Field
                                    label={ T.translate('description') }
                                    name='description'
                                    className='c-field'
                                    initialValue={ initialValues.description }
                                    component={ TextareaField }
                                />
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button type='button' className='button-a' onClick={ onHide }>
                            { T.translate('cancel') }
                        </button>
                        <button type='submit' className='button-a orange'>
                            { T.translate('save_changes') }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}

const selector = formValueSelector('supply')

const mapStateToProps = (state, ownProps) => {
    const { initialValues } = ownProps
    if (!initialValues.valorization)
        initialValues.valorization = { type: 'pc' }
    return { 
        formValues: {
            name: selector(state, 'name'),
        },
        initialValues,
        user: state.session.user
    }
}


export default connect(mapStateToProps)(SupplyModal = reduxForm({
    form: 'supply',
    validate
})(SupplyModal))