
import React        from 'react';
import Address      from '@cComponents/old/input/address';
import Map          from './map';
import T            from '@cBehaviour/i18n';
import Button       from '@cComponents/button';
import Layout       from '@cComponents/layout';
import Application  from "@uBehaviour/application";


import './edit.css';

export default class Edit extends React.Component {

  constructor(props) {
    super(props);
    this._address  = React.createRef();
    this._map      = React.createRef();
    this._value = this.props.value;
  }

  componentDidMount() {
    this._address.current.onChange.addListener(this);
    this._map.current.onChange.addListener(this);
  }

  componentWillUnmount() {
    this._address.current.onChange.removeListener(this);
    this._map.current.onChange.removeListener(this);
  }

  handleEvent(ev, value, origin) {
    this._value = value;
    if (origin.name === "address") {
      this._map.current.value = value.position;
    };
    if (origin.name === "position") {
      this._address.current.value = value.address;
    }    
  }
    
  render() {
    const { onSave, icon } = this.props;
    return React.createElement(Application.Service, { name: ["currentTenant"] }, ([currentTenant]) => (
      <div className="bs-edit-location">
        <Layout.Standart>
          <Layout.Standart.Header className="bs-locationEdit-header">
            <div><T>location_edit</T></div>            
            <div className="bs-subTitle"><T>location_edit_subLabel</T></div>
          </Layout.Standart.Header>
          <Layout.Standart.Content className="bs-locationEdit-content">
            <Address
              name="address"
              value={this._value ? this._value.address : null}
              ref={this._address}
              country={currentTenant.current.country}
            />
            <Map
              name="position"
              value={this._value ? this._value.position : null}
              ref={this._map}
              height="75vh" 
              icon={icon}                      
            />
          </Layout.Standart.Content>
          <Layout.Standart.Footer className="bs-locationEdit-footer">
            <Button.Text onClick={()=> { onSave(this._value) }}><T>save</T></Button.Text>
          </Layout.Standart.Footer>  
        </Layout.Standart>        
      </div>
    ));
  }
}






