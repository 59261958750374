import React, { PureComponent } from 'react'
import T from 'i18n-react'
import { reduxForm } from 'redux-form'
import SelectField from 'client/features/form/old/components/fields/Select.jsx'
import ContactModal from 'client/features/issues/components/modals/Contact.jsx'
import CheckboxField from 'client/features/form/old/components/CheckboxField.jsx'
import { Field } from 'redux-form'

const validate = values => {
  const errors = {}
  if (!values.message)
    errors.message = T.translate('form_validation_requiredValue')
  if (!values.to)
    errors.to = T.translate('form_validation_add_email')
  return errors
}


class IssueDetailsTransfer extends PureComponent {
  constructor(props) {
    super()
    this.onChange = this.onChange.bind(this)
    this.displayModal = this.displayModal.bind(this)
    this.submit = this.submit.bind(this)
    this.state = {
      transfer: props.transfer,
      showModal: false
    }
  }

  onChange(transfer) {
    if (!transfer || (this.props.transfer && transfer._id === this.props.transfer._id)) return
    this.props.change('transfer', transfer)
    this.setState({
      transfer,
      showModal: true
    })
    //this.props.dispatch(IssueOwnerChange(transfer._id))
  }

  displayModal() {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  submit() {
    this.props.handleSubmit()
    if (!this.props.valid) return
    this.setState({
      showModal: false
    })
  }

  render() {
    const { transfer, options, template, confirmation, disabled, currentIssueState } = this.props

    return (
      <div>
        <SelectField
          value={
            transfer && transfer._id && transfer._id !== 'none' ?
              { ...transfer, value: transfer._id, label: transfer.name } : null
          }
          label='transfer'
          placeholder={ T.translate('select_transfer') }
          columns
          onChange={ this.onChange }
          options={ options }
          confirmation={ confirmation }
          classNames={ {
            containerLeft: 'o-layout__item u-1of3',
            containerRight: 'o-layout__item u-2of3'
          } }
          disabled={ disabled }
        />
        {
          this.state.showModal &&
          <form>
            <ContactModal
              show={ this.state.showModal }
              onHide={ this.displayModal }
              title='transfer_issue_by_email'
              template={ template }
              submit={ this.submit }
              customTo={ this.state.transfer && this.state.transfer._id === 'other' }
              to={ this.state.transfer ? this.state.transfer.emails : null }
              copyEmail
              requiredMessage
              fallback={ this.state.transfer && this.state.transfer._id === 'none' ? 'remove_issue_transfer' : null }
              footerActions={ currentIssueState !== 'transferred' && (!this.state.transfer || this.state.transfer._id !== 'none') && [
                <Field
                  name='changeState'
                  component={ CheckboxField }
                  label={ T.translate('transfer_auto_change_state') }
                  style={ { display: 'inline-block', float: 'left', minHeight: 0 } }
                  key='change-state'
                  initialValues={ this.props.initialValues.changeState }
                />
              ] }
            />
          </form>
        }
      </div>
    )
  }
}

export default IssueDetailsTransfer = reduxForm({
  form: 'issueTransfer',
  validate
})(IssueDetailsTransfer)