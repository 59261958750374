import { AgentStatement, TeamStatement ,SupplyStatement, EquipmentStatement, ServiceStatement, Statement } from '../../../types/business/Valorization';

export interface IndexedStatement<T extends Statement> {
  index: number;
  statement: T;
}

interface DicTypeStatements {
  agents: Array<IndexedStatement<AgentStatement>>,
  teams: Array<IndexedStatement<TeamStatement>>,
  supplies: Array<IndexedStatement<SupplyStatement>>,
  equipments: Array<IndexedStatement<EquipmentStatement>>,
  services: Array<IndexedStatement<ServiceStatement>>
};

const sortStatementsByType = (statements: Statement[]) => statements.reduce<DicTypeStatements>((statements, statement, index) => {
  switch(statement.discriminator){
    case "agent": statements.agents.push({ statement: statement as AgentStatement, index}); break;
    case "team": statements.teams.push({ statement: statement as TeamStatement, index }); break;
    case "supply": statements.supplies.push({ statement: statement as SupplyStatement, index }); break;
    case "equipment": statements.equipments.push({ statement: statement as EquipmentStatement, index }); break;
    case "service": statements.services.push({ statement: statement as ServiceStatement, index }); break;
  }
  return statements;
}, { agents: [], teams: [], supplies: [], equipments: [], services: [] });

export default sortStatementsByType;