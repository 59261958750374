import React        from "react";
import _            from "lodash";
import Data         from "@uBehaviour/data";
import Button       from '@cComponents/button';
import Contact      from "@entities/contact";
import T            from "@cBehaviour/i18n";
import Filter       from "@cComponents/filter";
import FilteredList from "@cFeatures/filter/list";
import Input        from "@cComponents/input";
import CurrentTenantService from "@uServices/currentTenant";
import { withLink, withModalLinked } from "@cHoc/withLinkAndModalLinked";
import ContactForm from './form';
import useService from "@uBehaviour/hooks/useService";
import { Link } from "@common/features/router";
import ListHeaderLayout from '@cFeatures/layout/listHeader';
import Searchbar from "@cComponents/searchbar";
import { escapeRegExp } from "@uLib/tool";


const query = (currentTenant: CurrentTenantService) => {
  let query = currentTenant.currentId ? { tenant: currentTenant.currentId }: undefined;
  return query;
}
const textSearchBuildQuery = (value: string) => {
  return { $or: [
    { name:   { '$regex': escapeRegExp(value), '$options': 'i' } },
    { emails: { '$regex': escapeRegExp(value), '$options': 'i' } }
  ]};
}
const defaultQuery = () => {
  return [{ name: "disabled", value: false }];
}

const LinkedContactItem = withLink(Contact.Item);
const ModalizedContactForm = withModalLinked(ContactForm);


const ContactList = () => {
  const currentTenant = useService<CurrentTenantService>('currentTenant');
  return (
    <>
      <FilteredList default={defaultQuery()}>
        <FilteredList.Filter>
          <Contact.Filters />
        </FilteredList.Filter>
        <FilteredList.Header>
          <ListHeaderLayout>
            <ListHeaderLayout.Center>
              <Filter.Generic name="text" buildQuery={value => textSearchBuildQuery(value)} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                {(value, set, clear) => (
                  <Searchbar>
                    <Input.Text focusOnMount className="bs-filter-text" value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                      <T>freesearch</T>
                    </Input.Text>
                  </Searchbar>
                )}
              </Filter.Generic>
            </ListHeaderLayout.Center>
            <ListHeaderLayout.Right>
              <Link to="/new">
                <Button.Stylized.Text><T>add</T></Button.Stylized.Text>
              </Link>
            </ListHeaderLayout.Right>
          </ListHeaderLayout>
        </FilteredList.Header>
        <FilteredList.List>
          {composeQuery => (
            <Data.List model={"Contact"} query={composeQuery(query(currentTenant))} sort={{ 'name': 1}}>
              <LinkedContactItem />
            </Data.List>
          )}
        </FilteredList.List>
      </FilteredList>
      <ModalizedContactForm style={{ width: "80vw", height: "90vh" }} />
    </>
  );
}

export default ContactList;