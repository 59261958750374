import { Service }        from "@uLib/application";
import ReleaseService from "@uServices/release";

export default class ReleaseStrategyService extends Service {
  constructor(){
    super("release", ["session", "api", "pager", "acl", "currentTenant"]);
  }
  start(){
    return this.waitReady(["currentTenant", "session", "acl", "pager", "api"]).then(([ currentTenant ]) => {
      this._initializeService(currentTenant);
      this._currentTenant = currentTenant.current?._id;
      currentTenant.onServiceUpdated.addListener({
        handleEvent: (currentTenantService) => {
          if (this._currentTenant !== currentTenantService.current?._id)
          this._initializeService(currentTenantService)
        }
      });
    });
  }

  _initializeService(currentTenant) {
    this._releaseService = new ReleaseService(currentTenant.current?.settings.commercialOffer === "starter" ? "starter" : "admin", "web");
    this._releaseService.onServiceUpdated.addListener({
      handleEvent: (service, ...args) => this.triggerUpdate(...args)
    })
    this._releaseService.application = this.application;
    this._releaseService.initialize();
  }

  prepare() {
    return this._releaseService.prepare();
  }  
  
  hasAReleaseNotRead(){
    return this._releaseService.hasAReleaseNotRead();
  }
  updateLastSeen(){
    this._releaseService.updateLastSeen();
  }
  hasAReleaseSoon(){
    return this._releaseService.hasAReleaseSoon();
  }
  get next(){
    return this._releaseService.next;
  }
  get pager(){
    return this._releaseService.pager;
  }
}