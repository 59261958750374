import "./notification.css";
const addNotification = (trigger, searchHandler, onFound, dispose) => {
  trigger["@"] = {
    start: (capture) => {
      capture.persist();
    },
    update: (capture, ev) => {
      const text = capture.text;
      searchHandler(text.trim()).then(results => {
        if(results.length === 0 && text.length && [32, 160].indexOf(text.charCodeAt(text.length - 1)) !== -1){
          capture.release();
        }else{
          onFound(results, capture.element, (user) => {
            capture.replace("@" + user.fullname, true, "bs-editor-notification", { type: "User", data: { _id: user._id }});
          });
        }
      });
    },
    dispose: (capture, ev) => {
      dispose(capture);
    }
  };
  return trigger;
};

export default addNotification;