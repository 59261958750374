import dayjs, { Dayjs } from 'dayjs';
import _ from "lodash";
import data from '@uBusiness/data';

import BsDate from '@uTypes/technic/Date';

export type CorrepondingDayRule = (date: BsDate) => boolean;

export const orCombineRule = (...rules: CorrepondingDayRule[]): CorrepondingDayRule => {
  return (date: BsDate) => rules.reduce((valid, rule) => valid || rule(date), false);
};

export const andCombineRule = (...rules: CorrepondingDayRule[]): CorrepondingDayRule => {
  return (date: BsDate) => rules.reduce((valid, rule) => valid && rule(date), true);
};

export const combineRule = (operator: 'and' | 'or', ...rules: CorrepondingDayRule[]): CorrepondingDayRule => {
  return operator === 'and'
    ? andCombineRule(...rules)
    : orCombineRule(...rules);
};

interface CombinableRule extends CorrepondingDayRule {
  and: (rule: CorrepondingDayRule) => CombinableRule;
  or:  (rule: CorrepondingDayRule) => CombinableRule;
}

export const createCombinableRule = (rule: CorrepondingDayRule): CombinableRule => {
  return Object.assign(rule.bind(null), {
    and: (ruleToCombine: CorrepondingDayRule) => {
      return createCombinableRule(andCombineRule(rule, ruleToCombine));
    },
    or: (ruleToCombine: CorrepondingDayRule) => {
      return createCombinableRule(orCombineRule(rule, ruleToCombine));
    }
  });
};

export type RuleBuilder<T extends Array<unknown>> = (...args: T) => CombinableRule;

export const isDay: RuleBuilder<[number]> = (day) => createCombinableRule((date) => dayjs(date).date() === day);

export const isDayOfWeek: RuleBuilder<[number]> = (dayOfWeek) => createCombinableRule((date) => dayjs(date).day() === dayOfWeek);

export const isMonth: RuleBuilder<[number]> = (month) => createCombinableRule((date) => dayjs(date).month() === month);

export const isDayAndMonth: RuleBuilder<[number, number]> = (day, month) => isDay(day).and(isMonth(month));

//Algorithme Butcher-Meeus (https://fr.wikipedia.org/wiki/Calcul_de_la_date_de_P%C3%A2ques#:~:text=P%C3%A2ques%20est%20le%20dimanche%20qui,21%20mars%20ou%20imm%C3%A9diatement%20apr%C3%A8s.%20%C2%BB)
const getEasterDayOfTheYear = (year: number): [number, number] => {
  const metonCycle = year % 19;
  const yearRank = year % 100;
  const century = (year - yearRank) / 100;
  const nbrCenturySinceLastBisextileCentury = century % 4;
  const umpteenthCentury = (century - nbrCenturySinceLastBisextileCentury) / 4;
  const proemptosisCycle = Math.floor((century + 8) / 25);
  const proemptosis = Math.floor((century - proemptosisCycle + 1) / 3);
  const epact = (19 * metonCycle + century - umpteenthCentury - proemptosis + 15) % 30;
  const nbrYearSinceLastBisextileYearInCentury = yearRank % 4;
  const umpteenthBisextileYearInCentury = (yearRank - nbrYearSinceLastBisextileYearInCentury) / 4;
  const dominicalLetter = (2 * nbrCenturySinceLastBisextileCentury + 2 * umpteenthBisextileYearInCentury - epact - nbrYearSinceLastBisextileYearInCentury + 32) % 7;
  const correction = Math.floor((metonCycle + 11* epact + 22 * dominicalLetter) / 451);
  const baseCalc = epact + dominicalLetter - 7 * correction + 114;
  const dayOfYearSub1 = baseCalc % 31;
  const monthOfYear = (baseCalc - dayOfYearSub1) / 31;
  return [dayOfYearSub1 + 1, monthOfYear -1];
};

const getDateOfEasterDayOfTheYear = (date: Dayjs): Dayjs => {
  const year = date.year();
  const [day, month] = getEasterDayOfTheYear(year);
  return dayjs(new Date(year, month, day));
};

const isNewYearDay = isDayAndMonth(1, 0);
const isLaborDay = isDayAndMonth(1, 4);
const isFrenchVictory1945 = isDayAndMonth(8, 4);
const isEuropeDay = isDayAndMonth(9, 4);
const isLuxemburgNationalDay = isDayAndMonth(23, 5);
const isFrenchNationalDay = isDayAndMonth(14, 6);
const isBelgiumNationalDay = isDayAndMonth(21, 6);
const isAssumption = isDayAndMonth(15, 7);
const isAllSaintsDay = isDayAndMonth(1, 10);
const isArmistice1918 = isDayAndMonth(11, 10);
const isChristmasDay = isDayAndMonth(25, 11);
const isSaintStephenDay = isDayAndMonth(26, 11);

const isGoodFriday = createCombinableRule((date) => {
  return getDateOfEasterDayOfTheYear(dayjs(date)).subtract(2, 'day').isSame(date, 'date');
});
const isEasterDay = createCombinableRule((date) => {
  return getDateOfEasterDayOfTheYear(dayjs(date)).isSame(date, 'date');
});
//1 jour après Pâques
const isEasterMonday = createCombinableRule((date) => {
  return getDateOfEasterDayOfTheYear(dayjs(date)).add(1, 'day').isSame(date, 'date');
});
//40 jour après Pâques : https://fr.wikipedia.org/wiki/Ascension_(f%C3%AAte)
const isAscent = createCombinableRule((date) => {
  return getDateOfEasterDayOfTheYear(dayjs(date)).add(39, 'day').isSame(date, 'date');
});
//50 jour après Pâques : https://fr.wikipedia.org/wiki/Pentec%C3%B4te
const isPentecost = createCombinableRule((date) => {
  return getDateOfEasterDayOfTheYear(dayjs(date)).add(49, 'day').isSame(date, 'date');
});
const isPentecostMonday = createCombinableRule((date) => {
  return getDateOfEasterDayOfTheYear(dayjs(date)).add(50, 'day').isSame(date, 'date');
});

const isHolidayDays = {
  newYearDay: isNewYearDay,
  goodFriday: isGoodFriday,
  easterDay: isEasterDay,
  easterMonday: isEasterMonday,
  laborDay: isLaborDay,
  frenchVictory1945: isFrenchVictory1945,
  europeDay: isEuropeDay,
  luxemburgNationalDay: isLuxemburgNationalDay,
  frenchNationalDay: isFrenchNationalDay,
  belgiumNationalDay: isBelgiumNationalDay,
  assumption: isAssumption,
  allSaintsDay: isAllSaintsDay,
  armistice1918: isArmistice1918,
  christmasDay: isChristmasDay,
  saintStephenDay: isSaintStephenDay,
  ascent: isAscent,
  pentecost: isPentecost,
  pentecostMonday: isPentecostMonday,
};

export type HolidayDay = keyof typeof isHolidayDays;

type HolidayDaysType = {
  [Property in HolidayDay]: Property;
}

export const HolidayDays: Readonly<HolidayDaysType>  = Object.freeze(Object.keys(isHolidayDays).reduce((holidayDayNames, holidayDayName) => {
  holidayDayNames[holidayDayName] = holidayDayName;
  return holidayDayNames;
}, {}) as HolidayDaysType);

type GetHolidayDaysByCountry = (country: string | null) => HolidayDaysType;

export const getHolidayDayByCountry: GetHolidayDaysByCountry = (country) => {
  if (!country) {
    return HolidayDays;
  }
  let holidayKeys;
  switch(country) {
    case "fr":
      holidayKeys = data.frenchHolidayDays;
      break;
    case "be":
      holidayKeys = data.belgiumHolidayDays;
      break;
    case "lu":
      holidayKeys = data.luxembourgHolidayDays;
      break;
    default:
      throw ("Unknow country");
  }
  return _.pick(HolidayDays, holidayKeys);
}

type GetHolidayDayRuleType = (...holidayDayNames: Array<HolidayDay>) => CorrepondingDayRule;

export const getHolidayDayRule: GetHolidayDayRuleType = (...holidayDayNames) => {
  return orCombineRule(...holidayDayNames.map(holidayDayName => isHolidayDays[holidayDayName]));
};


type GetHolidayDayOfYear = (date: BsDate) => Dayjs;

export const getHolidayDaysOfTheYear: Record<HolidayDay, GetHolidayDayOfYear>  = {
  newYearDay: (date) => {
    return dayjs(new Date(dayjs(date).year(), 0, 1));
  },
  laborDay: (date) => {
    return dayjs(new Date(dayjs(date).year(), 4, 1));
  },
  frenchVictory1945: (date) => {
    return dayjs(new Date(dayjs(date).year(), 4, 8));
  },
  europeDay: (date) => {
    return dayjs(new Date(dayjs(date).year(), 4, 9));
  },
  luxemburgNationalDay: (date) => {
    return dayjs(new Date(dayjs(date).year(), 5, 23));
  },
  frenchNationalDay: (date) => {
    return dayjs(new Date(dayjs(date).year(), 6, 14));
  },
  belgiumNationalDay: (date) => {
    return dayjs(new Date(dayjs(date).year(), 6, 21));
  },
  assumption: (date) => {
    return dayjs(new Date(dayjs(date).year(), 7, 15));
  },
  allSaintsDay: (date) => {
    return dayjs(new Date(dayjs(date).year(), 10, 1));
  },
  armistice1918: (date) => {
    return dayjs(new Date(dayjs(date).year(), 10, 11));
  },
  christmasDay: (date) => {
    return dayjs(new Date(dayjs(date).year(), 11, 25));
  },
  saintStephenDay: (date) => {
    return dayjs(new Date(dayjs(date).year(), 11, 26));
  },
  goodFriday: (date) => {
    return getDateOfEasterDayOfTheYear(dayjs(date)).subtract(2, 'day');
  },
  easterDay: (date) => {
    return getDateOfEasterDayOfTheYear(dayjs(date));
  },
  easterMonday: (date) => {
    return getDateOfEasterDayOfTheYear(dayjs(date)).add(1, 'day')
  },
  ascent: (date) => {
    return getDateOfEasterDayOfTheYear(dayjs(date)).add(39, 'day')
  },
  pentecost: (date) => {
    return getDateOfEasterDayOfTheYear(dayjs(date)).add(49, 'day')
  },
  pentecostMonday: (date) => {
    return getDateOfEasterDayOfTheYear(dayjs(date)).add(50, 'day')
  },
};