import * as ActionTypes from 'client/features/calendar/actions'
import * as IssuesActionTypes from 'client/features/issues/actions'
import _ from 'lodash'
import { cloneDeep } from 'lodash'
import moment from 'moment'
import Timeline from "./timeline";
import { registerDefaultReducer, registerInitialstate } from "client/lib/reduxSaga";

const initialState = {
  mode: 'short_week',
  period: [],
  loading: false,
  initialized: false,
  lastDropped: null,
  loaded: {
    start: null,
    end: null
  },
  filters: {
    values: {},
    show: false
  },
  usersFilters: {
    values: {}
  },
  days: [],
  dicDays: {},
  confirmationAbsenceDisplay: false,
  assignments: []
}

export const initializeState = () => initialState;

const initCalendar = (state) => {
  const current   = moment(state.current).startOf('day');
  const calendar  = {
    dates: {
      start: current.clone().startOf('week'),
      end: current.clone().startOf('week').add(5, "day"),
      current: current
    },
    timeline: state.timeline ? state.timeline : new Timeline()
  };

  calendar.dicDays = setDays(calendar.dates.start, calendar.dates.end);
  calendar.days    = Object.values(calendar.dicDays);
  return getStateDates(Object.assign(state, calendar), current.clone(), state.mode);
}
registerInitialstate("calendar", initializeState);
registerDefaultReducer("calendar", function calendar(state = initializeState(), action = {}) {
  const { payload } = action
  if(action.type.startsWith("@@redux/INIT")){
    return initCalendar(state);
  }
  switch (action.type) {
    case ActionTypes.UPDATE_TIMELINE:
      return {
        ...state,
        timeline: action.timeline
      };

  case ActionTypes.IssuesRequest.get.ACTION:
    const dicDays = setDays(payload.start, payload.end)
    return {
      ...state,
      loading: true,
      dicDays: dicDays,
      days: Object.values(dicDays)
    }

  case ActionTypes.IssuesRequest.get.TYPES.FAILURE:
    return {
      ...state,
      loading: false
    }

  case ActionTypes.IssuesRequest.get.TYPES.REQUEST:
    return {
      ...state,
      initialized: false,
      loaded: {
        start: null,
        end: null
      }
    }

  case ActionTypes.IssuesRequest.get.TYPES.SUCCESS:
    setIssueToDays(state.dicDays, payload.all)
    return {
      ...state,
      assignments: _.uniqBy(payload.all.concat(state.assignments), "_id"),
      loading: false,
      initialized: true,
      loaded: setLoadedDates(state.loaded, payload.dates),
      lastLoaded: new Date().getTime()
    }

  // case ActionTypes.CALENDAR_SET_PERIOD:
  //   return {
  //     ...state,
  //     period: state.day
  //   }

  case ActionTypes.CALENDAR_SET_MODE:
    return getStateDates(state, state.dates.current, payload.mode);

  case ActionTypes.CALENDAR_NAV_DATE:
    return getStateDates(state, navDate(state, payload.sens), state.mode);

  case ActionTypes.CALENDAR_SET_DATE:
    return getStateDates(state, payload.date, state.mode);

  case ActionTypes.CALENDAR_UPDATE_DAYS:
    return {
      ...state,
      days: payload.days,
      lastDropped: payload.lastDropped
    }

  case ActionTypes.CALENDAR_REMOVE_LAST_DROPPED:
    return {
      ...state,
      lastDropped: null
    }

  case ActionTypes.SET_CALENDAR_FILTERS_VALUE:
    return {
      ...state,
      list: [],
      filters: {
        ...state.filters,
        values: setFiltersValues(state.filters.values, payload.key, payload.value)
      }
    }

  case ActionTypes.UNSET_CALENDAR_FILTER:
    return {
      ...state,
      filters: {
        ...state.filters,
        values: unsetFilter(state.filters.values, payload)
      }
    }

  case IssuesActionTypes.IssuesRequest.post.TYPES.SUCCESS:
  case IssuesActionTypes.IssueRequest.put.TYPES.SUCCESS:
    return {
      ...state,
      // days: checkNewIssue(state.days, payload)
    }

  case ActionTypes.SET_CALENDAR_USERS_FILTERS_VALUE:
    return {
      ...state,
      usersFilters: {
        ...state.usersFilters,
        values: setUsersFilters(state.usersFilters.values, action.payload)
      }
    }

  case "ISSUE_PLANNING_ABSENCE_CONFIRMATION_DISPLAY":
    return {
      ...state,
      confirmationAbsenceDisplay: true,
      confirmationAbsenceUser: action.user,
      confirmationAbsenceNextAction: action.nextAction
    };

  case "ISSUE_PLANNING_ABSENCE_CONFIRMATION_HIDE":
    return {
      ...state,
      confirmationAbsenceDisplay: false
    };

  case "ISSUE_PLANNING_VALIDATE_DROP":
    return {
      ...state,
      confirmationAbsenceDisplay: false
    };

  // case "calendarUpdateAssignment":
  //   return (() => {
  //     const { issueId, assignmentId, assignment } = action;
  //     const stateAssignment = state.assignments.find(a => a._id === assignmentId);
  //     stateAssignment.planification.scheduledFrom = assignment.scheduledFrom;
  //     stateAssignment.planification.scheduledTo   = assignment.scheduledTo;
  //     return state;
  //   })();
  // break;

  // case "calendarDeleteAssignment":
  //   return (() => {
  //     const { issueId, assignmentId } = action;
  //     const idx = state.assignments.findIndex(a => a._id === assignmentId);
  //     state.assignments.splice(idx, 1);
  //     return { ...state };
  //   })();
  // break;

  // case "calendarCreateAssignment":
  //   return (() => {
  //     const { issue, assignment } = action;
  //     return state;
  //   })();
  // break;

  default:
    return state
  }
})

const setUsersFilters = (usersFilters, payload) => {
  const newFilters = cloneDeep(usersFilters)
  if (!newFilters[payload.team])
    newFilters[payload.team] = { users: [] }
  const indexOfUser = newFilters[payload.team].users.indexOf(payload.user)
  if (indexOfUser === -1)
    newFilters[payload.team].users.push(payload.user)
  else
    newFilters[payload.team].users.splice(indexOfUser, 1)
  return newFilters
}

const setDays = (start, end) => {
  const now = moment();
  const dicDays = {};
  for (let i = new Date(start).getTime(); i < new Date(end).getTime(); i += 86400000) {
    const date = moment(i).startOf('day')
    dicDays[date.format("YYYYMMDD")] = {
      date:  date.toDate(),
      start: date.toDate(),
      end: date.clone().add(1, "d").toDate(),
      dateTime: date.valueOf(),
      issues: [],
      isToday: date.isSame(now, "day") 
    };
  }
  return dicDays;
}

const getStateDates = (state, current, mode) => {
  current       = moment(current).startOf('day');
  const p       = getPeriod(current, mode);
  const start   = moment(p.start);
  const end     = start.clone().add(p.nbrDay, "day");
  const dicDays = setDays(start.toISOString(), end.toISOString())
  return {
    ...state,
    dates: {
      start: start,
      end: end,
      current: current
    },
    dicDays: dicDays,
    days: Object.values(dicDays),
    period: Object.values(dicDays),
    loaded: {
      start: null,
      end: null
    },
    mode: mode
  }
}

const setIssueToDays = (dicDays, issues) => {
  issues.forEach((issue) => {
    const day = dicDays[moment(issue.planification.scheduledFrom).format("YYYYMMDD")];
    if (day && day.issues.indexOf(issue._id) === -1)
      day.issues.push(issue._id)
  })
}
const getPeriod = (date, mode) => {
  const current = moment(date);
  let nbrDay = 0;
  let start = null;
  switch(mode){
    case "3day": 
      nbrDay  = 3;
      start   = current.subtract(1, "d");
      break;
    case "short_week":
      nbrDay  = 5;
      start   = current.startOf('week');
      break;
    case "week":
      nbrDay  = 7;
      start   = current.startOf('week');
      break;
    case "month": 
      nbrDay  = current.daysInMonth();
      start   = current.startOf('month');
      break;
  }
  return { nbrDay, start };
};

const navDate = (state, sens) => {
  if (state.mode.match('week'))
    return new Date(moment(state.dates.current).add(sens, 'week'))
  return new Date(moment(state.dates.current).add(sens, state.mode === '3day' ? 'day' : state.mode))
}

const setFiltersValues = (values, key, value) => {
  values = { ...values }
  if (!values[key])
    values[key] = []
  else
    values[key] = [...values[key]]
  if (values[key].indexOf(value) === -1)
    values[key].push(value)
  else
    values[key].splice(values[key].indexOf(value), 1)

  return { ...values }
}

const unsetFilter = (values, key) => {
  if (values[key]) {
    values = _.omit(values, key)
  }

  return { ...values }
}

// const checkNewIssue = (days, payload) => {
//   if (!payload.issue || !payload.issue.planification || !payload.issue.planification.scheduledFrom)
//     return [...days]
//   days.forEach(day => {
//     const haveIssue = day.issues.findIndex(dayIssue => dayIssue === payload.issue._id)
//     if (haveIssue !== -1)
//       day.issues.splice(haveIssue, 1)
//   })
//   //const day = _.find(days, { dateTime: new Date(payload.issue.scheduledFrom).getTime() })
//   const day = getDay(days, payload.issue.planification.scheduledFrom)
//   if (!day)
//     return [...days]
//   if (day.issues.indexOf(payload.issue._id) === -1)
//     day.issues.push(payload.issue._id)
//   return [...days]
// }

const setLoadedDates = (loaded, dates) => {
  const start = loaded.start && new Date(loaded.start).getTime() < new Date(dates.start).getTime() ? loaded.start : dates.start
  const end = loaded.end && new Date(loaded.end).getTime() > new Date(dates.end).getTime() ? loaded.end : dates.end
  return {
    start,
    end
  }
}