import React, { FunctionComponent, PropsWithChildren } from "react";
import { Paginated } from "@cComponents/wizard";
import T             from "@uBehaviour/i18n";
import CTA           from "../../features/cta";
import Template      from "./template";
import PromotionalSubtitle from "./promotionalSubtitle";
import Text, { Style } from "@cComponents/text";
import Slot from "@universal/components/slot2";

import "./index.css";

const Image = Slot();

const NewIssueBase: FunctionComponent<PropsWithChildren> & { Image: typeof Image } = ({ children }) => {
  const image = Image.get(children);
  const restChildren = Image.not(children);

  return (
    <Template>
      <Template.Image>{ image }</Template.Image>
      <Template.Icon><span className="fa fa-plus bs-featuresDiscovery-newIssue-icon" /></Template.Icon>
      <Template.Title><Text style={ Style.huge.bold }><T>starter_featuresDiscovery_newIssue_title</T></Text></Template.Title>
      { restChildren }
    </Template>
  );
};
NewIssueBase.Image = Image;

const NewIssueCreation: FunctionComponent = () => {
  const focusedText = <Text style={ Style.bold }> <T>starter_featuresDiscovery_newIssue_creation_focus</T> </Text>;

  return (
    <NewIssueBase>
      <Image><img src="/images/starter/featuresDiscovery/newIssue_1.png" alt="Création de signalement"/></Image>
      <Text.Paragraph><T bind={{ focusedText }}>starter_featuresDiscovery_newIssue_creation</T></Text.Paragraph>
    </NewIssueBase>
  );
};


const NewIssueMobile: FunctionComponent = () => {
  const focusedText = <Text style={ Style.bold }><T>starter_featuresDiscovery_newIssue_mobile_focus</T></Text>;

  return (
    <NewIssueBase>
      <Image><img src="/images/starter/featuresDiscovery/newIssue_2.png" alt="Création de signalement"/></Image>
      <div className="bs-featuresDiscovery-content">
        <Text.Paragraph>
          <T bind={{ focusedText }}>starter_featuresDiscovery_newIssue_mobile</T>
        </Text.Paragraph>
      </div>
    </NewIssueBase>
  );
};


const NewIssueUpsale: FunctionComponent = () => (
  <NewIssueBase>
    <Image><img src="/images/starter/featuresDiscovery/newIssue_3.png" alt="Création de signalement"/></Image>
    <PromotionalSubtitle>
      <div className="bs-featuresDiscovery-content">      
        <Text>
          <T>starter_featuresDiscovery_newIssue_upsale</T>
        </Text>
        <span className="bs-featuresDiscovery-content-CTA">
          <CTA.DiscoverOffers.Orange />
        </span>
      </div>     
    </PromotionalSubtitle>
  </NewIssueBase>
);

type NewIssueProps = {
  close: () => void,
};
const NewIssue: FunctionComponent<NewIssueProps> = ({ close }) => (
  <Paginated close={ close } style={{ width: "920px", height: "540px" }}>
    <Paginated.Step>
      <NewIssueCreation />
    </Paginated.Step>
    <Paginated.Step>
      <NewIssueMobile />
    </Paginated.Step>
    <Paginated.Step>
      <NewIssueUpsale />
    </Paginated.Step>
  </Paginated>
);

export default NewIssue;