import React            from "react";
import { Route, Switch }        from "@cFeatures/router";
import Issues           from "@print/issues";
import AgentsCalendar   from "@print/agentsCalendar";
import Assignments      from "@print/assignments";
import TeamsCalendar    from "@print/teamsCalendar";
import Statistics       from "@print/statistics";
import TestPrint        from "@print/testPrint";
import useService       from "@uBehaviour/hooks/useService";
import UrlService       from "@cServices/url";

import "./print.css"; 

import "@cAssets/css/cursor-update.css";

const Print: React.FunctionComponent = () => {
  const url = useService<UrlService>("url");
  const p = url.params;

  return (
    <Switch>
      <Route path="/issue/:id" component={() =>  { 
        p.workOrder = "true"; 
        return React.createElement(Issues, p);
      }} />
      <Route path="/issues">
        <Issues { ...p } />
      </Route>
      <Route path="/assignments">
        <Assignments { ...p } />
      </Route>
      <Route path="/calendar/agents">
        <AgentsCalendar { ...p } />
      </Route>
      <Route path="/calendar/teams" >
        <TeamsCalendar { ...p } />
      </Route> 
      <Route path="/statistics">
        <Statistics { ...p } />
      </Route>
      <Route path="/test">
        <TestPrint { ...p } />
      </Route>
    </Switch>
  );
};

export default Print;