import React        from 'react';
import Wizard       from "@cComponents/wizard"; 
import Display      from "@uComponents/displayIf";
import Button       from "@cComponents/button";
import T            from '@cBehaviour/i18n';
import Form         from './form';
import Layout       from '@cComponents/layout';
import Papa         from 'papaparse';
import Nudge        from '@cComponents/nudge';
import Application  from "@uBehaviour/application";
import Scrollbar    from "@cComponents/scrollBar";
import Input        from "@cComponents/input";

import "./import.css";

class DisplayEquipment extends React.Component {
  constructor(props) {
    super(props);
    this._form = React.createRef();
    this._ready = false;
    this.state = {
      location: null,
      disabled: false
    }
  }

  _loadAddress() {
    this._ready = false;
    if(["", ","].includes(this.props.equipment.address.trim())){
      this._ready = true;
      return this.setState({
        location: null
      });
    }else{
      this.setState({ disabled: true });
      this.props.googleMap.getPositionFromAddress(this.props.equipment.address)
        .then(position => {
          const addressP = position ? this.props.googleMap.getAddressFromPosition(position) : Promise.resolve(null);
          addressP.then(address => {
            this._ready = true;
            window.setTimeout(() => this.setState({ disabled: false }), 2500);
            return this.setState({
              location: {
                position: position,
                address: address
              }
            });
          });   
        }); 
    }
  }
  componentDidMount() {
    this._loadAddress();
  }
  componentDidUpdate(prevProps){
    if(prevProps.equipment !== this.props.equipment){
      this._loadAddress();
    }
  }
  shouldComponentUpdate(nextProps){
    if(nextProps.equipment !== this.props.equipment){
      this._ready = false;
    }
    return true;
  }

  render() {
    const { wizard, equipment, index } = this.props;
    
    const vEquipement = {
      name: equipment.name,
      location: this.state.location,
    };
    if(equipment.unit || equipment.cost){
      vEquipement.valorization = {
        type: equipment.unit === "Kilomètres" ? "km" : "h",
        cost: equipment.cost * 100
      };
    }
    return (
      !this._ready
        ? <div>Loading</div>
        : (
          <Layout.Standart stretch={true}>
            <Layout.Standart.Header>
              <div className="bs-equipments-display-title">
                <Nudge>
                  <div><T bind={{ name: equipment.name }}>equipment_import_name</T></div>
                  <div><T bind={{ address: equipment.address }}>equipment_import_address</T></div>
                  <div><T bind={{ unit: equipment.unit }}>equipment_import_unit</T></div>
                  <div><T bind={{ cost: equipment.cost ? `${equipment.cost} €` : null }}>equipment_import_cost</T></div>
                </Nudge>
              </div>
            </Layout.Standart.Header>
            <Layout.Standart.Content>
              <Form 
                ref={this._form}
                name={"equipment" + index}
                value={vEquipement} 
              />            
            </Layout.Standart.Content>
            <Layout.Standart.Footer>
                <div className="bs-equipments-import-actions">
                  <Button.Text disabled={this.state.disabled} onClick={() => wizard.previous()}><T>previous</T></Button.Text>
                  <Button.Text disabled={this.state.disabled} onClick={() => { this.props.passEquipment(equipment); wizard.next() }}><T>pass</T></Button.Text>
                  <Button.Text disabled={this.state.disabled} onClick={() => this._form.current.submit(true).then(() => { this.props.addEquipment(equipment); wizard.next() }).catch(() => null)}><T>validate</T></Button.Text>
                </div>                
            </Layout.Standart.Footer>
          </Layout.Standart>  
        )   
    )
  }
} 

const DisplayEquipmentWithGoogleService = Application.Service.forward([["google-map", "googleMap"]], DisplayEquipment)

class Import extends React.Component {
  constructor(props) {
    super(props);
    this._extractFromCsv = this._extractFromCsv.bind(this);
    this._passEquipment = this._passEquipment.bind(this);
    this._addEquipment = this._addEquipment.bind(this);
    this._passedEquipments = [];
    this._addedEquipments = [];
    this.state = {
      equipments: null,
      parsingError: null,
      extractionErrors: null,
      encoding: "ISO-8859-15"
    }
  }

  _extractFromCsv(ev) {
    Papa.parse(ev.target.files[0], {
      header: false,
      encoding: this.state.encoding,
      skipEmptyLines: true,
      complete: result => {
        if (result.data.length) {
          this.setState({
            equipments: result.data.slice(4).reduce((equipments, line) => {
              if (line[2].length) {
                equipments.push({
                  name:line[2],
                  address: line[7],
                  unit: line[8],
                  cost: parseFloat(line[9].replace(',', "."))
                })
              }              
              return equipments;
            }, [])})
        } else if (result.errors.length) {
          this.setState({extractionErrors: result.errors})
        } else {
          this.forceUpdate()
        }
      },
      error: (error, file) => this.setState({
        parsingError: error
      })
    });
  }

  _passEquipment(equipment) {
    if(!this._addedEquipments.includes(equipment) && !this._passedEquipments.includes(equipment)){
      this._passedEquipments.push(equipment);
    }
  }

  _addEquipment(equipment) {
    const idx = this._passedEquipments.indexOf(equipment);
    if(idx !== -1){
      this._passedEquipments.splice(idx, 1);
    }
    if(!this._addedEquipments.includes(equipment)){
      this._addedEquipments.push(equipment);
    }
  }

  _encodingChange(input) {
    
  }

  render() {
    return (
      <Wizard close={this.props.onClose} style={{ width: "80vw", height: "90vh" }}>
        <Wizard.Step>
          {(context, wizard) => {
            return (
              <Layout.Standart stretch={true}>
                <Layout.Standart.Header>
                  <div className="bs-equipments-import-title"><T>equipment_import_title</T></div>
                </Layout.Standart.Header>
                <Layout.Standart.Content>
                  <div className="bs-equipments-import-body">
                    <div className="bs-equipments-import-label"><T>equipment_import_label</T> *</div>
                    <input type="file" accept=".csv" onChange={this._extractFromCsv} />
                    <div className="bs-equipments-encoding-label"><T>equipment_encoding_label</T></div>
                    <Input.Radio.Btn inline value={ this.state.encoding } onChange={(input) => this.setState({encoding: input})}>
                      <Input.Radio.Value value="ISO-8859-15"><div>ISO-8859-15 (format windows)</div></Input.Radio.Value>
                      <Input.Radio.Value value="UTF-8"><div>UTF-8</div></Input.Radio.Value>
                    </Input.Radio.Btn>                   
                    <Display.If condition={this.state.equipments}>
                      {() => (
                        <div className="bs-equipments-import-body-extracted">
                          {this.state.equipments.length 
                            ? <T bind={{length: this.state.equipments.length}}>equipment_import_extracted_success</T>
                            : <T>equipment_import_extracted_no_equipments</T>
                          }
                        </div>
                      )}                    
                    </Display.If>
                    <Display.If condition={this.state.parsingError}>
                      <div className="bs-equipments-import-body-error">{this.state.parsingError}</div>
                    </Display.If>
                    <Display.If condition={this.state.extractionErrors}>
                      {()=> (
                        <div className="bs-equipments-import-body-error">
                          <ul>
                            {this.state.extractionErrors.map(error => (<li><T bind={{...error, row: error.row ? error.row : 1}}>equipment_import_extraction_error</T></li>))}
                          </ul>
                        </div>
                      )}
                    </Display.If>
                  </div> 
                </Layout.Standart.Content>
                <Layout.Standart.Footer>
                  <div className="bs-equipments-import-actions">
                    <Button.Text onClick={this.props.onClose}><T>cancel</T></Button.Text>
                    <Button.Text disabled={!this.state.equipments} onClick={() => wizard.next()}><T>start_integration</T></Button.Text>
                  </div>
                </Layout.Standart.Footer>
              </Layout.Standart>      
            )
          }}
        </Wizard.Step>
        {this.state.equipments && this.state.equipments.length && this.state.equipments.map((equipment, idx) => (
          <Wizard.Step key={ 'form' + idx}>
            {(context, wizard) => (<DisplayEquipmentWithGoogleService index={ idx } equipment={equipment} wizard={wizard} addEquipment={this._addEquipment} passEquipment={this._passEquipment} />)}
          </Wizard.Step>
        ))}
        <Wizard.Step>
          {(context, wizard) => (
            <Layout.Standart stretch={true}>
              <Layout.Standart.Header>
                <div className="bs-equipments-import-title"><T>equipment_import_title</T></div>
              </Layout.Standart.Header>
              <Layout.Standart.Content>
                <Scrollbar>
                  <div className="bs-equipments-import-body">
                    <div className="bs-equipment-import-label"><T>equipment_import_file_end</T></div>
                    <div className="bs-equipments-import-abstract-container">
                      <div><T>equipment_import_added</T></div>
                      <ul>
                        {this._addedEquipments.map(equipment => (<li>{`${equipment.name} (${equipment.address})`}</li>))}
                      </ul>
                    </div>
                    <div className="bs-equipments-import-abstract-container">
                      <div><T>equipment_import_passed</T></div>
                      <ul>
                        {this._passedEquipments.map(equipment => (<li>{`${equipment.name} (${equipment.address})`}</li>))}
                      </ul>
                    </div>
                  </div>
                </Scrollbar>
              </Layout.Standart.Content>
              <Layout.Standart.Footer>
              <div className="bs-equipments-import-actions">
                <Button.Text onClick={() => wizard.previous()}><T>previous</T></Button.Text>
                <Button.Text onClick={this.props.onClose}><T>end_integration</T></Button.Text>
              </div>                
              </Layout.Standart.Footer>
            </Layout.Standart>
          )}
        </Wizard.Step>
      </Wizard>
    )
  }
}

export default Import;