import React from 'react'
import classNames from 'classnames'
import PartialHTML from 'client/features/common/components/PartialHTML.jsx'
import FontAwesome from 'react-fontawesome'
import './address.css'

//SubBuilding or freetext
const getSecondaryAddressLine = (place, locationInfo, roadInfo, address, short, full) => {
  if (place && place._id){
     if(place.externalLink){
      return (
        <span>
          <span>&nbsp;/&nbsp;</span>
          <a href={place.externalLink} onClick={e => e.stopPropagation()} target='_blank' rel="noreferrer" className={ classNames('c-address__item bs-overlay-view-address-external-link')}>
            {`${place.label}`}
          </a>
        </span>
      )}
      else { return ( <div className={ classNames('c-address__item', { 'u-margin-left-tiny': !full }) }>{`/ ${place.label}`}</div> )}
  }
  else if (locationInfo && locationInfo[0]) {
    return (
      <div className={ classNames('c-address__item', { 'u-margin-left-tiny': !full }) }>{`/ ${locationInfo}`}</div>
    )
  }
  return
}

const renderIcon = (type) => {
  if (!type)
    return
  let icon = 'map-marker'
  if (type === 'building')
    icon = 'building-o'
  else if (type === 'patrimony')
    icon = 'wrench'
  return <span><FontAwesome name={ icon }/>&nbsp;</span>
}


const OverlayViewAddress = ({ place, building, locationInfo, roadInfo, address, className = '', highlight, short, cut, icon, full, inline, type, subAddress }) => {
  if (short) {
    return (
      <div className={ classNames('c-address', 'c-address---short', className) }>
        {
          renderIcon(type)
        }
        <PartialHTML content={ address } highlight={ highlight } inline/>
        {
          subAddress && <p>{ subAddress }</p>
        }
      </div>
    )
  }
  return (
    <div className={ classNames('c-address', { 'c-address--full': full && !inline }, className, icon) }>
      {
        roadInfo &&
        <div className={ classNames('c-address__item', { 'u-margin-right-tiny': !full }) }>
          {
            renderIcon(type)
          }
          <PartialHTML content={ roadInfo } highlight={ highlight } inline/>
        </div>
      }
     
        <div className='c-address__item'>
          {
            !roadInfo && renderIcon(type)
          }
          { !building?.externalLink ? <PartialHTML content={ address } highlight={ highlight } inline/> : 
            <a href={building.externalLink} onClick={(e) => e.stopPropagation() } target='_blank' rel="noreferrer" className='bs-overlay-view-address-external-link'>
              <PartialHTML content={ address } highlight={ highlight } inline/>
            </a>
          } 
        </div>
      { getSecondaryAddressLine(place, locationInfo, roadInfo, address, full) }
      {
        subAddress && <br />
      }
      {
        subAddress && <div className='c-address__item' >{ subAddress }</div>
      }
    </div>
  )
}

export default OverlayViewAddress