import { fork, takeLatest, select, put } from 'redux-saga/effects'

import RequestSagas from 'client/core/RequestSagas'
import * as StatisticsActions from 'client/features/statistics/actions'

import URLS from 'constants/URLS'

/*Fetch Stats*/
export const getStatsSagas = new RequestSagas(StatisticsActions.StatsRequest.get, {
  method: 'GET',
  endpoint: URLS.statistics.get
})
getStatsSagas.success = function* (action) {

}
getStatsSagas.failure = function* () {

}

const getStats = function * (action) {
  if (action.type === StatisticsActions.SET_STATS_FILTERS_VALUE && action.payload.value === 'custom')
    return
  const filters = yield select((state) => state.statistics.filters.values)
  yield put(StatisticsActions.StatsRequest.get.action({
    payload: { filters },
    actionData: { filters }
  }))
}

export const getStatsSaga = function * () {
  yield takeLatest([
    StatisticsActions.SET_STATS_FILTERS_VALUE,
    StatisticsActions.RESET_STATS_FILTERS_VALUES,
    StatisticsActions.UNSET_STATS_FILTER_VALUE
  ], getStats)
}

const sagas = [
  fork(getStatsSaga),
  fork(getStatsSagas.saga)
]

export default sagas