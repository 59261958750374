import React, { FunctionComponent, useState }  from "react";
import T                    from '@cBehaviour/i18n'; 
import DropDown             from '@cComponents/dropDown';
import Release              from "@cEntities/release";
import Display              from "@cComponents/displayIf";
import useHelper from "@universal/behaviour/hooks/useHelper";
import AssistanceHelper from "@universal/helpers/assistance";
import CurrentTenantService from "@uServices/currentTenant";
import useService           from "@uBehaviour/hooks/useService";
import useOpenCloseToggle   from "@uBehaviour/hooks/useOpenCloseToggle";
import { useMatcher }       from "@uFeatures/router";
import StarterDemo          from "@features/starterGuide";


const Help: FunctionComponent = () => {
  const assistance = useHelper<AssistanceHelper>("assistance");
  const [displayRelease, setDisplayRelease] = useState<Boolean>(false);
  const [displayStarterGuide, openStarterGuide, closeStarterGuide] = useOpenCloseToggle();
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const { match: pathToSettings } = useMatcher("/settings");

  const openDocumentation = React.useCallback(() => {
    if(!!pathToSettings) {
      window.open("https://betterstreet.gitbook.io/betterstreet-console-d-administration/")
    }
    else if(currentTenant.current?.settings.commercialOffer === "starter") {
      window.open("https://betterstreet.gitbook.io/betterstreet-starter/")
    }
    else{
      window.open("https://betterstreet.gitbook.io/workspace/")
    }
  }, [currentTenant, pathToSettings]);

  const openAssistance = React.useCallback(async () => await assistance.open(), [assistance]);  

  const application = currentTenant.current?.settings.commercialOffer === "starter" ? "starter" : "admin";

  return (
    <>
      <DropDown.List alignRight={ true }>
        <DropDown.List.Main>
        <img 
          alt="BetterStreet" 
          src={'/images/ico-help.png'} style={{ 
          width: "25px",
          height: "25px"
        }}/>
        </DropDown.List.Main>
        <DropDown.List.List>
          <div onClick={ openAssistance }>
            <span className="fa fa-envelope"/>
            <span>&nbsp;<T>send_message</T></span>
          </div>
          <div onClick={ openDocumentation }>
            <span className="fa fa-book"/>
            <span>&nbsp;<T>documentation</T></span>
          </div>
          { currentTenant.current?.settings.commercialOffer === "starter" ?
            <div onClick={ openStarterGuide }>
              <span className="fa fa-users"/>
              <span>&nbsp;<T>help_starterDemo</T></span>
            </div>
            : <></>
          } 
          <div onClick={() => setDisplayRelease(true) }>
            <span className="fa fa-gitlab"/>
            <span>&nbsp;<T>release_note</T></span>
          </div>
        </DropDown.List.List>
      </DropDown.List>
      <Display.If  condition={displayRelease}>
      {() => (
        <Release.List application={application} close={() => { setDisplayRelease(false); }}/>
      )}
      </Display.If>
      <Display.If  condition={ displayStarterGuide }>
      {() => (
        <StarterDemo close={ closeStarterGuide }/>
      )}
      </Display.If>
     
    </>
  );
};

export default Help;