// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-input-old-file-thumb{
    background: #F5F5F5;
    display: flex;
    align-items: center;
    position: relative;
}

.bs-old-input-old-file-thumb:nth-child(n+2) {
    margin-top: 15px;
}

.bs-old-input-old-file-thumb>*{
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.bs-old-input-old-file-add:hover {
    cursor: pointer;
}

.bs-old-input-old-file-add>form>input{
    display: none;
}
.bs-old-input-old-file-add{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.bs-old-input-old-file-add>form>i{
    font-size: 50px;
    height: 50px;
    width: 50px;
    color: #555555;
    margin: 5px;
}
.bs-old-input-old-file-remove {
    position: absolute;
    top: 0px;
    right: 0px;
    height: 30px;
    width: 30px;
    transform: translate(50%, -50%);
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 20px;
    display: flex;
    box-shadow: 0px 1px 8px 0px black;
}
.bs-old-input-old-file-remove:hover{
    box-shadow: 0px 1px 8px 0px #3d3c3c;
    background-color: rgba(255, 255, 255, 1);
    cursor: pointer;
}
.bs-old-input-old-file-remove>span{
    margin: auto;
}`, "",{"version":3,"sources":["webpack://./../common/components/old/input/file.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;AACjB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,YAAY;IACZ,WAAW;IACX,cAAc;IACd,WAAW;AACf;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,YAAY;IACZ,WAAW;IACX,+BAA+B;IAC/B,kBAAkB;IAClB,0CAA0C;IAC1C,eAAe;IACf,aAAa;IACb,iCAAiC;AACrC;AACA;IACI,mCAAmC;IACnC,wCAAwC;IACxC,eAAe;AACnB;AACA;IACI,YAAY;AAChB","sourcesContent":[".bs-old-input-old-file-thumb{\n    background: #F5F5F5;\n    display: flex;\n    align-items: center;\n    position: relative;\n}\n\n.bs-old-input-old-file-thumb:nth-child(n+2) {\n    margin-top: 15px;\n}\n\n.bs-old-input-old-file-thumb>*{\n    display: block;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.bs-old-input-old-file-add:hover {\n    cursor: pointer;\n}\n\n.bs-old-input-old-file-add>form>input{\n    display: none;\n}\n.bs-old-input-old-file-add{\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n}\n.bs-old-input-old-file-add>form>i{\n    font-size: 50px;\n    height: 50px;\n    width: 50px;\n    color: #555555;\n    margin: 5px;\n}\n.bs-old-input-old-file-remove {\n    position: absolute;\n    top: 0px;\n    right: 0px;\n    height: 30px;\n    width: 30px;\n    transform: translate(50%, -50%);\n    border-radius: 50%;\n    background-color: rgba(255, 255, 255, 0.9);\n    font-size: 20px;\n    display: flex;\n    box-shadow: 0px 1px 8px 0px black;\n}\n.bs-old-input-old-file-remove:hover{\n    box-shadow: 0px 1px 8px 0px #3d3c3c;\n    background-color: rgba(255, 255, 255, 1);\n    cursor: pointer;\n}\n.bs-old-input-old-file-remove>span{\n    margin: auto;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
