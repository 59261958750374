// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-contractable-display {

}
.bs-contractable-hidden {
  display: none;
}`, "",{"version":3,"sources":["webpack://./../common/components/contractable.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;EACE,aAAa;AACf","sourcesContent":[".bs-contractable-display {\n\n}\n.bs-contractable-hidden {\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
