import { fork, takeLatest, put, select, call, takeEvery, delay } from 'redux-saga/effects'
import { initialize } from 'redux-form'
import _ from 'lodash'
import {
  addIssueConfirmation,
  CLOSE_ISSUE,
  GO_TO_ISSUE,
  IssueMatchFilters,
  MessengerIssueRequest,
  MessengerIssuesRequest,
  IssueRequest,
  IssueStarterRequest,
  IssuesAutocompleteRequest,
  IssuesRequest,
  LOAD_ISSUES_FILTERS,
  NearsRequest,
  NewIssueFilesRequest,
  ONLY_SEARCH_FILTERS_VALUE,
  RecurrencePreviewRequest,
  removeIssueConfirmation,
  REQUEST_ISSUES,
  RESET_ISSUES_FILTERS_SEARCH,
  RESET_ISSUES_FILTERS_VALUES,
  SaveIssuesFiltersRequest,
  SET_ISSUES_FILTERS_VALUE,
  setCurrentIssue,
  UNSET_ISSUES_FILTER_VALUE,
  ADD_ISSUE_CONFIRMATION,
  RecurrenceRequest,
  displayUpdateRecurrenceModal,
  NEW_ISSUE_SUBMIT,
  newIssueShowLoader,
  IssuesExportCSVRequest,
  addIssueAlert,
  removeIssueAlert,
  ADD_ISSUE_ALERT,
  ServiceFilesRequest,
  clearIssues
} from 'client/features/issues/actions'
import {
  checkUpdate as homeCheckUpdate
} from 'client/features/home/sagas'
import * as Flashes from 'client/features/issues/contents/flashes'
import { flash } from 'client/features/flashes/actions'
import { makeEndpoint } from 'client/core/api'
import RequestSagas from 'client/core/RequestSagas'
import URLS from 'constants/URLS'
import ISSUE from 'common/ISSUE'
import { cloneDeep } from 'lodash'
import { fetchApi } from "../../core/api"

/*Fetch Issue*/
export const getIssueSagas = new RequestSagas(IssueRequest.get, { method: 'GET', endpoint: URLS.issues.getOne })
getIssueSagas.success = function* (action) {
  const noRedirect = action.payload.actionData && action.payload.actionData.noRedirect
  if (!noRedirect)
    yield put(setCurrentIssue(action.payload.issue._id))
}
getIssueSagas.failure = function* () {

}

/*Fetch Starter Issue*/
export const getStarterIssueSagas = new RequestSagas(IssueStarterRequest.get, { method: 'GET', endpoint: URLS.issues.getOneStarter })
getStarterIssueSagas.success = function* (action) {
  yield put(setCurrentIssue(action.payload.issue._id))
}

/*Fetch issues*/
export const getIssuesSagas = new RequestSagas(IssuesRequest.get, { method: 'GET', endpoint: URLS.issues.get + "?ids=:ids" })
getIssuesSagas.success = function* (action) {
  const pathname = yield select((state) => window.location.pathname)
  if (action.payload.actionData && action.payload.actionData.isSearch && action.payload.count === 1) {
    yield put(setCurrentIssue(action.payload.issues[0]._id))
  }
}

getIssuesSagas.failure = function* () {

}

export const IssuesAutocompleteRequestSagas = new RequestSagas(IssuesAutocompleteRequest.get, { method: 'GET', endpoint: URLS.issues.autocomplete })


/*Update issue */
export const putIssueSagas = new RequestSagas(IssueRequest.put, { method: 'PUT', endpoint: URLS.issues.put })
putIssueSagas.success = function* (action) {
  if (action.payload && action.payload.type && action.payload.type.match(/starter/i)) {
    let starterUser = yield select((state) => state.session.starterUser)
    if (!starterUser)
      starterUser = {}
    yield put(initialize('issueStarterState', { ...starterUser, state: action.payload.issue.state.value }))
    return
  }
  if (!action.payload.live) {
    const pathname = yield select((state) => window.location.pathname)
    if (action.payload.type === 'state' && (pathname === URLS.issues.list || pathname === URLS.issues.index || pathname === URLS.issues.light || pathname === URLS.issues.calander))
      yield put(flash({ content: Flashes.updateStateSuccess(action.payload.issue, action.payload.log) }))
  }
  if (action.payload.type !== 'calendarIndex') {
    const homeInitialized = yield select((state) => state.home.initialized)
    if (homeInitialized)
      yield homeCheckUpdate(action)
  }
  if (action.payload.issue.recurrence && !action.payload.issue.recurrence.deleted && !action.payload.issue.recurrence.finished && ISSUE.recurrenceUpdates.indexOf(action.payload.type) !== -1) {
    yield put(displayUpdateRecurrenceModal({ type: action.payload.type }))
  }

  if (action.payload.type === 'address' || action.payload.type === 'building') {
    yield put(IssueRequest.get.action({ params: { id: action.payload.issue._id }, payload: { noLogs: true } }))
  }

  if (action.payload.alerts) {
    yield addAlerts(action.payload.alerts)
  }

}
putIssueSagas.failure = function* () {}

/*Alerts*/
const delayAlert = function * (action) {
  yield delay(15000)
  yield put(removeIssueAlert({ _id: action.payload._id }))
}
const catchNewAlert = function * () {
  yield takeEvery(ADD_ISSUE_ALERT, delayAlert)
}
const addAlerts = function* (alerts) {
  for (const alert of alerts) {
    yield put(addIssueAlert(alert))
  }
}

/*UpdateIssues*/
export const putIssuesSaga = new RequestSagas(IssuesRequest.put, { method: 'PUT', endpoint: URLS.issues.create }, { effect: 'takeEvery' })

/*Create issue */
export const postIssuesSagas = new RequestSagas(IssuesRequest.post, { method: 'POST', endpoint: URLS.issues.create })
postIssuesSagas.success = function* (action) {
  if (action.payload.accessRefused) {
    yield put(flash({ content: Flashes.issueCreated(action.payload.issue) }))
  } else if (!action.payload.live && !action.payload.isSlave && (!action.payload.actionData || !action.payload.actionData.noRedirect)) {
    const lastMain = yield select((state) => state.app.lastMain)
  }
  else if (!action.payload.live && action.payload.isSlave)
  
  if (!action.payload.live && action.payload.isSlave)
    yield put(flash({ content: Flashes.slaveIssueCreated(action.payload.issue) }))
  yield getIssues({ payload: { issue: action.payload.issue._id } })
  const homeInitialized = yield select((state) => state.home.initialized)
  if (homeInitialized)
    yield homeCheckUpdate(action)
}
postIssuesSagas.failure = function* () {

}

const tryPost = function * (action) {
  const uploadInProgress = yield select((state) => state.issues.newIssueUploadFileInProgress)
  if (!uploadInProgress) {
    const pictures = yield select((state) => state.form.issue.values.pictures)
    if (pictures)
      action.payload.pictures = pictures
    yield put(IssuesRequest.post.action({ payload: action.payload }))
  } else {
    yield delay(100)
    yield tryPost(action)
  }
}

export const checkPostIssue = function * (action) {
  yield put(newIssueShowLoader())
  yield tryPost(action)
}

export const createIssueSaga = function * () {
  yield takeLatest([
    NEW_ISSUE_SUBMIT
  ], checkPostIssue)
}


/*Check if issue match filters*/
export const getIssueMatchFilters = new RequestSagas(IssueMatchFilters.get, { method: 'GET', endpoint: URLS.issues.get }, { effect: 'takeEvery' })


/*Get nears*/
export const getNearsIssues = new RequestSagas(NearsRequest.get, { method: 'GET', endpoint: URLS.issues.nears })

/*Recurrence*/
export const recurrencePreviewSagas = new RequestSagas(RecurrencePreviewRequest.post, { method: 'POST', endpoint: URLS.issues.recurrencePreview })

/*Set Filters*/
const getFilters = (state) => state.issues.filters
const getIssues = function* (action) {
  if (action.payload && action.payload.options && action.payload.options.limit && action.payload.options.limit > 200)
    yield put(clearIssues())
  let RequestAction = IssuesRequest.get.action
  const { filters, pathname, selectActive, user } = yield select(state => ({
    filters: state.issues.filters,
    pathname: window.location.pathname,
    selectActive: state.issues.selectActive,
    user: state.session.user
  }));
  const actionData = {}
  const options = action.payload && action.payload.options ? action.payload.options : {}
  let values = cloneDeep(filters.values)
  if (action.payload && action.payload.issue) {
    values = { ...values, issue: action.payload.issue }
    RequestAction = IssueMatchFilters.get.action
    actionData.checkIssue = action.payload.issue
  }
  if (pathname !== URLS.calendar.show && values.onlyScheduled)
    values = { ...values, onlyScheduled: false }
  if (pathname !== URLS.issues.map && values.bounds)
    values = { ...values, bounds: false }
  if (pathname === URLS.issues.map)
    options.limit = 1500
  if (user && user.agentProfile)
    options.limit = 200
  if (selectActive && options.limit < 50)
    options.limit = 50

  if (action.type === SET_ISSUES_FILTERS_VALUE && action.payload.key === 'search')
    actionData.isSearch = true

  if (pathname.match('map'))
    values.haveLocation = true

  let sort = action.payload && action.payload.options && action.payload.options.forceSort ? action.payload.options.forceSort : filters.sort
  if (!sort)
    sort = 'created'
  yield put(RequestAction({
    payload: {
      filters: values,
      limit: options.limit || 18,
      sort,
      skip: options.skip || 0,
      options
    },
    actionData
  }))
}

export const getIssuesSaga = function * () {
  // const ret = takeLatest(action => {
  //   console.log("getIssuesSaga action");
  //   console.log(action);
  //   return [
  //     SET_ISSUES_FILTERS_VALUE,
  //     UNSET_ISSUES_FILTER_VALUE,
  //     ONLY_SEARCH_FILTERS_VALUE,
  //     RESET_ISSUES_FILTERS_VALUES,
  //     RESET_ISSUES_FILTERS_SEARCH,
  //     LOAD_ISSUES_FILTERS,
  //     REQUEST_ISSUES
  //   ].indexOf(action.type) !== -1;
  // }, getIssues);
  // console.log("getIssuesSaga initialize 2");
  // yield ret;
  yield takeLatest([
    SET_ISSUES_FILTERS_VALUE,
    UNSET_ISSUES_FILTER_VALUE,
    ONLY_SEARCH_FILTERS_VALUE,
    RESET_ISSUES_FILTERS_VALUES,
    RESET_ISSUES_FILTERS_SEARCH,
    LOAD_ISSUES_FILTERS,
    REQUEST_ISSUES
  ], getIssues)
}

/*Save Filters*/
export const saveIssuesFiltersSagas = new RequestSagas(SaveIssuesFiltersRequest.post, { method: 'POST', endpoint: URLS.issues.saveFilters })

export const updateIssuesFiltersSagas = new RequestSagas(SaveIssuesFiltersRequest.put, { method: 'PUT', endpoint: URLS.issues.updateFilters })

export const deleteIssuesFiltersSagas = new RequestSagas(SaveIssuesFiltersRequest.delete, { method: 'DELETE', endpoint: URLS.issues.updateFilters })


/*Upload files*/
export const postIssueFilesSagas = new RequestSagas(NewIssueFilesRequest.post, { method: 'POST', endpoint: URLS.files.fileStorage }, { effect: 'takeEvery', progress: true })
postIssueFilesSagas.success = function* (action) {

}

export const postIssueServiceFilesSagas = new RequestSagas(ServiceFilesRequest.post, { method: 'POST', endpoint: URLS.files.fileStorage }, { effect: 'takeEvery', progress: true })

/*Confirmations*/

const manageConfirmation = function * (action) {
  if (action.payload.action === 'request')
    return
  let time = 3000
  /*TODO: refactor set time in action*/
  if (action && action.payload && action.payload.type === 'starterState')
    time = 8000

  yield delay(time)
  yield put(removeIssueConfirmation(action.payload))
}

const catchConfirmation = function * (action) {
  yield takeEvery(ADD_ISSUE_CONFIRMATION, manageConfirmation)
}

const manageSuccessConfirmation = function * (action) {
  if (action.payload.live)
    return
  const confirmation = {
    action: 'success',
    date: new Date().getTime(),
    message: action.payload.message,
    issue: action.payload.issue._id,
    type: action.payload.type
  }
  yield put(addIssueConfirmation(confirmation))
  if (action.payload.otherActions) {
    yield action.payload.otherActions.map(function * (type, index) {
      const data = cloneDeep(confirmation)
      data.type = type
      yield put(addIssueConfirmation(data))
    })
  }
}

const manageRequestConfirmation = function * (action) {
  const confirmation = {
    action: 'request',
    issue: action.params.id,
    type: action.params.type
  }
  yield put(addIssueConfirmation(confirmation))
}

export const issuesSuccessConfirmationsSaga = function * () {
  yield takeEvery(IssueRequest.put.TYPES.SUCCESS, manageSuccessConfirmation)
}

export const issuesRequestConfirmationsSaga = function * () {
  yield takeEvery(IssueRequest.put.ACTION, manageRequestConfirmation)
}

/*Navigation*/
const goToIssue = function * (action) {
  const currentPath = yield select((state) => window.location.pathname)
  const match = /(.*)\/issues\/(.*)/.exec(currentPath)
  const replace = match && match[1] ? match[1].substring(1) : currentPath.substring(1)
  yield put(setCurrentIssue(action.payload.issue.issue || action.payload.issue._id))
}
export const goToIssueSaga = function * () {
  yield takeLatest(GO_TO_ISSUE, goToIssue)
}

const closeIssue = function * (action) {
  const currentPath = yield select((state) => window.location.pathname)
  const match = /(.*)\/issues\/(.*)/.exec(currentPath)
  const resource = match && match[1].slice(0, -1) ? match[1] : URLS.issues.list
}

export const closeIssueSaga = function * () {
  yield takeLatest(CLOSE_ISSUE, closeIssue)
}

/*Recurrence*/
export const deleteRecurrence = new RequestSagas(RecurrenceRequest.delete, { method: 'DELETE', endpoint: URLS.recurrence.delete })
export const updateRecurrence = new RequestSagas(RecurrenceRequest.put, { method: 'PUT', endpoint: URLS.recurrence.update })

/*Export CSV */
export const exportCSVSagas = new RequestSagas(IssuesExportCSVRequest.get, { method: 'GET', endpoint: URLS.issues.exportCSV })
exportCSVSagas.request = function * (action) {
  /*yield put(flash({
        content: Flashes.exportCSVRequested()
    }))*/
}
exportCSVSagas.success = function * (action) {
  window.open(action.payload.fileURI)
}


/*Live update: from other process*/
export const getMessengerIssueSagas = new RequestSagas(MessengerIssueRequest.get, { method: 'GET', endpoint: URLS.issues.messenger })
export const getMessengerIssuesSagas = new RequestSagas(MessengerIssuesRequest.get, { method: 'POST', endpoint: URLS.issues.messenger })

const liveUpdate = function* (action) {
  const state = yield select(state => state)
  const toLoadIssues = action.payload.issues.filter(issueId => {
    return state.issues.all.find(issue => issue._id === issueId);
  });
  if(!toLoadIssues.length){
    return;
  }
  yield put({ type: "loadMessengerIssues", issues: toLoadIssues });
}

export const checkLiveUpdateSaga = function * () {
  yield takeLatest([
    MessengerIssueRequest.get.TYPES.SUCCESS,
    MessengerIssuesRequest.post.TYPES.SUCCESS
  ], liveUpdate)
}



/*Export watchers sagas*/
const sagas = [
  fork(getIssuesSagas.saga),
  fork(getIssuesSaga),
  fork(checkLiveUpdateSaga),
  fork(getIssueSagas.saga),
  fork(getStarterIssueSagas.saga),
  fork(putIssueSagas.saga),
  fork(saveIssuesFiltersSagas.saga),
  fork(IssuesAutocompleteRequestSagas.saga),
  fork(postIssueFilesSagas.saga),
  fork (postIssueServiceFilesSagas.saga),
  fork(postIssuesSagas.saga),
  fork(recurrencePreviewSagas.saga),
  fork(getNearsIssues.saga),
  fork(issuesSuccessConfirmationsSaga),
  fork(issuesRequestConfirmationsSaga),
  fork(getIssueMatchFilters.saga),
  fork(putIssuesSaga.saga),
  fork(goToIssueSaga),
  fork(closeIssueSaga),
  fork(catchConfirmation),
  fork(deleteRecurrence.saga),
  fork(updateRecurrence.saga),
  fork(createIssueSaga),
  fork(exportCSVSagas.saga),
  fork(updateIssuesFiltersSagas.saga),
  fork(deleteIssuesFiltersSagas.saga),
  fork(getMessengerIssueSagas.saga),
  fork(getMessengerIssuesSagas.saga),
  fork(catchNewAlert)
]

export default sagas