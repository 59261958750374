import * as IssuesActionTypes from 'client/features/issues/actions'
import _ from 'lodash'
import { registerDefaultReducer, registerInitialstate } from "client/lib/reduxSaga";

const initialState = {
  progress: 0,
  active: false
}

const initializeState = () => Object.assign({}, initialState)


registerInitialstate("progressBar", initializeState);
registerDefaultReducer("progressBar", function progressBar(state = initializeState(), action = {}) {
  switch (action.type) {

  case IssuesActionTypes.IssuesRequest.post.TYPES.PROGRESS:
    return { ...state, active: true, percent: action.payload.percent || 0 }
  case IssuesActionTypes.IssuesRequest.post.TYPES.SUCCESS:
  case IssuesActionTypes.IssuesRequest.post.TYPES.FAILURE:
    return { ...state, active: false }

  default:
    return state
  }
});