
export default class Observer {
  constructor(){
    this._handlers = [];
  }
  notify(data){
    this._handlers.forEach(handler => { handler(data) });;
  }
  observe(handler){
    if(this._handlers.indexOf(handler) === -1){
      this._handlers.push(handler);
    }
  }
  stopObserve(handler){
    const idx = this._handlers.indexOf(handler)
    if(idx !== -1){
      this._handlers.splice(idx, 1);
    }
  }
}