// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-file-frame {
  background-color: #FFFFFF;
  padding: 4px;
  border: 1px solid #ddd9d7;
  width: fit-content;
}`, "",{"version":3,"sources":["webpack://./../common/components/file/frame.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":[".bs-file-frame {\n  background-color: #FFFFFF;\n  padding: 4px;\n  border: 1px solid #ddd9d7;\n  width: fit-content;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
