
import React                      from "react";
import File                       from "@cComponents/file";
import PrintButton                from "@cComponents/printButton";
import WhoWhenWhat                from "@cFeatures/whoWhenWhat";
import { useDataTestId }          from "@uFeatures/dataTestId";

import Issue                      from "@uTypes/business/Issue";

import State                      from "@entities/issue/state";
import Subject                    from "@entities/assignmentsAndIssues/subject";

import Title                      from "../title";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import { AssignmentPrintBuilder }      from "@lib/printBuilder";
import Modal from '@common/components/modal';
import FileType from "@uTypes/business/File";
import Query from "@universal/lib/query";
import useService from "@universal/behaviour/hooks/useService";
import CurrentTenantService from "@universal/services/currentTenant";
import T from "@uBehaviour/i18n";
import Text, { Style } from "@cComponents/text";


import "./detail.css";

type StarterIssueDetailProps = {
  issue: BusinessEntity<Issue, { category: Loader, files: Loader }>;
};

type FileProps = {
  file: FileType,
  index: number
}

const Detail: React.FunctionComponent<StarterIssueDetailProps> = ({ issue }) => {
  const issueDescriptionTestId = useDataTestId("starter-issue-description");
  const [ displayedFile, setDisplayedFile ] = React.useState<number | null>(null);
  const openDisplayFile = React.useCallback((file: FileProps) => setDisplayedFile(file.index), []);
  const closeDisplayfile = React.useCallback(() => setDisplayedFile(null), []);
  const currentTenantService = useService<CurrentTenantService>("currentTenant");

  const printUrl = React.useMemo(() => {
    const assignmentQuery = Query.joinWithOptimizer({ _id: issue.assignments[0]._id }, { tenant: currentTenantService.currentId });
    return AssignmentPrintBuilder.create()
    .query(JSON.stringify(assignmentQuery))
    .build();
  }, [ issue, currentTenantService.currentId ]);

  const files = React.useMemo(() => {
    if (issue.files.length) {
      return issue.files;
    }
    return [{
      type:"static",
      src: "/images/no_photo_little_all_tags.png"
    }]
  }, [ issue.files ])
  const hasBeenUpdated = issue.createdBy._id !== issue.updatedBy._id;
  return (
    <div className="bs-issues-starter-form-detail">
      <div className="bs-issues-starter-form-detail-file">
        <File.Gallery files={ files.map(file => file) } column={ 4 } onClick={ openDisplayFile }/>
        { displayedFile !== null  && ( 
          <Modal.Show close={ closeDisplayfile } style={{ width: "90vw", height: "90vh" }}>
            <File.Viewer files={ files } display={ displayedFile } />
          </Modal.Show>
        )}
      </div>
      <div className="bs-issues-starter-form-detail-info">
        <Title.Issue issue={ issue } />
        
        <div>
          <Subject location={issue.location} equipment={ null } />
        </div>
  
        <div className="bs-issues-starter-form-detail-description-container">
          <div className="bs-issues-starter-form-detail-description" data-testId={ issueDescriptionTestId }>
            { issue.description }
          </div>
        </div>
  
        <div className="bs-issues-starter-form-detail-footer">
          <div>
            <WhoWhenWhat who={ issue.requestor } when={ issue.createdAt } what="issues_starter_form_item_created_at_by" />
          </div>
          {hasBeenUpdated && (
            <div>
              <WhoWhenWhat who={ issue.updatedBy } when={ issue.updatedAt } what="issues_starter_form_item_updated_at_by" />
            </div>
          )}
        </div>
      </div>
  
      <div className="bs-issues-starter-form-detail-meta">
        <PrintButton url={ printUrl }>
          <div className="bs-issues-starter-form-detail-print">
            <span className="fa fa-print" />
            <Text style={ Style.small }><T>issues_starter_form_detail_print</T></Text>
          </div>
        </PrintButton>
        <State.Standart state={ issue.state } />
      </div>
    </div>
  );
};

export default Detail;