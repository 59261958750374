import Valorization, { Statement } from '../../../types/business/Valorization';

const _getStatementsTotal = (statements: Statement[]): number => { 
  return statements.reduce<number>((total, statement) => total + statement.cost, 0);
};

interface ValorizationTotal {
  teamOrAgentsTotal: number;
  equipmentsTotal: number;
  suppliesTotal: number;
  externalServicesTotal: number;
  total: number;
};

const getValorizationsTotals = (valorizations: Valorization[]): ValorizationTotal => {
  const statements = valorizations.reduce<Statement[]>((statements, valorization) => statements.concat(valorization.statements.map(s => s)), []);
  const teamOrAgentsTotal = _getStatementsTotal(statements.filter(s => ["team", "agent"].includes(s.discriminator)));
  const equipmentsTotal = _getStatementsTotal(statements.filter(s => s.discriminator === "equipment"));
  const suppliesTotal = _getStatementsTotal(statements.filter(s => s.discriminator === "supply"));
  const externalServicesTotal = _getStatementsTotal(statements.filter(s => s.discriminator === "service"));
  
  return {
    teamOrAgentsTotal,
    equipmentsTotal,
    suppliesTotal,
    externalServicesTotal,
    total: teamOrAgentsTotal + equipmentsTotal + suppliesTotal + externalServicesTotal
  };
};

export default getValorizationsTotals;