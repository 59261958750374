import React, { FunctionComponent }      from 'react';

import usePager                          from '@cBehaviour/data/usePager';
import Valorization                      from '@uTypes/business/Valorization';
import Issue                             from '@uTypes/business/Issue';

import { SetForAssignment, SetForIssue } from './setByType';
import { Grid } from 'semantic-ui-react';
import Button from './button';
import T from '@uBehaviour/i18n';
import { useCreateUpdateModelData } from '@uBehaviour/hooks/useUpdatedData';
import Modal from '@cComponents/modal';
import Display from '@uComponents/displayIf';
import Form from './form';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import Summary from './summary';
import useService from '@universal/behaviour/hooks/useService';
import AclService from '@universal/services/acl';
import Acl from '@uBehaviour/acl';

import './valorizations.css';
import Title from '@common/components/section/title';

interface ValorizationProps {
  issue: Issue;
  isDisplay: boolean;
}
const Valorizations: FunctionComponent<ValorizationProps> = ({ issue, isDisplay = false }) => {
  const { datas: valorizations, loadNext, loading } = usePager<Valorization>({
    model: 'Valorization',
    query: { issue: issue._id },
    sort: { issue: 1, assignment: 1, statementOf: 1 },
    load: {
      'statements.agent': true,
      'statements.team': true,
      'statements.equipment': true,
      'statements.supply': true,
      'statements.files': true,
    },
    pageSize: 100,
    loadAll: true
  });

  React.useEffect(() => {
    loadNext();
  }, [loadNext]);

  const onCreate = React.useCallback(function(valorization: Valorization): Valorization {
    valorization.issue = issue._id;
    return valorization;
  }, [issue]);

  const onUpdate = React.useCallback(function(valorization: BusinessEntity<Valorization>): Valorization {
    if(valorization.toPlainText){
      return valorization.toPlainText();
    }
    return valorization;
  }, []);

  const [updatedValorization, openFormValorizationInCreation, openFormValorization, closeFormValorization] = useCreateUpdateModelData<Valorization>("Valorization", onCreate, onUpdate);
  const acl = useService<AclService>('acl');

  React.useEffect(() => {
    const readOnly = !(
      acl.connectedUserIsAllow('valorizations', 'writeOnIssue')      ||
      acl.connectedUserIsAllow('valorizations', 'writeOnAssignment') ||
      acl.connectedUserIsAllow('valorizations', 'writeOnAssignmentForOthers')
    );
    if(!readOnly && isDisplay && !valorizations.length && !loading){
      openFormValorizationInCreation();
    }
  }, [isDisplay, loading])
  
  const sets = React.useMemo(() => {
    const sets = [];
    let current = null;
    let last = undefined;
    valorizations.forEach(valorization => {
      if(valorization.assignment !== last){
        last = valorization.assignment;
        current = { valorizations: [], target: null };
        sets.push(current);
      }
      current.valorizations.push(valorization);
    });
    sets.forEach((set) => {
      if(set.valorizations[0].assignment){
        set.target = {
          assignment: issue.assignments.find(assignment => assignment._id === set.valorizations[0].assignment)
        };
      } else {
        set.target = {
          issue
        };
      }
    });
    return sets;
  }, [valorizations, issue]);
  
  const canSeeSomeCost = React.useMemo(() => {
    return acl.connectedUserIsAllow('equipments', 'readCost')
      || acl.connectedUserIsAllow('supplies', 'readCost')
      || acl.connectedUserIsAllow('users', 'readCost')
      || acl.connectedUserIsAllow('teams', 'readCost');
  }, [acl]);


  return (
    <>
      <Grid>
        <Grid.Row>
          <Acl.If resource="valorizations" action="writeOnAssignment">
            <Grid.Column textAlign='right' width={15}>
              <Button onClick={ openFormValorizationInCreation } data-testid="valorization-create">
                <T>valorizations_create</T>
              </Button>
            </Grid.Column>
          </Acl.If>
        </Grid.Row>
      </Grid>
      <Display.If condition={ canSeeSomeCost }>
        <Summary valorizations={ valorizations } />
      </Display.If>
      <br />
      {
        sets.map(set => (
          set.target.issue
            ? <SetForIssue key={ set.target.issue._id } valorizations={ set.valorizations } issue={ set.target.issue } update={ openFormValorization } />
            : <SetForAssignment key={ set.target.assignment._id } valorizations={ set.valorizations } assignment={ set.target.assignment } update={ openFormValorization } />
        ))
      }
      <Display.If condition={ updatedValorization }>
      {() => (
        <Modal.Show close={ closeFormValorization } style={{ height: '90vh', width: '70vw' }}>
        {(close) => (
          <div className='bs-valorization-modal-form'>
            <div>
              <Title>
                <b><T bind={{ issue: issue.bsId }}>valorization_modal_form_title</T></b>
              </Title>
            </div>
            <div className='bs-valorization-modal-form-container'>
              <Form issue={ issue } valorization={ updatedValorization } close={ close }/>
            </div>
          </div>
        )}
        </Modal.Show>
      )}
      </Display.If>
    </>
  );
}

export default Valorizations;