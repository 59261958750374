import { Service }        from "@uLib/application";
import Location           from '@uLib/location';
export default class TenantGeolocationService extends Service{
  constructor(){
    super("tenant-geolocation", ["geolocation", "currentTenant"]);
  }
  start(){
    this.waitReady(["geolocation"]).then(([geolocation]) => {
      this._geolocation = this.application.servicesContainer.override("geolocation", this);
    });
  }
  get current(){
    let current                 = this._geolocation.current;
    const currentTenant         = this.application.getService("currentTenant").current;
    const tenantDefaultPosition = currentTenant && currentTenant.settings && currentTenant.settings.defaultMap && currentTenant.settings.defaultMap.position
      ? currentTenant.settings.defaultMap.position
      : null;
    if(current === this.application.getService("configuration").get("default_map_position") && tenantDefaultPosition){
      current = tenantDefaultPosition;
    }
    return current;
  }
  get defaultZoom(){
    return this._geolocation.defaultZoom;
  }
  getUserLocation(){
    return this._geolocation.getUserLocation;
  }
  getPositionFromAddress(address){
    return this._geolocation.getPositionFromAddress(address);
  }
  getAddressFromPosition(position){
    return this._geolocation.getAddressFromPosition(position);
  }
  updateLastUse(position){
    return this._geolocation.updateLastUse(position);
  }
}