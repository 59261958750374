import React, { FunctionComponent, PropsWithChildren } from 'react';
import Display from './display';

import State from '@uTypes/business/State';
import T from '@universal/behaviour/i18n';

interface ShortProps {
  state: State;
};

const Standart: FunctionComponent<PropsWithChildren<ShortProps>> = ({ state, children }) => {
  return (
    <Display type="standart" color={state.color} height={28} width={159}>
      <span><T>{ state.i18n }</T></span>
      { children }
    </Display>
  );
}

export default Standart;