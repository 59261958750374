// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-recurrence-demo-list{
    display: flex;
    flex-wrap: wrap;
}

.bs-recurrence-demo-item{
    color: white;
    margin-right: 8px;
    margin-bottom: 8px;
    border: 1px dashed;
    background-color: #A9A6A6;
    text-align: center;
    padding: 2px;
}
.bs-recurrence-demo-item-weekDay{
    font-size: 12px;
    margin-bottom: 2px;
}
.bs-recurrence-demo-item-day{
    background-color: white;
    font-size: 10px;
    color: #818181;
}
.bs-recurrence-demo-item-createdAtLabel{
    font-size: 8px;
}
.bs-recurrence-demo-item-createdAt{
    font-size: 8px;
}`, "",{"version":3,"sources":["webpack://./src/entities/recurrence/demo.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;AACA;IACI,eAAe;IACf,kBAAkB;AACtB;AACA;IACI,uBAAuB;IACvB,eAAe;IACf,cAAc;AAClB;AACA;IACI,cAAc;AAClB;AACA;IACI,cAAc;AAClB","sourcesContent":[".bs-recurrence-demo-list{\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.bs-recurrence-demo-item{\n    color: white;\n    margin-right: 8px;\n    margin-bottom: 8px;\n    border: 1px dashed;\n    background-color: #A9A6A6;\n    text-align: center;\n    padding: 2px;\n}\n.bs-recurrence-demo-item-weekDay{\n    font-size: 12px;\n    margin-bottom: 2px;\n}\n.bs-recurrence-demo-item-day{\n    background-color: white;\n    font-size: 10px;\n    color: #818181;\n}\n.bs-recurrence-demo-item-createdAtLabel{\n    font-size: 8px;\n}\n.bs-recurrence-demo-item-createdAt{\n    font-size: 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
