// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-body{
    display: flex;
    flex-direction: column-reverse;
    background: #f2eeeb;
    color: #222222;
    flex-grow: 1;
    overflow: hidden;
    height: 100%;
}
.bs-navigation-bar{
    display: flex;
    flex-direction: row;
    height: 43px;
    flex-grow: 0;
    flex-shrink: 0;
    background: white;
    box-shadow: 0px 1px 8px 0px #c4c4c4;
}

.bs-navigation-bar>*:first-child{
  flex-grow: 2;
}
.bs-body>.bs-content{
  flex-grow: 1;
  overflow: hidden;
}

.bs-navigation-bar-static {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--std-size-inter-element);
}
`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/body.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,cAAc;IACd,YAAY;IACZ,gBAAgB;IAChB,YAAY;AAChB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,cAAc;IACd,iBAAiB;IACjB,mCAAmC;AACvC;;AAEA;EACE,YAAY;AACd;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kCAAkC;AACpC","sourcesContent":[".bs-body{\n    display: flex;\n    flex-direction: column-reverse;\n    background: #f2eeeb;\n    color: #222222;\n    flex-grow: 1;\n    overflow: hidden;\n    height: 100%;\n}\n.bs-navigation-bar{\n    display: flex;\n    flex-direction: row;\n    height: 43px;\n    flex-grow: 0;\n    flex-shrink: 0;\n    background: white;\n    box-shadow: 0px 1px 8px 0px #c4c4c4;\n}\n\n.bs-navigation-bar>*:first-child{\n  flex-grow: 2;\n}\n.bs-body>.bs-content{\n  flex-grow: 1;\n  overflow: hidden;\n}\n\n.bs-navigation-bar-static {\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: var(--std-size-inter-element);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
