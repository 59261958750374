import React from "react";

import './numeric.css'

class Numeric extends React.Component {
  constructor(props){
    super(props);
    this.state = { showInput: false }
    
    this._hideInput     = this._hideInput.bind(this);
    this._showInput     = this._showInput.bind(this);
    this._decrease      = this._decrease.bind(this);
    this._increase      = this._increase.bind(this);
    this._onInputChange = this._onInputChange.bind(this);
  }

  _onChange(value){
    if(this.props.min !== undefined && value < this.props.min){
      value = this.props.min;
    }
    if(this.props.max !== undefined && value > this.props.max){
      value = this.props.max;
    }
    if(value !== this.props.value){
      this.props.onChange(value);
    }
  }

  _stringify(value){
    if(this.props.stringify){
      return this.props.stringify(value);
    }
    return value;
  }

  _hideInput(){
    this.setState({ showInput: false });
  }

  _showInput(){
    this.setState({ showInput: true });
  }

  _decrease(e){
    const { value = 0 } = this.props;
    this._onChange(value - 1);
  }

  _increase(){
    const { value = 0 } = this.props;
    this._onChange(value + 1);
  }

  _onInputChange(e){
    this._onChange(e.target.valueAsNumber);
  }
  
  render(){
    const { value = 0 } = this.props;
    return(
      this.state.showInput
        ? (
            <input 
              className='bs-numeric-input'
              type="number"
              min={ this.props.min ? this.props.min : "" }
              max={ this.props.max ? this.props.max : ""} 
              value={ value }
              onChange={ this._onInputChange }
              onBlur={ this._hideInput }/>
        )
        : (
            <div className='bs-numeric'>
              <button className='bs-numeric-buttons' onClick={ this._decrease }>-</button>
              <div className='bs-numeric-content' onClick={ this._showInput }>
              {
                this._stringify(value)
              }
              </div>
              <button className='bs-numeric-buttons' onClick={ this._increase }>+</button>
            </div>
        )
    )
  }
}

export default Numeric;