import React, { FunctionComponent, PropsWithChildren }             from "react";

import T                 from "@uBehaviour/i18n";
import useService        from "@uBehaviour/hooks/useService";
import RepositoryService from "@uServices/repository";
import DataTestId        from "@uFeatures/dataTestId";

import Issue             from "@uTypes/business/Issue";

import Modal             from "@cComponents/modal";

import CTA               from "../../features/cta";
import Detail            from "@entities/issues/components/starter/detail";
import Map               from "@entities/issues/components/starter/map"
import Form              from "@entities/issues/components/starter/form";
import { ScrollableManager } from "@cComponents/scrollBar";
import History from "@entities/issues/components/starter/history";

import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import Text from "@common/components/text";

import "./form.css";

const PromotionalOffer: React.FunctionComponent = () => (
  <div className="bs-starter-issue-form-offer-container">
    <div className="bs-starter-issue-form-offer">
      <span className="bs-starter-issue-form-offer-content">
        <Text><T>starter_issue_form_upgrade_offer_1</T></Text>
        <br/>
        <Text><T>starter_issue_form_upgrade_offer_2</T></Text>
      </span>
      <CTA.DiscoverStdExp />
    </div>
  </div>
);

const PartContainer: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <div className="bs-starter-issue-form-partContainer">
    <div className="bs-starter-issue-form-partContainer-content">
      { children }
    </div>
  </div>
);

type StarterIssueFormProps = {
  id: Issue["_id"],
  close?: () => void,
};

const StarterForm: React.FunctionComponent<StarterIssueFormProps> = ({ id, close = () => {} }) => {
  const repository = useService<RepositoryService>("repository");
  const [issue, setIssue] = React.useState<BusinessEntity<Issue, { files: Loader, category: Loader }> | null>(null);

  React.useEffect(() => {
    repository.get("Issue").repository.findOne(id, { files: true, category: true })
              .then((result: BusinessEntity<Issue, { files: Loader, category: Loader }>) => setIssue(result));
  }, [repository, id]);

  if(!issue){
    return null;
  }
   
  return(
    // TODO: Intégrer le composant dans une scrollbar
    <Modal.Manager>
      <DataTestId.Prefix value="starter-issue-form">
        <ScrollableManager className="bs-starter-issue-form bs-starter-issue-form-layout">      
          <div className="bs-starter-issue-form-layout-left">
            <ScrollableManager.Scrollable>
              <div className="bs-starter-issue-form-container-layout">
                <div className="bs-starter-issue-form-container-layout-top bs-starter-issue-form-container-issue-layout">

                  <div className="bs-starter-issue-form-container-issue-layout-left bs-starter-issue-form-issue">
                    <PartContainer>
                      <Detail issue={ issue } />
                      <hr />
                      <Form issue={ issue } close={ close }/>
                    </PartContainer>
                  </div>
                  
                  <div className="bs-starter-issue-form-container-issue-layout-right bs-starter-issue-form-map">
                    <PartContainer>
                      <Map issue={ issue }/>
                    </PartContainer>
                  </div>
                </div>
                
                <div className="bs-starter-issue-form-container-layout-bottom">
                  <PartContainer>
                    <PromotionalOffer />
                  </PartContainer>
                </div>
              </div>
            </ScrollableManager.Scrollable>
          </div>
          
          <div className="bs-starter-issue-form-layout-right">
            <ScrollableManager.Scrollable>
              <PartContainer>
                <History issue={ issue } />
              </PartContainer>
            </ScrollableManager.Scrollable>
          </div>
        </ScrollableManager>
      </DataTestId.Prefix>
    </Modal.Manager>
  );
}

export default StarterForm;