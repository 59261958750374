// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-notifications-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 48px;
  padding: 13px;
}
.bs-notifications-button:hover{
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.05);
}

.bs-notifications-button-quantity {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: red;
  border-radius: 100%;
  color: white;
  font-weight: bold;
  font-size: 10px;
  height: 15px;
  width: 15px;
  bottom: 3px;
  right: 3px;
}`, "",{"version":3,"sources":["webpack://./src/entities/notifications/button.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,WAAW;EACX,aAAa;AACf;AACA;EACE,eAAe;EACf,uCAAuC;AACzC;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;EACZ,iBAAiB;EACjB,eAAe;EACf,YAAY;EACZ,WAAW;EACX,WAAW;EACX,UAAU;AACZ","sourcesContent":[".bs-notifications-button {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n  width: 48px;\n  padding: 13px;\n}\n.bs-notifications-button:hover{\n  cursor: pointer;\n  background-color: rgba(255, 0, 0, 0.05);\n}\n\n.bs-notifications-button-quantity {\n  position: absolute;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  background-color: red;\n  border-radius: 100%;\n  color: white;\n  font-weight: bold;\n  font-size: 10px;\n  height: 15px;\n  width: 15px;\n  bottom: 3px;\n  right: 3px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
