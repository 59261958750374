import React, { FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react';

import './button.css';

type OnClickHandler = () => void;
interface ButtonProps {
  onClick: OnClickHandler;
  ['data-testid']?: string;
}
const Button : FunctionComponent<PropsWithChildren<ButtonProps>> = ({ onClick, 'data-testid': dataTestId, children }) => {
  const clickHandler: MouseEventHandler = React.useCallback<MouseEventHandler>((event) => {
    event.stopPropagation();
    onClick();
  }, [onClick]);

  return (
    <span className='bs-valorization-button' onClick={ clickHandler } data-testid={ dataTestId }>
      <span className='fa fa-pencil' />
      <span>{ children }</span>
    </span>
  );
}

export default Button;