// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-planning-hider {
  position: relative;
}
.bs-planning-hider-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #aaa;
}
.bs-planning-hider-label>*:nth-child(n + 2) {
  margin-left: 5px;
}
.bs-planning-hider-label>*{
  flex-grow: 1;
}
.bs-planning-hider-label>*:last-child{
  flex-grow: 0;
}
.bs-planning-hider-content {
  z-index: 1;
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: white;
  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);
  padding: 5px;
}
.bs-planning-hider-label .fa:hover{
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/planning/selector.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;AAC/B;AACA;EACE,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;AACA;EACE,YAAY;AACd;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,SAAS;EACT,SAAS;EACT,uBAAuB;EACvB,4EAA4E;EAC5E,YAAY;AACd;AACA;EACE,eAAe;AACjB","sourcesContent":[".bs-planning-hider {\n  position: relative;\n}\n.bs-planning-hider-label {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  border-bottom: 1px solid #aaa;\n}\n.bs-planning-hider-label>*:nth-child(n + 2) {\n  margin-left: 5px;\n}\n.bs-planning-hider-label>*{\n  flex-grow: 1;\n}\n.bs-planning-hider-label>*:last-child{\n  flex-grow: 0;\n}\n.bs-planning-hider-content {\n  z-index: 1;\n  position: absolute;\n  top: 100%;\n  left: 0px;\n  background-color: white;\n  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);\n  padding: 5px;\n}\n.bs-planning-hider-label .fa:hover{\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
