import React, { FunctionComponent, ReactNode } from 'react';
import Button from "./button";
import ClipboardService from '@cServices/clipboard';
import useService from '@universal/behaviour/hooks/useService';

import './copy.css';

interface CopyProps {
  value: string;
  children: (value: string) => ReactNode | ReactNode;
}

const Copy: FunctionComponent<CopyProps> = ({ value, children }) => {
  const fnChildren :(value: string) => ReactNode = children instanceof Function
    ? children
    : () => children;

  const clipboard = useService<ClipboardService>("clipboard");
  const copyToClipboard = React.useCallback(async () => {
    clipboard.copyText(value);
  }, [value]);

  return (
    <div className='bs-copy'>
      { fnChildren(value) }
      { clipboard.isEnabled() && 
        <Button onClick={ copyToClipboard }>
          <span className='fa fa-clipboard' />
        </Button>
      }
    </div>
  );
}

export default Copy;