import { Service }        from "@uLib/application";


export default class TestErrorService extends Service {
  constructor() {
    super("testError");
    this._error = false;
  }
  isError(){
    return this._error;
  }
  triggerError(){
    this._error = true;
    this.triggerUpdate();
  }
}
