import React         from 'react';
import _             from "lodash";
import FieldGroup    from '@components/fieldGroup';
import Input         from '@cComponents/old/input';
import NewInput      from "@cComponents/input";
import FInput        from '@components/input';
import Form          from '@cBehaviour/form';
import T             from '@cBehaviour/i18n';
import Button        from '@cComponents/button';
import Field         from '@cComponents/old/field';
import FormError     from "@cComponents/formError";
import User          from "@entities/user";
import Filter        from "@cComponents/filter";
import Application   from '@uBehaviour/application';


import './form.css';

class TeamForm extends React.Component {
  constructor(props) {
    super(props);
    this._inputArray = React.createRef();
    this._preload = this._preload.bind(this);
  }
  _preload(val) {
    this._initialMembers = val.members;
    val.disabled = !val.disabled;
    val.members = val.members ? val.members.map(member => ({ _id: member })) : [];
    val.tempAvatar = val.avatar ? [val.avatar] : [];
    return val;
  }

  _presubmit(val) {
    val.disabled = !val.disabled;
    val.members = val.members.map(member => member._id);
    val.avatar = val.tempAvatar.length ? val.tempAvatar[0] : null;
    delete val.tempAvatar;
    return val;
  }

  render() {
    return React.createElement(Application.Service, { name: ["currentTenant"] }, ([currentTenant]) => (
      <Form.Model model={"Team"} observer={this.props.observer} id={this.props.id} preload={this._preload} presubmit={this._presubmit} ref={this.props.forwardedRef} >
      {(team, errors) => {
        let query = { tenants: { $elemMatch: { tenant: currentTenant.currentId, roles: 'agent', disabled: false } } };
        if (this._initialMembers && this._initialMembers.length) {
          query = { $and: [query, { $or: [{ team: null }, { _id: { $in: this._initialMembers } }] }] };
        } else {
          query.team = null;
        }
        return (
          <FormError errors={errors}>
            <div className="bs-teamForm">
              <div className="bs-teamForm-picture">
                <FieldGroup title="team_form_photography" type="full">
                  <Input.File name="tempAvatar" limit={ 1 } width={ 285 } height={ 285 } fit="contain" />
                </FieldGroup>  
              </div>
              <div className='bs-teamForm-content'>
              <FieldGroup type="full" title="generals_informations">
                <Field>
                  <Field.Label><T>name</T></Field.Label>
                  <Field.Input><Input.Text required={true} name="name" /></Field.Input>
                </Field>
                <Field>
                  <Field.Label><T>code</T></Field.Label>
                  <Field.Input><Input.Text required={true} maxLength={3} name="code" /></Field.Input>
                </Field>
                <Field>
                  <Field.Label><T>type</T></Field.Label>
                  <Field.Input>
                    <Input.Radio.Group name="type">
                      <Input.Radio.Value value="internal">
                        {(selected) => (<Button.Radio pushed={selected}><T>internal</T></Button.Radio>)}
                      </Input.Radio.Value>
                      <Input.Radio.Value value="external">
                        {(selected) => (<Button.Radio pushed={selected}><T>external</T></Button.Radio>)}
                      </Input.Radio.Value>
                    </Input.Radio.Group>
                  </Field.Input>
                </Field>
                <Field>
                  <Field.Label><T>hourly_cost_precise</T></Field.Label>
                  <Field.Input><Input.Number name="valorization" min={0} decimals={2} /></Field.Input>
                </Field>
                <Field>
                  <Field.Label><T>active_f</T></Field.Label>
                  <Field.Input><FInput.YesNo name="disabled" /></Field.Input>
                </Field>
              </FieldGroup>
              <FieldGroup type="full" required={true} title="members" subTitle="members_fieldGroup_requirements">
                <Field>
                  <Field.Input>
                    <Input.Selectable.List name="members" load={{ avatar: true }} model={"User"} query={query}>
                      <Input.Selectable.List.Render textify={(user) => `${user.firstname} ${user.lastname}`} />
                      <Input.Selectable.List.Open>
                        {(open) => (<Button.Text onClick={open} ><T>add</T></Button.Text>)}
                      </Input.Selectable.List.Open>
                      <Input.Selectable.List.Filter>
                        <div className="bs-role-modal-filter">
                          <Filter.Generic name="text" buildQuery={value => ({ fullname: { '$regex': value, '$options' : 'i' }})} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                          {(value, set, clear) => (
                            <NewInput.Text value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                              <T>freesearch</T>
                            </NewInput.Text>
                          )}
                          </Filter.Generic>
                        </div>
                      </Input.Selectable.List.Filter>
                      <Input.Selectable.List.ListTitle>
                        <span><T>team_members_selection</T> : {team.name}</span>
                      </Input.Selectable.List.ListTitle>
                      <Input.Selectable.List.Item>
                        <User.Item />
                      </Input.Selectable.List.Item>
                    </Input.Selectable.List>
                  </Field.Input>
                </Field>
              </FieldGroup>
              </div>
            </div>
          </FormError>
        )}
      }
      </Form.Model>
    ))
  }
}
export default React.forwardRef((props, ref) => {
  return (<TeamForm {...props} forwardedRef={ref} />)
})