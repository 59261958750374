import React, { Component } from 'react'
import T from 'i18n-react'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import { emailTemplate, formatUserNameAndEmail } from 'client/features/issues/lib'
import { Field, reduxForm } from 'redux-form'
import TextareaField from 'client/features/form/old/components/TextareaField.jsx'
import CheckboxesField from 'client/features/form/old/components/CheckboxesField.jsx'

const validate = values => {
    const errors = {}
    if (!values.message)
        errors.message = T.translate('form_validation_requiredValue')
    if (!values.receivers || !values.receivers[0])
        errors.receivers = T.translate('issue_contact_missing')
    return errors
}

class ContactRequestorModal extends Component {
    render() {
        const { show, onHide, issue, user, handleSubmit } = this.props
        const template = emailTemplate(this.props.template, issue, user)
        return (
            <Modal show={ show } onHide={ onHide } aria-labelledby='contained-modal-title-lg' bsSize="large" className='modal-helper'>
                <form onSubmit={ handleSubmit }>
                    <Modal.Header closeButton>
                        <Modal.Title>
                            { T.translate('contact_requestor') }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='row-fluid'>
                            <div className='span1'>
                                <strong>{ T.translate('from') }:</strong>
                            </div>
                            <div className='span11'>
                                { template.fromName }   
                            </div>
                        </div>
                        <div className='row-fluid'>
                            <div className='span1'>
                                <strong>{ T.translate('to') }:</strong>
                            </div>
                            <div className='span11'>
                                {
                                    !issue.followers || !issue.followers[0] ?
                                        <span>{ formatUserNameAndEmail(issue.requestor) }</span>
                                        :
                                        <div>
                                            <Field
                                                component={ CheckboxesField }
                                                fields={ [...issue.followers, issue.requestor].filter(requestor => requestor.email !== user.email).map((user) => (
                                                    { value: user._id ? user._id : user.email, label: formatUserNameAndEmail(user) }
                                                )) }
                                                name='receivers'
                                                labelClass={ 'label-email-to' }
                                                initialValues={ this.props.initialValues.receivers }
                                            />
                                        </div>
                                }
                            </div>
                        </div>
                        <div className='row-fluid'>
                            <div className='span1'>
                                <strong>{ T.translate('mail_object') }</strong>
                            </div>
                            <div className='span11'>
                                { template.subject }
                            </div>
                        </div>
                        <hr />
                        <div className='row-fluid'>
                            <div className='span12'>
                                <strong>{ T.translate('form_comment') }*:</strong>
                                <Field name='message' component={ TextareaField } className='span12'/>
                            </div>
                        </div>
                        <div className='row-fluid'>
                            <div className='span12' dangerouslySetInnerHTML={ { __html: template.body } } />
                        </div>
                        <div className='row-fluid'>
                            <div className='span12' dangerouslySetInnerHTML={ { __html: template.footer } } />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className='button-a' onClick={ onHide }>
                            { T.translate('cancel') }
                        </button>
                        <button className='button-a orange'>
                            { T.translate('form_submit') }
                        </button>
                    </Modal.Footer>
                </form>
            </Modal>
        )
    }
}

const mapStateToProps = (state) => (
    { 
        template: state.session.emailsTemplates.sendIssue
    }
)

export default connect(mapStateToProps)(ContactRequestorModal = reduxForm({
    form: 'issueContactRequestor',
    validate
})(ContactRequestorModal))