// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-numeric{
  display: flex;
  cursor: pointer;
}

.bs-numeric-buttons{
  padding-left: 6px;
  padding-right: 6px;
  border: none;
}

.bs-numeric-content{
  text-align: center;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 0px;
  border-bottom: 1px solid #cecbc8;
  white-space: nowrap;
  flex-grow: 2;
}

.bs-numeric-input{
  width: 100px;
  text-align: center;
}

.bs-numeric-input::focus{
  border-color: rgba(82,168,236,0.8);
}`, "",{"version":3,"sources":["webpack://./src/old/components/input/numeric.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,gBAAgB;EAChB,gCAAgC;EAChC,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".bs-numeric{\n  display: flex;\n  cursor: pointer;\n}\n\n.bs-numeric-buttons{\n  padding-left: 6px;\n  padding-right: 6px;\n  border: none;\n}\n\n.bs-numeric-content{\n  text-align: center;\n  padding-left: 6px;\n  padding-right: 6px;\n  margin-left: 0px;\n  border-bottom: 1px solid #cecbc8;\n  white-space: nowrap;\n  flex-grow: 2;\n}\n\n.bs-numeric-input{\n  width: 100px;\n  text-align: center;\n}\n\n.bs-numeric-input::focus{\n  border-color: rgba(82,168,236,0.8);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
