import React, { FunctionComponent } from "react";
import Address from "./address";

import Equipment from "@uTypes/business/Equipment";
import { IssueLocation } from "@uTypes/business/Issue";

import './subject.css';

interface SubjectProps {
  equipment: Equipment | null;
  location: IssueLocation;
}

const Subject: FunctionComponent<SubjectProps> = ({ equipment, location }) => {
    if(!equipment){
        return (
            <span className='bs-item-subject'>
                <Address location={ location } />
            </span>
        );
    }
    return (
        <span className='bs-item-subject'>
            <span className='fa fa-wrench'/>
            &nbsp;
            <span>{ equipment.name + (location ? ', ' : '')}</span>
            &nbsp;
            { location ? <Address location={ location } withIcon={ false }/> : null }
        </span>
    );
};

export default Subject;