import React, {
  FunctionComponent
} from 'react';

import ObjectId from '@uTypes/technic/ObjectId';
import Section from "@cComponents/section";
import Layout from '@cComponents/layout';
import Button from '@cComponents/button';
import T from '@universal/behaviour/i18n';
import DetailContent from './detail/content';
import useService from '@uBehaviour/hooks/useService';
import ApiService from '@uServices/api';
import useGet from "@uBehaviour/data/hooks/useGet";
import Recurrence from '@uTypes/business/Recurrence';

import ScrollBar from '@common/components/scrollBar/scrollBar';

import './detail.css'
import Display from '@common/components/displayIf';

interface DetailProps {
  recurrenceId: ObjectId;
  close: () => void
}

const Detail: FunctionComponent<DetailProps> = ({ recurrenceId, close }) => {
  const apiService = useService<ApiService>("api");
  const [confirmation, setConfirmation] = React.useState<boolean>(false);
  const onDelete = React.useCallback(() => {
    apiService.service("recurrences", "delete").execute(recurrenceId)
    .then(() => close());
  }, [apiService, recurrenceId, close]);

  const toggleConfirmation = React.useCallback(() => setConfirmation(!confirmation), [confirmation])

  const recurrence = useGet<Recurrence>("Recurrence", [{ _id: recurrenceId }])[0];
  if (!recurrence) return null;
  return (
    <Layout.Standart stretch>
      <Layout.Standart.Header>
        <Section.Header>
          <b><T>recurrence_detail_modal_title</T></b>
        </Section.Header>
      </Layout.Standart.Header>
      <Layout.Standart.Content>
        <ScrollBar>
          <DetailContent recurrence={recurrence} />
        </ScrollBar>
      </Layout.Standart.Content>
      <Layout.Standart.Footer>
        <Section.Footer>
          <div className="bs-issue-detail-buttons">
            <Display.If condition={confirmation}>
              <Display.Then>
                <T>recurrence_detail_delete_confirmed_label</T>
                <Button.Text onClick={ toggleConfirmation } color="#a09696" ><T>recurrence_detail_cancel_btn</T></Button.Text>
                <Button.Text onClick={ onDelete } color="red"><T>recurrence_detail_delete_confirmed_btn</T></Button.Text>
              </Display.Then>
              <Display.Else>
                {!recurrence.deleted && (<Button.Text onClick={ toggleConfirmation } color="#a09696"><T>recurrence_detail_delete_btn</T></Button.Text>)}
                <Button.Text onClick={ close } ><T>recurrence_detail_close_btn</T></Button.Text>
              </Display.Else>
            </Display.If>
          </div>
        </Section.Footer>
      </Layout.Standart.Footer>
    </Layout.Standart>
  );
}

export default Detail;