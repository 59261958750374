import React, { PureComponent } from 'react'
// import T from 'i18n-react'
import Confirmation from 'client/features/common/components/Confirmation.jsx'
import ButtonGroupSelector from '../../../common/components/ButtonGroupSelector'
import T from '@cBehaviour/i18n';
import Application from '@uBehaviour/application';

class MandatoryValorization extends PureComponent {
  state = {
    confirmation: null
  }

  _renderPublicLabel = () => {
    return <T>{this.props.mustBeValorized ? 'issue_detail_mandatory_valorization_value_yes' : 'issue_detail_mandatory_valorization_value_no' }</T>
  }

  _renderSwitch(mustBeValorized) {
      const options = [
        {
          label: this.props.i18n.translate('issue_detail_mandatory_valorization_value_yes'),
          value: true,
          isSelected: mustBeValorized
        },
        {
          label: this.props.i18n.translate('issue_detail_mandatory_valorization_value_no'),
          value: false,
          isSelected: !mustBeValorized
        }
      ]
      return <ButtonGroupSelector onSelect={ (value) => {
        // state confirmation => simulation ancien système pour affichage de la coche verte (si succès, affichage coche verte puis fermeture après 2.5sec)
        this.setState({confirmation : { action: "request" }});
        this.props.mustBeValorizedChange(value).then(() => {
          this.setState({confirmation : { action: "success" }});
          setTimeout(
            () => this.setState({ confirmation : null }),
            2500
          );

        }); 
      }} options={ options }/>
  }

  render() {
    return <div className='c-issue-detail-field'>
      <div className='o-layout o-layout--middle'>
        <div className='o-layout__item u-1of3'>
          <div className='c-issue-detail-field__label'>{<T>issue_detail_mandatory_valorization_label</T>}:</div>
        </div>
        <div className='o-layout__item u-2of3'>
          <div className='c-confirmation-container'>
            <div className='c-confirmation-container__component c-issue-detail-field__content'>
              { this.props.edit ? this._renderSwitch(this.props.mustBeValorized) : this._renderPublicLabel() }
            </div>
            <Confirmation data={ this.state.confirmation }/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Application.Service.forward(["i18n"], MandatoryValorization);