// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-text {
  line-height: 1.5;
}

.bs-text-standard {
  font-size: 14px;
}
.bs-text-tiny {
  font-size: 8px;
}
.bs-text-small {
  font-size: 11px;
}
.bs-text-large {
  font-size: 18px;
}
.bs-text-huge {
  font-size: 24px;
}

.bs-text-bold {
  font-weight: bold;
}
.bs-text-italic {
  font-style: italic;
}
.bs-text-underline {
  text-decoration: underline;
}
.bs-text-capitalize {
  text-transform: capitalize;
}
.bs-text-super{
  vertical-align: super;
}

.bs-text-justify {
  text-align: justify;
}
.bs-text-left {
  text-align: left;
}
.bs-text-center {
  text-align: center;
}
.bs-text-right {
  text-align: right;
}

.bs-text-black {
  color: var(--std-color-black);
}
.bs-text-orange {
  color: var(--std-color-orange);
}
.bs-text-white {
  color: var(--std-color-white);
}
.bs-text-light-gray {
  color: var(--std-color-gray-light);
}
.bs-text-gray {
  color: var(--std-color-gray);
}
.bs-text-dark-gray {
  color: var(--std-color-gray-dark);
}
.bs-text-green {
  color: var(--std-color-green);
}
.bs-text-red {
  color: var(--std-color-red);
}
.bs-text-stdColor {
  color: var(--std-color-text);
}

.bs-text-clickable:hover {
  cursor: pointer;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./../common/components/text.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,0BAA0B;AAC5B;AACA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;AAC/B;AACA;EACE,8BAA8B;AAChC;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,kCAAkC;AACpC;AACA;EACE,4BAA4B;AAC9B;AACA;EACE,iCAAiC;AACnC;AACA;EACE,6BAA6B;AAC/B;AACA;EACE,2BAA2B;AAC7B;AACA;EACE,4BAA4B;AAC9B;;AAEA;EACE,eAAe;EACf,0BAA0B;AAC5B","sourcesContent":["\n.bs-text {\n  line-height: 1.5;\n}\n\n.bs-text-standard {\n  font-size: 14px;\n}\n.bs-text-tiny {\n  font-size: 8px;\n}\n.bs-text-small {\n  font-size: 11px;\n}\n.bs-text-large {\n  font-size: 18px;\n}\n.bs-text-huge {\n  font-size: 24px;\n}\n\n.bs-text-bold {\n  font-weight: bold;\n}\n.bs-text-italic {\n  font-style: italic;\n}\n.bs-text-underline {\n  text-decoration: underline;\n}\n.bs-text-capitalize {\n  text-transform: capitalize;\n}\n.bs-text-super{\n  vertical-align: super;\n}\n\n.bs-text-justify {\n  text-align: justify;\n}\n.bs-text-left {\n  text-align: left;\n}\n.bs-text-center {\n  text-align: center;\n}\n.bs-text-right {\n  text-align: right;\n}\n\n.bs-text-black {\n  color: var(--std-color-black);\n}\n.bs-text-orange {\n  color: var(--std-color-orange);\n}\n.bs-text-white {\n  color: var(--std-color-white);\n}\n.bs-text-light-gray {\n  color: var(--std-color-gray-light);\n}\n.bs-text-gray {\n  color: var(--std-color-gray);\n}\n.bs-text-dark-gray {\n  color: var(--std-color-gray-dark);\n}\n.bs-text-green {\n  color: var(--std-color-green);\n}\n.bs-text-red {\n  color: var(--std-color-red);\n}\n.bs-text-stdColor {\n  color: var(--std-color-text);\n}\n\n.bs-text-clickable:hover {\n  cursor: pointer;\n  text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
