// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-filter-icon {
  margin-right: 4px;
}
.bs-filter-state, .bs-filter-state-name{
  color: white;
  padding:5px;
}

.bs-filter-state-title{
  font-size: 1.1em;
}
.bs-bg-open {
  color: #f77448;
}
.bs-bg-in_progress {
  color: #6b98b5;
}
.bs-bg-planned {
  color: #5dc9e6;
}
.bs-bg-resolved {
  color: #89cc1f;
}
.bs-bg-transferred {
  color: #e78fa5;
}
.bs-bg-rejected {    
  color: #aaaa5a;
}
.bs-bg-deleted {
  color: #a09696;
}
.bs-bg-merged {
  color: #e01a6c
}
.bs-bg-finished {
  color: #1fcc36
}
.bs-bg-to_do {
  color: #f77448
}
.bs-bg-ongoing {
  color: #396988
}`, "",{"version":3,"sources":["webpack://./src/entities/filters/state.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE,cAAc;AAChB;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF","sourcesContent":[".bs-filter-icon {\n  margin-right: 4px;\n}\n.bs-filter-state, .bs-filter-state-name{\n  color: white;\n  padding:5px;\n}\n\n.bs-filter-state-title{\n  font-size: 1.1em;\n}\n.bs-bg-open {\n  color: #f77448;\n}\n.bs-bg-in_progress {\n  color: #6b98b5;\n}\n.bs-bg-planned {\n  color: #5dc9e6;\n}\n.bs-bg-resolved {\n  color: #89cc1f;\n}\n.bs-bg-transferred {\n  color: #e78fa5;\n}\n.bs-bg-rejected {    \n  color: #aaaa5a;\n}\n.bs-bg-deleted {\n  color: #a09696;\n}\n.bs-bg-merged {\n  color: #e01a6c\n}\n.bs-bg-finished {\n  color: #1fcc36\n}\n.bs-bg-to_do {\n  color: #f77448\n}\n.bs-bg-ongoing {\n  color: #396988\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
