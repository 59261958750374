import { initialState as IssuesState } from 'client/features/issues/reducers'
import { initialState as SessionState } from 'client/features/session/reducers'
import { initialState as AppState } from 'client/features/app/reducers'
import { initialState as HomeState } from 'client/features/home/reducers'
import { initializeState as CalendarInitializeState } from 'client/features/calendar/reducers'
import { initialState as StatisticsState } from 'client/features/statistics/reducers'
import { initialState as NotificationsState } from 'client/features/notifications/reducers'

const initializeStore = (req, lang) => {
  const CalendarState = CalendarInitializeState()
  if (!req.session || !req.session.user) {
    const session = {
      ...SessionState,
    }
    return {
      // router: connectRouter({}),
      session,
      app: {
        ...AppState,
        lang: lang
      },
      issues: {
        ...IssuesState,
        currentLoading: session.starter ? true : false
      },
      home: {
        ...HomeState
      },
      calendar: {
        ...CalendarState
      },
      statistics: {
        ...StatisticsState
      },
      notifications: {
        ...NotificationsState
      },
      // userPros: {
      // }
    }
  }
  try {
    const session = {
      ...SessionState,
      loggingIn: true,
      starter: Boolean(req.session.user.starter),
      ...req.session,
      ...req.session.data
    }
    delete session.data
    let defaultFilters = req.session.user.issuesFilters.find(preset => preset.default)
    if (defaultFilters)
      defaultFilters = defaultFilters.value;
    else
      defaultFilters = IssuesState.filters.values;
    const state = {
      // router: connectRouter({}),
      session,
      app: {
        ...AppState,
        lang: lang,
        currentPathname: req.url,
        //lastMain: PAGE.mains.indexOf(req.url) !== -1 ? req.url : URLS.issues.index
      },
      issues: {
        ...IssuesState,
        filters: {
          ...IssuesState.filters,
          issueFields: req.config.filters,
          agentFields: req.config.agentFilters,
          values: defaultFilters,
          sort: 'created'
        }
      },
      home: {
        ...HomeState,
        filters: {owner : req.session.user.homeFilters.managers}
      },
      calendar: {
        ...CalendarState,
        filters: {
          ...CalendarState.filters,
          values: req.session.user.calendarFilters
        },
        dates: {
          ...CalendarState.dates,
          //current: moment(new Date()).startOf('day')
        },
      },
      statistics: {
        ...StatisticsState,
        filters: {
          ...StatisticsState.filters,
          fields: req.config.statsFilters
        }
      },
      notifications: {
        ...NotificationsState
      },
      supplies: {
        ...({ all: [], current: null}),
        all: req.session.data.supplies
      }
    }
    if(state.userPros){
      delete state.userPros;
    }
    return state;
  } catch (err) {
    throw err
  }
}

export default initializeStore