import { NotificationUsed } from '@uTypes/business/Notification';
import ObjectId from '@universal/types/technic/ObjectId';
import dayjs from 'dayjs';

class NotificationBuilder{
  private _seen: null | Date;
  
  private _allRead: null | Date;

  private _reads: Record<string, Date>;

  private _lots: Record<string, any>;

  private _notifs: Array<NotificationUsed>;

  private _notUpdatables: Array<any>;

  private _updated: boolean;

  constructor(){
    this._seen    = null;
    this._allRead = null;
    this._reads   = {};
    this._lots    = {};
    this._notifs  = [];
    this._notUpdatables = [];
    this._updated = false;
  }
  markSeen(date: Date){
    if(!this._seen || date.getTime() > this._seen.getTime()){
      this._seen = date;
    }
    Object.values(this._lots).forEach(lot => {
      this._notUpdatables.push(lot);
    });
    this._lots = {};
    this._updated = true;
  }
  markIssueRead(issueId, date: Date){
    if(!this._reads[issueId] || date.getTime() > this._reads[issueId].getTime()){
      this._reads[issueId] = date;
    }
  }
  markAllRead(date: Date){
    if(!this._allRead || date.getTime() > this._allRead.getTime()){
      this._allRead = date;
    }    
  }
  _getIssueLot(issue){
    if(!this._lots[issue._id]){
      this._lots[issue._id] = {
        issue
      };
    }
    return this._lots[issue._id];
  }
  _isRead(issueId: ObjectId, notificationCreatedAt: Date){
    const notificationTime = dayjs(notificationCreatedAt).toDate().getTime();
    return (this._allRead && this._allRead.getTime() > notificationTime)
      || (this._reads[issueId] && this._reads[issueId].getTime() > notificationTime);
  }
  _isSeen(createdAt){
    return this._seen && this._seen.getTime() > createdAt.getTime();
  }
  addCreated(issue, createdAt, createdBy){
    this._getIssueLot(issue).create = {
      createdAt,
      createdBy,
      read: this._isRead(issue._id, dayjs(createdAt).toDate()),
      seen: this._isSeen(dayjs(createdAt).toDate())
    };
    this._updated = true;
  }
  addStateUpdated(issue, createdAt, createdBy, event){
    let issueLot = this._getIssueLot(issue);
    if(!issueLot.stateUpdate){
      issueLot.stateUpdate = {
        createdAt,
        createdBy,
        event,
        read: this._isRead(issue._id, dayjs(createdAt).toDate()),
        seen: this._isSeen(dayjs(createdAt).toDate())
      };
    }
    this._updated = true;
  }
  addUpdated(issue, createdAt, createdBy, event){
    let issueLot = this._getIssueLot(issue);
    if(!issueLot.update){
      issueLot.update = {
        createdAt,
        createdBy,
        more: 0,
        event,
        read: this._isRead(issue._id, dayjs(createdAt).toDate()),
        seen: this._isSeen(dayjs(createdAt).toDate())
      };
    }else{
      ++issueLot.update.more;
    }
    this._updated = true;
  }
  addAssigned(issue, createdAt, createdBy, event){
    let issueLot = this._getIssueLot(issue);
    if(!issueLot.assignment){
      issueLot.assignment = {
        createdAt,
        createdBy,
        more:0,
        read: this._isRead(issue._id, dayjs(createdAt).toDate()),
        seen: this._isSeen(dayjs(createdAt).toDate())
      };
    } else {
      ++issueLot.assignment.more;
    }
    this._updated = true;
  }
  addComment(issue, createdAt, createdBy, message){
    let issueLot = this._getIssueLot(issue);
    if(!issueLot.comment){ 
      issueLot.comment = {
        more: 0,
        createdAt,
        createdBy,
        creatorCitizen: createdBy.discriminator === "citizen",
        message,
        read: this._isRead(issue._id, dayjs(createdAt).toDate()),
        seen: this._isSeen(dayjs(createdAt).toDate())
      };
    }else{
      ++issueLot.comment.more;
    }
    this._updated = true;
  }
  _collect(){
    return this._notUpdatables.concat(Object.values(this._lots));
  }
  _flush(){
    if(!this._updated){
      return;
    }
    this._notifs = this._collect().reduce((notifications, lot) => {
      const notification: Partial<NotificationUsed> = {
        issue: lot.issue
      };

      if(lot.assignment){
        notification.type      = "assignment";
        notification.createdAt = lot.assignment.createdAt;
        notification.createdBy = lot.assignment.createdBy;
        notification.read      = lot.assignment.read;
        notification.seen      = lot.assignment.seen;
        notification.more      = lot.assignment.more;
        notification.event     = lot.assignment.event;
        notifications.push(notification);
      }else if(lot.create){
        notification.type      = "create";
        notification.createdAt = lot.create.createdAt;
        notification.createdBy = lot.create.createdBy;
        notification.read      = lot.create.read;
        notification.seen      = lot.create.seen;
        notifications.push(notification);
        
      } else if(lot.update){
        notification.type      = "update";
        notification.createdAt = lot.update.createdAt;
        notification.createdBy = lot.update.createdBy;
        notification.read      = lot.update.read;
        notification.seen      = lot.update.seen;
        notification.more      = lot.update.more;
        notification.event     = lot.update.event;
        notifications.push(notification);
      } 
      if(lot.stateUpdate){
        const notification: Partial<NotificationUsed> = {
          issue: lot.issue
        };
        notification.type      = "stateUpdate";
        notification.createdAt = lot.stateUpdate.createdAt;
        notification.createdBy = lot.stateUpdate.createdBy;
        notification.read      = lot.stateUpdate.read;
        notification.seen      = lot.stateUpdate.seen;
        notification.more      = lot.stateUpdate.more;
        notification.event     = lot.stateUpdate.event;
        notifications.push(notification);
      }
      if(lot.comment){
        const notification: Partial<NotificationUsed> = {
          issue: lot.issue
        };
        notification.type           = "comment";
        notification.more           = lot.comment.more;
        notification.createdAt      = lot.comment.createdAt;
        notification.createdBy      = lot.comment.createdBy;
        notification.creatorCitizen = lot.comment.creatorCitizen;
        notification.read           = lot.comment.read;
        notification.seen           = lot.comment.seen;
        notification.message        = lot.comment.message;
        notifications.push(notification);
      }
      return notifications as Array<NotificationUsed>;
    }, []).sort((l1, l2) => l2.createdAt.getTime() - l1.createdAt.getTime());
    this._updated = false;
  }
  get(){
    this._flush();
    return this._notifs;
  }
}

export default NotificationBuilder;