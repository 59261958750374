import React, { FunctionComponent, MouseEvent, PropsWithChildren } from "react";
import _                from "lodash";
import T                from "@cBehaviour/i18n";
import Filter           from "@cComponents/filter";
import Input            from "@cComponents/input";
import Application      from "@uBehaviour/application";
import Highlight        from "@cComponents/highlight";
import Display from "@common/components/displayIf";
import { escapeRegExp } from "@uLib/tool";

import './search.css';

interface FakeAutoCompleteProps {
  onEdit: () => void;
  onDelete: () => void;
}

const FakeAutoComplete: FunctionComponent<PropsWithChildren<FakeAutoCompleteProps>> = ({ onEdit, onDelete, children }) => {
  const stopPropagationAndTransfertOnDelete = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onDelete();
  }, [onDelete]);

  const stopPropagationAndTransfertOnEdit = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onEdit();
  }, [onEdit]);

  return (
    <div className="bs-assignmentsAndIssues-search-fakeAutoComplete" onClick={ stopPropagationAndTransfertOnEdit }>
      <div className="bs-assignmentsAndIssues-search-fakeAutoComplete-content"><span>{ children }</span></div>
      <div><span className="fa fa-times" onClick={ stopPropagationAndTransfertOnDelete } /></div>
    </div>
  );
}

type SearchProps = {
  issueIdPath: string;
  assignmentsPath: string;
  fluid?: boolean;
};

class Search extends React.Component<SearchProps>{
  private _searched: string;
  constructor(props){
    super(props);
    this._searched = "";
  }
  _search = (value) => {
    if(!value) return Promise.resolve([]);
    return this.props.api.service("sessions", "search").execute(value, this.props.currentTenant.currentId).then(results => 
      results.reduce((acc, result) => {
        if(result.type === "equipment") {
          acc.push({
            type: "equipmentConcerned",
            label: result.label,
            value: {
              ...result,
              type: "equipmentConcerned",
            }
          },
          {
            type: "equipmentUsed",
            label: result.label,
            value: {
              ...result,
              type: "equipmentUsed",
            }
          })
        }
        else acc.push({
          type: result.type,
          label: result.label,
          value: result
        });

        return acc;
      }, []));
  }
  _stringifySearchQuery = (value) => {
    switch(value.type){
      case "issue": return (<><T bind={{ issue: value.label }}>issue_filter_search_issue</T></>);
      case "building": return (<><T bind={{ building: value.label }}>issue_filter_search_building</T></>);
      case "equipmentConcerned": return (<><T bind={{ equipment: value.label }}>issue_filter_search_equipment_concerned</T></>);
      case "equipmentUsed": return (<><T bind={{ equipment: value.label }}>issue_filter_search_equipment_used</T></>);
      case "userpro": return (<><T bind={{ user: value.label }}>issue_filter_search_userpro</T></>);
      case "citizen": return (<><T bind={{ citizen: value.label }}>issue_filter_search_citizen</T></>);
      case "contains": return (<><T bind={{ searched: value.label }}>issue_filter_search_freeSearch</T></>);
    }
  }
  _buildSearchQuery = (value) => {
    switch(value.type){
      case "issue": return { [this.props.issueIdPath]: value.value };
      case "building": return { "location.building": value.value };
      case "equipmentConcerned": return { equipment: value.value };
      case "equipmentUsed": return { [`${this.props.assignmentsPath}.necessariesEquipments`]: value.value };
      case "userpro": 
        return { $or: [
          { createdBy: value.value },
          { manager: value.value },
          { updatedBy: value.value },
          { [this.props.assignmentsPath]: {"agents": value.value }}
        ]};
      case "citizen": return { createdBy: value.value };
      case "contains": { 
        const query = { $or: [
          { description: { $regex: value.value, $options: "i" }},
          {"location.address.locality": { $regex: value.value, $options: "i" }},
          {"location.address.street": { $regex: value.value, $options: "i" }},
          {"location.address.streetNumber": { $regex: value.value, $options: "i" }}
        ]};

        if (!Number.isNaN(parseInt(value.value))) {
          query.$or.push({ bsIdNumber: value.value });
        }
        return query;
      }
    }
  }
  _getSearchIcon(value){
    switch(value.type){
      case "issue": return "exclamation-circle";
      case "building": return "building";
      case "equipmentConcerned":
      case "equipmentUsed"     : return "wrench";
      case "userpro": return "institution";
      case "citizen": return "user";
    }
  }
  _getSearchPrefix(value){
    switch(value.type){
      case "equipmentConcerned": return <T>issue_filter_search_concerned</T>;
      case "equipmentUsed":      return <T>issue_filter_search_used</T>;
      default: return null;
    }
  }

  _onSearchChange(value, set){
    const excludes = [];
    switch(value.type){
      case "building": excludes.push("buildings", "subs_buildings"); break;
      case "equipmentConcerned":
      case "equipmentUsed"     : excludes.push("equipments"); break;
      case "userpro":
      case "citizen": excludes.push("creators"); break;
    }
    set(value, excludes);
  }

  _onKeyUp(ev, set, clear) {
    if (ev.code === "Enter" || ev.code === "NumpadEnter") {
      if(ev.target.value){
        set({
          type:"contains",
          value: escapeRegExp(ev.target.value),
          label: ev.target.value
        });
      }else{
        clear();
      }
    } else {
      this._searched = ev.target.value;
    }
  }

  render(){
    if (!this.props.assignmentsPath || !this.props.issueIdPath) {
      throw new Error('Les props "assignmentPath" et "issueIdPath" doivent être définies dans le composant Search');
    }
    return (
      <Filter.Generic name="text" buildQuery={this._buildSearchQuery} stringify={this._stringifySearchQuery}>
      {(value, set, clear) => (
        <>
          <Highlight.Controller type={ value?.type } highlight={ value?.type === 'contains' ? value.value : null } />
          <Display.If condition={ value }>
            <Display.Then>
            {() => (
              <FakeAutoComplete onEdit={ clear } onDelete={() => { this._searched = ""; clear(); }}>
                <div>
                  <span className={`fa fa-${this._getSearchIcon(value)}`} />
                  &nbsp;
                  { this._stringifySearchQuery(value) }
                </div>
              </FakeAutoComplete> 
            )}
            </Display.Then>
            <Display.Else>
              <Input.Autocomplete 
                delay={ 500 }
                search={ this._search }
                searched={ this._searched }
                value={ value }
                onChange={ value => this._onSearchChange(value, set) }
                onKeyUp={ ev => this._onKeyUp(ev, set, clear) }
                fluid={ this.props.fluid }
                focusOnMount
              >
                <Input.Autocomplete.Placeholder>
                  <T>freesearch</T>
                </Input.Autocomplete.Placeholder>
                <Input.Autocomplete.Item>
                {(value, label) => (
                  <div>
                    <span className={`fa fa-${this._getSearchIcon(value)}`} />
                    &nbsp;
                    { this._getSearchPrefix(value) }
                    { label }
                  </div>
                )}
                </Input.Autocomplete.Item>
              </Input.Autocomplete>
            </Display.Else>
          </Display.If>
        </>
      )}
      </Filter.Generic>
    );
  }
}

export default Application.Service.forward(["api", "currentTenant"], Search);
