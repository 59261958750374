import Accessor from './components/accessor';
import PrefixBorder from './components/prefixBorder';
import Prefix from './components/prefix';
import useDataTestId from './hooks/useDataTestId';

export { 
  useDataTestId,
  Accessor,
  PrefixBorder,
  Prefix,
};

export default {
  useDataTestId,
  Accessor,
  PrefixBorder,
  Prefix,
};
