import React    from "react";
import Layout   from "@components/formLayout";
import Form     from '@cBehaviour/form';
import T        from "@cBehaviour/i18n";
import Team     from "@entities/team";
import Observer from "@common/lib/observer";
import Display  from "@cComponents/displayIf";


export default class TeamForm extends React.Component{
  constructor(props){
    super(props);
    this._observer = new Observer();
  }
  render(){
    const props = this.props;
    
    const id = props.id === "new" ? null : props.id;
    
    return (
      <Layout close={ props.close }>
        <Layout.Title>
          <Form.Observer observer={ this._observer }>
          {team => (
            <div>
              <T>team</T>
              <span> --- </span>
              <span>{ team.name }</span>
            </div>
          )}
          </Form.Observer>
        </Layout.Title>
        <Layout.Form>
          <Team.Form id={ id } observer={ this._observer }/>
        </Layout.Form>
        {/* <Layout.Log>
          <Display.If condition={ id !== null }>
          {() => (
            <Team.Log id={ props.id } />
          )}
          </Display.If>
        </Layout.Log> */}
      </Layout>
    )
  };
}