import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import toDic        from "@cLib/toDic";
import Application  from '@uBehaviour/application';
import User         from "@entities/user";

export default Application.Service.forward(["api", "currentTenant", "repository"], props => (
  <Filter.Generic 
    deshydrate={value => value._id}
    hydrate={values => props.api.service("users", "get").execute({_id: {$in: values }}, null, null, values.length).then(users => toDic(values, v => v, (v) => users.find(u => u._id === v)))}
    multiple
    name="agents"
    buildQuery={values => ({
        $or : [
          { "assignments.agents": { $in: values.map(v => v._id) } },
          { "assignments.team":   { $in: values.map(v => v.team?._id) }}
        ],
      })
    }
    stringify={value => { return (<><T>issue_filter_agents</T> : {value.fullname}</>)}}
  >
    {(values, add, drop, clear) => {
      let query = { tenants: { $elemMatch: { tenant: props.currentTenant.currentId, roles: { $in: ["agent"] }}}};
      if(values.length){
        query = { $and: [query, { _id: { $nin: values.map(v => v._id) }}]};
      }
      return (
        <Collapsable>
          <Collapsable.Title><T>issue_filter_agents</T></Collapsable.Title>
          <Collapsable.Content>
            <Input.Selectable 
              value={ values.map(v => props.repository.get("User").key.extract(v))}
              onChange={(vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }}
              model={ props.repository.get("User") }
              load={{ avatar:true }}
              query={ query }
              sort={{ fullname: 1 }}
              textify={ user => user.fullname }
              filterQuery={ value => ({ fullname: { '$regex': value, '$options': 'i' } }) }
            >
              <User.Item />
            </Input.Selectable>
          </Collapsable.Content>
        </Collapsable>
      )}}
  </Filter.Generic>
));