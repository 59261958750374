import _ from 'lodash'

const METHODS = ['get', 'post', 'put', 'delete']
const REQUEST = 'REQUEST'
const SUCCESS = 'SUCCESS'
const FAILURE = 'FAILURE'
const CANCEL = 'CANCEL'
const PROGRESS = 'PROGRESS'
const TYPES = [REQUEST, SUCCESS, FAILURE, CANCEL, PROGRESS]

/*
    #Methods
        ACTION: action type
        action: Object who must be dispatched by the store
        actions: Requests objects (REQUEST, SUCCESS, FAILURE, CANCEL)
        types: Requests types (REQUEST, SUCCESS, FAILURE, CANCEL)
    #OPTIONS
        excludedMethods: ['get', 'post', 'put', 'delete']
        moreMethods: ['method1', 'method2']                //Adds methods to defaults
        overwrite: Boolean                                 //Overwrite defaults methods with 'moreMethods'
        TODO: moreTypes: ['step1', 'step2']                      //Adds types to defaults (['REQUEST', 'SUCCESS', ..., 'ADDED_STEP'])
*/

class RequestsActions {
  constructor(KEY, options = {}) {
    this.KEY = KEY
    this.options = options
    this.methods = METHODS.filter(method =>
      !options.excludedMethods || !options.excludedMethods.includes(method.toLowerCase())
    )
    if (options.moreMethods && !options.overwrite)
      this.methods = this.methods.concat(options.moreMethods)
    else if (options.moreMethods)
      this.methods = options.moreMethods
    this.initMethods()
  }

  static createRequestActions(REQUEST_TYPES, moreTypes = []) {
    return TYPES.concat(moreTypes).reduce((actions, type) => {
      actions[_.camelCase(type)] = (payload) => RequestsActions.action(REQUEST_TYPES[type], { payload })
      return actions
    }, {})
  }

  static createRequestTypes(base, moreTypes = []) {
    return TYPES.concat(moreTypes).reduce((types, type) => {
      types[type.toUpperCase()] = `${base}_${type.toUpperCase()}`
      return types
    }, {})
  }

  static action(type, payload = {}) {
    return { type, ...payload }
  }

  initMethods() {
    this.methods.forEach((method) => {
      this[method] = this.initMethod(method)
    })
  }

  initMethod(method) {
    const result = {}
    result.ACTION = `@@RA_${method.toUpperCase()}_${this.KEY}`
    result.TYPES = RequestsActions.createRequestTypes(`@@RA_${method.toUpperCase()}_${this.KEY}`, this.options.moreTypes)
    result.actions = RequestsActions.createRequestActions(result.TYPES, this.options.moreTypes)
    result.action = (payload) => RequestsActions.action(result.ACTION, payload)
    return result
  }
}

export default RequestsActions

