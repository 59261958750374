import React        from 'react';
import Data         from "@uBehaviour/data";
import T            from "@cBehaviour/i18n";
import File         from '@cComponents/file';
import Formater     from "@cBusiness/format";
import Display      from '@cComponents/displayIf';
import Item         from '@cComponents/item';
import Actions      from './actions';
import Title        from '@cComponents/title';

import './item.css';
const TeamItem = (props) => {
    const team = props.data;
    return (
        <Item className="bs-team-item">
            <Item.Illustration>
              <File file={team.avatar ? team.avatar : null} width={100} height={100} fit="contain" />
            </Item.Illustration>
            <Item.Content.Header>
                <Title>{team.name}</Title>
            </Item.Content.Header>
            <Item.Content.Body>
                <div>
                    <span><T>type</T> : </span>
                    <span>{team.type === "internal" ? <T>internal</T> : <T>external</T> }</span>
                </div>
            </Item.Content.Body>
            <Item.Content.Footer>
                <Display.If condition={team.members.length}>
                    <div>
                        <span><T>members</T> : </span>
                        <span>
                            { team.members.map((user, idx) => React.createElement(
                                Data.Synchronizer, {
                                    entity: user,
                                    key: user.id
                                }, (user) => (idx !== 0 ? ", " : "") + Formater.User.longFullname(user))                                
                            )}
                        </span>
                    </div>               
                </Display.If>
            </Item.Content.Footer>
            <Item.Actions>
                <Actions team={ props.data }/>
            </Item.Actions>
        </Item>
    );
}

export default TeamItem;