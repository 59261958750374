import React          from "react";
import Application    from "@uBehaviour/application";
import data           from "@uBusiness/data";
import T              from "@cBehaviour/i18n";
import Filter         from "@cComponents/filter";
import Input          from "@cComponents/input";
import Collapsable    from "@cComponents/collapsable";
import toDic          from "@cLib/toDic";
import Filters        from "@entities/filters";

import "./filters.css";
import Acl from "@universal/behaviour/acl";

const workOrderStates = data.workOrderStates.slice();

const assignToMe = [{
  _id: "me",
  label: "issue_filter_assignedToMe_toMe",
  query: (user) => {
    return { "assignment.agents": user._id };
  }
}, {
  _id: "team",
  label: "issue_filter_assignedToMe_toMyTeam",
  query: (user) => {
    return { "assignment.team": user.team };
  }
}];

export default Application.Service.forward(["i18n", "repository", "session", "currentTenant", "api"], props => (
  <div className="bs-assignments-filters-containers">
    {/* Filtre sur type */}
    <Filters.Type />
    {/* Filtre sur statuts des bons de travail */}
    <Filter.Generic 
      multiple
      name="workOrderState"
      buildQuery={values => ({ "assignment.progress": { $in: values } })}
      stringify={value => (<T>{value}</T>)}
    >
      {(values, add, drop, clear) => (
        <Collapsable alwaysOpen>
          <Collapsable.Title><T>issue_filter_workOrderStates</T></Collapsable.Title>
          <Collapsable.Content>
            <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
              {
                workOrderStates.map(workOrderState => (
                  <Input.Checkbox.Value key={ workOrderState } value={ workOrderState }>
                    <span><span className={`bs-filter-icon fa fa-stop bs-bg-${workOrderState}`} /><T>{ workOrderState }</T></span>
                  </Input.Checkbox.Value>
                ))
              }
            </Input.Checkbox.BtnFilter>
          </Collapsable.Content>
        </Collapsable>
      )}
    </Filter.Generic>
    {/* Filtre sur mes assignations */}
    <Filter.Generic
      deshydrate={value => value._id}
      hydrate={values => toDic(values, v => v, (v) => assignToMe.find(a => a._id === v))}
      multiple
      name="assignedToMe"
      buildQuery={values => ({ $or: values.map(v => v.query(props.session.user)) })}
      stringify={value => (<><T>issue_filter_assignedToMe_assigned</T> : <T>{value.label}</T></>)}
    >
    {(values, add, drop, clear) => (
      <Collapsable alwaysOpen>
        <Collapsable.Title><T>issue_filter_assignedToMe</T></Collapsable.Title>
        <Collapsable.Content>
          <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
          {
            assignToMe.filter(a => a._id !== "team" || props.session.user.team).map(a => (
              <Input.Checkbox.Value key={ a.label } value={ a }>
                <span><T>{ a.label }</T></span>
              </Input.Checkbox.Value>
            ))
          }
          </Input.Checkbox.BtnFilter>
        </Collapsable.Content>
      </Collapsable>
    )}
    </Filter.Generic>
    <Acl.If resource="assignments" action="readBuilding">
      {/* Filtre sur bâtiments */}
      <Filters.Building />
      {/* Filtre sur les sous-bâtiments*/}
      <Filters.SubBuilding />
    </Acl.If>
    <Acl.If resource="assignments" action="readEquipment">
      {/* Filtre sur les équipements */}
      <Filters.Equipment />
    </Acl.If>
    {/* Filtre sur les localités */}
    <Filters.Localities />
    {/* Filtre sur le fait de suivre un signalement*/}
    <Filters.Followed />
    {/* Filtre sur la date de création */}
    <div className="bs-filter-period">
      <Filters.CreatedAt />
    </div>
  </div>
));