import RequestsActions from 'client/core/RequestsActions'
import { action } from 'client/core/actions'

/*isAuth: check if user is logged.*/
export const IsAuthRequest = new RequestsActions('IS_AUTH', { excludedMethods: ['post', 'put', 'delete'], moreMethods: ['cool'], moreTypes: ['test'] })

/*Login form*/
export const LoginRequest = new RequestsActions('LOGIN', { excludedMethods: ['get', 'put', 'delete'] })

/*Request new password*/
export const PasswordRequest = new RequestsActions('REQUEST_PASSWORD', { excludedMethods: ['get', 'put', 'delete'] })

/*Reset password*/
export const ResetPasswordRequest = new RequestsActions('RESET_PASSWORD', { excludedMethods: ['get', 'put', 'delete'] })

/*Magic login */
export const MagicLoginRequest = new RequestsActions('MAGIC_LOGIN', { excludedMethods: ['get', 'put', 'delete'] })

/*Logout*/
export const LogoutRequest = new RequestsActions('LOGOUT', { excludedMethods: ['post', 'put', 'delete'] })

/*Current user*/
export const CurrentUserRequest = new RequestsActions('CURRENT_USER', { excludedMethods: ['post'] })

/*Hashtags*/
export const SESSION_HASHTAGS_UPDATED = 'SESSION_HASHTAGS_UPDATED'
export const hashtagsUpdated = (payload) => action(SESSION_HASHTAGS_UPDATED, payload)


/*Disable confirmations*/
export const SESSION_DISABLE_CONFIRMATION = 'SESSION_DISABLE_CONFIRMATION'
export const disableSessionConfirmation = (confirmation) => action('SESSION_DISABLE_CONFIRMATION', { confirmation })


/*Confirmations*/
export const ADD_USER_CONFIRMATION = 'ADD_USER_CONFIRMATION'
export const addUserConfirmation = (payload) => action(ADD_USER_CONFIRMATION, { payload })
export const REMOVE_USER_CONFIRMATION = 'REMOVE_USER_CONFIRMATION'
export const removeUserConfirmation = (payload) => action(REMOVE_USER_CONFIRMATION, { payload })

/*Update user attribute*/
export const UPDATE_USER_ATTRIBUTE = 'UPDATE_USER_ATTRIBUTE'
export const updateUserAttribute = (payload) => action(UPDATE_USER_ATTRIBUTE, { payload })

//Update session
export const UPDATE_SESSION = 'UPDATE_SESSION'
export const updateSession = () => action (UPDATE_SESSION)