import T from '@universal/behaviour/i18n';
import React, { FunctionComponent } from 'react'
import _ from "lodash";
import useService from '@universal/behaviour/hooks/useService';

import I18nService from '@universal/services/i18n';
import ConfigurationService from '@universal/services/configuration';

const CommunicationKit: FunctionComponent = () => {
  const i18nService = useService<I18nService>("i18n");
  const configurationService = useService<ConfigurationService>("configuration");
  const [ alreadyDownloaded, setAlreadyDownloaded ] = React.useState(false);

  const possiblyPreventDownload = React.useCallback((ev: React.MouseEvent<HTMLElement>) => {
    if (alreadyDownloaded) {
      ev.preventDefault();
      return;
    }
    setAlreadyDownloaded(true);
    setTimeout(() => setAlreadyDownloaded(false), 5000);
  }, [ alreadyDownloaded ]);

  return (
    <a onClick={ possiblyPreventDownload } href={configurationService.get("communicationKitLink")} download><T>communication_kit_label</T></a>
  )
}

export default CommunicationKit;