import * as React from 'react';

import BuilderContext from './dataTestIdBuilderContext';
import StringBuilder  from '../lib/stringBuilder';

export interface Props extends React.PropsWithChildren {
  /**
   * Valeur du préfixe
   */
  value: string;
}
/**
 * Composant permettant de rajouter un préfixe au composant enfant utilisant
 * soit le composant Accessor soit le hook useDataTestId
 */
const Prefix: React.FunctionComponent<Props> = ({ value, children }) => {
  const builder: StringBuilder = React.useContext(BuilderContext);

  const prefixedBuilder = React.useMemo(() => builder.add(value), [builder, value]);

  return (
    <BuilderContext.Provider value={prefixedBuilder}>
      {children}
    </BuilderContext.Provider>
  );
};

export default Prefix;
