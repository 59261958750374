import React, { FunctionComponent } from 'react';
import Standarts from './standarts';
import FieldGroup from '@components/fieldGroup';

import './holidayDays.css';
import Generics from './generics';
import { HolidayDays as HolidayDaysType } from '@universal/types/business/Tenant';

interface HolidayDaysProps {
  onChange: (value: HolidayDaysType) => void,
  value: HolidayDaysType;
  country: string
}

const HolidayDays: FunctionComponent<HolidayDaysProps> = ({ onChange, value, country }) => {
  const sendStandartsChange = React.useCallback((standarts) => {
    onChange({ ...value, standarts })
  }, [onChange, value]);


  const sendGenerictsChange = React.useCallback((generics) => {
    onChange({ ...value, generics })
  }, [onChange, value]);

  return (
    <div className="bs-tenant-form-holidayDays">
      <div>
        <FieldGroup subTitle="tenant_form_holidayDay_standart" type="full">
          <Standarts value={ value.standarts } onChange={ sendStandartsChange } country={country} />
        </FieldGroup>
      </div>
      <div>
        <FieldGroup subTitle="tenant_form_holidayDay_generic" type="full">
          <Generics value={ value.generics } onChange={ sendGenerictsChange } />
        </FieldGroup>
      </div>
    </div>
  );
};

export default HolidayDays;