import * as ActionTypes from 'client/features/flashes/actions'
import _ from 'lodash'

import { registerDefaultReducer, registerInitialstate } from "client/lib/reduxSaga";

const initialState = {
  all: []
}

const initializeState = () => Object.assign({}, initialState)


registerInitialstate("flashes", initializeState);
registerDefaultReducer("flashes", function flashes(state = initializeState(), action = {}) {
  switch (action.type) {

  case ActionTypes.NEW_FLASH:
    return { ...state, all: [...state.all, action.flash] }

  case ActionTypes.HIDE_FLASH:
    return { ...state, all: _.without(state.all, _.find(state.all, { date: action.flash.date })) }

  default:
    return state
  }
});