import React       from "react";
import Action      from "@cComponents/action";
import T           from "@uBehaviour/i18n";
import Nudge       from '@cComponents/nudge'
import Application from "@uBehaviour/application";

import './actions.css'

const MapLayerActions = Application.forward([], [["mapLayer", "mapLayerHelper"]], (props) => (
  <Action 
    id="remove_mapLayer"
    icon="trash"
    onClick={(ev) => { props.mapLayerHelper.remove(props.mapLayer._id)}}
  >
      <Action.Tooltip>
        <div className="bs-mapLayer-action-tooltip-container">
          <Nudge>
            <T>mapLayer_item_remove</T>
          </Nudge>
        </div>
      </Action.Tooltip>
  </Action>
));

export default MapLayerActions;