// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-unauthenticated-hsForm-container {
  font-family: "Open Sans", sans-serif; /* Police renseignée sur la maquette, à rendre globale ? */
  height: 100%;
  background-image: url("/images/authentification/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.bs-unauthenticated-hsForm-content {
  padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/applications/unauthenticated/views/hsForm/hsForm.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC,EAAE,0DAA0D;EAChG,YAAY;EACZ,gEAAgE;EAChE,sBAAsB;EACtB,4BAA4B;;EAE5B,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf","sourcesContent":[".bs-unauthenticated-hsForm-container {\n  font-family: \"Open Sans\", sans-serif; /* Police renseignée sur la maquette, à rendre globale ? */\n  height: 100%;\n  background-image: url(\"/images/authentification/background.jpg\");\n  background-size: cover;\n  background-repeat: no-repeat;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.bs-unauthenticated-hsForm-content {\n  padding: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
