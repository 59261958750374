import React, { PureComponent } from 'react'
import T from 'i18n-react'
import { Modal } from 'react-bootstrap'
import Search from 'client/features/issues/components/filters/Search.jsx'
import IssueListItem from 'client/features/issues/components/lists/Item.jsx'
import { 
    IssuesAutocompleteRequest,
    IssueRequest
} from 'client/features/issues/actions'
import { getIssueFromAllByBsId } from 'client/features/issues/lib'
import { connect } from 'react-redux'
import { getIssueFromAll } from 'client/features/issues/lib'

class IssueMergeModal extends PureComponent {
    constructor() {
        super()
        this.lastSearchChange = null
        this.state = {
            toMerge: null
        }
    }
    merge = () => {
      let issueId = null;
      if (this.state.toMerge)
        issueId = getIssueFromAllByBsId(this.props.all, this.state.toMerge)._id;
      else
        issueId = this.props.slaveIssue._id;

      this.updateIssue('merge', {
        payload: {
          slaveIssue: issueId
        }
      })
      this.props.hide();
    }

    updateIssue = (type, data = {}) => {
      if (!type)
        throw new Error('Update issue: missing type')
      const req = {
        ...data,
        params: {
          id: this.props.issueId || this.state.issueId,
          type
        },
        actionData: {
          type
        }
      }
      this.props.dispatch(IssueRequest.put.action(req))
    }

    searchChange = (value) => {
        const now = new Date().getTime()
        this.lastSearchChange = now
        setTimeout(() => {
            if (this.lastSearchChange === now)
                this.props.dispatch(IssuesAutocompleteRequest.get.action({
                    payload: { value: value.replace('#', '____hash____tag'), select: ['bsId'] }
                }))
        }, 140)
    }

    onSearchSelected = (item) => {
        this.props.dispatch(IssueRequest.get.action({ params: { id: item.value },
            actionData: {
                noRedirect: true
            } }))
        this.setState({ toMerge: item.value })
    }

    resetSearch() {
        
    }

    render() {
        const { show, hide, merge, issue, user } = this.props
        const alreadySelected = this.props.slaveIssue && this.props.slaveIssue._id ? true : false
        let { slaveIssue } = this.props
        if ((!this.props.slaveIssue || !this.props.slaveIssue._id) && this.state.toMerge)
            slaveIssue = getIssueFromAllByBsId(this.props.all, this.state.toMerge)
        return(
            <Modal show={ show } onHide={ hide } aria-labelledby='contained-modal-title-lg' className='modal-helper' bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title>
                        { T.translate('merge') }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        !alreadySelected &&
                        <p>{ T.translate('merge_search_issue_explanation') }</p>
                    }
                    {
                        !alreadySelected &&
                        <div className='c-issues-navbar__search'>
                            <Search
                                onChange={ this.searchChange }
                                items={ this.props.autocompleteItems }
                                onSelect={ this.onSearchSelected }
                                resetSearch={ this.resetSearch }
                                onEnterPress={ () => null }
                                placeholder={ 'search_by_bs_id' }
                            />
                        </div>
                    }
                    {
                        slaveIssue && slaveIssue._id &&
                            <div>
                                { T.translate('merge_issue_explanation_1') } <strong>#{ slaveIssue.bsIdNumber }</strong> { T.translate('with') } <strong>#{ issue.bsIdNumber }</strong>.
                            </div>
                    }
                    {
                        slaveIssue && slaveIssue._id &&
                        <IssueListItem
                            issue={ slaveIssue }
                            goToIssue={ () => null }
                            showDistance={ false }
                            user={ user }
                            readOnly={ true }
                        />
                    }
                    {
                        slaveIssue && slaveIssue._id && !slaveIssue.noCover &&
                        <div className='u-margin-top-small'>{ T.translate('merge_issue_pictures') } #{ slaveIssue.bsIdNumber } { T.translate('will_be_added_to') } #{ issue.bsIdNumber }</div>
                    }
                    {
                        slaveIssue && slaveIssue._id && slaveIssue.sender && (!issue.sender || issue.sender._id !== slaveIssue._id) &&
                        <div className='u-margin-top-small'>
                            { T.translate('the_requestor') } ({ slaveIssue.sender.firstName } { slaveIssue.sender.lastName }) { T.translate('will_be_added_as_follower_to') } #{ issue.bsIdNumber }.
                        </div>
                    }
                    {
                        slaveIssue && slaveIssue._id && slaveIssue.requestor && slaveIssue.requestor.type === 'citizen' && slaveIssue.requestor.email &&
                        <div className='u-margin-top-small'>
                            { T.translate('the_requestor') } ({ slaveIssue.requestor.firstName ? `${slaveIssue.requestor.firstName} ` : '' }{ slaveIssue.requestor.lastName ? `${slaveIssue.requestor.lastName} ` : '' } { slaveIssue.requestor.email }) { T.translate('will_be_added_as_follower_to') } #{ issue.bsIdNumber }.
                        </div>
                    }
                    {
                        slaveIssue && slaveIssue._id && slaveIssue.followers && slaveIssue.followers[0] &&
                        <div className='u-margin-top-small'>
                            { T.translate('the_followers_of') } #{ slaveIssue.bsIdNumber } { T.translate('will_be_added_as_follower_to') } #{ issue.bsIdNumber }.
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className='button-a' onClick={ hide }>
                        { T.translate('cancel') }
                    </button>
                    <button className='button-a orange' onClick={ this.merge }>
                        { T.translate('merge') }
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default connect((state, props) => {
  const { issues, session } = state
  let issue = props.issue || getIssueFromAll(issues.all, issues.current)
  if(issues.lastAssignment && issue?.assignments){
    issue = JSON.parse(JSON.stringify(issue));
    issue.assignments = issue.assignments.filter(a => a._id === issues.lastAssignment);
  }
  return {
    all: issues.all,
    issue,
    issueId: props.issue ? props.issue._id : issues.current,
    autocompleteItems: issues.autocomplete,
    user: session.user
  };
})(IssueMergeModal);