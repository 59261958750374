import React from 'react';
import { Switch, Route, Redirect } from '@cFeatures/router';
import Transfer from './views/transfer';

const RedirectToIssue = ({ params: { id: issueId }}: { params: { id: string }}) => {
  return <Redirect to={`/issues/${issueId}`} fromRoot/>;
}

const Root = () => {
  return (
    <Switch>
      <Route path="/external/transfer/issues/:id" component={ RedirectToIssue } />
      <Route path="/issues/:id" component={ Transfer } />
    </Switch>
  );
}

export default Root;