import React from "react";
import Data  from "@uBehaviour/data";
import ScrollBar from "@cComponents/scrollBar";
import moment from "moment";
import _ from "lodash";

import "./list.css";
import { useDataTestId } from "@universal/features/dataTestId";

const dateFormat = "DD/MM/YYYY";

const List = ({ query, sort, title, dateField, model, children: Item, load, color = '#5e676c', debugPager }) => {
  const listGeneralTestId = useDataTestId('', ''); // Récupération du test-id défini dans le composant parent pour chaque colonne
  const listCounterNumberTestId = `info-${useDataTestId("list-counter-number")}`;
  const listDateTestId          = `info-${useDataTestId("list-date")}`;

  return (
    <div className="bs-home-list bs-home-box" data-testid={ listGeneralTestId }>
      <div className="bs-home-list-title bs-home-box-title">
        <span style={{ ["--bs-home-title-color"]: color }} className="bs-home-list-title-text">{ title }</span>
        <span data-testid={ listCounterNumberTestId }>
          <Data.Count model={model} query={query}>
          { nbr =>  nbr }
          </Data.Count>
        </span>
      </div>
      <div>
        <ScrollBar.ListController viewPortClassName="bs-home-list-viewport">
          <Data.List debugPager={ debugPager } model={model} query={ query } sort={ sort } limit={ 20 } load={ load }>
            <Data.List.Separator has={(previous, current) => !previous || moment(_.get(previous, dateField)).format(dateFormat) !== moment(_.get(current, dateField)).format(dateFormat)}>
            { data => (
              <div className="bs-home-list-date" data-testid={ listDateTestId }>{moment(_.get(data, dateField)).format(dateFormat)}</div>
            )}
            </Data.List.Separator>
            <Data.List.Item>
              { Item }
            </Data.List.Item>
          </Data.List>
        </ScrollBar.ListController>
      </div>
    </div>
  )
};

export default List;