import React from 'react';
import File from '@cComponents/file';
import Title from "@entities/issueAndAssignment/title";
import Subject from '@entities/assignmentsAndIssues/subject';
import State from '../state';
import UserAvatar from "@cFeatures/userAvatar";
import Period from "@components/period";
import WhoWhenWhat from '@cFeatures/whoWhenWhat';
import { useDataTestId } from '@universal/features/dataTestId';

import './standart.css';

const AssignedTo = ({ agents, team }) => (
  <span className="bs-item-assignment-assignedTo">
    <span className={`fa fa-${agents.length ? 'user': 'users'}`} />
    <span>
    {
      agents.length ? agents.map(agent => agent.fullname).join(", ") : team.map(t => t.name).join(", ")
    }
    </span>
  </span>
);


const Item = ({ data }) => {
  const assignmentDescriptionTestId = `info-${useDataTestId("assignment-description")}`;
  const assignmentItemTestId   = `inter-${useDataTestId("assignment")}`
  return (
    <div className="bs-item bs-item-assignment"  data-testid={ assignmentItemTestId }>
      <div>
        <File file={data.files.length ? data.files[0] : null} height={ 160 } width={ 160 }/>
      </div>
      <div className="bs-item-assignment-content">
        <Title 
          title={ data.bsIdNumber + "-" + data.assignment.bsIdNumber}
          category={ data.category }
          $followed={ data.$followed }
          priority={ data.priority }
          issueId={ data.issue }
          deadline={ data.deadline }
        />
        <div className="bs-item-assignment-address">
          <Subject location={ data.location || data.equipment?.location } equipment={ data.equipment } />
        </div>
        <div data-testid={ assignmentDescriptionTestId } className="bs-item-assignment-description">
          <span>{ data.description }</span>
        </div>
        <div className="bs-item-assignment-footer">
          <div><WhoWhenWhat who={ data.createdBy } when={ data.createdAt } what="assignment_item_create_at_by" /></div>
          <div><WhoWhenWhat who={ data.updatedBy } when={ data.updatedAt } what="assignment_item_update_at_by" /></div>
        </div>
      </div>
      <div>
        <div>
          <State.SelectCommentAndValorize assignment={ data.assignment } mustBeValorized={ data.mustBeValorized } issue={ data.issue } tenant={ data.assignment.tenant }/>
        </div>
        <div className="bs-item-assignment-meta">
          <div className="bs-item-assignment-meta-planification">
            <div>
              <div><AssignedTo agents={ data.assignment.agents } team={ data.assignment.team } /></div>
              <div><Period start={data.assignment.scheduledFrom} end={data.assignment.scheduledTo} /></div>
            </div>
          </div>
          { data.manager ? <UserAvatar user={ data.manager } /> : null }
        </div>
      </div>
    </div>
  );
};

Item.load = {
  manager: { avatar: 1 },
  createdBy: 1,
  updatedBy: 1,
  files: 1,
  category: 1,
  "assignment.agents": 1,
  "assignment.team": 1,
  equipment: 1,
  "equipment.location.building": 1
}

export default Item;