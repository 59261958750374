// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-starterActivation-label {
  margin-bottom: 5px;
}
.bs-starterActivation-label {
  margin-top: 20px;
}

.bs-activationStarter-email-acceptGeneralConditions{
  margin-top: 20px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.bs-activationStarter-email-acceptGeneralConditions>*:first-child {
  margin-right: 20px;

}

.bs-activationStarter-email-alreadyHaveAnAccount {
  margin-top: 40px;
}

.bs-activationStarter-almostCreated {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bs-activationStarter-almostCreated-scrollBar {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;
  overflow-y: hidden;
}

.bs-activationStarter-activation {
  padding: 0 5%;
  height: 100%;
}

.bs-activationStarter-columns {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.bs-activationStarter-finalize-bottom,
.bs-activationStarter-almostCreated-send,
.bs-activationStarter-almostCreated-advice {
  margin-top: 60px;
}

.bs-activationStarter-finalize-envelope{
  margin: 120px 0px;
  display: flex;
  justify-content: center;
  height: 10vh;
}
`, "",{"version":3,"sources":["webpack://./src/applications/starterActivation/views/activation.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,kBAAkB;;AAEpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,YAAY;EACZ,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX;;AAEA;;;EAGE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,YAAY;AACd","sourcesContent":[".bs-starterActivation-label {\n  margin-bottom: 5px;\n}\n.bs-starterActivation-label {\n  margin-top: 20px;\n}\n\n.bs-activationStarter-email-acceptGeneralConditions{\n  margin-top: 20px;\n  margin-bottom: 60px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n}\n.bs-activationStarter-email-acceptGeneralConditions>*:first-child {\n  margin-right: 20px;\n\n}\n\n.bs-activationStarter-email-alreadyHaveAnAccount {\n  margin-top: 40px;\n}\n\n.bs-activationStarter-almostCreated {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.bs-activationStarter-almostCreated-scrollBar {\n  flex-basis: 0;\n  flex-grow: 1;\n  flex-shrink: 1;\n  overflow-y: hidden;\n}\n\n.bs-activationStarter-activation {\n  padding: 0 5%;\n  height: 100%;\n}\n\n.bs-activationStarter-columns {\n  display: flex;\n  flex-direction: row;\n  gap: 20px;\n}\n\n.bs-activationStarter-finalize-bottom,\n.bs-activationStarter-almostCreated-send,\n.bs-activationStarter-almostCreated-advice {\n  margin-top: 60px;\n}\n\n.bs-activationStarter-finalize-envelope{\n  margin: 120px 0px;\n  display: flex;\n  justify-content: center;\n  height: 10vh;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
