import React from "react";
import T from "@uBehaviour/i18n";
import moment from 'moment';
import Format from '@uBusiness/format';

const WhoWhenWhat = ({ who, when, what }) => (
  <span>
    <T bind={{
      who: Format.User.shortFullname(who),
      when: moment(when).fromNow()
    }}>{ what }</T>
  </span>
);

export default WhoWhenWhat;