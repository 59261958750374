import Supply from "@uTypes/business/Supply";
import React, { FunctionComponent } from "react";
import Subject from "./subject";
import Data from "@universal/behaviour/data";

interface SupplySubjectProps {
  supply: Supply;
  del?: null | (() => void);
};
const SupplySubject: FunctionComponent<SupplySubjectProps> = ({ supply, del = null }) => (
  <Data.Synchronizer entity={ supply }>
  {(supply) => (
    <Subject icon="cubes" del={ del }>{ supply.name }</Subject>
  )}
  </Data.Synchronizer>
);

export default SupplySubject;