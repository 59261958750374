import React from "react";

import "./selectable.css";

const Selectable = ({ onClick, selected, children }) => (
  <div className={`bs-selectable${ selected ? " selected" : ""}`}>
    <div><span className={`fa ${selected ? "fa-check-square-o" : "fa-square-o" }`} onClick={ onClick }/></div>
    <div>{ children }</div>
  </div>
);

export default Selectable;