// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issue-state-comment {
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.bs-issue-state-comment-section>.bs-section-title-text { 
  border-bottom: 2px solid #cecbc8;
  margin-bottom: 15px;
}

.bs-issue-state-comment>.bs-section-header,
.bs-state-comment>.bs-section-footer {
  flex: 0 0 auto;
}

.bs-issue-state-comment-body {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1em;
  padding: 15px;
}

.bs-issue-state-comment-body > div {
  flex: 0 0 auto;
}

.bs-issue-state-comment-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: var(--std-size-inter-element);
}

.bs-modal-state-alert>*{
  padding: 12px;
}
.bs-modal-state-alert-assignments {
  margin: 0 10px;
}
.bs-modal-state-alert-footer{
  text-align: end;
}

`, "",{"version":3,"sources":["webpack://./src/entities/issue/state.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;EAChC,mBAAmB;AACrB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,QAAQ;EACR,aAAa;AACf;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;EACnB,kCAAkC;AACpC;;AAEA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB;AACA;EACE,eAAe;AACjB","sourcesContent":[".bs-issue-state-comment {\n  overflow: hidden;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.bs-issue-state-comment-section>.bs-section-title-text { \n  border-bottom: 2px solid #cecbc8;\n  margin-bottom: 15px;\n}\n\n.bs-issue-state-comment>.bs-section-header,\n.bs-state-comment>.bs-section-footer {\n  flex: 0 0 auto;\n}\n\n.bs-issue-state-comment-body {\n  overflow-y: auto;\n  display: flex;\n  flex-direction: column;\n  flex-grow: 1;\n  gap: 1em;\n  padding: 15px;\n}\n\n.bs-issue-state-comment-body > div {\n  flex: 0 0 auto;\n}\n\n.bs-issue-state-comment-footer {\n  display: flex;\n  justify-content: flex-end;\n  align-items: center;\n  gap: var(--std-size-inter-element);\n}\n\n.bs-modal-state-alert>*{\n  padding: 12px;\n}\n.bs-modal-state-alert-assignments {\n  margin: 0 10px;\n}\n.bs-modal-state-alert-footer{\n  text-align: end;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
