import React, { FunctionComponent, PropsWithChildren } from "react";
import Text, { Style } from "@cComponents/text";
import T from "@uBehaviour/i18n";
import Slot from "@uComponents/slot2";

const Title   = Slot();
const Content = Slot(); 

const PromotionalTemplate: FunctionComponent<PropsWithChildren> & { Title: typeof Title, Content: typeof Content } = ({ children }) => {
  const title   = Title.get(children);
  const content = Content.get(children);

  return (
    <div>
      <Text.Paragraph style={ Style.bold }>{ title }</Text.Paragraph>
      <Text.Paragraph>{ content }</Text.Paragraph>
    </div>
  )
};

const PromotionalSubtitle: React.FunctionComponent<PropsWithChildren> = ({ children }) => {
  return (
    <PromotionalTemplate>
      <PromotionalTemplate.Title><T>starter_promotion_interested</T></PromotionalTemplate.Title>
      <PromotionalTemplate.Content>
        { children }
      </PromotionalTemplate.Content>
    </PromotionalTemplate>
  );
};

PromotionalTemplate.Title = Title;
PromotionalTemplate.Content = Content;

export default PromotionalSubtitle;