import * as React from 'react';

import BuilderContext from '../components/dataTestIdBuilderContext';
import StringBuilder  from '../lib/stringBuilder';


/**
 * Hook permettant de récupérer un data-testid utilisant
 * tous les préfixes "parent" dans l'arbre React
 */
const useDataTestId = (dataTestId: string, separator = '-'): string => {
  const builder: StringBuilder = React.useContext(BuilderContext);

  const completeDataTestId: string = React.useMemo(
    () => builder.add(dataTestId).build(separator),
    [builder, dataTestId, separator],
  );

  return completeDataTestId;
};

export default useDataTestId;
