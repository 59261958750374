import React, { PureComponent } from 'react'
import classNames from 'classnames'
import T from 'i18n-react'
import randomKey from 'random-key'
import ReactTooltip from 'react-tooltip'
import FontAwesome from 'react-fontawesome'

import Title from './Title'
import Tooltip from 'client/features/common/components/Tooltip.jsx'
import Picture from 'client/features/issues/components/lists/Picture.jsx'
import IssueStateBannerSVG from '../common/IssueStateBannerSVG'
import Description from 'client/features/issues/components/lists/Description.jsx'
import Popover from 'client/features/issues/components/Popover.jsx'
import Address from 'client/features/overlay-view/components/Address.jsx' 
import IssueListItemSelector from 'client/features/issues/components/lists/Selector.jsx'
import _ from 'lodash';
 

const SmallItemContent = function ({ issue, highlight, freeSearch, showDistance, unlink, link, merge, selectActive, selected, select, expend }) {
    return <div>
        <div className='c-issue__image u-position-relative'>
            <Picture issue={ issue } />
            <IssueListItemSelector
                selectActive={ selectActive }
                selected={ selected }
                select={ select }
                issueId={ issue._id }
            />
        </div>

        <div className='c-issue__content'>
            <div>
                <div className='c-issue__title'>
                    <Title
                        issue={ issue }
                        short={ true }
                        highlight={ highlight }
                        freeSearch={ freeSearch }
                        noDeadline
                    />
                    {
                        link && 
                            <span className='button-a small clear' onClick={ link }>
                                <FontAwesome name='link'/> { T.translate('link') }
                            </span>
                    }
                    <span className={ 'c-issue__state' }>
                        <IssueStateBannerSVG state={ issue.state } workOrderState={ issue.workOrder.state }/>
                    </span>
                </div>
                <div className='c-issue__location'>
                    <div className='c-issue__address'>

                        <Address
                            place={ issue.place }
                            locationInfo={ issue.locationInfo }
                            roadInfo={ issue.roadInfo }
                            address={ issue.subject }
                            cut={ (showDistance && issue.distance >= 0) ? 25 : 32 }
                            short={ true }
                            highlight={ highlight }
                        />

                    </div>
                    {
                        (showDistance && issue.distance >= 0) &&
                        <div className='c-issue__distance'>
                            <span
                                className={ 'c-issue-distance c-badge c-badge--ghost c-badge--rounded c-badge--success' }>
                                {issue.distance}m
                            </span>
                        </div>
                    }
                </div>
            </div>
            <div className='o-flex-layout o-flex-layout--middle'>
                <div className='o-flex-layout__item o-flex-layout__item--fill o-flex-layout__item--shrink'>
                    {
                        issue.description &&
                            <Description
                                description={ issue.description }
                                highlight={ highlight } 
                                freeSearch={ freeSearch }
                                wrap
                            />
                    }
                </div>
                {
                    unlink && 
                        <div className='o-flex-layout__item o-flex-layout__item--no-shrink u-margin-top-tiny'>
                            <span className='button-a small clear' onClick={ unlink }>
                                <FontAwesome name='unlink'/> { T.translate('unlink') }
                            </span>
                        </div>
                }
                {
                    merge && 
                        <div className='o-flex-layout__item o-flex-layout__item--no-shrink u-margin-top-tiny'>
                            <span className='button-a small clear' onClick={ merge }>
                                { T.translate('merge') }
                            </span>
                        </div>
                }
                {
                    expend &&
                        <div
                            className='o-flex-layout__item o-flex-layout__item--no-shrink u-margin-top-tiny'
                            onClick={ (e) => e.stopPropagation() }
                        >
                            <span className='u-color-grey' onClick={ expend.bind(null, issue._id) }>
                                <FontAwesome name='caret-down' style={ { marginTop: 4 } }/>
                            </span>
                        </div>
                }
            </div>
        </div>
    </div>
}

class IssueSmallListItem extends React.Component {
    constructor(props) {
        super(props)
        this._issue = React.createRef();
        this.mouseEnter = this.mouseEnter.bind(this)
        this.mouseLeave = this.mouseLeave.bind(this)
        this.dragStart = this.dragStart.bind(this)
        this.dragEnd = this.dragEnd.bind(this)
        this.unlink = this.unlink.bind(this)
        this.link = this.link.bind(this)
        this.merge = this.merge.bind(this)
        this.goToIssue = this.goToIssue.bind(this)
        this.popoverId = `${randomKey.generate(32)}_${props.issue._id}`
        this.containerId = `issue-${randomKey.generate(32)}_${props.issue._id}`
        this.state = {
            isDrag: false
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !_.isEqual(this.props, nextProps) || !_.isEqual(this.state, nextState)
    }
    
    mouseEnter() {
        if (this.props.onMouseEnter)
            this.props.onMouseEnter(this.props.nearFull ? this.props.issue : this.props.issue._id)
    }

    mouseLeave() {
        if (this.props.onMouseLeave)
            this.props.onMouseLeave(this.props.nearFull ? this.props.issue : this.props.issue._id)
    }

    dragStart(e) {
        ReactTooltip.hide()
        //setTimeout(() => {
        this.setState({
            isDrag: true
        })
        //}, 100)
        if (!this.props.draggable)
            return
        if (e.dataTransfer.setDragImage) {
            e.dataTransfer.setDragImage(document.getElementById(this.containerId), -10, -10)
        }
        e.dataTransfer.setData('text', JSON.stringify({
            issue: this.props.issue._id,
            type: 'issue',
            source: 'list'
        }))
        return false
    }

    unlink(e, p) {
        if (!this.props.unlink)
            return
        e.stopPropagation()
        this.props.unlink(this.props.issue)
    }

    link(e, p) {
        if (!this.props.link)
            return
        e.stopPropagation()
        this.props.link(this.props.issue)
    }
    
    merge(e, p) {
        if (!this.props.merge)
            return
        e.stopPropagation()
        this.props.merge(this.props.issue)
    }

    dragEnd() {
        this.setState({
            isDrag: false
        })
    }

    goToIssue() {
        if (this.props.goToIssue)
            this.props.goToIssue(this.props.issue)
    }

    render() {
        const {
            issue,
            noPopover,
            showDistance,
            draggable,
            scheduledClass,
            className,
            highlight,
            freeSearch,
            isExpended,
            expend,
            selectActive
        } = this.props
        
        if (isExpended)
            return (
                <div
                    onMouseEnter={ this.mouseEnter }
                    onMouseLeave={ this.mouseLeave }
                >
                    <Popover
                        issue={ issue }
                        showDistance={ showDistance }
                        onClick={ this.goToIssue }
                        onlyContent
                        editable
                        className={ classNames('u-margin-tiny c-popover-issue__in-list', { 'c-issue--select-active': selectActive }) }
                        expend={ expend }
                        selectActive={ selectActive }
                        selected={ this.props.selected }
                        select={ this.props.select }
                    />
                </div>
            )

        return (
            <div
                className={ classNames(
                    'c-issue',
                    `c-issue--${issue.state.value}`,
                    `c-issue--${issue.priority.value}`,
                    `c-issue--${issue._id}`,
                    {
                        'anim-issue-draggable': draggable,
                        'anim-issue-dragged': this.state.isDrag,
                        scheduled: scheduledClass && issue.planification && issue.planification.scheduledFrom,
                        [`c-issue--${this.props.mode}`]: this.props.mode,
                        ['c-issue--large']: this.props.large,
                        ['c-card']: this.props.mode !== 'frameless',
                        ['u-high']: this.props.mode !== 'frameless',
                        ['c-issue--distance']: (showDistance && issue.distance >= 0),
                        'c-issue--low-priority': issue.priority.value === 'a_low',
                        'c-issue--middle-priority': issue.priority.value === 'j_middle',
                        'c-issue--high-priority': issue.priority.value === 'z_high',
                        'c-issue--select-active': selectActive
                    },
                    className
                ) }
                onClick={ this.goToIssue }
                onMouseEnter={ this.mouseEnter }
                onMouseLeave={ this.mouseLeave }
                draggable={ draggable }
                onDragStart={ this.dragStart }
                onDragEnd={ this.dragEnd }
                data-tip=''
                data-for={ this.popoverId }
                id={ this.containerId }
                ref={ this._issue }
            >

                {/*TODO improve*/
                    !noPopover && !this.state.isDrag &&
                        <Tooltip
                            id={ this.popoverId }
                            type='light'
                            delayShow={ 40 }
                            place={ 'right' }
                            effect={ 'solid' }
                        >
                            <Popover
                                issue={ issue }
                                showDistance={ showDistance }
                                onClick={ this.goToIssue }
                                onlyContent
                            />
                        </Tooltip>
                }
                <SmallItemContent 
                    issue={ issue } 
                    highlight={ highlight } 
                    freeSearch={ freeSearch } 
                    showDistance={ showDistance } 
                    unlink={ this.props.unlink ? this.unlink : null }
                    link={ this.props.link ? this.link : null }
                    merge={ this.props.merge ? this.merge : null }
                    selectActive={ selectActive }
                    selected={ this.props.selected }
                    select={ this.props.select }
                    expend={ expend }
                />
            </div>

        )
    }
}

export default IssueSmallListItem