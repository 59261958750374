import React  from "react";
import moment from "moment";
import T      from "@uBehaviour/i18n";

const DATE_FORMAT = "DD/MM/YYYY";
const HOUR_FORMAT = "HH:mm";

const PeriodAllDay = ({ start, end }) => (
  <span><T bind={{ 
    start: moment(start).format(DATE_FORMAT), 
    end: moment(end).subtract(1, "day").format(DATE_FORMAT) 
  }}>period_allday</T></span>
);
const PeriodSameDayAllDay = ({ start, end }) => (
  <span><T bind={{ 
    date: moment(start).format(DATE_FORMAT)
  }}>period_sameday_allday</T></span>
);
const PeriodSameDayWithHour = ({ start, end }) => (
  <span><T bind={{ 
    date: moment(start).format(DATE_FORMAT), 
    start: moment(start).format(HOUR_FORMAT), 
    end: moment(end).format(HOUR_FORMAT)
  }}>period_sameday_withhour</T></span>
);
const PeriodWithHour = ({ start, end }) => (
  <span><T bind={{ 
    start_date: moment(start).format(DATE_FORMAT), 
    start_hour: moment(start).format(HOUR_FORMAT), 
    end_date: moment(end).format(DATE_FORMAT), 
    end_hour: moment(end).format(HOUR_FORMAT)
  }}>period_withhour</T></span>
);

const Factory = ({ start, end }) => {
  if(!start || !end){
    return null;
  }
  let Component = null;
  const withHour = moment(start).format(HOUR_FORMAT) !== "00:00" || moment(end).format(HOUR_FORMAT) !== "00:00";
  const sameDay  = moment(start).format(DATE_FORMAT) === (withHour ? moment(end) : moment(end).subtract(1, "day")).format(DATE_FORMAT);

  if(withHour && sameDay){
    Component = PeriodSameDayWithHour;
  }else if(!withHour && sameDay){
    Component = PeriodSameDayAllDay;
  }else if(!withHour && !sameDay){
    Component = PeriodAllDay;
  }else if(withHour && !sameDay){
    Component = PeriodWithHour;
  }
  
  return React.createElement(Component, { start, end });
}

export default Factory;