import React from 'react';
import { Route } from '@cFeatures/router';
import CIssue from '@common/entities/issues';

const Root = () => {
  return (
    <Route path="/issues/:id" component={ (props) => React.createElement(CIssue.Detail, { ...props, readOnly: true }) } />
  );
}

export default Root;