
import React                  from 'react'; 
import Log                    from "@entities/log";
import T                      from "@uBehaviour/i18n";
import Location               from "@cLib/location";

const BuildingLog = (props) => (
  <Log.View subject={ props.id } type="Building" createdAt={(building) => building.createdAt} createdBy={(building) => building.createdBy ? building.createdBy : { firstname:'', lastname:'Inconnu'}} text={(building) => building.name}>
    <Log.View.Rule 
      version={2}
      type={"name"}
      title={() => (<T>building_logs_name</T>)}
      textify={ data => data.name }
    ></Log.View.Rule>
    <Log.View.Rule 
      version={2}
      type={"disabled"}
      title={(_old, _new) => _new && _new.disabled ? (<T>building_logs_disabled</T>) : (<T>building_logs_enabled</T>)}
    />
    <Log.View.Rule
      version={2}
      type={"externalLink"}
      title={(_old, _new) => _new && _old && _new.externalLink && _old.externalLink ? (<T>building_logs_externalLink_update</T>) : _new && _new.externalLink ? (<T>building_logs_externalLink_add</T>) : (<T>building_logs_externalLink_remove</T>)}
      textify={ data => data.externalLink }
    />
    <Log.View.Rule
      version={2}
      type={"location.address"}
      title={() => (<T>building_logs_address</T>)}
      textify={ data => Location.getLabelFromAddress(data.location.address) }
    />
    {/* <Log.View.Rule
      version={2}
      multiple={true}
      key="_id"
      type="places"
      title={(datas, add) => {
        return add ? (<T>building_logs_places_add</T>) : (<T>building_logs_places_del</T>)
      }}
      textify={ datas => datas.map(data => data.name).join(", ") }
    /> */}
  </Log.View>
);

export default BuildingLog;