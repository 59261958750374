import React          from "react";
import Form           from "@uBehaviour/form";
import Field          from "@cComponents/field";
import Input          from "@cComponents/input";
import T              from "@uBehaviour/i18n";
import Button         from "@cComponents/button";
import Application    from "@uBehaviour/application";

import "./form.css";

class MapLayerForm extends React.Component {
  constructor(props) {
    super(props);
    this._form = React.createRef();
  }

  render() {
    const value = this.props.data && this.props.data.toPlainText ? this.props.data.toPlainText() : this.props.data;
    return (
      <div className="bs-form-mapLayer-container">
        <div className="bs-mapLayer-form">  
          <Field.ShortLabelContext prefix="mapLayer_form">
            <div>
              <Form.Simple 
                ref={ this._form }
                value={ value }
                submit={ (form, value)=>this.props.mapLayerHelper.submit(value).then(()=>this.props.close()) }
              >
                <Field.Short name="name" required><Input.Text /></Field.Short>
                <Field.Short name="serviceUrl" required><Input.Text /></Field.Short>
                <Field.Short name="iconUrl" required><Input.Text /></Field.Short>
                <Field.Short name="type">
                  <Input.Radio.Btn>
                    <Input.Radio.Value value="xyz"><div>xyz</div></Input.Radio.Value>
                    <Input.Radio.Value value="mvt"><div>mvt</div></Input.Radio.Value>
                  </Input.Radio.Btn>
                </Field.Short>
                <Field.Short name="transparent">
                  <Input.Radio.Btn>
                    <Input.Radio.Value value={true}><T>mapLayer_form_transparent_true</T></Input.Radio.Value>
                    <Input.Radio.Value value={false}><T>mapLayer_form_transparent_false</T></Input.Radio.Value>
                  </Input.Radio.Btn>
                </Field.Short>
              </Form.Simple>
            </div>
          </Field.ShortLabelContext>
        </div>
        <div className="bs-mapLayer-button">
          <Button.Text onClick={() => this._form.current.submit()}><T>save</T></Button.Text>
        </div>        
      </div>
    );
  }
}

export default Application.forward(["currentTenant"], [["mapLayer", "mapLayerHelper"]], MapLayerForm);