import _ from 'lodash'
import { combineReducers } from 'redux'
import { prepareReducer  }  from "client/lib/reduxSaga";
import "client/lib/reduxSaga";
import 'client/features/app/reducers'
import 'client/features/calendar/reducers'
import 'client/features/statistics/reducers'
import 'client/features/flashes/reducers'
import 'client/features/home/reducers'
import 'client/features/issues/reducers'
import 'client/features/progressBar/reducers'
import 'client/features/session/reducers'
import 'client/features/users/reducers'
import 'client/features/notifications/reducers'
import forms from 'client/features/form/reducers'

const supplies = (state = { all: [], current: null }, action = {}) => { return state };

const rootReducer = () => combineReducers(prepareReducer({
  form: forms,
  supplies:(state = { all: [], current: null}, action) => state 
}));


export const addItemToStore = (state, newItem, options = {}) => {
  const all = [...state.all]
  newItem = _.cloneDeep(newItem)
  const existingIndex = all.filter(i => i).findIndex(item => item._id === newItem._id)
  if (existingIndex === -1)
    all.push(newItem)
  else if (options.merge) {
    all[existingIndex] = { ...all[existingIndex], ...newItem }
  } else {
    all[existingIndex] = newItem
  }
  return {
    ...state,
    all: _.cloneDeep(all),
    current: newItem._id
  }
}

export const getItemFromAll = (all = [], itemId) =>
  all.find(item => item && itemId && item._id === itemId)

export default rootReducer