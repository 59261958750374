// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print{
  .bs-layout-standart-container{
    height: auto;
  }
}

.bs-print-issue{
  page-break-inside: avoid;
  flex-shrink: 2;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(44, 62, 80, 0.6), 0 5px 10px -3px rgba(44, 62, 80, 0.4);
  margin: 5px;
}`, "",{"version":3,"sources":["webpack://./src/print/issues.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,wBAAwB;EACxB,cAAc;EACd,kBAAkB;EAClB,gFAAgF;EAChF,WAAW;AACb","sourcesContent":["@media print{\n  .bs-layout-standart-container{\n    height: auto;\n  }\n}\n\n.bs-print-issue{\n  page-break-inside: avoid;\n  flex-shrink: 2;\n  border-radius: 3px;\n  box-shadow: 0 0 1px rgba(44, 62, 80, 0.6), 0 5px 10px -3px rgba(44, 62, 80, 0.4);\n  margin: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
