import React, { PureComponent } from 'react'
import T from 'i18n-react'
import Title from 'client/features/overlay-view/components/Title.jsx'
import _ from 'lodash'
import InternalNote from 'client/features/issues/components/details/InternalNote.jsx'
import Logs from 'client/features/issues/components/details/history/Logs.jsx'
 

class IssueDetailsHistory extends PureComponent {

    constructor() {
        super()
        this._container = React.createRef();
        this.open = this.open.bind(this)
        this.setMoreButton = this.setMoreButton.bind(this)
        this.state = {
            open: false,
            showMoreButton: false
        }
    }
    
    componentDidMount() {
        this.setMoreButton()
    }
    
    componentDidUpdate(prevProps, prevState) {
        this.setMoreButton()
    }

    setMoreButton() {
        const showMoreButton = this._container.current.clientHeight >= 500
        if (this.state.showMoreButton !== showMoreButton)
            this.setState({ showMoreButton })
    }

    open() {
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        const { issue, loading, confirmations = {}, user, colleagues, setMemo, goToIssue } = this.props
        //const className = this.state.open ? 'issue-log' : 'issue-log closed'
        const logs = issue.logs || {}
        const className = ''
        return (
            <div className='c-issue-history'>
                <div className='c-issue-history__header'>
                    <div className='u-padding-left-small u-padding-right-small'>
                        <Title title={ T.translate('issue_details_title_history') } />
                    </div>
                </div>
                <div className={ className } ref={ this._container }>
                    <div className='c-issue-history__editor'>
                        <InternalNote 
                            issue={ issue }
                            user={ user }
                            confirmation={ confirmations.internalNote }
                            colleagues={ colleagues }
                            submit={ setMemo }
                        />
                    </div>
                    <div className='c-issue-history__logs'>
                        <Logs
                            logs={ logs }
                            loading={ loading }
                            issue={ issue }
                            goToIssue={ goToIssue }
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default IssueDetailsHistory