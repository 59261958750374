import React  from "react";
import Button from "@cComponents/button";
import T      from "@uBehaviour/i18n";
import useService from "@uBehaviour/hooks/useService";
import ConfigurationService from "@uServices/configuration";

import "./discoverStarter.css";

const DiscoverStarter: React.FunctionComponent = () => {
  const CTA = Button.withStyle(Button.Stylized.BigOrange);
  const configuration = useService<ConfigurationService>("configuration");

  const openPromotionalLink = React.useCallback(() => window.open(configuration.get("discoverLink")), [configuration]);

  return (
    <CTA onClick={ openPromotionalLink }>
      <div className="bs-authentication-cta-discoverStarter-betterstreet">
        <T>authentication_cta_discoverStarter_betterstreet</T>
        <i className="fa fa-arrow-right"></i>
      </div>
    </CTA>
  );
}

export default DiscoverStarter;