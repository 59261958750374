import * as React from 'react';

import BuilderContext from './dataTestIdBuilderContext';
import StringBuilder  from '../lib/stringBuilder';

/**
 * Composant permettant de bloquer l'utilisation des préfixes qui serait
 * plus haut dans l'arbre React
 */
const PrefixBorder: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const stringBuilder = React.useMemo(() => StringBuilder.create(), []);

  return (
    <BuilderContext.Provider value={stringBuilder}>
      {children}
    </BuilderContext.Provider>
  );
};

export default PrefixBorder;
