import React, { Component } from 'react'
import Autocomplete from 'react-autocomplete'
import FontAwesome from 'react-fontawesome'
import T from 'i18n-react'
import PropTypes from 'prop-types'
 

class IssuesSearch extends Component {
    constructor(props) {
        super()
        this._autocomplete = React.createRef();
        this.onBlur = this.onBlur.bind(this)
        this.onChange = this.onChange.bind(this)
        this.onKeyPress = this.onKeyPress.bind(this)
        this.onSelect = this.onSelect.bind(this)
        this.submitFreeSearch = this.submitFreeSearch.bind(this)
        this.reset = this.reset.bind(this)
        this.lastChange = new Date().getTime()
        this.state = {
            value: props.value || '',
            loadgin: false,
            item: null
        }
    }

    componentDidUpdate(prevProps, prevState) {
        /*TODO: refactor this shit */
        if (new Date().getTime() - this.props.forceChange <= 1000 && this.props.value !== this.state.value)
            this.setState({ value: this.props.value })
    }

    _renderItem(item, isHighlighted) {
        return (
            <div className={ isHighlighted ? 'search-item active' : 'search-item' }>
                { item.icon ? <span><FontAwesome name={ item.icon } />&nbsp;</span> : null }
                { item.label }
            </div>
        )
    }

    onSelect(value, item) {
        this.setState({ value, item })
        this.props.onSelect(item)
    }

    onChange(event, value) {
        this.setState({
            value,
            item: null
        })
        const now = new Date().getTime()
        this.lastChange = now
        setTimeout(() => {
            if (now === this.lastChange)
                this.props.onChange(value)
        }, 200)
    }
    
    submitFreeSearch() {
        this.props.onEnterPress(this._autocomplete.current.refs.input.value)
    }

    onKeyPress(e) {
        if (e.key !== 'Enter')
            return
        this.submitFreeSearch()
    }

    onBlur() {
        if (!this._autocomplete.current.refs.input.value)
            this.props.resetSearch()
    }

    reset() {
        this.setState({
            value: ''
        })
        this.props.resetSearch()
    }
    
    render() {
        const { items = [], filters = {}, placeholder = 'search' } = this.props
        return (
            <div className='o-field o-field--icon-right' id='filter-search'>
                <Autocomplete
                    inputProps={ { 
                        name: 'search',
                        id: 'states-autocomplete',
                        placeholder: T.translate(placeholder),
                        ref: 'input',
                        type: 'text',
                        className: 'c-field',
                        onKeyPress: this.onKeyPress,
                        onBlur: this.onBlur
                    } }
                    ref={ this._autocomplete }
                    className=''
                    autoHighlight={ false }
                    menuStyle={ {
                        borderRadius: '3px',
                        boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                        background: 'rgba(255, 255, 255, 0.9)',
                        padding: '2px 0',
                        fontSize: '90%',
                        position: 'fixed',
                        overflow: 'auto',
                        maxHeight: '50%', // TODO: don't cheat, let it flow to the bottom
                        zIndex: 10,
                        textAlign: 'left'
                    } }
                    wrapperStyle= { {
                        display: 'block',
                    } }
                    value={ this.state.value }
                    items={ items }
                    getItemValue={ (item) => item.label || item.value }
                    onSelect={ this.onSelect }
                    onChange={ this.onChange }
                    renderItem={ this._renderItem }
                />
                {
                    !filters.freeSearch && !filters.search ?
                        <a className='c-icon u-cursor-pointer' onClick={ this.submitFreeSearch }>
                            <FontAwesome name='search'/>
                        </a>
                        :
                        <a className='c-icon u-cursor-pointer' onClick={ this.reset }>
                            <FontAwesome name='remove'/>
                        </a>
                }
                
            </div>
        )
    }
}

IssuesSearch.propTypes = {
    onChange: PropTypes.func,
    onSelect: PropTypes.func,
    items: PropTypes.array
}

export default IssuesSearch