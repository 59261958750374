// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-item{
    box-shadow: 0 0 1px rgba(44, 62, 80, 0.6), 0 5px 10px -3px rgba(44, 62, 80, 0.4);
    margin-bottom: 12px; 
    opacity: 0.8;
    transition: all 0.3;
    border-radius: 3px;
}

.bs-item.bs-normal .bs-item-content {
    height:160px;
}

.bs-item.bs-compact .bs-item-content {
    height:100px;
}

.bs-item.bs-line .bs-item-content {
    height:40px;
}
.bs-item .bs-item-illustration {
   
}

.bs-item-content{
    padding: 6px 12px;
}

.bs-item-complementary {
    background-color: #efefef;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:   15%
}

.bs-item-complementary > * {
   margin: 4px;
}`, "",{"version":3,"sources":["webpack://./../common/components/old/item.css"],"names":[],"mappings":"AAAA;IACI,gFAAgF;IAChF,mBAAmB;IACnB,YAAY;IACZ,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;AACA;;AAEA;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB;AACJ;;AAEA;GACG,WAAW;AACd","sourcesContent":[".bs-item{\n    box-shadow: 0 0 1px rgba(44, 62, 80, 0.6), 0 5px 10px -3px rgba(44, 62, 80, 0.4);\n    margin-bottom: 12px; \n    opacity: 0.8;\n    transition: all 0.3;\n    border-radius: 3px;\n}\n\n.bs-item.bs-normal .bs-item-content {\n    height:160px;\n}\n\n.bs-item.bs-compact .bs-item-content {\n    height:100px;\n}\n\n.bs-item.bs-line .bs-item-content {\n    height:40px;\n}\n.bs-item .bs-item-illustration {\n   \n}\n\n.bs-item-content{\n    padding: 6px 12px;\n}\n\n.bs-item-complementary {\n    background-color: #efefef;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    width:   15%\n}\n\n.bs-item-complementary > * {\n   margin: 4px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
