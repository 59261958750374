import React from "react";
import Slot from "@cComponents/slot";
import dataTestId from "@universal/features/dataTestId";
const Context = React.createContext(null);


const Action = (props) => (
  <Context.Consumer>
  {(value) => {
    value.register(props.id, props.icon, Action.Tooltip.get({ props }), props.onClick, props.premise ? props.premise : () => true, props.dataTestId);
    return null;
  }}
  </Context.Consumer>
);
Action.Context = Context;
Action.Tooltip = Slot();

export default Action;