import React              from "react";
import _                  from "lodash";
import Input              from "@cComponents/input";
import Button             from '@cComponents/button';
import Modal              from "@cComponents/modal";
import Display            from "@cComponents/displayIf";

import ApiService         from "@uServices/api";
import useService         from "@uBehaviour/hooks/useService";
import useOpenCloseToggle from "@uBehaviour/hooks/useOpenCloseToggle";
import T                  from "@uBehaviour/i18n";
import Form               from "@uBehaviour/form/simple";

import Issue              from "@uTypes/business/Issue";
import { BusinessEntity } from "@uTypes/technic/Entityable";
import Text, { Style } from "@common/components/text";
import Field from "@common/components/field";

import "./form.css";

type FormData = {
  state?: string,
  publicComment?: string
};

type ModalConfirmProps = {
  valid: () => void,
  reject: () => void,
  value?: FormData,
};

const ModalConfirm: React.FunctionComponent<ModalConfirmProps> = ({ valid, reject, value }) => {
  const withComment = !!value?.publicComment;

  return (
    <div className="bs-issues-starter-form-content-modal-confirm">
      <Text.Paragraph><T>{ `issues_starter_form_content_state_change${ withComment ? "_comment" : ""}_warning`}</T></Text.Paragraph>
      <div className="bs-issues-starter-form-content-modal-confirm-buttons">
        <ButtonOrangeRounded onClick={ reject }>
          <T>issues_starter_form_content_state_form_cancel</T>
        </ButtonOrangeRounded>

        <ButtonOrangeRounded onClick={ valid }>
          <T>issues_starter_form_content_state_form_confirm</T>
        </ButtonOrangeRounded>
      </div>
    </div>
  );
};

const ModalSuccess: React.FunctionComponent<{close: () => void}> = ({ close }) => {

  React.useEffect(() => {
    const timeoutId = setTimeout(close, 3500);
    return () => clearTimeout(timeoutId);
  }, [close]);
  
  return (
    <div className="bs-issues-starter-form-content-modal-success">
      <img src={ `/images/starter/issue_updated.png` } alt="Signalement mis à jour"/>
      <div className="bs-issues-starter-form-content-modal-success-description">
        <Text.Paragraph style={ Style.bold.huge }>
          <T>issues_starter_form_content_issue_updated</T>
        </Text.Paragraph>
        <Text.Paragraph style={ Style.large.justify }>
          <T>issues_starter_form_content_issue_updated_description</T>
        </Text.Paragraph>
      </div>
    </div>
  );
};

type StarterFormProps = {
  issue: BusinessEntity<Issue>,
  close: () => void,
};

const ButtonOrangeRounded = Button.withStyle(Button.Stylized.orange.round);

const Content: React.FunctionComponent<StarterFormProps > = ({ issue, close }) => {
  const api = useService<ApiService>('api');
  const states = React.useMemo(() => (["open", "in_progress", "resolved", "rejected"]), []);

  const [displayConfirmModal, openConfirmModal, closeConfirmModal] = useOpenCloseToggle();
  const [displaySuccessModal, openSuccessModal] = useOpenCloseToggle();


  const submitAndCloseModal = React.useCallback( async(form: Form) => {
    closeConfirmModal();

    api.service('issues', 'updateState')
      .execute(issue._id, form.value.state || issue.state, undefined, form.value.publicComment)
      .then(() => _.defer(openSuccessModal));
  }, [api, issue, openSuccessModal, closeConfirmModal]);

  return (
  <>
    <Form submit={ submitAndCloseModal } default={{ state: issue.state }}>
    {(ctx, value, errors, form, submit) => (
      <>
        <div className="bs-issues-starter-form-content">
          <Field.Standart name="state" required>
            <Field.Label><T>issues_starter_form_content_state_label</T></Field.Label>
            <Field.Input>
            <Input.Radio inline noFrame>
            {
              states.map(state => (
                <Input.Radio.Value key={ state } value={ state }>
                {(selected) => (
                  <div className={ `bs-issues-starter-form-content-state-item ` + (selected ? "bs-issues-starter-form-content-state-item-selected" : "") }>
                    <span className={ `bs-issues-starter-form-content-state-icon bs-issues-starter-form-content-state-icon-${ state }` }/>
                    <T>{ "issue_state_" + state }</T>
                  </div>
                )}
                </Input.Radio.Value>
              ))
            }      
            </Input.Radio>
            </Field.Input>
          </Field.Standart>
          <Field.Standart name="publicComment">
            <Field.Label><T>issues_starter_form_content_comment_label</T></Field.Label>
            <Field.Input>
              <Input.TextArea />
            </Field.Input>
          </Field.Standart>
          <div className="bs-issues-starter-form-content-send-btn-container">
            <ButtonOrangeRounded onClick={ form.hasBeenUpdated() ? openConfirmModal : () => null }>
              <T>issues_starter_form_content_send</T>
            </ButtonOrangeRounded>
          </div>
        </div>
        {/* Modale de validation */}
        <Display.If condition={ displayConfirmModal }>
          <Modal.Show  close={ closeConfirmModal } >
          {(close) => (
            <ModalConfirm valid={ submit } reject={ close } value={ value } />
          )}
          </Modal.Show>
        </Display.If>
        {/* Modale de confirmation de changement de statut */}
        <Display.If condition={ displaySuccessModal }>
          <Modal.Show style={{ width: "50vw", height: "60vh"}} close={ close } >
          {(close) => (
            <ModalSuccess close={ close }/>
          )}
          </Modal.Show>
        </Display.If>
      </>        
    )}
    </Form>
  </>
)};
          
export default Content;