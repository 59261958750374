import React, { ComponentType, FunctionComponent, PropsWithChildren, ReactNode } from "react";
import dayjs from "dayjs";
import T from "@universal/behaviour/i18n";
import Text, { Style } from "@common/components/text";
import { Icon as UserIcon } from "@entities/users";

import Slot from "@universal/components/slot2";
import usePager from "@universal/behaviour/data/hooks/usePager";
import useService from "@universal/behaviour/hooks/useService";
import RepositoryService from "@universal/services/repository";

import { longFullname } from "@universal/business/format/user";
import classNames from "@universal/lib/classNames";
import BackToThePast from "@uLib/backToThePast";

import Resource, { ResourceToCollect, CollectedResource } from "@uTypes/business/Resource";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import ObjectId from "@universal/types/technic/ObjectId";
import BsDate from "@universal/types/technic/Date";
import Log, { isLogV2, LogV3, LogV2, Diff, isLogV3, isLogV3Merge, LogV3Creation, isLogV3Creation  } from "@uTypes/business/Log";
import Comment from "@uTypes/business/Comment";
import User, { isUserCitizen } from "@universal/types/business/User";

import './view.css';




const LogDisplayTitle = Slot();
const LogDisplayContent = Slot();

export const LogDisplay: FunctionComponent<PropsWithChildren> & { Title: typeof LogDisplayTitle, Content: typeof LogDisplayContent } = ({ children }) => {
  const title = LogDisplayTitle.get(children);
  const content = LogDisplayContent.get(children);
  return (
    <div className="bs-log-view-container-item-content-container">
      <div className="bs-log-view-container-item-content-container-title">
        <Text style={ Style.bold.small }>{ title }</Text>
      </div>
      { content ? <Text style={ Style.small }>{ content }</Text> : null }
    </div>
  );
};

LogDisplay.Title = LogDisplayTitle;
LogDisplay.Content = LogDisplayContent;


export type Element = {
  title: ReactNode;
  content: ReactNode;
}

function createLogView<T extends keyof Resource>(resource: T){
  type Type = Resource[typeof resource];

  type RuleBase = {
    title: () => ReactNode;
  }

  const getDataToCollect = (backToThePast: BackToThePast<Type>, ruleV2: RuleV2Props[], ruleV3: RuleV3Props[]): ResourceToCollect => {
    const dataToCollect: ResourceToCollect = {};

    backToThePast.toStart();

    while(backToThePast.hasPreviousIteration()){
      backToThePast.previousIteration();
      if(backToThePast.isCurrentLog() && isLogV2(backToThePast.currentLog)) {
        const log = backToThePast.currentLog;
        log.diff.forEach(diff => {
          ruleV2.forEach(rule => {
            if(ruleV2MatchLogDiff(rule, diff) && rule.dataToCollect){
              const resourceToCollect = rule.dataToCollect(backToThePast.beforeChangeValue, backToThePast.afterChangeValue, diff);
              (Object.keys(resourceToCollect) as unknown as (keyof ResourceToCollect)[]).forEach((resource) => {
                if(!dataToCollect[resource]){
                  dataToCollect[resource] = [];
                }
                dataToCollect[resource] = (dataToCollect[resource] as Array<ObjectId>).concat(resourceToCollect[resource] as Array<ObjectId>);
              });
            }
          });
        });
      } else if(backToThePast.isCurrentLog() && isLogV3(backToThePast.currentLog) && backToThePast.currentLog.type === "merge") {
        const log = backToThePast.currentLog;
        const mergeRule = ruleV3.find(rule => rule.type === "merge") as RuleV3MergeProps | undefined;
        if(mergeRule && mergeRule.dataToCollect && isLogV3Merge(log)) {
          const resourceToCollect = mergeRule.dataToCollect(backToThePast.beforeChangeValue, backToThePast.afterChangeValue, log.datas.issueMerged);
          (Object.keys(resourceToCollect) as unknown as (keyof ResourceToCollect)[]).forEach((resource) => {
            if(!dataToCollect[resource]){
              dataToCollect[resource] = [];
            }
            dataToCollect[resource] = (dataToCollect[resource] as Array<ObjectId>).concat(resourceToCollect[resource] as Array<ObjectId>);
          });
        }
      }
    }
    return dataToCollect;
  }

  type RuleV2Props = RuleBase & {
    version: 2;
    type: string | RegExp | ((diff: Diff) => boolean);
    dataToCollect?: (before: Type, after: Type, diff: Diff) => ResourceToCollect
    textify?: (before: Type, after: Type, diff: Diff, resources: CollectedResource) => ReactNode;
  };

  const ruleV2MatchLogDiff = (rule: RuleV2Props, diff: Diff) => {
    if(rule.type instanceof Function) {
      return rule.type(diff);
    } else if(typeof rule.type === "string") {
      return rule.type === diff.path.join(".");
    } else {
      return rule.type.test(diff.path.join("."));
    }
  }

  type DisplayLog<Type> = (args: Type) => Element[];

  type DisplayRuleV2Props = {
    rules: RuleV2Props[];
    log: LogV2;
    before: Type;
    after: Type;
    collectedResources: CollectedResource;
  }
  const DisplayRuleV2: DisplayLog<DisplayRuleV2Props> = ({ rules, log, before, after, collectedResources }) => {
    return log.diff.reduce<Element[]>((elements, diff) => {
      const rule = rules.find(rule => ruleV2MatchLogDiff(rule, diff));
      if(rule){
        elements.push({
          title: rule.title(),
          content: rule.textify ? rule.textify(before, after, diff, collectedResources) : null
        })
      }
      return elements;
    }, []);
  };

  type RuleV3NoDataProps = RuleBase & {
    version: 3;
    type: Exclude<LogV3["type"], "merge">;
    textify?: (data: Type) => ReactNode;
  };

  type RuleV3MergeProps = RuleBase & {
    version: 3;
    type: "merge";
    dataToCollect?: (before: Type, after: Type, id: ObjectId) => ResourceToCollect;
    textify?: (data: Type, issueId: ObjectId, collectedResources: CollectedResource) => ReactNode;
  };

  type RuleV3Props = RuleV3NoDataProps | RuleV3MergeProps;

  type DisplayRuleV3Props = {
    rules: RuleV3Props[];
    log: LogV3;
    data: Type;
    collectedResources: CollectedResource;
  }

  const DisplayRuleV3: DisplayLog<DisplayRuleV3Props> = ({ rules, log, data, collectedResources }) => {
    const rule = rules.find(rule => rule.type === log.type);
    if(!rule) {
      return [];
    }
    const elements = [];
    if(isLogV3Merge(log) && rule.type === "merge") {
      elements.push({
        title: rule.title(),
        content: rule.textify ? rule.textify(data, log.datas.issueMerged, collectedResources) : null 
      });
    } else if(rule.type !== "merge"){
      elements.push({
        title: rule.title(),
        content: rule.textify ? rule.textify(data) : null
      });
    }
    return elements;
  }

  type RuleProps = RuleV2Props | RuleV3Props;

  const Rule = Slot<null, RuleProps>(true, false);

  type DisplayCommentProps = {
    comment: string;
  }
  const DisplayComment: DisplayLog<DisplayCommentProps> = ({ comment }) => {
    return [{
      title: <T>log_view_commentTitle</T>,
      content: <>{ comment }</>
    }];
  };

  type ContentContainerProps = {
    elements: Element[];
    after: Type;
    before: Type;
    createdBy: User;
  }

  const DefaultContentContainer: FunctionComponent<ContentContainerProps> = ({ createdBy, elements }) => (
    <div className="bs-log-view-defaultContentContainer">
      <div className="bs-log-view-defaultContentContainer-user">
        <Text style={ (isUserCitizen(createdBy) ? Style.gray : Style.orange).center }><UserIcon user={ createdBy } />&nbsp;</Text>
      </div>
      {
        elements.map(({ title, content }, index) => (
          <LogDisplay key={ index }>
            <LogDisplay.Title>{ title }</LogDisplay.Title>
            <LogDisplay.Content>{ content }</LogDisplay.Content>
          </LogDisplay>
        ))
      }
    </div>
  );

  type ContainerProps = {
    type: "comment" | "log";
    elements: Element[];
    after: Type;
    before: Type;
    createdBy: User;
    createdAt: BsDate;
    ContentContainer: ComponentType<PropsWithChildren<ContentContainerProps>>;
  };

  const footerStyle = Style.gray.tiny;

  const DefaultContainer: FunctionComponent<ContainerProps> = ({ elements, after, before, createdBy, createdAt, type, ContentContainer }) => {
    return (
      <div className="bs-log-view-container">
        <div className="bs-log-view-container-branch">
          <div className={ classNames("bs-log-view-container-subject").add(`bs-log-view-container-subject-${type}`) }>
            <span className={`fa fa-${ type === "comment" ? "comment-o" : "cog"}`} />
          </div>
          <div className={"bs-log-view-container-thread-branch"} />
        </div>
        <div className="bs-log-view-container-item">
          <div className="bs-log-view-container-item-content">
            <ContentContainer elements={ elements } after={ after } before={ before } createdBy={ createdBy } />
          </div>
          <div className="bs-log-view-container-item-footer">
            <Text style={ footerStyle }>@</Text>
            <Text style={ footerStyle }>{longFullname(createdBy) }</Text>
            <Text style={ footerStyle }>&nbsp;-&nbsp;</Text>
            <Text style={ footerStyle }>{ dayjs(createdAt).format("HH:mm") }</Text>
          </div>
        </div>
      </div>
    )
  };

  type DisplayDateProps = {
    date: BsDate;
  };

  const DisplayDate: FunctionComponent<DisplayDateProps> = ({ date }) => (
    <div className="bs-log-view-displayDate">
      <Text style={ Style.small.gray }>
      { dayjs(date).format("DD/MM/YYYY") }
      </Text>
    </div>
  );

  type ViewProps = {
    data: BusinessEntity<Type>;
    withComments?: boolean;
    ContentContainer?: ComponentType<ContentContainerProps>;
    getLogCreation?: (data: Type) => LogV3Creation;
    creationIsComment?: boolean;
  };
  const View: FunctionComponent<PropsWithChildren<ViewProps>> & { Rule: typeof Rule }= ({ data, withComments = false, ContentContainer = DefaultContentContainer, getLogCreation, creationIsComment = false, children }) => {

    const [datas, setDatas] = React.useState<{ loaded: boolean, backToThePast: BackToThePast<Type>, resources: CollectedResource}>({
      loaded: false,
      backToThePast: new BackToThePast(data, [], []),
      resources: {}
    });

    const repository = useService<RepositoryService>("repository");

    const { datas: logs, allElementsLoaded: logsLoaded } = usePager<Log, { createdBy: Loader }>({
      model: "Log",
      query: {
        "subject.id": data._id
      },
      sort: { createdAt: -1 },
      load: { createdBy: true },
      loadAll: true
    });

    const { datas: comments, allElementsLoaded: commentsLoaded } = usePager<Comment, { createdBy: Loader }>({
      model: "Comment",
      query: withComments ? {
        "subject.id": data._id
      } : { _id: null },
      sort: { createdAt: -1 },
      load: { createdBy: true },
      loadAll: true
    });

    const logCreation = React.useMemo(() => {
      if(!getLogCreation){
        return null;
      }
      const logCreation = getLogCreation(data.toPlainText());
      const logRepository = repository.get("Log").repository;
      const entity = logRepository.getFromCache(logCreation)  as  BusinessEntity<LogV3Creation, { createdBy: Loader }>; ;
      logRepository.updateDependancies([entity], { createdBy: true });
      return entity;
    }, [data, getLogCreation, repository]);

    const rules = Rule.props(children, true);
    const ruleV2: RuleV2Props[] = rules.filter((rule): rule is RuleV2Props => rule.version === 2);
    const ruleV3: RuleV3Props[] = rules.filter((rule): rule is RuleV3Props => rule.version === 3);
    
    React.useEffect(() => {
      if(!logsLoaded || !commentsLoaded) {
        return;
      }
      const backToThePast = new BackToThePast<Type>(data, logCreation ? [...logs, logCreation] : logs, comments);
      const resourceToCollect: ResourceToCollect = getDataToCollect(backToThePast, ruleV2, ruleV3);
      const resources = {} as CollectedResource;
      Promise.all((Object.keys(resourceToCollect) as (keyof ResourceToCollect)[]).map(resource => {
        return repository.get(resource).repository.get(resourceToCollect[resource]).then((entities: Array<Resource[typeof resource]>) => {
          resources[resource] = entities.reduce<Record<ObjectId, Resource[typeof resource]>>((acc, entity) => {
            acc[entity._id] = entity;
            return acc;
          }, {} as Record<ObjectId, Resource[typeof resource]>);
        });
      })).then(() => {
        setDatas({
          loaded: true,
          backToThePast,
          resources
        })
      });
    }, [rules, logs, comments, repository, logCreation]);

    const nodes: ReactNode[] = React.useMemo(() => {
      const { loaded, backToThePast, resources } = datas;
      if(!loaded) {
        return [];
      }
      const nodes: ReactNode[] = [];
      let lastDate: BsDate | null = null;
      backToThePast.toStart();
      while(backToThePast.hasPreviousIteration()) {
        backToThePast.previousIteration();
        
        let elements: Element[] = [];
        if(backToThePast.isCurrentLog()){
          const log = backToThePast.currentLog;
          if(isLogV2(log)){
            elements = DisplayRuleV2({
              rules: ruleV2,
              log,
              before: backToThePast.beforeChangeValue,
              after: backToThePast.afterChangeValue,
              collectedResources: resources
            });
          } else if(isLogV3(log)){
            elements = DisplayRuleV3({
              rules: ruleV3,
              log,
              data: backToThePast.afterChangeValue,
              collectedResources: resources
            });
          }
        } else if(backToThePast.isCurrentComment()) {
          const comment = backToThePast.currentComment;
          elements = DisplayComment({ comment: comment.message });
        }

        if(elements.length){
          const baseData = backToThePast.baseData;
          if(dayjs(baseData.createdAt).format("DD/MM/YYYY") !== lastDate) {
            lastDate = dayjs(baseData.createdAt).format("DD/MM/YYYY");
            nodes.push(
              <DisplayDate key={ lastDate } date={ baseData.createdAt } />
            );
          }

          let type: "comment" | "log" = "log";
          if(backToThePast.isCurrentComment() || (isLogV3Creation(backToThePast.currentLog) && creationIsComment)){
            type = "comment";
          }

          nodes.push(
            <DefaultContainer
              key={ baseData._id }
              type={ type }
              elements={ elements }
              after={ backToThePast.afterChangeValue }
              before={ backToThePast.beforeChangeValue }
              createdBy={ baseData.createdBy }
              createdAt={ baseData.createdAt }
              ContentContainer={ ContentContainer }
            />
          );
        }
      }
      return nodes;
    }, [datas, ContentContainer, ruleV2, ruleV3]);



    return (
      <div className="bs-log-view-component">
        <div className="bs-log-view-component-title">
          <Text style={ Style.bold }><span className="fa fa-undo" /></Text>
          <Text style={ Style.bold }><T>log_view_title</T></Text>
        </div>
        <div className="bs-log-view">
          <div className="bs-log-view-thread-trunk" />
          { nodes }
        </div>
      </div>
    );
  };
  View.Rule = Rule;

  return View;
}


export default createLogView;


