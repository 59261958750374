import React, { PureComponent } from 'react'
import classNames from 'classnames'
import FontAwesome from 'react-fontawesome'
import { isAuthorized } from 'client/features/issues/lib'

import Address from 'client/features/overlay-view/components/Address.jsx'
import Dates from 'client/features/issues/components/common/Dates.jsx'
import Description from 'client/features/issues/components/lists/Description.jsx'
import Infos from 'client/features/issues/components/lists/Infos.jsx'
import Picture from 'client/features/issues/components/lists/Picture.jsx'
import State from 'client/features/issues/components/lists/State.jsx'
import WorkorderState from 'client/features/issues/components/workOrders/State.jsx'
import Title from 'client/features/issues/components/lists/Title.jsx'
import Hashtags from 'client/features/issues/components/common/Hashtags.jsx'
import IssueListItemSelector from 'client/features/issues/components/lists/Selector.jsx'
 

class IssueListItem extends PureComponent {

  constructor() {
    super()
    this._issue = React.createRef();
  }

  render() {
    const { issue, user, highlight, freeSearch, frameless, search, setSearch, selectActive, selected, select } = this.props

    if (!issue) {
      return (<div></div>)
    }

    const className = classNames('c-issue', 'c-issue--large', `c-issue--${issue.state}`, {
      'c-issue--frameless': frameless,
      'c-issue--low-priority': issue.priority.value === 'a_low',
      'c-issue--middle-priority': issue.priority.value === 'j_middle',
      'c-issue--high-priority': issue.priority.value === 'z_high',
    })
    return (
      <div
        ref={ this._issue }
        id={ `issue-link-${issue._id}` }
        className={ className }
      >
        <div className='c-card' onClick={ this.props.goToIssue.bind(null, issue) }>
          <div className='c-issue__image u-position-relative'>
            <Picture issue={ issue } />
            <IssueListItemSelector
              selectActive={ selectActive }
              selected={ selected }
              select={ select }
              issueId={ issue._id }
            />
          </div>

          <div className='c-issue__content'>
            <div className='c-issue__primary'>
              <div>
                <div className='c-issue__title u-margin-bottom-tiny'>
                  <Title
                    issue={ issue }
                    user={ user }
                    highlight={ highlight }
                    freeSearch={ freeSearch }
                    showLinks
                  />
                  <Hashtags
                    hashtags={ issue.hashtags }
                    disabled
                    search={ setSearch }
                    highlight={ highlight }
                    freeSearch={ freeSearch }
                  />
                </div>
                <div className='c-issue__address'>
                  <Address
                    place={ issue.place }
                    locationInfo={ issue.locationInfo }
                    roadInfo={ issue.roadInfo }
                    address={ issue.subject }
                    highlight={ highlight }
                    type={ issue.type }
                  />
                </div>
              </div>
              <Description description={ issue.description } highlight={ highlight } freeSearch={ freeSearch } large />
              <div className='c-issue__dates'>
                {
                  isAuthorized(issue, 'requestor', 'read') &&
                  <Dates issue={ issue } user={ user } highlight={ highlight } />
                }
              </div>
            </div>
            <div className='c-issue__meta'>
              <div className='c-issue__state' onClick={ e => e.stopPropagation() }>
                {
                  issue.assignments && 
                  <State
                    issue={ issue }
                    state={ issue.state }
                    edit={ issue.eTag && issue.tag.key !== 'congratulation' && user.authorizations.issue.state }
                    user={ user }
                    updateState={ this.props.updateState }
                    readOnly={ this.props.readOnly || issue.state.value === "merged" }
                  />
                }
                {
                  issue.assignment && 
                  <WorkorderState
                    issue={ Object.assign({}, issue, { _id: issue.issue }) }
                    assignment={ issue.assignment }
                  />
                }
              </div>
              <Infos issue={ issue } user={ user } highlight={ highlight } search={ search }/>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default IssueListItem