// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-input-editor{
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.02);

}
.bs-input-editor > div {
  padding: 5px;
  box-shadow: 0 0 1px rgba(44, 62, 80, 0.6), 0 5px 10px -3px rgba(44, 62, 80, 0.4);
  overflow-y: scroll;
}
.bs-input-editor > div[contenteditable] {
  outline: 0px solid transparent;
}
.bs-input-editor > div:focus {
  border: 1px solid rgba(82,168,236,0.8);
}`, "",{"version":3,"sources":["webpack://./../common/components/input/editor.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,qCAAqC;;AAEvC;AACA;EACE,YAAY;EACZ,gFAAgF;EAChF,kBAAkB;AACpB;AACA;EACE,8BAA8B;AAChC;AACA;EACE,sCAAsC;AACxC","sourcesContent":[".bs-input-editor{\n  padding: 5px;\n  background-color: rgba(0, 0, 0, 0.02);\n\n}\n.bs-input-editor > div {\n  padding: 5px;\n  box-shadow: 0 0 1px rgba(44, 62, 80, 0.6), 0 5px 10px -3px rgba(44, 62, 80, 0.4);\n  overflow-y: scroll;\n}\n.bs-input-editor > div[contenteditable] {\n  outline: 0px solid transparent;\n}\n.bs-input-editor > div:focus {\n  border: 1px solid rgba(82,168,236,0.8);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
