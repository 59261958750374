import React from "react";
import useService from "@universal/behaviour/hooks/useService";
import SessionService from "@universal/services/session";
import UpdatePassword from "./views/updatePassword";
import { Route, Switch } from '@cFeatures/router';
import HsForm from "./views/hsForm";
import Login from "./views/login";
import PanneauPocketValidateEmail from "./views/panneauPocketValidateEmail";
import Discover from "./views/discover";
import Release from '@common/entities/release';


const Authentication = ({}) => {
  const session = useService<SessionService>('session');
  
  if(session.isLogged() && session.user.discriminator === "requestorValidation" && session.user.provider === "jvs-pp"){
    return (
      <PanneauPocketValidateEmail />
    );
  }

  if(session.mustResetPassword()){
    return (
      <UpdatePassword />
    );
  }

  return (
    <>
      <Release.Next />
      <Login />
    </>    
  );
};

const Unauthenticated = ({}) => {
  return (
    <Switch>
      <Route path="/discover" component={ Discover } />
      <Route path="/hsForm/:id/:?width" component={ HsForm } />
      <Route path="/" component={ Authentication } />
    </Switch>
  );
}

export default Unauthenticated;