// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-item-release{
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.bs-item-release>div {
  margin-bottom: var(--std-large-size-inter-element);
}
.bs-item-release:nth-last-child(n + 2){
  margin-bottom: 30px;
}
.bs-item-release-item{
  margin-right: 40px;
  margin-bottom: var(--std-size-inter-element);
}

.bs-item-release-item>div{
  margin-bottom: 10px;
}
.bs-item-release-item-files{
  display: flex;
  flex-flow: row;
}
.bs-item-release-item-links>*:nth-child(n + 2),
.bs-item-release-item-files>*:nth-child(n + 2){
  margin-left: 10px;;
}

.bs-item-release-list-title {
  margin-bottom: var(--std-size-inter-element);
}`, "",{"version":3,"sources":["webpack://./../common/features/release/item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,oCAAoC;AACtC;AACA;EACE,kDAAkD;AACpD;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,4CAA4C;AAC9C;;AAEA;EACE,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,cAAc;AAChB;AACA;;EAEE,iBAAiB;AACnB;;AAEA;EACE,4CAA4C;AAC9C","sourcesContent":[".bs-item-release{\n  padding: 10px;\n  border: 1px solid rgba(0, 0, 0, 0.3);\n}\n.bs-item-release>div {\n  margin-bottom: var(--std-large-size-inter-element);\n}\n.bs-item-release:nth-last-child(n + 2){\n  margin-bottom: 30px;\n}\n.bs-item-release-item{\n  margin-right: 40px;\n  margin-bottom: var(--std-size-inter-element);\n}\n\n.bs-item-release-item>div{\n  margin-bottom: 10px;\n}\n.bs-item-release-item-files{\n  display: flex;\n  flex-flow: row;\n}\n.bs-item-release-item-links>*:nth-child(n + 2),\n.bs-item-release-item-files>*:nth-child(n + 2){\n  margin-left: 10px;;\n}\n\n.bs-item-release-list-title {\n  margin-bottom: var(--std-size-inter-element);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
