// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-layout-coreff-container {
  display:flex;
  height: 100%;
}

.bs-layout-coreff-left {
  width: 20%;
}

.bs-layout-coreff-right {
  width: 80%;
}`, "",{"version":3,"sources":["webpack://./../common/components/layout/coreff.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".bs-layout-coreff-container {\n  display:flex;\n  height: 100%;\n}\n\n.bs-layout-coreff-left {\n  width: 20%;\n}\n\n.bs-layout-coreff-right {\n  width: 80%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
