import React, { PureComponent } from 'react'
import T from 'i18n-react'
import IssueSmallListItem from 'client/features/issues/components/lists/SmallItem.jsx'
import Title from 'client/features/overlay-view/components/Title.jsx'
import Loader from 'client/features/common/components/Loader.jsx'

class IssueDetailsNears extends PureComponent {
    constructor(props) {
        super(props)
        this.showMore = this.showMore.bind(this)
        this.state = {
            limit: props.displayAll ? 1000 : 3
        }
    }

    showMore() {
        this.setState({ limit: this.state.limit === 3 ? 6 : 3 })
    }

    render() {
        const { issues, loading, displayAll, title, link, merge } = this.props
        if (!loading && !issues[0])
            return (<div></div>)
        return (
            <div className='u-hide-for-print'>
                <Title title={ title } />
                <div id='filters-result' className='u-letter-box--small u-padding-bottom-none'>
                    {
                        loading && !issues[0] ?
                            <div style={ { marginBottom: '70px' } }><Loader small/></div>
                            :
                            issues.slice(0, this.state.limit).map((near) => 
                                <IssueSmallListItem
                                    issue={ near }
                                    key={ near._id }
                                    onMouseEnter={ this.props.displayNearIssue }
                                    onMouseLeave={ this.props.displayNearIssue }
                                    goToIssue={ this.props.goToIssue }
                                    showDistance
                                    className='u-margin-right-none u-margin-left-none'
                                    link={ link }
                                    merge={ merge }
                                />
                            )
                    }
                </div>
            </div>
        )
    }
}

export default IssueDetailsNears