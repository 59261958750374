import React              from 'react';
import { makeStorageUrl } from 'client/lib/file';
import noPhoto from "@root/assets/no_photo_little_all_tags.png"

const stopPropagation = (ev) => ev.stopPropagation();
class ImageDynamic extends React.Component{
  render(){
    const imgProps = {
      src: this.props.file ? makeStorageUrl(this.props.file, {
        fit: this.props.fit,
        width: this.props.width,
        height: this.props.height,
        format: this.props.format
      }) : noPhoto
    }
    if(!this.props.noConstraint){
      imgProps.width  = this.props.width;
      imgProps.height = this.props.height;
    }
    return this.props.openable
      ? (
        <div className="bs-file" onClick={stopPropagation}>
          <a href={this.props.file ? makeStorageUrl(this.props.file) : noPhoto} target="_blank">
            <img { ...imgProps } />
          </a>
        </div>
      ) : (
        <div className="bs-file">
          <img { ...imgProps } />
        </div>
      );
  }
}
class ImageURI extends React.Component{
  render(){
    const imgProps = {
      src: this.props.file.uri
    }
    if(!this.props.noConstraint){
      imgProps.width  = this.props.width;
      imgProps.height = this.props.height;
    }
    return React.createElement("img", imgProps);
  }
}

export default class File extends React.Component{
  render(){
    let Component = null;
    if(!this.props.file) {
      Component = ImageDynamic;
    } else {
      switch(this.props.file.mimeType){
        case "application/pdf":
        case "image":
        case "image/svg+xml":
        case "image/png":
        case "image/bmp":
        case "image/jpeg":
          Component = ImageDynamic;
        break;
        case "image/uri":
          Component = ImageURI;
        break;
        default:
          Component = ImageDynamic;
      }
    } 
    return React.createElement(Component, {
      file: this.props.file,
      fit: this.props.fit,
      height: this.props.height,
      width: this.props.width,
      openable: this.props.openable,
      noConstraint: this.props.noConstraint,
      format: this.props.format
    });
  }
}