import { Service }        from "@uLib/application";


export default class TooltipService extends Service {
  constructor() {
    super("tooltip");
    this._tooltips = {};
  }
  get(){
    return Object.values(this._tooltips);
  }
  add(chanel, component, relativeTo, position = "bottom"){
    this._tooltips[chanel] = {
      component: component,
      relativeTo: relativeTo,
      position: position
    };
    this.triggerUpdate();
  }
  close(chanel){
    delete this._tooltips[chanel];
    this.triggerUpdate();
  }
}