import { AgentStatement } from "@uTypes/business/Valorization";
import { ComponentType, FunctionComponent } from "react";
import { AgentSubject } from "../subjects";
import User from "@uTypes/business/User";
import { DurationProps } from "./type";
import StatementLayout from "../statementLayout";

interface AgentStatementProps {
  statement: AgentStatement;
};

function AgentStatementComponent(Duration: ComponentType<DurationProps>) : FunctionComponent<AgentStatementProps> {
  return ({ statement }) => (
    <StatementLayout statement={ statement } displayCostResource='users' displayCostAction='readCost'>
      <StatementLayout.Subject><AgentSubject agent={ statement.agent as User } /></StatementLayout.Subject>
      <StatementLayout.Occurence><Duration duration={ statement.duration } /></StatementLayout.Occurence>
    </StatementLayout>
  );
};


export default AgentStatementComponent;
