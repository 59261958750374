import React, { CSSProperties, ComponentType, FunctionComponent, PropsWithChildren } from 'react';
import DropDown from '@cComponents/dropDown';
import State from '@uTypes/business/State';
import T from '@universal/behaviour/i18n';

import './select.css';

type StateSelectedHandle = (state: State) => void;

interface ItemProps {
  state: State;
  onClick: StateSelectedHandle;
}

const Item: FunctionComponent<ItemProps> = ({ state, onClick }) => {
  const sendState = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    onClick(state);
  }, [state, onClick]);

  return (
    <div className='bs-state-list-item' style={{ '--state-list-item-color': state.color } as CSSProperties } onClick={ sendState }>
      <T>{ state.i18n }</T>
    </div>
  );
}

interface ListProps {
  states: State[];
  onSelect: StateSelectedHandle;
  close: () => void;
}

export const List: FunctionComponent<ListProps> = ({ states, onSelect, close }) => {
  const selectAndClose = React.useCallback((state: State) => {
    close();
    onSelect(state);
  }, [close, onSelect]);

  const assignableStates = React.useMemo(() => states.filter((state) => state.assignable),[states]);

  return (
    <div className='bs-state-list'>
      <div className='bs-state-list-content'>
      {
        assignableStates.map(state => (<Item state={ state } onClick={ selectAndClose } />))
      }
      </div>
      <div className='bs-state-list-arrow' />
    </div>
  );
}

export interface SelectProps {
  value: State;
  onChange: StateSelectedHandle;
  states: State[];
}
interface DisplayComponentProps {
  state: State
}
const createSelect : (DisplayState: ComponentType<PropsWithChildren<DisplayComponentProps>>) => FunctionComponent<SelectProps> = (DisplayState) => {
  const Select: FunctionComponent<SelectProps> = ({ value, states, onChange }) => {
    const selectableState = React.useMemo(() => states.filter(state => state.label !== value.label), [value]);
    
    if(!value.updatable) { 
      return <DisplayState state={ value } />;
    }
    return (
      <DropDown 
        open='click'
        alignRight
        noMaxWidth
      >
        <DropDown.Menu>
        {(open: boolean) => (
            <DisplayState state={ value }>
              <span className={`bs-state-select fa fa-caret-${open ? 'up' : 'down'}`}  />
            </DisplayState>
          )
        }
        </DropDown.Menu>
        <DropDown.Content>
          <List states={ selectableState } onSelect={ onChange } />
        </DropDown.Content>
      </DropDown>
    );
  }
  return Select;
}

export default createSelect;