import React    from 'react'
import File     from 'client/components/file.jsx';

const IssueListPicture = ({ issue, className }) => (
    <File 
        file={ issue.cover }
        width={160}
        height={160}
        fit="cover"
    />
)

export default IssueListPicture