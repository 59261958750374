import fetch from 'isomorphic-fetch';
import application from '../../app.services';

const __CONFIG = window.__CONFIG;

export const request = (path, options = {}) => {
  const headers = new Headers()
  headers.append('authorization', `Bearer ${application.getService("session").token}`);
  headers.append('tenant', application.getService("currentTenant").currentId);
  if(options.headers){
    Object.keys(options.headers).forEach(name => {
      headers.append(name, options.headers[name]);
    });
  }
  const opts = Object.assign({}, options, {
    mode: 'cors',
    cache: 'default',
    headers: headers
  });
  const endpoint = `${options.version === 1 ? __CONFIG.apiHost : __CONFIG.apiV2Host}${path}`;
  return fetch(endpoint, opts);
}

export const jsonFetch = (path, method, datas = null, version = 2) => {
  return request(path, {
    method: method,
    headers: {
      "content-type": "application/json"
    },
    version: version,
    body: datas ? JSON.stringify(datas) : undefined
  }).then(response => response.json());
}

export default request;