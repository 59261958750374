import React  from "react";
import T      from "@uBehaviour/i18n";

import "./noData.css";

const NoData = (props) => (
  <div className="bs-statistics-no-data">
    <div>
      <span className="fa fa-ban"/>
      <div><T>statistics_no_data</T></div>
    </div>    
  </div>
);

export default NoData;