import { Helper }   from '@uLib/application';
import _ from "lodash";

export default class FilterHelper extends Helper {
  constructor() {
    super('filter', ["api", "currentTenant"]);
  }

  storeForPlanning(values){
    return this.application.getService("api").service("filters", "get").execute({ type: "planning", tenant: this.application.getService("currentTenant").currentId })
      .then(results => {
        const filter = results.length ? results[0] : { 
          name: "planning",
          type: "planning",
          default: true,
          tenant: this.application.getService("currentTenant").currentId
        };
        filter.values = values;
        if(filter._id){
          return this.application.getService("api").service("filters", "put").execute(filter._id, _.omit(filter, "_id"));
        }else{
          return this.application.getService("api").service("filters", "post").execute(filter);
        }
      });
  }

  getForPlanning(){
    return this.application.getService("api").service("filters", "get").execute({ type: "planning", tenant: this.application.getService("currentTenant").currentId })
      .then(results => {
        if(results.length){
          return results[0].values;
        }
        return [];
      });
  }
}