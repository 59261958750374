import Issue from '@universal/types/business/Issue';
import Group from '@universal/types/business/issue/Group';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import React, { CSSProperties, FunctionComponent } from 'react';
import { useLocation } from '@cFeatures/router';

import { Link } from '@cFeatures/router';
import StatesConfiguration from "@universal/types/business/issue/State";
import Data from '@uBehaviour/data';
import { useDataTestId } from '@universal/features/dataTestId';

import './group.css';
import ObjectId from '@universal/types/technic/ObjectId';

interface GroupIssueProps {
  issue: BusinessEntity<Issue>
}

const GroupIssue: FunctionComponent<GroupIssueProps> = ({ issue }) => {
  const style = {
    '--state-display-color': StatesConfiguration[issue.state].color
  } as CSSProperties;

  const [location] = useLocation();

  const groupLinkTestId = `inter-${ useDataTestId('group')}`;
  
  return (
    <Data.Synchronizer entity={ issue }>
    {(issue) => (
      <span className='bs-issue-group-issue' style={ style }>
        <Link to={ `${ location }/issue/${ issue.bsId }`} data-testid={ groupLinkTestId }>
          <span className='fa fa-link' />
          <span>{ issue.bsId }</span>
        </Link>
      </span>
    )}
    </Data.Synchronizer>
  )
};

interface GroupProps {
  group: BusinessEntity<Group>
  issueId: ObjectId
}

const GroupComponent: FunctionComponent<GroupProps> = ({ group, issueId }) => (
  <span className='bs-issue-group'>
  {
    group.issues.filter(i => (i as BusinessEntity<Issue>)._id !== issueId).map(issue => (
      <GroupIssue key={ (issue as BusinessEntity<Issue>)._id } issue={ issue as BusinessEntity<Issue> } />
    ))
  }
  </span>
);

export default GroupComponent;