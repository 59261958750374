// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-tooltip-generalData{
  font-size: 14px;
}
.bs-tooltip-generalData>div{
  margin-bottom: 5px;
}
.bs-tooltip-generalData-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.bs-tooltip-generalData-title{
  flex-shrink: 1;
  font-size: 16px;
  color: #444;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bs-tooltip-generalData-title-bsIdNumber{
  font-weight: bold;
  margin-right: 5px;
}
.bs-tooltip-generalData-title-category{
  text-transform: uppercase;
}
.bs-tooltip-generalData-state{
  flex-shrink: 0;
}
.bs-tooltip-generalData-manager-label{
  margin-right: 20px;
  color: #444;
}`, "",{"version":3,"sources":["webpack://./src/entities/issue/infoTooltipContent.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;AACA;EACE,cAAc;EACd,eAAe;EACf,WAAW;EACX,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB;AACA;EACE,iBAAiB;EACjB,iBAAiB;AACnB;AACA;EACE,yBAAyB;AAC3B;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,WAAW;AACb","sourcesContent":[".bs-tooltip-generalData{\n  font-size: 14px;\n}\n.bs-tooltip-generalData>div{\n  margin-bottom: 5px;\n}\n.bs-tooltip-generalData-header {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n.bs-tooltip-generalData-title{\n  flex-shrink: 1;\n  font-size: 16px;\n  color: #444;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n}\n.bs-tooltip-generalData-title-bsIdNumber{\n  font-weight: bold;\n  margin-right: 5px;\n}\n.bs-tooltip-generalData-title-category{\n  text-transform: uppercase;\n}\n.bs-tooltip-generalData-state{\n  flex-shrink: 0;\n}\n.bs-tooltip-generalData-manager-label{\n  margin-right: 20px;\n  color: #444;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
