import React          from 'react';
import Form           from '@cBehaviour/form';
import T              from '@cBehaviour/i18n';
import Button         from '@cComponents/button';
import Field          from '@cComponents/old/field';
import FormError      from "@cComponents/formError";
import FieldGroup     from '@components/fieldGroup';
import Input          from '@cComponents/old/input';
import FInput         from '@components/input';

import './form.css';

class SupplyForm extends React.Component {
  constructor(props) {
    super(props);
    this._preload   = this._preload.bind(this);
    this._presubmit = this._presubmit.bind(this);
  }

  _preload(value) {
    value.disabled = !value.disabled;
    return value;
  }

  _presubmit(value) {
    value.disabled = !value.disabled;
    if(!value.valorization.cost){
      value.valorization.cost = 0;
    }
    return value;
  }

  render() {   
    return (
      <Form.Model 
        model={ "Supply" }
        observer={ this.props.observer }
        id={ this.props.id }
        ref={ this.props.forwardedRef }
        preload={ this._preload } 
        presubmit={ this._presubmit }
        onSubmitted={this.props.onSubmitted}
      >
        {(supply, errors) => (
            <FormError errors={ errors }>
              <div className="bs-form-supply">
                <FieldGroup type="full" title="generals_informations">
                    <Field>
                      <Field.Label><T>name</T></Field.Label>
                      <Field.Input><Input.Text required={true} name="name"/></Field.Input>
                    </Field>
                    <Field>
                      <Field.Label><T>external_reference</T></Field.Label>
                      <Field.Input><Input.Text name="sku"/></Field.Input>
                    </Field>
                    <Field>                 
                      <Field.Label><T>description</T></Field.Label>
                      <Field.Input><Input.Textarea name="description"/></Field.Input>
                    </Field>  
                    <Input.Object name="valorization">
                      <Field>
                        <Field.Label><T>measure_unit</T></Field.Label>
                        <Field.Input>
                          <Input.Radio.Group name="type" default="pc">
                            <Input.Radio.Value value="other">{(selected) => (<Button.Radio pushed={selected}><T>other_mesure</T></Button.Radio>)}</Input.Radio.Value>
                            <Input.Radio.Value value="kg">{(selected) => (<Button.Radio pushed={selected}><T>kilogramme</T></Button.Radio>)}</Input.Radio.Value>
                            <Input.Radio.Value value="l">{(selected) => (<Button.Radio pushed={selected}><T>liter</T></Button.Radio>)}</Input.Radio.Value>
                            <Input.Radio.Value value="pc">{(selected) => (<Button.Radio pushed={selected}><T>piece</T></Button.Radio>)}</Input.Radio.Value>
                          </Input.Radio.Group>   
                        </Field.Input>
                      </Field>              
                      <Field>
                        <Field.Label><T>measure_unit_cost</T></Field.Label>
                        <Field.Input><Input.Number name="cost" min={0} decimals={2}/></Field.Input>
                      </Field>
                    </Input.Object>
                    <Field>
                      <Field.Label><T>active_f</T></Field.Label>
                      <Field.Input><FInput.YesNo name="disabled"/></Field.Input>
                    </Field>
                </FieldGroup>
              </div>
            </FormError>
        )}
      </Form.Model>
    )
  }
}
export default React.forwardRef((props, ref) => {
  return (<SupplyForm { ...props } forwardedRef={ ref }/>)
})