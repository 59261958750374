// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-assignments-filters-containers .DayPicker,
.bs-assignments-filters-containers .bs-select-wa-values-item {
  color: #5e676c;
}

.bs-assignments-filters-containers .bs-button-radio,
.bs-assignments-filters-containers .bs-filter-period .bs-select-display-default .bs-text,
.bs-assignments-filters-containers .bs-filter-period input {
  color: white !important;
}

.bs-filter-icon {
  margin-right: 4px;
}

.bs-bg-finished {
  color: #1fcc36
}
.bs-bg-to_do {
  color: #f77448
}
.bs-bg-ongoing {
  color: #396988
}
`, "",{"version":3,"sources":["webpack://./src/entities/assignments/filters.css"],"names":[],"mappings":"AAAA;;EAEE,cAAc;AAChB;;AAEA;;;EAGE,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;AACF;AACA;EACE;AACF;AACA;EACE;AACF","sourcesContent":[".bs-assignments-filters-containers .DayPicker,\n.bs-assignments-filters-containers .bs-select-wa-values-item {\n  color: #5e676c;\n}\n\n.bs-assignments-filters-containers .bs-button-radio,\n.bs-assignments-filters-containers .bs-filter-period .bs-select-display-default .bs-text,\n.bs-assignments-filters-containers .bs-filter-period input {\n  color: white !important;\n}\n\n.bs-filter-icon {\n  margin-right: 4px;\n}\n\n.bs-bg-finished {\n  color: #1fcc36\n}\n.bs-bg-to_do {\n  color: #f77448\n}\n.bs-bg-ongoing {\n  color: #396988\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
