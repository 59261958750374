// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-featuresDiscovery-template {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 75px 30px 0 30px;
  gap: 50px;
}

.bs-featuresDiscovery-template-image {
  user-select: none;
  overflow: hidden;
  flex-shrink: 0;
  background-color: #f3edeb;
  height: 385px;
  width: 300px;
  border-radius: var(--std-radius);
  padding-left: 4px;
}

.bs-featuresDiscovery-template-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  user-select: none;
}

.bs-featuresDiscovery-template-title {
  font-size: 22px;
  font-weight: bold;
}

/* Taille unique pour les icones des slides */
.bs-featuresDiscovery-template-icon > :first-child {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/applications/starter/views/featuresDiscovery/template.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,yBAAyB;EACzB,SAAS;AACX;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,cAAc;EACd,yBAAyB;EACzB,aAAa;EACb,YAAY;EACZ,gCAAgC;EAChC,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,iBAAiB;AACnB;;AAEA,6CAA6C;AAC7C;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".bs-featuresDiscovery-template {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  padding: 75px 30px 0 30px;\n  gap: 50px;\n}\n\n.bs-featuresDiscovery-template-image {\n  user-select: none;\n  overflow: hidden;\n  flex-shrink: 0;\n  background-color: #f3edeb;\n  height: 385px;\n  width: 300px;\n  border-radius: var(--std-radius);\n  padding-left: 4px;\n}\n\n.bs-featuresDiscovery-template-content {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n  user-select: none;\n}\n\n.bs-featuresDiscovery-template-title {\n  font-size: 22px;\n  font-weight: bold;\n}\n\n/* Taille unique pour les icones des slides */\n.bs-featuresDiscovery-template-icon > :first-child {\n  width: 36px;\n  height: 36px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 18px;\n  border-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
