// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-old-input-boolean{
  display: flex;
  gap: var(--std-size-inter-element);
  align-items: center;
}
.bs-old-input-boolean{
  width: auto;
  margin-right: 5px;
}
.bs-old-input-boolean{
  border-color: none;
  box-shadow: none;
}`, "",{"version":3,"sources":["webpack://./../common/components/old/input/boolean.css"],"names":[],"mappings":";AACA;EACE,aAAa;EACb,kCAAkC;EAClC,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":["\n.bs-old-input-boolean{\n  display: flex;\n  gap: var(--std-size-inter-element);\n  align-items: center;\n}\n.bs-old-input-boolean{\n  width: auto;\n  margin-right: 5px;\n}\n.bs-old-input-boolean{\n  border-color: none;\n  box-shadow: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
