import React                from 'react';
import T                    from "@cBehaviour/i18n";
import useHelper            from '@uBehaviour/hooks/useHelper';
import AssistanceHelper     from '@uHelpers/assistance';
import CTA                  from '../features/cta';

import './layout.css';

const Layout: React.FunctionComponent<React.PropsWithChildren> = ({ children }) => {
  const assistance = useHelper<AssistanceHelper>("assistance");
  const [assistanceEmail, setAssistanceEmail] = React.useState();
  React.useEffect(() => {
    assistance.getSupportUri().then((uri: string) => setAssistanceEmail(uri));
  }, [assistance]);

  const supportLink = (
    <a href={ assistanceEmail } className="bs-authentification-layout-footer-supportLink" target='_blank' rel="noreferrer">
      <T>login_contact_support_prompt</T>
    </a>
  );

  return (
    <div className="bs-authentification-layout">
      <div className="bs-authentification-layout-login">
        <div className="bs-authentification-layout-header">
          <img src={`/images/logos/betterstreet_color_text_vertical.svg`} alt="Logo BetterStreet"/>
        </div>
        <div className="bs-authentification-layout-content">
        { children }
        </div>
        <div className="bs-authentification-layout-footer">
          <b><T>login_problem</T></b>
          <span><T bind={{ supportLink }}>login_contact_support_link</T></span>
        </div>
      </div>
      <div className="bs-authentification-layout-offer">
        <span><T>starter_login_discover</T></span>
        <CTA.DiscoverStarter />
      </div>
    </div>
  )
};

export default Layout;