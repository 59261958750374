import Event from "./event";

class Client{
  constructor(lib, uri, token){
    this._lib           = lib;
    this._uri           = uri;
    this._token         = token;
    this._authenticated = false;
    this._socket        = null;
    this._stateEvent    = new Event();
    this._receiptEvent  = new Event();
    this._onReceipt     = this._onReceipt.bind(this);
  }
  get onReceipt(){
    return this._receiptEvent;
  }
  get onStateChange(){
    return this._stateEvent;
  }
  get connected(){
    return this._socket !== null && !this._socket.disconnected;
  }
  set token(token){
    this._token = token;
    if(this._authenticated && this.connected){
      this._socket.emit("message", { type: "renewToken", params: this._token });
    }
  }
  isConnected() {
    return !!this._socket;
  }
  connect(){
    if(!this._socket && this._token){
      this._socket = this._lib(`${this._uri.protocol}://${this._uri.host}`, {
        path: this._uri.path,
        transports: ['websocket']
      });

      this._socket.on("authenticateOrder", () => {
        this._socket.emit("authenticate", this._token);
        this._authenticated = true;
      });
      
      this._socket.on("message", (data) => {
        if(data && data.action){
          this._onReceipt(data);
        }
      });
      this._stateEvent.trigger("connect", this);
    }
  }
  disconnect(){
    if(this._socket){
      this._socket.disconnect();
      this._socket = null;
      this._stateEvent.trigger("disconnect", this);
    }
  }
  _onReceipt(data){
    this._receiptEvent.trigger(data.type, data.action, data.datas);
  }
}
export default {
  Client
}