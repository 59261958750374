// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  @page {
    size:  auto;   /* auto is the initial value */
    margin: 0mm;  /* this affects the margin in the printer settings */
  }
  html {
    margin: 0mm 0mm 0mm 0mm; /* margin you want for the content */
  }
}`, "",{"version":3,"sources":["webpack://./src/applications/starter/features/print/print.css"],"names":[],"mappings":"AAAA;EACE;IACE,WAAW,IAAI,8BAA8B;IAC7C,WAAW,GAAG,oDAAoD;EACpE;EACA;IACE,uBAAuB,EAAE,oCAAoC;EAC/D;AACF","sourcesContent":["@media print {\n  @page {\n    size:  auto;   /* auto is the initial value */\n    margin: 0mm;  /* this affects the margin in the printer settings */\n  }\n  html {\n    margin: 0mm 0mm 0mm 0mm; /* margin you want for the content */\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
