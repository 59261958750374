import React            from 'react';
import InnerTextContent from "./innerTextContent";


export default class Text extends React.Component {
  constructor(props){
    super(props);
    this._onChange        = this._onChange.bind(this);
    this._setPlaceholder  = this._setPlaceholder.bind(this);
    this._input           = React.createRef();
    this._last            = "";
    this._stack           = [];
    this._posCursor       = null;
    if(this.props.mask){
      this._stack.push((value, ev) => {
        if(value === this._last){
          return value;
        }
        let maskedValue = "";
        this._posCursor = this._input.current.selectionStart;
        if(this._posCursor === value.length){
          for(let i = 0; i < value.length && i < this.props.mask.length; ++i){
            if(this.props.mask[i] === "|"){
              maskedValue += value[i];
            }else{
              maskedValue += this.props.mask[i];
            }
          }
          while(maskedValue.length < this.props.mask.length && this.props.mask[maskedValue.length] !== "|"){
            maskedValue += this.props.mask[value.length];
          }
        }else if(this._last.length < value.length){
          const target = this._posCursor - 1;
          maskedValue = this._last.substring(0, target) + value.substring(target, target + 1) + this._last.substring(target + 1);
        }else{
          maskedValue = this._last;
        }
        while(this._posCursor < this.props.mask.length && this.props.mask[this._posCursor] !== "|"){
          ++this._posCursor
        }
        return maskedValue.substr(0, this.props.mask.length);
      });
    }
  }
  get value(){
    return this._value;
  }
  set value(value){
    this._last  = value;
    this._value = value ? value : "";
    let start = this._posCursor !== null ? this._posCursor : this._input.current.selectionStart;
    this._input.current.setRangeText(this._value, 0, this._input.current.value.length);
    this._input.current.setRangeText("", start, start, "end");
    this._posCursor = null;
  }
  valid(){
    if(!this.props.regexp){
      return true;
    }
    return this.props.regexp.test(this.value);
  }
  _onChange(ev){
    this.value = this._stack.reduce((value, fn) => fn(value, ev),  ev.target.value);
    if(this.props.onChange && this.valid()){
      this.props.onChange(this.value);
    }
  }
  _setPlaceholder(text){
    this._input.current.placeholder = text;
  }
  componentDidMount(){
    if(this.props.value !== undefined){
      this.value = this.props.value;
    }
  }
  componentDidUpdate(){
    if(this.props.value !== undefined){
      this.value = this.props.value;
    }
  }
  render() {
    this._d = document.createElement("div");
    return React.createElement(React.Fragment, {},      
      React.createElement("input", {
        ref: this._input,
        type: "text",
        className: "bs-input-wa-text" + (this.props.className ? " " + this.props.className : ""),
        onChange: this._onChange,
        autoComplete: "off"
      }),
      React.createElement(InnerTextContent, { onReady: this._setPlaceholder }, this.props.children)
    ); 
  }
}