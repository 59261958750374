// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-statistics-frame {
  background-color: white;
  margin: 24px;
  text-align: center;
  padding: 24px;
}
.bs-statistics-frame-filter{
  display: none;
}
.bs-statistics-frame-title {
  font-size: 1.2rem;
  border-bottom: 1px solid;
  padding-bottom: 6px;
  position: relative;
}
.bs-statistics-frame-title-toCsv{
  position: absolute;
  right: 1.2rem;
  top: 0px;
}
.bs-statistics-frame-title-print{
  position: absolute;
  right: 0px;
  top: 0px;
}
.bs-statistics-frame-title-print:hover,
.bs-statistics-frame-title-toCsv:hover {
  cursor: pointer;
}
.bs-statistics-frame-content {
  
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/statistics/frame.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,YAAY;EACZ,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;EACjB,wBAAwB;EACxB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,QAAQ;AACV;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;AACV;AACA;;EAEE,eAAe;AACjB;AACA;;AAEA","sourcesContent":[".bs-statistics-frame {\n  background-color: white;\n  margin: 24px;\n  text-align: center;\n  padding: 24px;\n}\n.bs-statistics-frame-filter{\n  display: none;\n}\n.bs-statistics-frame-title {\n  font-size: 1.2rem;\n  border-bottom: 1px solid;\n  padding-bottom: 6px;\n  position: relative;\n}\n.bs-statistics-frame-title-toCsv{\n  position: absolute;\n  right: 1.2rem;\n  top: 0px;\n}\n.bs-statistics-frame-title-print{\n  position: absolute;\n  right: 0px;\n  top: 0px;\n}\n.bs-statistics-frame-title-print:hover,\n.bs-statistics-frame-title-toCsv:hover {\n  cursor: pointer;\n}\n.bs-statistics-frame-content {\n  \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
