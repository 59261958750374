// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-team-item .bs-action {
  color: #27ae60;
  display: flex;
  justify-content: space-between;
}

.bs-team-item .bs-action {
  width: 55vw;
}

.bs-team-item .bs-action>div:first-child {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}`, "",{"version":3,"sources":["webpack://./src/old/components/team/item.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".bs-team-item .bs-action {\n  color: #27ae60;\n  display: flex;\n  justify-content: space-between;\n}\n\n.bs-team-item .bs-action {\n  width: 55vw;\n}\n\n.bs-team-item .bs-action>div:first-child {\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
