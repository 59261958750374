import React from 'react';
import T     from '@uBehaviour/i18n';

import './filters.css';

export default (props) => {
  const filters = props.filters ? JSON.parse(props.filters) : null;
  const typeFilter = filters && filters.types ? filters.types : [];
  const stateFilter = filters && filters.states ? filters.states : [];
  return (
    <div className="bs-table-main-head-filters-resume">
      <div className="bs-table-main-head-filters-resume-fake-logo"></div>
      <div className="bs-table-main-head-filters-resume-infos">
        <div>
          <span><T>categories_type</T></span>
          <span>&nbsp;:&nbsp;</span>
          <span>{typeFilter.length ? typeFilter.map((t, i)  => (<span><T>{t}</T>{typeFilter.length > 1 && i < typeFilter.length -1 ? ", " : ""}</span>)) : <T>all</T>}</span>
        </div>
        <div>
          <span><T>interventions_state</T></span>
          <span>&nbsp;:&nbsp;</span>
          <span>{stateFilter.length ? stateFilter.map((s, i)  => (<span><T>{s}</T>{stateFilter.length > 1 && i < stateFilter.length -1 ? ", " : ""}</span>)) : <T>all</T>}</span>
        </div>
      </div>
    </div>      
  )
}