import { fork, put } from 'redux-saga/effects'
import { UserRequest } from 'client/features/users/actions'
import RequestSagas from 'client/core/RequestSagas'
import URLS from 'constants/URLS'

/*Fetch users*/
export const putUsersSagas = new RequestSagas(UserRequest.put, { method: 'PUT', endpoint: URLS.users.put })
putUsersSagas.success = function* () {
}
putUsersSagas.failure = function* () {
  //yield put(flash({ content: Flashes.getError() }))
}

/*Export watchers sagas*/
const sagas = [
  fork(putUsersSagas.saga)
]

export default sagas