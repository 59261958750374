// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-item-tiny {
  box-shadow: 0 0 1px rgba(44, 62, 80, 0.6), 0 5px 10px -3px rgba(44, 62, 80, 0.4);
  background-color: rgba(255, 255, 255, 1);
  transition: all 0.3;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  padding: var(--std-large-size-inter-element);
}`, "",{"version":3,"sources":["webpack://./../common/components/item/tiny.css"],"names":[],"mappings":"AAAA;EACE,gFAAgF;EAChF,wCAAwC;EACxC,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,4CAA4C;AAC9C","sourcesContent":[".bs-item-tiny {\n  box-shadow: 0 0 1px rgba(44, 62, 80, 0.6), 0 5px 10px -3px rgba(44, 62, 80, 0.4);\n  background-color: rgba(255, 255, 255, 1);\n  transition: all 0.3;\n  border-radius: 3px;\n  display: flex;\n  flex-direction: row;\n  overflow: hidden;\n  padding: var(--std-large-size-inter-element);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
