import { Helper } from "@uLib/application";
import _ from "lodash";

export default class ReleaseHelper extends Helper {
  constructor(){
    super("release");
  }
  submit(value){
    if(value._id){
      return this.application.getService("api").service("releases", "put").execute(value._id, value);
    }else{
      return this.application.getService("repository").get("Release").repository.create(value)
    }
  }
  submitNewFeatures(release, value){
    if(value._id){
      return this.application.getService("api").service("releases", "updateFeature").execute(release, value._id, _.omit(value, ["_id"]));
    }else{
      return this.application.getService("api").service("releases", "addFeature").execute(release, value);
    }
  }
  submitBugsFixed(release, value){
    if(value._id){
      return this.application.getService("api").service("releases", "updateBugFixed").execute(release, value._id, _.omit(value, ["_id"]));
    }else{
      return this.application.getService("api").service("releases", "addBugFixed").execute(release, value);
    }
  }
  removeNewFeature(releaseId, featureId){
    return this.application.getService("api").service("releases", "removeNewFeature").execute(releaseId, featureId);
  }
  removeBugFixed(releaseId, bugFixedId){
    return this.application.getService("api").service("releases", "removeBugFixed").execute(releaseId, bugFixedId);
  }
  publish(release){
    return this.application.getService("api").service("releases", "publish").execute(release._id);
  }
  moveFeatureForward(releaseId, idx){
    return this.application.getService("api").service("releases", "rotate").execute(releaseId, "feature", [idx, idx + 1]);
  }
  moveFeatureBack(releaseId, idx){
    return this.application.getService("api").service("releases", "rotate").execute(releaseId, "feature", [idx, idx - 1]);
  }
  moveBugFixedForward(releaseId, idx){
    return this.application.getService("api").service("releases", "rotate").execute(releaseId, "bugFixed", [idx, idx + 1]);
  }
  moveBugFixedBack(releaseId, idx){
    return this.application.getService("api").service("releases", "rotate").execute(releaseId, "bugFixed", [idx, idx - 1]);
  }
}