import React, {
  FunctionComponent
}                   from 'react';

import Form from '@uBehaviour/form';
import Field from '@cComponents/field';
import Input from '@cComponents/input';
import Button from '@cComponents/button';
import T from '@cBehaviour/i18n';

export interface InviteFormValueProps {
  defaultEmail: string;
  type: string;
}

interface InviteFormProps {
  defaultValue: InviteFormValueProps;
  onSubmit: (value: InviteFormValueProps) => void;
}

const InviteForm: FunctionComponent<InviteFormProps> = ({ defaultValue, onSubmit }) => (
  <Form.Simple
    submit={(form, value) => onSubmit(value)}
    default={defaultValue}
  >
    {(ctx, value, errors, form, submit) => (
      <div className='bs-tenant-item-invite-modal-container'>
        <Field.Standart name="defaultEmail">
          <Field.Label><T>tenant_item_invite_modal_form_defaultEmail</T></Field.Label>
          <Field.Input>
            <Input.Text />
          </Field.Input>
        </Field.Standart>
        <Field.Standart name="type">
          <Field.Label><T>tenant_item_invite_modal_form_type</T></Field.Label>
          <Field.Input>
            <Input.Radio.Btn inline>
              <Input.Radio.Value value="betterstreet"><T>tenant_item_invite_modal_form_type_betterstreet</T></Input.Radio.Value>
              <Input.Radio.Value value="attractiveCity"><T>tenant_item_invite_modal_form_type_attractiveCity</T></Input.Radio.Value>
              <Input.Radio.Value value="panneauPocket"><T>tenant_item_invite_modal_form_type_panneauPocket</T></Input.Radio.Value>
            </Input.Radio.Btn>
          </Field.Input>
        </Field.Standart>
        <div className='bs-tenant-item-invite-modal-submit-btn'>
          <Button.Text onClick={() => submit(true)}><T>tenant_item_invite_modal_form_submit</T></Button.Text>
        </div>
      </div>
    )}
  </Form.Simple>
);

export default InviteForm;