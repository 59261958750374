// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-user-filters-skill-item {
  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);
  padding: 12px;
  opacity: 0.8;
  border-radius: 3px;
}`, "",{"version":3,"sources":["webpack://./src/entities/user/filters.css"],"names":[],"mappings":"AAAA;EACE,4EAA4E;EAC5E,aAAa;EACb,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".bs-user-filters-skill-item {\n  box-shadow: 0 0 1px rgb(44 62 80 / 60%), 0 5px 10px -3px rgb(44 62 80 / 40%);\n  padding: 12px;\n  opacity: 0.8;\n  border-radius: 3px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
