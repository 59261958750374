// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-planning-form-printWorkOrder{
  display: flex;
  flex-direction: column;
  height: 100%;
}
.bs-planning-form-printWorkOrder>*:nth-child(1),
.bs-planning-form-printWorkOrder>*:nth-child(2){
  padding: 10px;
}
.bs-planning-form-printWorkOrder>*:nth-child(2){
  flex-grow: 1;
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/planning/form/printWorkOrder.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;AACA;;EAEE,aAAa;AACf;AACA;EACE,YAAY;AACd","sourcesContent":[".bs-planning-form-printWorkOrder{\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n.bs-planning-form-printWorkOrder>*:nth-child(1),\n.bs-planning-form-printWorkOrder>*:nth-child(2){\n  padding: 10px;\n}\n.bs-planning-form-printWorkOrder>*:nth-child(2){\n  flex-grow: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
