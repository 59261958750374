import React, { FunctionComponent } from 'react';
import Display from './display';

import State from '@uTypes/business/State';

interface TinyProps {
  state: State;
};

const Tiny: FunctionComponent<TinyProps> = ({ state }) =>(
  <Display type="tiny" color={state.color} height={4} width={20} />
);

export default Tiny;