import React, { Component } from 'react';
import T from 'i18n-react';
import Autocomplete from 'react-google-autocomplete';
import createReactClass from 'create-react-class';
//import { GoogleMapLoader, GoogleMap, Marker } from 'react-google-maps'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import Nears from 'client/features/issues/components/form/Nears.jsx';
import BsLocation from 'client/lib/BsLocation';
 
import genericOpenImage from 'client/assets/images/tags/generic_open.png'


const Map = withGoogleMap(class MapBase extends React.Component {
  constructor(props){
    super(props)
    this._marker = React.createRef();
  }
  render() {
    const { props } = this
    return (
      <GoogleMap
        defaultZoom={ props.defaultZoom }
        center={ { lat: props.marker.latitude, lng: props.marker.longitude } }
        onIdle={ props.onIdle }
        options={ props.options }
        onDragEnd={ props.centerChange }
        onZoomChanged={ props.centerChange }
      >
        <Marker
          position={ { lat: props.marker.latitude, lng: props.marker.longitude } }
          icon={ props.marker.icon }
          draggable={ true }
          onDragEnd={ props.onDrag }
          ref={ this._marker }
        />
        {
          props.currentNear && <Marker position={ { lat: props.currentNear.latitude, lng: props.currentNear.longitude } } icon={ props.currentNear.marker }/>
        }
      </GoogleMap>
    )
  }
})

class GMAutocompleteField extends Component {

  constructor(props) {
    super()
    this._location = React.createRef();
    this.displayNearIssue = this.displayNearIssue.bind(this)
    this.onPlaceSelected = this.onPlaceSelected.bind(this)
    this.onMarkerDrag = this.onMarkerDrag.bind(this)
    this.setLatLng = this.setLatLng.bind(this)
    this.state = {
      currentNear: null,
      location: props.defaultLocation || '',
      latitude: props.defaultLatitude,
      longitude: props.defaultLongitude
    }
  }

  locationChange(evt) {
    this.setState({
      location: evt.target.value
    })
  }

  displayNearIssue(currentNear) {
    if (currentNear && this.state.currentNear && currentNear._id === this.state.currentNear._id)
      currentNear = null
    this.setState({
      currentNear
    })
  }

  componentDidMount() {
    window.setTimeout(() => {
      this._location.current.autocomplete = "off";
    }, 0);
  }

  onPlaceSelected(place) {
    const latitude = place.geometry.location.lat()
    const longitude = place.geometry.location.lng()
    this.setLatLng(latitude, longitude, place.formatted_address)
    const data = BsLocation.getAddressFromGMComponents(place.address_components)
    data.latitude = latitude
    data.longitude = longitude
    setTimeout(() => {
      if (this.props.input)
        this.props.input.onChange(this.props.input.value = data)
      this.onChange(data)
    }, 150)
  }

  onMarkerDrag(data) {
    const latitude = data.latLng.lat()
    const longitude = data.latLng.lng()
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ latLng: new window.google.maps.LatLng(latitude, longitude) }, (results, status) => {
      this.setLatLng(latitude, longitude, results[0].formatted_address)
      const data = BsLocation.getAddressFromGMComponents(results[0].address_components)
      data.latitude = latitude
      data.longitude = longitude
      this.onChange(data)
      if (this.props.input)
        this.props.input.onChange(this.props.input.value = data)
      this.setState({ values: data })
    })
  }

  onChange = (data) => {
    if (this.props.onChange)
      this.props.onChange(data)
  }

  setLatLng(latitude, longitude, location) {
    this.setState({
      latitude,
      longitude,
      location
    })
    if (!this.props.change)
      return
    this.props.change('latitude', latitude)
    this.props.change('longitude', longitude)
  }

  render() {
    const {
      country,
      containerHeight = '600px',
      form = {},
      nears = [],
      onCancel,
      field = {},
      onSave
    } = this.props

    const {
      latitude,
      longitude,
      location
    } = this.state

    const { currentNear } = this.state

    return (
      <div>
        <div className='autocomplete'>
          <T.span className='new-issue-address-explanation' text='form_location_legend' />
          {
            onCancel &&
            <a className='button-a orange small u-pull-right' onClick={ onCancel }>
              { T.translate('cancel') }
            </a>
          }
          {
            onSave && this.state.values && this.state.values.street &&
            <a className='button-a green small u-pull-right u-margin-right-tiny' onClick={ onSave.bind(null, this.state.values) }>
              { T.translate('save_changes') }
            </a>
          }
          <Autocomplete
            //{ ...this.props.input }
            style={ { width: '100%', margin: '8px 0' } }
            onPlaceSelected={ this.onPlaceSelected }
            className='ico address span12'
            type='text'
            value={ location }
            onChange={ this.locationChange.bind(this) }
            ref={ this._location }
            options= {{
              types: ['address'],
              fields: ["formatted_address", "address_components", "geometry.location"],
              componentRestrictions: { country }
            }}
            name='address'
          />
          { form && field && form.submitCount > 0 && form.errors[field.name] && <p className='error'>{form.errors[field.name]}</p> }
        </div>
        <section>
          <div style={ { height: containerHeight } }>
            <Map
              defaultZoom={ 15 }
              containerElement={
                <div style={ { height: '100%' } } />
              }
              mapElement={
                <div style={ { height: '100%' } } />
              }
              ref={ (map) => this.map = map }
              onIdle={ this.onMapLoaded }
              marker={ {
                latitude,
                longitude,
                icon: genericOpenImage
              } }
              onDrag={ this.onMarkerDrag }
              currentNear={ currentNear }
              centerChange={ this.centerChange }
            />
          </div>
          {
            nears && nears[0] &&
            <Nears
              goToIssue={ this.props.goToIssue }
              nears={ nears }
              title='new_nears_public_space'
              displayNearIssue={ this.displayNearIssue }
            />
          }
        </section>
      </div>
    )
  }
}

export default GMAutocompleteField
