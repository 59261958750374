// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-layout-file-standart>div{
  display: flex;
  flex-direction: column;
}

.bs-layout-file-standart>div>* {
  margin: 10px 0px;
}`, "",{"version":3,"sources":["webpack://./../common/components/layout/file/standart.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".bs-layout-file-standart>div{\n  display: flex;\n  flex-direction: column;\n}\n\n.bs-layout-file-standart>div>* {\n  margin: 10px 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
