import React, { FunctionComponent } from 'react';
import ExternalLink from '@cComponents/externalLink';
import { IssueLocation } from '@uTypes/business/Issue';
import BaseAddress from '@cFeatures/address';
import Building from '@uTypes/business/Building';
import ObjectId from '@uTypes/technic/ObjectId';

import './address.css';

interface AddressProps {
  withIcon?: boolean;
  location: IssueLocation;
}

interface LocationInfoProps {
  locationInfo: string;
}
const LocationInfo: FunctionComponent<LocationInfoProps> = ({ locationInfo }) => <>{ locationInfo }</>;

interface PlaceProps {
  building: Building;
  placeId: ObjectId;
}

const Place: FunctionComponent<PlaceProps> = ({ building, placeId }) => {
  const component = React.useMemo(() => {
    const place = building.places.find(p => p._id === placeId);
    if(!place){
      return null;
    }
    if(place.externalLink){
      return (
        <ExternalLink url={ place.externalLink }>
          { place.name }
        </ExternalLink>
      )
    }
    return (<>{ place.name }</>);
  }, [building, placeId]);

  return component;
}

const BuildingAddress: FunctionComponent<AddressProps> = ({ withIcon = true, location }) => {
  const building = location.building as Building;

  const iconComponent = React.useMemo(() => withIcon
    ? (<span className="fa fa-building-o" />)
    : null
  , [withIcon]);

  const buildingComponent = React.useMemo(() => {
    if(building.externalLink){
      return (
        <ExternalLink url={ building.externalLink }>
          { building.name }
        </ExternalLink>
      );
    }
    return (<>{ building.name }</>)
  }, [building]);

  const subLocation = React.useMemo(() => {
    if(location.locationInfo){
      return (<LocationInfo locationInfo={ location.locationInfo } />);
    }
    if(location.place){
      return (<Place building={ building } placeId={ location.place } />);
    }
    return null;
  }, [building, location.locationInfo, location.place]);

  const buildingAndSubLocation = React.useMemo(() => {
    if(!subLocation){
      return buildingComponent;
    }
    return (
      <>
        { buildingComponent }
        &nbsp;/&nbsp;
        { subLocation }
      </>
    );
  }, [buildingComponent, subLocation]);

  return (
    <span className="bs-issues-address">
      { iconComponent }
      { buildingAndSubLocation }
      ,&nbsp;
      <BaseAddress withIcon={ false } location={ building.location } />
    </span>
  );
}

const Address: FunctionComponent<AddressProps> = ({ withIcon = true, location }) => {
  const component = React.useMemo(() => location.building
    ? (<BuildingAddress withIcon={ withIcon } location={ location } />)
    : (<BaseAddress withIcon={ withIcon } location={ location } />)
  , [withIcon, location]);  
  return component;
}

export default Address;