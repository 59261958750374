import React from 'react'

import { isAuthorized } from 'client/features/issues/lib'

import StatesDropDown from 'client/features/issues/components/common/StatesDropdown.jsx'
import IssueStateBannerSVG from '../common/IssueStateBannerSVG'
import DropDown from '../../../common/components/DropDown'
import IssueUpdatesWrapper from 'client/features/issues/containers/UpdatesWrapper.jsx'

const IssueListState = ({ issue = {}, user, displayStateModal, confirmations = {}, readOnly }) => (
  <div className='c-issue-state'>
    {
      issue && issue.tag && issue.tag.key !== 'congratulation' && !readOnly && isAuthorized(issue, 'state') &&
      <DropDown
        renderToggle={ () => <IssueStateBannerSVG state={ issue.state } workOrderState={ issue.workOrder.state } label big/> }
        renderBody={ (close) =>
          <StatesDropDown
            updateState={
              (state, issue) => {
                displayStateModal(state, issue)
                close()
            }
            }
            defaultState={ issue.state.value }
            issue={ issue }
          />
        }
        confirmation={ confirmations.state }
        confirmationSize='2x'
      />
    }
    {
      (readOnly || (issue.tag && issue.tag.key !== 'congratulation' && !isAuthorized(issue, 'state'))) &&
      <IssueStateBannerSVG state={ issue.state } workOrderState={ issue.workOrder.state } label big caret={ false } />
    }
  </div>
)

export default IssueUpdatesWrapper(IssueListState)