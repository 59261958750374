import React, { PureComponent } from 'react'
import { WithContext as ReactTags } from 'react-tag-input'
import PropTypes from 'prop-types'
import { Modal } from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
import T from 'i18n-react'
import _ from 'lodash'
 

class IssueHashtags extends PureComponent {
    constructor() {
        super()
        this.edit = this.edit.bind(this)
        this.displayDeleteModal = this.displayDeleteModal.bind(this)
        this.removeHastag = this.removeHastag.bind(this)
        this.state = {
            currentEdit: null,
            showRemoveModal: false
        }
    }

    edit(currentEdit) {
        if (this.props.customRemove)
            return this.props.removeHastag(currentEdit)
        this.setState({
            currentEdit,
            showRemoveModal: true
        })
    }

    displayDeleteModal() {
        this.setState({
            showRemoveModal: !this.state.showRemoveModal
        })
    }

    removeHastag() {
        if (this.props.removeHastag)
            this.props.removeHastag(this.state.currentEdit)
        this.setState({ showRemoveModal: false, currentEdit: null })
    }

    render() {
        const { hashtags = [], bsId = '', search = () => null, disabled, forceEditMode } = this.props

        return (
            <div onClick={ (e) => e.stopPropagation() }>
                {
                    hashtags.map((hashtag, index) => 
                        <IssueHashtags.Hashtag
                            key={ index }
                            hashtag={ hashtag }
                            edit={ this.edit }
                            search={ search }
                            disabled={ disabled }
                            forceEditMode={ forceEditMode }
                        />
                    )
                }
                {
                    this.state.currentEdit &&
                        <IssueHashtags.DeleteHashtagModal
                            show={ this.state.showRemoveModal }
                            onHide={ this.displayDeleteModal }
                            removeHastag={ this.removeHastag }
                            hashtag={ this.state.currentEdit }
                            bsId={ bsId }
                        />
                }
            </div>
        )
    }
}

IssueHashtags.Hashtag = ({ hashtag, onClick, edit = () => null, search, disabled, forceEditMode, showUsed }) => 
    <div
        className={ 
            classNames('c-hashtag',
                { 
                    'c-hashtag--force-edit': forceEditMode, 
                    'u-cursor-pointer': onClick
                }
            )
        }
        onClick={ onClick ? onClick.bind(null, hashtag) : search ? search.bind(null, hashtag) : null }
    >
        #{ hashtag.label }&nbsp;
        {
            showUsed && <small>({ hashtag.used })&nbsp;</small>
        }
        {
            !disabled &&
                <a className='c-hashtag__action' onClick={ edit.bind(null, hashtag) }>
                    <FontAwesome name='remove' />&nbsp;
                </a>
        }
        { /*<a className='c-hashtag__action' onClick={ search.bind(null, hashtag) }>
                    <FontAwesome name='search' />
        </a>*/ }
    </div>

IssueHashtags.DeleteHashtagModal = ({ show, onHide, removeHastag, hashtag = {}, bsId }) =>
    <Modal show={ show } onHide={ onHide } bsSize="large" className='modal-helper'>
        <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-lg'>
                <T.span text='remove_hashtag_from_issue'/>
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div>
                <T.span text='remove_hashtag_from_issue_confirmation' /> <strong>#{ hashtag.label }</strong>&nbsp;
                <T.span text='remove_hashtag_from_issue_confirmation_2' /> <strong>{ bsId }</strong>?
            </div>
        </Modal.Body>
        <Modal.Footer>
            <button className='button-a' onClick={ onHide }>
                { T.translate('cancel') }
            </button>
            <button className='button-a orange' onClick={ removeHastag }>
                { T.translate('remove') }
            </button>
        </Modal.Footer>
    </Modal>

IssueHashtags.CreateHashtagModal = class CreateHashtagModal extends PureComponent {
    constructor(props) {
        super(props)
        this._input = React.createRef();
        this.change = this.change.bind(this)
        this.delete = this.delete.bind(this)
        this.addition = this.addition.bind(this)
        this.addHashtag = this.addHashtag.bind(this)
        this.addExistinghashtag = this.addExistinghashtag.bind(this)
        this.submit = this.submit.bind(this)
        this.state = {
            value: '',
            hashtags: props.hashtags ? props.hashtags.map(hashtag => ({ id: hashtag._id, text: hashtag.label })) : [],
            key: 1
        }
    }

    change(value) {
        this.setState({ value })
    }

    delete(i) {
        const { hashtags } = this.state
        hashtags.splice(i, 1)
        this.setState({ hashtags })
    }
 
    addition(hashtag) {
        hashtag = hashtag.replace(/#/g, '').replace(/ /g, '_').trim()
        const { hashtags } = this.state
        const existing = hashtags.some(data => hashtag === data.text)
        if (existing)
            return
        hashtags.push({
            id: hashtag,
            text: hashtag
        })
        this.setState({ hashtags, value: '' })
        this.forceUpdate()
    }

    addExistinghashtag(hashtag) {
        this.addition(hashtag.label)
    }

    addHashtag() {
        //if (this._input.current.child.state && this._input.current.child.state.query) {
        if(this.state.value){
            this.addition(this.state.value)
            this.setState({ key: this.state.key + 1 })
        }
    }

    submit() {
        this.props.onHide()
        this.props.onSubmit(this.state.hashtags.map(hashtag => hashtag.text))
    }

    render() {
        const { hashtags, value } = this.state
        const { show, onHide, mentions = [], mostUsed = [] } = this.props

        return (
            <Modal show={ show } onHide={ onHide } bsSize="large" className='modal-helper'>
                <Modal.Header closeButton>
                    <Modal.Title id='contained-modal-title-lg'>
                        <T.span text='add_hashtag_to_issue'/>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={ { overflow: 'visible' } }>
                    <div>
                        <ReactTags
                            key={ this.state.key }
                            tags={ hashtags }
                            handleInputChange={ this.change }
                            suggestions={ mentions.map(hashtag => {return {text: hashtag.label, id: hashtag._id}}) }
                            handleDelete={ this.delete }
                            handleAddition={ (hashtag) => this.addition(hashtag.text) }
                            placeholder={ T.translate('add_a_hashtag_placeholder') }
                            allowDeleteFromEmptyInput={ false }
                            ref={ this._input }
                            removeComponent={ (props) => 
                                <a onClick={ props.onRemove } className={ props.className }>
                                    <FontAwesome name='remove' />&nbsp;
                                </a>
                            }
                            classNames={ {
                                tags: 'u-margin-bottom-small',
                                tagInput: 'u-display-block u-margin-top',
                                tagInputField: 'c-field',
                                tag: 'c-hashtag c-hashtag--force-edit',
                                remove: 'u-margin-left-tiny c-hashtag__action'
                            } }
                        />
                        {
                            value && value[0] && 
                                <div className='u-margin-bottom-small'>
                                    <button className='button-a blue' onClick={ this.addHashtag }>
                                        { T.translate('add') }
                                    </button>
                                </div>
                        }
                    </div>
                    {
                        mostUsed && mostUsed[0] &&
                            <div>
                                <p><strong>{ T.translate('most_used_hashtags') }</strong></p>
                                {
                                    mostUsed.map((hashtag, index) => 
                                        <IssueHashtags.Hashtag
                                            key={ index }
                                            hashtag={ hashtag }
                                            onClick={ this.addExistinghashtag }
                                            disabled
                                            forceEditMode
                                            showUsed
                                        />
                                    )
                                }
                            </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button className='button-a' onClick={ onHide }>
                        { T.translate('cancel') }
                    </button>
                    <button className='button-a orange' onClick={ this.submit }>
                        { T.translate('save_changes') }
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

IssueHashtags.propTypes = {
    hashtags: PropTypes.array,
    bsId: PropTypes.string,
    search: PropTypes.func
}

export default IssueHashtags