import React, { PureComponent } from 'react'
import _ from 'lodash'
import { compose } from 'redux'
import { connect } from 'react-redux'

import IssueUpdatesWrapper from 'client/features/issues/containers/UpdatesWrapper.jsx'
import RecurrenceDetail from '@entities/recurrence/detail';
import Modal from '@cComponents/modal';

import {
  RecurrenceRequest
} from 'client/features/issues/actions'

const IssueDataWrapper = (WrappedComponent) => {
  class IssueDataHOC extends PureComponent {
    constructor(props) {
      super(props)
      this.ownerChange = this.ownerChange.bind(this)
      this.deadlineChange = this.deadlineChange.bind(this)
      this.displayChange = this.displayChange.bind(this)
      this.transferChange = this.transferChange.bind(this)
      this.priorityChange = this.priorityChange.bind(this)
      this.deleteRecurrence = this.deleteRecurrence.bind(this)
      this.state = {
        showRecurrenceModal: false
      }
    }

    toggleRecurrenceModal = () => {
      this.setState(state => ({
        showRecurrenceModal: !state.showRecurrenceModal
      }))
    }

    ownerChange(payload) {
      this.props.updateIssue('owner', { payload })
    }

    deadlineChange(deadline) {
      if (this.props.issue.deadline === deadline)
        return
      this.props.updateIssue('deadline', {
        payload: { deadline }
      })
    }

    displayChange(display) {
      this.props.updateIssue('display', { payload: { display } })
    }

    transferChange(payload) {
      this.props.updateIssue('transfer', { payload })
      if (payload.changeState && this.props.issue.state.value !== 'transferred' && payload.transfer.value !== 'none') {
        this.props.displayStateModal('transferred')
      }
    }


    priorityChange(priority) {
      this.props.updateIssue('priority', { payload: { priority } })
    }

    deleteRecurrence() {
      this.props.dispatch(
        RecurrenceRequest.delete.action({
          params: {
            id: this.props.issue.recurrence._id
          }
        })
      )
    }
    
    render() {
      return (
        <div>
          <WrappedComponent
            ownerChange={ this.ownerChange }
            deadlineChange={ this.deadlineChange }
            displayChange={ this.displayChange }
            transferChange={ this.transferChange }
            priorityChange={ this.priorityChange }
            displayRecurrenceModal={ this.toggleRecurrenceModal }
            { ...this.props }
          />
          {this.state.showRecurrenceModal && (
            <Modal.Show style={{ width: "80vw", height: '70vh' }} close={ this.toggleRecurrenceModal }>
              <RecurrenceDetail recurrenceId={this.props.issue.recurrence._id} />
            </Modal.Show>
          )}
        </div>
      )
    }
  }

  return IssueDataHOC
}


const mapStateToProps = (state) => {
  const { session } = state
  return {
    colleagues: session.colleagues
  }
}

export default compose(IssueUpdatesWrapper, connect(mapStateToProps), IssueDataWrapper)