// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-issue-assignment-add{
  display: flex;
  justify-content: flex-end;
}
.bs-old-issue-assignment-add>.bs-button{
  margin-left: 10px;
  color: #aaa;
  font-weight: 600;
}
.bs-old-issue-assignment-add>.bs-button:hover{
  cursor: pointer;
}

.bs-old-issue-detail-left {
  flex:1;
  padding: 12px;
}

.bs-old-issue-detail-state {
  position: absolute;
  top: 0px;
  right: 0; 
}`, "",{"version":3,"sources":["webpack://./src/old/features/issues/containers/Issue.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;AAC3B;AACA;EACE,iBAAiB;EACjB,WAAW;EACX,gBAAgB;AAClB;AACA;EACE,eAAe;AACjB;;AAEA;EACE,MAAM;EACN,aAAa;AACf;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,QAAQ;AACV","sourcesContent":[".bs-old-issue-assignment-add{\n  display: flex;\n  justify-content: flex-end;\n}\n.bs-old-issue-assignment-add>.bs-button{\n  margin-left: 10px;\n  color: #aaa;\n  font-weight: 600;\n}\n.bs-old-issue-assignment-add>.bs-button:hover{\n  cursor: pointer;\n}\n\n.bs-old-issue-detail-left {\n  flex:1;\n  padding: 12px;\n}\n\n.bs-old-issue-detail-state {\n  position: absolute;\n  top: 0px;\n  right: 0; \n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
