import { Helper }                 from "@uLib/application";
import UserStorageStrategyService from "@universal/services/userStorageStrategy";

class GeniallyHelper extends Helper {
  constructor() {
    super('genially');
  }
  async markAsSeen(id: string){
    const userStorage = this.application.getService<UserStorageStrategyService>("userPersistentStorage");
    const consultedGuides = await userStorage.get<string[]>("genially", true) || [];

    if(consultedGuides?.length && consultedGuides.includes(id)) {
      return;
    }
    consultedGuides.push(id);

    await userStorage.set("genially", consultedGuides, true);
  };

  async wasSeenByUser(id: string){
    const userStorage = this.application.getService<UserStorageStrategyService>("userPersistentStorage");
    const consultedGuides = await userStorage.get<string[]>("genially", true);

    return consultedGuides?.length && consultedGuides.includes(id);
  };
}

export default GeniallyHelper;
