// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-user-details{
  display: flex;
  flex-direction: row;
  margin-top: var(--std-size-inter-element);
}

.bs-user-details > div {
  width: 50%;
}

.bs-user-details-title {
  font-weight: 550;
}
`, "",{"version":3,"sources":["webpack://./src/entities/user/details.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".bs-user-details{\n  display: flex;\n  flex-direction: row;\n  margin-top: var(--std-size-inter-element);\n}\n\n.bs-user-details > div {\n  width: 50%;\n}\n\n.bs-user-details-title {\n  font-weight: 550;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
