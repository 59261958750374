import React, { FunctionComponent }     from 'react';
import { Grid }                         from 'semantic-ui-react';
import dayjs                            from 'dayjs';
import Display                          from '@uComponents/displayIf';
import T                                from '@uBehaviour/i18n';

import sortStatementsByType             from '@uBusiness/lib/valorizations/sortStatementsByType';

import Valorization, { Statement }      from '@uTypes/business/Valorization';
import Button                           from './button';

import { DurationProps, QuantityProps } from './statements/type';

import * as StatementComponent          from './statements';
import Footer from './footer';
import AclService from '@universal/services/acl';
import useService from '@universal/behaviour/hooks/useService';
import Acl from '@uBehaviour/acl';


const minuteNumberToString = (num: number) => num < 10 ? '0' + num : num;

const Duration: FunctionComponent<DurationProps> = ({ duration }) => (
  <span>{ `${ Math.floor(duration) }:${ minuteNumberToString(Math.round((duration % 1) * 60)) }` }</span>
);

const Quantity: FunctionComponent<QuantityProps> = ({ quantity, unit }) => (
  <span>{ `${quantity} ${unit}` }</span>
);


const AgentStatementComponent = StatementComponent.Agent(Duration);
const TeamStatementComponent = StatementComponent.Team(Duration);
const EquipmentStatementComponent = StatementComponent.Equipment(Duration, Quantity);
const SupplyStatementComponent = StatementComponent.Supply(Quantity);
const ServiceStatementComponent = StatementComponent.Service;



interface HeaderProps {
  valorization: Valorization;
  update: (valorization: Valorization) => void;
}
const Header: FunctionComponent<HeaderProps> = ({ valorization, update }) => {
  const updateHandler = React.useCallback(() => {
    update(valorization);
  }, [valorization, update]);

  const acl = useService<AclService>('acl');
  const canSeeSomeCost = React.useMemo(() => {
    return acl.connectedUserIsAllow('equipments', 'readCost')
      || acl.connectedUserIsAllow('supplies', 'readCost')
      || acl.connectedUserIsAllow('users', 'readCost')
      || acl.connectedUserIsAllow('teams', 'readCost');
  }, [acl]);

  return (
    <>
      <Grid.Row>
        <Grid.Column width="4" verticalAlign='middle'><T>valorization_item_header_statementOf</T> :</Grid.Column>
        <Grid.Column width="9" verticalAlign='middle'>{ dayjs(valorization.statementOf).format("DD/MM/YYYY") }</Grid.Column>
        <Acl.If resource="valorizations" action="writeOnAssignment">
          <Grid.Column width="3" textAlign='center' verticalAlign='middle'>
            <Button onClick={ updateHandler } data-testid={`valorization-${ valorization._id }-item-update`}>
              <T>valorization_item_header_update</T>
            </Button>
          </Grid.Column>
        </Acl.If>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width="7"/>
        <Grid.Column width={ canSeeSomeCost ? "3" : "9" } textAlign='center'><b><T>valorization_item_header_quantity</T></b></Grid.Column>
        <Display.If condition={ canSeeSomeCost }>
          <Grid.Column width="3" textAlign='center'><b><T>valorization_item_header_unit_cost</T></b></Grid.Column>
          <Grid.Column width="3" textAlign='center'><b><T>valorization_item_header_total_cost</T></b></Grid.Column>
        </Display.If>
      </Grid.Row>
    </>
  );
};


interface ContentProps {
  statements: Array<Statement>
};
const Content: FunctionComponent<ContentProps> = ({ statements }) => {
  const dicTypeStatements = sortStatementsByType(statements);
  
  return (
    <>
      <Display.If condition={ dicTypeStatements.agents.length || dicTypeStatements.teams.length }>
        <StatementComponent.Title displayCostResource='teams' displayCostAction='readCost' statements={ (dicTypeStatements.agents.map(a => a.statement) as Array<Statement>).concat(dicTypeStatements.teams.map(t => t.statement)) }>
          <T>valorization_item_teamNAgent</T>
        </StatementComponent.Title>
        {
          dicTypeStatements.teams.map(statement => (<TeamStatementComponent statement={ statement.statement } />))
        }
        {
          dicTypeStatements.agents.map(statement => (<AgentStatementComponent statement={ statement.statement } />))
        }
      </Display.If>
      <Display.If condition={ dicTypeStatements.equipments.length }>
        <StatementComponent.Title displayCostResource='equipments' displayCostAction='readCost' statements={ dicTypeStatements.equipments.map(e => e.statement) }>
          <T>valorization_item_equipment</T>
        </StatementComponent.Title>
        {
          dicTypeStatements.equipments.map(statement => (<EquipmentStatementComponent statement={ statement.statement } />))
        }
      </Display.If>
      <Display.If condition={ dicTypeStatements.supplies.length }>
        <StatementComponent.Title displayCostResource='supplies' displayCostAction='readCost' statements={ dicTypeStatements.supplies.map(s => s.statement) }>
          <T>valorization_item_supply</T>
        </StatementComponent.Title>
        {
          dicTypeStatements.supplies.map(statement => (<SupplyStatementComponent statement={ statement.statement } />))
        }
      </Display.If>
      <Display.If condition={ dicTypeStatements.services.length }>
        <StatementComponent.Title displayCostResource='valorizations' displayCostAction='readCost' statements={ dicTypeStatements.services.map(s => s.statement) }>
          <T>valorization_item_service</T>
        </StatementComponent.Title>
        {
          dicTypeStatements.services.map(statement => (<ServiceStatementComponent statement={ statement.statement } />))
        }
      </Display.If>
    </>
  );
}

interface ItemProps {
  valorization: Valorization;
  update: (valorization: Valorization) => void;
};

const Item: FunctionComponent<ItemProps>= ({ valorization, update }) => {
  const acl = useService<AclService>('acl');
  const maySeeSomeCost = React.useMemo(() => {
    return acl.connectedUserIsAllow('equipments', 'readCost')
      || acl.connectedUserIsAllow('supplies', 'readCost')
      || acl.connectedUserIsAllow('users', 'readCost')
      || acl.connectedUserIsAllow('teams', 'readCost');
  }, [acl]);
  return (
    <Grid columns="16">
      <Header valorization={ valorization } update={ update } />
      <Content statements={ valorization.statements } />
      <Display.If condition={ maySeeSomeCost }>
        <Footer statements={ valorization.statements } />
      </Display.If>
    </Grid>
  );
}

export default Item;