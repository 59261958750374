import React, { ComponentType, ReactElement } from 'react';
import defer from '@uLib/defer';

export interface QuestionProps<T> {
  data: T;
  accept: () => void;
  reject: () => void;
  error?: (error: any) => void;
}
interface Context<T> { 
  promise: Promise<boolean>,
  resolve: (data: boolean) => void,
  reject: (error: any) => void,
  data: T
}

function useAsyncValidationWithQuestion<T>(Question: ComponentType<QuestionProps<T>>) : [ReactElement<any, any> | null, (data: T) => Promise<boolean>]{
  const [context, setContext] = React.useState<Context<T> | null>(null);

  const resolve = React.useCallback(() => {
    (context as Context<T>).resolve(true);
    setContext(null);
  }, [context, setContext]);

  const reject = React.useCallback(() => {
    (context as Context<T>).resolve(false);
    setContext(null);
  }, [context, setContext]);

  const error = React.useCallback((error: any) => {
    (context as Context<T>).reject(error);
    setContext(null);
  }, [context]);

  const validateData = React.useCallback((data: T) => {
    const promise = defer<boolean>();
    setContext({ ...promise, data });
    return promise.promise;
  }, [setContext]);
  
  const element = React.useMemo(() => {
    if(!context){
      return null;
    }
    return (
      <Question data={ context.data } accept={ resolve } reject={ reject } error={ error }/>
    );
  }, [context]);

  return [element, validateData];
};

export default useAsyncValidationWithQuestion;