import React  from "react";
import Modal  from "../modal";
import Flow   from "../flow";
export default class Wizard extends React.Component{
  static Step = Flow.Step;
  constructor(props){
    super(props);
    this._modal = React.createRef();
    this._flow  = React.createRef();
  }
  close(){
    this._modal.current.close();
  }
  clear(){
    this._flow.current.clear();
  }
  next(context){
    this._flow.current.next(context);
  }
  previous(){
    this._flow.current.previous();
  }
  render(){
    return React.createElement(
      Modal.Show, {
        ref: this._modal,
        userCanClose: this.props.userCanClose,
        close: this.props.close,
        style: Object.assign(this.props.style, { overflow: "hidden" })
      }, 
      React.createElement.apply(React, [Flow, {
        animate: this.props.animate,
        onFinish: this.props.onFinish
      }].concat(this.props.children))
    )
  }
}