// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-address{
  color: #27ae60
}

.bs-issues-address .bs-external-link {
  color: #27ae60;
  text-decoration: underline;
}

.bs-issues-address .bs-external-link:hover {
  color: #27ae60;
}

.bs-issues-address>.fa{
  margin-right: 2px;
}`, "",{"version":3,"sources":["webpack://./src/entities/assignmentsAndIssues/address.css"],"names":[],"mappings":"AAAA;EACE;AACF;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-issues-address{\n  color: #27ae60\n}\n\n.bs-issues-address .bs-external-link {\n  color: #27ae60;\n  text-decoration: underline;\n}\n\n.bs-issues-address .bs-external-link:hover {\n  color: #27ae60;\n}\n\n.bs-issues-address>.fa{\n  margin-right: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
