import { Service }        from "@uLib/application";


export default class ResourcesService extends Service {
  constructor() {
    super("resources");
  }
  start(){
    return new Promise(resolve => {
      window.addEventListener("load", () => resolve());
    });
  }
}