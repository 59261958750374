// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-itemTitle {
  margin:0;
  font-size: 1.2em;
  font-weight: 450;
  color: rgb(94, 103, 108);
}

.bs-itemTitle .bs-data-id {
  margin-right: 5px;
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./../common/components/title.css"],"names":[],"mappings":"AAAA;EACE,QAAQ;EACR,gBAAgB;EAChB,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB","sourcesContent":[".bs-itemTitle {\n  margin:0;\n  font-size: 1.2em;\n  font-weight: 450;\n  color: rgb(94, 103, 108);\n}\n\n.bs-itemTitle .bs-data-id {\n  margin-right: 5px;\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
