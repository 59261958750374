import Display from '@uComponents/displayIf';
import React, { FunctionComponent, MouseEvent } from 'react';

import './button.css';

interface ButtonProps {
  onClick: () => void;
  notificationNotSeen: number;
}

const Button: FunctionComponent<ButtonProps> = ({ notificationNotSeen, onClick }) => {
  const stopPropagationAndTriggerOnClick = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onClick();
  }, [onClick]);

  return (
    <div className='bs-notifications-button' onClick={ stopPropagationAndTriggerOnClick }>
      <span className='fa fa-bell-o fa-lg' />
      <Display.If condition={ notificationNotSeen }>
        <div className='bs-notifications-button-quantity'>
          <span>{ notificationNotSeen }</span>
        </div>
      </Display.If>
    </div>
  );
}

export default Button;