import React from "react";
import GeneralTooltip from "@entities/issue/infoTooltipContent";
import Data from "@uBehaviour/data";
import AssignmentTooltip from "./assignmentTooltip"; 

import "./tooltipContent.css";



const IssueTooltip = ({ id }) => (
  <Data.One model="Assignment" id={ id } load={Object.assign({}, GeneralTooltip.load, AssignmentTooltip.load("assignment"))}>
  {assignment => (
    <div className="bs-assignment-tooltip">
      <GeneralTooltip data={ assignment } />
      <AssignmentTooltip assignment={ assignment.assignment } />
    </div>
  )}
  </Data.One>
);

export default IssueTooltip;