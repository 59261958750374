import React, { PureComponent } from 'react'
import Dropzone from 'react-dropzone'
import FontAwesome from 'react-fontawesome'
//import FontAwesome from 'react-fontawesome'
import _ from 'lodash'
import T from 'i18n-react'

/*TODO: REVIEW AND REFACTOR */

class DropzoneField extends PureComponent {
    constructor() {
        super()
        this.getUploadProgress = this.getUploadProgress.bind(this)
        this._upload = this._upload.bind(this);
    }

    getUploadProgress(callId) {
        const upload = _.find(this.props.uploads, {
            callId
        })
        if (upload)
            return `${upload.percent}%`
    }

    onDrop(pictures) {
        this.props.upload(this.pictures)
    }

    onChange() {
        return
    }
    _upload(files, errors){
      if(errors && errors.length){ 
        return this.props.upload(files, errors);
      }
      return Promise.all(files.map(file => new Promise((resolve, reject) => {
        var reader = new FileReader();
        reader.onload = (r) => {
          const buffer = new Uint8Array(r.srcElement.result); 
          file.preview = `data:${file.type};base64,${btoa(buffer.reduce((data, byte) => data + String.fromCharCode(byte), ""))}`;
          resolve(file);
        };
        reader.readAsArrayBuffer(file);
      }))).then(files => this.props.upload(files, errors));
    }

    render() {
        const {
            input,
            meta: { touched, error, warning },
            accept = 'image/gif,image/jpeg,image/png,image/jpg',
            uploaded,
            removeUpload = () => null,
            noProgressBar
        } = this.props
        
        return (
            <div>
                <div className='o-layout hide-for-quick-entry u-margin-top'>
                    {
                        uploaded.filter((upload) => upload.type !== 'application/pdf').map((upload, index) =>
                            <div className='o-layout__item u-3of12-tablet u-position-relative' key={ `${upload.tempId}_${index}` }>
                                <div className='img-polaroid'>
                                    <img src={ upload.preview } className='preview' draggable={ false }/>
                                    <a className='remove-upload-picture' onClick={ removeUpload.bind(null, upload) }>
                                        <FontAwesome name='times-circle' />
                                    </a>
                                </div>
                                {
                                    !noProgressBar &&
                                    <div className='progress'>
                                        <div className='bar orange' style={ { width: this.getUploadProgress(upload.tempId) } }/>
                                    </div>
                                }
                            </div>
                        )
                    }
                </div>
                <ul className='viewer unstyled sortable'>
                    {
                        uploaded.filter((upload) => upload.type === 'application/pdf').map((upload, index) =>
                            <li key={ index }>
                                <FontAwesome name='file-pdf-o' />&nbsp;
                                <span>{ upload.name }</span>
                                {
                                    !noProgressBar &&
                                        <div className='progress'>
                                            <div className='bar orange' style={ { width: this.getUploadProgress(upload.tempId) } }/>
                                        </div>
                                }
                            </li>
                        )
                    }
                </ul>
                <Dropzone { ...input } accept={ accept } onDrop={ this._upload } onChange={ this.onChange } className='dropzone' activeClassName='dropzone-active'>
                {({getRootProps, getInputProps}) => (
                    <div className='hide-for-quick-entry' { ...getRootProps() }>
                        <div className='row-fluid upload-pictures'>
                            <div className='text-center drop-box'>
                                <div className='aligner'>
                                    <input type="file" { ...getInputProps() } style={{ display: "none" }} />
                                    <div className='aligner-item'>
                                        <FontAwesome name='camera' size='3x' style={ { verticalAlign: 'middle', marginRight: '20px' } }/>
                                        <FontAwesome name='file-pdf-o' size='3x' style={ { verticalAlign: 'middle' } }/>
                                        <br />
                                        <span style={ { fontSize: '14px' } }>
                                            { T.translate('drag_your_file') }
                                        </span>
                                        <br />
                                        <span>
                                            { T.translate('or') }
                                        </span>
                                        <br />
                                        <a className='button-a'>
                                            <FontAwesome name='camera' style={ { marginRight: '8px' } }/>
                                            { T.translate('add_pictures') }
                                        </a>
                                    </div>
                                </div>
                                { touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>)) }
                            </div>
                        </div>
                    </div>
                )}
                </Dropzone>
            </div>
        )
    }
}

export default DropzoneField