import React from 'react'
import moment from 'moment'
import classnames from 'classnames'

const Date = ({ date, mode, displayHour = false, format = 'DD/MM/YYYY', }) => (
    <span className={ classnames('c-date', { [`c-date--${mode}`]: mode }) }>
        <span className='c-date__date'>{moment(date).format(format)}</span>
        { displayHour && <span className='c-date__hour'>{moment(date).format('HH:mm')}</span>}
    </span>
)

export default Date
