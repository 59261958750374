import RequestsActions from 'client/core/RequestsActions'
import { action } from 'client/core/actions'

export const IssuesRequest = new RequestsActions('CALENDAR_ISSUES')

export const CALENDAR_SET_PERIOD = 'CALENDAR_SET_PERIOD'
export const setPeriod = () => action(CALENDAR_SET_PERIOD)

export const CALENDAR_SET_MODE = 'CALENDAR_SET_MODE'
export const setMode = (payload) => action(CALENDAR_SET_MODE, { payload })

export const CALENDAR_SET_DATE = 'CALENDAR_SET_DATE'
export const setDate = (payload) => action(CALENDAR_SET_DATE, { payload })

export const CALENDAR_NAV_DATE = 'CALENDAR_NAV_DATE'
export const navDate = (payload) => action(CALENDAR_NAV_DATE, { payload })

export const CALENDAR_DROP = 'CALENDAR_DROP'
export const drop = (payload) => action(CALENDAR_DROP, { payload })

export const CALENDAR_UPDATE_DAYS = 'CALENDAR_UPDATE_DAYS'
export const updateDays = (payload) => action(CALENDAR_UPDATE_DAYS, { payload })

export const CALENDAR_REMOVE_LAST_DROPPED = 'CALENDAR_REMOVE_LAST_DROPPED'
export const removeLastDropped = () => action(CALENDAR_REMOVE_LAST_DROPPED)

/*Filters*/
export const SET_CALENDAR_FILTERS_VALUE = 'SET_CALENDAR_FILTERS_VALUE'
export const setCalendarFiltersValue = (payload) =>
  action(SET_CALENDAR_FILTERS_VALUE, { payload })


export const UNSET_CALENDAR_FILTER = 'UNSET_CALENDAR_FILTER'
export const unsetCalendarFilter = (payload) =>
  action(UNSET_CALENDAR_FILTER, { payload })

export const SET_CALENDAR_USERS_FILTERS_VALUE = 'SET_CALENDAR_USERS_FILTERS_VALUE'
export const setCalendarUsersFiltersValue = (payload) =>
  action(SET_CALENDAR_USERS_FILTERS_VALUE, { payload })

export const UPDATE_TIMELINE        = "UPDATE_TIMELINE";
export const INIT_CALENDAR          = "INIT_CALENDAR";