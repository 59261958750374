import React from "react";

import "./areaTitle.css";

const AreaTitle = ({ children }) => (
  <div className="bs-areaTitle">
    { children }
  </div>
);

export default AreaTitle;