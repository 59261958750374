import React, { useMemo }                  from 'react'; 
import Data                                                     from "@uBehaviour/data";
import { Link, Redirect, Route, Switch, useLocation, useRoute } from '@cFeatures/router';
import Item                                                     from "@entities/issue/item";
import IssueFilters                                             from "@entities/issue/filters";
import FilteredList                                             from "@cFeatures/filter/list";
import Application                                              from "@uBehaviour/application";
import Search                                                   from "@entities/assignmentsAndIssues/search";
import Input                                                    from "@cComponents/input";
import T                                                        from "@uBehaviour/i18n";
import Filter                                                   from "@cComponents/filter";
import md5                                                      from "md5";
import Selectable                                               from "@cComponents/selectable";
import Display                                                  from "@uComponents/displayIf";
import DropDown                                                 from "@cComponents/dropDown";
import UrlBuilder                                               from "@uLib/urlBuilder";
import Map                                                      from "@cComponents/map2";
import State                                                    from "@entities/issue/state";
import Modal                                                    from "@cComponents/modal";
import ScheduledForm                                            from "@entities/issue/scheduledForm";
import ManagerForm                                              from "@entities/issue/managerForm";
import DeadlineForm                                             from "@entities/issue/deadlineForm";
import IssueTooltip                                             from "@entities/issue/map/tooltip";
import Tooltip                                                  from '@cComponents/tooltip';
import Nudge                                                    from '@cComponents/nudge';
import PriorityItem                                             from "@entities/priority/item";
import Acl                                                      from "@uBehaviour/acl";
import Query                                                    from '@uLib/query';
import { IssuePrintBuilder }                                    from "@root/lib/printBuilder";
import { IssueDetail }                                          from './detail';
import Highlight                                                from "@common/components/highlight";
import ListHeaderLayout                                         from '@cFeatures/layout/listHeader';
import Searchbar                                                from "@common/components/searchbar";
import { deepReplace }                                          from "@universal/lib/objectTool";

import "./list.css";
import useAcl from '@universal/behaviour/hooks/useAcl';
const sort = [
  { label: "issue_list_sort_createdAt", value: { 'createdAt': -1 }},
  { label: "issue_list_sort_updatedAt", value: { 'updatedAt': -1 }},
  { label: "issue_list_sort_deadline", value: { 'deadline': -1 }},
];

const Counter = ({ api, querying }) => (
  <Filter.Subject>
  {composeQuery => {
    const query = composeQuery(querying());
    return (
      <Data.Count model="Issue" query={query}>
      {nbrIssues => (
        <div className="bs-issue-list-counter"><span><T bind={{ nbrIssues }}>issue_list_counter</T></span></div>
      )}
      </Data.Count>
    )
  }}
  </Filter.Subject>
);

const Sorter = ({ value, onChange}) => (
  <Input.Radio inline value={ value } onChange={ onChange }>
  {
    sort.map(s => (
      <Input.Radio.Value value={ s.value }>
      {(selected) => (<div className="bs-issue-list-sorter-item">{ selected ? (<b><T>{s.label}</T></b>) : (<T>{s.label}</T>)}</div> )}
      </Input.Radio.Value>
    ))
  }
  </Input.Radio>
);

const DisplayTypeItem = ({ icon, children, selected }) => (
  <span className={`bs-issue-list-displayType-item${selected ? " selected" : ""}`}>
    <span className={`fa ${icon}`} />
    <span><T>{ children }</T></span>
  </span>
);

const SelectDisplayType = ({ value, onChange }) => (
  <Input.Radio inline value={ value } onChange={ onChange } noFrame>
      <Input.Radio.Value value="default"><DisplayTypeItem icon="fa-list-ul">issue_list_displayType_standart</DisplayTypeItem></Input.Radio.Value>
      <Input.Radio.Value value="compact"><DisplayTypeItem icon="fa-list">issue_list_displayType_compact</DisplayTypeItem></Input.Radio.Value>
      <Input.Radio.Value value="map"><DisplayTypeItem icon="fa-map-marker">issue_list_displayType_map</DisplayTypeItem></Input.Radio.Value>
  </Input.Radio>
);

const SelectableMode = ({ value, onChange }) => (
  <Input.Checkbox inline value={ value ? ["checked"] : [] } onChange={(value) => onChange(!!value.length)} noFrame>
    <Input.Checkbox.Value value="checked">
      {(selected) => (
        <div className={`bs-issue-list-selectableMode${selected ? " bs-issue-list-selectableMode-active": ""}`}>
          <span className="fa fa-check-square-o" />
        </div>
      )}
    </Input.Checkbox.Value>
  </Input.Checkbox>  
);

const PrintExportAction = ({
  printExport: { printList, printMultipleWorkorderByPage, printOneWorkorderByPage, exportIssue, exportValorization, exportAssignment, exportKML }
}) => {

  const canExportIssues = useAcl("issues", "exportCsv");
  const canExportValorizations = useAcl("valorizations", "exportCsv");
  const canExportAssignments = useAcl("assignments", "exportCsv");
  const canExportKML = useAcl("issues", "exportKml");

  const issueExportItem = useMemo(() => {
    return canExportIssues ? (
      <div onClick={exportIssue} key="exportIssue">
        <T>issue_list_printExport_exportIssue</T>
      </div>
    ) : null;
  }, [canExportIssues, exportIssue]);

  const valorizationExportItem = useMemo(() => {
    return canExportValorizations ? (
      <div onClick={exportValorization} key="exportValorization">
        <T>issue_list_printExport_exportValorization</T>
      </div>
    ) : null;
  }, [canExportValorizations, exportValorization]);

  const assignmentExportItem = useMemo(() => {
    return canExportAssignments ? (
      <div onClick={exportAssignment} key="exportAssignment">
        <T>issue_list_printExport_exportAssignment</T>
      </div>
    ) : null;
  }, [canExportAssignments, exportAssignment]);

  const kmlExportItem = useMemo(() => {
    return canExportKML ? (
      <div onClick={exportKML} key="exportKML">
        <T>issue_list_printExport_exportKML</T>
      </div>
    ) : null;
  }, [canExportKML, exportKML]);

  return (
    <DropDown.List open={"click"} alignRight>
      <DropDown.List.Main>
        <div className="bs-issue-selector-button">
          <T>issue_list_printExport</T>
        </div>
      </DropDown.List.Main>
      <DropDown.List.List>
        <div onClick={printList}>
          <T>issue_list_printExport_printList</T>
        </div>
        <div onClick={printMultipleWorkorderByPage}>
          <T>issue_list_printExport_printMultipleWorkorderByPage</T>
        </div>
        <div onClick={printOneWorkorderByPage}>
          <T>issue_list_printExport_printOneWorkorderByPage</T>
        </div>
        {issueExportItem}
        {valorizationExportItem}
        {assignmentExportItem}
        {kmlExportItem}
      </DropDown.List.List>
    </DropDown.List>
  );
};



const SelectableBarAction = ({ icon, onClick, children }) => (
  <span onClick={ onClick } className="bs-issue-list-selectableBar-action">
    <span className={`fa fa-${icon}`} />
    <span>{ children }</span>
  </span>
);

const MultipleStateChange = ({ onStateSelected }) => (
  <DropDown open='click' alignRight noMaxWidth>
    <DropDown.Menu>
    {() => (
      <SelectableBarAction icon="flag">
        <T>issue_list_selectableBar_state</T>
      </SelectableBarAction>
    )}
    </DropDown.Menu>
    <DropDown.Content>
      <State.List onSelect={ onStateSelected } />
    </DropDown.Content>
  </DropDown>
);

const PrioritySelector = ({ onPrioritySelected }) => (
  <DropDown.List open={ "click" } alignRight>
    <DropDown.List.Main>
      <div className="bs-issue-selector-button">
        <span className="fa fa-exclamation-triangle" />
        <span className="bs-priority-selector-button-text"><T>priority_selector_priority_title</T></span>
      </div>
    </DropDown.List.Main>
    <DropDown.List.List>
      <div onClick={ () => onPrioritySelected(1) }><PriorityItem type="normal" /></div>
      <div onClick={ () => onPrioritySelected(3) }><PriorityItem type="moyen" /></div>
      <div onClick={ () => onPrioritySelected(5) }><PriorityItem type="urgent" /></div>
    </DropDown.List.List>
  </DropDown.List>
);


const MultipleScheduled = ({ close, submit }) => (
  <Modal.Show close={ close } style={{ width: "60vw" }}>
  {close => (
    <ScheduledForm close={ close } submit={ submit } />
  )}
  </Modal.Show>
)

const MultipleDeadline = ({ close, submit }) => (
  <Modal.Show close={ close } style={{ width: "60vw" }}>
  {close => (
    <DeadlineForm close={ close } submit={ submit } />
  )}
  </Modal.Show>
)

const MultipleManager = ({ close, submit }) => (
  <Modal.Show close={ close } style={{ width: "60vw" }}>
  {close => (
    <ManagerForm close={ close } submit={ submit } />
  )}
  </Modal.Show>
)

const SelectableBar = ({ selected, stopSelect, selectAll, unselectAll, printExport, bulkUpdate, assignBulk }) => {
  const [displayFormPlane, setDisplayFormPlane] = React.useState(false);
  const [displayFormDeadline, setDisplayFormDeadline] = React.useState(false);
  const [displayFormManager, setDisplayFormManager] = React.useState(false);
  const openFormPlane = React.useCallback(() => setDisplayFormPlane(true), setDisplayFormPlane);
  const closeFormPlane = React.useCallback(() => setDisplayFormPlane(false), setDisplayFormPlane);

  return (
    <div className="bs-issue-list-selectableBar">
      <div>
        <span className="fa fa-times bs-clickable" onClick={ stopSelect }/>
        &nbsp;
        <span className="bs-clickable" onClick={ selectAll }><T>issue_list_selectableBar_all</T></span>
        <span>/</span>
        <span className="bs-clickable" onClick={ unselectAll }><T>issue_list_selectableBar_any</T></span>
        &nbsp;
        <span><T bind={{ nbrSelected: selected.length }}>issue_list_selectableBar_selected</T></span>
      </div>
      <div className="bs-issue-list-selectableBar-actions">
        <MultipleStateChange onStateSelected={(state) => bulkUpdate({ state })}/>
        <PrioritySelector onPrioritySelected={(priority) => bulkUpdate({ priority })} />
        <SelectableBarAction icon="calendar" onClick={ openFormPlane }><T>issue_list_selectableBar_plane</T></SelectableBarAction>
        <Display.If condition={ displayFormPlane }>
          <MultipleScheduled close={closeFormPlane} submit={ assignBulk }/>
        </Display.If>
        <SelectableBarAction icon="calendar-times-o" onClick={ () => setDisplayFormDeadline(true) }><T>issue_list_selectableBar_deadLine</T></SelectableBarAction>
        <Display.If condition={ displayFormDeadline }>
          <MultipleDeadline close={() => setDisplayFormDeadline(false)} submit={(deadline) => bulkUpdate({ deadline })}/>
        </Display.If>
        <SelectableBarAction icon="user" onClick={ () => setDisplayFormManager(true) }><T>issue_list_selectableBar_manager</T></SelectableBarAction>
        <Display.If condition={ displayFormManager }>
          <MultipleManager close={() => setDisplayFormManager(false)} submit={(manager) => bulkUpdate({ manager })}/>
        </Display.If>
        <PrintExportAction printExport={ printExport } />
      </div>
    </div>
);
};

const IssueTooltipMap = ({ id, onReady }) => (
  <Data.One model="Issue" id={id} load={{ category: 1, files: 1, "location.building": 1 }} onLoad={ onReady }>
    {issue => <IssueTooltip issue={ issue } /> }
  </Data.One>
);

const MapList = Application.Service.forward(['api'], ({ api, history, query }) => {
  const hash  = md5(JSON.stringify(query));
  const [, setLocation] = useLocation();
  const route = useRoute("/");
  return (
    <Data.Async async={() => api.service("issues", "light").execute(query)} hash={hash}>
    {(results) => (
      <Map cluster={ true }>
      {
        results.map(issue => (
          <Map.GeoJSON position={ issue.position } key={ issue._id } className={ `fa fa-map-marker bs-issue-mapMarker bs-${ issue.state }` } onClick={ () => {
            setLocation(`${route}${ issue._id }`);
          }}>
            <IssueTooltipMap id={ issue._id } />
          </Map.GeoJSON>
      ))}
      </Map>
    )}
    </Data.Async>
  );
});

class IssueList extends React.Component {
  constructor(props){
    super(props);
    this._dataList = React.createRef();
    this._filter   = React.createRef();
    this.state = {
      sort: sort[0].value,
      displayType: "default",
      selectableModeActive: false,
      selected: []
    };
  }
  query = () => {
    const { currentTenant } = this.props;
    return currentTenant.useInQuery(null, true);
  }
  _textSearchBuildQuery(value) {
    return { description: { '$regex': value, '$options': 'i' } };
  }
  _defaultFilter() {
    return [];
  }
  _defaultFilters = () => {
    return [
      { 
        name: this.props.i18n.translate("issue_filter_default"), 
        values: [],
        _id: "see_all_filters"
      },
      { 
        name: this.props.i18n.translate("issue_filter_state_pending"),
        values: ["open", "in_progress", "planned", "transferred"].map(state => ({ name: "state", value: state, excludes: [] })),
        _id: "see_pending_issues"
      },
      {
        name: this.props.i18n.translate("issue_filter_state_finished"),
        values: ["resolved", "merged", "rejected", "deleted"].map(state => ({ name: "state", value: state, excludes: [] })),
        _id: "see_finished_issues"
      },
    ]
  };
  _onSortChange = (sort) => {
    this.setState({ sort });
  }
  _onDisplayTypeChange = (displayType) => {
    this.setState({ displayType, selectableModeActive: this.state.selectableModeActive && displayType !== "map" });
  }
  _closeSelectableMode = () => {
    this._onSelectableModeChange(false);
  }
  _onSelectableModeChange = (selectableModeActive) => {
    this.setState({ selectableModeActive, selected: [] });
  }
  _onSelectableClick = (value) => {
    const selected = this.state.selected.slice();
    const idx = selected.indexOf(value);
    if(idx === -1){
      selected.push(value);
    }else{
      selected.splice(idx, 1);
    }
    this.setState({ selected });
  }
  _selectAll = () => {
    const selected = [];
    this._dataList.current.pager.forEachLoaded(issue => {
      selected.push(issue._id);
    });
    this.setState({ selected });
  }
  _unselectAll = () => {
    this.setState({ selected: [] });
  }
  _onListDefChange = () => {
    this._unselectAll();
  }

  _assignBulk = (assignment) => {
    return this.props.api.service("issues", "assignBulk").execute(
      this.state.selected,
      assignment
    );
  }

  _bulkUpdate = (datas) => {
    return this.props.api.service("issues", "bulkUpdate").execute(
      this.state.selected,
      datas
    ).then(({ state }) => {
      if (state && state.result !== "successfull") {
        state.errors.forEach(error => {
          const bsIds = (<>{ error.issues.map(issue => (<><b>{ issue.bsId }</b>&nbsp;</>)) }</>);
          switch(error.code){
            case 3503:
              this.props.message.send("error", this.props.i18n.translate(
                "issues_bulkUpdate_error_stateResolved_mustBeValorized",
                { bsIds }
              ));
            break;
            case 3504:
              this.props.message.send("error", this.props.i18n.translate(
                "issues_bulkUpdate_error_issueStateMerged",
                { bsIds }
              ));
            break;
          }
        })
      }
    });
  }

  _getPrintExportQuery(isAssignment = false){
    if(this.state.selectableModeActive && this.state.selected.length){
      return {
        [isAssignment ? "issue" : "_id"]: { $in: this.state.selected }
      };
    }else{
      return this._filter.current.compose(this.query());
    }
  }
  _getSort(){
    return this.state.sort;
  }

  _printList = () => {
    const url = IssuePrintBuilder.create()
      .query(JSON.stringify(this._getPrintExportQuery()))
      .sort(JSON.stringify(this._getSort()))
      .build();

    this.props.printHelper.stdPrint(url);
  }

  _printMultipleWorkorderByPage = () => {
    this.props.printHelper.workOrder(this._getPrintExportQuery(true), this._getSort());
  }

  _printOneWorkorderByPage = () => {
    this.props.printHelper.workOrder(this._getPrintExportQuery(true), this._getSort(), true);
  }

  _exportIssue = () => {
    const query = this._getPrintExportQuery();
    const sort  = this._getSort();
    this._download(
      UrlBuilder.create('/export/issues')
        .addParam("q", JSON.stringify(query))
        .addParam("sort", JSON.stringify(sort))
        .build()
    );
  }

  _exportValorization = () => {
    const query = this._getPrintExportQuery();
    const sort  = this._getSort();
    this._download(
      UrlBuilder.create('/export/valorizations')
        .addParam("q", JSON.stringify(query))
        .addParam("sort", JSON.stringify(sort))
        .build()
    );
  }

  _exportAssignment = () => {
    let query = Query.joinWithOptimizer(this._getPrintExportQuery(true), { tenant: this.props.currentTenant.currentId });
    query = deepReplace(query, "assignments", "assignment");
    const sort  = this._getSort();
    this._download(
      UrlBuilder.create('/export/assignments')
        .addParam("q", JSON.stringify(query))
        .addParam("sort", JSON.stringify(sort))
        .build()
    );
  }

  _exportKML = () => {
    const query = this._getPrintExportQuery();
    this._download(
      UrlBuilder.create('/export/issues/geoDatas')
        .addParam("format", "kml")
        .addParam("q", JSON.stringify(query))
        .build()
    );
  }

  _download = (url) => {
    return this.props.downloadService.download(url);
  }

  render() {
    const { api } = this.props;

    const printExport = {
      printList: this._printList,
      printMultipleWorkorderByPage: this._printMultipleWorkorderByPage,
      printOneWorkorderByPage: this._printOneWorkorderByPage,
      exportIssue: this._exportIssue,
      exportValorization: this._exportValorization,
      exportAssignment: this._exportAssignment,
      exportKML: this._exportKML,
    };

    const UsedItem = this.state.displayType === "default" ? Item.Standart : Item.Short;
    const fnItem = (data) => (
      <Link to={ `/${ data._id }`}>
        <UsedItem data={ data } noTooltip={ true }/>
      </Link>
    );
    return (
      <>
        <Highlight.Manager>
          <FilteredList default={this._defaultFilter()} defaultFilters={ this._defaultFilters() } type="issues" myFilterTitle={<T>issue_list_myFilter</T>} filterRef={ this._filter }>
            <FilteredList.Filter>
              <IssueFilters />
            </FilteredList.Filter>
            <FilteredList.Header>
              <div className="bs-issue-list-header">
                <div className="bs-issue-list-header-part-top">
                  <ListHeaderLayout>
                    <ListHeaderLayout.Left>
                      <SelectDisplayType value={ this.state.displayType } onChange={ this._onDisplayTypeChange } />
                    </ListHeaderLayout.Left>
                    <ListHeaderLayout.Center>
                      <Searchbar>
                        <Search issueIdPath="_id" assignmentsPath="assignments" fluid/>
                      </Searchbar>
                    </ListHeaderLayout.Center>
                  </ListHeaderLayout>
                </div>
                <div className="bs-issue-list-header-part-bottom">
                  <ListHeaderLayout>
                    <ListHeaderLayout.Left>
                      <div className="bs-issue-list-header-selectAndCounter">
                        <Acl.If resource="issues" action="assignBulk">
                          <Display.If condition={ this.state.displayType !== "map" }>
                            <Tooltip placement='right-end'>
                              <Tooltip.Subject> 
                                <SelectableMode value={ this.state.selectableModeActive } onChange={ this._onSelectableModeChange }/>
                              </Tooltip.Subject>
                              <Tooltip.Info style={{ width: "55vw", backgroundColor: 'white' }} >
                                <Nudge>
                                  <T>issue_list_tooltip_info</T>
                                </Nudge>
                              </Tooltip.Info>
                            </Tooltip>
                          </Display.If>
                        </Acl.If>
                        <Counter api={api} querying={this.query} />
                      </div>
                    </ListHeaderLayout.Left>
                    <ListHeaderLayout.Center>
                      <Display.If condition={ this.state.displayType !== "map" }>
                        <div className="bs-issue-list-header-sorter">
                          <Sorter value={ this.state.sort } onChange={ this._onSortChange }/>
                        </div>
                      </Display.If>
                    </ListHeaderLayout.Center>
                    <ListHeaderLayout.Right>
                      <Display.If condition={ !this.state.selectableModeActive && this.state.displayType !== "map" }>
                        <div className="bs-issue-list-header-printer">
                          <PrintExportAction printExport={ printExport } />
                        </div>
                      </Display.If>
                    </ListHeaderLayout.Right>
                  </ListHeaderLayout>
                </div>
              </div>
            </FilteredList.Header>
            <FilteredList.SubHeader>
              <Display.If condition={ this.state.selectableModeActive }>
                <SelectableBar
                  selected={this.state.selected }
                  stopSelect={this._closeSelectableMode }
                  selectAll={this._selectAll }
                  unselectAll={this._unselectAll }
                  printExport={ printExport }
                  bulkUpdate={ this._bulkUpdate }
                  assignBulk={ this._assignBulk }
                />
              </Display.If>
            </FilteredList.SubHeader>
            <FilteredList.List scrollBar={ this.state.displayType !== "map" }>
            {composeQuery => (
              <Display.If condition={ this.state.displayType !== "map"}>
                <Display.Then>
                  <Data.List ref={ this._dataList } model={"Issue"} query={composeQuery(this.query())} load={ Item.Standart.load } sort={this.state.sort} onDefinitionChange={ this._onListDefChange }>
                    {(data) => (
                      <Display.If condition={ this.state.selectableModeActive }>
                        <Display.Then>
                          <Selectable onClick={(ev) => { ev.preventDefault(); this._onSelectableClick(data._id); }} selected={ this.state.selected.includes(data._id) }>
                            { fnItem(data) }
                          </Selectable>
                        </Display.Then>
                        <Display.Else>
                          { fnItem(data) }
                        </Display.Else>
                      </Display.If>
                    )}
                  </Data.List>
                </Display.Then>
                <Display.Else>
                {() => {
                  const query = composeQuery(this.query());
                  return (
                    <MapList query={ query } />
                  )
                }}
                </Display.Else>
              </Display.If>
            )}
            </FilteredList.List>
          </FilteredList>
        </Highlight.Manager>
        <Switch>
          <Route path="/list" component={ RedirectTo } hasJunction={ false } />
          <Route path="/:id" component={ IssueDetail } />
        </Switch>
      </>
    )
  }
}

const RedirectTo = () => {
  return <Redirect to="" />;
}

export default Application.forward(
  ["currentTenant", "api", "message", "i18n", ["download", "downloadService"]],
  [["print", "printHelper"]],
  IssueList
);
