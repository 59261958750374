import { Helper } from "../lib/application";
import ApiService from '../services/api';
import Valorization from '../types/business/Valorization';

interface ApiServiceType<P extends unknown[], R> {
  execute: (...args: P) => R;
};

type ApiStoreValorization = ApiServiceType<[Valorization], Promise<Valorization>>;
type ApiDeleteValorization = ApiServiceType<[Valorization], Promise<boolean>>;

class ValorizationHelper extends Helper {
  constructor(){
    super('valorizations', ['api']);
  }

  createOrUpdate(valorization: Valorization): Promise<Valorization>{
    const api: ApiService = this.application.getService('api');

    let service: ApiStoreValorization|null = null;

    if(valorization._id && !valorization.assignment){
      service = api.service('valorizations', 'updateOnIssue') as ApiStoreValorization;
    } else if(valorization._id && valorization.assignment){
      service = api.service('valorizations', 'updateOnAssignment') as ApiStoreValorization;
    } else if(!valorization.assignment){
      service = api.service('valorizations', 'createOnIssue') as ApiStoreValorization;
    } else{
      service = api.service('valorizations', 'createOnAssignment') as ApiStoreValorization;
    }

    return service.execute(valorization);
  }

  delete(valorization: Valorization) : Promise<boolean>{
    const api: ApiService = this.application.getService('api');

    let service: ApiDeleteValorization|null = null;

    if(!valorization.assignment){
      service = api.service('valorizations', 'deleteOnIssue') as ApiDeleteValorization;
    } else {
      service = api.service('valorizations', 'deleteOnAssignment') as ApiDeleteValorization;
    }

    return service.execute(valorization);
  }
}

export default ValorizationHelper;