import React, { PureComponent } from 'react'
import T from 'i18n-react'
import classNames from 'classnames'
import { Modal } from 'react-bootstrap'
import FontAwesome from 'react-fontawesome'
import Search from 'client/features/issues/components/filters/Search.jsx'
import IssueListItem from 'client/features/issues/components/lists/Item.jsx'
import { 
    IssuesAutocompleteRequest,
    IssueRequest
} from 'client/features/issues/actions'
import { getIssueFromAllByBsId } from 'client/features/issues/lib'
import { connect } from 'react-redux'

import { getIssueFromAll } from 'client/features/issues/lib'

class IssueLinkModal extends PureComponent {
    constructor() {
        super()
        this.lastSearchChange = null
        this.state = {
            toLink: null
        }
    }
    link = () => {
      const issueToLink = getIssueFromAllByBsId(this.props.all, this.state.toLink)
      const payload = {
          master: this.props.issue._id,
          slave: issueToLink._id
      }
      if (this.props.issue && this.props.issue.group)
          payload.group = this.props.issue.group._id

      this.updateIssue('link', { payload })
      this.props.hide();
    }

    updateIssue = (type, data = {}) => {
      if (!type)
        throw new Error('Update issue: missing type')
      const req = {
        ...data,
        params: {
          id: this.props.issueId || this.state.issueId,
          type
        },
        actionData: {
          type
        }
      }
      this.props.dispatch(IssueRequest.put.action(req))
    }

    searchChange = (value) => {
        const now = new Date().getTime()
        this.lastSearchChange = now
        setTimeout(() => {
            if (this.lastSearchChange === now)
                this.props.dispatch(IssuesAutocompleteRequest.get.action({
                    payload: { value: value.replace('#', '____hash____tag'), select: ['bsId'], exclude: { _id: [this.props.issue._id] } }
                }))
        }, 140)
    }

    onSearchSelected = (item) => {
        this.props.dispatch(IssueRequest.get.action({ params: { id: item.value },
            actionData: {
                noRedirect: true
            } }))
        this.setState({ toLink: item.value })
    }

    resetSearch() {
        
    }

    render() {
        const { show, hide, issue, user } = this.props
        const toLink = getIssueFromAllByBsId(this.props.all, this.state.toLink)
        const isOnSamegroup = toLink && toLink.group && issue.group && toLink.group._id === issue.group._id
        const isOnOtherGroup = toLink && toLink.group
        
        return(
            <Modal show={ show } onHide={ hide } aria-labelledby='contained-modal-title-lg' bsSize="large" className='modal-helper'>
                <Modal.Header closeButton>
                    <Modal.Title>
                        { T.translate('link_issue') }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        <p>{ T.translate('link_search_issue_explanation') }</p>
                    }
                    {
                        <div className='c-issues-navbar__search'>
                            <Search
                                onChange={ this.searchChange }
                                items={ this.props.autocompleteItems }
                                onSelect={ this.onSearchSelected }
                                resetSearch={ this.resetSearch }
                                onEnterPress={ () => null }
                                placeholder={ 'search_by_bs_id' }
                            />
                        </div>
                    }
                    {
                        toLink &&
                        <div>
                            <div>
                                { T.translate('link_issue_explanation_1') } <strong>#{ toLink.bsIdNumber }</strong> { T.translate('with') } <strong>#{ issue.bsIdNumber }</strong>.
                            </div>
                            <IssueListItem
                                issue={ toLink }
                                goToIssue={ () => null }
                                showDistance={ false }
                                user={ user }
                                readOnly
                            />
                            {
                                isOnSamegroup && <p className='error'>{ T.translate('issues_link_already_in_same_group') }</p>
                            }
                            {
                                isOnOtherGroup && !isOnSamegroup &&
                                    <p className='warning'>
                                        <FontAwesome name='warning' />&nbsp;
                                        { T.translate('issues_link_already_in_other_group') }
                                    </p>
                            }
                        </div>
                    }`
                </Modal.Body>
                <Modal.Footer>
                    <button className='button-a' onClick={ hide }>
                        { T.translate('cancel') }
                    </button>
                    <button
                        className={ classNames('button-a', !toLink || isOnSamegroup ? 'clear' : 'orange') }
                        onClick={ this.link }
                        disabled={ !toLink || isOnSamegroup }
                    >
                        { T.translate('link') }
                    </button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default connect((state, props) => {
  const { issues, session } = state
  let issue = props.issue || getIssueFromAll(issues.all, issues.current)
  if(issues.lastAssignment && issue?.assignments){
    issue = JSON.parse(JSON.stringify(issue));
    issue.assignments = issue.assignments.filter(a => a._id === issues.lastAssignment);
  }
  return {
    all: issues.all,
    issue,
    issueId: props.issue ? props.issue._id : issues.current,
    autocompleteItems: issues.autocomplete,
    user: session.user
  };
  
})(IssueLinkModal);