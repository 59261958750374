interface DeferOjbect<T> {
  promise: Promise<T>;
  resolve: (data: T) => void;
  reject: (error?: unknown) => void;
}

function defer <T = unknown>(): DeferOjbect<T> {
  const p: Partial<DeferOjbect<T>> = {};
  p.promise = new Promise((resolve, reject) => {
    p.resolve = resolve;
    p.reject  = reject; 
  });
  return p as DeferOjbect<T>;
}

export default defer;