import { Helper } from "@uLib/application";
import _ from "lodash";

export default class MapLayerHelper extends Helper {
  constructor(){
    super("mapLayer");
  }
  submit(value){
    if(value._id){
      return this.application.getService("api").service("mapLayers", "put").execute(value._id, value);
    }else{
      return this.application.getService("repository").get("MapLayer").repository.create(value)
    }
  }
  remove(id) {
    return this.application.getService("api").service("mapLayers", "delete").execute(id)
  }
}