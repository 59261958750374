import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import toDic        from "@cLib/toDic";
import Application  from '@uBehaviour/application';
import Team         from "@entities/team";

export default Application.Service.forward(["api", "currentTenant", "repository"], props => (
  <Filter.Generic 
    deshydrate={value => value._id}
    hydrate={values => props.api.service("teams", "get").execute({_id: {$in: values }}, null, null, values.length).then(teams => toDic(values, v => v, (v) => teams.find(t => t._id === v)))}
    multiple
    name="teams"
    buildQuery={values => {
      const agents = values.reduce((acc, { members }) => { 
        members.forEach(agent => acc.push(agent._id)); 
        return acc;
      }, []);
      
      return (
        { $or: [
          {"assignments.team": { $in: values.map(v => v._id) }},
          {"assignments.agents": { $in: agents } } 
      ]}
    )
    }}
    stringify={value => { return (<><T>issue_filter_teams</T> : {value.name}</>)}}
  >
    {(values, add, drop, clear) => {
      let query = { tenant: props.currentTenant.currentId };
      if(values.length){
        query = { $and: [query, { _id: { $nin: values.map(v => v._id) }}]};
      }
      return (
        <Collapsable>
          <Collapsable.Title><T>issue_filter_teams</T></Collapsable.Title>
          <Collapsable.Content>
            <Input.Selectable 
              value={ values.map(v => props.repository.get("Team").key.extract(v))}
              onChange={(vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }}
              model={ props.repository.get("Team") }
              load={{ members: 1, avatar: true }}
              query={ query }
              sort={{ name: 1 }}
              textify={ team => team.name }
              filterQuery={ value => ({ name: { '$regex': value, '$options': 'i' } }) }
            >
              <Team.Item />
            </Input.Selectable>
          </Collapsable.Content>
        </Collapsable>
      )}}
  </Filter.Generic>
));