import React, { Component } from 'react'
import BsString from 'client/lib/BsString'
import randomKey from 'random-key'
import _ from 'lodash'
import m from 'match-string'

/*TODO: refactor + es6 */

/*TODO: Put in consts */
const REGEXPS = {
  hashTag: '#([a-z\d]{2,})',
  url: '(https?:\/\/(?:www\.|(?!www))[^ \.]+\.[^ ]{2,}|www\.[^ ]+\.[a-zA-Z0-9]( |$))'
}

class PartialHTML extends Component {
  constructor(props) {
    super()
    this.state = {
      content: props.forceMatch || props.content
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ content: this.props.forceMatch || this.props.content })
  }

  render() {
    const self = this
    let content = []
    if (!_.isString(this.state.content))
      return (<span></span>)

    if (this.state.content)
      content = this.state.content.split('\n')

    if (!this.props.highlight)
      return <span>{ this.props.content }</span>

    const lines = []
    const highlight = this.props.highlight
    const regEx = new RegExp(`${REGEXPS.url }|${ REGEXPS.hashTag }|(    )`, 'ig')
    let highlightRegEx
    if (highlight)
      highlightRegEx = m(highlight)//BsString.escapeSpecialCharacter(highlight, self.props.multiple)
    content.map((line, index) => {
      let match
      let found = false
      const subLines = []
      let lastIndex = 0
      if (!self.props.noUrl) {
        while (match = regEx.exec(line)) {
          found = true
          const previousText = line.substring(lastIndex, match.index)
          const matched = match[0]
          let htmlElement
          const highlighted = setHighLight(matched)

          if (matched.match(/\b(https?:\/\/[^\s\(\)\'\"\<\>]+)/i)) {
            htmlElement =
              <a rel='nofollow' target='_blank' href={ matched.trim() } key={ `${index}-${randomKey.generate(32)}` }>
                { highlighted }
              </a>
          } else if (matched.match(new RegExp(REGEXPS.hashTag))) {
            if (self.props.setSearch) {
              htmlElement =
                <a onClick={ self.props.setSearch.bind(self, { hashTag: matched.replace('#', '') }) } className='hash-tag' key={ `${index}-${randomKey.generate(32)}` }>
                  { highlighted }
                </a>
            } else {
              htmlElement =
                <span className='hash-tag' key={ `${index}-${randomKey.generate(32)}` }>
                  { highlighted }
                </span>
            }
          } else {
            htmlElement =
              <a rel='nofollow' target='_blank' href={ `http://${ matched.trim()}` } key={ `${index}-${randomKey.generate(32)}` }>
                { highlighted }
              </a>
          }
          subLines.push(setHighLight(previousText))
          subLines.push(htmlElement)
          lastIndex = match.index + matched.length
        }
      }
      if (!found) {
        if (self.props.forceMatch)
          line = this.state.content
        if (self.props.inline)
          lines.push(<span key={ randomKey.generate(32) }>{setHighLight(line)}</span>)
        else
          lines.push(<p key={ randomKey.generate(32) }>{setHighLight(line)}</p>)
      } else {
        subLines.push(setHighLight(line.substring(lastIndex)))
        lines.push({ subLines })
      }
    })

    function setHighLight(string) {
      if (!string) return

      if (!highlightRegEx)
        return <span key={ randomKey.generate(32) }>{string}</span>

      let lastIndex = 0
      let htmlElements = []
      let match
      let isMatch = false
      for (let i = 0; i < highlightRegEx.length; i++) {
        while (match = highlightRegEx[i].exec(string)) {
          isMatch = true
          const previousText = string.substring(lastIndex, match.index)
          if (previousText)
            htmlElements.push(<span key={ `${i}-${randomKey.generate(32)}` }>{ string.substring(lastIndex, match.index) }</span>)
          const matched = match[0]
          const htmlElement = <span className='highlighted' key={ `${i}-${randomKey.generate(32)}` }>{ matched }</span>
          htmlElements.push(htmlElement)
          lastIndex = match.index + matched.length
        }
      }

      const endOfString = string.substring(lastIndex)
      if (endOfString)
        htmlElements.push(<span key={ randomKey.generate(32) }>{ endOfString }</span>)

      if (isMatch && self.props.forceMatch)
        htmlElements = [<span className='highlighted' key={ randomKey.generate(32) }>{ self.props.content }</span>]

      const result = htmlElements.map(function (span) {
        return span
      })
      return result
    }
    const linesNodes = lines.map(function (line, index) {
      if (line.subLines) {
        const LineElements = line.subLines.map(function (subLine, index) {
          return (
            <span key={ `${index}-${randomKey.generate(32)}` }>{subLine}</span>
          )
        })
        if (self.props.inline) {
          <span key={ `${index}-${randomKey.generate(32)}` }>
            {LineElements}
          </span>
        } else {
          return (
            <p key={ `${index}-${randomKey.generate(32)}` }>
              {LineElements}
            </p>
          )
        }
      } else {
        return (line)
      }
    })
    if (self.props.inline) {
      return (
        <span className={ this.props.className } key={ randomKey.generate(32) }>
          {linesNodes}
        </span>
      )
    }
    return (
      <div className={ this.props.className } key={ randomKey.generate(32) }>
        {linesNodes}
      </div>
    )

  }
}

export default PartialHTML