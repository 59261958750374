const APP = {
  resources: {
    types: ['commune', 'entity'],
    models: ['Commune', 'Entity']
  },
  users: {
    types: ['userPro', 'user'],
    models: ['UserPro', 'User']
  },
  teams: {
    types: ['internal', 'external']
  },
  locales: {
    acceptedCountries: ['be', 'fr', 'mq', 'gp', 'gf', 're', 'yt', 'lu', 'nc'],
    frenchCountries: ['fr', 'mq', 'gp', 'gf', 're', 'yt', 'nc'],
    languages: ['fr', 'nl', 'en'],
	  defaultLanguage: 'fr'
  },
  login: {
    providers: ['facebook', 'google', 'local'],
    registrationTypes: ['login', 'submitIssue', 'local', 'facebook', 'google']
  },
  patrimonies: {
    valorizationTypes: ['km', 'h']
  },
  userPros: {
    profiles: ['full', 'building', 'publicSpace', 'readOnly', 'teller', 'agent'],
    porfilesCodes: ['ag', 'bu', 'ps', 'ro', 'te', 'p', 'pa', 'sa'],
    identifier: ['email', 'nickname']
  },
  valorizations: {
    itemKinds: ['Team', 'Patrimony', 'UserPro', 'Supply', 'ExternalService']
  }
}

module.exports = Object.freeze(APP)