import React                  from 'react';
import _                      from "lodash";
import Form                   from '@cBehaviour/form';
import T                      from '@cBehaviour/i18n';
import Button                 from "@cComponents/button";
import Field                  from '@cComponents/old/field';
import FormError              from "@cComponents/formError";
import FieldGroup             from '@components/fieldGroup';
import Input                  from '@cComponents/old/input';
import FInput                 from '@components/input';
import Application            from "@uBehaviour/application";
import Filter                 from "@cComponents/filter";
import NewInput               from "@cComponents/input";
import Building               from "@entities/building";

import './form.css';

class EquipmentForm extends React.Component {

  _preload(val) {
    val.disabled = !val.disabled;
    val.locationType = !val.location ? null : val.location.building ? "building": "geolocation";
    if(val.locationType === "building"){
      val["location.building"] = val.location.building;
    }
    val.files = val.files ? val.files : [];

    return val;
  }

  _presubmit(val) {
    val.disabled = !val.disabled;
    if (!val.locationType) val.location = null;
    if(val["location.building"]){
      val.location = { 
        building: val["location.building"]
      }
      delete val["location.building"];
    }
    if (!val.valorization.cost) {
      val.valorization = null;
    }
    delete val.locationType;
    return val;
  }

  render() {  
    return (
      <Form.Model 
        model={ "Equipment" } 
        preload={ this._preload } 
        presubmit={ this._presubmit} 
        validate={this._validate}
        value={ this.props.value }
        id={ this.props.id } 
        ref={ this.props.forwardedRef }
        observer={ this.props.observer } 
        name={this.props.name}
      >
        {(equipment, errors) => (          
          <FormError errors={ errors }>
            <div className="bs-equipmentForm">
              <div className="bs-equipmentForm-picture">
                <FieldGroup title="files" type="full">
                  <Field>
                    <Field.Input>
                      <Input.File name="files" width={ 285 } height={285} fit="contain" />
                    </Field.Input>
                  </Field>
                </FieldGroup>  
              </div>
              <div className="bs-equipmentForm-content">
                <FieldGroup title="generals_informations" type="full">
                  <Field>
                    <Field.Label><T>name</T></Field.Label>
                    <Field.Input><Input.Text required={true} name="name" /></Field.Input>
                  </Field>
                  <Field>
                    <Field.Label><T>equipment_form_withLocation</T></Field.Label>
                    <Field.Input>
                      <Input.Radio.Group name="locationType">
                        <Input.Radio.Value value={ null }>{(selected) => (<Button.Radio pushed={selected}><T>equipment_form_withLocation_without</T></Button.Radio>)}</Input.Radio.Value>
                        <Input.Radio.Value value="geolocation">{(selected) => (<Button.Radio pushed={selected}><T>equipment_form_withLocation_geolocation</T></Button.Radio>)}</Input.Radio.Value>
                        <Input.Radio.Value value="building">{(selected) => (<Button.Radio pushed={selected}><T>equipment_form_withLocation_building</T></Button.Radio>)}</Input.Radio.Value>
                      </Input.Radio.Group>
                    </Field.Input>
                  </Field>
                  { equipment.locationType === "geolocation" 
                    ? <Field>
                        <Field.Input>
                          <Input.NewToOld name="location" required>
                            {(onChange, value) => (
                              <div style={{ height: "330px" }}>
                                <NewInput.AddressMap onChange={onChange} value={value} />
                              </div>
                            )}
                          </Input.NewToOld>
                        </Field.Input>
                      </Field>
                    : equipment.locationType === "building" 
                      ?<Field>
                      <Field.Input>
                        <Input.Selectable.One min={ 1 } name="location.building" model={"Building"} load={{ "tenant": true, files: true }} query={{ disabled: false, tenant: this.props.currentTenant.currentId }} in={_in => _in ? { _id: _in } : null} out={_out => _out ? _out._id : null}>
                          <Input.Selectable.One.Render textify={(building) => building.name } />
                          <Input.Selectable.One.Open>
                            {(open) => (<Button.Text onClick={open} ><T>select</T></Button.Text>)}
                          </Input.Selectable.One.Open>
                          <Input.Selectable.One.ListTitle>
                            <span><T>buildings</T></span>
                          </Input.Selectable.One.ListTitle>
                          <Input.Selectable.One.Filter>
                            <div className="bs-role-modal-filter">
                              <Filter.Generic name="text" buildQuery={value => ({ name: { '$regex': value, '$options': 'i' } })} stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
                                {(value, set, clear) => (
                                  <NewInput.Text value={value} onChange={_.debounce(value => { value ? set(value) : clear() }, 1000)}>
                                    <T>freesearch</T>
                                  </NewInput.Text>
                                )}
                              </Filter.Generic>
                            </div>
                          </Input.Selectable.One.Filter>
                          <Input.Selectable.One.Item>
                            <Building.Item />
                          </Input.Selectable.One.Item>
                        </Input.Selectable.One>
                      </Field.Input>
                    </Field>
                      : null
                  }        
                  <Field>
                    <Field.Label><T>active_m</T></Field.Label>
                    <Field.Input><FInput.YesNo name="disabled"/></Field.Input>
                  </Field>
                </FieldGroup>       
                <FieldGroup type="full" title="valorization_settings">
                  <Input.Object name="valorization">
                    <Field>
                      <Field.Label><T>measure_unit</T></Field.Label>
                      <Field.Input>
                        <Input.Radio.Group name="type" default="h">
                          <Input.Radio.Value value="km">{(selected) => (<Button.Radio pushed={selected}><T>kilometers</T></Button.Radio>)}</Input.Radio.Value>
                          <Input.Radio.Value value="h">{(selected) => (<Button.Radio pushed={selected}><T>hours</T></Button.Radio>)}</Input.Radio.Value>
                        </Input.Radio.Group>   
                      </Field.Input>
                    </Field>
                    <Field>
                      <Field.Label><T>measure_unit_cost</T></Field.Label>
                      <Field.Input><Input.Number name="cost" decimals={2} /></Field.Input>
                    </Field>               
                  </Input.Object>      
                </FieldGroup>
              </div>
            </div>
          </FormError>
        )}
      </Form.Model>
    )
  }
}
export default Application.forward(["currentTenant"], [], React.forwardRef((props, ref) => {
  return (<EquipmentForm { ...props } forwardedRef={ ref }/>)
}));