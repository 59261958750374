import StringBuilder from '@uLib/stringBuilder';
import User, { 
  RealPerson,
  UserTechnical,
  UserTechnicalApplication,
  UserBetterstreet,
  isUserBetterstreet,
  isUserTechnical,
  isRealPerson
} from '../../types/business/User';
import BsDate from '@universal/types/technic/Date';
import dayjs from 'dayjs';

const actionAtBy =(action: string, at: BsDate, by: string) => StringBuilder.create()
  .add(action + " ")
  .add(dayjs(at).fromNow())
  .add(" par ")
  .add(by)
  .build();



const realPersonShortFullname = (user: RealPerson) => StringBuilder.create()
  .addIfExist(user.firstname && user.firstname.length ? user.firstname.split(/[\s\-]/).map(f => f.toUpperCase()[0]).join("") : undefined, ". ")
  .add(user.lastname)
  .build();

const technicalUserShortFullname = (user: UserTechnical) => {
  switch(user.application){
    case UserTechnicalApplication.attractiveCity: return "AttractiveCity";
    case UserTechnicalApplication.gestionDeSalleJvs: return "Gestion de salle";
  }
};

const betterstreetShortFullname = (user: UserBetterstreet) => user.fullname;

export const shortFullname = (user: User) => {
  if(isRealPerson(user)) return realPersonShortFullname(user);
  if(isUserTechnical(user)) return technicalUserShortFullname(user);
  if(isUserBetterstreet(user)) return betterstreetShortFullname(user);
  return "Inconnu";
}



const realPersonLongFullname = (user: RealPerson) => StringBuilder.create()
  .addIfExist(user.firstname, " ")
  .add(user.lastname)
  .build();

const technicalUserLongFullname = (user: UserTechnical) => user.fullname;

const betterstreetLongFullname = (user: UserBetterstreet) => user.fullname;

export const longFullname = (user: User) => {
  if(isRealPerson(user)) return realPersonLongFullname(user);
  if(isUserTechnical(user)) return technicalUserLongFullname(user);
  if(isUserBetterstreet(user)) return betterstreetLongFullname(user);
  return "Inconnu";  
}  


const realPersonInitial = (user: RealPerson) => 
  user.firstname.split(" ").concat(user.lastname.split(" "))
    .map(part => part.substring(0, 1).toUpperCase())
    .join("");

const technicalUserInitial = (user: UserTechnical) => {
  switch(user.application){
    case UserTechnicalApplication.attractiveCity: return "AC";
    case UserTechnicalApplication.gestionDeSalleJvs: return "GDS";
  }
};

const betterstreetUserInitial = (user: UserBetterstreet) => "BS";

export const initial = (user: User) => {
  if(isRealPerson(user)) return realPersonInitial(user);
  if(isUserTechnical(user)) return technicalUserInitial(user);
  if(isUserBetterstreet(user)) return betterstreetUserInitial(user);
  return "JD"; //John Doe
}

export default {
    actionAtBy,
    shortFullname,
    longFullname,
    initial
};