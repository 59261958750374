// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-starterActivation-alreadyActivated {
  display: flex;
  height: 100%;
  flex-direction: column;
}

.bs-starterActivation-alreadyActivated-content {
  flex-grow: 1;
  flex-basis: 0;
}
.bs-starterActivation-alreadyActivated-content>*{
  margin-bottom: 60px;
}

.bs-starterActivation-alreadyActivated-footer {

}`, "",{"version":3,"sources":["webpack://./src/applications/starterActivation/views/alreadyActivated.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;AACA;EACE,mBAAmB;AACrB;;AAEA;;AAEA","sourcesContent":[".bs-starterActivation-alreadyActivated {\n  display: flex;\n  height: 100%;\n  flex-direction: column;\n}\n\n.bs-starterActivation-alreadyActivated-content {\n  flex-grow: 1;\n  flex-basis: 0;\n}\n.bs-starterActivation-alreadyActivated-content>*{\n  margin-bottom: 60px;\n}\n\n.bs-starterActivation-alreadyActivated-footer {\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
