import React from "react";

import "./dragAndDrop.css";
import classNames from "@uLib/classNames";

window.document.addEventListener("dragover", function( event ) {
  // Empêche le comportement par défaut afin d'autoriser le drop
  event.preventDefault();
}, false);

const Context = React.createContext(null);

class Manager extends React.Component{
  #dropAllowed    = false;
  #currentTarget  = null;
  #type           = null;
  #datas          = null;
  get isDragInProgress(){
    return !!this.#type;
  }
  isDropAllowed(component){
    return this.#dropAllowed && this.#currentTarget === component;
  }
  _validate(dragged, dropped){
    if(dropped.allowedTypes.indexOf(dragged.type) === -1){
      return false;
    }
    return true;
  }
  validate(allowedTypes, datas){
    const validate = this.props.validate ? this.props.validate : this._validate;

    return validate(
      { type: this.#type, datas: this.#datas },
      { allowedTypes, datas }
    );
  }
  emit(allowedTypes, datas){
    if(this.validate(allowedTypes, datas) && this.props.onDrop){
      this.props.onDrop(
        { type: this.#type, datas: this.#datas },
        { allowedTypes, datas }
      );
    }
  }
  setSubject = (type, datas) => {
    this.#type    = type;
    this.#datas   = datas;
    this.forceUpdate();
  }
  resetSubject(){
    this.#type          = null;
    this.#datas         = null;
    this.#dropAllowed   = false;
    if(this.#currentTarget){
      this.dragLeave(this.#currentTarget);
    }
    this.forceUpdate();
  }
  dragEnter(allowedTypes, datas, component){
    this.#dropAllowed   = this.validate(allowedTypes, datas);
    this.#currentTarget = component;
    component.forceUpdate();
  }
  dragLeave(component){
    this.#dropAllowed = false;
    if(this.#currentTarget === component){
      this.#currentTarget = null;
    }
    component.forceUpdate();
  }
  render(){
    const { children } = this.props;
    return (
      <Context.Provider value={ this }>
      { 
        children instanceof Function
          ? children(this)
          : children
      }
      </Context.Provider>
    )
  }
}
class Draggable extends React.Component {
  static contextType = Context;
  state = {
    isDragged: false
  }
  onDragStart = () => {
    const { type, datas } = this.props;
    this.context.setSubject(type, datas);
    this.setState({ isDragged: true });
  }
  onDragEnd = () => {
    this.context.resetSubject();
    this.setState({ isDragged: false });
  }
  render(){
    const { children, className } = this.props;

    if(!this.context){
      return children;
    }

    return (
      <div draggable="true" className={ "bs-dragAndDrop bs-dragAndDrop-draggable " + className} onDragStart={ this.onDragStart } onDragEnd={ this.onDragEnd }>
      { 
        children instanceof Function
          ? children(this.state.isDragged)
          : React.cloneElement(children, { isDragged: this.state.isDragged })
      }
      </div>
    );
  }
};

class Droppable extends React.Component{
  static contextType = Context;
  onDrop = () => {
    this.context.emit(this.props.allowedTypes, this.props.datas);
  }
  onDragEnter = () => {
    this.context.dragEnter(this.props.allowedTypes, this.props.datas, this);
  }
  onDragLeave = () => {
    this.context.dragLeave(this);
  }
  render(){
    const { children, className } = this.props;

    if(!this.context){
      return children;
    }

    return (
      <div className={ classNames("bs-dragAndDrop bs-dragAndDrop-droppable").addIf(className) } onDrop={ this.onDrop } onDragEnter={ this.onDragEnter } onDragLeave={ this.onDragLeave }>
        {
          children instanceof Function
            ? children(this.context.isDragInProgress, this.context.isDropAllowed(this))
            : React.cloneElement(children, { 
              isDragInProgress: this.context.isDragInProgress,
              isDropAllowed: this.context.isDropAllowed(this)
            })
        }
      </div>
    )
  }
};

export default {
  Draggable,
  Droppable,
  Manager
};
