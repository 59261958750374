import Team from "@uTypes/business/Team";
import React, { FunctionComponent } from "react";
import Subject from "./subject";
import Data from "@universal/behaviour/data";

interface TeamSubjectProps {
  team: Team;
  del?: null | (() => void);
};
const TeamSubject: FunctionComponent<TeamSubjectProps> = ({ team, del = null }) => (
  <Data.Synchronizer entity={ team }>
  {(team) => (
    <Subject icon="users" del={ del }>{ team.name }</Subject>
  )}
  </Data.Synchronizer>
);

export default TeamSubject;