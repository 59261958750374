
import React                  from 'react';
import Application            from "@uBehaviour/application";
import Log                    from "@entities/log";
import T                      from "@uBehaviour/i18n";

const BuildingLog = (props) => (
  <Log.View subject={ props.id } type="Category" createdAt={(category) => category.createdAt} createdBy={(category) => category.createdBy ? category.createdBy : { firstname:'', lastname:'Inconnu'}} text={(category) => props.i18n.translate(category.label)}>
    <Log.View.Rule 
      version={2}
      type={"label.fr"}
      title={() => (<T>category_logs_label_fr</T>)}
      textify={ data => data.label.fr }
    ></Log.View.Rule>
    <Log.View.Rule 
      version={2}
      type={"label.en"}
      title={() => (<T>category_logs_label_en</T>)}
      textify={ data => data.label.en }
    ></Log.View.Rule>
    <Log.View.Rule 
      version={2}
      type={"label.nl"}
      title={() => (<T>category_logs_label_nl</T>)}
      textify={ data => data.label.nl }
    ></Log.View.Rule>
    <Log.View.Rule 
      version={2}
      type={"disabled"}
      title={(_old, _new) => _new && _new.disabled ? (<T>category_logs_disabled</T>) : (<T>category_logs_enabled</T>)}
    />
    <Log.View.Rule
      version={2}
      type={"type"}
      title={() => (<T>category_logs_type</T>) }
      textify={ data => (<T>{ data.type }</T>) }
    />    
    <Log.View.Rule
      version={2}
      multiple={true}
      entity="User"
      type={"followers"}
      title={(els, add) => {
        return add ? (<T>category_logs_followers_add</T>) : (<T>category_logs_followers_drop</T>)
      }}
      textify={els => els.map(el => el.fullname).join(", ")}
    />
    <Log.View.Rule
      version={2}
      type={"redirectRule"}
      reject={ data => !data.redirectRule.discriminator }
      collect={(toLoad, data) => {
        if(data.redirectRule.manager){
          if(!toLoad["User"]){
            toLoad["User"] = {};
          }
          toLoad["User"][data.redirectRule.manager] = 1;
        }else if(data.redirectRule.category){
          if(!toLoad["Category"]){
            toLoad["Category"] = {};
          }          
          toLoad["Category"][data.redirectRule.category] = { tenant: 1 }
        }
      }}
      set={(entities, data) => {
        if(data.redirectRule.manager){
          if(!entities["User"]){
            return;
          }
          data.redirectRule.manager = entities["User"][data.redirectRule.manager];
        }else if(data.redirectRule.category){
          if(!entities["Category"]){
            return;
          }          
          data.redirectRule.category = entities["Category"][data.redirectRule.category];
        }
      }}      
      title={() => (<T>category_logs_redirectRule</T>) }
      textify={ (data, to) => {
        if(data.redirectRule.manager){
          return props.i18n.translate("category_logs_redirectRule_manager" + (to ? "_to" : "_from"), { manager: data.redirectRule.manager.fullname })
        }else if(data.redirectRule.category){
          return props.i18n.translate("category_logs_redirectRule_monotenant" + (to ? "_to" : "_from"), { 
            category: props.i18n.translate(data.redirectRule.category.label), 
            tenant: props.i18n.translate(data.redirectRule.category.tenant.name)
          });
        }else if(data.redirectRule.discriminator === "multiTenant"){
          return props.i18n.translate("category_logs_redirectRule_multitenant" + (to ? "_to" : "_from"));
        }
      }}
    /> 
  </Log.View>
);

export default Application.forward(["i18n"], [], BuildingLog);