import React             from "react";
import FocusBlur         from "@cComponents/focusBlur";
import Display           from "@uComponents/displayIf";
import DayPicker         from 'react-day-picker/DayPicker';
import MomentLocaleUtils from 'react-day-picker/moment';
import moment            from "moment";
import T                 from "@uBehaviour/i18n";

import "./selectDate.css";

const SelectDate = ({ children, back, forward, current, onDateSelected }) => {
  const [displayCalendar, setDisplayCalendar] = React.useState(false);
  return (
    <div className="bs-planning-selectDate-container">
      <div className="bs-planning-selectDate">
        <div onClick={ back }><span className="fa fa-arrow-left"/></div>
        <div onClick={() => setDisplayCalendar(!displayCalendar) }>{ children }</div>
        <div onClick={ forward }><span className="fa fa-arrow-right"/></div>
      </div>
      <Display.If condition={ displayCalendar }>
        <FocusBlur onBlur={() => setDisplayCalendar(false) }>
          <div className="bs-planning-selectDate-picker">
            <DayPicker 
              localeUtils={ MomentLocaleUtils }
              locale={ "fr" }
              onDayClick={ onDateSelected }
              selectedDays={ moment(current).toDate() }
              month={ current ? moment(current).startOf("month").toDate() : null }
            />
          </div>
        </FocusBlur>
      </Display.If>
    </div>
  );
}

export const SelectMonth = ({ current, onDateSelected }) => {
  const back = React.useCallback(() => {
    onDateSelected(moment(current).subtract(1, "month").toDate());
  }, [current, onDateSelected]);
  const forward = React.useCallback(() => {
    onDateSelected(moment(current).add(1, "month").toDate());
  }, [current, onDateSelected]);
  return (
    <SelectDate back={ back } forward={ forward } current={ current } onDateSelected={ onDateSelected }>
      { (d => d[0].toUpperCase() + d.substring(1))(moment(current).format("MMMM")) }
    </SelectDate>
  );
}

export const SelectDay = ({ current, onDateSelected }) =>  {
  const back = React.useCallback(() => {
    onDateSelected(moment(current).subtract(1, "day").toDate());
  }, [current, onDateSelected]);
  const forward = React.useCallback(() => {
    onDateSelected(moment(current).add(1, "day").toDate());
  }, [current, onDateSelected]);
  return (
    <SelectDate back={ back } forward={ forward } current={ current } onDateSelected={ onDateSelected }>
      { moment(current).format("DD/MM/YYYY") }
    </SelectDate>
  );
}

export const SelectWeek = ({ current, onDateSelected }) => {
  const back = React.useCallback(() => {
    onDateSelected(moment(current).subtract(1, "week").toDate());
  }, [current, onDateSelected]);
  const forward = React.useCallback(() => {
    onDateSelected(moment(current).add(1, "week").toDate());
  }, [current, onDateSelected]);
  return (
    <SelectDate back={ back } forward={ forward } current={ current } onDateSelected={ onDateSelected }>
      <T bind={{ week: moment(current).format("W") }}>planning_selectDate_week</T>
    </SelectDate>
  );
}

const SelectDateFactory = ({ type, current, onDateSelected }) => {
  switch(type){
    case "day": return (<SelectDay current={ current } onDateSelected={ onDateSelected } />);
    case "week": return (<SelectWeek current={ current } onDateSelected={ onDateSelected } />);
    case "month": return (<SelectMonth current={ current } onDateSelected={ onDateSelected } />);
    case "default": throw new Error(`SelectDateFactory => unknow type: ${type}`);
  }
}

export default SelectDateFactory;