import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode
} from 'react';
import Slot from '@uComponents/slot2';

import "./addableList.css";

const Item = Slot<(value: object, index: number) => ReactNode>();

interface AddableListProps {
  values: object[];
  onAdd: () => void;
  allowAddition: boolean;
  onDelete: (index: number) => void;
  getKey: (index:number) => string;
} 

const AddableList: FunctionComponent<PropsWithChildren<AddableListProps>> & { Item: typeof Item } = ({ values, onAdd, allowAddition, onDelete, getKey, children }) => {
  const item = Item.get(children);
  return (
    <div>
      { values.map((value, index) => {
        const key = getKey(index); 
        return (
          <div className="bs-addableList-container" key={key}>
            <div data-testid="addableList_delete_btn" className='bs-addableList-container-delete' onClick={ () => onDelete(index) }>
              <span className='fa fa-times' />
            </div>
            {item(value, index)}
          </div>
        )
      })}
      <div data-testid="addableList_add_btn" onClick={ () => allowAddition ? onAdd() : null } className={`bs-addableList-add ${!allowAddition ? "bs-addableList-add-disabled" : ""}`}>
        <span className="fa fa-plus" />
      </div>
    </div>
  )
}

AddableList.Item = Item;

export default AddableList;

