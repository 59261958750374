import { makeStorageUrl } from '../lib/file'

class Media {
  static _getExtension(extension = 'png') {
    return extension[0] === '.' ? extension.substring(1) : extension;
  }

  static url(media, extension) {
    return makeStorageUrl(media, {
      format: this._getExtension(extension)
    });
  }

  static original(media, extension) {
    return this.url(media, extension)
  }

  static thumbnail(media, width = 320, height, extension = 'jpg') {
    if (!height) {
      height = width
    }     
    return makeStorageUrl(media, {
      width: width,
      height: height,
      fit: "inside",
      format: this._getExtension(extension)
    });  
  }

  static resize(media, width = 500, height) {
    if (!height) {
      height = width;
    }
    return makeStorageUrl(media, {
      width: width,
      height: height,
      format: "png"
    });
  }

  static avatar(media, width = 220, height = 220) {
    return makeStorageUrl(media, {
      width: width,
      height: height,
      fit: "inside",
      format: "png"
    });
  }
}




export default Media;