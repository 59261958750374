import React, { FunctionComponent } from 'react';
import _ from 'lodash';

import { TabRendererProps } from '../type';

import './default.css';
import Display from '@uComponents/displayIf';

const executeOrClone = (fn, props) => {
  if (fn instanceof Function) {
    return fn(props);
  }
  return React.cloneElement(fn, props);
}
const Renderer: FunctionComponent<TabRendererProps> = ({ tabs, tabListControl, add }) => {
  const [withButton, setWithButton] = React.useState(false);
  const container = React.useRef<HTMLDivElement>(null);
  const headers = React.useRef<HTMLDivElement>(null);
  const scroll = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const verifyHeadersSize = () => {
      if (headers.current && container.current) {
        setWithButton(headers.current.clientWidth > container.current.clientWidth);
      }
    };
    verifyHeadersSize();
    window.addEventListener('resize', verifyHeadersSize);
    return () => {
      window.removeEventListener('resize', verifyHeadersSize);
    }
  } , [headers, container, tabs.map(({ id }) => id).join(',')]);


  const scrollLeft = React.useCallback(() => {
    if(!scroll.current){
      return;
    }
    scroll.current.scroll(scroll.current.scrollLeft - 100, 0);
  }, [scroll]);

  const scrollRight = React.useCallback(() => {
    if(!scroll.current){
      return;
    }
    scroll.current.scroll(scroll.current.scrollLeft + 100, 0);
  }, [scroll]);

  return (
    <div className="bs-tab" ref={ container }>
      <div className="bs-tab-contents">
        { tabs.map(({ id, content, active }) => {
          const props = {
            id,
            active,
            tabListControl
          };
          return (
            <div key={id} className={`bs-tab-content ${active ? 'bs-tab-content-active' : ''}`}>
            { executeOrClone(content, props) }
            </div>
          );
        })}
      </div>
      <div className="bs-tab-headers-container">
        <div className="bs-tab-headers-scroll" ref={ scroll }>
          <div className="bs-tab-headers" ref={ headers }>
            { tabs.map(({ id, header, active, activate }) => {
              const props = {
                id,
                active,
                activate,
                tabListControl
              };
              return (
                <div key={id} className={`bs-tab-header ${active ? 'bs-tab-header-active' : ''}`} onClick={ activate }>
                { executeOrClone(header, props) }
                </div>
              );
            })}
            <div className="bs-tab-header-end">
              <Display.If condition={!!add}>
                <div className="bs-tab-header-add" onClick={add}>
                  <i className="fa fa-plus"></i>
                </div>
              </Display.If>
            </div>
          </div>
        </div>
        {withButton && (
          <div className="bs-tab-header-scroll-control">
            <div className="bs-tab-header-scroll-control-left" onClick={ scrollLeft }>
              <i className="fa fa-angle-left"></i>
            </div>
            <div className="bs-tab-header-scroll-control-right" onClick={ scrollRight }>
              <i className="fa fa-angle-right"></i>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Renderer