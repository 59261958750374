// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-edit-location .bs-locationEdit-header {
  background-color: #f5f5f5;
  font-size: 1.2em;
  padding:10px 15px;
  border-bottom: 1px solid #dddddd;
}

.bs-edit-location .bs-locationEdit-header .bs-subTitle {
  font-size:0.75em;
}

.bs-edit-location .bs-locationEdit-content {
  min-width: 90vw;
  margin: 10px;
}

.bs-edit-location .bs-locationEdit-content input {
  margin-bottom: 10px;
}

.bs-edit-location .bs-locationEdit-footer {
  background-color: #f5f5f5;
  padding:10px 15px;
  border-top: 1px solid #dddddd;
  display: flex;
  justify-content: flex-end;
}

`, "",{"version":3,"sources":["webpack://./src/components/input/map/location/edit.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,gBAAgB;EAChB,iBAAiB;EACjB,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,6BAA6B;EAC7B,aAAa;EACb,yBAAyB;AAC3B","sourcesContent":[".bs-edit-location .bs-locationEdit-header {\n  background-color: #f5f5f5;\n  font-size: 1.2em;\n  padding:10px 15px;\n  border-bottom: 1px solid #dddddd;\n}\n\n.bs-edit-location .bs-locationEdit-header .bs-subTitle {\n  font-size:0.75em;\n}\n\n.bs-edit-location .bs-locationEdit-content {\n  min-width: 90vw;\n  margin: 10px;\n}\n\n.bs-edit-location .bs-locationEdit-content input {\n  margin-bottom: 10px;\n}\n\n.bs-edit-location .bs-locationEdit-footer {\n  background-color: #f5f5f5;\n  padding:10px 15px;\n  border-top: 1px solid #dddddd;\n  display: flex;\n  justify-content: flex-end;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
