import React, { CSSProperties, FunctionComponent, PropsWithChildren } from 'react';
import Color from '@universal/types/technic/Color';

import './display.css';

interface StateDisplayProps {
  color: Color;
  type: string;
  width: number;
  height: number;
}

const StateDisplay: FunctionComponent<PropsWithChildren<StateDisplayProps>> = ({ type, color,  width, height, "data-testid": dataTestId, children }) => {
  const containerStyle = {
    '--state-display-width': `${width}px`,
    '--state-display-height': `${height}px`,
  } as CSSProperties;

  const commonStyle = {
    '--state-display-color': color
  } as CSSProperties;

  const triangleStyle = { 
    ...commonStyle,
    '--state-display-triangle-height': `${height}px`,
    '--state-display-triangle-width': `${width / 12}px`,
  } as CSSProperties;

  const bodyStyle = { 
    ...commonStyle,
  } as CSSProperties;

  return (
    <div className={ `bs-state-display bs-state-display-${type}` } style={ containerStyle } data-testid={ dataTestId }>
      <div style={ triangleStyle }/>
      <div style={ bodyStyle }>{ children }</div>
    </div>
  );
}

export default StateDisplay;