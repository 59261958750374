import React from "react";
import Display from "@uComponents/displayIf";
import Slot from "@uComponents/slot";
import FocusBlur from "@cComponents/focusBlur";
import Input from "@cComponents/input";

import "./selector.css";

const HiderDisplay = ({ visible, label, content }) => (
  <div className="bs-planning-hider">
    <div className="bs-planning-hider-label">
      { label }
    </div>
    <Display.If condition={ visible }>
      <div className="bs-planning-hider-content">
      { content }
      </div>
    </Display.If>
  </div>
);

const Hider = (props) => {
  const [visible, setVisible] = React.useState(false);
  const closeHandler          = React.useCallback(() => setVisible(false),  [setVisible]);
  const switchVisibleHandler  = React.useCallback(() => setVisible(visible => !visible), [setVisible]);

  const label   = Hider.Label.get({ props });
  const content = Hider.Content.get({ props });

  return (
    <FocusBlur onBlur={ closeHandler }>
      <HiderDisplay
        visible={ visible }
        label={
          <>{ label }<span className={ `fa fa-caret-${ visible ? "up" : "down"}`} onClick={ switchVisibleHandler } /></>
        }
        content={ content}
      />
    </FocusBlur>
  )
};

Hider.Label    = Slot();
Hider.Content  = Slot();

const Selector = ({ datas, selected, onSelect, dataToValue, dataToLabel, children }) => (
  <Hider>
    <Hider.Label>{ children }</Hider.Label>
    <Hider.Content>
      <div className="bs-planning-selector">
        <Input.Checkbox.Btn value={ selected } onChange={ onSelect } layout={(<Input.Checkbox.Layout.Table column={ 3 } />)}>
        {
          datas.map(data => {
            const value = dataToValue(data);
            return (<Input.Checkbox.Value key={ value } value={ value }><span>{ dataToLabel(data) }</span></Input.Checkbox.Value>);
          })
        }
        </Input.Checkbox.Btn>
      </div>
    </Hider.Content>
  </Hider>
);

export default Selector;