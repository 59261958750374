import React from 'react'
import T from 'i18n-react'
import PropTypes from 'prop-types'

import MentionsField from 'client/features/form/old/components/Mentions.jsx'
import Field from 'client/features/form/old/components/fields/Field.jsx'
import Textarea from 'react-textarea-autosize'
 

class IssueDetailsTextareaField extends Field {
  constructor(props) {
    super(props)
    this._input = React.createRef();
    this.onMentionChange = this.onMentionChange.bind(this)
    this.inputType = 'textarea';
    this.state = {
      ...this.state,
      value: props.value || props.defaultValue
    };
  }

  set value(value) {
    this.setState({value: value})
  }

  onMentionChange(mentionsValue) {
    this.value = mentionsValue;
    if (this.props.mentionsChange)
      this.props.mentionsChange(mentionsValue)
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.value = this.props.value;
    }
  }

  onFieldChange() {
    this.edit(false);
    this.props.onChange(this.state.value);
  }

  _renderField() {
    const { mentions, placeholder, defaultValue, noButtons, label, field = {}, maxHeight } = this.props;
    const error = this.getError();
    let errors = error ? [error] : [];
    if (this.props.validate) {
      errors = errors.concat(this.props.validate(this.state.value));
    }
    errors = this.props.meta && this.props.meta.error ? [this.props.meta.error] : errors;
    const style = { backgroundColor: 'white' }
    if (maxHeight)
      style.maxHeight = maxHeight

    return (
      <div className='c-field-textarea'>
        {
          label &&
          <label className='c-label u-padding-none u-font-size-small'>
            { label }
          </label>
        }
        {
          mentions ?
            <div className='c-field-mentions'>
              <MentionsField
                autoFocus
                mentions={ mentions }
                placeholder={ placeholder && T.translate(placeholder) }
                value={ this.state.value || defaultValue }
                onChange={ this.onMentionChange }
              />
            </div>
            :
            <Textarea
              autoFocus
              onChange={e => this.value = e.target.value}
              ref={ this._input }
              className='no-resize'
              style={ style }
              placeholder={ placeholder && T.translate(placeholder) }
              { ...field }
            >
              { this.state.value }
            </Textarea>
        }
        { ((this.props.meta && this.props.meta.touched) || errors) && (
          errors.map(e => (<p className='error'>{e}</p> ))
        )}
        {
          !noButtons &&
          <div className='u-margin-top-small u-text-align-right'>
            <button className='c-button u-small u-margin-right-tiny' onClick={ this.edit.bind(null, false) }>
              {
                T.translate('cancel')
              }
            </button>
            <button className='c-button c-button--brand u-small' onClick={ !errors?.length ? this.onFieldChange : null}>
              {
                T.translate('save_changes')
              }
            </button>
          </div>
        }
      </div>
    )
  }
}

IssueDetailsTextareaField.propTypes = {
  value: PropTypes.string,
  mentions: PropTypes.array
}

export default IssueDetailsTextareaField