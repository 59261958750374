// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-discover-container {
  height: 500px;
  padding: 0px 50px;
}

.bs-discover-step {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
}

.bs-discover-step-footer {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.bs-discover-fakeInput {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  border: 1px solid #00000044;
  border-radius: 9px;
  height: 35px;
  background-color: white;
  padding: 20px;
  color: var(--std-color-gray);
  cursor: default;
  white-space: nowrap;
}

.bs-discover-tenant-info {
  display: flex;
  gap: 25px;
  align-items: flex-end;
  justify-content: center;
  margin-bottom: var(--std-large-size-inter-element);
}
.bs-discover-tenant-info > * {
  flex-basis: 50%;
}
.bs-discover-finalize {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bs-discover-finalize-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--std-size-inter-element);
}
.bs-discover-finalize-content > img{
  margin: 25px;
}
.bs-discover-finalize-footer {
  margin: var(--std-large-size-inter-element) auto;
}
`, "",{"version":3,"sources":["webpack://./src/applications/unauthenticated/views/discover/discover.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,2BAA2B;EAC3B,kBAAkB;EAClB,YAAY;EACZ,uBAAuB;EACvB,aAAa;EACb,4BAA4B;EAC5B,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,qBAAqB;EACrB,uBAAuB;EACvB,kDAAkD;AACpD;AACA;EACE,eAAe;AACjB;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kCAAkC;AACpC;AACA;EACE,YAAY;AACd;AACA;EACE,gDAAgD;AAClD","sourcesContent":[".bs-discover-container {\n  height: 500px;\n  padding: 0px 50px;\n}\n\n.bs-discover-step {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  flex-grow: 1;\n}\n\n.bs-discover-step-footer {\n  display: flex;\n  flex-direction: column;\n  gap: 25px;\n}\n\n.bs-discover-fakeInput {\n  display: flex;\n  align-items: center;\n  justify-content: space-between; \n  border: 1px solid #00000044;\n  border-radius: 9px;\n  height: 35px;\n  background-color: white;\n  padding: 20px;\n  color: var(--std-color-gray);\n  cursor: default;\n  white-space: nowrap;\n}\n\n.bs-discover-tenant-info {\n  display: flex;\n  gap: 25px;\n  align-items: flex-end;\n  justify-content: center;\n  margin-bottom: var(--std-large-size-inter-element);\n}\n.bs-discover-tenant-info > * {\n  flex-basis: 50%;\n}\n.bs-discover-finalize {\n  flex-grow: 1;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n\n.bs-discover-finalize-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: var(--std-size-inter-element);\n}\n.bs-discover-finalize-content > img{\n  margin: 25px;\n}\n.bs-discover-finalize-footer {\n  margin: var(--std-large-size-inter-element) auto;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
