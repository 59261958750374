import React from 'react'
import T from 'i18n-react'
import { Modal } from 'react-bootstrap'

const IssueUngroupModal = ({ show, onHide, onEnter, unlink, issue = {}, group = {} }) => (
    <Modal show={ show } onHide={ onHide } onEnter={ onEnter } aria-labelledby='contained-modal-title-lg' bsSize="large" className='modal-helper'>
        <Modal.Header closeButton>
            <Modal.Title>
                { T.translate('unlink') }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                issue &&
                    <div>
                        <div>
                            { T.translate('unlink_confirmation_1') } <strong>{ issue.bsId }</strong>? { /*{ T.translate('of_the_group') } <strong>{ group.name }</strong> */}
                        </div>
                        <div className='u-margin-top-tiny'>
                            { T.translate('this_issue_is_linked_to') }:
                            <ul className='u-margin-top-small'>
                                {
                                    group.issues.filter((groupIssue) => issue.bsId !== groupIssue.bsId).map((groupIssue, index) =>
                                        <li key={ index }> { groupIssue.bsId }</li>
                                    )
                                } 
                            </ul>
                        </div>
                    </div>
            }
        </Modal.Body>
        <Modal.Footer>
            <button className='button-a' onClick={ onHide }>
                { T.translate('cancel') }
            </button>
            <button className='button-a orange' onClick={ unlink }>
                { T.translate('unlink') }
            </button>
        </Modal.Footer>
    </Modal>
)

export default IssueUngroupModal