// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-filters-bulk{
  /* margin-bottom: 24px;; */
}

.bs-filters-bulk-title{
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.bs-filters-bulk-title-text{
  flex-grow: 2;
  font-size: 16px;
  font-weight: bold;
}

.bs-filters-bulk-title > .fa{
  color: #aaaaaa;
  cursor: pointer;
}

.bs-filters-bulk-content{
  display: flex;
  flex-wrap: wrap;
}
.bs-filters-bulk-content-item{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2px;
  margin: 2px;
  background-color: rgba(255,255,255, 0.2);
  overflow: hidden;
  white-space: nowrap;
}
.bs-filters-bulk-content-item>*{
  overflow: hidden;
  text-overflow: ellipsis;
}
.bs-filters-bulk-content-item-drop {
  flex-shrink: 0;
  margin: 0 2px;
  color: #ccc;
}
.bs-filters-bulk-content-item-drop:hover{
  cursor: pointer;
  color: #eee;
}`, "",{"version":3,"sources":["webpack://./../common/components/filter/bulk.css"],"names":[],"mappings":";AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,eAAe;AACjB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,8BAA8B;EAC9B,YAAY;EACZ,WAAW;EACX,wCAAwC;EACxC,gBAAgB;EAChB,mBAAmB;AACrB;AACA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,cAAc;EACd,aAAa;EACb,WAAW;AACb;AACA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":["\n.bs-filters-bulk{\n  /* margin-bottom: 24px;; */\n}\n\n.bs-filters-bulk-title{\n  display: flex;\n  align-items: center;\n  margin-right: 10px;\n}\n\n.bs-filters-bulk-title-text{\n  flex-grow: 2;\n  font-size: 16px;\n  font-weight: bold;\n}\n\n.bs-filters-bulk-title > .fa{\n  color: #aaaaaa;\n  cursor: pointer;\n}\n\n.bs-filters-bulk-content{\n  display: flex;\n  flex-wrap: wrap;\n}\n.bs-filters-bulk-content-item{\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  padding: 2px;\n  margin: 2px;\n  background-color: rgba(255,255,255, 0.2);\n  overflow: hidden;\n  white-space: nowrap;\n}\n.bs-filters-bulk-content-item>*{\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.bs-filters-bulk-content-item-drop {\n  flex-shrink: 0;\n  margin: 0 2px;\n  color: #ccc;\n}\n.bs-filters-bulk-content-item-drop:hover{\n  cursor: pointer;\n  color: #eee;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
