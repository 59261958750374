import moment from "moment";
import _ 			from "lodash";

const escapeSpecialCharacter = function escapeSpecialCharacter (string) {
	if (!string)
		return "";

	let result = [];

	string = string.toLowerCase();

	let values = string.split(" ");
	values.forEach(function (value) {
		let a = "[aâàä]";
		let e = "[eéèêë]";
		let o = "[oôòøö]";
		let u = "[uùûü]";
		let i = "[iïî]";
		let c = "[cç]";
		for (let index = value.length; index >= 0; index--) {
			let letter = value[index];
			if (letter && a.indexOf(letter) !== -1) {
				value = replaceAt(value, index, a);
			} else if (letter && e.indexOf(letter) !== -1) {
				value = replaceAt(value, index, e);
			} else if (letter && o.indexOf(letter) !== -1) {
				value = replaceAt(value, index, o);
			} else if (letter && u.indexOf(letter) !== -1) {
				value = replaceAt(value, index, u);
			} else if (letter && i.indexOf(letter) !== -1) {
				value = replaceAt(value, index, i);
			} else if (letter && c.indexOf(letter) !== -1) {
				value = replaceAt(value, index, c);
			}
		}
		result.push(new RegExp(value, "ig"));
	});
	function replaceAt(string, index, replace) {
		return string.substring(0, index) + replace + string.substring(index + 1);
	}
	return result;
};
class QueryBuilder{
	static create(){
		return new QueryBuilder();
	}
	constructor(){
		this.query = {};
	}
	addFilter(key, value, condition){
		if(value instanceof Function && (condition === undefined || (Array.isArray(condition) && !condition[0]))){
			return this;
		}
		if(!(value instanceof Function)){
			if(value === undefined || (Array.isArray(value) && !value[0])){
				return this;
			}
			const tmpVal = value;
			value = (() => tmpVal);
		}
		const val = value();
		if(key && val !== undefined){
			this.query[key] = val;
		}
		return this;
	}
	addInFilter(key, value){
		if (value !== undefined && !Array.isArray(value)) value = [value];
		return this.addFilter(key, () => ({ $in: value }), value);
	}
	_getDate(value){
		if(value instanceof Date) return value;
		if(value instanceof moment) return value.toDate();
		return new Date(value);
	}
	addDateFilter(key, period){
		if(!period) return this;
		return this.addFilter(key, () => {
			const value = {};
			if(period.start !== undefined){
				value.$gte = this._getDate(period.start);
			}
			if(period.end !== undefined){
				value.$lte = this._getDate(period.end);
			}
			return value;
		}, period.start || period.end);
	}
	addPeriodFilter(keyStart, keyEnd, period){
		if(!period) return this;
		this.addFilter(keyStart, () =>({
			$lte: this._getDate(period.end)
		}), period.end);
		this.addFilter(keyEnd, () => ({
			$gt: this._getDate(period.start)
		}), period.start);
		return this;
	}
	or(filters){
		if(!this.query.$or){
			this.query.$or = [];
		}
		this.query.$or = this.query.$or.concat(filters);
	}
	and(filters){
		if(!this.query.$and){
			this.query.$and = [];
		}
		this.query.$and = this.query.$and.concat(filters);
	}
	build(){
		return this.query;
	}
};
class IssuesQueryBuilder{
	static build(query = {}){
		const builder = QueryBuilder.create()
			.addFilter("_id", Array.isArray(query.issues) ? { $in: query.issues } : query.issue)
			.addFilter("tenant", query.tenants)
			.addDateFilter("resolvedAt", query["dates.resolved"])
			.addDateFilter("deadline", query.deadline)
			.addDateFilter("createdAt", query.created)
			.addFilter("valorizations.elements", () => (query.valorizationsTotal ? { $exists: true } : { $exists: false }), query.valorizationsTotal)
			.addFilter("location.position", () => ({ $exists: query.haveLocation }), query.haveLocation)
			.addFilter("location.position", () => ({ $geoWithin: { $box: query.bounds }}), query.bounds)
			.addFilter("createdBy", () => ({ $in : query.creator }), query.creator)
			.addFilter("public", query.display)
			.addFilter("followers", () => query.followed ? query.follower : { $ne: query.follower }, query.followed)
			.addInFilter("state", query.state)
			.addInFilter("assignment.progress", query.workOrderState)
			.addInFilter("location.building", query.building)
			.addInFilter("location.place", query.places)
			.addInFilter("equipment", query.patrimony)
			.addInFilter("tags", query.hashtags)
			.addInFilter("priority", query.priority)
			.addInFilter("manager", query.owner)
			.addInFilter("location.address.zip", query.zip)
			.addInFilter("location.address.locality", query.locality)
			.addInFilter("category", query.category)

		if (query.transfer) {
			let transferTo = [];
			if (query.transfer.includes(null)) {
				transferTo.push({transferredTo:null})
			}
			transferTo.push({"transferredTo._id": {$in:query.transfer.filter(t => t)}})
			builder.or(transferTo);
		}

		if (query.teams && query.agents) {
			builder.or([
				{'assignment.team' : {$in:query.teams}},
				{'assignment.agents' : {$in:query.agents}},
			])
		} else if (query.teams) {
			if (query.teams[0] === null) {
				builder.and([
					{'assignment.team' : []},
					{'assignment.agents' : []}
				]);
			} else {
				builder.addInFilter("assignment.team", query.teams)
			}				
		} else if (query.agents) {
			builder.addInFilter("assignment.agents", query.agents)
		}
		
		if(query.type && query.type.length){
			let queryType = [];
			if(query.type.indexOf("publicSpace") !== -1){
				queryType.push({ equipment: null, "location.building": null});
			}
			if(query.type.indexOf("building") !== -1){
				queryType.push({ "location.building": { $ne: null }});
			}
			if(query.type.indexOf("patrimony") !== -1){
				queryType.push({ equipment: { $ne: null }});
			}
			builder.or(queryType);
		}

		if(query.onlyScheduled){
			builder.addFilter("assignment.scheduledFrom", null);
		}else{
			builder.addPeriodFilter("assignment.scheduledFrom", "assignment.scheduledTo", query.scheduledFrom);
		}

		if(query.search){
			builder
				.addFilter("requestor.firstName", () => query.search.requestor.firstName, query.search.requestor)
				.addFilter("requestor.lastName", () => query.search.requestor.lastName, query.search.requestor)
				.addFilter("createdBy", query.search.sender)
				.addFilter("type.building", query.search.building)
				.addFilter("location.address.street", query.search.street)
				.addFilter("location.road.code", query.search.road)
				.addFilter("planning.users", query.search.teamUser)
				.addFilter("hashtag", query.search.hashtag)
				.addFilter("planning.teams", query.search.team);
		}

		if(query.freeSearch){
			const valueStrict = new RegExp("^"+query.freeSearch+"$", "i");
			const regExpValues = escapeSpecialCharacter(query.freeSearch);
			builder.and({$or: [
				{ "requestor.firstName": 		{ $all: regExpValues }},
				{ "requestor.lastName": 		{ $all: regExpValues }},
				{ "location.road.code": 		{ $all: regExpValues }},
				{ "description": 				{ $all: regExpValues }},
				{ "bsId": 						valueStrict },
				{ "bsIdNumber": 				valueStrict},
				{ "location.address.street":	{ $all: regExpValues }}
			]});
		}

		return builder.build();
	}
};

const standarizeDate = (date, direction = 1) => {
	let start = 0;
	let end = 0;
	if (date === "custom") {
		return;
	} else if(date === "today"){
		return {
			start: moment().startOf("day").toDate().getTime(),
			end: moment().startOf("day").add(1, "days").toDate().getTime()
		};
	}else if(_.isInteger(date)){
		if(date === 86400000){
			start = 86400000;
			end = 86400000 * 2;
		}else {
			start = 0;
			end = date;
		}
	}else if(date.gte || date.lte){
		return {
			start: date.gte && new Date(parseInt(date.gte)),
			end: date.lte && new Date(parseInt(date.lte))
		};
	}else if(date.start || date.end){
		return {
			start: new Date(date.start),
			end: new Date(date.end)
		};
	} else if(Object.keys(date).length === 0) {
		return;
	} else {
		throw "Uncompatible date";
	}
	return {
		start: 	moment().add(direction > 0 ? start 	: end * -1, 	"milliseconds").startOf("day").toDate().getTime(),
		end: 		moment().add(direction > 0 ? end 		: start * -1, "milliseconds").endOf("day").toDate().getTime()
	};
};

const standarizeQuery = (query) => {
	//Suppression des champs obsolètes
	if(query.resolved && !query["dates.resolved"]){
		query["dates.resolved"] = query.resolved;
		delete query.resolved;
	}

	if(query.scheduled){
		if(!query.scheduledFrom){
			query.scheduledFrom = query.scheduled;
		}
		delete query.scheduled;
	}

	//Normalisation des requêtages sur date (problématique de construction des requêtes sur les dates doit être déplacé dans l'interface graphique)
	if(query["dates.resolved"] !== undefined){
		query["dates.resolved"] = standarizeDate(query["dates.resolved"], -1);
	}
	if(query.scheduledFrom !== undefined){
		query.scheduledFrom = standarizeDate(query.scheduledFrom);
	}
	if(query.deadline !== undefined){
		query.deadline = standarizeDate(query.deadline);
	}
	if(query.created !== undefined){
		query.created = standarizeDate(query.created, -1);
	}

	if(query.priority){
		query.priority = query.priority.map(p => {
			return p === "a_low" ? 1 : p === "j_middle" ? 3 : 5;
		})
	}

	//Normalisation des valeurs passés
	//boolean, une seule valeur possible
	if(Array.isArray(query.valorizationsTotal)){
		if(query.valorizationsTotal.length !== 1){
			delete query.valorizationsTotal;
		}else{
			query.valorizationsTotal = query.valorizationsTotal[0];
		}
	}
	if(query.archived){
		query.archived = true;
	}
	//Display => une issue est publique ou pas publique, il s'agit d'un booléen, donc display doit être positionné sur vrai ou faux
	//si les deux sont choisies, le filtres n'a plus de raison d'être
	if(query.display !== undefined){
		if(Array.isArray(query.display)){
			if(query.display.length === 1){
				query.display = query.display[0];
			}else{
				delete query.display;
			}
		}
		if(_.isString(query.display)){
			if(query.display === "private") query.display = false;
			else if(query.display === "public") query.display = true;
			else delete query.display;
		}
	}
	//internalFollowers migré sur followers
	//followed demande un booléen donc true or false (cf commentaire au dessus pour display)
	if(query.internalFollowers){
		query.followed = query.internalFollowers;
		delete query.internalFollowers;
	}
	if(query.followed){
		if(Array.isArray(query.followed)){
			if(query.followed.length === 1){
				query.followed = query.followed[0];
			}else{
				delete query.followed;
			}
		}
		if(_.isString(query.followed)){
			if(query.followed === "notFollowed") query.followed = false;
			else if(query.followed === "followed") query.followed = true;
			else delete query.followed;
		}
	}
	//Séparation du type de créateur et des ids de créateur
	if(query.creator){
		if(!Array.isArray(query.creator)){
			query.creator = [query.creator];
		}
	}
	return query;
};

export default (filters) => IssuesQueryBuilder.build(standarizeQuery(filters))