import { Assignment } from "../../../types/business/Issue";

const isEmptyAssignment = (assignment: Assignment): boolean => {
  return !assignment || (
    !assignment.agents.length 
    &&  !assignment.necessariesEquipments.length
    &&  !assignment.necessariesSupplies.length
    &&  assignment.progress === "to_do"
    &&  !assignment.scheduledFrom
    &&  !assignment.scheduledTo
    &&  !assignment.team.length
    &&  assignment.workInstructions === ""
  );
};

export default isEmptyAssignment;