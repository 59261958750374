import Field from '@common/components/field';
import Input from '@common/components/input';
import Form from '@universal/behaviour/form';
import { IndexedStatement } from '@universal/business/lib/valorizations/sortStatementsByType';
import { ServiceStatement } from '@universal/types/business/Valorization';
import React, { FunctionComponent } from 'react';
import { Grid } from 'semantic-ui-react';
import T from '@universal/behaviour/i18n';
import Display from '@universal/components/displayIf';
import Button from '@common/components/button';

import './externalServiceForm.css';
import FormSimple from '@universal/behaviour/form/simple';
import Scrollbar from '@common/components/scrollBar';

interface IndexedServiceStatementNullable {
  index: null|number;
  statement: ServiceStatement;
}
export type IndexedServiceStatement = IndexedStatement<ServiceStatement> | IndexedServiceStatementNullable;


interface ExternalServiceFormDisplayProps {
  isNew: boolean;
  del: () => void;
  form: FormSimple;
  cancel: () => void;
}
const ExternalServiceFormDisplay: FunctionComponent<ExternalServiceFormDisplayProps> = ({ isNew, form, cancel, del}) => {
  const submitForm = React.useCallback(() => {
    form.submit();
  }, [form]);
  const deleteStatement = React.useCallback(() => {
    del();
    cancel();
  }, [del, cancel])
  return (
    <Input.File.DropArea>
      <Input.File.DropArea.DisplayOnDrag>
      <div className="bs-valorization-externalServiceForm">
        <div className='bs-valorization-externalServiceForm-header'>
          <h4><T>valorization_form_externalService_title</T></h4>
        </div>
        <div className='bs-valorization-externalServiceForm-body'>
          <Scrollbar>
          <Field.ShortLabelContext prefix="valorization_form_externalService">
            <Grid>
              <Grid.Row>
                <Grid.Column width={ 8 }>
                  <Field.Short name="name">
                    <Input.Text />
                  </Field.Short>
                </Grid.Column>
                <Grid.Column width={ 8 }>
                  <Field.Short name="externalReference">
                    <Input.Text />
                  </Field.Short>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={ 8 } />
                <Grid.Column width={ 8 }>
                  <Field.Short name="cost">
                    <Input.Numeric step="0.01" numericType="decimal"/>
                  </Field.Short>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={ 16 }>
                  <Field.Short name="description">
                    <Input.TextArea />
                  </Field.Short>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={ 16 }>
                  <Form.Simple.InputAdapter name="files">
                  {(value, set, clear) => (
                    <Input.File value={ value } onChange={ set } inline/>
                  )}
                  </Form.Simple.InputAdapter>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Field.ShortLabelContext>
          </Scrollbar>
        </div>
        <div className='bs-valorization-externalServiceForm-footer'>
          <Grid>
            <Grid.Row>
              <Grid.Column width={ 12 }>
                <Display.If condition={ !isNew }>
                  <Button.Text onClick={ deleteStatement } color="rgb(160, 150, 150)">
                    <T>valorization_form_externalService_delete</T>
                  </Button.Text>
                </Display.If>
              </Grid.Column>
              <Grid.Column width={ 4 } className="bs-valorization-form-control">
                <Button.Text color="rgb(160, 150, 150)" onClick={ cancel }>
                  <T>valorization_form_externalService_cancel</T>
                </Button.Text>
                <Button.Text onClick={ submitForm }>
                  <T>valorization_form_externalService_valid</T>
                </Button.Text>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </div>
      </Input.File.DropArea.DisplayOnDrag>
    </Input.File.DropArea>
  );
};



interface ExternalServiceFormProps {
  indexedStatement: IndexedServiceStatement;
  del: () => void;
  onChange: (statement: IndexedServiceStatement) => void;
  cancel: () => void;
}
const formValidator = {
  schema: {
    name: { type: "string", required: true },
    cost: { type: "number", positive: true }
  }
}; 

const ExternalServiceForm: FunctionComponent<ExternalServiceFormProps> = ({ indexedStatement: { index, statement }, onChange, cancel, del }) => {

  const submitForm = React.useCallback((form: FormSimple, statement: ServiceStatement) => {
    const usedStatement = {
      ...statement,
      cost: statement.cost * 100
    };
    onChange({ index, statement: usedStatement });
    cancel();
  }, [index, onChange, cancel]);

  const usedStatement = React.useMemo(() => ({
    ...statement,
    cost: statement.cost / 100
  }), [statement]);

  return (
    <Form.Simple submit={ submitForm } value={ usedStatement } validator={formValidator}>
    {(ctx, value, errors, form) => (
      <ExternalServiceFormDisplay cancel={ cancel } form={ form } isNew={ !statement.name } del={ del } />
    )}
    </Form.Simple>
  );
}

export default ExternalServiceForm;
