import T from 'i18n-react'

export const updateStateSuccess = (issue = {}, log = {}) => ({
  type: 'success',
  title: `${T.translate('update_state_title_1')} ${issue.bsId} ${T.translate('update_state_title_2')}`,
  log: log.change && log.change.old && log.new ? `${log.change.old} > ${log.change.new}` : null,
  message: T.translate('update_state_explain')
})

export const badFilesAdded = (files = []) => ({
  type: 'error',
  title: T.translate('form_validation_accepted_validimage'),
  message: files.join(', ')
})

export const slaveIssueCreated = (issue) => ({
  type: 'success',
  title: `${T.translate('confirmation_issue_created_to_entity')}`,
  message: `${T.translate('confirmation_issue_created_to_entity_explanation')}`
})

export const issueCreated = (issue) => ({
  type: 'success',
  title: `${T.translate('confirmation_issue_created_by_agent')}`,
  message: issue.bsId
})

export const exportCSVRequested = () => ({
  type: 'info',
  title: T.translate('csv_export_in_progress'),
  message: T.translate('please_wait')
})