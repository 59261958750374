import Avatar           from '@cFeatures/user/avatar';
import Item             from './item';
import Form             from './form';
import Filters          from "./filters";
import CitizenItem      from './citizenItem';
import Details          from './details';
import Actions          from './actions';

export default {
  Avatar,
  Item,
  Form,
  Filters,
  CitizenItem,
  Details,
  Actions
}