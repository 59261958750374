import React, { FunctionComponent, PropsWithChildren } from 'react';

import './subject.css';

interface SubjectProps {
  icon: string;
  del?: null | (() => void);
  update?: null | (() => void);
};
const Subject: FunctionComponent<PropsWithChildren<SubjectProps>> = ({ icon, children, del = null, update = null }) => (
  <div className='bs-valorizations-subject'>
    <span className={ `fa fa-${icon}` } />
    <span>{ children }</span>
    {!!update && (
      <span onClick={ update } className="bs-valorizations-subject-update fa fa-pencil" />
    )}
    {!!del && (
      <span onClick={ del } className="bs-valorizations-subject-delete fa fa-times" />
    )}
  </div>
);

export default Subject;