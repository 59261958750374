// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-starter-header-bar{
  height: 48px;
  background: white;
  box-shadow: 0px 1px 8px 0px #c4c4c4;
}

.bs-starter-header-bar-left{
  display: flex;
  align-items: center;
  flex-shrink: 0;
  border-right: 1px solid #999999;
}
.bs-starter-header-bar-left>img{
  height: 42px;
  margin: 0 4px;
}

.bs-starter-header-bar-right{
  display: flex;
  align-items: center;
}
.bs-starter-header-bar-items{
  display: flex;
  align-items: center;
  flex-grow: 1;
  gap: 15px;
}
.bs-starter-header-bar-item {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 7px 15px;
  border-radius: 5px;
  color: var(--std-color-gray);

}
.bs-starter-header-bar-item>.fa {
  font-size: 18px;
}
.bs-starter-header-bar-item-locked{
  background-color: #f6f4f4;
  cursor: pointer;
}
.bs-starter-header-bar-item>.fa-lock {
  position: absolute;
  top: -3px;
  right: -3px;
  color: black;
  aspect-ratio: 1/1;
  background-color: #dadadaf4;
  border-radius: 50%;
  padding: 2px 4px;
  font-size: 14px;  
}

.bs-starter-header-bar-actions{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--std-size-inter-element);
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/applications/starter/header.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,iBAAiB;EACjB,mCAAmC;AACrC;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,cAAc;EACd,+BAA+B;AACjC;AACA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,SAAS;AACX;AACA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,mBAAmB;EACnB,QAAQ;EACR,iBAAiB;EACjB,kBAAkB;EAClB,4BAA4B;;AAE9B;AACA;EACE,eAAe;AACjB;AACA;EACE,yBAAyB;EACzB,eAAe;AACjB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,2BAA2B;EAC3B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,kCAAkC;EAClC,YAAY;AACd","sourcesContent":[".bs-starter-header-bar{\n  height: 48px;\n  background: white;\n  box-shadow: 0px 1px 8px 0px #c4c4c4;\n}\n\n.bs-starter-header-bar-left{\n  display: flex;\n  align-items: center;\n  flex-shrink: 0;\n  border-right: 1px solid #999999;\n}\n.bs-starter-header-bar-left>img{\n  height: 42px;\n  margin: 0 4px;\n}\n\n.bs-starter-header-bar-right{\n  display: flex;\n  align-items: center;\n}\n.bs-starter-header-bar-items{\n  display: flex;\n  align-items: center;\n  flex-grow: 1;\n  gap: 15px;\n}\n.bs-starter-header-bar-item {\n  user-select: none;\n  position: relative;\n  display: flex;\n  align-items: center;\n  gap: 5px;\n  padding: 7px 15px;\n  border-radius: 5px;\n  color: var(--std-color-gray);\n\n}\n.bs-starter-header-bar-item>.fa {\n  font-size: 18px;\n}\n.bs-starter-header-bar-item-locked{\n  background-color: #f6f4f4;\n  cursor: pointer;\n}\n.bs-starter-header-bar-item>.fa-lock {\n  position: absolute;\n  top: -3px;\n  right: -3px;\n  color: black;\n  aspect-ratio: 1/1;\n  background-color: #dadadaf4;\n  border-radius: 50%;\n  padding: 2px 4px;\n  font-size: 14px;  \n}\n\n.bs-starter-header-bar-actions{\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n  gap: var(--std-size-inter-element);\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
