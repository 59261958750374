import React from 'react'
import I18n from 'i18n-react'
import { Modal } from 'react-bootstrap';
import Nudge from '@cComponents/nudge';
import T from '@uBehaviour/i18n';
import "./PrintModal.css"

const IssuePrintModal = ({ show, onHide, onEnter, print }) => (
    <Modal show={ show } onHide={ onHide } onEnter={ onEnter } aria-labelledby='contained-modal-title-lg'  bsSize="large" className='modal-helper'>
        <Modal.Header closeButton>
            <Modal.Title>
                { I18n.translate('print_work_order') }
            </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <Nudge type="info"><T>assignment_print_modal_pdf_info</T></Nudge>
            <div className='row-fluid bs-assignment-print-modal-body-line'>
              <div className='offset2 span4'>
                <a
                  className='button-a orange print-work-order clickable multi-lines'
                  onClick={ () => print() }
                >
                  { I18n.translate('print_work_order_without_pdf') }
                </a>
              </div>
              <div className='span4'>
                <a
                  className='button-a orange print-work-order clickable multi-lines'
                  onClick={ () => print(true) }
                >
                  { I18n.translate('print_work_order_with_pdf') }
                </a>
              </div>
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
            <button className='button-a' onClick={ onHide }>
                { I18n.translate('cancel') }
            </button>
        </Modal.Footer>
    </Modal>
)

export default IssuePrintModal