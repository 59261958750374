import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";

import './state.css';

interface StatesFilterProps {
  states: Array<string>
}
const StatesFilter: React.FunctionComponent<StatesFilterProps> = ({ states }) => (
  <div className='bs-filter-state'>
    <Filter.Generic 
      multiple
      name="state"
      buildQuery={values => ({ state: { $in: values } })}
      stringify={value => (<T>{value}</T>)}
    >
    {(values, add, drop, clear) => (
      <Collapsable alwaysOpen>
        <Collapsable.Title><span className='bs-filter-state-title'><T>issue_filter_state</T></span></Collapsable.Title>
        <Collapsable.Content>
          <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
            {
              states.map(state => (
                <Input.Checkbox.Value key={ state } value={ state }>
                  <span>
                    <span className={`bs-filter-icon fa fa-stop bs-bg-${state}`} />
                    <span className='bs-filter-state-name'><T>{ state }</T></span>
                  </span>
                </Input.Checkbox.Value>
              ))
            }
          </Input.Checkbox.BtnFilter>
        </Collapsable.Content>
      </Collapsable>
    )}
    </Filter.Generic>
  </div>
  
);

export default StatesFilter;