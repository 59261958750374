import getValorizationsTotals from '@universal/business/lib/valorizations/getValorizationTotal';
import Display from '@universal/components/displayIf';
import Valorization from '@universal/types/business/Valorization';
import React, { FunctionComponent } from 'react';

import './summary.css';
import T from '@universal/behaviour/i18n';
import Money from '@cComponents/money';
interface BarProps {
  type: string;
  value: number;
  total: number;
}
const Bar: FunctionComponent<BarProps> = ({  type, value, total }) => (
  <Display.If condition={ value }>
    <div className='bs-valorization-summary-bar'>
      <div className={ `bs-valorization-summary-bar-indicator bs-valorization-summary-${ type }`}  style={{
        '--bs-valorization-summary-bar-width': `${ value / total * 50 }%`
      }}/>
      <div><T>{ `valorization_summary_${type}` }</T></div>
      <div><b><Money value={ value } /></b></div>
    </div>
  </Display.If>
);

interface SummaryProps {
  valorizations: Valorization[];
}
const Summary: FunctionComponent<SummaryProps> = ({ valorizations }) => {
  const totals = React.useMemo(() => getValorizationsTotals(valorizations), [valorizations]);

  return (
    <div className='bs-valorization-summary'>
      <Bar type="agentTeam" value={ totals.teamOrAgentsTotal } total={ totals.total } />
      <Bar type="equipment" value={ totals.equipmentsTotal } total={ totals.total } />
      <Bar type="supply" value={ totals.suppliesTotal } total={ totals.total } />
      <Bar type="service" value={ totals.externalServicesTotal } total={ totals.total } />
      <div>
        <b>
          <span><T>valorization_summary_total</T></span>
          &nbsp;
          <span>:</span>
          &nbsp;
          <Money value={ totals.total } />
        </b>
      </div>
    </div>
  )
}

export default Summary;