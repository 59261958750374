// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.bs-business-container{
  display: flex;
  height: 100%;
}
.bs-business-container-list{
  flex-grow: 2;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.bs-business-container-list-header{
  border-bottom: 1px solid #cecbc8;
  padding: 12px 24px;
}
.bs-business-container-list-content{
  flex-grow: 1;
  overflow: hidden;
}
.bs-business-container-list-content .bs-scrollbar-viewport{
  padding: 0px 32px;
}

.bs-business-container-filters{
  width: 20%;
  background: #786577;
  color: white;
  display: flex;
  flex-direction: column;
  flex-flow: column-reverse;
  justify-content: flex-end;
  overflow: hidden;
  height: 100%;
  flex-shrink: 0;
}
.bs-business-container-filters-header{
  max-height: 35%;
  padding: 12px 0;
  border-bottom: 1px solid var(--std-color-gray);
  margin-bottom: 12px;
}
.bs-business-container-filters-container{
  position: relative;
  overflow: hidden;
}
.bs-business-container-filters-container>div{
  height: 100%;
}
.bs-business-container-filters-content,
.bs-business-container-filters-header-viewport{
  padding-left:   24px;
  padding-right:  24px;
}
`, "",{"version":3,"sources":["webpack://./../common/features/filter/list.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,YAAY;AACd;AACA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,gBAAgB;AAClB;AACA;EACE,gCAAgC;EAChC,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;EACV,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,yBAAyB;EACzB,yBAAyB;EACzB,gBAAgB;EAChB,YAAY;EACZ,cAAc;AAChB;AACA;EACE,eAAe;EACf,eAAe;EACf,8CAA8C;EAC9C,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,YAAY;AACd;AACA;;EAEE,oBAAoB;EACpB,oBAAoB;AACtB","sourcesContent":["\n\n.bs-business-container{\n  display: flex;\n  height: 100%;\n}\n.bs-business-container-list{\n  flex-grow: 2;\n  height: 100%;\n  position: relative;\n  display: flex;\n  flex-direction: column;\n  overflow: hidden;\n}\n.bs-business-container-list-header{\n  border-bottom: 1px solid #cecbc8;\n  padding: 12px 24px;\n}\n.bs-business-container-list-content{\n  flex-grow: 1;\n  overflow: hidden;\n}\n.bs-business-container-list-content .bs-scrollbar-viewport{\n  padding: 0px 32px;\n}\n\n.bs-business-container-filters{\n  width: 20%;\n  background: #786577;\n  color: white;\n  display: flex;\n  flex-direction: column;\n  flex-flow: column-reverse;\n  justify-content: flex-end;\n  overflow: hidden;\n  height: 100%;\n  flex-shrink: 0;\n}\n.bs-business-container-filters-header{\n  max-height: 35%;\n  padding: 12px 0;\n  border-bottom: 1px solid var(--std-color-gray);\n  margin-bottom: 12px;\n}\n.bs-business-container-filters-container{\n  position: relative;\n  overflow: hidden;\n}\n.bs-business-container-filters-container>div{\n  height: 100%;\n}\n.bs-business-container-filters-content,\n.bs-business-container-filters-header-viewport{\n  padding-left:   24px;\n  padding-right:  24px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
