import React from 'react'
import T from 'i18n-react'
import FontAwesome from 'react-fontawesome'
import Cut from 'client/features/common/components/Cut.jsx'
import moment from 'moment'
import Avatar from 'client/features/common/components/Avatar.jsx'
import PartialHTML from 'client/features/common/components/PartialHTML.jsx'
import classNames from 'classnames'
import { isAuthorized } from 'client/features/issues/lib'
import Period from "@components/period";

const IssueListInfos = ({ issue, user, preferTransfer, highlight, search }) => (
  <div className='c-issue-infos'>
    {
      isAuthorized(issue, 'transfer', 'read') && !(preferTransfer && (!issue.transfer || !issue.transfer.name)) && (issue.transfer && issue.transfer.email) &&
      <div className='c-issue-infos__item transfer'>
        { issue.transfer.other ? issue.transfer.email : issue.transfer.name }&nbsp;
        <FontAwesome name='share-sign'/>
      </div>
    }
    {
      isAuthorized(issue, 'teams', 'read') && ((!preferTransfer || !issue.transfer && issue.planification && issue.planification.assigneesShortLabel) && issue.planification && issue.planification.assigneesShortLabel) &&
      <div className='c-issue-infos__item assign'>
        <FontAwesome name='users'/>&nbsp;
        <Cut
          string={
            <PartialHTML
              content={
                issue.planification.assigneesShortLabel
              }
              highlight={ highlight }
              inline
            />
          }
          className={ classNames({ highlighted: search && search.teamUser }) }
          cut='25'
          more='.'
        />
      </div>
    }

    {
      issue.planification.scheduledFrom && isAuthorized(issue, 'scheduledFrom', 'read') &&
        <Period start={ issue.planification.scheduledFrom } end={ issue.planification.scheduledTo } />
    }

    {
      issue.owner &&
      <div className='c-issue-infos__item u-margin-top-small'>
        <div className='o-flag o-flag--tiny o-flag--reverse'>
          <div className='o-flag__img'>
            <Avatar
              label={ `${issue.owner.firstName[0]}${issue.owner.lastName[0]}` }
              size='u-xxsmall'
              img={ issue.owner && issue.owner.avatar }
            />
          </div>
          <div className='o-flag__body'>
            {issue.owner.firstName[0].toUpperCase()}. {issue.owner.lastName}&nbsp;
          </div>
        </div>
      </div>

    }

  </div>
)

export default IssueListInfos