// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-priority-item-label {
  margin-left: 5px;
}

.bs-priority-normal{
  color: green;
}
.bs-priority-moyen{
  color: orange;
}
.bs-priority-urgent{
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/entities/priority/item.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd;AACA;EACE,aAAa;AACf;AACA;EACE,UAAU;AACZ","sourcesContent":[".bs-priority-item-label {\n  margin-left: 5px;\n}\n\n.bs-priority-normal{\n  color: green;\n}\n.bs-priority-moyen{\n  color: orange;\n}\n.bs-priority-urgent{\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
