import React, { PureComponent } from 'react'
import { findDOMNode } from 'react-dom'
import ReactTooltip from 'react-tooltip'
 

class Tooltip extends PureComponent {

    constructor() {
        super()
        this._popover = React.createRef();
        this.afterShow = this.afterShow.bind(this)
        this.afterHide = this.afterHide.bind(this)
        this.updated = false
        this.state = { showed: false };
    }

    afterShow() {
        this.setState({ showed: true });
    }

    afterHide() {
        this.setState({ showed: false });
    }

    onTooltipShowed(){
      const domNode = findDOMNode(this._popover.current)
      const domNodeRect = domNode.getBoundingClientRect()
      const windowHeight = window.outerHeight
      const { offsetTop } = domNode
      if (offsetTop < 50 && offsetTop > -18000) {
          this.updated = true
          if (this.props.masterRef) {
              const masterDomNode = findDOMNode(this.props.masterRef)
              const masterDomOffsetTop = this.props.masterRefBottom ? masterDomNode.getBoundingClientRect().bottom : masterDomNode.getBoundingClientRect().top
              const masterHeight = masterDomNode.clientHeight
              domNode.style.height = 100 + masterDomOffsetTop + masterHeight
          }
      }
      let padding = 30
      setTimeout(() => {
          if (domNode.style.left && (domNode.className.match('place-right') || domNode.className.match('place-left'))) {
              if (domNode.className.match('place-right'))
                  padding *= -1
              const left = Number(domNode.style.left.replace('px', '')) + padding
              domNode.style.left = `${left}px`
          } else if (domNode.style.top && (domNode.className.match('place-bottom') || domNode.className.match('place-top'))) {
              if (domNode.className.match('place-bottom'))
                  padding *= -1
              const top = Number(domNode.style.top.replace('px', '')) + padding
              domNode.style.top = `${top}px`
          }

          if (this.props.forcePlace && !domNode.className.match(`place-${this.props.place}`)) {
              domNode.className = domNode.className.replace(/place-[a-z]*/, `place-${this.props.place}`)
              if (this.props.place === 'left') {
                  const masterDomNodeRect = findDOMNode(this.props.masterRef).getBoundingClientRect()
                  domNode.style.left = masterDomNodeRect.left - (domNodeRect.width - 40)
              }
          }
      }, 1)
    }

    onTooltipHidden(){
      if (!this.updated)
      return
      const domNode = findDOMNode(this._popover.current)
      domNode.style.height = null
      domNode.style.top = null
      this.updated = false
    }

    componentDidUpdate(prevProps, prevState){
      if(prevState.showed !== this.state.showed){
        if(this.state.showed){
          this.onTooltipShowed();
        }else{
          this.onTooltipHidden();
        }
      }
    }

    render() {
        const {
            delayShow = 120,
            delayHide = 1,
            scrollHide = true,
            id,
            type,
            place = 'right',
            effect = 'solid',
            className = 'c-popover-issue-wrapper',
            children
        } = this.props
        
        return (
            <ReactTooltip
                delayShow={ delayShow }
                delayHide={ delayHide }
                scrollHide={ scrollHide }
                ref={ this._popover }
                id={ id }
                type={ type }
                place={ place }
                effect={ effect }
                className={ className }
                afterShow={ this.afterShow }
                afterHide={ this.afterHide }
            >
                {
                    this.state.showed ? children : (<div />)
                }
            </ReactTooltip>
        )
    }
}

export default Tooltip