import React, { FunctionComponent, PropsWithChildren }                    from "react";
import Highlight                from "@cComponents/highlight";
import Filter                   from "@cComponents/filter";
import StateFilter              from "@entities/filters/state";
import Layout                   from '@cComponents/layout';
import Scrollbar                from "@cComponents/scrollBar";
import MapComponent             from "@cComponents/map2";
import Collapsable              from "@cComponents/collapsable";
import usePager                 from "@uBehaviour/data/hooks/usePager";
import useService               from "@uBehaviour/hooks/useService";
import T                        from "@uBehaviour/i18n";
import UrlBuilder               from "@uLib/urlBuilder";
import CurrentTenantService     from "@uServices/currentTenant";
import DownloadService          from "@cServices/download";
import Issue                    from "@uTypes/business/Issue";
import { withLink, withModalLinked } from "@cHoc/withLinkAndModalLinked";
import Item                     from "@entities/issue/item/starter";
import Tooltip                  from "@entities/issue/map/tooltip";
import Search                   from "@entities/assignmentsAndIssues/search";
import CTA                      from "../../features/cta";
import Form                     from "./form";
import classNames               from "@universal/lib/classNames";
import useOpenCloseToggle       from "@universal/behaviour/hooks/useOpenCloseToggle";
import Display                  from "@universal/components/displayIf";
import { useLocation }          from "@uFeatures/router";
import { BusinessEntity }       from "@uTypes/technic/Entityable";
import FeaturesDiscovery        from "../featuresDiscovery";
import Searchbar                from "@cComponents/searchbar";
import ListHeaderLayout         from '@cFeatures/layout/listHeader';

import "./list.css";
import ScrollBar from "@common/components/scrollBar/scrollBar";

const states: Array<string> = ["open", "in_progress", "resolved", "rejected"];

type FakeTypeFilterItemProps = {
  icon: string;
  lock?: boolean;
  onClick: () => void
}
const FakeTypeFilterItem: FunctionComponent<PropsWithChildren<FakeTypeFilterItemProps>> = ({ icon, lock = false, onClick, children }) => {
  return (
    <div className={ classNames("bs-starter-issues-fake-filter-item").addIf("bs-starter-issues-fake-filter-item-locked", lock) } onClick={ onClick }>
      { <span className={ `fa fa-${ icon }` } /> }
      { children }
      { lock ? <span className="fa fa-lock bs-starter-issues-fake-filter-item-locked-icon" /> : null }
    </div>
  );
};

const FakeTypeFilter: FunctionComponent = ({}) => {
  const [showBuildingDiscovery, openBuildingDiscovery, closeBuildingDiscovery] = useOpenCloseToggle();
  const [showEquipmentDiscovery, openEquipmentDiscovery, closeEquipmentDiscovery] = useOpenCloseToggle();

  return (
    <>
      <div className="bs-starter-issues-fake-filter">
        <FakeTypeFilterItem icon="map-marker">
          <span><T>starter_issues_fakeFilter_publicSpace</T></span>
        </FakeTypeFilterItem>
        <FakeTypeFilterItem icon="building-o" lock onClick={ openBuildingDiscovery }>
          <span><T>starter_issues_fakeFilter_building</T></span>
        </FakeTypeFilterItem>
        <FakeTypeFilterItem icon="wrench" lock onClick={ openEquipmentDiscovery }>
          <span><T>starter_issues_fakeFilter_equipment</T></span>
        </FakeTypeFilterItem>
      </div>
      <Display.If condition={ showBuildingDiscovery }>
        <FeaturesDiscovery.Building close={ closeBuildingDiscovery }/>
      </Display.If>
      <Display.If condition={ showEquipmentDiscovery }>
        <FeaturesDiscovery.Equipment close={ closeEquipmentDiscovery }/>
      </Display.If>
    </>
  );
};



const LeftPanel: React.FunctionComponent = () => {
  const [showCategoryDiscovery, openCategoryDiscovery, closeCategoryDiscovery] = useOpenCloseToggle();
  const [showTeamDiscovery, openTeamDiscovery, closeTeamDiscovery] = useOpenCloseToggle();

  return (
  <ScrollBar>
    <div className="bs-starter-issues-filters">
    {/* Statuts */}
      <StateFilter states={ states }/>

    {/* Catégories */}
      <Collapsable lock>
        <Collapsable.Title>
          <div onClick={ openCategoryDiscovery }>
            <span className={`bs-starter-issues-filter-title`}><T>starter_issues_filter_categories</T></span>
          </div>
        </Collapsable.Title>
      </Collapsable>

    {/* Types */}
      <Collapsable alwaysOpen>
        <Collapsable.Title>
          <span className={`bs-starter-issues-filter-title`}><T>starter_issues_filter_type</T></span>
        </Collapsable.Title>
        <Collapsable.Content>
          <FakeTypeFilter />
        </Collapsable.Content>
      </Collapsable>
         {/* Équipes */}
        <Collapsable lock>
          <Collapsable.Title>
            <div onClick={ openTeamDiscovery }>
              <span className={`bs-starter-issues-filter-title`}><T>starter_issues_filter_teams</T></span>
            </div>
          </Collapsable.Title>
        </Collapsable>
      <CTA.DiscoverOffers.White />
    </div>
    <Display.If condition={ showCategoryDiscovery }>
      <FeaturesDiscovery.Category close={ closeCategoryDiscovery }/>
    </Display.If>
    <Display.If condition={ showTeamDiscovery }>
      <FeaturesDiscovery.Team close={ closeTeamDiscovery }/>
    </Display.If>
  </ScrollBar>
)};

type StarterMapProps = {
  issues: Array<BusinessEntity<Issue>>;
}; 
const LinkedIssueTooltip = withLink(Tooltip);

const Map: React.FunctionComponent<StarterMapProps> = ({ issues }) => {
  const [current, goTo] = useLocation();

  const goToIssue = React.useCallback((issue: BusinessEntity<Issue>) => {
    goTo(`${current}/${issue._id}`);
  }, [current, goTo]);

  return (
    <MapComponent 
      cluster={ true }
      visitorDidUpdate={MapComponent.onGeoJSONObjectsUpdated.recenter}
    >
    {
      issues.filter(issue => issue.location).map((issue) => (
        <MapComponent.GeoJSON 
          key={ issue._id } 
          position={ issue.location.toPlainText().position } 
          className={ `fa fa-map-marker bs-issue-mapMarker bs-${ issue.state }`}
          onClick={ () => goToIssue(issue) }
        >
          <LinkedIssueTooltip data={ issue } issue={ issue }/>
        </MapComponent.GeoJSON>
      ))
    } 
    </MapComponent>
  );
}


type ListAndMapProps = {
  query: Object
};
const ListAndMap: React.FunctionComponent<ListAndMapProps> = ({ query }) => {
  const { datas: issues, loadNext } = usePager<Issue, typeof Item.load>({
    model: "Issue",
    query : query,
    sort: { createdAt: -1 },
    load: Item.load,
  });

  const onScroll = React.useCallback((scrollbar: any) => { 
    if(scrollbar.position > 0.8) loadNext();
    return;
  }, [loadNext]);

  return (
    <div className="bs-starter-issues-listAndMap">
      {/* Liste des signalements */}
      <div className="bs-starter-issues-list">
        <Scrollbar onScroll={ onScroll } whenViewPortHeightChange="keepPostion">
        {
          issues.map((issue) => (
            <div className="bs-starter-issue-link" key={ issue._id }>
              <LinkedIssueItem data={ issue } />
            </div>
          ))
        }
        </Scrollbar>
      </div>

      {/* Carte des signalements */}
      <div>
        <div className="bs-starter-issues-map">
          <Map issues={ issues } />
        </div>
      </div>
    </div>
  )
};

const LinkedIssueItem = withLink(Item);
const ModalizedIssueForm = withModalLinked(Form);


type IssueListProps = {
};
const List: React.FunctionComponent<IssueListProps> = () => {
  const filter        = React.createRef();
  const currentTenant   = useService<CurrentTenantService>("currentTenant", ["onServiceUpdated"]);
  const downloadService = useService<DownloadService>("download");

  const defaultQuery  = React.useMemo(() => ({ 
    tenant: currentTenant.currentId,
    equipment: null,
    "location.building": null,
  }), [currentTenant.currentId]);

  const exportIssues = React.useCallback(() => {
    downloadService.download(
      UrlBuilder.create('/export/issues')
        .addParam("q", JSON.stringify(defaultQuery))
        .addParam("sort", JSON.stringify({ createdAt: -1 }))
        .build()
    );
  }, [downloadService, defaultQuery]);

  return (
    <Filter.Aggregator default={[]} ref={ filter }>
      <Layout.Coreff>
        {/* Filtres */}
        <Layout.Coreff.Left className="bs-starter-issues-left">
          <LeftPanel />
        </Layout.Coreff.Left>

        <Layout.Coreff.Right className="bs-starter-issues-right">
          <Highlight.Manager>
          {/* Barre de recherche */}
          <div className="bs-starter-issues-header">
            <ListHeaderLayout>
              <ListHeaderLayout.Left>
                <span className="bs-starter-issues-export-button" onClick={ exportIssues }>
                  <span className="fa fa-download" />
                  <T>issue_list_export</T>
                </span>
              </ListHeaderLayout.Left>
              <ListHeaderLayout.Center>
                <Searchbar>
                  <Search issueIdPath="_id" assignmentsPath="assignments" fluid />
                </Searchbar>
              </ListHeaderLayout.Center>
            </ListHeaderLayout>
          </div>
          <Filter.Subject>
          {(composeQuery: (q: Object) => Object) => (
            <ListAndMap query={ composeQuery(defaultQuery) } />
          )}
          </Filter.Subject>
          </Highlight.Manager>
        </Layout.Coreff.Right>
      </Layout.Coreff>      
      {/* Formulaire de changement de statut */}
      <ModalizedIssueForm style={{ width: "90vw", height: "90vh" }} />
    </Filter.Aggregator>
  );
}

export default List;
