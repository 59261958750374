import React, { PureComponent } from 'react'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { setMapBoudaries } from 'client/features/issues/lib'

const requireAll = (r) => {
  return r.keys().reduce((acc, path) => {
    acc[path.substr(2)] = r(path).default;
    return acc;
  }, {});
}
const images = requireAll(require.context('client/assets/images/tags', false, /\.png$/));

const Map = withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={ props.defaultZoom }
    center={ { lat: props.marker.latitude, lng: props.marker.longitude } }
    onIdle={ props.onIdle }
    options={ props.options }
  >
    <Marker
      position={ { lat: props.marker.latitude, lng: props.marker.longitude } }
      icon={ props.marker.icon }
    />
    {
      props.currentNear && <Marker position={ { lat: props.currentNear.latitude, lng: props.currentNear.longitude } } icon={ props.currentNear.marker }/>
    }
  </GoogleMap>
)

class IssueDetailsMap extends PureComponent {

  constructor() {
    super()
    this._container = React.createRef();
    this.onMapLoaded = this.onMapLoaded.bind(this)
  }

  onMapLoaded() {
    const { user } = this.props
    setMapBoudaries(this.map.state.map, user)
    window.google.maps.event.trigger(this.map.state.map, 'resize')
  }

  render() {
    const {
      containerHeight = '180px',
      latitude,
      longitude,
      marker,
      onClick = () => null,
      currentNear,
      options = {
        draggable: false,
        scrollwheel: false,
        disableDoubleClickZoom: true,
        panControl: false,
        fullscreenControl: false
      }
    } = this.props

    return (
      <section style={ { height: containerHeight } } ref={ this._container }
      onClick={ onClick }>
        <div className='u-hide-for-print' style={{ height: "100%" }}>
          <Map
            defaultZoom={ 15 }
            containerElement={
              <div style={ { height: '100%' } } />
            }
            mapElement={
              <div style={ { height: '100%' } } />
            }
            ref={ (map) => this.map = map }
            onIdle={ this.onMapLoaded }
            marker={ {
              latitude,
              longitude,
              icon: images[marker]
            } }
            currentNear={ currentNear }
            options={ options }
          />
          
        </div>
        <div className='u-show-for-print'>
          <img src={
            `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&size=${this.oldRefs && this._container.current ? this._container.current.offsetWidth : 1200}x182&scale=2&markers=icon:${images[marker]}|${latitude},${longitude}&key=${window.__CONFIG.gmap}`
          } style={ { height: containerHeight, width: '100%' } }/>
        </div>
      </section>
    )
  }
}

export default IssueDetailsMap