// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-table-main-head-filters-resume {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin: auto;
}

.bs-table-main-head-filters-resume-fake-logo {
  flex:1;
}

.bs-table-main-head-filters-resume-infos {
  display:flex;
  justify-content: space-between;
  flex:11;
}`, "",{"version":3,"sources":["webpack://./src/print/components/filters.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,UAAU;EACV,YAAY;AACd;;AAEA;EACE,MAAM;AACR;;AAEA;EACE,YAAY;EACZ,8BAA8B;EAC9B,OAAO;AACT","sourcesContent":[".bs-table-main-head-filters-resume {\n  display: flex;\n  justify-content: space-between;\n  width: 95%;\n  margin: auto;\n}\n\n.bs-table-main-head-filters-resume-fake-logo {\n  flex:1;\n}\n\n.bs-table-main-head-filters-resume-infos {\n  display:flex;\n  justify-content: space-between;\n  flex:11;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
