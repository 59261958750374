import React from "react";
import T from "@uBehaviour/i18n";

import "./state.css";

const State = ({ recurrence }) => (
    <div className={ "bs-recurrence-state " + (recurrence.deleted ? "bs-recurrence-state-deleted" : recurrence.ended ? "bs-recurrence-state-ended" : "bs-recurrence-state-inProgress") }>
        <div className="bs-recurrence-state-triangle"/>
        <div className="bs-recurrence-state-rectangle"><T>{ recurrence.deleted ? "recurrence_state_deleted" : recurrence.ended ? "recurrence_state_ended" : "recurrence_state_inProgress" }</T></div>
    </div>
);

export default State;