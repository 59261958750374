import User from '@uTypes/business/User';
import React, { FunctionComponent } from 'react';
import Subject from './subject';
import Data from '@universal/behaviour/data';

interface AgentSubjectProps {
  agent: User;
  del?: null | (() => void);
};
const AgentSubject: FunctionComponent<AgentSubjectProps> = ({ agent, del = null }) => (
  <Data.Synchronizer entity={ agent }>
  {(agent) => (
    <Subject icon="user" del={ del }>{ agent.fullname }</Subject>
  )}
  </Data.Synchronizer>
);

export default AgentSubject;