import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { root } from 'client/core/sagas'
import rootReducer from 'client/core/reducers'
import { createBrowserHistory } from 'history'
import nextRootReducer from '../../core/reducers'

const initialState = {}

export const history = createBrowserHistory();

const myMiddleWare = mainReducer => (state, action) => {
  if(action?.type === "changeState"){
    return action.datas;
  }
  return mainReducer(state, action);
};

export default function initStore(defaultStoreState) {
  const sagaMiddleware = createSagaMiddleware();
  let store
  const state = { ...initialState, ...defaultStoreState }

  const composeEnhancers = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            name: "STORE_BORDELIQUE"
        })
        : compose;

  store = createStore(
    myMiddleWare(rootReducer(history)),
    state,
    // composeEnhancers(applyMiddleware(routerMiddleware(history), sagaMiddleware))
    applyMiddleware(sagaMiddleware)
  )
  if (module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(nextRootReducer)
    })
  }

  store.runSaga = sagaMiddleware.run
  sagaMiddleware.run(root)

  return store
}