import React, {
  FunctionComponent,
  ReactNode
} from 'react';
import Demo from '../demo';
import { Section  } from "@cComponents/section";
import Recurrence from '@uTypes/business/Recurrence';
import Issue from '@uTypes/business/Issue';
import Scheduler from "../scheduler";
import T from '@universal/behaviour/i18n';
import SchedulerRule from '../schedulerRule';
import dayjs from 'dayjs';
import useService from '@uBehaviour/hooks/useService';
import CurrentTenantService from '@uServices/currentTenant';
import usePager from "@uBehaviour/data/hooks/usePager";
import IssueItem from "@entities/issue/item";
import { BusinessEntity } from '@universal/types/technic/Entityable';

import './content.css';
import Display from '@common/components/displayIf';

interface ContentProps {
  recurrence: BusinessEntity<Recurrence>;
}

const Content: FunctionComponent<ContentProps> = ({ recurrence }) => {
  const currentTenantService = useService<CurrentTenantService>("currentTenant");
  const { datas: alreadyCreatedIssues } = usePager<Issue>({
    model: "Issue",
    query: { recurrence: recurrence._id },
    sort: { createdAt: -1 },
    load: IssueItem.Short.load,
    pageSize: 3
  });

  const titleInfos = React.useMemo<ReactNode>(()  => (
    <>
      <Display.If condition={recurrence.deleted}>
        <span>
          &nbsp;
          <T>recurrence_detail_content_general_infos_deleted_recurrence</T>
        </span>
      </Display.If>
      <Display.If condition={recurrence.ended}>
        <span>
          &nbsp;
          <T>recurrence_detail_content_general_infos_ended_recurrence</T>
        </span>
      </Display.If>
    </>
  ), [recurrence]);

  const endDate = React.useMemo<string | ReactNode>(() => {
    const scheduler = Scheduler.factory(recurrence.toPlainText(), (date) => currentTenantService.isNotAWorkingDay(date));
    const endDate = scheduler.endDate();
    return endDate ? dayjs(endDate).format("DD/MM/YYYY") : <T>recurrence_detail_content_general_infos_end_never</T>;
  }, [recurrence, currentTenantService]);

  return (
    <div className="bs-recurrence-detail-content">
      <Section>
        <Section.Title>
          <T>recurrence_detail_content_general_infos</T>
          {titleInfos}
        </Section.Title>
        <Section.Content>
          <SchedulerRule schedulerRule={recurrence.schedulerRule} eventHorizonInDay={ recurrence.eventHorizonInDay }/>
          <div><T bind={{ date: endDate}}>recurrence_detail_content_general_infos_end</T></div>
          <div><T bind={{ date: dayjs(recurrence.createdAt).format("DD/MM/YYYY") }}>recurrence_detail_content_general_infos_createdAt</T></div>
        </Section.Content>
      </Section>
      <Section>
        <Section.Title><T>recurrence_detail_content_already_created</T></Section.Title>
        <Section.Content>
          <div className="bs-recurrence-detail-already-created-container">
            {alreadyCreatedIssues.map(issue => (
              <div className="bs-recurrence-detail-already-created-item">
                <IssueItem.Short data={issue} />
              </div>
            ))}
          </div>
        </Section.Content>
      </Section>
      <Section>
        <Section.Title><T>recurrence_detail_content_next_created</T></Section.Title>
        <Section.Content>
          <Display.If condition={recurrence.deleted || recurrence.ended}>
            <Display.Then>
              {recurrence.deleted 
                ? <T>recurrence_detail_content_deleted_recurrence</T>
                : <T>recurrence_detail_content_ended_recurrence</T>
              }
            </Display.Then>
            <Display.Else>
              <Demo recurrence={recurrence.toPlainText()} />
            </Display.Else>
          </Display.If>
        </Section.Content>
      </Section>
    </div>
  )
}

export default Content;