import React from 'react';
import moment from 'moment';

const timeBoundary = 15 * 24 * 60 * 60;
const Deadline = ({ value, "data-testid": dataTestId }) => {
  //Calcul de la valeur H de la couleur HSL à appliquer en style sur la date d'échéance
  const now = moment();
  let diffFromNow = moment(value).diff(now) / 1000;
  if (diffFromNow > timeBoundary) {
    diffFromNow = timeBoundary;
  }else if(diffFromNow < 0) {
    diffFromNow = 0;
  }
  const hDeadlineColor = Math.round(120 * diffFromNow / timeBoundary);
  
  
  return (
    <span style={{ color: `hsl(${hDeadlineColor}, 75%, 50%)` }} data-testid={ dataTestId }>
      { moment(value).format('DD/MM/YYYY') }
    </span>
  );
};

export default Deadline;