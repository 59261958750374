// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style général hors template (valable pour la dernière slide de chaque composant */
.bs-featuresDiscovery-content{
  display: flex;
  flex-direction: column;
}
.bs-featuresDiscovery-content-CTA{
  margin: auto;
  margin-top: 50px;
}

/* Couleurs des icônes par composant */
.bs-featuresDiscovery-newIssue-icon{
  color: #00D47D;
  background-color: #DCFFF3;
}
.bs-featuresDiscovery-planning-icon{
  color: #15ACC0;
  background-color: #DCFBFF;
}
.bs-featuresDiscovery-stats-icon{
  color: #0472E4;
  background-color: #E0EDFF;
}
.bs-featuresDiscovery-team-icon{
  color: #9235E4;
  background-color: #F0DFFF;
}
.bs-featuresDiscovery-equipment-icon{
  color: #FF0057;
  background-color: #FFD4E6 ;
}
.bs-featuresDiscovery-category-icon{
  color: #FFB700;
  background-color: #FFFADD;
}
.bs-featuresDiscovery-building-icon{
  color: #FF5800;
  background-color: #FFE1CB;
}`, "",{"version":3,"sources":["webpack://./src/applications/starter/views/featuresDiscovery/index.css"],"names":[],"mappings":"AAAA,oFAAoF;AACpF;EACE,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA,sCAAsC;AACtC;EACE,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,0BAA0B;AAC5B;AACA;EACE,cAAc;EACd,yBAAyB;AAC3B;AACA;EACE,cAAc;EACd,yBAAyB;AAC3B","sourcesContent":["/* Style général hors template (valable pour la dernière slide de chaque composant */\n.bs-featuresDiscovery-content{\n  display: flex;\n  flex-direction: column;\n}\n.bs-featuresDiscovery-content-CTA{\n  margin: auto;\n  margin-top: 50px;\n}\n\n/* Couleurs des icônes par composant */\n.bs-featuresDiscovery-newIssue-icon{\n  color: #00D47D;\n  background-color: #DCFFF3;\n}\n.bs-featuresDiscovery-planning-icon{\n  color: #15ACC0;\n  background-color: #DCFBFF;\n}\n.bs-featuresDiscovery-stats-icon{\n  color: #0472E4;\n  background-color: #E0EDFF;\n}\n.bs-featuresDiscovery-team-icon{\n  color: #9235E4;\n  background-color: #F0DFFF;\n}\n.bs-featuresDiscovery-equipment-icon{\n  color: #FF0057;\n  background-color: #FFD4E6 ;\n}\n.bs-featuresDiscovery-category-icon{\n  color: #FFB700;\n  background-color: #FFFADD;\n}\n.bs-featuresDiscovery-building-icon{\n  color: #FF5800;\n  background-color: #FFE1CB;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
