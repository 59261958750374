
import moment from "moment";
const df     = "YYYYMMDDHHmmss";
class Timeline{
	constructor(periods = [], startProperty = "start", endProperty = "end"){
		this._timeline = {};
		this._startProperty = startProperty;
		this._endProperty   = endProperty;
		const fnDateAdd = ((date, val) =>{
			if(!this._timeline[date]) this._timeline[date] = 0;
			this._timeline[date] += val;
		});
		periods.forEach(period => {
			if(period[startProperty] >= period[endProperty]){
				throw new Error("period start must be lower than period end");
			}
			fnDateAdd(moment(period[startProperty]).format(df), 1);
			fnDateAdd(moment(period[endProperty]).format(df), -1);
		});
	}
	add(timeline){
		const tmpTimeline = new Timeline();
		tmpTimeline._timeline = Object.assign({}, this._timeline);
		Object.keys(timeline._timeline).forEach(key => {
			if(!tmpTimeline._timeline[key]) tmpTimeline._timeline[key] = timeline._timeline[key];
			else  tmpTimeline._timeline[key] += timeline._timeline[key];
		});
		return new Timeline(tmpTimeline.toPeriods(), this._startProperty, this._endProperty);
	}
	sub(timeline){
		const tmpTimeline = new Timeline();
		tmpTimeline._timeline = Object.assign({}, this._timeline);
		Object.keys(timeline._timeline).forEach(key => {
			if(!tmpTimeline._timeline[key]) tmpTimeline._timeline[key] = -1 *timeline._timeline[key];
			else  tmpTimeline._timeline[key] += -1 * timeline._timeline[key];
		});
		return new Timeline(tmpTimeline.toPeriods(), this._startProperty, this._endProperty);
	}
	intersect(timeline){
		return this.sub(this.add(timeline).sub(timeline));
	}
  exclude(timeline) {
    return this.sub(timeline);
  }
	toPeriods(){
		const periods = [];
		let current = 0;
		let tmpPeriod = {};
		Object.keys(this._timeline).sort().forEach(key => {
			current += this._timeline[key];
			if(current >= 1 && !tmpPeriod[this._startProperty]){
				tmpPeriod[this._startProperty] = moment(key, df).toDate();
			}
			if(current <= 0 && tmpPeriod[this._startProperty]){
				tmpPeriod[this._endProperty] = moment(key, df).toDate();
				periods.push(tmpPeriod);
				tmpPeriod = {};
			}
		});
		return periods;
	}
}

export default Timeline;