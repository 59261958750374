import React from 'react'

const CheckboxesField = (field) => {
    const { label, name, input, fields, labelClass = '', initialValues, meta: { error, touched } } = field 
    const onClick = (value) => {
        if (field.onChange) field.onChange(value)
        const inputValue = input.value || []
        const indexOfValue = inputValue.indexOf(value)
        if (!inputValue || inputValue[0] === undefined) return input.onChange(input.value = [])
        if (indexOfValue === -1) inputValue.push(value)
        else inputValue.splice(indexOfValue, 1)
        return input.onChange(input.value = inputValue)
    }

    return (
        <div>
            <div className='col-md-12'>
                { label ? <h5>{ label }</h5> : null }
                {
                    fields.map((field, index) => 
                        <div className='checkbox-inline' key={ `checkbox-${name}-${index}` }>
                            <label className={ labelClass }>
                                <input
                                    onChange={ onClick.bind(null, field.value) }
                                    type='checkbox'
                                    name={ name }
                                    value={ field.value }
                                    defaultChecked={ initialValues && initialValues.indexOf(field.value) !== -1 }
                                />
                                { field.label }
                            </label>
                        </div>
                    )
                }
                { touched && ((error && <p className='error'>{error}</p>)) }
            </div>
        </div>
    )
}

export default CheckboxesField