// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.bs-weather-list{
  display: flex;
  justify-content: space-evenly;
  background-color: rgba(0, 0, 0, 0.1);
}

.bs-weather-day{
  display: flex;
}

.bs-weather-day-icon{
  width: 111px;
  height: 111px;
}

.bs-weather-day-info{
 display: flex;
 align-items: center;
}

.bs-weather-day-title{
  font-weight: 600;
  margin-bottom: 16px;
  font-size: 19px;
}

.bs-weather-day-temp{
  display: flex;
  align-items: center;
}

.bs-weather-day-temp-item{
  display: inline-block;
  padding: 0 4px;
  color: white;
  border-radius: 2px;
  cursor: default;
  margin-left: 5px;;
}
.bs-weather-day-temp-item.bs-temp-min{
  background-color: #8b8b8b;
  margin-right: 8px;
}
.bs-weather-day-temp-item.bs-temp-max{
  background-color: #fc714a;
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/home/weather.css"],"names":[],"mappings":";;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,oCAAoC;AACtC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA;CACC,aAAa;CACb,mBAAmB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,iBAAiB;AACnB;AACA;EACE,yBAAyB;AAC3B","sourcesContent":["\n\n.bs-weather-list{\n  display: flex;\n  justify-content: space-evenly;\n  background-color: rgba(0, 0, 0, 0.1);\n}\n\n.bs-weather-day{\n  display: flex;\n}\n\n.bs-weather-day-icon{\n  width: 111px;\n  height: 111px;\n}\n\n.bs-weather-day-info{\n display: flex;\n align-items: center;\n}\n\n.bs-weather-day-title{\n  font-weight: 600;\n  margin-bottom: 16px;\n  font-size: 19px;\n}\n\n.bs-weather-day-temp{\n  display: flex;\n  align-items: center;\n}\n\n.bs-weather-day-temp-item{\n  display: inline-block;\n  padding: 0 4px;\n  color: white;\n  border-radius: 2px;\n  cursor: default;\n  margin-left: 5px;;\n}\n.bs-weather-day-temp-item.bs-temp-min{\n  background-color: #8b8b8b;\n  margin-right: 8px;\n}\n.bs-weather-day-temp-item.bs-temp-max{\n  background-color: #fc714a;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
