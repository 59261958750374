import React                  from "react";
import Data                   from "@uBehaviour/data";
import T                      from "@uBehaviour/i18n";
import dayjs                  from "dayjs";
import Pages                  from "./components/pages";
import Filters                from "./components/filters";
import Location               from "@uLib/location";
import Application            from "@uBehaviour/application";
import File                   from "@cComponents/file";
import Query                  from '@uLib/query';
import useService             from "@uBehaviour/hooks/useService";
import usePager from '@uBehaviour/data/hooks/usePager';

import "./agentsCalendar.css";

class AgentCollector{
  constructor(user){
    this._user        = user;
    this._assignments = [];
  }
  get fullname(){
    return this._user.fullname;
  }
  addAssignment(assignment){
    this._assignments.push(assignment);
  }
  get(maxDay){
    const tabs = [];
    const pushLine = () => {
      const line = [];
      for(let i = 0; i < maxDay; i++){
        line.push(null);
      }
      tabs.push(line);
    };
    pushLine();
    this._assignments.sort((a1, a2) => a2.nbr - a1.nbr).forEach(assignment => {
      let found = false;
      let index = 0;
      while(index < tabs.length && !found){
        let i = 0;
        for(; i < assignment.nbr && !tabs[index][assignment.startIndex + i]; ++i);
        if(i === assignment.nbr){
          for(i = 0; i < assignment.nbr; ++i){
            tabs[index][assignment.startIndex + i] = assignment;
          }
          found = true;
        }else{
          ++index;
        }
      }
      if(!found){
        pushLine();
        for(let i = 0; i < assignment.nbr; ++i){
          tabs[tabs.length - 1][assignment.startIndex + i] = assignment;
        }
      }
    });
    return {
      user: this._user,
      tabs: tabs
    };
  }
}
class TeamCollector{
  constructor(team, globalCollector){
    this._agentsCollector = team.members.map(agent => globalCollector.getAgent(agent));    
  }
  addAssignment(assignment){
    this._agentsCollector.forEach(agentCollector => {
      agentCollector.addAssignment(assignment);
    });
  }
}

class GlobalCollector{
  constructor(){
    this._teamCollectors  = {};
    this._agentCollectors = {};
  }
  getAgent(agent){
    if(!this._agentCollectors[agent._id]){
      this._agentCollectors[agent._id] = new AgentCollector(agent);
    }
    return this._agentCollectors[agent._id];
  }
  getTeam(team){
    if(!this._teamCollectors[team._id]){
      this._teamCollectors[team._id] = new TeamCollector(team, this);
    }
    return this._teamCollectors[team._id];
  }
  addAssignment(assignment){
    assignment.datas.assignment.agents.forEach(agent => {
      this.getAgent(agent).addAssignment(assignment);
    });
    assignment.datas.assignment.team.forEach(team => {
      this.getTeam(team).addAssignment(assignment);
    });
  }
  get(maxDay){
    return Object.values(this._agentCollectors).sort((u1, u2) => { return u1.fullname.localeCompare(u2.fullname) }).map(collector => {
      return collector.get(maxDay)
    });
  }
}



const AgentCalendar = (props) => {  
  const currentTenant = useService("currentTenant");
  const start = dayjs(props.start);
  const end   = dayjs(props.end);
  const defaultQuery = { "assignment.scheduledFrom": { $lt: props.end }, "assignment.scheduledTo":   { $gt: props.start } };
  const query = Query.joinWithOptimizer((props.query ? JSON.parse(props.query) : defaultQuery), { tenant: currentTenant.currentId });
  const compress = props.compress === "true";

  const { datas: assignments, allElementsLoaded } = usePager({
    model: "Assignment",
    query : query,
    load: {
      'assignment.agents': { team: true },
      'assignment.team': { "members": true },
      "location.building": true,
      "equipment": true
    },
    loadAll: true
  });
  
  if(!allElementsLoaded) {
    return null;
  }
 
  const collector = new GlobalCollector(start, end);
  assignments.forEach(assignment => {
    const a = {
      start: dayjs(assignment.assignment.scheduledFrom) >= start ? dayjs(assignment.assignment.scheduledFrom) : start,
      end: dayjs(assignment.assignment.scheduledTo) <= end ? dayjs(assignment.assignment.scheduledTo) : end,
      datas: assignment
    };
    a.startIndex = Math.floor(dayjs.duration(a.start.diff(start)).asDays());;
    a.nbr = Math.ceil(dayjs.duration(a.end.diff(a.start)).asDays());
    collector.addAssignment(a);
  });
  const datas = collector.get(Math.ceil(dayjs.duration(end.diff(start)).asDays()));
  
  return (
    <Pages type="landscape">
      <div className="bs-print-overlay">
        <div>
          <h1>
            <T>print_ready</T>
          </h1>
        </div>
      </div>
      <table className="bs-table-main">
        <thead>
          <Header start={start} end={end}/>
        </thead>
        <thead>
          <Filters filters={props.filters}/>
        </thead>
        <tbody>
          <div>
            <TableAgents start={start} end={end}>
            {
              datas.map((data, index) => (
                <Agent data={ data } isEven={ !(index % 2)} compress={ compress }/>
              ))
            }
            </TableAgents>
          </div>
        </tbody>
      </table>
    </Pages>
  );
}

const Header = Application.Service.forward(["currentTenant"], ({ start, end, currentTenant }) => {
  let dateHeader = "";
  if(start.month() !== end.month()){
    if(start.year() !== end.year()){
      dateHeader = start.format('MMM YYYY') + " - " + end.format('MMM YYYY');
    }else{
      dateHeader = start.format('MMM') + " - " + end.format('MMM YYYY');
    }
  }else{
    dateHeader = start.format('MMMM YYYY');
  }
  
  return (
    <Data.Query model="File" query={{ _id: currentTenant.current.informations.pictures.logo }}>
    {(file) => {
      window.setTimeout(() => {
        window.print();
        window.close();
      }, 200);
      return (
        <div className="bs-teams-header">
          <div className="bs-teams-header-file"><File file={ file.length ? file[0] : null } width={32} height={32} fit="contains"/></div>
          <div className="bs-teams-header-infos">
            <div><T>agents_planning</T></div>
            <div className="bs-teams-header-date">{ dateHeader }</div>
            <div className="bs-teams-header-week">{ `Semaine ${start.format('W')}` }</div>
            <div className="bs-teams-header-printdate">{ `Imprimé le ${dayjs().format('DD/MM/YYYY')} à ${dayjs().format('HH:mm')}` }</div>
          </div>          
        </div>
      );
    }}
    </Data.Query>
  )
});

const TableAgents = ({ start, end, children }) => {
  const currentTenant = useService("currentTenant")

  const days = React.useMemo(() => {
    const d = [];
    let day = start.clone();
    while(day < end){
      d.push(day);
      day = day.clone().add(1, "day");
    }
    return d;
  }, [start, end]);

  React.useEffect(() => {
    const styles = `
    .bs-table-agents td{
      width: ${ 1 / (days.length + 1) * 100}%
    }`;

    const styleSheet      = document.createElement("style");
    styleSheet.type       = "text/css";
    styleSheet.innerText  = styles;
    document.head.appendChild(styleSheet);

    return () => { document.head.removeChild(styleSheet); }
  }, [days]);

  return (
    <table className="bs-table-agents">
      <thead>
        <tr>
          <td><T>agents</T></td>
          { 
            days.map(day => (
              <td>
                <span>{ day.format("dddd")}</span><br/><span>{ day.format("D") }</span>
                { currentTenant.isAnHolidayDay(day) && <span className="bs-table-agents-holiday"> (F) </span>}
              </td>
            ))
          }
        </tr>
      </thead>
      <tbody>
      { children }
      </tbody>
    </table>
  )
};

const Agent = ({ data, isEven, compress }) => {
  return (
    <>
    {
      data.tabs.map((tab, index) => {
        let first = index === 0 ? (<td className="bs-table-agents-row-header" rowspan={"" + data.tabs.length }>{ (data.user.team && data.user.team.code ? data.user.team.code + " - " : "") + data.user.fullname }</td>) : null;
        return (
          <tr className={ `bs-table-agents-row-${ isEven ? "even" : "odd" } bs-table-agents-row-${index}` }>
            { first }
            {
              tab.map(tab => (
                <td>
                { tab ? <Assignment assignment={ tab.datas } compress={ compress }/> : null }
                </td>
              ))
            }
          </tr>
        )
      })
    }
    </>
  )
}

const Assignment = ({ assignment, compress }) => {
  let description = assignment.description;
  if(compress){
    description = description.substr(0, 16 * 3 - 3) + "...";
  }
  return (
    <div className="bs-table-agents-assignment">
      <div className="bs-table-agents-header">
        <span className={ `fa fa-exclamation-triangle ${ assignment.priority === 1 ? "bs-table-agents-priority-normal" : assignment.priority === 3 ? "bs-table-agents-priority-middle" : "bs-table-agents-priority-urgent"}`} />
        <span className={ "bs-table-agents-state-" + assignment.state }>{ assignment.bsIdNumber }</span>
        <span className={ assignment.state !== "resolved" && dayjs(assignment.deadline).isBefore(dayjs()) ? "bs-table-agents-deadline-late" : ""}>{ assignment.deadline ? dayjs(assignment.deadline).format("DD/MM/YYYY") : null}</span>
      </div>
      <div className="bs-table-agents-address">
      {
        assignment.equipment
          ? assignment.equipment.name
          : assignment.location.building
            ? assignment.location.building.name
            : Location.getLabelFromAddress(assignment.location.address)
      }
      </div>
      <div>
      {
        description
      }
      </div>
    </div>
  );
}

export default AgentCalendar;