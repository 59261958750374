import React        from "react";
import Application  from "@uBehaviour/application";
import T            from "@uBehaviour/i18n";
import Button       from '@cComponents/button';

import "./selectManager.css";

const fa = {
  publicSpace: "fa fa-road",
  building: "fa fa-building-o",
  equipment: "fa fa-truck"
};
class SelectManager extends React.Component{
  state = { 
    categories: [],
    users: [],
    categoryManagers: {},
    columnUser: null
  }
  constructor(props) {
    super(props);
    this._refs = {};
    this._headers   = React.createRef();
    this._firstLine = React.createRef();
  }
  validate() {
    const categoryManagers = {};
    Object.keys(this.state.categoryManagers).forEach(categoryId => {
      if(this.state.categoryManagers[categoryId] && this.state.users.find(user => user._id === this.state.categoryManagers[categoryId])){
        categoryManagers[categoryId] = this.state.categoryManagers[categoryId];
      }
    });
    this.props.api.service("categories", "updateManyManager").execute(categoryManagers)
      .then(() => this.props.close());
  }
  componentDidMount(){
    const queryUser     = { tenants: { $elemMatch: { disabled: false, tenant: this.props.currentTenant.currentId, roles: { $in: [ "categoryManager", "manager", "admin" ] } } } };
    const queryCategory = { disabled: false, tenant: this.props.currentTenant.currentId };
    Promise.all([
      this.props.api.service("users", "get").execute(queryUser, { fullname: 1}, 0, 500),
      this.props.api.service("categories", "get").execute(queryCategory, {[this.props.i18n.queryProperty("label")]: 1 }, 0, 500)
    ]).then(([users, categories]) => {
      const categoryManagers = {};
      categories.forEach(category => {
        categoryManagers[category._id] = category.redirectRule && category.redirectRule.discriminator === "manager" ? category.redirectRule.manager : null;
      });
      this.setState({ users, categories, categoryManagers });
    });

  }
  componentDidUpdate(){
    Object.values(this._refs).forEach(ref => {
      ref.current.checked = false;
    });
    Object.keys(this.state.categoryManagers).forEach(categoryId => {
      const userId = this.state.categoryManagers[categoryId];
      if(userId){
        this._refs[categoryId + "_" + userId].current.checked = true;
      }
    });
  }
  render(){
    return (
      <div className="bs-selectManager">
        <div>
          <div>
            <div className="bs-selectManager-header">
              <div ref={ this._headers }>
                <div></div>
                { 
                  this.state.users.map(user => {                    
                    const props = { key: user._id };
                    if(this.state.columnUser === user._id){
                      props.className = "bs-selectManager-userHover"
                    }
                    return (<div { ...props }>{ user.fullname }</div>);
                  })
                }
              </div>
            </div>
            <div className="bs-selectManager-content">
            {
              this.state.categories.map((category, index) => {
                const props = {
                  key: category._id
                };
                if(index === 0){
                  props.ref = this._firstLine;
                }
                return (
                  <div { ...props }>
                    <div><span className={fa[category.type]} />&nbsp;<T>{ category.label }</T></div>
                    {
                      this.state.users.map(user => {
                        if(!this._refs[category._id + "_" + user._id]){
                          this._refs[category._id + "_" + user._id] = React.createRef();
                        }
                        const props = {
                          key: category._id + "_" + user._id,
                          onMouseEnter: () => { this.setState({ columnUser: user._id }); },
                          onMouseLeave: () => { this.setState({ columnUser: null }); },
                          onClick: () => {
                            this.setState({ categoryManagers: Object.assign({}, this.state.categoryManagers, { [category._id]:  user._id })});
                          }
                        };
                        if(this.state.columnUser === user._id){
                          props.className = "bs-selectManager-userHover"
                        }
                        return (
                          <div { ...props }>
                            <input type="checkbox" ref={ this._refs[category._id + "_" + user._id] }/>
                          </div>)
                      })
                    }
                  </div>
                );
              })
            }
            </div>
          </div>
        </div>
        <div className="bs-selectManager-buttons" onClick={() => this.validate()}><Button.Stylized.Text><T>validate</T></Button.Stylized.Text></div>
      </div>
    )
  }
}

export default Application.Service.forward(["currentTenant", "api", "i18n"], SelectManager);