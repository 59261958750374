import React, { FunctionComponent } from "react";
import useService from "@universal/behaviour/hooks/useService";
import SessionService from "@universal/services/session";
import CurrentTenantService from "@universal/services/currentTenant";
import ConfigurationService from "@universal/services/configuration";
import Text, { Paragraph, Style } from "@cComponents/text";
import T from "@universal/behaviour/i18n";
import Template from '@components/starterTemplate';
import cButton from "@cComponents/button";
import AssistanceHelper from "@uHelpers/assistance";
import useHelper from "@uBehaviour/hooks/useHelper";

import './alreadyActivated.css';

const Button = cButton.withStyle(cButton.Stylized.orange.big.fluid.round);


const AlreadyActivated: FunctionComponent = () => {
  const session = useService<SessionService>("session");
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const assistance    = useHelper<AssistanceHelper>("assistance");
  const [assistanceEmail, setAssistanceEmail] = React.useState();
  React.useEffect(() => {
    assistance.getSupportUri().then((uri: string) => setAssistanceEmail(uri));
  }, [assistance]);
  const returnToLogin = React.useCallback(() => {
    session.logout();
  }, [session]);

  const tenant = React.useMemo(() => (
    <Text style={ Style.bold }>
      <T>{ currentTenant.current?.name }</T>
    </Text>
  ), [currentTenant]);

  const email = React.useMemo(() => (
    <Text style={ Style.orange }>
      { currentTenant.current?.settings.citizen.defaultEmail }
    </Text>
  ), [currentTenant]);


  const supportLink = React.useMemo(() => (
    <a href={ assistanceEmail } target="_blank" rel="noopener noreferrer">
      <Text style={Style.orange.small.bold}>
        <T>starterActivation_alreadyActivated_supportLinkText</T>
      </Text>
    </a>
  ), [assistanceEmail]);

  return (
    <Template>
      <div className="bs-starterActivation-alreadyActivated">
        <div className="bs-starterActivation-alreadyActivated-content">
          <Paragraph style={ Style.bold.center.large }>
            <T>starterActivation_alreadyActivated_title</T>
          </Paragraph>
          <Paragraph>
            <T bind={{ tenant, email }}>starterActivation_alreadyActivated_info</T>
          </Paragraph>
          <Button onClick={ returnToLogin }>
            <T>starterActivation_alreadyActivated_button</T>
          </Button>
        </div>
        <div className="bs-starterActivation-alreadyActivated-footer">
          <Paragraph style={ Style.bold.center.small }>
            <T>starterActivation_alreadyActivated_encounterProblem</T>
          </Paragraph>
          <Paragraph style={ Style.center.small }>
            <T bind={{ supportLink }}>starterActivation_alreadyActivated_encounterProblemSolution</T>
          </Paragraph>
        </div>
      </div>
    </Template>
  );
}


export default AlreadyActivated;