// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-statistics-number {
  font-size: 2.9rem;
  font-weight: 700;
  padding: 24px;
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/statistics/number.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".bs-statistics-number {\n  font-size: 2.9rem;\n  font-weight: 700;\n  padding: 24px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
