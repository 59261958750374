import State from '../State';

export type States = 'open' | 'planned' | 'in_progress' | 'resolved' | 'transferred' | 'rejected' | 'deleted' | 'merged';

export const StatesConfiguration: Record<States, State> = {
  open: { label: 'open', color: '#f77448', i18n: 'issues_state_open', assignable: true, updatable: true },
  planned: { label: 'planned', color: '#5dc9e6', i18n: 'issues_state_planned', assignable: true, updatable: true },
  in_progress: { label: 'in_progress', color: '#6b98b5', i18n: 'issues_state_in_progress', assignable: true, updatable: true },
  resolved: { label: 'resolved', color: '#89cc1f', i18n: 'issues_state_resolved', assignable: true, updatable: true },
  transferred: { label: 'transferred', color: '#e78fa5', i18n: 'issues_state_transferred', assignable: false, updatable: true },
  rejected: { label: 'rejected', color: '#aaaa5a', i18n: 'issues_state_rejected', assignable: true, updatable: true },
  deleted: { label: 'deleted', color: '#a09696', i18n: 'issues_state_deleted', assignable: true, updatable: true },
  merged: { label: 'merged', color: '#e01a6c', i18n: 'issues_state_merged', assignable: false, updatable: false },
};

export default StatesConfiguration;