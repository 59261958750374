// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print{
  .bs-layout-standart-container{
    height: auto;
  }
}

.bs-print-issue{
  page-break-inside: avoid;
  flex-shrink: 2;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(44, 62, 80, 0.6), 0 5px 10px -3px rgba(44, 62, 80, 0.4);
  margin: 5px;
}

.bs-print-issue-workOrderReturn{
  border-top: 1px solid #cccccc;
}
  .bs-print-issue-workOrderReturn-state{
    display: flex;
    flex-direction: row;
  }

  .bs-print-issue-workOrderReturn-state-date {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

.bs-print-issue-container{
  height: 100%;
  display: flex;
  flex-direction: column;
}
.bs-print-issue-container>.bs-print-issue-list-image>div{
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/print/assignments.css"],"names":[],"mappings":"AAAA;EACE;IACE,YAAY;EACd;AACF;;AAEA;EACE,wBAAwB;EACxB,cAAc;EACd,kBAAkB;EAClB,gFAAgF;EAChF,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B;EACE;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,qBAAqB;EACvB;;AAEF;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;AACd","sourcesContent":["@media print{\n  .bs-layout-standart-container{\n    height: auto;\n  }\n}\n\n.bs-print-issue{\n  page-break-inside: avoid;\n  flex-shrink: 2;\n  border-radius: 3px;\n  box-shadow: 0 0 1px rgba(44, 62, 80, 0.6), 0 5px 10px -3px rgba(44, 62, 80, 0.4);\n  margin: 5px;\n}\n\n.bs-print-issue-workOrderReturn{\n  border-top: 1px solid #cccccc;\n}\n  .bs-print-issue-workOrderReturn-state{\n    display: flex;\n    flex-direction: row;\n  }\n\n  .bs-print-issue-workOrderReturn-state-date {\n    flex-grow: 2;\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n  }\n\n.bs-print-issue-container{\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n}\n.bs-print-issue-container>.bs-print-issue-list-image>div{\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
