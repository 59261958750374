import React, { Component } from 'react'
import Period from '@cComponents/input/period'
import moment from 'moment'

class IssuesDateFilters extends Component {

    constructor() {
        super()
        this.handleChange = this.handleChange.bind(this)
    }


    handleChange(start, end) {
        if(start && end){
            this.props.setFilter({
                key: this.props.field.key,
                value: start ? start.getTime() : null,
                uniq: true,
                inputKey: 'gte'
            })
            this.props.setFilter({
                key: this.props.field.key,
                value: end ? end.getTime() : null,
                uniq: true,
                inputKey: 'lte'
            })
        } else {
            this.props.unsetFilter(this.props.field.key, true, this.props.field.key); 
        }
    }

    render() {
        const attributes = this.props.field.attributes.sort((a, b) => a.key.localeCompare(b.key));
        let start, end;
        if(attributes[0].value || attributes[1].value) {
            start = attributes[0].value ? attributes[0].value : moment().startOf("day").toDate();
            end = attributes[1].value ? attributes[1].value : moment().add(1, "day").startOf("day").toDate();
        }
        return (
            <div className='fields filter-date-field filter-period'>
                <Period.Stated
                    start={ start } 
                    end={ end }
                    onChange={ this.handleChange }
                    allDay
                    nullable
                />
            </div>
        )
    }
}

export default IssuesDateFilters