import React, { FunctionComponent, PropsWithChildren } from "react";
import Wizard from "./wizard"; 

import "./paginated.css";

enum Direction {
  right = "right",
  left = "left"
}

type StepNavigationProps = {
  direction: Direction;
  active: boolean;
  onClick: () => void
};
const StepNavigation: React.FunctionComponent<StepNavigationProps> = ({ onClick, direction, active }) => (
  <div className="bs-paginated-wizard-navigation-container" onClick={ onClick }>
    {active && (<span className={`fa fa-caret-${direction}`} />)}
  </div>
);

type PaginationStepsProps = {
  totalSteps: number,
  index: number
}
const PaginationSteps: FunctionComponent<PaginationStepsProps> = ({ totalSteps, index }) => (
  <div className="bs-paginated-wizard-steps">
    { Array.from({ length: totalSteps }).map((key, i) => (<span key={i} className={`fa fa-circle bs-paginated-wizard-step${ i === index ? "-selected" : "" }`}/>)) }
  </div>
);

type PaginatedContentProps = {
  wizard: typeof Wizard;
  index: number;
  steps: number,
};

const PaginatedContent: FunctionComponent<PropsWithChildren<PaginatedContentProps>> = ({ wizard, steps, index, children }) => {
  const goBack = React.useCallback(() => wizard.previous(), [ wizard ]);
  const goForward = React.useCallback(() => wizard.next(), [ wizard ]);

  return (
    <div className="bs-paginated-wizard-content">
        <StepNavigation onClick={ goBack } direction={Direction.left} active={ wizard.state.step > 0 } />
        <div>
          { children }
          <PaginationSteps totalSteps={ steps } index={ index } />  
        </div>
        <StepNavigation onClick={ goForward } direction={Direction.right} active={ wizard.state.step + 1 < steps } /> 
    </div>
  );
};

type PaginatedProps = {
  close: () => void;
  style?: Object;
};
const Paginated: FunctionComponent<PropsWithChildren<PaginatedProps>> & { Step: typeof Wizard.Step } = ({ close, style = {}, children }) => {
  const steps = Wizard.Step.get(children, true);
  return (
    <Wizard close={ close } style={ style }>
      { steps.map((step: typeof Wizard.Step, index: number) => (
        <Wizard.Step>
          {(ctx, wizard) => (
            <PaginatedContent wizard={ wizard } index={index} steps={ steps.length }>
            { step instanceof Function
              ? step(ctx, wizard)
              : step
            }
            </PaginatedContent>
          )}
        </Wizard.Step>
      ))}
    </Wizard>
    )
  };

Paginated.Step = Wizard.Step;

export default Paginated;