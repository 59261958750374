// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-form-error{
  display: flex;
  flex-direction: column;
  height: 100%;
}

.bs-form-error>*:first-child{
  flex: auto;
}
.bs-form-error>*:nth-child(2){
  flex: none;
}`, "",{"version":3,"sources":["webpack://./../common/components/formError.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;AACd;;AAEA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ","sourcesContent":[".bs-form-error{\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.bs-form-error>*:first-child{\n  flex: auto;\n}\n.bs-form-error>*:nth-child(2){\n  flex: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
