import React, { FunctionComponent, PropsWithChildren } from 'react';
import FocusBlur from "@common/components/focusBlur";
import T from '@universal/behaviour/i18n';
import cButton from "@cComponents/button";
import { Paragraph, Style } from '@common/components/text';
import useService from '@universal/behaviour/hooks/useService';
import ConfigurationService from '@universal/services/configuration';

import { DeepSlot } from '@universal/components/slot2';
import ScrollBar from '@common/components/scrollBar/scrollBar';
import ResponsiveParts from '@common/components/layout/responsiveParts';
import './starterTemplate.css';

const Button = cButton.Stylized.whiteTransparent.big.round.hoverAnimatedScale;

const Illustration: FunctionComponent = () => {
  const configuration = useService<ConfigurationService>('configuration');
  return (
    <div className='bs-starterActivation-template-illustration'>
      <div className='bs-starterActivation-template-illustration-image'>
        <img src='/images/logos/betterstreet_starter_white_text_vertical.svg' alt='logo betterstreet starter' />
      </div>
      <div className='bs-starterActivation-template-illustration-content'>
        <div className='bs-starterActivation-template-illustration-content-message'>
          <Paragraph style={ Style.white.center.bold }>
            <T>starterActivation_template_message</T>
          </Paragraph>
        </div>
        <a href={ configuration.get('discoverLink') } target='_blank' rel='noopener noreferrer'>
          <Button>
            <T>starterActivation_template_betterstreetDiscovery</T>
          </Button>
        </a>
      </div>
    </div>
  );
};

const [Header, useHeader, withHeader] = DeepSlot();

const Content: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const header = useHeader();
  return (
    <div className='bs-starterActivation-template-content'>
      <div className='bs-starterActivation-template-content-header'>
        <img src='/images/logos/betterstreet_starter_color_text_welcome_center_horizontal.svg' alt='logo betterstreet starter' />
      </div>
      <div className='bs-starterActivation-template-content-body'>
        { header && <div className='bs-starterActivation-template-content-body-header'>{ header }</div> }
        <ScrollBar>
        { children }
        </ScrollBar>
      </div>
    </div>
  );
}


const TemplateImpl: FunctionComponent<PropsWithChildren> = ({ children }) => (
  <FocusBlur.Context className="bs-activationStarter-template-base">
    <div className='bs-starterActivation-template-box'>
      <ResponsiveParts direction='horizontal'>
        <ResponsiveParts.Part noSmallScreen>
          <Illustration />
        </ResponsiveParts.Part>
        <ResponsiveParts.Part>
          <Content>
            { children }
          </Content>
        </ResponsiveParts.Part>
      </ResponsiveParts>
    </div>
  </FocusBlur.Context>
);

const Template: FunctionComponent<PropsWithChildren<{}>> & { Header: typeof Header }= Object.assign(
  withHeader(TemplateImpl),
  { Header }
);

export default Template;