import React              from "react";
import { ResponsiveBar } from '@nivo/bar';
const NewIssueInTimeBar = (props) => {
  return (
    <ResponsiveBar
      data={ props.datas }
      margin={{ top: 25, right: 0, bottom: 85, left: 35 }}
      keys={ props.keys }
      padding={0.3}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      axisTop={null}
      axisRight={null}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
      colors={({ id, data }) => String(data[`${id}Color`])}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 90,
      }}
      legends={[
        {
          anchor: 'bottom',
          direction: 'row',
          justify: false,
          translateX: 0,
          translateY: 85,
          itemsSpacing: 0,
          itemWidth: 110,
          itemHeight: 18,
          itemTextColor: '#999',
          itemDirection: 'left-to-right',
          itemOpacity: 1,
          symbolSize: 18,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemTextColor: '#000'
              }
            }
          ]
        }
      ]}
    /> 
  );
}

export default NewIssueInTimeBar;
