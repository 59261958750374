import { Service }              from "@uLib/application";
import { Listener } from "@universal/lib/event";
import _                        from 'lodash';

export default class LoginAsManagerService extends Service {
  constructor(){
    super("loginAsSessionManager", ["session", "acl", "storage", "configuration"]);
    this._session = null;
    this._userConnected = null;
    this._favoritesUsers = [];
    this._superAdminToken = null;
  }

  async start() {
    const [storage, session] = await this.waitReady(["storage", "session"]);

    session.onServiceUpdated.addListener(new Listener(this.removeSuperAdminToken));

    if(await storage.has("loginAsFavoritesUsers")){
      this._favoritesUsers = await storage.get("loginAsFavoritesUsers", true);
    }

    if(await storage.has("loginAsSuperAdminToken")){
      this._superAdminToken = await storage.get("loginAsSuperAdminToken");
    }

    return true;
  }

  removeSuperAdminToken = async (session) => {
    if(!session.isLogged()){
      await this._removeSuperAdminToken();
    }
  }

  get favoritesUsers() {
    return this._favoritesUsers;
  }

  async _persistFavorites() {
    await this.application.getService("storage").set("loginAsFavoritesUsers", this._favoritesUsers);
  }

  async setAsFavorite(user) {
    if (this._favoritesUsers.some(f => f === user._id)) {
      return;
    }
    this._favoritesUsers = this._favoritesUsers.concat([user._id]);

    await this._persistFavorites();
    
    this.triggerUpdate();
  }

  async removeAsFavorite(userId) {
    if (!this._favoritesUsers.some(f => f === userId)) {
      return;
    }
    this._favoritesUsers = this._favoritesUsers.filter(f => f !== userId)

    await this._persistFavorites()
    
    this.triggerUpdate();
  }

  async _setSuperAdminToken() {
    this._superAdminToken = this.application.getService("session").token;
    await this.application.getService("storage").set("loginAsSuperAdminToken", this._superAdminToken);
  }

  async _removeSuperAdminToken() {
    this._superAdminToken = null;
    await this.application.getService("storage").remove("loginAsSuperAdminToken");
  }
  
  isAllowToLoginAs() {
    const configuration = this.application.getService("configuration");
    const acl = this.application.getService("acl");
    return acl.connectedUserIsAllow("sessions", "loginAs")
    && configuration.get("env") === "development";
  }

  isLoggedAsTemporarySession() {
    return !!this._superAdminToken;
  }

  async login(userId) {
    if (!this.application.getService("acl").connectedUserIsAllow("sessions", "loginAs")) {
      console.error("Vous n'avez pas les droits pour réaliser cette action");
      return;
    }
    if (window.process.env === "production") {
      console.error("Action impossible en mode production");
      return;
    }
    const token = await this.application.getService("api").service("sessions", "loginAs").execute(userId);

    await this._setSuperAdminToken();
    
    this.application.getService("session").token = token;
    this.triggerUpdate();
  }

  async backToSuperAdmin() {
    this.application.getService("session").token = this._superAdminToken;
    await this._removeSuperAdminToken();
  }
};