// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issue-formContent-type-value {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
  border: 1px solid #cecbc8;
  font-size: 16px;
  padding: 10px 20px;
  width: 162px;
}
.bs-issue-formContent-type-value-selected{
  background-color: #fa7251;
  color: white;
}
.bs-issue-formContent-content{
  display: flex;
  flex-direction: row;
}
.bs-issue-formContent-content>div{
  width: 50%;
}
.bs-issue-formContent-content>div:first-child{
  margin-right: 10px;
}
.bs-issue-formContent-content>div:last-child{
  margin-left: 10px;
}
.bs-issue-formContent-type-padder{
  display: flex;
  justify-content: center;
}
.bs-issue-formContent-location-map{
  height: 600px;
  margin-top: 12px;
}
.bs-issue-formContent-requestor{
  margin-top: 12px;
}
.bs-issue-formContent-requestor>div {
  display: flex;
  flex-direction: row;
}
.bs-issue-formContent-requestor>div>*{
  flex-grow: 1;
}
.bs-issue-formContent-requestor>div>*:first-child{
  margin-right: 5px;
}
.bs-issue-formContent-requestor>div>*:last-child{
  margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/entities/issue/form/content.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;EACd,yBAAyB;EACzB,eAAe;EACf,kBAAkB;EAClB,YAAY;AACd;AACA;EACE,yBAAyB;EACzB,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,UAAU;AACZ;AACA;EACE,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB;AACA;EACE,aAAa;EACb,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".bs-issue-formContent-type-value {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin: 0 10px;\n  border: 1px solid #cecbc8;\n  font-size: 16px;\n  padding: 10px 20px;\n  width: 162px;\n}\n.bs-issue-formContent-type-value-selected{\n  background-color: #fa7251;\n  color: white;\n}\n.bs-issue-formContent-content{\n  display: flex;\n  flex-direction: row;\n}\n.bs-issue-formContent-content>div{\n  width: 50%;\n}\n.bs-issue-formContent-content>div:first-child{\n  margin-right: 10px;\n}\n.bs-issue-formContent-content>div:last-child{\n  margin-left: 10px;\n}\n.bs-issue-formContent-type-padder{\n  display: flex;\n  justify-content: center;\n}\n.bs-issue-formContent-location-map{\n  height: 600px;\n  margin-top: 12px;\n}\n.bs-issue-formContent-requestor{\n  margin-top: 12px;\n}\n.bs-issue-formContent-requestor>div {\n  display: flex;\n  flex-direction: row;\n}\n.bs-issue-formContent-requestor>div>*{\n  flex-grow: 1;\n}\n.bs-issue-formContent-requestor>div>*:first-child{\n  margin-right: 5px;\n}\n.bs-issue-formContent-requestor>div>*:last-child{\n  margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
