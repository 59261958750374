// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-address{
    color: #27ae60
}
.bs-address>.fa{
    padding-right: 2px;
}`, "",{"version":3,"sources":["webpack://./../common/features/address.css"],"names":[],"mappings":"AAAA;IACI;AACJ;AACA;IACI,kBAAkB;AACtB","sourcesContent":[".bs-address{\n    color: #27ae60\n}\n.bs-address>.fa{\n    padding-right: 2px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
