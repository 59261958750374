import React from 'react'
import classNames from 'classnames'
import FontAwesome from 'react-fontawesome'
import File     from 'client/components/file.jsx';

const Avatar = ({
  label,
  text,
  closeButton,
  size = 'u-xsmall',
  bg,
  color,
  smallText = false,
  labelIcon,
  className,
  img,
  inline
}) => {
  if (img) {
    return (
      <div className={ classNames({ 'u-display-inline-block': inline }) }>
        <div
          className={ classNames(
            'c-avatar',
            className,
            size,
            bg,
            color,
            { 'u-display-inline-block': inline }
          ) }
        >
          <File file={ img } width={60} height={60} fit="cover" />
        </div>
        {
          text &&
          <div
            className={ classNames(
              'u-text-align-center',
              {
                'u-font-size-xsmall': smallText
              }
            ) }
          >
            { text }
          </div>
        }
      </div>
    )
  }
  return (
    <div className={ classNames({ 'u-display-inline-block': inline }) }>
      <div
        className={
          classNames(
            'c-avatar',
            className,
            size,
            bg,
            color
          )
        }
      >
        <div className='c-avatar__label'>
          { label }
          {
            !label && labelIcon && <FontAwesome name={ labelIcon } size='2x'/>
          }
        </div>
      </div>
      {
        closeButton &&
        <FontAwesome
          name='remove'
          className='u-vertical-align-top u-color-grey u-position-absolute'
          onClick={ closeButton }
        />
      }
      {
        text &&
        <div
          className={
            classNames('u-text-align-center',
              {
                'u-font-size-xsmall': smallText
              }
          )
          }
        >
          { text }
        </div>
      }
    </div>
  )
}

export default Avatar