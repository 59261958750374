import { LoginRequest, PasswordRequest } from 'client/features/session/actions'
import { ISSUE_OWNER_CHANGE, NewIssueFilesRequest, RecurrencePreviewRequest, NearsRequest, ServiceFilesRequest } from 'client/features/issues/actions'
import { reducer as formReducer } from 'redux-form'

const reducer = formReducer.plugin({
  login: (state, action) => {
    switch(action.type) {

    case LoginRequest.post.TYPES.FAILURE:
      return { ...state, error: action.payload.error }

    default:
      return state
    }
  },
  requestPassword: (state, action) => {
    switch(action.type) {

    case PasswordRequest.post.TYPES.FAILURE:
      return { ...state, error: action.payload.error }

    default:
      return state
    }
  },
  issueOwner: (state, action) => {
    switch(action.type) {

    case ISSUE_OWNER_CHANGE:
      return { values: { owner: action._id } }

    default:
      return state
    }
  },
  issue: (state, action) => {
    switch(action.type) {
    case NewIssueFilesRequest.post.TYPES.SUCCESS:
      return {
        ...state,
        values: {
          ...state.values,
          pictures: addFiles(state.values.pictures, action.payload)
        }
      }

    case RecurrencePreviewRequest.post.TYPES.SUCCESS:
      return {
        ...state,
        recurrencePreview: action.payload
      }

    case NearsRequest.get.TYPES.SUCCESS:
      return {
        ...state,
        nears: action.payload.issues.map((issue) => issue._id)
      }

    default:
      return state
    }
  },
  service: (state, action) => {
    switch(action.type) {
    case ServiceFilesRequest.post.TYPES.SUCCESS:
      return {
        ...state,
        values: {
          ...state.values,
          files: addFiles(state.values.files, action.payload)
        }
      }

    default:
      return state
    }
  }
})

export default reducer

const addFiles = (files = [], payload) => {
  files = files.concat(payload.files)
  return [...files]
}