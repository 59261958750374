import React from 'react'
import _ from 'lodash'
import LogDay from 'client/features/issues/components/details/history/LogDay.jsx'
import Loader from 'client/features/common/components/Loader.jsx'

const IssueHistoryLogs = ({ logs, loading, issue, goToIssue }) => {
    if (loading)
        return (
            <div style={ { marginBottom: '70px' } }>
                <Loader small/>
            </div>
        )
    
    return (
        <div>
            {
                _.sortBy(Object.keys(logs), (key) => key * -1).map((date) =>
                    <LogDay
                        key={ date }
                        date={ date }
                        logs={ logs }
                        issue={ issue }
                        goToIssue={ goToIssue }
                    />
                )
            }
        </div>
    )
}
    

export default IssueHistoryLogs