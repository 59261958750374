import React, 
  { FunctionComponent }     from "react";
import T                    from "@universal/behaviour/i18n";
import Text, { Style }      from "@cComponents/text";

import useService           from "@uBehaviour/hooks/useService";
import SessionService       from "@universal/services/session";
import Nudge                from "@common/components/nudge";
import AssistanceHelper     from "@uHelpers/assistance";
import useHelper            from "@uBehaviour/hooks/useHelper";

import "./userWithoutTenants.css"

const UserWithoutTenants: FunctionComponent = () => {
  const session = useService<SessionService>("session");
  const assistance = useHelper<AssistanceHelper>("assistance");
  const [assistanceEmail, setAssistanceEmail] = React.useState();
  React.useEffect(() => {
    assistance.getSupportUri().then((uri: string) => setAssistanceEmail(uri));
  }, [assistance]);

  const supportLink = React.useMemo(() =>  (
    <a href={ assistanceEmail } target="_blank" rel="noopener noreferrer">
      <Text style={ Style.orange }> <T>user_without_tenant_contact_support_prompt</T> </Text>
    </a>
  ), [assistanceEmail]);

  return (
    <div className="bs-userWithoutTenants-container">
      <div className="bs-userWithoutTenants-content">
        <img src={`/images/logos/betterstreet_color_text_vertical.svg`} alt="Logo BetterStreet" />
        <Nudge type="warning"> <T>user_without_tenant_info</T> </Nudge>
        <Text style={ Style.orange } onClick={ () => session.logout() }>
          <T>user_without_tenant_try_another_user</T>
        </Text>
        <Text style={ Style.center }><T bind={{ supportLink }}>user_without_tenant_contact_support</T></Text>
      </div>
    </div>
  );
}

export default UserWithoutTenants;