// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-hiddenString {
  display: flex;
  align-items: center;
}

.bs-hiddenString>*:first-child {
  flex-grow: 1;
}

.bs-hiddenString>.bs-button-base>.fa {
  margin-left: var(--std-size-inter-element);
  color: var(--std-color-gray-dark);
}`, "",{"version":3,"sources":["webpack://./../common/components/hiddenString.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,0CAA0C;EAC1C,iCAAiC;AACnC","sourcesContent":[".bs-hiddenString {\n  display: flex;\n  align-items: center;\n}\n\n.bs-hiddenString>*:first-child {\n  flex-grow: 1;\n}\n\n.bs-hiddenString>.bs-button-base>.fa {\n  margin-left: var(--std-size-inter-element);\n  color: var(--std-color-gray-dark);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
