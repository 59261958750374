import React, { ComponentType, FunctionComponent, PropsWithChildren, ReactElement } from "react";


import "./selected.css";
type Action<T> = {
  onRemove?: (data: T) => void;
}

type SelectedProps<T> = Action<T> & {
  data: T;
}

function Selected <T = any>({ data, onRemove, children }: PropsWithChildren<SelectedProps<T>>): ReactElement<PropsWithChildren<SelectedProps<T>>> {
  const remove = React.useMemo(() => {
    if (!onRemove) {
      return null;
    }
    return (
      <div className="bs-item-selected-action-remove" onClick={() => onRemove(data) }>
        <span className="fa fa-times" />
      </div>
    );
  }, [data, onRemove]);

  const actions = React.useMemo(() => {
    if(!remove){
      return null;
    }
    return (
      <div className="bs-item-selected-actions">
      { remove }
      </div>
    );
  }, [remove]);


  return (
    <div className="bs-item-selected">
      <div className="bs-item-selected-content">
      { children }
      </div>
      { actions }
    </div>
  );
}

export function withSelected <T extends { data: Y }, Y>(Component: ComponentType<T>): FunctionComponent<SelectedProps<Y> & T> {
  return (props) => {
    const { data, onRemove } = props;
    return (
      <Selected data={ data } onRemove={ onRemove }>
        <Component { ...props } data={ data }/>
      </Selected>
    ); 
  }
}

export default Selected;