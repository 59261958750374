import _ from 'lodash'
import moment from 'moment'
import { cloneDeep } from 'lodash'
import * as HomeActions from 'client/features/home/actions'
import { registerDefaultReducer, registerInitialstate } from "client/lib/reduxSaga";

export const initialState = {
  newIssues: {},
  deadlineInNextWeek: {},
  scheduledToday: {},
  deadlineOrScheduledExpired: {},
  weather: [],
  loaded: false,
  weatherLoaded: false,
  initialized: false,
  logs: [],
  loading: false,
  logsLoading: false,
  allLogsLoaded: false,
  filters: {
    type: 'me',
    owner: []
  }
}

const initializeState = () => ({ ...initialState })

registerInitialstate("home", initializeState);
registerDefaultReducer("home", function home(state = initializeState(), action = {}) {
  switch (action.type) {

  case HomeActions.HomeRequest.get.ACTION:
    return {
      ...state,
      newIssues: {},
      deadlineInNextWeek: {},
      scheduledToday: {},
      deadlineOrScheduledExpired: {},
      initialized: true,
      loading: true
    }

  case HomeActions.HomeRequest.get.TYPES.SUCCESS:
    return {
      ...state, ...action.payload.grouped, loaded: true, loading: false
    }

  case HomeActions.HomeWeatherRequest.get.TYPES.SUCCESS:
    return {
      ...state, weather: action.payload.weather, weatherLoaded: true
    }

  case HomeActions.HomeLogsRequest.get.ACTION:
    return {
      ...state, logsLoading: true, logs: !action.payload.skip ? [] : state.logs, allLogsLoaded: true
    }

  case HomeActions.HomeLogsRequest.get.TYPES.SUCCESS:
    return {
      ...state, logs: addLogs(state.logs, cloneDeep(action.payload.logs)), logsLoading: false, allLogsLoaded: action.payload.logs.length === 0
    }

  case HomeActions.SET_HOME_FILTERS:
    return {
      ...state, filters: setFilters(action, state.filters)
    }

  case HomeActions.HomeIssueMatch.get.TYPES.SUCCESS:
    return checkUpdate(state, action)

  case HomeActions.HomeDeadlineIssuesRequest.get.TYPES.REQUEST:
    return {
      ...state,
      deadlineOrScheduledExpiredLoading: true
    }
  case HomeActions.HomeDeadlineIssuesRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      deadlineOrScheduledExpired: groupBy(action.payload.issues, 'deadline'),
      deadlineOrScheduledExpiredLoading: false
    }
  case HomeActions.HomeLastIssuesRequest.get.TYPES.REQUEST:
    return {
      ...state,
      newIssuesLoading: true
    }
  case HomeActions.HomeLastIssuesRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      newIssues: groupBy(action.payload.issues, 'created'),
      newIssuesLoading: false
    }
  case HomeActions.HomeDeadlineInNextWeekIssuesRequest.get.TYPES.REQUEST:
    return {
      ...state,
      deadlineInNextWeekLoading: true
    }
  case HomeActions.HomeDeadlineInNextWeekIssuesRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      deadlineInNextWeek: groupBy(action.payload.issues, 'deadline'),
      deadlineInNextWeekLoading: false
    }
  case HomeActions.HomePlannedTodayIssuesRequest.get.TYPES.REQUEST:
    return {
      ...state,
      scheduledTodayLoading: true
    }
  case HomeActions.HomePlannedTodayIssuesRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      scheduledToday: groupBy(action.payload.issues, 'scheduledFrom'),
      scheduledTodayLoading: false
    }
  case HomeActions.CLEAR_HOME_LOGS:
    return {
      ...state,
      logs: []
    }

  default:
    return state
  }
});

function groupBy(issues, key, startDate, endDate) {
  const result = {}
  issues.forEach(function (issue) {
    const date = moment(issue[key]).startOf('day')
    if (startDate && new Date(issue.scheduledFrom).getTime() !== new Date(issue.scheduledTo).getTime()) {
      const dates = []
      for (let i = new Date(issue.scheduledFrom).getTime(); i <= new Date(issue.scheduledTo).getTime(); i += 86400000) {
        if (i >= new Date(startDate).getTime() && i <= new Date(endDate).getTime()) {
          dates.push(new Date(i))
        }
      }
      dates.forEach(function (date) {
        if (!result[date]) result[date] = [issue._id]
        else result[date].push(issue._id)
      })
    } else {
      if (!result[new Date(date)]) result[new Date(date)] = [issue._id]
      else result[new Date(date)].push(issue._id)
    }
  })
  return result
}


const addLogs = (all = [], logs = []) => {
  logs.forEach((log) => {
    if (log.issue)
      log.issue = cloneDeep(log.issue._id)
    all.push(log)
  })
  return [...all]
}

const setFilters = (action, filters) => {
  if (action.key === 'owner') {
    if (!filters.owner)
      filters.owner = []
    const indexOfOwner = filters.owner.indexOf(action.value)
    if (indexOfOwner >= 0)
      filters.owner.splice(indexOfOwner, 1)
    else
      filters.owner.push(action.value)
  } else {
    filters[action.key] = action.value
  }

  return { ...filters }
}

const checkUpdate = (state, action) => {
  if (!state.loaded)
    return { ...state }
  for (const list in action.payload.grouped) {
    const data = action.payload.grouped[list]
    for (const date in state[list]) {
      const indexOfIssue = state[list][date].indexOf(action.payload.issueMatch)
      if (indexOfIssue !== -1)
        state[list][date].splice(indexOfIssue, 1)
    }
    for (const date in data) {
      if (data[date][0]) {
        if (!state[list][date])
          state[list][date] = []
        state[list][date] = state[list][date].concat(data[date])
      }
    }
    for (const date in state[list]) {
      if (!state[list][date][0])
        delete state[list][date]
    }
  }
  return { ...state }
}