import React        from "react";
import Form         from "@uBehaviour/form";
import Slot         from "@cComponents/slot";
import T            from "@uBehaviour/i18n";
import Display      from "@uComponents/displayIf";
import NewCError    from '@cComponents/error_new';

import "./field.css";
import classNames from "@universal/lib/classNames";

const Label   = Slot();
const Input   = Slot();

const DisplayTableContext = React.createContext(false);

const FieldTable = ({ children }) => (
  <DisplayTableContext.Provider value={ true }>
    <div className="bs-field-table">
    { children}
    </div>
  </DisplayTableContext.Provider>
);

const FieldUntable = ({ children }) => (
  <DisplayTableContext.Provider value={ false }>
  { children}
  </DisplayTableContext.Provider>
);

const DisplayField = ({ required, labelClassName, inputClassName, errors = [], noError, ...props }) => {
  const displayTable = React.useContext(DisplayTableContext);
  return (
    <div className="bs-field">
      <div className={ `bs-field-content ${ displayTable ? 'bs-field-content-column' : 'bs-field-content-row' }`}>
        <div className={ classNames("bs-field-label").addNotEmpty(labelClassName) }>
        { Label.get({ props }) }{required ? " *" : ""}
        </div>
        <div className={ classNames("bs-field-input").addNotEmpty(inputClassName)}>
          <div className={ classNames("bs-field-input-content")}>
          { Input.get({ props }) }
          </div>
        </div>
      </div>
      <Display.If condition={errors.length && !noError}>
        <NewCError errors={errors} />
      </Display.If>
    </div>
  );
};

const Base = (props) => React.createElement(Form.Simple.InputAdapter, { name: props.name, multiple: props.multiple, required:props.required } , function(...args){
  return React.createElement(Form.Simple.ErrorAdapter, { name: props.name }, errors => (
    <DisplayField { ...props } errors={ errors }>
      <Label>{ Label.get({ props }) }</Label>
      <Input>{ Input.get({ props }).apply(null, args) }</Input>
    </DisplayField>
  ));
});

const InputFactory = (multiple, input) => multiple
  ? (value, add, drop, set, clear) => React.cloneElement(input, {
      value: value,
      onChange: (values, value, idx) => {
        parseInt(idx) === idx ? set(idx, value) : idx ? add(value) : drop(values.indexOf(value))
        if(input.props.onChange){
          input.props.onChange(values, value, idx);
        }
      },
      clear
    })
  : (value, set, clear) => React.cloneElement(input, {
      value: value,
      onChange: value => {
        set(value);
        if(input.props.onChange){
          input.props.onChange(value);   
        }
      },
      clear
    });

const Standart = (props) => (
  <Base name={ props.name } multiple={ props.multiple } required={props.required} inputClassName={ props.inputClassName } labelClassName={ props.labelClassName } noError={ props.noError }>
    <Label>{ Label.get({ props }) }</Label>
    <Input>{ InputFactory(props.multiple, Input.get({ props })) }</Input>
  </Base>
);
const ShortContext = React.createContext();
const ShortLabelContext = (props) => React.createElement(ShortContext.Provider, { value: props.prefix }, props.children);
const Short = ({ name, multiple, required, inputClassName, labelClassName, noError, children, ...props}) => React.createElement(ShortContext.Consumer, null, prefix => (
  <Standart name={ name } multiple={ multiple } required={required} inputClassName={ inputClassName } labelClassName={ labelClassName } noError={ noError }>
    <Label><T>{ prefix + "_label_" + name.replace(".", "_") }</T></Label>
    <Input>{ children }</Input>
  </Standart>
));

export default {
  Display: DisplayField,
  Base,
  Standart,
  Input,
  Label,
  ShortLabelContext,
  Short,
  Table: FieldTable,
  Untable: FieldUntable,
}