import useService from "../behaviour/hooks/useService";
import RepositoryService from "../services/repository";
import { BusinessEntity, Loader } from "../types/technic/Entityable";
import ObjectId from "../types/technic/ObjectId";

type Repository<Type, TypeName extends string> = {
  find<Loaded extends Loader>(query?: object, sort?: object, start?: number, nbr?: number, load?: Loaded): Promise<BusinessEntity<Type, Loaded>[]>,
  hasInCache(data: Type): boolean,
  get(ids: { _id: ObjectId<TypeName> }[]):  Promise<BusinessEntity<Type, {}>[]>,
  findOne<Loaded extends Loader>(id: ObjectId<TypeName>, load?: Loaded): Promise<BusinessEntity<Type, Loaded>>,
  count(query: object): Promise<number>,
}

const useRepository = <Type, TypeName>(name: TypeName): Repository<Type, TypeName extends string ? TypeName: never> => {
  const repositorySerivce = useService<RepositoryService>("repository");
  return repositorySerivce.get(name).repository as Repository<Type, TypeName extends string ? TypeName: never>;
}

export default useRepository;