import { Service }    from "@uLib/application";


interface Clipboard {
  isEnabled: () => boolean;
  copytText: (value: string) => Promise<boolean>;
}

class ClipboardWithPermission implements Clipboard {
  isEnabled(){
    return true;
  }
  async copytText(value: string): Promise<boolean> {
    try {
      await window.navigator.clipboard.writeText(value);
    } catch(err) {
      return false;
    }
    return true;
  }
}

class ClipboardWithoutPermission implements Clipboard {
  isEnabled(){
    return false;
  }
  async copytText(value: string): Promise<boolean> {
    return false;
  }
}


class ClipboardService extends Service {
  private clipboard: Clipboard;
  constructor(){
    super("clipboard");
  }

  async start(): Promise<void>{
    let valid = false;
    try{
      const result = await window.navigator.permissions.query({ name: "clipboard-write" });
      valid = result.state === "granted" || result.state === "prompt";      
    }catch(e){
      valid = !!window.navigator;
    }
    if(valid) {
      this.clipboard = new ClipboardWithPermission();
    } else {
      this.clipboard = new ClipboardWithoutPermission();
    }
  }

  isEnabled(): boolean {
    return this.clipboard.isEnabled();
  }

  async copyText(value: string): Promise<boolean> {
    return this.clipboard.copytText(value);
  }
}

export default ClipboardService;