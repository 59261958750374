import React from 'react'

import { isAuthorized } from 'client/features/issues/lib'

import Tag from 'client/features/issues/components/details/Tag.jsx'

const renderBsId = (issue, user) => {
  if (!user.admin && (!user.resource.slaves || !user.resource.slaves[0]))
    return `${issue.bsIdNumber}`
  return `${issue.bsId}`
}

const IssueDetailsMainTitle = ({ issue, session, confirmations = {}, tagChange, displayStateModal }) => (
  <h5 className='c-issue-detail-title'>
    <span className='c-issue-id'>
      { renderBsId(issue, session.user) }&nbsp;
    </span>
    <Tag
      issue={ issue }
      tags={ issue.type && session.tags && session.tags[issue.type] }
      onChange={ tagChange }
      confirmation={ confirmations.tag }
      disabled={ !isAuthorized(issue, 'tag') }
      muted
    />
  </h5>
)

export default IssueDetailsMainTitle