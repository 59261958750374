const _ = require('lodash')
const { Client } = require("@googlemaps/google-maps-services-js")

const __CONFIG = window.__CONFIG;

class BsLocation {
  static async reverseGeocode(latitude, longitude) {
    const googleMapsClient = new Client({})
    if (!latitude || !longitude)
      throw new Error('reverseGeocode: missing latitude or longitude')
  
    try {
      const response = await googleMapsClient.reverseGeocode({ params: { latlng: `${latitude},${longitude}`, key: __CONFIG.gmap.key }})
      if (!response.json.results)
        throw new Error('reverseGeocode: no data found')
      return {
        latitude,
        longitude,
        formattedAddress: response.json.results[0].formatted_address,
        ...BsLocation.getAddressFromGMComponents(response.json.results[0].address_components)
      }
    } catch (err) {
      throw err
    }
  }

  static getAddressFromGMComponents(addressComponents) {
    var result = {}
    const contain = (types, string) => types.some(type => type === string)
    addressComponents.forEach(function (entry) {
        if (contain(entry.types, 'locality')) result.locality = entry.long_name
        if (contain(entry.types, 'postal_code')) result.zip = entry.long_name
        if (contain(entry.types, 'street_number')) result.streetNumber = entry.long_name
        if (contain(entry.types, 'intersection')) result.street = entry.long_name
        if (contain(entry.types, 'route')) result.street = entry.long_name
        if (contain(entry.types, 'country'))result.country = entry.short_name.toLowerCase()
    })
    return result
  }
}

export default BsLocation