// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-newEntry {
  height: 100%;
  display: flex;
  align-items: center;
  padding: 12px 24px;
  border-bottom: 3px solid rgba(0, 0, 0, 0);
}
.bs-issues-newEntry-icon {
  margin-left: var(--std-size-inter-element);
  color: var(--std-color-orange);
  font-size: var(--std-font-size-large);
}
.bs-issues-newEntry-label{
  text-transform: uppercase;
  color: var(--std-color-gray-light);
  font-size: var(--std-font-size-small);
  font-weight: var(--std-font-weight-semi-bold);
}
.bs-issues-newEntry:hover {
  cursor: pointer;
  background-color: rgba(232, 76, 36, 0.05);
  border-bottom: 3px solid var(--std-color-orange);
}

.bs-issue-newEntry-form-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.bs-issue-newEntry-form-footer>* {
  margin-left: var(--std-size-inter-element);
}`, "",{"version":3,"sources":["webpack://./src/entities/issue/newEntry.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,kBAAkB;EAClB,yCAAyC;AAC3C;AACA;EACE,0CAA0C;EAC1C,8BAA8B;EAC9B,qCAAqC;AACvC;AACA;EACE,yBAAyB;EACzB,kCAAkC;EAClC,qCAAqC;EACrC,6CAA6C;AAC/C;AACA;EACE,eAAe;EACf,yCAAyC;EACzC,gDAAgD;AAClD;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,yBAAyB;AAC3B;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".bs-issues-newEntry {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  padding: 12px 24px;\n  border-bottom: 3px solid rgba(0, 0, 0, 0);\n}\n.bs-issues-newEntry-icon {\n  margin-left: var(--std-size-inter-element);\n  color: var(--std-color-orange);\n  font-size: var(--std-font-size-large);\n}\n.bs-issues-newEntry-label{\n  text-transform: uppercase;\n  color: var(--std-color-gray-light);\n  font-size: var(--std-font-size-small);\n  font-weight: var(--std-font-weight-semi-bold);\n}\n.bs-issues-newEntry:hover {\n  cursor: pointer;\n  background-color: rgba(232, 76, 36, 0.05);\n  border-bottom: 3px solid var(--std-color-orange);\n}\n\n.bs-issue-newEntry-form-footer {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-end;\n}\n\n.bs-issue-newEntry-form-footer>* {\n  margin-left: var(--std-size-inter-element);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
