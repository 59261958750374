import React, { FunctionComponent } from 'react';
import T from '@universal/behaviour/i18n';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import UserType, { isUserPro } from '@uTypes/business/User';
import useService from '@universal/behaviour/hooks/useService';
import CurrentTenantService from '@universal/services/currentTenant';
import Tenant from '@universal/types/business/Tenant';
import AclService from '@universal/services/acl';

import './details.css';


type DetailsProps = {
  user: BusinessEntity<UserType>
}

const Details: FunctionComponent<DetailsProps> = ({ user }) => {
  const currentTenantService = useService<CurrentTenantService>("currentTenant");
  const aclService = useService<AclService>("acl");
  
  const tenantsConcerned = React.useMemo(() => {
    if (!isUserPro(user)) {
      return [];
    }
    //Si consultation en mode super-admin, sans structure sélectionnée, on affiche les rôles et limitations bâtiment de l'utilisateur pour l'ensemble des structures
    return currentTenantService.currentId 
      ? user.tenants.filter(tenant => (tenant.tenant as Tenant)._id === currentTenantService.currentId)
      : user.tenants;
  }, [ user, currentTenantService.currentId ]);
  
  const roles = React.useMemo(() => {
    const uniqRoles = _.uniq(tenantsConcerned.reduce((acc, tenant) => {
      acc = acc.concat(tenant.roles.toPlainText());
      return acc;
    }, []));

    return uniqRoles.map((role, idx) => (
      <span key={role}>{idx > 0 ? ", " : ""}<T>{`user_item_pro_role_${role}`}</T></span>
    ));
  }, [ tenantsConcerned.map(t => t.roles.join("|")).join("|") ]);

  const displayBuildingLimitation = React.useMemo(() => {
    return tenantsConcerned.some(t =>
      aclService.isAllow("issues", "createOnBuilding", user.toPlainText(), t.tenant._id) || 
      aclService.isAllow("issues", "readBuilding", user.toPlainText(), t.tenant._id)
    );
  }, [ tenantsConcerned, user, aclService ]);

  const buildingLimitation = React.useMemo(() => {
    return tenantsConcerned.some(tenant => tenant.buildings.ids.length || tenant.buildings.tags.length)
      ? <T>user_item_building_limitation_limited</T>
      : <T>user_item_building_limitation_all</T>

  }, [ tenantsConcerned ]);
  const displayRoles = React.useMemo(() => !!tenantsConcerned.length, [ tenantsConcerned ]);
  return (
    <div className="bs-user-details">
      { displayRoles && (
        <div>
          <div className="bs-user-details-title"><T>user_item_description_roles</T></div>
          <div>{ roles }</div>
        </div>
      )}
      { displayBuildingLimitation && (
        <div>
          <div className="bs-user-details-title"><T>user_item_description_buildings</T></div>
          <div>{ buildingLimitation }</div>
        </div>
      )}
    </div>
  )
}

export default Details;