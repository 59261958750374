// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-input-autocomplete-display{
  position: relative;
}
.bs-input-autocomplete-display-clear {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bs-input-autocomplete-display-clear>.fa:hover {
 cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/autocomplete.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;EACR,YAAY;EACZ,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;CACC,eAAe;AAChB","sourcesContent":[".bs-input-autocomplete-display{\n  position: relative;\n}\n.bs-input-autocomplete-display-clear {\n  position: absolute;\n  right: 0px;\n  top: 0px;\n  height: 100%;\n  width: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.bs-input-autocomplete-display-clear>.fa:hover {\n cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
