// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-assignments-lists-header {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bs-assignments-lists-header>div {
  width: 66%;
}

.bs-assignments-lists-header .bs-select input{
  background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/applications/stdExp/views/assignments/lists.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,uBAAuB;AACzB","sourcesContent":[".bs-assignments-lists-header {\n  width: 100%;\n  display: flex;\n  justify-content: center;\n}\n\n.bs-assignments-lists-header>div {\n  width: 66%;\n}\n\n.bs-assignments-lists-header .bs-select input{\n  background-color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
