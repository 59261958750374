import React, { useState }  from "react";
import T                    from "@uBehaviour/i18n";
import FocusBlur            from "@cComponents/focusBlur.jsx";

import "./workInstructions.css";

class WorkInstructionsInput extends React.Component {
  constructor(props) {
    super(props);
    this._validateChange  = this._validateChange.bind(this);
    this._close           = this._close.bind(this);
    this._ref             = React.createRef();
  }
  _validateChange(){
    if(this.props.onChange){
      const value = this._ref.current.value;
      this.props.onChange(value);
    }
    this._close();
  }
  _close(){
    this.props.close();
  }
  componentDidMount() {
    this._ref.current.click();
    this._ref.current.focus();
  }
  render(){
    const { value } = this.props;
    return (
      <FocusBlur className="bs-workInstructions-write" onBlur={ this._close }>
        <textarea ref={ this._ref } defaultValue={ value ? value : ""} />
        <div>
          <span className="bs-button bs-button-cancel" onClick={ this._close }><T>assignment_workInstructions_cancel</T></span>
          <span className="bs-button bs-button-save" onClick={ this._validateChange }><T>assignment_workInstructions_save</T></span>
        </div>
      </FocusBlur>
    )
  }
};

const WorkInstructionsRead = ({ value, onClick, readOnly }) => {
  return (
    <div className={ `bs-workInstructions-read${value && !readOnly ? " valued" : "" }${ readOnly ? " readOnly" : "" }` } onClick={ () => !readOnly && onClick() }>{ value ? value : <span><T>assignment_workInstructions_add</T></span>}</div>
  );
};

const WorkInstructions = ({ onChange, value, readOnly }) => {
  const [read, setRead] = useState(true);
  return read
    ? <WorkInstructionsRead value={ value } onClick={ () => setRead(false) } readOnly={ readOnly }/>
    : <WorkInstructionsInput value={ value } onChange={ onChange } close={ () => setRead(true) } />
};

export default WorkInstructions;