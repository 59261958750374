import * as ActionTypes from 'client/features/notifications/actions'
import _ from 'lodash'
import { registerDefaultReducer, registerInitialstate } from "client/lib/reduxSaga";

export const initialState = {
  all: [],
  notSeen: 0,
  fetchLimit: 6,
  fetchLoading: false
}

const initializeState = () => Object.assign({}, initialState)

registerInitialstate("notifications", initializeState);
registerDefaultReducer("notifications", function notifications(state = initializeState(), action = {}) {
  switch (action.type) {
  case "notification_refresh_success":
    return {
      ...state,
      all: addNotifications([], action.payload.notifications),
      notSeen: action.payload.notSeen,
      allLoaded: false,
      fetchLimit: 6,
      fetchLoading: false
    }

  case ActionTypes.NotificationsRequest.get.TYPES.REQUEST:
    return {
      ...state,
      fetchLoading: true
    }

  case ActionTypes.NotificationsRequest.put.ACTION:
    return {
      ...state,
      notSeen: action.params.type === 'seen' ? 0 : state.notSeen,
      all: action.params.type === 'read' ? readAll(state.all) : state.all
    }


  case ActionTypes.NotificationsRequest.put.TYPES.SUCCESS:
    return {
      ...state,
      notSeen: action.payload.type === 'seen' ? 0 : state.notSeen,
      all: action.payload.type === 'readAll' ? readAll(state.all) : state.all
    }

  case ActionTypes.NotificationsRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      all: addNotifications(state.all, action.payload.notifications),
      notSeen: action.payload.notSeen || 0,
      allLoaded: action.payload.notifications.length < state.fetchLimit,
      fetchLoading: false
    }

  case ActionTypes.NotificationRequest.get.TYPES.SUCCESS:
    return {
      ...state,
      all: addNotification(state.all, action.payload.notification),
      notSeen: action.payload.notSeen ? action.payload.notSeen : state.notSeen
    }

  case ActionTypes.NotificationRequest.put.TYPES.SUCCESS:
    return {
      ...state,
      all: addNotification(state.all, action.payload.notification)
    }

  default:
    return state
  }
});

const addNotifications = (all, notifications) => {
  notifications = _.cloneDeep(notifications)
  notifications.forEach(notification => {
    if (notification.issue && notification.issue._id)
      notification.issue = notification.issue._id
  })
  return all.concat(notifications);
}
const addNotification = (all, notification) => {
  notification = _.cloneDeep(notification)
  if (notification.issue && notification.issue._id)
    notification.issue = notification.issue._id
  let found = false;
  all.forEach(notif => {
    if(notif._id === notification._id){
      found = true;
      Object.assign(notif, notification);
    }
  });
  if(found){
    return all;
  }

  return [...all, notification]
}

const readAll = (all) => {
  all.forEach(item => item.read = true)
  return _.cloneDeep(all)
}