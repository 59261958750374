// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-valorizations-serviceStatement-files {
  display: flex;
  gap: 5px;
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/entities/valorizations/serviceStatementFiles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;EACR,mBAAmB;AACrB","sourcesContent":[".bs-valorizations-serviceStatement-files {\n  display: flex;\n  gap: 5px;\n  flex-direction: row;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
