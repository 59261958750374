import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import Slot from '@uComponents/slot2';
import { Grid } from 'semantic-ui-react';
import Cost from './cost';
import { Statement } from '@universal/types/business/Valorization';
import useService from '@universal/behaviour/hooks/useService';
import AclService from '@universal/services/acl';
import Display from '@common/components/displayIf';

const Subject = Slot();
const Occurence = Slot();

interface  StatementLayoutProps {
  displayCostResource: string;
  displayCostAction: string;
  statement: Statement;
}

const StatementLayout: FunctionComponent<PropsWithChildren<StatementLayoutProps>> & { Subject, Occurence } = ({ children, displayCostResource, displayCostAction, statement }) => {
  const acl = useService<AclService>('acl');

  const displayCost = React.useMemo(() => {
    return acl.connectedUserIsAllow(displayCostResource, displayCostAction);
  }, [acl, displayCostResource, displayCostAction]);

  const subject = Subject.get(children);
  const occurence = Occurence.get(children);
  return (
    <Grid.Row>
      <Grid.Column width="7">{ subject }</Grid.Column>
      <Grid.Column width={ displayCost ? 3 : 9 } textAlign='center'>{ occurence }</Grid.Column>
      <Display.If condition={ displayCost }>
        <Cost cost={ statement.cost } unitCost={ statement.unitCost }/>
      </Display.If>
    </Grid.Row>
  );
};

StatementLayout.Subject = Subject;
StatementLayout.Occurence = Occurence;

export default StatementLayout;