// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-input-textarea {
  padding: 5px 10px;
  box-sizing: border-box;
  position: relative;
  margin: 0;
  border: 1px solid #e3dfdc;
  background: #f2eeeb;
  color: #666;
  font-size: 12px;
  font-weight: 550;
  border-radius: 3px;
  width: 100%;
  resize: none;
}

.bs-old-input-textarea:focus {
  border-color:rgba(82,168,236,0.8);
  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 5px rgba(82,168,236,0.6);
}`, "",{"version":3,"sources":["webpack://./../common/components/old/input/textarea.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,kBAAkB;EAClB,SAAS;EACT,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,iCAAiC;EACjC,2EAA2E;AAC7E","sourcesContent":[".bs-old-input-textarea {\n  padding: 5px 10px;\n  box-sizing: border-box;\n  position: relative;\n  margin: 0;\n  border: 1px solid #e3dfdc;\n  background: #f2eeeb;\n  color: #666;\n  font-size: 12px;\n  font-weight: 550;\n  border-radius: 3px;\n  width: 100%;\n  resize: none;\n}\n\n.bs-old-input-textarea:focus {\n  border-color:rgba(82,168,236,0.8);\n  box-shadow: inset 0 1px 1px rgba(0,0,0,0.075), 0 0 5px rgba(82,168,236,0.6);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
