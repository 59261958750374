import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import TextareaField from 'client/features/form/old/components/TextareaField.jsx'
import T from 'i18n-react'
import FontAwesome from 'react-fontawesome'

const validate = values => {
    const errors = {}
    if (!values.message)
        errors.message = T.translate('form_validation_requiredValue')

    return errors
}

class IssuePublicMessageForm extends Component {
    constructor(props) {
        super()
    }

    render() {
        const { handleSubmit, cancel } = this.props
        return (
            <form className='c-issue-detail-public-message-form'>
                <Field name='message' component={ TextareaField } id='new-comment' label=''/>
                <div className='pull-right' style={{ marginTop: 8 }}>
                    <a className='c-button c-button--ghost u-margin-right-small' onClick={ cancel }>
                        { T.translate('cancel') }
                    </a>
                    <button className='c-button c-button--brand' type='submit' onClick={ handleSubmit }>
                        { T.translate('confirm_update') }
                    </button>
                </div>
            </form>
        )
    }
}

export default IssuePublicMessageForm = reduxForm({
    form: 'issuePublicMessage',
    initialValues: {},
    validate
})(IssuePublicMessageForm)