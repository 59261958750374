import React from "react";
import "./header.css";

export const MainHeader = ({ children, icon }) => (
  <div className="bs-planning-header-main">
    { icon + "" === icon ? (<span className={`fa fa-${icon}`} />) : icon }
    { children }
  </div>
);

export const SecondaryHeader = ({ children, icon }) => (
  <div className="bs-planning-header-secondary">
    { icon + "" === icon ? (<span className={`fa fa-${icon}`} />) : icon }
    { children }
  </div>
);