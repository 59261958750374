import React, { FunctionComponent, MouseEvent, ReactNode } from "react";
import useOpenCloseToggle           from "@universal/behaviour/hooks/useOpenCloseToggle";
import useService                   from "@universal/behaviour/hooks/useService";
import LoginAsManagerService        from "@universal/services/loginAsSessionManager";
import UserList                     from "@entities/user/loginAsList"
import Button                       from "@common/components/button";
import Display                      from "@cComponents/displayIf";
import Modal                        from "@common/components/modal";
import T                            from "@universal/behaviour/i18n";

import "./loginAs.css";
import ObjectId from "@universal/types/technic/ObjectId";
import User from "@universal/types/business/User";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import Tenant from "@universal/types/business/Tenant";
import Data from "@universal/behaviour/data";



interface LoginAsProps {
  userId: ObjectId 
}

type TenantConfiguration = {
  tenant: BusinessEntity<Tenant>,
  roles: string[]
};

const LoginAs: FunctionComponent<LoginAsProps> = ({ userId }) => {
  const loginAsSessionManager = useService<LoginAsManagerService>('loginAsSessionManager');

  const loginAs = React.useCallback(() => {
    loginAsSessionManager.login(userId);
  }, [loginAsSessionManager, userId]);

  const removeFavoriteUser = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    loginAsSessionManager.removeAsFavorite(userId);
  }, [loginAsSessionManager, userId]);



  return (
    <Data.One id={userId} model="User" load={{ "tenants.tenant": true }} >
    {(user: BusinessEntity<User, { "tenants.tenant": Loader }>) => (
      <Button.Display onClick={ loginAs }>
        <div className="bs-connected-favorite-user">
          <div>
            <div className="bs-connected-favorite-user-fullname">{ user.fullname }</div>
            {user.tenants?.map((t: TenantConfiguration) => (
              <div key={ t.tenant._id } className="bs-connected-favorite-user-roles">
              {`${t.tenant.name.fr} : ${t.roles.join(",")}`}
              </div>
            ))}
          </div>
          <div className="fa fa-times fa-lg bs-connected-favorite-user-drop" onClick={ removeFavoriteUser } />
        </div>
      </Button.Display>
    )}
    </Data.One>
  );
}

const SelectUserLoginAs: FunctionComponent = () => {
  const [showLoginAsUserListModal, openLoginAsUserListModal, closeLoginAsUserListModal] = useOpenCloseToggle(false);
  return (
    <>
      <Button.Display onClick={openLoginAsUserListModal}>
        <T>connected_user_loginAs</T>
      </Button.Display>
      <Display.If condition={showLoginAsUserListModal}> 
        {() => (
          <Modal.Show key="modal" close={ closeLoginAsUserListModal } style={{ width: "80vw", height: "80vh" }}>
            <UserList />
          </Modal.Show>
        )}
      </Display.If>
    </>
  );
}


const BackToSuperAdmin: FunctionComponent = () => {
  const loginAsSessionManager = useService<LoginAsManagerService>("loginAsSessionManager");
  
  const backToSuperAdmin = React.useCallback(() => {
    loginAsSessionManager.backToSuperAdmin();
  }, [loginAsSessionManager]);

  return (
    <Button.Display onClick={ backToSuperAdmin }>
      <T>connected_user_backToSuperAdmin</T>
    </Button.Display>
  );
}


const useLoginAs = (): ReactNode[] => {
  const loginAsSessionManager = useService<LoginAsManagerService>("loginAsSessionManager", ["onServiceUpdated"]);

  const loginAs: ReactNode[] = React.useMemo<ReactNode[]>(() => {
    const loginAs: ReactNode[] = [];

    if(loginAsSessionManager.isAllowToLoginAs()){
      loginAs.push(
        <SelectUserLoginAs key="loginAs" />
      );

      loginAsSessionManager.favoritesUsers.map(userId => (
        loginAs.push(
          <LoginAs key={ userId } userId={ userId }/>
        )
      ));
    }

    if(loginAsSessionManager.isLoggedAsTemporarySession()){
      loginAs.push(
        <BackToSuperAdmin key="backToSuperAdmin" />
      );
    }

    return loginAs;
  }, [loginAsSessionManager.favoritesUsers, loginAsSessionManager.isLoggedAsTemporarySession()]);

  return loginAs;
}

export default useLoginAs;