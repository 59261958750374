import React, { PureComponent } from 'react'
import T from 'i18n-react'
import { compose } from 'redux'
import { Modal } from 'react-bootstrap'
import { Field, withFormik } from 'formik'

import TextareaField from 'client/features/form/old/components/fields/Textarea.jsx'
import DropzoneField from 'client/features/form/old/components/DropzoneField.jsx'
import Dropzone from 'client/features/common/components/Dropzone.jsx'
import ISSUE from 'common/ISSUE'

class WorkorderStateModal extends PureComponent {
  constructor() {
    super()
    this.state = {
      uploaded: [],
      files: []
    }
  }

    addFiles = (files) => {
      this.setState({
        files: this.state.files.concat(files)
      }, () => this.addFilesToForm())
    }

    removeFile = (file) => {
      this.setState({
        files: this.state.files.filter(existingFile => existingFile.name !== file.name)
      }, () => this.addFilesToForm())
    }

    addFilesToForm = () => {
      this.props.setFieldValue('files', this.state.files)
    }

    render() {
      const { show, onHide, handleSubmit } = this.props
      return (
        <Modal
          show={ show }
          onHide={ onHide }
          bsSize="large"
          className='modal-helper'
        >
          <Dropzone
            accept={ ISSUE.files.accepted }
            disableClick
            onChange={ this.addFiles }
          >
            <form onSubmit={ handleSubmit }>
              <Modal.Header closeButton className='u-cursor-move'>
                <Modal.Title>
                  { T.translate('workOrder_update_state_title') }
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className='o-layout u-margin-top-small'>
                  <div className='o-layout u-margin-top-small'>
                    <div className='o-layout__item u-12of12 u-padding-left-large'>
                      <Field
                        name='comment'
                        noButtons
                        forceEditMode
                        placeholder='form_comment'
                        component={ TextareaField }
                      />
                    </div>
                    <div className='o-layout__item u-12of12 u-padding-left-large field'>
                      <DropzoneField
                        accept={ ISSUE.files.accepted }
                        meta={ {} }
                        upload={ this.addFiles }
                        uploaded={ this.state.files }
                        removeUpload={ this.removeFile }
                        noProgressBar
                        //uploads={ this.state.files }
                      />
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className='button-a'
                  type='button'
                  onClick={ onHide }
                >
                  { T.translate('cancel') }
                </button>
                <button
                  className='button-a orange'
                  type='submit'
                  onClick={ this.submit }
                >
                  { T.translate('confirm_update') }
                </button>
              </Modal.Footer>
            </form>
          </Dropzone>
        </Modal>
      )
    }
}

export default compose(withFormik({
  mapPropsToValues: (props) => props.initialValues,
  enableReinitialize: true,
  handleSubmit: (values, { props }) => props.onSubmit(values)
}))(WorkorderStateModal)