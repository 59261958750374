import React                from "react";
import _                    from "lodash";
import Field                from "@wComponents/field";
import T                    from "@uBehaviour/i18n";
import WInput               from "client/components/input";
import Input                from "@cComponents/input";
import fetch, { jsonFetch } from "client/lib/fetch";
import SelectTeamsAgents    from "@entities/teamAndAgents/select";
import SelectEquipments     from "./selectEquipments";
import InputSelectSupply    from "./selectSupplies";
import WorkInstructions     from "./workInstructions";
import Period               from "@cComponents/period";
import Supply               from '@entities/supply'
import moment               from "moment";
import Modal                from "@cComponents/modal";
import Display              from "@cComponents/displayIf";
import Acl                  from "@uBehaviour/acl";
import Application          from '@uBehaviour/application';
import Tooltip              from "@cComponents/tooltip";
import Nudge                from "@common/components/nudge"; 
import ErrorDeleteDisplay   from "client/components/assignments/errorDeleteDisplay";
import State from './state';
import usePager from "@universal/behaviour/data/hooks/usePager";
import Button from "@common/components/button";
import Layout from "@common/components/layout";
import Section from "@common/components/section";
import isEmptyAssignment from '@uBusiness/lib/issues/isEmptyAssignment';

import "./assignment.css";

const ModalAlert = ({ reject, accept, children }) => (
  <Modal.Show userCanClose={false}>
  {(close) => (
    <div className="bs-modal-assignments-alert">
      <div>{ children }</div>
      <div>
        <div onClick={() => { reject(); close(); }}><T>no</T></div>
        <div onClick={() => { accept(); close(); }}><T>yes</T></div>
      </div>
    </div>
  )}
  </Modal.Show>
);



const alertIf = (tests, execute, cancel) => {
  tests.reduce((p, { condition, alert }) => {
    return p.then(() => condition())
      .then(({ valid, meta }) => {
        if(valid){
          return true;
        }
        return new Promise((resolve, reject) => {
          alert(resolve, reject, meta);
        });
      });
  }, Promise.resolve())
    .then(
      () => execute(),
      () => cancel()
    );
}

const WarningEquipment = ({ unavailableEquipments }) => {
  const { datas: equipments } = usePager({
    model: "Equipment",
    query: { _id: { $in: unavailableEquipments.map(eu => eu.equipment) }},
    loadAll: true
  });

  if(!equipments.length){
    return null;
  }

  return (
    <div className="bs-issue-assignment-warningEquipment">
      <span> <T>assignment_warningEquipment_already_used_label</T> </span>
      <ul>
      { 
        unavailableEquipments.map(({ equipment, assignments }) => (
          <li key={ equipment } className="bs-issue-assignment-warningEquipment-block">
            <span><b>{ equipments.find(e => e._id === equipment).name }</b> : </span>
            <ul>
              { assignments.map(assignment => ( 
                <li key={ assignment.bsId }>
                  <T bind={ assignment }>assignment_warningEquipment_alreadyUserOnAssignment</T> 
                </li>
              ))}
            </ul>
          </li>
        ))
      }
      </ul>
      <span><T>assignment_warningEquipment_areYouSure</T></span>
    </div>
  );
}

class Assignment extends React.Component {
  constructor(props) {
    super(props);
    this._getTeamsAndAgents   = this._getTeamsAndAgents.bind(this);
    this._putTeamsAndAgents   = this._putTeamsAndAgents.bind(this);
    this._getEquipements      = this._getEquipements.bind(this);
    this._putSupplies         = this._putSupplies.bind(this);
    this._onSupplyEditHandler = this._onSupplyEditHandler.bind(this);
    this._onSupplyCreateHandler = this._onSupplyCreateHandler.bind(this);
    this._putEquipments       = this._putEquipments.bind(this);
    this._putWorkInstructions = this._putWorkInstructions.bind(this);
    this._onPeriodChange      = _.debounce(this._onPeriodChange.bind(this), 1000);
    this._delete              = this._delete.bind(this);
    this._print               = this._print.bind(this);
    this._selectSupply        = React.createRef();
    this._formSupplyRef       = React.createRef();
    this.state                = { showSupplyModal: false, initialValues: {}, modalAlert: { display: false }, modalErrorDelete: false };
  }
  _save(assignment){
    let endpoint
    if(this.props.assignment._id){
      endpoint = {
        url: `/issues/${ this.props.issue }/assignments/${ this.props.assignment._id }`,
        method: "PATCH"
      };
    }else{
      endpoint = {
        url: `/issues/${ this.props.issue }/assignments/`,
        method: "POST"
      };
    }
    return fetch(endpoint.url, {
      body: JSON.stringify(assignment),
      headers: {
        "content-type": "application/json"
      },
      method: endpoint.method
    });
  }
  _hideModalAlert(){
    this.setState({
      modalAlert: {
        display: false
      }
    });
  }
  _showModalAlert(accept, reject, children){
    this.setState({
      modalAlert: {
        display: true,
        accept: () => {
          accept();
          this._hideModalAlert()
        },
        reject: () => {
          reject();
          this._hideModalAlert()
        },
        children: children
      }
    });
  }

  _hideModalErrorDelete = () => {
    this.setState({
      modalErrorDelete: false
    });
  }
  _showModalErrorDelete = () => {
    this.setState({
      modalErrorDelete: true,
    });
  }
  _searchAbsences = (agents, start, end) => (
    this.props.api.service('absences', 'searchByPeriods').execute(
      [{ start: moment(start).toISOString(), end: moment(end).toISOString()}],
      agents,
      this.props.currentTenant.currentId
    ).then(absences => {
      return {
        valid: !absences.length,
        meta: absences
      }
    })
  );
  _onPeriodChange(start, end){
    alertIf([{
      condition: () => {
        return {
          valid: !start || !end || !this.props.deadline || this.props.deadline >= moment(end).toISOString()
        }
      },
      alert: (accept, reject) => {
        this._showModalAlert(accept, reject, (<T>assignment_period_out_of_deadline</T>));
      }
    }, {
      // Validation absence agent
      condition: () => {
        if(!this.props.assignment.agents.length || !start || !end){
          return { valid: true };
        }
        return this._searchAbsences(this.props.assignment.agents, start, end);
      },
      alert: (accept, reject, absences) => {
        return this.props.api.service('users', 'get').execute({ _id: { $in: absences.map(a => a.user)}}).then(users => {
          const message = (
            <T bind={{ users: users.map(u => u.fullname).join(', ') }}>
              { users.length === 1 ? "assignment_user_absent" : "assignment_users_absent"}
            </T>
          );

          this._showModalAlert(accept, reject, message);
        });
      }
    }, {
      // Validation absence équipe entière
      condition: () => {
        if(!this.props.assignment.team.length || !start || !end){
          return { valid: true };
        }
        return this.props.api.service('teams', 'get').execute({ _id: this.props.assignment.team[0], tenant: this.props.currentTenant.currentId })
          .then(teams => this.props.api.service('absences', 'searchByPeriods').execute(
            [{ start: moment(start).toISOString(), end: moment(end).toISOString()}],
            teams[0].members,
            this.props.currentTenant.currentId
          ).then(absences => {
            const absentUsers = absences.map(a => a.user);
            const valid = !teams[0].members.every((member) => absentUsers.includes(member));
            return {
              valid
            };
          }));
      },
      alert: (accept, reject) => {
        this._showModalAlert(accept, reject, (<T>assignment_team_absent</T>));
      }
    }, {
      condition: () => {
        if(!start || !end){
          return { valid: true };
        }
        let date = moment(start).clone();
        let lEnd = moment(end).clone();
        const notWorkingDayPeriods = [];
        let lStart = null;
        while(date.toDate() < lEnd.toDate()){
          if(this.props.currentTenant.isNotAWorkingDay(date)){
            if(!lStart){
              lStart = date.clone();
            }
          } else {
            if(lStart){
              notWorkingDayPeriods.push({ start: lStart, end: date.clone() });
              lStart = null;
            }
          }
          date.add(1, "day");
        }
        if(lStart){
          notWorkingDayPeriods.push({ start: lStart, end: date.clone() });
          lStart = null;
        }
        return {
          valid: !notWorkingDayPeriods.length,
          meta: notWorkingDayPeriods
        }
      },
      alert: (accept, reject, periodsNotWorked) => {
        const reactNode = (
          <>
            <T>planning_warningNotAWorkingDay_label1</T>
            <ul>
            {
              periodsNotWorked.map(({ start, end }) => (
                <li>
                  <T bind={{ start: start.format("DD/MM/YYYY"), end: end.clone().subtract(1, "day").format("DD/MM/YYYY") }}>
                  { start.isSame(end.clone().subtract(1, "day"), "day") ? "planning_warningNotAWorkingDay_label_oneDay" : "planning_warningNotAWorkingDay_label_period" }
                  </T>
                </li>
              ))
            }
            </ul>
            <T>planning_warningNotAWorkingDay_label2</T>
          </>
        );
        this._showModalAlert(accept, reject, reactNode);
      }
    }, {
      // Validation de disponibilité des équipements au changement de date
      condition: async () => {
        if(!start || !end || !this.props.assignment.necessariesEquipments.length){
          return { valid: true };
        }
        const response = await this.props.api.service("equipments", "isAvailable").execute(
          this.props.assignment.necessariesEquipments,
          start,
          end,
          [this.props.assignment._id]
        );
        return {
          valid: response.available,
          meta: response.equipments
        };
      },
      alert: (accept, reject, unavailableEquipments) => {
        this._showModalAlert(accept, reject, this._renderEquipmentWarningContent(unavailableEquipments));
      }
    }],
    () => {
      this._save({ 
        scheduledFrom: start,
        scheduledTo: end
      });
    },
    (err) => { console.error(err); })
  }
  _getTeamsAndAgents(){
    const { team, agents }  = this.props.assignment;
    const tenant            = this.props.tenant;
    if(team && team.length){
      const query = { _id: team[0], tenant: tenant };
      return fetch(`/teams?q=${JSON.stringify(query)}`).then(response => response.json()).then(teams => teams.map(t => { t.type = "team"; return t; }));
    }
    if(agents && agents.length){
      const query = { _id: { $in: agents }, "tenants.tenant": tenant };
      return fetch(`/users?q=${JSON.stringify(query)}`).then(response => response.json()).then(agents => agents.map(a => { a.type = "agent"; return a; }));
    }
  }
  _putTeamsAndAgents({ team: teams, agents }){
    const assignment = { 
      agents: agents?.map(a => a._id), team: teams?.map(t => t._id)
    }
    alertIf([
      {
        // Validation absence agent
        condition: () => {
          if((!assignment.agents.length) || !this.props.assignment.scheduledFrom || !this.props.assignment.scheduledTo){
            return { valid: true };
          }
          return this._searchAbsences(assignment.agents, this.props.assignment.scheduledFrom, this.props.assignment.scheduledTo);
        },
        alert: (accept, reject, absences) => {
          return this.props.api.service('users', 'get').execute({ _id: { $in: absences.map(a => a.user)}}).then(users => {
            const message = (
              <T bind={{ users: users.map(u => u.fullname).join(', ') }}>
                { users.length === 1 ? "assignment_user_absent" : "assignment_users_absent"}
              </T>
            );
  
            this._showModalAlert(accept, reject, message);
          });
        }
      }, {
        // Validation absence équipe entière
        condition: () => {
          const start = this.props.assignment.scheduledFrom;
          const end = this.props.assignment.scheduledTo;
          if(!assignment.team.length || !start || !end){
            return { valid: true };
          }
          return this.props.api.service('teams', 'get').execute({ _id: assignment.team[0], tenant: this.props.currentTenant.currentId })
            .then(teams => this.props.api.service('absences', 'searchByPeriods').execute(
              [{ start: moment(start).toISOString(), end: moment(end).toISOString()}],
              teams[0].members,
              this.props.currentTenant.currentId
            ).then(absences => {
              const users = absences.map(a => a.user);
              const valid = !teams[0].members.every((member) => users.includes(member));
              return {
                valid
              };
            }));
        },
        alert: (accept, reject) => {
          this._showModalAlert(accept, reject, (<T>assignment_team_absent</T>));
        }
      }],
    () => {
      this._save(assignment);
    },
    () => {})
  }
  _getEquipements(equipments){
    const tenant            = this.props.tenant;
    const query = { _id: { $in: equipments }, tenant: tenant };
    return fetch(`/equipments?q=${JSON.stringify(query)}`).then(response => response.json());
  }
  _putEquipments(equipments){
    alertIf([{
      condition: async () => {
        // En cas de suppression : pas de validation de disponibilité
        if(equipments.length < this.props.assignment?.necessariesEquipments?.length || !this.props.assignment.scheduledFrom || !this.props.assignment.scheduledTo) {
          return { valid: true }
        }
        const selectedEquipments = _.difference(equipments.map(equipment => equipment._id), this.props.assignment.necessariesEquipments);

        const response = await this.props.api.service("equipments", "isAvailable").execute(
          selectedEquipments,
          this.props.assignment.scheduledFrom,
          this.props.assignment.scheduledTo,
          [this.props.assignment._id]
        );
        return {
          valid: response.available,
          meta: response.equipments
        }
      },
      alert: (accept, reject, unavailableEquipments) => {
        this._showModalAlert(accept, reject, this._renderEquipmentWarningContent(unavailableEquipments));
      }
    }],
    () => {
      this._save({ necessariesEquipments: equipments.map(e => e._id) });
    },
    (err) => { console.error(err); }
  )}

  _renderEquipmentWarningContent(unavailableEquipments) {
    return (<WarningEquipment unavailableEquipments={ unavailableEquipments } />);
  }
  _putSupplies(supplies){
    this._save({ necessariesSupplies: supplies });
  }
  _putWorkInstructions(workInstructions){
    this._save({ workInstructions });
  }
  async _delete(){
    await jsonFetch(`/issues/${ this.props.issue }/assignments/${ this.props.assignment._id }`, "DELETE")
    .then(data => {
      if(!data.message) return;
      this._showModalErrorDelete((<T>{data.message}</T>));
    })
  }
  _print(){
    this.props.printHelper.workOrder({ _id: this.props.assignment._id }, {}, true);
  }
  _showOnCalendar = () => {
    this.props.openCalendar(this.props.assignment.scheduledFrom);
  }
  _onSupplyCreateHandler(){
    this.setState({initialValues: {}, showSupplyModal: true});
  }
  _onSupplyEditHandler(supply){
    this.setState({initialValues: supply, showSupplyModal: true});
  }
  _handleSupplySubmitted = (value) => {
    const necessariesSupplies = this.props.assignment.necessariesSupplies.concat([{ supply: value._id, quantity: 1 }]);
    this._save({ necessariesSupplies }).then(() => {
      this._selectSupply.current.load();
    });
    this.setState({ showSupplyModal: false });
  }
  getValues(){
    return { 
      ...this.state.initialValues, valorization: {
        ...(this.state.initialValues.valorization || {}),
        cost: (this.state.initialValues.valorization?.cost || 0) / 100
      }
    };
  }
  render(){
    const start = this.props.assignment.scheduledFrom ? new Date(this.props.assignment.scheduledFrom) : null;
    const end   = this.props.assignment.scheduledTo   ? new Date(this.props.assignment.scheduledTo)   : null;
    const isInCalendar = start && end && (this.props.assignment.team.length || this.props.assignment.agents.length || this.props.assignment.necessariesEquipments.length);
    return (
      <div className="bs-issue-assignment" onClick={(e) => e.stopPropagation() }>
        <div className="bs-issue-assignment-bsIdNumber">
          <span>{ this.props.assignment.bsIdNumber }</span>
        </div>
        {!this.props.hideAction && (
          <div className="bs-action">
            <Display.If condition={ isInCalendar }>
              <Acl.If resource="issues" action="seePlanning">
                <span className="bs-button" onClick={ this._showOnCalendar }><span className="fa fa-calendar"/>&nbsp;<T>assignment_show_in_calendar</T></span>
              </Acl.If>
            </Display.If>
            <span className="bs-button" onClick={ this._print }><span className="fa fa-print"/>&nbsp;<T>assignment_print</T></span>
            {
              !this.props.readOnly && !this.props.isAssignment && this.props.canBeDeleted
                ? (<span className="bs-button" onClick={ this._delete }><span className="fa fa-trash-o"/>&nbsp;<T>assignment_delete</T></span>)
                : null
            }
            <Acl.If resource="issues" action="changeAssignmentProgress">
              <Acl.Then>
                <State.SelectCommentAndValorize assignment={ this.props.assignment } mustBeValorized={ this.props.mustBeValorized } issue={ this.props.issue } tenant={ this.props.tenant } />
              </Acl.Then>
              <Acl.Else>
                <State.Standart state={ this.props.assignment.progress } />
              </Acl.Else>
            </Acl.If>
          </div>
        )}
        <Display.If condition={!this.props.readOnly || start}>
          <Field label={<T>assignment_planned</T>}>
          { 
            !this.props.readOnly ? (
              <WInput.Period nullable start={ start } end={ end } onChange={ this._onPeriodChange } constrain={WInput.Period.StartToHour(8)}>
                <WInput.Period.ModeSwitchButtonDecorator>
                {(btn, allDay) => (
                  <Tooltip>
                    <Tooltip.Subject>
                      {btn}
                    </Tooltip.Subject>
                      
                    <Tooltip.Info>
                      <div className='bs-issue-assignment-period-tooltip'>
                        <Nudge>
                          { allDay 
                            ? <T>assignment_formContent_clock_info</T> 
                            : <T>assignment_formContent_agenda_info</T> 
                          }
                        </Nudge>
                      </div>
                    </Tooltip.Info>
                  </Tooltip>
                )}
                </WInput.Period.ModeSwitchButtonDecorator>
              </WInput.Period>
            )
            : ( <Period start={ start } end={ end } /> )
          }
          </Field>
        </Display.If>
        <div className="bs-issue-assignment-subjects">
          <Display.If condition={!this.props.readOnly || this.props.assignment.team.length || this.props.assignment.agents.length}>
            <Field label={<T>assignment_assigned_to</T>}>
              <Input.SimpleSelectable 
                textify={assignment => assignment.type === "agent" ? <span><span className="fa fa-user" />&nbsp;{ assignment.fullname }</span> : <span><span className="fa fa-users" />&nbsp;{ assignment.name }</span> }
                getDatas={ this._getTeamsAndAgents }
                onChange={ this._putTeamsAndAgents }
                value={  this.props.assignment.team.concat(this.props.assignment.agents) }
                label={(<T>assignment_add_assignment</T>)}
                readOnly={ this.props.readOnly }
              >
                <Input.SimpleSelectable.Means>
                {(onChange, close) => (
                  <SelectTeamsAgents
                    stopSelection={close}
                    onChange ={onChange}
                    selected={{ team: this.props.assignment.team.map(t => ({ _id: t })), agents: this.props.assignment.agents.map(a => ({ _id: a })) }}
                  />
                )}
                </Input.SimpleSelectable.Means>
              </Input.SimpleSelectable>
            </Field>
          </Display.If>
          <Acl.If resource="equipments" action="read">
            <Display.If condition={!this.props.readOnly || this.props.assignment.necessariesEquipments.length}>
              <Field label={<T>assignment_equipments</T>}>
                <Input.SimpleSelectable 
                  textify={equipment => <span><span className="fa fa-wrench" />&nbsp;{ equipment.name }</span> }
                  getDatas={ this._getEquipements }
                  onChange={ this._putEquipments }
                  value={  this.props.assignment.necessariesEquipments }
                  label={(<T>assignment_add_equipment</T>)}
                  readOnly={ this.props.readOnly }
                >
                  <Input.SimpleSelectable.Means>
                    <SelectEquipments
                      tenant={ this.props.tenant }
                      selected={ this.props.assignment.necessariesEquipments.map(e => ({ _id: e }))  }
                    />
                  </Input.SimpleSelectable.Means>
                </Input.SimpleSelectable>
              </Field>
            </Display.If>
          </Acl.If>
          <Display.If condition={!this.props.readOnly || this.props.assignment.necessariesSupplies.length}>
            <Field label={<T>assignment_supplies</T>}>
              <InputSelectSupply
                ref={ this._selectSupply }
                tenant={ this.props.tenant } 
                onChange={ this._putSupplies }
                value={ this.props.assignment.necessariesSupplies }
                onEdit={ this._onSupplyEditHandler }
                onCreate={ this._onSupplyCreateHandler }
                readOnly={ this.props.readOnly }
              />
              { this.state.showSupplyModal && (
                <Modal.Show close={ () => this.setState({ showSupplyModal: false }) } style={{ width: "60vw", height: "60vh" }}>
                  <Layout.Standart stretch>
                    <Layout.Standart.Header>
                      <Section.Header>
                        <b><T>assignment_create_supply_form_title</T></b>
                      </Section.Header>
                    </Layout.Standart.Header>
                    <Layout.Standart.Content>
                    <Supply.Form
                      ref={ this._formSupplyRef }
                      id={ this.state.initialValues._id || null }
                      onSubmitted={this._handleSupplySubmitted}
                    />
                    </Layout.Standart.Content>
                    <Layout.Standart.Footer>
                      <Section.Footer>
                        <div className="bs-issue-assignment-create-supply-footer-container">
                          <Button.Text onClick={ () => this.setState({ showSupplyModal: false }) }><T>assignment_create_supply_form_cancel</T></Button.Text>
                          <Button.Text onClick={ () => this._formSupplyRef.current.submit() }><T>assignment_create_supply_form_confirm</T></Button.Text>
                        </div>
                      </Section.Footer>
                    </Layout.Standart.Footer>
                  </Layout.Standart>
                </Modal.Show>
              )}
            </Field>
          </Display.If>
          <Display.If condition={!this.props.readOnly || this.props.assignment.workInstructions}>
            <Field label={<T>assignment_workInstructions</T>}>
              <WorkInstructions 
                value={ this.props.assignment.workInstructions }
                onChange={ this._putWorkInstructions }
                readOnly={ this.props.readOnly }
              />
            </Field>
          </Display.If>
        </div>
        { this.state.modalAlert.display
          ? <ModalAlert
              accept={this.state.modalAlert.accept}
              reject={this.state.modalAlert.reject}
            >
            { this.state.modalAlert.children }
            </ModalAlert>
          : null
        }
        { this.state.modalErrorDelete ?
          <Modal.Show userCanClose={false}>
            {(close) => <ErrorDeleteDisplay accept={this._hideModalErrorDelete} close={close} /> }
          </Modal.Show>
          : null
        }
      </div>
    )
  }
}

export default Application.forward(['api', 'currentTenant'], [["print", "printHelper"]], Assignment);
