import React       from "react";
import Action      from "@cComponents/action";
import T           from "@uBehaviour/i18n";
import Application from "@uBehaviour/application";
import Nudge       from "@cComponents/nudge";

const ReleaseActions = Application.forward([], [["release", "releaseHelper"]], (props) => (
  <Action 
    id="publish_release"
    icon="rocket"
    premise={() => !props.release.openToPublic}
    onClick={(ev) => { props.releaseHelper.publish(props.release)}}>
      <Nudge>
        <div className="bs-teams-action-tooltip">
          <T>release_publish</T>
        </div>
      </Nudge>
  </Action>
));

export default ReleaseActions;