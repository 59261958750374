import React, { FunctionComponent, MouseEvent } from 'react';
import { NotificationUsed } from '@universal/types/business/Notification';
import File from '@common/components/file';
import T from '@universal/behaviour/i18n';
import User from '@universal/business/format/user';
import classNames from '@uLib/classNames';
import dayjs from 'dayjs';
import Display from '@common/components/displayIf';
import useService from '@universal/behaviour/hooks/useService';
import BusinessNotificationService from '@universal/services/businessNotification';
import { BusinessEntity } from '@universal/types/technic/Entityable';
import Tenant from '@universal/types/business/Tenant';
import Issue from '@universal/types/business/Issue';

import './item.css';
import ApiService from '@universal/services/api';

interface ItemProps {
  notification: NotificationUsed;
  onClick: (issue: Issue) => void;
}

const Item: FunctionComponent<ItemProps> = ({ notification, onClick }) => {
  const businessNotification = useService<BusinessNotificationService>('businessNotification');
  const api = useService<ApiService>('api');
  const file = React.useMemo(() => {
    const file = notification.issue.files?.find(f => f.mimeType.startsWith('images'));
    return (
      <File file={ file } fit="cover" width={ 108 } height={ 108 }/>
    );
  }, [notification]);

  const createdBy = React.useMemo(() => {
    return (<b>{User.shortFullname(notification.createdBy)}</b>);
  }, [notification]);

  const stopPropagationAndTransmitIssue = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    onClick(notification.issue);
  }, [notification, onClick]);

  const reason = React.useMemo(() => {
    switch(notification.type){
      case 'assignment': return (<T bind={{ createdBy }}>notifications_item_reason_assignment</T>);
      case 'comment': return (<T bind={{ createdBy }}>{notification.creatorCitizen ? "notifications_item_reason_comment_citizen" : "notifications_item_reason_comment_pro"}</T>);
      case 'create': return (<T bind={{ createdBy }}>notifications_item_reason_create</T>);
      case 'update': return (<T bind={{ createdBy }}>notifications_item_reason_update</T>);
      case 'stateUpdate': return (<T bind={{ createdBy, state: <T>{notification.issue.state}</T> }}>notifications_item_reason_stateUpdate</T>);
    }
  }, [notification, createdBy]);

  const tenant = React.useMemo(() => {
    if(!businessNotification.withTenant){
      return null;
    }
    return (
      <div className='bs-notifications-item-content-tenant'>
        <T>{ (notification.issue.tenant as BusinessEntity<Tenant>).name }</T>
      </div>
    )
  }, [businessNotification]);

  const markNotificationRead = React.useCallback((e: MouseEvent) => {
    e.stopPropagation();
    api.service('notifications', 'read').execute(notification.issue._id);
  }, [api, notification]);

  return (
    <div className={ classNames('bs-notifications-item').addIf('bs-notifications-item-read', notification.read) } onClick={ stopPropagationAndTransmitIssue }>
      <div className='bs-notifications-item-file'>{ file }</div>
      <div className='bs-notifications-item-content'>
        { tenant }
        <div className='bs-notifications-item-content-reason'>
          { reason }
          <Display.If condition={ notification.more }>
            <T bind={{ more: notification.more }}>notifications_item_more</T>
          </Display.If>
        </div>
        <div className='bs-notifications-item-content-createdAt'>{ dayjs(notification.createdAt).fromNow() }</div>
      </div>
      <Display.If condition={ !notification.read }>
        <div className='bs-notifications-item-read-button' onClick={ markNotificationRead }>
          <span className='fa fa-dot-circle-o' />
        </div>
      </Display.If>
    </div>
  );
}

export default Item;