import React, { Component } from 'react'
import T from 'i18n-react'
import _ from 'lodash'
import SelectField from 'client/features/form/old/components/fields/Select.jsx'

class IssueDetailsTag extends Component {

    constructor() {
        super()
        this.onChange = this.onChange.bind(this)
        this.edit = this.edit.bind(this)
        this.state = {
            edit: false
        }
    }

    onChange(tag) {
        this.props.onChange(tag)
        this.setState({
            edit: false
        })
    }

    edit() {
        this.setState({
            edit: !this.state.edit
        })
    }

    render() {
        const { issue, tags = [], confirmation, disabled, muted } = this.props
        return (
            <span className='c-issue-title'>
                { T.translate(issue.type).toUpperCase() }
                <small>&nbsp;/&nbsp;</small>
                <SelectField
                    muted
                    value={ { ...issue.tag, label: issue.tag.label, value: issue.tag._id } }
                    onChange={ this.onChange }
                    options={
                        tags.map((tag) => ({ ...tag, label: tag.label, value: tag._id })) 
                    }
                    confirmation={ confirmation }
                    disabled={ disabled }
                />
            </span>
        )
    }
}

export default IssueDetailsTag