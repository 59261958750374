import React, { Component } from 'react'
import T from 'i18n-react'
import _ from 'lodash'
 

class SelectField extends Component {
    constructor() {
        super()
        this._input = React.createRef();
        this.onChange = this.onChange.bind(this)
        this.state = {
            value: null
        }
    }
    
    componentWillUpdate(nextProps, nextState) {
        if ((!nextProps.input.value || !nextProps.input.value[0]) && this.state.value)
            this.setState({ value: null })
    }

    onChange() {
        let { value } = this._input.current
        if (this.props.change) {
            this.props.change(value)
        }
        if (value === 'null')
            value = null
        if (!this.props.className && value)
            this.setState({ value: _.find(this.props.options, { value }).label })
        else if (!this.props.className)
            this.setState({ value: null })
        return this.props.input.onChange(this.props.input.value = value)
    }

    render() {
        const { 
            label,
            placeholder,
            options = [],
            initialValue,
            emptyOptionLabel = T.translate('unselect_all_f'),
            hideEmptyOption,
            meta: { error, touched },
            disabled,
            className = '',
        } = this.props

        return (
            <span>
                {
                    label &&
                        <label>
                            { label }
                        </label>
                }
                <span className='select-a' style={ { width: '100%' } }>
                    { /*Todo: remove this shit (old input style)*/ }
                    {
                        className === '' ?
                            <span>
                                {
                                    this.state.value ? this.state.value : placeholder
                                }
                            </span>
                            : null
                    }
                    <select
                        onChange={ this.onChange }
                        ref={ this._input } 
                        className={ className }
                        placeholder={ placeholder }
                        defaultValue={ initialValue }
                        disabled={ disabled }
                    >
                        { !hideEmptyOption ? <option value='null'>{ emptyOptionLabel }</option> : null }
                        {
                            options.map((option) => 
                                <option
                                    value={ option.value || option._id }
                                    key={ option.value || option._id }
                                >
                                    { option.label }
                                </option>
                            )
                        }
                    </select>
                </span>
                { touched && ((error && <p className='error'>{error}</p>)) }
            </span>
        )
    }
}

export default SelectField