// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-valorizations-subject {
  display: flex;
  align-items: center;
  max-width: fit-content;

  background-color: #EFEFEF;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.6);
  font-size: .8rem;
  font-weight: 700;
  padding: 4px 8px;
}

.bs-valorizations-subject>span:nth-child(n + 2){
  margin-left: 5px;
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bs-valorizations-subject-update,
.bs-valorizations-subject-delete{
  visibility: hidden;
}
.bs-valorizations-subject:hover > .bs-valorizations-subject-update,
.bs-valorizations-subject:hover > .bs-valorizations-subject-delete{
  visibility: visible;
  flex-shrink: 0;
}
.bs-valorizations-subject-update:hover,
.bs-valorizations-subject-delete:hover{
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/entities/valorizations/subjects/subject.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;;EAEtB,yBAAyB;EACzB,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;;EAEE,kBAAkB;AACpB;AACA;;EAEE,mBAAmB;EACnB,cAAc;AAChB;AACA;;EAEE,eAAe;AACjB","sourcesContent":[".bs-valorizations-subject {\n  display: flex;\n  align-items: center;\n  max-width: fit-content;\n\n  background-color: #EFEFEF;\n  border-radius: 3px;\n  color: rgba(0, 0, 0, 0.6);\n  font-size: .8rem;\n  font-weight: 700;\n  padding: 4px 8px;\n}\n\n.bs-valorizations-subject>span:nth-child(n + 2){\n  margin-left: 5px;\n  flex-shrink: 1;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.bs-valorizations-subject-update,\n.bs-valorizations-subject-delete{\n  visibility: hidden;\n}\n.bs-valorizations-subject:hover > .bs-valorizations-subject-update,\n.bs-valorizations-subject:hover > .bs-valorizations-subject-delete{\n  visibility: visible;\n  flex-shrink: 0;\n}\n.bs-valorizations-subject-update:hover,\n.bs-valorizations-subject-delete:hover{\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
