import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import toDic        from "@cLib/toDic";
import Category     from "@entities/category";
import useService from '@universal/behaviour/hooks/useService';
import ApiService from '@universal/services/api';
import CurrentTenantService from '@universal/services/currentTenant';
import RepositoryService from '@universal/services/repository';
import I18nService from '@universal/services/i18n';
import { combinate } from '@universal/lib/query';

const textify = category => (<T>{ category.label }</T>);

const CategoryFilterDisplay = ({values, add, drop, clear, query }) => {
  const currentTenant = useService<CurrentTenantService>("currentTenant");
  const repository = useService<RepositoryService>("repository");
  const i18n = useService<I18nService>("i18n");

  const usedQuery = React.useMemo(() => combinate("$and",
    { tenant: currentTenant.currentId },
    values.length ? { _id: { $nin: values.map(v => v._id) }} : null,
    query
  ), [currentTenant.currentId, values, query]);

  const loadedDependency = React.useMemo(() => ({
    tenant:true,
    "redirectRule.manager": true,
    "redirectRule.category":{
      tenant:true
    },
    "redirectRule.categories":{
      tenant:true
    },
    "redirectRule.defaultTransfer": true
  }), []);

  const sort = React.useMemo(() => {
    return { disabled: 1, [i18n.queryProperty("label")]: 1 };
  }, [i18n]);

  const filterQuery = React.useCallback(value => (
    { [i18n.queryProperty("label")]: { '$regex': value, '$options': 'i' } }
  ), [i18n]);

  const value = React.useMemo(() => values.map(v => repository.get("Category").key.extract(v)), [values, repository]);

  const triggerChange = React.useCallback((vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }, [add, drop]);
  return (
    <Collapsable>
      <Collapsable.Title><T>issue_filter_categories</T></Collapsable.Title>
      <Collapsable.Content>
        <Input.Selectable 
          value={ value }
          onChange={ triggerChange }
          model={ repository.get("Category") }
          load={ loadedDependency }
          query={ usedQuery }
          sort={ sort }
          textify={ textify }
          filterQuery={ filterQuery }
        >
          <Category.Item />
        </Input.Selectable>
      </Collapsable.Content>
    </Collapsable>
  );
}

const buildQuery = values => ({ "category": { $in: values.map(v => v._id) } });

const stringify = value => { return (<><T>issue_filter_categories</T> : <T>{value.label}</T></>)};

const CategoryFilter = ({ query }) => {
  const api = useService<ApiService>("api");

  const hydrate = React.useCallback(values => api
    .service("categories", "get")
    .execute({_id: {$in: values }}, null, null, values.length)
    .then(categories => toDic(values, v => v, (v) => categories.find(c => c._id === v)))
  , [api]);

  return (
    <Filter.Generic
      deshydrate={value => value._id}
      hydrate={hydrate}
      multiple
      name="categories"
      buildQuery={ buildQuery }
      stringify={ stringify }
    >
      {(values, add, drop, clear) => (
        <CategoryFilterDisplay values={ values } add={ add } drop={ drop } clear={ clear } query={ query }/>
      )}
    </Filter.Generic>
  )
}

export default CategoryFilter;