import useService from '@universal/behaviour/hooks/useService';
import BusinessNotificationService from '@universal/services/businessNotification';
import React, { FunctionComponent } from 'react';
import Item from './item';
import ScrollBar from '@common/components/scrollBar/scrollBar';

import T from '@universal/behaviour/i18n';
import IssueTooltip from '@entities/issue/tooltipContent';
import Tooltip from '@cComponents/tooltip';
import Issue from '@universal/types/business/Issue';

import './list.css';
import ApiService from '@universal/services/api';

interface ListProps {
  onIssueSelected: (issue: Issue) => void;
}

const List: FunctionComponent<ListProps> = ({ onIssueSelected }) => {
  const notificationService = useService<BusinessNotificationService>('businessNotification', ['onNotificationChange']);

  const loadNotificationWhenScrollOnEnd = React.useCallback((scrollbar: ScrollBar) => {
    if(scrollbar.position > 0.8){
      notificationService.loadNext();
    }
  }, [notificationService]);

  const api = useService<ApiService>('api');

  const markAllRead = React.useCallback(() => {
    api.service('notifications', 'readAll').execute();
  }, [api]);

  return (
    <div className='bs-notifications-list'>
      <div className='bs-notifications-list-header'>
        <div className='bs-notifications-list-header-title'><T>notifications_list_header_title</T></div>
        <div className='bs-notifications-list-header-readAll' onClick={ markAllRead }><T>notifications_list_header_readAll</T></div>
      </div>
      <div className='bs-notifications-list-items'>
        <ScrollBar onScroll={ loadNotificationWhenScrollOnEnd } whenViewPortHeightChange="keepPostion">
        {notificationService.get().map(notification => (
          <Tooltip placement="left">
            <Tooltip.Subject>
              <Item notification={ notification } onClick={ onIssueSelected } />
            </Tooltip.Subject>
            <Tooltip.Info>
              <IssueTooltip id={ notification.issue._id } />
            </Tooltip.Info>
          </Tooltip>
        ))}
      </ScrollBar>
      </div>
    </div>
  );
};

export default List;