import React from 'react'
import _ from "lodash";
import SelectEquipmentsBase from "client/components/assignments/selectEquipments.jsx";
import fetch                 from "client/lib/fetch";
class SelectEquipments extends React.Component{
  state = { 
    equipments: []
  }
  constructor(props) {
    super(props);
    this._onFilterChange = _.debounce(this._onFilterChange.bind(this), 500);
  }
  
  componentDidMount(){
    this._load();
  }
  _onFilterChange(value){
    this._load(value);
  }
  _load(filter = null){
    let endpointEquipments = "/equipments?sort=" + JSON.stringify({ name: 1 }) + "&limit=100";
    let queryEquipments    = { tenant: this.props.tenant, disabled: false };
    if(filter){
      queryEquipments = { $and: [{name: { '$regex': filter, '$options' : 'i' }}, queryEquipments]};
    }
    endpointEquipments += "&q=" + JSON.stringify(queryEquipments);

    return fetch(endpointEquipments).then(response => response.json())
            .then(equipments => {
              this.setState({
                equipments
              })
            });
  }
  render(){
    return (
      <SelectEquipmentsBase 
        selected={ this.props.selected }
        equipments={ this.state.equipments }
        onFilterChange={ this._onFilterChange }
        onChange={ this.props.onChange }
        close={ this.props.close }
      />
    )
  }
}

export default  SelectEquipments;