import React from 'react';
import { BusinessEntity } from '@uTypes/technic/Entityable';
import { Model } from '@uLib/model';
import useModel from './useModel';

function useGet<T>(modelName: string | Model, ids: Partial<T>[]): BusinessEntity<T>[] {
  const model: Model = useModel(modelName);
  const [entities, setEntities] = React.useState<BusinessEntity<T>[]>([]);
  
  React.useEffect(() => {
    model.repository.get(ids).then(results => {
      setEntities(results);
    });
    return () => {
      entities.forEach(entity => {
        entity.dispose();
      });
    }
  }, [model, setEntities, JSON.stringify(ids)]);

  return entities;
};

export default useGet;

