import React, { FunctionComponent, PropsWithChildren } from "react";

import "./template.css";
import Slot from "@universal/components/slot2";

const Image = Slot();
const Icon = Slot();
const Title = Slot();

const Template: FunctionComponent<PropsWithChildren> & { Image: typeof Image, Icon: typeof Icon, Title: typeof Title } = ({ children }) => {
  const image = Image.get(children);
  const icon = Icon.get(children);
  const title = Title.get(children);
  return (
    <div className="bs-featuresDiscovery-template">
      <div className="bs-featuresDiscovery-template-image">
        { image }
      </div>
      <div className="bs-featuresDiscovery-template-content">
        <div className="bs-featuresDiscovery-template-icon">
          { icon }
        </div>
        <span className="bs-featuresDiscovery-template-title">
          { title }
        </span>
        <div className="bs-featuresDiscovery-template-description">
          { children }
        </div>
      </div>
    </div>
  );
}

Template.Image = Image;
Template.Icon = Icon;
Template.Title = Title;

export default Template;