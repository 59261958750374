import dayjs from "dayjs";

export class NoDaySelectedError extends Error {}
export class NoOccuranceGeneratedError extends Error {}

class Scheduler {
  static factory(recurrence, notWorkingDayRule){
    return new Scheduler(
      EndRule.factory(recurrence.endRule),
      SchedulerRule.factory(recurrence.schedulerRule, notWorkingDayRule),
      new History(
        dayjs(recurrence.history.start),
        recurrence.history.lastPlannedDate ? dayjs(recurrence.history.lastPlannedDate) : null,
        recurrence.history.nbrAlreadyPlanned
      )
    );
  }
  constructor(endRule, schedulerRule, history){
    this._endRule           = endRule;
    this._schedulerRule     = schedulerRule;
    this._history           = history;
  }
  isEnd(){
    return this._endRule.isEnded(this._history, this) || !this._schedulerRule.isValid();
  }
  endDate() {
    return this._endRule.endDate(this._history.clone(), this.clone());
  }
  clone() {
    return new Scheduler(this._endRule.clone(), this._schedulerRule.clone(), this._history.clone());
  }
  next(){
    if(this.isEnd()){
      return null;
    }
    const date = this._next();
    if(this._schedulerRule.respect(date.used)){
      this._history.lastPlannedDate = date.used;
    } else {
      this._history.lastPlannedDate = date.calculated;
    }
    return date.used;
  }
  _next(){
    let date = this._history.lastPlannedDate;
    if(!date){
      const start = this._history.start.clone().startOf("day");
      const { used, calculated } = this._schedulerRule.next(start);
      let usedDate = this._schedulerRule.respect(used) ? used : calculated;
      const previous = this._schedulerRule.previous(usedDate.startOf("day"));
      if(previous.used.isAfter(start)){
        return previous;
      }
      date = start;
    }
    date = date.clone().startOf("day");
    return this._schedulerRule.next(date);
  }
  previous(){
    const date = this._previous();
    if(this._schedulerRule.respect(date.used)){
      this._history.lastPlannedDate = date.used;
    } else {
      this._history.lastPlannedDate = date.calculated;
    }
    return date.used;
  }
  _previous(){
    let date = this._history.lastPlannedDate;
    if(!date){
      date = this._history.start;
      if(this._schedulerRule.respect(date)){
        return { used: date.clone(), calculated: date.clone() };
      }
    }
    date = date.clone();
    date = this._schedulerRule.previous(date);
    return date;
  }
  rollback(){
    this._history.rollback();
  }
  toPlainText(){
    return {
      schedulerRule: this._schedulerRule.toPlainText(),
      endRule: this._endRule.toPlainText(),
      history: this._history.toPlainText()
    };
  }
}
class History {
  constructor(start, lastPlannedDate = null, nbrAlreadyPlanned = 0){
    this._start             = start;
    this._lastPlannedDate   = lastPlannedDate;
    this._nbrAlreadyPlanned = nbrAlreadyPlanned;
    this._lasts             = [];
  }
  get start(){
    return this._start;
  }
  get lastPlannedDate(){
    return this._lastPlannedDate;
  }
  set lastPlannedDate(lastPlannedDate){
    this._nbrAlreadyPlanned++;
    this._lasts.push(this._lastPlannedDate);
    this._lastPlannedDate = lastPlannedDate;
  }
  get nbrAlreadyPlanned(){
    return this._nbrAlreadyPlanned;
  }
  clone() {
    const history = new History(this._start, this._lastPlannedDate ? this._lastPlannedDate.clone() : null, this._nbrAlreadyPlanned);
    history._lasts = this._lasts.map(date => date ? date.clone() : null);
    return history;
  }
  rollback(){
    this._nbrAlreadyPlanned--;
    this._lastPlannedDate = this._lasts.pop();
  }
  toPlainText(){
    return {
      start: this._start.toDate(),
      lastPlannedDate: this._lastPlannedDate ? this._lastPlannedDate.toDate() : null,
      nbrAlreadyPlanned: this._nbrAlreadyPlanned
    };
  }
}
class EndRule                               {
  static factory(object){
    if(!object) return new NoEndRule();
    switch(object.discriminator){
    case "occurenceNumbers":  return new LimitedOccurenceRule(object.number);
    case "date":              return new PlannedEndRule(dayjs(object.date));
    default: throw new Error(`EndRule.factory : unknown discriminator "${object.discriminator}"`);
    }
  }
  clone() { throw new Error("must be override"); }
  isEnded(history){ throw new Error("must be override"); }
  endDate(history){ throw new Error("must be override"); }
}
class NoEndRule             extends EndRule {
  clone() { 
    return new NoEndRule(); 
  }
  isEnded(){ 
    return false; 
  }
  endDate() { 
    return null; 
  }
  toPlainText() { 
    return null; 
  }
}
class LimitedOccurenceRule  extends EndRule { 
  constructor(nbrOccurences){
    super();
    this._nbrOccurences = nbrOccurences;
  }
  clone() {
    return new LimitedOccurenceRule(this._nbrOccurences);
  }
  isEnded(history){
    return history.nbrAlreadyPlanned > 0 && history.nbrAlreadyPlanned >= this._nbrOccurences;
  }
  endDate(history, scheduler) {
    let endDate = history.lastPlannedDate;
    const nbrFutureOccurance = this._nbrOccurences - history.nbrAlreadyPlanned;
    for(let i = 0; i < nbrFutureOccurance; i++) {
      endDate = scheduler.next();
    }
    return endDate;
  }
  toPlainText(){
    return {
      discriminator: "occurenceNumbers",
      number: this._nbrOccurences
    };
  }
}
class PlannedEndRule extends EndRule {
  constructor(plannedEnd){
    super();
    this._plannedEnd = plannedEnd;
  }
  clone() { 
    return new PlannedEndRule(this._plannedEnd.clone()); 
  }
  isEnded(history, scheduler){
    return history.nbrAlreadyPlanned > 0 && !scheduler._next().used.isBefore(this._plannedEnd);
  }
  endDate() {
    return this._plannedEnd;
  }
  toPlainText(){
    return {
      discriminator: "date",
      date: this._plannedEnd.toDate()
    };
  }
}

class SchedulerRule { 
  static factory(object, notWorkingDayRule){
    if(object){
      switch(object.discriminator){
      case "jump":
        return new JumpedRule(SchedulerRule.factory(object.rule, notWorkingDayRule), object.jump);
      case "notWorkingDayJumpNext":
        return new JumpedNotWorkingDayRule(SchedulerRule.factory(object.rule, notWorkingDayRule), notWorkingDayRule);
      case "notWorkingDayScheduleOnNextWorkingDay":
        return new FindWorkingDayOnNotWorkingDayRule(SchedulerRule.factory(object.rule, notWorkingDayRule), notWorkingDayRule, true);
      case "notWorkingDayScheduleOnPreviousWorkingDay":
        return new FindWorkingDayOnNotWorkingDayRule(SchedulerRule.factory(object.rule, notWorkingDayRule), notWorkingDayRule, false);
      case "weekly":              return new WeeklyRule(object.dayOfWeek); 
      case "monthlyFixedDay":     return new MonthlyFixedDayRule(object.dayOfMonth); 
      case "monthlyRelativeDay":  return new MonthlyRelativeDayRule(object.dayOfWeek, object.nth); 
      case "yearlyFixedDay":      return new YearlyFixedDayRule(object.dayOfMonth, object.month); 
      case "yearlyRelativeDay":   return new YearlyRelativeDayRule(object.dayOfWeek, object.nth, object.month);
      default: throw new Error(`SchedulerRule.factory : unknown discriminator "${object.discriminator}"`);
      }
    }
    return null;
  }
  respect(last){
    return this._previousCalculatedDate(this._nextCalculatedDate(last)).startOf("day").isSame(last.clone().startOf("day"));
  }
  next(last){
    const nextCalculated = this._nextCalculatedDate(last);
    return {
      used: nextCalculated.clone(),
      calculated: nextCalculated.clone()
    };
  }
  clone() { throw new Error("Must be override"); } 
  _nextCalculatedDate()    { throw new Error("Must be override"); }
  previous(last){
    const previousCalculated = this._previousCalculatedDate(last);
    return {
      used: previousCalculated.clone(),
      calculated: previousCalculated.clone()
    };
  }
  _previousCalculatedDate(){ throw new Error("Must be override"); }
  isValid() { return true; }
  isNextJumpable(last){
    return true;
  }
  isPreviousJumpable(last){
    return true;
  }
  howManyDateToDoJump(last){
    return 1;
  }
}
class WeeklyRule extends SchedulerRule{
  constructor(daysOfWeek){
    super();
    if(!daysOfWeek.length){
      throw new NoDaySelectedError("WeeklyRule must have at least one day selected");
    }
    if(daysOfWeek.findIndex(dayOfWeek => dayOfWeek > 6) !== - 1){
      throw new Error("Invalid day of week");
    }
    this._daysOfWeek = daysOfWeek.sort((a, b) => {
      if(a === 0) return 1;
      if(b === 0) return -1;
      return a - b;
    });
  }
  clone() {
    return new WeeklyRule(this._daysOfWeek.slice());
  }
  respect(last){
    return this._daysOfWeek.includes(last.day());
  }
  isValid() {
    return this._daysOfWeek.length !== 0;
  }
  _nextCalculatedDate(last){
    const lastDay = last.day();
    let index = this._daysOfWeek.findIndex(dayOfWeek => lastDay < dayOfWeek);
    if(index === -1){
      index = 0;
    }
    const dayOfWeek = this._daysOfWeek[index];
    return last.clone().add(dayOfWeek - lastDay + (lastDay >= dayOfWeek  ? 7 : 0), "d");
  }
  _previousCalculatedDate(last){
    const lastDay = last.day();
    let index = this._daysOfWeek.findLastIndex(dayOfWeek => lastDay > dayOfWeek);
    if(index === -1){
      index = this._daysOfWeek.length - 1;
    }
    const dayOfWeek = this._daysOfWeek[index];
    return last.clone().subtract(lastDay - dayOfWeek + (lastDay <= dayOfWeek ? 7 : 0), "d");
  }
  isNextJumpable(last){
    return this._daysOfWeek.lastIndexOf(last.day()) === this._daysOfWeek.length - 1;
  }
  isPreviousJumpable(last){
    return this._daysOfWeek.indexOf(last.day()) === 0;
  }
  howManyDateToDoJump(last){
    return this._daysOfWeek.length;
  }
  toPlainText(){
    return {
      discriminator: "weekly",
      dayOfWeek: this._daysOfWeek
    };
  }
}
class MonthlyFixedDayRule extends SchedulerRule {
  constructor(dayOfMonth){
    super();
    this._dayOfMonth = dayOfMonth;
  }
  clone() {
    return new MonthlyFixedDayRule(this._dayOfMonth);
  }
  respect(last){
    return last.date() === this._dayOfMonth;
  }
  _nextCalculatedDate(last){
    return last.clone()
      .startOf("month")
      .add(1, "M")
      .date(this._dayOfMonth);
  }
  _previousCalculatedDate(last){
    return last.clone()
      .startOf("month")
      .subtract(1, "M")
      .date(this._dayOfMonth);
  }
  toPlainText(){
    return {
      discriminator: "monthlyFixedDay",
      dayOfMonth: this._dayOfMonth
    };
  }
}
class MonthlyRelativeDayRule extends SchedulerRule {
  constructor(dayOfWeek, nth){
    super();
    this._dayOfWeek = dayOfWeek;
    this._nth       = nth;
  }
  clone() {
    return new MonthlyRelativeDayRule(this._dayOfWeek, this._nth);
  }
  _nextCalculatedDate(last){
    return this._getForMonth(last.clone().startOf("month").add(1, "M"));
  }
  _previousCalculatedDate(last){
    return this._getForMonth(last.clone().startOf("month").subtract(1, "M"));
  }
  _getForMonth(firstDayOfMonth){
    return firstDayOfMonth.clone().add(this._nth - 1, "w")
      .add(this._dayOfWeek - firstDayOfMonth.day() + (this._dayOfWeek < firstDayOfMonth.day() ? 7 : 0), "d");
  }
  toPlainText(){
    return {
      discriminator: "monthlyRelativeDay",
      dayOfWeek: this._dayOfWeek,
      nth: this._nth
    };
  }
}
class YearlyFixedDayRule extends SchedulerRule{
  constructor(dayOfMonth, month){
    super();
    this._dayOfMonth  = dayOfMonth;
    this._month       = month;
  }
  clone() {
    return new YearlyFixedDayRule(this._dayOfMonth, this._month);
  }
  respect(last){
    return last.date() === this._dayOfMonth && last.month() === this._month;
  }
  _nextCalculatedDate(last){
    return this._getForYear(last.clone().startOf("year").add(1, "year"));
  }
  _previousCalculatedDate(last){
    return this._getForYear(last.clone().startOf("year").subtract(1, "year"));
  }
  _getForYear(firstDayOfYear){
    return firstDayOfYear.add(this._month, "M").date(this._dayOfMonth);
  }
  toPlainText(){
    return {
      discriminator: "yearlyFixedDay",
      dayOfMonth: this._dayOfMonth,
      month: this._month
    };
  }
}
class YearlyRelativeDayRule extends MonthlyRelativeDayRule{
  constructor(dayOfWeek, nth, month){
    super(dayOfWeek, nth);
    this._month = month;
  }
  clone() {
    return new YearlyRelativeDayRule(this._dayOfWeek, this._nth, this._month);
  }
  _nextCalculatedDate(last){
    return this._getForYear(last.clone().startOf("year").add(1, "y"));
  }
  _previousCalculatedDate(last){
    return this._getForYear(last.clone().startOf("year").subtract(1, "y"));
  }
  _getForYear(firstDayOfYear){
    return this._getForMonth(firstDayOfYear.month(this._month));
  }
  toPlainText(){
    return {
      discriminator: "yearlyRelativeDay",
      dayOfWeek: this._dayOfWeek,
      nth: this._nth,
      month: this._month
    };
  }
}

class StrategySchedulerRule extends SchedulerRule {
  constructor(rule){
    super();
    this._rule = rule;
  }
  respect(last){ 
    return this._rule.respect(last);
  }
  _nextCalculatedDate(last){
    return this._rule._nextCalculatedDate(last);
  }
  _previousCalculatedDate(last){
    return this._rule._previousCalculatedDate(last);
  }
  isValid() {
    return this._rule.isValid();
  }
  isNextJumpable(last){
    return this._rule.isNextJumpable(last);
  }
  isPreviousJumpable(last){
    return this._rule.isPreviousJumpable(last);
  }
  howManyDateToDoJump(last){
    return this._rule.howManyDateToDoJump(last);
  }
}

class JumpedRule extends StrategySchedulerRule {
  constructor(rule, nbrJumped){
    super(rule);
    this._nbrJumped = nbrJumped;
  }
  clone() {
    return new JumpedRule(this._rule.clone(), this._nbrJumped);
  }
  _nextCalculatedDate(last){
    if(this._rule.isNextJumpable(last)){
      const nbrToJump = (this._nbrJumped) * this._rule.howManyDateToDoJump(last);
      for(let i = 0; i < nbrToJump; ++i){
        last = this._rule._nextCalculatedDate(last);
      }
    }
    return this._rule._nextCalculatedDate(last);
  }
  _previousCalculatedDate(last){
    if(this._rule.isPreviousJumpable(last)){
      const nbrToJump = (this._nbrJumped) * this._rule.howManyDateToDoJump(last);
      for(let i = 0; i < nbrToJump; ++i){
        last = this._rule._previousCalculatedDate(last);
      }
    }
    return this._rule._previousCalculatedDate(last);
  }
  toPlainText(){
    return {
      discriminator: "jump",
      jump: this._nbrJumped,
      rule: this._rule.toPlainText()
    };
  }
}

class JumpedNotWorkingDayRule extends StrategySchedulerRule {
  constructor(rule, notWorkingDayRule){
    super(rule);
    this._notWorkingDayRule = notWorkingDayRule;
  }
  clone() {
    return new JumpedNotWorkingDayRule(this._rule.clone(), this._notWorkingDayRule);
  }
  respect(last){
    return super.respect(last) && !this._notWorkingDayRule(last);
  }
  _nextCalculatedDate(last){
    let counter = 0;
    do {
      if(++counter > 100){
        throw new NoOccuranceGeneratedError("Impossible to find a corresponding day")
      }
      last = this._rule._nextCalculatedDate(last);
    } while(this._notWorkingDayRule(last));
    return last.clone();
  }
  _previousCalculatedDate(last){
    let counter = 0;
    do {
      if(++counter > 100){
        throw new NoOccuranceGeneratedError("Impossible to find a corresponding day")
      }
      last = this._rule._previousCalculatedDate(last);
    } while(this._notWorkingDayRule(last));
    return last.clone();
  }
  toPlainText(){
    return {
      discriminator: "notWorkingDayJumpNext",
      rule: this._rule.toPlainText()
    };
  }
}

class FindWorkingDayOnNotWorkingDayRule extends StrategySchedulerRule {
  constructor(rule, notWorkingDayRule, forward = true){
    super(rule);
    this._notWorkingDayRule = notWorkingDayRule;
    this._forward = forward;
  }
  clone() {
    return new FindWorkingDayOnNotWorkingDayRule(this._rule.clone(), this._notWorkingDayRule, this._forward);
  }
  respect(last){
    return super.respect(last) && !this._notWorkingDayRule(last);
  }
  next(last){
    let { calculated, used } = super.next(last);
    while(this._notWorkingDayRule(used)){
      used = used.clone()[ this._forward ? "add" : "subtract"](1, "day");
    }
    return { calculated, used };
  }
  previous(last){
    let { calculated, used } = super.previous(last);
    while(this._notWorkingDayRule(used)){
      used = used.clone()[ this._forward ? "add" : "subtract"](1, "day");
    }
    return { calculated, used };
  }
  toPlainText(){
    return {
      discriminator: this._forward ? "notWorkingDayScheduleOnNextWorkingDay" : "notWorkingDayScheduleOnPreviousWorkingDay",
      rule: this._rule.toPlainText()
    };
  }
}


export default Object.assign(Scheduler, {
  Rule: SchedulerRule,
  History,
  EndRule,
  JumpedRule,
  JumpedNotWorkingDayRule,
  FindWorkingDayOnNotWorkingDayRule
});