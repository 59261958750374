import React, { FunctionComponent, PropsWithChildren } from 'react';
import PartItem from './item';

import Valorization from '@uTypes/business/Valorization';
import Issue, { Assignment } from '@uTypes/business/Issue';
import T from '@uBehaviour/i18n';

import './setByType.css';
import Summary from './summary';
import { Grid } from 'semantic-ui-react';
import useOpenCloseToggle from '@universal/behaviour/hooks/useOpenCloseToggle';
import Display from '@universal/components/displayIf';
import useService from '@universal/behaviour/hooks/useService';
import AclService from '@universal/services/acl';

interface SetForIssueProps {
  issue: Issue;
  valorizations: Array<Valorization>;
  update: (valorization: Valorization) => void;
}
export const SetForIssue: FunctionComponent<SetForIssueProps> = ({ issue, valorizations, update }) => (
  <SetByType update={ update } valorizations={ valorizations }>
    <b><T>valorizations_setForIssue_title</T></b>
  </SetByType>
);

interface SetForAssignmentProps {
  assignment: Assignment;
  valorizations: Array<Valorization>;
  update: (valorization: Valorization) => void;
}
export const SetForAssignment: FunctionComponent<SetForAssignmentProps> = ({ assignment, valorizations, update }) => (
  <SetByType update={ update } valorizations={ valorizations }>
    <b><T bind={{ assignment: assignment.bsIdNumber }}>valorizations_setForAssignment_title</T></b>
  </SetByType>
);

interface SetByTypeProps {
  valorizations: Array<Valorization>;
  update: (valorization: Valorization) => void;
}
const SetByType: FunctionComponent<PropsWithChildren<SetByTypeProps>> = ({ children: title, valorizations, update }) => {
  const [isOpen, open, close, toggle] = useOpenCloseToggle();

  const acl = useService<AclService>('acl');
  const canSeeSomeCost = React.useMemo(() => {
    return acl.connectedUserIsAllow('equipments', 'readCost')
      || acl.connectedUserIsAllow('supplies', 'readCost')
      || acl.connectedUserIsAllow('users', 'readCost')
      || acl.connectedUserIsAllow('teams', 'readCost');
  }, [acl]);

  return (
    <div className="bs-valorizations-set" onClick={ toggle }>
      <Grid>
        <Grid.Row>
          <Grid.Column width={ 13 }>
            <div className="bs-valorizations-set-title">
            {
              title
            }
            </div>
          </Grid.Column>
          <Grid.Column width={ 3 }>
            <div className='bs-valorizations-set-controlOpen'>
              <span><T>{ isOpen ? 'valorizations_setByType_hideDetail' : 'valorizations_setByType_showDetail'}</T></span>
              <span className={ `fa fa-caret-${ isOpen ? 'up' : 'down' }` } />
            </div>
          </Grid.Column>
        </Grid.Row>
        <Display.If condition={ canSeeSomeCost }>
          <Grid.Row>
            <Grid.Column>
              <Summary valorizations={ valorizations } />
            </Grid.Column>
          </Grid.Row>
        </Display.If>
        <Display.If condition={ isOpen }>
          <Grid.Row>
            <Grid.Column>
            {
              valorizations.map(valorization => (
                <div className="bs-valorizations-set-item">
                  <PartItem valorization={ valorization } update={ update } />
                </div>
              ))
            }
            </Grid.Column>
          </Grid.Row>
        </Display.If>
      </Grid>
    </div>
  );
};

export default SetByType;