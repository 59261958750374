// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-select{
  font-size: 12px;
}
.bs-old-select-display-default{
  padding: 0px 10px;
  box-sizing: border-box;
  min-height: 27px;
  border: 1px solid #e3dfdc;
  background: #f2eeeb;
  color: #666;
  font-weight: 550;
  border-radius: 3px;
  font-size: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.bs-old-select-values-container {
  position: relative;
}
.bs-old-select-values-content {
  position: absolute;
  z-index: 1;
  max-height: 100px;
  width: 100%;
  border-bottom: 1px solid #cecbc8;
  border-left: 1px solid #cecbc8;
  border-right: 1px solid #cecbc8;
  display: flex;
  flex-direction: column;
  background-color: rgba(255,255,255, 0.95);
}

.bs-old-select-values-item {
  cursor: pointer;
  opacity: 0.8;
  padding: 5px 8px;
  font-size: 1em;;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

.bs-old-select-values-item:hover {
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./../common/components/old/input/select.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;AACA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;EACX,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,UAAU;EACV,iBAAiB;EACjB,WAAW;EACX,gCAAgC;EAChC,8BAA8B;EAC9B,+BAA+B;EAC/B,aAAa;EACb,sBAAsB;EACtB,yCAAyC;AAC3C;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,8CAA8C;AAChD;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".bs-old-select{\n  font-size: 12px;\n}\n.bs-old-select-display-default{\n  padding: 0px 10px;\n  box-sizing: border-box;\n  min-height: 27px;\n  border: 1px solid #e3dfdc;\n  background: #f2eeeb;\n  color: #666;\n  font-weight: 550;\n  border-radius: 3px;\n  font-size: 1em;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.bs-old-select-values-container {\n  position: relative;\n}\n.bs-old-select-values-content {\n  position: absolute;\n  z-index: 1;\n  max-height: 100px;\n  width: 100%;\n  border-bottom: 1px solid #cecbc8;\n  border-left: 1px solid #cecbc8;\n  border-right: 1px solid #cecbc8;\n  display: flex;\n  flex-direction: column;\n  background-color: rgba(255,255,255, 0.95);\n}\n\n.bs-old-select-values-item {\n  cursor: pointer;\n  opacity: 0.8;\n  padding: 5px 8px;\n  font-size: 1em;;\n  border-bottom: 1px solid rgba(51, 51, 51, 0.1);\n}\n\n.bs-old-select-values-item:hover {\n  opacity: 1;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
