// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-user-item {
  height: 160px;
}

.bs-user-item-description {
  display: flex;
  justify-content: space-between;
}

.bs-user-item .bs-item2-content{
  padding: 10px;
}

.bs-user-item-action-tooltip-container {
  background-color: white;
  color: #5e676c;
  border-radius: 5px;
}

.bs-user-item-email{
  color: rgb(94, 103, 108);
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/entities/user/item.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,wBAAwB;EACxB,gBAAgB;AAClB","sourcesContent":[".bs-user-item {\n  height: 160px;\n}\n\n.bs-user-item-description {\n  display: flex;\n  justify-content: space-between;\n}\n\n.bs-user-item .bs-item2-content{\n  padding: 10px;\n}\n\n.bs-user-item-action-tooltip-container {\n  background-color: white;\n  color: #5e676c;\n  border-radius: 5px;\n}\n\n.bs-user-item-email{\n  color: rgb(94, 103, 108);\n  margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
