import React from 'react';
import T from "@uBehaviour/i18n";

interface ErrorDeleteDisplayProps {
  accept: () => void;
  close: () => void;
}
const ErrorDeleteDisplay: React.FunctionComponent<ErrorDeleteDisplayProps> = ({ accept, close }) => {
  const acceptAndClose: () => void = React.useCallback(() => { accept(); close(); }, [accept, close]);

  return (
    <div className="bs-modal-assignments-alert">
      <div><T>assignment_is_valorized_cant_delete</T></div>
      <div>
        <div onClick={ acceptAndClose }>Ok</div> 
      </div>
    </div>
  );
};

export default ErrorDeleteDisplay;