import React, { Component } from 'react'
 

class CheckboxField extends Component {
    constructor() {
        super()
        this._input = React.createRef();
        this.onClick = this.onClick.bind(this)
    }

    onClick(value) {
        return this.props.input.onChange(this.props.input.value = this._input.current.checked)
    }

    render() {
        const {
            label,
            name,
            input,
            initialValues,
            containerClassName,
            hideError,
            meta: { error, touched },
            style,
            className = 'checkbox inline-block',
            testid
        } = this.props

        return (
            <div style={ style || { minHeight: '0px' } } className={ containerClassName }>
                <div className={ className }>
                    <label>
                        <input
                            type='checkbox'
                            ref={ this._input }
                            name={ name }
                            value={ true }
                            onClick={ this.onClick.bind(this, true) }
                            defaultChecked={ initialValues && initialValues === true }
                            data-testid={ testid }
                        />
                        { label }
                    </label>
                </div>
                { touched && ((error && !hideError && <p className='error'>{error}</p>)) }
            </div>
        )
    }
}

export default CheckboxField