import { takeEvery, fork, take, put, select } from "redux-saga/effects"
import { eventChannel, END } from "redux-saga"


const actions         = {};
const reducers        = { namespaces: {}};
export const sagas    = [];
const initialStates   = {};
const defaultReducer  = { namespaces: {} };
const namespaces      = [];

const addNamespace = (namespace) => {
  if(namespaces.indexOf(namespace) === -1){
    namespaces.push(namespace);
  }
}

export const registerReducer = (namespace, action, handler) => {
  addNamespace(namespace);
  actions[action]                         = action;
  if(!reducers.namespaces[namespace]){
    reducers.namespaces[namespace] = {};
  }
  reducers.namespaces[namespace][action]  = handler;
}

export const registerDefaultReducer = (namespace, defaultReducerHandler) => {
  addNamespace(namespace);
  defaultReducer.namespaces[namespace] = defaultReducerHandler;
}

export const registerAction = (namespace, actionName, promiseHandler, successHandler, errorHandler = null) => {
  addNamespace(namespace);
  actions[actionName] = actionName;
  registerReducer(namespace, actionName + "_success", successHandler);
  if(errorHandler){
    registerReducer(namespace, actionName + "_error", successHandler);
  }
  sagas.push(takeEvery(actionName, function*(action){
    const state = yield select((state) => state)
    yield fork(function*(chan) {
      while (true) {
        const action = yield take(chan)
        yield put(action)
      }}, eventChannel(emmiter => {
        promiseHandler(action, state)
          .then(result => {
            emmiter({ type: `${actionName}_success`, result, action });
          }, error => {
            emmiter({ type: `${actionName}_error`, error });
          }).finally(() => {
            emmiter(END)
          });
      return () => {}
    }));
  }));
}


export const registerInitialstate = (namespace, initialStateHandler) => {
  addNamespace(namespace);
  initialStates[namespace] = initialStateHandler;
}

export const prepareReducer = (initialState = {}) => {
  const combinatedReducers = namespaces.reduce((combinated, namespace) => {
    combinated[namespace] = (state = initialStates[namespace](), action) => {
      if(reducers.namespaces[namespace] && reducers.namespaces[namespace][action.type]){
        return reducers.namespaces[namespace][action.type](state, action);
      }else if(defaultReducer.namespaces[namespace]){
        return defaultReducer.namespaces[namespace](state, action);
      }
      return state;
    }
    return combinated;
  }, initialState);
  return combinatedReducers;
}

export default actions;