import React from 'react'
import T from 'i18n-react'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
import _ from 'lodash'

const subEnumsCount = (types = [], key, field) => {
    if (!types || !types[0])
        return field.enums.length
    const sub = field.subs.find(sub => types.indexOf(sub.main) !== -1)
    return sub.enums.length
}


const setFilter = (unset, key, uniq, kill, e) => {
    e.stopPropagation()
    unset(key, uniq, kill)
}

const FilterHead = ({ field, displayFilters, isOpen, values, open, unsetFilter, className }) => (
    <div 
        className={ classNames(field.fixed ? 'c-issues-filter-head no-clickable' : 'c-issues-filter-head clickable', className) }
        onClick={ displayFilters }
    >
        <div className='c-issues-filter-head__title c-heading-medium'>
            { field.title }
        </div>
        <div className='c-issues-filter-head__content'>
            {
                values[field.key] && values[field.key].length && Array.isArray(values[field.key]) ?
                    <span className='c-issues-filter-head__count'>
                        <span>{ values[field.key].length }</span>
                        <span>/</span>
                        { 
                            field.subs ? 
                                <span>{ subEnumsCount(values.type, field.key, field) }</span>
                                : 
                                <span>{ field ? field.enums.length : 0 /*- rejectedLabelsLength*/ }</span>
                        }
                    </span>
                    : null
            }
            { (field.fixed || open) && field.inputType === 'checkbox' && values[field.key] && values[field.key].length !== 0 &&
                    <span className='c-issues-filter-head__select-all'>
                        <a onClick={ setFilter.bind(null, unsetFilter, field.key, false, null) }>
                            { field.selectAllLabel }
                        </a>
                    </span>
            }
            { (field.fixed || open) && field.inputType === 'customDatePicker' && values[field.key] &&
                    <span className='c-issues-filter-head__select-all'>
                        <a onClick={ setFilter.bind(null, unsetFilter, field.key, true, field.key) }>
                            { field.selectAllLabel }
                        </a>
                    </span>
            }

            {
                !field.fixed &&
                    <a className='c-issues-filter-head__caret'>
                        <FontAwesome
                            name={ isOpen ? 'caret-up' : 'caret-down' }
                            style={ { marginTop: '5px' } }
                        />
                    </a>
            }

        </div>
    </div>
)

export default FilterHead