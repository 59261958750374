import React                        from "react";
import { CSSTransition }            from 'react-transition-group'; 
import Slot                         from "@uComponents/slot2";

import "./flow.css";

export default class Flow extends React.Component{
  static Step = Slot();
  constructor(props){
    super(props);
    this.state = {
      contexts: [],
      step: 0
    };
  }
  clear(){
    this.setState({
      contexts: [],
      step: 0
    });
  }
  next(context){
    this.state.contexts.push(context);
    const steps = Flow.Step.get(this.props.children, true);
    if(this.state.step + 1 < steps.length){
      this.setState({
        contexts: this.state.contexts,
        step: this.state.step + 1
      });
      this.forceUpdate();
    }else{
      if(this.props.onFinish){
        this.props.onFinish(this.state.contexts);
      }
    }
  }
  previous(){
    if(this.state.step){
      this.state.contexts.pop();
      this.setState({
        contexts: this.state.contexts,
        step: this.state.step - 1
      });
    }
  }
  goTo(step){
    if(this.state.contexts.length > step){
      this.setState({
        step
      });
    }
  }
  render(){
    const steps = Flow.Step.get(this.props.children, true);
    if(this.state.step >= steps.length){
      return null;
    }
    if(this.props.animate){
      return React.createElement("div",{ className: "bs-flow-animate"}, steps.map((step, idx) => {
        return React.createElement(
          CSSTransition, {
            key: '' + idx,
            transitionEnter: true,
            in: idx === this.state.step,
            classNames: 'flow-slide',
            timeout: 1000
          },
            React.createElement("div", { className: "bs-flow-animate-container" },
              idx <= this.state.step
                ? (step instanceof Function
                  ? step(this.state.contexts.slice(0, idx), this, idx === this.state.step, idx)
                  : step)
                : React.createElement("div")
            )
        )}))
    }else{
      return steps[this.state.step] instanceof Function
        ? steps[this.state.step](this.state.contexts.slice(), this, true, this.state.step)
        : steps[this.state.step];
    }
  }
}