// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-select-supplyItem {
  width: 300px;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 10px;
  overflow: hidden;
}

.bs-select-supplyItem>div{
  overflow: hidden;
  text-overflow: ellipsis;
}
.bs-select-supplyItem:hover{
  cursor: pointer;
}
.bs-select-supplyItem-selected{
  color: #E84C24;
}`, "",{"version":3,"sources":["webpack://./src/entities/supply/select.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;AACA;EACE,eAAe;AACjB;AACA;EACE,cAAc;AAChB","sourcesContent":[".bs-select-supplyItem {\n  width: 300px;\n  display: flex;\n  flex-direction: column;\n  text-align: center;\n  margin: 10px;\n  overflow: hidden;\n}\n\n.bs-select-supplyItem>div{\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n.bs-select-supplyItem:hover{\n  cursor: pointer;\n}\n.bs-select-supplyItem-selected{\n  color: #E84C24;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
