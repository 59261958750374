import RequestsActions from 'client/core/RequestsActions'
import { action } from 'client/core/actions'

export const UPDATE = "ISSUE_UPDATE";

export const IssuesRequest = new RequestsActions('ISSUES')
export const IssueRequest = new RequestsActions('ISSUE')
export const IssueStarterRequest = new RequestsActions('ISSUE_STARTER')
export const RecurrencePreviewRequest = new RequestsActions('ISSUE_RECURRENCE')
export const NewIssueFilesRequest = new RequestsActions('ISSUE_FILES')
export const ServiceFilesRequest = new RequestsActions('ISSUE_SERVICE_FILES')
export const NearsRequest = new RequestsActions('NEARS_ISSUES')
export const RecurrenceRequest = new RequestsActions('ISSUE_RECURRENCE')
export const IssuesExportCSVRequest = new RequestsActions('ISSUES_EXPORT_CSV')
export const MessengerIssueRequest = new RequestsActions('ISSUE_MESSENGER_LIVE')
export const MessengerIssuesRequest = new RequestsActions('ISSUES_MESSENGER_LIVE')

/*Filters*/
export const SaveIssuesFiltersRequest = new RequestsActions('SAVE_ISSUES_FILTERS', { excludedMethods: ['get'] })
export const IssuesAutocompleteRequest = new RequestsActions('ISSUES_AUTOCOMPLETE', { excludedMethods: ['post', 'put', 'delete'] })
export const IssueMatchFilters = new RequestsActions('ISSUE_MATCH_FILTERS', { excludedMethods: ['post', 'put', 'delete'] })

export const SET_ISSUES_FILTERS_VALUE = 'SET_ISSUES_FILTERS_VALUE'
export const setIssuesFiltersValue = (payload) =>
  action(SET_ISSUES_FILTERS_VALUE, { payload })

export const RESET_ISSUES_FILTERS_VALUES = 'RESET_ISSUES_FILTERS_VALUES'
export const resetFiltersValues = () =>
  action(RESET_ISSUES_FILTERS_VALUES)

export const RESET_ISSUES_FILTERS_SEARCH = 'RESET_ISSUES_FILTERS_SEARCH'
export const resetFiltersSearch = () =>
  action(RESET_ISSUES_FILTERS_SEARCH)

export const SET_ISSUES_HOT_BOUNDS_FILTERS = 'SET_ISSUES_HOT_BOUNDS_FILTERS'
export const setHotBoundsFilters = (payload) => action('SET_ISSUES_HOT_BOUNDS_FILTERS', { payload })

export const SET_ISSUES_SEARCH_VALUE = 'SET_ISSUES_SEARCH_VALUE'
export const setIssuesSearchValue = (payload) => action('SET_ISSUES_SEARCH_VALUE', { payload })

/*Navigation*/
export const GO_TO_ISSUE = 'GO_TO_ISSUE'
export const goToIssue = (payload) => action(GO_TO_ISSUE, { payload })

export const CLOSE_ISSUE = 'CLOSE_ISSUE'
export const closeIssue = (payload) => action(CLOSE_ISSUE, { payload })

/*TODO: use object (payload) and not 3 params*/

export const UNSET_ISSUES_FILTER_VALUE = 'UNSET_ISSUES_FILTER_VALUE'
export const unSetFilter = (key, uniq, kill) =>
  action(UNSET_ISSUES_FILTER_VALUE, { payload: { key, uniq, kill } })

export const LOAD_ISSUES_FILTERS = 'LOAD_ISSUES_FILTERS'
export const loadFilters = (values, options) =>
  action(LOAD_ISSUES_FILTERS, { payload: { values, options } })

export const ONLY_SEARCH_FILTERS_VALUE = 'ONLY_SEARCH_FILTERS_VALUE'
export const onlySearch = () =>
  action(ONLY_SEARCH_FILTERS_VALUE)

export const REQUEST_ISSUES = 'REQUEST_ISSUES'
export const requestIssues = (options = {}) =>
  action(REQUEST_ISSUES, { payload: { options } })

/*Force count*/
export const FORCE_ISSUES_COUNT = 'FORCE_ISSUES_COUNT'
export const forceIssuesCount = (count) =>
  action(FORCE_ISSUES_COUNT, { payload: { count } })

/*Set value*/
export const ISSUE_SET_VALUES = 'ISSUE_SET_VALUES'
export const setValues = (payload) => action(ISSUE_SET_VALUES, { payload })

/*Set current*/
export const SET_CURRENT_ISSUE = 'SET_CURRENT_ISSUE'
export const setCurrentIssue = (_id) =>
  action(SET_CURRENT_ISSUE, { _id })

export const CLEAR_ISSUES = 'CLEAR_ISSUES'
export const clearIssues = () => action(CLEAR_ISSUES)

/*Display near issue*/
export const DISPLAY_NEAR_ISSUE = 'DISPLAY_NEAR_ISSUE'
export const displayNearIssue = (_id) =>
  action(DISPLAY_NEAR_ISSUE, { _id })

/*Owner change (update form store)*/
export const ISSUE_OWNER_CHANGE = 'ISSUE_OWNER_CHANGE'
export const IssueOwnerChange = (_id) => action(ISSUE_OWNER_CHANGE, { _id })

/*List scroll position*/
export const ISSUES_LIST_SCROLL_POSITION = 'ISSUES_LIST_SCROLL_POSITION'
export const setListScrollPosition = (position) => action(ISSUES_LIST_SCROLL_POSITION, { position })

/*New issue: upload files*/
export const NEW_ISSUE_UPLOAD_FILES = 'NEW_ISSUE_UPLOAD_FILES'
export const newIssueUploadFile = (data) => action(NEW_ISSUE_UPLOAD_FILES, data)

/*New issue: show loader*/
export const NEW_ISSUE_SHOW_LOADER = 'NEW_ISSUE_SHOW_LOADER'
export const newIssueShowLoader = () => action(NEW_ISSUE_SHOW_LOADER)

/*New issue: submit*/
export const NEW_ISSUE_SUBMIT = 'NEW_ISSUE_SUBMIT'
export const newIssueSubmit = (payload) => action(NEW_ISSUE_SUBMIT, payload)

/*Confirmations*/
export const ADD_ISSUE_CONFIRMATION = 'ADD_ISSUE_CONFIRMATION'
export const addIssueConfirmation = (payload) => action(ADD_ISSUE_CONFIRMATION, { payload })
export const REMOVE_ISSUE_CONFIRMATION = 'REMOVE_ISSUE_CONFIRMATION'
export const removeIssueConfirmation = (payload) => action(REMOVE_ISSUE_CONFIRMATION, { payload })

/*Map*/
export const ISSUES_IN_MAP_BOUNDS = 'ISSUES_IN_MAP_BOUNDS'
export const issuesInMapBounds = (issues) => action(ISSUES_IN_MAP_BOUNDS, { payload: { issues } })

/*Alerts*/
export const ADD_ISSUE_ALERT = 'ADD_ISSUE_ALERT'
export const addIssueAlert = (payload) => action(ADD_ISSUE_ALERT, { payload })
export const REMOVE_ISSUE_ALERT = 'REMOVE_ISSUE_ALERT'
export const removeIssueAlert = (payload) => action(REMOVE_ISSUE_ALERT, { payload })

/*Modals*/
export const HIDE_NEW_ISSUE_MASTER_ERROR_ATTRIBUTION = 'HIDE_NEW_ISSUE_MASTER_ERROR_ATTRIBUTION'
export const hideNewIssueMasterErrorModal = () => action(HIDE_NEW_ISSUE_MASTER_ERROR_ATTRIBUTION)

export const DISPLAY_UPDATE_RECURRENCE_MODAL = 'DISPLAY_UPDATE_RECURRENCE_MODAL'
export const displayUpdateRecurrenceModal = (payload) => action(DISPLAY_UPDATE_RECURRENCE_MODAL, { payload })

/*Reset new issue form*/

export const RESET_NEW_ISSUE_FORM = 'RESET_NEW_ISSUE_FORM'
export const resetNewIssueForm = () => action(RESET_NEW_ISSUE_FORM)

/*Issues list select*/
export const ISSUES_LIST_ACTIVE_SELECT = 'ISSUES_LIST_ACTIVE_SELECT'
export const issuesListActiveSelect = () => action(ISSUES_LIST_ACTIVE_SELECT)

export const ISSUES_LIST_SELECT_ISSUE = 'ISSUES_LIST_SELECT_ISSUE'
export const issuesListSelectIssue = (payload) => action(ISSUES_LIST_SELECT_ISSUE, { payload })

export const ISSUES_LIST_UNSELECT_ISSUES = 'ISSUES_LIST_UNSELECT_ISSUES'
export const issuesListUnselectAll = () => action(ISSUES_LIST_UNSELECT_ISSUES)

export const ISSUES_LIST_SELECT_ALL_ISSUES = 'ISSUES_LIST_SELECT_ALL_ISSUES'
export const issuesListSelectAll = () => action(ISSUES_LIST_SELECT_ALL_ISSUES)