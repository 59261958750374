import React        from "react";
import Layout       from "@components/formLayout";
import Form         from '@cBehaviour/form';
import T            from '@cBehaviour/i18n';
import Equipment    from "@entities/equipment";
import Observer     from "@common/lib/observer";

export default class EquipmentForm extends React.Component{
  constructor(props){
    super(props);
    this._observer = new Observer();
  }
  render(){
    const props = this.props;

    const id = props.id === "new" ? null : props.id;
    
    return (
      <Layout close={ props.close }>
        <Layout.Title>
          <Form.Observer observer={ this._observer }>
          {equipment => (
            <div>
              <T>equipment</T>
              <span> --- </span>
              <span>{ equipment.name }</span>
            </div>
          )}
          </Form.Observer>
        </Layout.Title>
        <Layout.Form>
          <Equipment.Form id={ id } observer={ this._observer }/>
        </Layout.Form>
      </Layout>
    )
  }
};