import React from 'react'
import T from 'i18n-react'
import { Modal } from 'react-bootstrap'
import Media from 'client/services/media'

const RemovePDFModal = ({ show, onHide, currentPDF = {}, removePDF }) => (
  <Modal show={ show } onHide={ onHide } aria-labelledby='contained-modal-title-lg' bsSize="large" className='modal-helper'>
    <Modal.Header closeButton>
      <Modal.Title>
        { T.translate('delete_pdf_title') }
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='row-fluid'>
        <p>
          { T.translate('delete_pdf_confirmation') }
        </p>
      </div>
      <div className='row-fluid centered'>
        { currentPDF ? <img className='img-polaroid' src={ currentPDF.original || Media.original(currentPDF) } style={ { maxHeight: '300px' } }/> : null }
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button className='button-a' onClick={ onHide }>
        { T.translate('cancel') }
      </button>
      <button className='button-a orange' onClick={ removePDF.bind(null, currentPDF) }>
        { T.translate('remove') }
      </button>
    </Modal.Footer>
  </Modal>
)

export default RemovePDFModal