//import { fetchEntity } from 'client/core/sagas'
import { fork, put, take, race, takeLatest, select, all } from 'redux-saga/effects'
import * as sagaEffects from 'redux-saga/effects'
import { callApi, fetchApi, makeEndpoint } from 'client/core/api'
import application from '@root/app.services'

const tokenName = application.getService("session").tokenName;

/*
    #Params
        request: action request entity
        api: function calling api
    #Methods
        success: generatior function
        failure: generatior function
*/

class RequestSagas {
  constructor(requestAction, api, options = {}) {
    this.requestAction = requestAction
    this.api = api
    this.saga = this.saga.bind(this)
    this.start = this.start.bind(this)
    this.success = this.success.bind(this)
    this.failure = this.failure.bind(this)
    this.options = options
  }

  * start(action) {
    const { actionData } = action
    yield put(this.requestAction.actions.request({ payload: { actionData } }))
    const endpoint = makeEndpoint(this.api.endpoint, action.params)
    const { method } = this.api

    const headers = []
    if (action.isomorphic && action.user)
      headers.push({ key: 'isomorphic-user', value: action.user._id })
    headers.push({
      key: 'authorization',
      value: yield select(state => state.session && state.session[tokenName] ? `Bearer ${state.session[tokenName]}` : null)
    })
    headers.push({
      key: 'lang',
      value: yield select(state => state.app && state.app.lang)
    })

    const emitter = callApi(endpoint, this.api.method, action.payload, action.files,
      this.requestAction.actions, actionData, this.options.progress, action.query, this.api.version)
    yield fork(this.progressListener, emitter)
  }

  * request() {

  }

  * success() {
    /*if (__DEV)
            console.info(this.request.TYPES.SUCCESS)*/
  }

  * failure() {
    /*if (__DEV)
            console.warn(this.request.TYPES.FAILURE)*/
  }

  * progressListener(chan) {
    while (true) {
      const action = yield take(chan)
      yield put(action)
    }
  }

  * saga() {
    const effect = this.options.effect ? sagaEffects[this.options.effect] : takeLatest
    yield all([
      effect(this.requestAction.ACTION, this.start),
      effect(this.requestAction.TYPES.REQUEST, this.request),
      effect(this.requestAction.TYPES.SUCCESS, this.success),
      effect(this.requestAction.TYPES.FAILURE, this.failure)
    ])
  }
}

export default RequestSagas

