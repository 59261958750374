import React, { Statement } from "@uTypes/business/Valorization";
import { FunctionComponent } from "react";
import { Grid } from "semantic-ui-react";
import Money from "@cComponents/money";

interface FooterProps {
  statements: Array<Statement>;
}
const Footer: FunctionComponent<FooterProps> = ({ statements }) => (
  <Grid.Row>
    <Grid.Column width="13"></Grid.Column>
    <Grid.Column width="3" textAlign='center'><b><Money value={statements.reduce<number>((total, statement) => total + statement.cost, 0)} /></b></Grid.Column>
  </Grid.Row>
);

export default Footer;
