import Field from '@common/components/field';
import Input from '@common/components/input';
import Form from '@universal/behaviour/form';
import { DayAndMonth, Generic } from '@universal/types/business/Tenant';
import React, { FunctionComponent } from 'react';
import AddableList from '@cComponents/addableList';

import './generics.css'

const getDefaultGeneric = () => {
  return {
    discriminator: "dayAndMonth",
    name: "",
    params: {
      day: 1,
      month: 0
    }
  };
}

interface GenericSpecialisationProps<T extends Generic = Generic> {
  value: T["params"];
}
const _in = (value) => value + 1;
const _out = (value) => value - 1;
const DayAndMonthForm: FunctionComponent<GenericSpecialisationProps<DayAndMonth>> = ({ value  }) => (
  <div className='bs-tenant-form-holidayDays-generic-dayAndMonth'>
    <Field.Standart name="params.day">
      <Field.Label>Jour</Field.Label>
      <Field.Input><Input.Numeric min={1} max={31} numericType="integer"/></Field.Input>
    </Field.Standart>
    <Field.Standart name="params.month">
      <Field.Label>Mois</Field.Label>
      <Field.Input><Input.Numeric min={1} max={12} numericType="integer" in={ _in } out={ _out }/></Field.Input>
    </Field.Standart>
  </div>
);
interface GenericFormProps {
  value: Generic;
  index: number;
  onChange: (value: Generic, index: number) => void;
}

const GenericForm: FunctionComponent<GenericFormProps> = ({ value, index, onChange }) => {
  let Component: null | FunctionComponent<GenericSpecialisationProps> = null;
  switch(value.discriminator){
    case "dayAndMonth": Component = DayAndMonthForm; break;
    default: throw new Error(`Unknow discriminator for tenant.holidayDays.generics: "${value.discriminator}"`);
  }

  const transmitChange = React.useCallback((form, value: Generic) => {
    onChange({ ...value, discriminator: "dayAndMonth" }, index);
    return value;
  }, [onChange, index])

  return (
    <Form.Simple onChange={ transmitChange } value={ value }>
      <Field.Standart name="name">
        <Field.Label>Nom</Field.Label>
        <Field.Input><Input.Text/></Field.Input>
      </Field.Standart>
      <Component value={ value } />
    </Form.Simple>
  );
}

interface GenericsProps {
  value: Generic[];
  onChange: (value: Generic[]) => void;
}

let nbrDelete = 0;

const Generics: FunctionComponent<GenericsProps> = ({ value, onChange }) => {
  const hasOneEmpty = React.useMemo(() => value.some(v => !v.name), [value]);
  const updateArrayOnChangeAndTransmit = React.useCallback((updatedValue: Generic, index: number) => {
    const newValue = value.slice();
    newValue[index] = updatedValue;
    onChange(newValue);
  }, [onChange, value]);

  const updateArrayOnDeleteAndTransmit = React.useCallback((index: number) => {
    const newValue = value.slice();
    newValue.splice(index, 1);
    ++nbrDelete;
    onChange(newValue);
  }, [onChange, value]);

  const addNewInArrayAndTransmit = React.useCallback(() => {
    if (!hasOneEmpty) {
      const newValue = value.slice();
      onChange(newValue.concat([getDefaultGeneric()]));
    }
  }, [onChange, value]);

  const usedValues = React.useMemo(() => value, [value]);
  return (
    <AddableList 
      values={usedValues} 
      allowAddition={!hasOneEmpty}
      onAdd={addNewInArrayAndTransmit} 
      onDelete={updateArrayOnDeleteAndTransmit} 
      getKey={(index) => `${nbrDelete}-${index}`}
    >
      <AddableList.Item>
        {(value, index) => (
          <GenericForm
            value={ value }
            index={ index }
            onChange={ updateArrayOnChangeAndTransmit }
          />
        )}
      </AddableList.Item>
    </AddableList>
    
  );
}

export default Generics;