import * as React from 'react';

import useDataTestId from '../hooks/useDataTestId';

export interface Props {
  /**
   * Le data-testid qui doit être fusionné aux préfixes
   */
  dataTestId: string;
  /**
   * Séparateur souhaité pour séparer le data-testid et les différents préfixes (par défaut: "-")
   */
  separator?: string;
  /**
   *
   * @param dataTestId Le data-testid chainé aux différents préfixes
   * @returns
   */
  children: (dataTestId: string) => React.ReactElement | null;
}
/**
 * Composant permettant de récupérer le data-testid fusionné avec les préfixes potentiellement
 * renseigné plus haut dans l'arbre React
 */
const Accessor: React.FunctionComponent<Props> = ({ dataTestId, separator = '-', children }) => {
  const completeDataTestId = useDataTestId(dataTestId, separator);
  return children(completeDataTestId);
};

export default Accessor;
