import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";
import toDic        from "@cLib/toDic";
import Query        from '@uLib/query';
import useService from '@universal/behaviour/hooks/useService';
import useHelper from '@universal/behaviour/hooks/useHelper';

const SubBuilding =  ({}) => {
  const api = useService("api");
  const currentTenant = useService("currentTenant");
  const queryHelper = useHelper("query");

  return (
    <Filter.Generic 
      deshydrate={value => value._id}
      hydrate={values => api.service("buildings", "places").execute(Query.joinWithOptimizer({_id: {$in: values }}, { tenant: currentTenant.currentId }), null, null, values.length).then(subBuildings => toDic(subBuildings, "_id"))}
      multiple
      name="subs_buildings"
      buildQuery={values => ({ "location.place": { $in: values.map(v => v._id) } })}
      stringify={value => { return (<><T>issue_filter_subs_buildings</T> : {`${value.buildingName} -- ${value.name}`}</>)}}
    >
      {(values, add, drop, clear) => {
        let query = { disabled:false };
        if(values.length){
          query["_id"] = { $nin: values.map(v => v._id) };
        }
        query = queryHelper.completeQueryWithBuildings(query, (query, buildings) => ({ ...query, building: { $in: buildings }}));
        return (
          <Collapsable>
            <Collapsable.Title><T>issue_filter_subs_buildings</T></Collapsable.Title>
            <Collapsable.Content>
              <Input.Selectable 
                value={ values.slice() }
                onChange={(vIds, values, isAdded) => { isAdded ? add(values, ["buildings"]) : drop(values) }}
                source={ (query, sort, offset, pageSize) => api.service("buildings", "places").execute(Query.joinWithOptimizer(query, { tenant: currentTenant.currentId }), sort, offset, pageSize) }
                query={ query }
                sort={{ buildingName: 1, name:1 }}
                pageSize={ 25 }
                textify={ subBuilding => `${subBuilding.buildingName} -- ${subBuilding.name}` }
                filterQuery={ value => ({ $or:[
                  { name: { '$regex': value, '$options': 'i' }},
                  { buildingName: { '$regex': value, '$options': 'i' }}
                ]})}
              >
                {data => (<div className="bs-issues-filters-item-line">{`${data.buildingName} -- ${data.name}`}</div>)}
              </Input.Selectable>
            </Collapsable.Content>
          </Collapsable>
        )
      }}
    </Filter.Generic>
  );
};

export default SubBuilding;