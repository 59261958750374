// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-old-input-password {
  display: flex;
  align-items: center;
  justify-content: right;
  position: relative;
}

.bs-old-input-password .bs-old-input {
  display: flex;
  align-items: center;
  justify-content: right;
}

.bs-old-input-password .bs-icon {
  position: absolute;
  color: #2c3e50;
  right: 10px;
}`, "",{"version":3,"sources":["webpack://./../common/components/old/input/password.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,WAAW;AACb","sourcesContent":[".bs-old-input-password {\n  display: flex;\n  align-items: center;\n  justify-content: right;\n  position: relative;\n}\n\n.bs-old-input-password .bs-old-input {\n  display: flex;\n  align-items: center;\n  justify-content: right;\n}\n\n.bs-old-input-password .bs-icon {\n  position: absolute;\n  color: #2c3e50;\n  right: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
