// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-equipmentForm {
  display: flex;
}

.bs-equipmentForm .bs-equipmentForm-picture {
  padding: 0 15px;
}

.bs-equipmentForm .bs-equipmentForm-content {
  flex-grow: 1;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/entities/equipment/form.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,2BAA2B;AAC7B","sourcesContent":[".bs-equipmentForm {\n  display: flex;\n}\n\n.bs-equipmentForm .bs-equipmentForm-picture {\n  padding: 0 15px;\n}\n\n.bs-equipmentForm .bs-equipmentForm-content {\n  flex-grow: 1;\n  padding: 0 15px;\n  display: flex;\n  flex-direction: column;\n  justify-content: flex-start;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
