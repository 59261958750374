// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-selectTenant {
    display: flex;
    align-items: center;
    width: 200px;
}

.bs-selectTenant > div:first-child {
    width: 100%;
}

.bs-selectTenant .bs-selectedTenant {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #999;
    padding: 5px 10px;
    border: 1px solid grey;
    border-radius: 3px;
  }
  
  .bs-selectTenant .bs-selectedTenant .bs-close {
    padding-left: 5px;
  }
  
  .bs-selectTenant .bs-selectedTenant .bs-close:hover {
    cursor: pointer;
  }

  .bs-selectedTenant > span:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }`, "",{"version":3,"sources":["webpack://./src/entities/tenant/selectCurrentTenant.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,WAAW;IACX,iBAAiB;IACjB,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;EACzB","sourcesContent":[".bs-selectTenant {\n    display: flex;\n    align-items: center;\n    width: 200px;\n}\n\n.bs-selectTenant > div:first-child {\n    width: 100%;\n}\n\n.bs-selectTenant .bs-selectedTenant {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    color: #999;\n    padding: 5px 10px;\n    border: 1px solid grey;\n    border-radius: 3px;\n  }\n  \n  .bs-selectTenant .bs-selectedTenant .bs-close {\n    padding-left: 5px;\n  }\n  \n  .bs-selectTenant .bs-selectedTenant .bs-close:hover {\n    cursor: pointer;\n  }\n\n  .bs-selectedTenant > span:first-child {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
