import React from "react";
import _      from "lodash";
import Display from "@uComponents/displayIf";
import moment       from "moment";
import DayPicker from 'react-day-picker/DayPicker';
import MomentLocaleUtils from 'react-day-picker/moment';
import FocusBlur from "@cComponents/focusBlur";


const keyRegEx    = /[0-9]/;

const emptyDate = "__/__/____"

class Date extends React.Component{
  constructor(props){
    super(props);
    this.state                = { cursor: 0, displaySelect: false };
    this._input               = React.createRef();
    this._onKeyDown           = this._onKeyDown.bind(this);
    this._onChange            = this._onChange.bind(this);
    this.switchSelectDisplay  = this.switchSelectDisplay.bind(this);
    this.componentDidUpdate   = _.debounce(this.componentDidUpdate.bind(this), 0);
    this._onDayClick          = this._onDayClick.bind(this);
    this._listenerCloseSelect = this._listenerCloseSelect.bind(this);
    this._value               = this._getValueFromProps();
  }
  get value(){
    return this._valueDate;
  }
  
  _getValueFromProps(value) {
    return this.props.value ? moment(this.props.value).format("DD/MM/YYYY") : emptyDate;
  }

  _onKeyDown(ev){
    let cursor = ev.currentTarget.selectionStart;

    if(ev.key.length === 1 && !(keyRegEx.test(ev.key) && cursor < 10)){
      ev.preventDefault();
      return;
    }
    if(ev.key.length > 1 && ["Backspace", "Delete"].indexOf(ev.key) === -1){
      return;
    }

    let key = ev.key;

    if(key === "Backspace"){
      if(cursor === 0){
        return;
      }
      cursor -= 1;
      key = "_";
    } else if(key ===  "Delete"){
      key = "_";
    }
    if(cursor === 2 || cursor === 5){
      ++cursor;
    }
    
    this._value = this._value.substring(0, cursor) + key + this._value.substring(cursor + key.length);

    if(ev.key !== "Backspace"){
      ++cursor;
      if(cursor === 2 || cursor === 5){
        ++cursor;
      }
    } else if(cursor === 3 || cursor === 6){
      --cursor;
    }
    
    this._onChange(this._value);
    this.setState({ cursor })
  }
  _onChange(value){
    if(this.props.onChange && (value === emptyDate || moment(value, "DD/MM/YYYY").format("DD/MM/YYYY") === value)){
      value = value === emptyDate ? null : moment(value + " 00:00:00.000", "DD/MM/YYYY HH:mm:ss.SSS").toDate();
      this._valueDate = value;
      if((!value && this.props.value) || (value && (!this.props.value || value.getTime() !== this.props.value.getTime()))){
        this.props.onChange(value);
      }
    }
  }
  _onDayClick(date){
    this._value = moment(date).format("DD/MM/YYYY");
    this.closeSelect();
    this._onChange(this._value);
  }
  componentDidUpdate(oldProps){
    if(this._input.current){
      this._input.current.setSelectionRange(this.state.cursor, this.state.cursor);
    }
  }
  shouldComponentUpdate(nextProps){
    if(this.props.value !== nextProps.value){
      this._value = nextProps.value ? moment(nextProps.value).format("DD/MM/YYYY") : emptyDate;
    }
    return true;
  }
  forceRerenderFromProps(value) {
    this._value = this._getValueFromProps();
    this._valueDate = null;
    this.forceUpdate();
  }
  _listenerCloseSelect(){
    this.closeSelect();
  }
  isSelectOpened(){
    return this.state.displaySelect;
  }
  openSelect(){
    if(!this.isSelectOpened()){
      this.switchSelectDisplay();
    }
  }
  closeSelect(){
    if(this.isSelectOpened()){
      this.switchSelectDisplay();
    }
  }
  switchSelectDisplay(){
    this.setState({ displaySelect: !this.state.displaySelect });
  }
  render(){
    return (
      <FocusBlur onBlur={ this._listenerCloseSelect }>
        <div className="bs-input-wa-date">
          <div>
            <div>
              <input ref={ this._input } type="text" onKeyDown={ this._onKeyDown } value={ this._value }/>
              <span className="fa fa-calendar" onClick={ (ev) => { this.switchSelectDisplay(); }}/>
            </div>
            <Display.If condition={ this.isSelectOpened() }>
              <div onClick={ (ev) => { ev.stopPropagation(); }}>
                <DayPicker 
                  localeUtils={ MomentLocaleUtils }
                  locale={ "fr" }
                  onDayClick={ this._onDayClick }
                  selectedDays={ this._valueDate }
                  month={ this._valueDate ? moment(this._valueDate).startOf("month").toDate() : null }
                />
              </div>
            </Display.If>
          </div>
        </div>
      </FocusBlur>
    );
  }
};


export default Date;