import React, { FunctionComponent }        from "react";
import _            from "lodash";
import User         from "@entities/user";
import Tenant       from "@entities/tenant";
import T            from "@cBehaviour/i18n";
import Filter       from "@cComponents/filter";
import FilteredList from "@cFeatures/filter/list";
import Input        from "@cComponents/input";
import Collapsable  from "@cComponents/collapsable";
import useService   from '@universal/behaviour/hooks/useService';
import LoginAsManagerService from '@uServices/loginAsSessionManager';
import RepositoryService from '@uServices/repository';
import Data               from "@uBehaviour/data";
import { UserCollaboratorRoles, UserProEmailRoles } from '@universal/types/business/User';


const roles             = Object.values(UserProEmailRoles).filter(role => !['starter'].includes(role));;
const collaboratorRoles = Object.values(UserCollaboratorRoles);

interface ListProps {

}

const List: FunctionComponent<ListProps> = ({}) => {
  const loginAsSessionManagerService = useService<LoginAsManagerService>('loginAsSessionManager');
  const repositoryService = useService<RepositoryService>('repository');

  const _textSearchBuildQuery = React.useCallback((value: String) => {
    return { $or: [
      { fullname: { '$regex': value, '$options' : 'i' }},
      { email:    { '$regex': value, '$options' : 'i' }}
    ]};
  }, []);

  const _query = { $and: [
    { _id: { $nin : loginAsSessionManagerService.favoritesUsers.map(f => f)} },
    { $or: [
      { discriminator: "collaborator", disabled: false },
      { discriminator: "pro", "tenants.disabled": false }
    ]}
  ]};

  return (
    <FilteredList>
      <FilteredList.Filter>
        <Collapsable alwaysOpen>
          <Collapsable.Title><T>users_filter_collaborator_role</T></Collapsable.Title>
          <Collapsable.Content>
            <Filter.Generic multiple name="collaborator_roles" buildQuery={values => ({ "roles": { $in: values } })} stringify={value => (<><T>users_filter_discriminator</T>: <T>{"users_filter_collaborator_role_" + value}</T></>)}>
              {(values, add, drop, clear) => (
                <Input.Checkbox.Btn onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
                  {
                    collaboratorRoles.map(role => (<Input.Checkbox.Value key={role} value={role}><T>{"users_filter_collaborator_role_" + role}</T></Input.Checkbox.Value>))
                  }
                </Input.Checkbox.Btn>
              )}
            </Filter.Generic>
          </Collapsable.Content>
        </Collapsable>
        <Collapsable alwaysOpen>
          <Collapsable.Title><T >roles</T></Collapsable.Title>
          <Collapsable.Content>
            <Filter.Generic multiple name="roles" buildQuery={values => ({ "tenants.roles": { $in: values } })} stringify={value => (<><T>role</T>: <T>{`users_filter_pro_role_${value}`}</T></>)}>
            {(values, add, drop, clear) => (
              <Input.Checkbox.Btn onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
              {
                roles.map(role => (
                  <Input.Checkbox.Value key={role} value={role}>
                    <T>{`users_filter_pro_role_${role}`}</T>
                  </Input.Checkbox.Value>
                ))
              }
              </Input.Checkbox.Btn>
            )}
            </Filter.Generic>
          </Collapsable.Content>
        </Collapsable>
        <Collapsable alwaysOpen>
          <Collapsable.Title><T>loginAs_filter_tenants</T></Collapsable.Title>
          <Collapsable.Content>
            <Filter.Generic multiple name="tenant" buildQuery={values => ({ "tenants.tenant": { $in: values.map(v => v._id) } })} stringify={value => { return (<><T>loginAs_filter_tenant</T> : <T>{value.name}</T></>)}}>
              {(values, add, drop, clear) => {
                let query = {};
                if(values.length){
                  query = { _id: { $nin: values.map(v => v._id) }};
                }
                return (
                  <Input.Selectable 
                    value={ values.map(v => repositoryService.get("Tenant").key.extract(v))}
                    onChange={(vIds, values, isAdded) => { isAdded ? add(values) : drop(values) }}
                    model={ repositoryService.get("Tenant") }
                    query={ query }
                    textify={ tenant => tenant.name.fr }
                    filterQuery={ value => ({ "name.fr": { '$regex': value, '$options': 'i' } }) }
                    load={ Tenant.Item.Load }
                  >
                    <Tenant.Item />
                  </Input.Selectable>
                );
              }}
            </Filter.Generic>
          </Collapsable.Content>
        </Collapsable>
      </FilteredList.Filter>
      <FilteredList.Header>
        <Filter.Generic name="text" buildQuery={ value => _textSearchBuildQuery(value) } stringify={value => (<><b><T>contains</T></b>: {value}</>)}>
        {(value, set, clear) => (
          <Input.Text className="bs-filter-text" value={ value } onChange={_.debounce(value => { value ? set(value) : clear()}, 1000)}>
            <T>freesearch</T>
          </Input.Text>
        )}
        </Filter.Generic>
      </FilteredList.Header>
      <FilteredList.List>
      {composeQuery => (
        <Data.List model={"User"} query={composeQuery(_query)} sort={{ firstname: 1, lastname: 1 }} load={{ "tenants.tenant": true, "tenants.buildings.ids":true, avatar: true }}>
        {(user) => (
          <div key={ user._id } onClick={() => loginAsSessionManagerService.setAsFavorite(user)}>
            <User.Item data={user}>
              <User.Item.Details>
                <User.Details user={ user }/>
              </User.Item.Details>
            </User.Item>
          </div>
        )}
        </Data.List>
      )}
      </FilteredList.List>
    </FilteredList>
  )
}

export default List;
