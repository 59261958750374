// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-building-item {
  overflow: hidden;
  height: 100px;
}

.bs-building-item .bs-action {
  display: flex;
  justify-content: space-between;
  color: #27ae60;
}

.bs-building-item .bs-building-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}

.bs-building-item .bs-building-description>div:first-child {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.bs-building-item .bs-item2-content{
  padding: 10px;
}`, "",{"version":3,"sources":["webpack://./src/entities/building/item.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".bs-building-item {\n  overflow: hidden;\n  height: 100px;\n}\n\n.bs-building-item .bs-action {\n  display: flex;\n  justify-content: space-between;\n  color: #27ae60;\n}\n\n.bs-building-item .bs-building-description {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  overflow: hidden;\n}\n\n.bs-building-item .bs-building-description>div:first-child {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n\n.bs-building-item .bs-item2-content{\n  padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
