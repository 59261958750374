import React from 'react'

const MatchAndLinkBsId = ({ string, goToIssue, issue }) => {
    const regExp = /(#[0-9]{1,})/g
    if (!string || !string.match(regExp) || !issue)
        return <span>{ string }</span>
        
    let match
    const positions = []
    const result = []
    while (match = regExp.exec(string)) {
        positions.push({ 
            start: string.indexOf(match[1]),
            end: string.indexOf(match[1]) + match[1].length,
            match: match[1]
        })
    }
    let currentIndex = 0
    positions.forEach((position) => {
        const bsId = position.match.replace('#', '')
        result.push(string.substring(currentIndex, position.start))
        result.push(
            <a 
                onClick={ bsId !== issue.bsIdNumber ? goToIssue.bind(null, { _id: issue.bsId.replace(issue.bsIdNumber, bsId) }) : null }
                key={ position.start }
            >
                { string.substring(position.start, position.end) }
            </a>
        )
        currentIndex = position.end
    })
    return <span>{ 
        result.map((data) => data)    
    }</span>
}

export default MatchAndLinkBsId