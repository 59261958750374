import React        from 'react';
import Address      from '@cFeatures/address';
import File         from '@cComponents/file';
import T            from '@cBehaviour/i18n';
import Display      from '@cComponents/displayIf';
import Item         from '@cComponents/item';
import Layout       from '@cComponents/layout';
import Title        from '@cComponents/title';
import Money        from '@cComponents/money';

import './item.css';


const getValorizationType = (valorizationType) => {
  switch(valorizationType) {
    case 'h':
      return <T>hour</T>;
    case 'km':
      return <T>kilometer</T>;
    default:
      return <T>unknown</T>
  }
};
const EquipementItem = ({ data: { files, name, valorization, location }}) => (
  <Item type="compact" className="bs-equipment-item">
    <Item.Illustration>
      <File file={ files && files.length > 0 ? files[0] : null } height={100} width={100} fit="cover"/>
    </Item.Illustration>
    <Item.Content>
      <Layout.Standart className="bs-equipment-content" stretch>
          <Layout.Standart.Header className="bs-equipment-title">
              <Title>{ name }</Title>
          </Layout.Standart.Header>
          <Layout.Standart.Content className="bs-equipment-description"> 
            <div>
              <span><T>cost</T> : </span>
              <Display.If condition={valorization && valorization.cost}>
                <Display.Then>
                {()=>(
                  <span>
                    <Money value={valorization.cost} />
                    <span>&nbsp;/&nbsp;</span>
                    <span>{ getValorizationType(valorization.type) }</span>
                  </span>
                )}
                </Display.Then>
                <Display.Else>
                  <T>none</T>
                </Display.Else>
              </Display.If>
            </div>
          </Layout.Standart.Content>
        <Layout.Standart.Footer className="bs-action">
          <Display.If condition={ location }>
            <Address location={ location } />
          </Display.If>
        </Layout.Standart.Footer>
      </Layout.Standart>
    </Item.Content>
  </Item>
);

EquipementItem.load = {
  files:true,
  "location.building": true 
}

export default EquipementItem;