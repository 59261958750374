// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-input-datetime{
  display: flex;
  flex-direction: row;
  width: fit-content;
}
.bs-input-datetime>*:nth-child(n+2){
  margin-left: 10px;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/datetime.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".bs-input-datetime{\n  display: flex;\n  flex-direction: row;\n  width: fit-content;\n}\n.bs-input-datetime>*:nth-child(n+2){\n  margin-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
