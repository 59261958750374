// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-selectable{
  display: flex;
  align-items: center;
}
.bs-selectable>div:first-child{
  padding-right: 10px;
}
.bs-selectable.selected>div:first-child{
  padding-right: 8px;
}
.bs-selectable>div:last-child{
  flex-grow: 1;
  width: calc(100% - 22.58px);
}
.bs-selectable>div>.fa:hover{
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../common/components/selectable.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;AACA;EACE,mBAAmB;AACrB;AACA;EACE,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,2BAA2B;AAC7B;AACA;EACE,eAAe;AACjB","sourcesContent":[".bs-selectable{\n  display: flex;\n  align-items: center;\n}\n.bs-selectable>div:first-child{\n  padding-right: 10px;\n}\n.bs-selectable.selected>div:first-child{\n  padding-right: 8px;\n}\n.bs-selectable>div:last-child{\n  flex-grow: 1;\n  width: calc(100% - 22.58px);\n}\n.bs-selectable>div>.fa:hover{\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
