import React, { Component } from 'react'
import { reduxForm, Field, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import InputField from 'client/features/form/old/components/InputField.jsx'
import SelectField from 'client/features/form/old/components/SelectField.jsx'
import REGEXP from 'common/REGEXP'
import T from 'i18n-react'

const validate = values => {
  const errors = {}
  if (values.email && !REGEXP.validations.email.test(values.email))
    errors.email = T.translate('form_validation_add_email')
    //if (!values.message)
    //    errors.message = T.translate('form_validation_requiredValue')

  return errors
}

class IssueRequestorForm extends Component {
  constructor(props) {
    super()
    this.typeChange = this.typeChange.bind(this)
    this.getTypes = this.getTypes.bind(this)
  }


  typeChange(value) {
    if (this.props.formValues.type === 'userPro' && value !== 'userPro') {
      this.props.change('firstName', '')
      this.props.change('lastName', '')
      this.props.change('email', '')
    } else if (value === 'userPro') {
      this.props.change('firstName', this.props.issue.userPro.firstName)
      this.props.change('lastName', this.props.issue.userPro.lastName)
      this.props.change('email', this.props.issue.userPro.email)
    }
  }

  getTypes() {
    const { issue, user } = this.props
    const types = [
      {
        value: 'citizen',
        label: T.translate('citizen')
      },
      {
        value: 'internal',
        label: T.translate('internal')
      }
    ]

    if (issue.userPro && issue.requestor && issue.requestor.type === 'userPro') {
      types.unshift({
        value: 'userPro',
        label: issue.userPro._id === user._id ? T.translate('me') : `${issue.userPro.firstName} ${issue.userPro.lastName}`
      })
    }

    return types
  }

  render() {
    const { handleSubmit, cancel, formValues, issue } = this.props
    const disabledMainsFields = formValues.type === 'userPro' || formValues.type === 'bsCitizen'
    const showPhoneField = formValues.type !== 'userPro'

    return (
      <form onSubmit={ handleSubmit }>
              {
          !issue.sender &&
                    <Field
                          name='type'
                          component={ SelectField }
                          label={ T.translate('requestor') }
                          disabled={ issue.sender ? true : false }
                          initialValue={ this.props.initialValues.type }
                          className='c-select'
                          change={ this.typeChange }
                          hideEmptyOption
                          options={ this.getTypes() }
                        />
        }
        <Field
          name='firstName'
          component={ InputField }
          label={ T.translate('first_name') }
          disabled={ disabledMainsFields }
          autoComplete='off'
        />
              <Field
          name='lastName'
          component={ InputField }
          label={ T.translate('last_name') }
          disabled={ disabledMainsFields }
          autoComplete='off'
        />
              <Field
          name='email' type='email'
          component={ InputField }
          label={ T.translate('email') }
          disabled={ disabledMainsFields }
          autoComplete='off'
        />
        {
          showPhoneField &&
                    <Field
                          name='phone'
                          component={ InputField }
                          label={ T.translate('phone') }
                          autoComplete='off'
                        />
        }
        <div className='pull-right' style={ { marginTop: 8 } }>
          <a className='c-button u-small u-margin-right-tiny' onClick={ cancel }>
            { T.translate('cancel') }
          </a>
          <button className='c-button u-small c-button--brand' type='submit'>
            { T.translate('confirm_update') }
          </button>
        </div>
      </form>
    )
  }
}

const selector = formValueSelector('issueRequestor')

const mapStateToProps = (state, props) => (
  {
    formValues: {
      type: selector(state, 'type')
    }
  }
)


export default connect(mapStateToProps)(IssueRequestorForm = reduxForm({
  form: 'issueRequestor',
  initialValues: {},
  validate
})(IssueRequestorForm))