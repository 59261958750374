import React, { Component } from 'react'
import GMAutocompleteField from 'client/features/form/old/components/GMAutocompleteField.jsx'
import { Field, reduxForm, formValueSelector } from 'redux-form'
import { connect } from 'react-redux'
import { Modal } from 'react-bootstrap'
import Address from 'client/features/overlay-view/components/Address.jsx'
import Map from 'client/features/common/components/Map.jsx'
import Nears from 'client/features/issues/components/details/Nears.jsx'
import Place from 'client/features/issues/components/form/Place.jsx'
import T from 'i18n-react'
import FontAwesome from 'react-fontawesome'
 
const validate = values => {
  const errors = {}
  if (!values.location)
    errors.location = T.translate('form_validation_location_requiredAddress')
  else if (values.location && ((!values.location.locality && !values.location.zip) || !values.location.street))
    errors.location = T.translate('form_validation_location_unauthorizedAddress')

  if (!values.building)
    errors.building = T.translate('form_validation_requiredValue')
  else if (values.building && values.building.places && values.building.places[0] && !values.place)
    errors.place = T.translate('form_validation_requiredValue')

  return errors
}

/*TODO: component for autocomplete map for issue address*/

class IssueMapModal extends Component {
  constructor(props) {
    super()
    this._map = React.createRef();
    this._location = React.createRef();
    this.edit = this.edit.bind(this)
    this.exit = this.exit.bind(this)
    this.buildingChange = this.buildingChange.bind(this)
    this.patrimonyChange = this.patrimonyChange.bind(this)
    this.state = {
      edit: false
    }
  }

  edit() {
    this.setState({ edit: !this.state.edit })
  }

  exit() {
    this.setState({
      edit: false
    })
  }

  buildingChange(building) {
    this.props.change('place', null)
    this.props.change('locationInfo', null)
    this.props.change('building', building)
  }

  patrimonyChange(patrimony) {
    this.props.change('patrimony', patrimony)
  }

  render() {
    const {
      issue,
      show,
      onHide,
      nears = [],
      goToIssue,
      onEnter,
      loading,
      displayNearIssue,
      currentNear,
      session,
      formValues,
      showMoreIssues
    } = this.props

    return (
      <Modal show={ show } onHide={ onHide } onEnter={ onEnter } onExit={ this.exit } aria-labelledby='contained-modal-title-lg' className='modal-large modal-helper bs-old-issues-map-modal' bsSize="large">
        <Modal.Header closeButton>
          <Modal.Title>
            <Address
              building={ issue.building }
              place={ issue.place }
              locationInfo={ issue.locationInfo }
              roadInfo={ issue.roadInfo }
              address={ issue.subject }
            />
            {
              !this.state.edit &&
              <span className='pull-right' style={ { marginTop: '-19px', marginRight: '40px' } }>
                {
                  session.user.authorizations.issue.editAddress &&
                  <a className='button-a' onClick={ this.edit }>
                    <FontAwesome name='pencil' />&nbsp;
                    { T.translate(issue.type === 'publicSpace' ? 'update_issue_address' : issue.type === 'building' ? 'update_issue_building' : 'update_issue_patrimony') }
                  </a>
                }
              </span>
            }
            {
              issue.type === 'building' && (issue.building.externalLink || (issue.place && issue.place.externalLink)) &&
              <span className='pull-right' style={ { marginTop: '-19px', marginRight: '8px' } }>
                <a className='button-a' href={ issue.place && issue.place.externalLink ? issue.place.externalLink : issue.building.externalLink } target='_blank'>
                  <FontAwesome name='map' />&nbsp;
                  { T.translate('building_external_link') }
                </a>
              </span>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {
            !this.state.edit ?
              <div className='o-layout'>
                <div className={ session.user.authorizations.issue.nears ? 'o-layout__item u-8of12' : 'o-layout__item u-12of12' }>
                  {
                    issue.latitude && <Map
                      latitude={ issue.latitude }
                      longitude={ issue.longitude }
                      marker={ issue.marker }
                      user={ session.user }
                      ref='map'
                      currentNear={ currentNear }
                      containerHeight='600px'
                      options={ {
                        draggable: true,
                        scrollwheel: true,
                        disableDoubleClickZoom: false,
                        panControl: true,
                        fullscreenControl: false
                      } }
                    />
                  }
                </div>
                {
                  session.user.authorizations.issue.nears &&
                  <div className='o-layout__item u-4of12'>
                    <Nears
                      issues={ nears }
                      type={ issue.type }
                      displayNearIssue={ displayNearIssue }
                      goToIssue={ goToIssue }
                      loading={ loading }
                      displayAll={ issue.type === 'publicSpace' }
                      showMoreIssues={ showMoreIssues }
                      merge={ this.props.merge }
                      title={ this.props.nearsTitle }
                    />
                  </div>
                }
              </div>
              :
              issue.type === 'publicSpace' ?
                <div className='row-fluid' id='update-issue-address'>
                  <div className='span12'>
                    <Field
                      name='location'
                      component={ GMAutocompleteField }
                      country={ issue.country }
                      defaultLatitude={ issue.latitude }
                      defaultLongitude={ issue.longitude }
                      ref='location'
                      change={ this.props.change }
                    />
                  </div>
                </div>
                :
                issue.type === 'building' ?
                  <Place
                    name='building'
                    placeholder='form_building'
                    options={
                      session.buildings.filter((option) =>
                        !session.user.buildings || !session.user.buildings[0] || session.user.buildings.indexOf(option._id) !== -1)
                        .map((option) => ({ ...option, label: option.name, value: option._id }))
                    }
                    value={ formValues.building }
                    change={ this.buildingChange }
                    sub={ {
                      placeholder: 'form_sub_building',
                      change: this.placeChange,
                      options: formValues.building &&
                                                formValues.building.places &&
                                                formValues.building.places[0] &&
                                                formValues.building.places.map((place) => ({ ...place, value: place._id })),
                      value: formValues.place
                    } }
                    resource={ session.user.resource }
                    goToIssue = { this.goToIssue }
                  />
                  :
                  <Place
                    name='patrimony'
                    placeholder='form_patrimony'
                    options={ session.patrimonies }
                    value={ formValues.patrimony }
                    change={ this.patrimonyChange }
                    resource={ session.user.resource }
                    goToIssue = { this.goToIssue }
                    hideMapIfNoLocation
                  />
          }
        </Modal.Body>
        {
          this.state.edit &&
          <Modal.Footer>
            <button className='button-a' onClick={ this.edit }>
              { T.translate('cancel') }
            </button>
            <button className='button-a orange' type='submit' onClick={ this.props.handleSubmit }>
              { T.translate('confirm_update') }
            </button>
          </Modal.Footer>
        }
      </Modal>
    )
  }
}

const selector = formValueSelector('issueAddress')

const mapStateToProps = (state, props) => {
  const initialValues = {
    locationInfo: props.issue.locationInfo
  }
  if (props.issue.building && props.issue.building._id)
    initialValues.building = { ...props.issue.building }
  if (props.issue.place && props.issue.place._id)
    initialValues.place = { ...props.issue.place }
  if (props.issue.patrimony && props.issue.patrimony._id)
    initialValues.patrimony = { ...props.issue.patrimony }

  return({
    formValues: {
      building: selector(state, 'building'),
      patrimony: selector(state, 'patrimony')
    },
    initialValues
  })
}

export default connect(mapStateToProps)(IssueMapModal = reduxForm({
  form: 'issueAddress',
  validate
})(IssueMapModal))