import React, { PureComponent } from 'react'
import T from 'i18n-react'
import Confirmation from 'client/features/common/components/Confirmation.jsx'
import ButtonGroupSelector from '../../../common/components/ButtonGroupSelector'
import Application from "@uBehaviour/application";

class IssueDetailsDisplay extends PureComponent {
  constructor() {
    super()
    this._displayChange = this._displayChange.bind(this)
    this._renderVisibilityLabel = this._renderVisibilityLabel.bind(this)
  }

  _displayChange() {
    const display = this._display.current.checked
    this.props.displayChange(display)
  }

  _renderVisibilityLabel() {
    return <T.span text={ this.props.display.value ? 'public' : 'private' }/>
  }

  _renderVisibilityToggle(display, type) {
    const options = [
      {
        label: T.translate('public'),
        value: true,
        isSelected: display
      },
      {
        label: T.translate('private'),
        value: false,
        isSelected: !display
      }
    ]
    return <ButtonGroupSelector onSelect={ this.props.displayChange } options={ options }/>
  }
  
  render() {
    const edit = this.props.edit && this.props.type === "publicSpace" && this.props.currentTenant.current.discriminator === "town";

    return <div className='c-issue-detail-field'>
      <div className='o-layout o-layout--middle'>
        <div className='o-layout__item u-1of3'>
          <div className='c-issue-detail-field__label'>{T.translate('visibility')}:</div>
        </div>
        <div className='o-layout__item u-2of3'>
          <div className='c-confirmation-container'>
            <div className='c-confirmation-container__component c-issue-detail-field__content'>
              { edit ? this._renderVisibilityToggle(this.props.display.value, this.props.type) : this._renderVisibilityLabel() }
            </div>
            <Confirmation data={ this.props.confirmation }/>
          </div>
        </div>
      </div>
    </div>
  }
}

export default Application.Service.forward(["currentTenant"], IssueDetailsDisplay);