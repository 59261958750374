import React       from 'react';
import Address     from '@cFeatures/address';
import Display     from '@cComponents/displayIf';
import Map         from '@common/components/oldmap';
import Application from "@uBehaviour/application";
import './location.css';

export default class Location extends React.Component {
  render() {
    const { location, height, icon } = this.props;

    return React.createElement(Application.Service, { name: ["tenant-geolocation"] }, ([tenantGeolocation]) => (
      <div className="bs-location">
        <div style={{ height: height }}>
          <Map default={tenantGeolocation.current} zoomOnScroll={true} >
            { location.position
              ? (<Map.Shape definition={location.position.toGeoJSON()} options={{ icon: icon }} />)
              : null
            }
          </Map>
        </div>
        <Display.If condition={location && location.address} >
          <div className="bs-location-address">
            <Address location={location} />
          </div>
        </Display.If>
      </div>
    ));
  }
}