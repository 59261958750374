import React          from "react";
import Filter         from "@cComponents/filter";
import T              from "@cBehaviour/i18n";
import Input          from "@cComponents/input";
import Collapsable    from "@cComponents/collapsable";
import Display        from "@cComponents/displayIf";
import moment         from "moment";
import InputPeriod    from "@cComponents/input/period";
import Application    from "@uBehaviour/application";    
import cFilters        from "@entities/filters";
         

import "./index.css";
import Acl from "@universal/behaviour/acl";
import useAcl from "@universal/behaviour/hooks/useAcl";
import { combinate } from "@universal/lib/query";

const buildQueryPeriod = (value) => {
  let period;
  switch(value.type){
    case "current_week":
      period = { $gte: moment().startOf("week").toISOString(), $lt: moment().startOf("week").add(1, "week").toISOString() };
      break;
    case "last_week":
      period = { $gte: moment().startOf("week").subtract(1, "week").toISOString(), $lt: moment().startOf("week").toISOString() };
      break;
    case "current_month":
      period = { $gte: moment().startOf("month").toISOString(), $lt: moment().startOf("month").add(1, "month").toISOString() };
      break;
    case "last_month":
      period = { $gte: moment().startOf("month").subtract(1, "month").toISOString(), $lt: moment().startOf("month").toISOString() };
      break;
    case "3_last_month":
      period = { $gte: moment().startOf("month").subtract(3, "month").toISOString(), $lt: moment().startOf("month").toISOString() };
      break;
    case "6_last_month":
      period = { $gte: moment().startOf("month").subtract(6, "month").toISOString(), $lt: moment().startOf("month").toISOString() };
      break;
    case "current_year":
      period = { $gte: moment().startOf("year").toISOString(), $lt: moment().startOf("year").add(1, "year").toISOString() };
      break;
    case "last_year":
      period = { $gte: moment().startOf("year").subtract(1, "year").toISOString(), $lt: moment().startOf("year").toISOString() };
      break;
    case "custom_date":
      if(value.start && value.end){
        period = { $gte: moment(value.start).toISOString(), $lt: moment(value.end).toISOString() };
      }else{
        period = null;
      }
      break;
    default: throw new Error("Unknown type for buildQueryPeriod : " + value.type)
  }
  return { period }
}
const stringifyPeriod = (value) => {
  if(value.type !== "custom_date"){
    return (<T>{`statistics_${value.type}`}</T>)
  }else{
    if(value.start && value.end){
      return (<T bind={{ start: moment(value.start).format("DD/MM/YYYY"), end: moment(value.end).subtract(1, "day").format("DD/MM/YYYY")}}>statistics_custom_date_stringify</T>)
    }else{
      return (<T>statistics_custom_date</T>)
    }
  }
}
const Filters = (props) => {
  const allowToReadBuilding = useAcl("categories", "readBuilding");
  const allowToReadEquipment = useAcl("categories", "readEquipment");

  let categoryQuery = null;
  if(!allowToReadBuilding){
    categoryQuery = combinate("$and", categoryQuery, { type: { $ne: "building" }});
  }
  if(!allowToReadEquipment){
    categoryQuery = combinate("$and", categoryQuery, { type: { $ne: "equipment" }});
  }
  return (
    <>
      <Filter.Generic
        deshydrate={({ type, start, end }) => ({ type, start: start ? moment(start).toISOString(): null, end: end ? moment(end).toISOString() : null }) }
        hydrate={ 
          values => values.reduce((map, value) => { 
            map.set(value, { type: value.type, start: value.start ? moment(value.start).toDate() : null, end: value.end ? moment(value.end).toDate() : null });
            return map
          }, new Map())
        }
        name="period"
        buildQuery={buildQueryPeriod}
        stringify={stringifyPeriod}
      >
        {(value, set, clear) => (
          <Collapsable alwaysOpen>
            <Collapsable.Title><T>statistics_filters_period</T></Collapsable.Title>
            <Collapsable.Content>
                <>
                  <Input.Radio.BtnFilter onChange={(value) => { set({ type: value }); }} value={value && value.type}>
                    <Input.Radio.Value value={"current_week"}><T>statistics_current_week</T></Input.Radio.Value>
                    <Input.Radio.Value value={"last_week"}><T>statistics_last_week</T></Input.Radio.Value>
                    <Input.Radio.Value value={"current_month"}><T>statistics_current_month</T></Input.Radio.Value>
                    <Input.Radio.Value value={"last_month"}><T>statistics_last_month</T></Input.Radio.Value>
                    <Input.Radio.Value value={"3_last_month"}><T>statistics_3_last_month</T></Input.Radio.Value>
                    <Input.Radio.Value value={"6_last_month"}><T>statistics_6_last_month</T></Input.Radio.Value>
                    <Input.Radio.Value value={"current_year"}><T>statistics_current_year</T></Input.Radio.Value>
                    <Input.Radio.Value value={"last_year"}><T>statistics_last_year</T></Input.Radio.Value>
                    <Input.Radio.Value value={"custom_date"}><T>statistics_custom_date</T></Input.Radio.Value>
                  </Input.Radio.BtnFilter>
                  <Display.If condition={ value && value.type === "custom_date"}>
                    <div className="bs-filter-period">
                      <InputPeriod
                        allDay
                        start={value && value.start }
                        end={value && value.end }
                        onChange={ (start, end) =>  set({ type: "custom_date", start, end }) }
                      >
                        <InputPeriod.StartLabel>
                          <T>statistics_filter_period_input_period_start_label</T>
                        </InputPeriod.StartLabel>
                        <InputPeriod.EndLabel>
                          <T>statistics_filter_period_input_period_end_label</T>
                        </InputPeriod.EndLabel>
                      </InputPeriod>
                    </div>
                  </Display.If>
                </>
            </Collapsable.Content>
          </Collapsable>
        )}
      </Filter.Generic>
      <Acl.If resource="issues" action="readBuilding">
        <cFilters.Building />
        <cFilters.SubBuilding />
      </Acl.If>
      <cFilters.Category query={ categoryQuery }/>
      <cFilters.Manager />
      <cFilters.Team />
      <cFilters.Agent />
      <cFilters.Tag />
      <cFilters.RequestorType />
      <cFilters.CreatorType />
    </>
  );
};

export default Application.forward(["repository", "currentTenant", "i18n", "api"], [], Filters);