import APP from 'common/APP'
import URLS from 'constants/URLS'

import { registerDefaultReducer, registerInitialstate } from "client/lib/reduxSaga";

const Pages = {
  CENTERED: 'centered',
  LARGE: 'large',
  FULL: 'full',
  mains: [URLS.issues.index, URLS.calendar.show, URLS.home.show, URLS.issues.list, URLS.issues.light, URLS.issues.map]
}

export const initialState = {
  lang: 'fr',
  languages: APP.locales.languages,
  lastPathname: null,
  currentPathname: null
}

const initializeState = () => Object.assign({}, initialState)

registerInitialstate("app", initializeState);
export default registerDefaultReducer("app", function app(state = initializeState(), action = {}) {
  const pathname = action.payload && action.payload.location ? action.payload.location.pathname : null
  switch (action.type) {
  case "set_application":
    return {
      ...state,
      newApplication: action.payload
    };
  default:
    return state
  }
});

const isMainPathname = (pathname) => {
  if (Pages.mains.indexOf(pathname) !== -1)
    return pathname
  return false
}