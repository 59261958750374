// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-fieldGroup {
  margin: 10px 0 20px 0;
  padding: 0px 0px;
  box-sizing: border-box;
}

.bs-fieldGroup .bs-fieldGroup-title {
  padding: 5px;
  border-bottom: 2px solid #cecbc8;
  font-size: 1.2em;
}

.bs-fieldGroup .bs-fieldGroup-content>* {
  margin-top: 15px;
}

.bs-fieldGroup .bs-fieldGroup-title {
  color: #5a6266;
}
.bs-fieldGroup .bs-fieldGroup-title > div:first-child {
  font-weight: 600;
}

.bs-fieldGroup .bs-subTitle {
  font-size:  0.8em;
}

.bs-fieldGroup.bs-full {
  width: 100%;
}

.bs-fieldGroup.bs-full .bs-fieldGroup-content.bs-wrap {
  display: flex;
  flex-wrap:wrap;
  justify-content: space-between;
}

.bs-fieldGroup.bs-full .bs-fieldGroup-content.bs-wrap > * {
  width: 46%;
}

.bs-fieldGroup.bs-half {
  width: 49%;
}`, "",{"version":3,"sources":["webpack://./src/components/fieldGroup.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,cAAc;EACd,8BAA8B;AAChC;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".bs-fieldGroup {\n  margin: 10px 0 20px 0;\n  padding: 0px 0px;\n  box-sizing: border-box;\n}\n\n.bs-fieldGroup .bs-fieldGroup-title {\n  padding: 5px;\n  border-bottom: 2px solid #cecbc8;\n  font-size: 1.2em;\n}\n\n.bs-fieldGroup .bs-fieldGroup-content>* {\n  margin-top: 15px;\n}\n\n.bs-fieldGroup .bs-fieldGroup-title {\n  color: #5a6266;\n}\n.bs-fieldGroup .bs-fieldGroup-title > div:first-child {\n  font-weight: 600;\n}\n\n.bs-fieldGroup .bs-subTitle {\n  font-size:  0.8em;\n}\n\n.bs-fieldGroup.bs-full {\n  width: 100%;\n}\n\n.bs-fieldGroup.bs-full .bs-fieldGroup-content.bs-wrap {\n  display: flex;\n  flex-wrap:wrap;\n  justify-content: space-between;\n}\n\n.bs-fieldGroup.bs-full .bs-fieldGroup-content.bs-wrap > * {\n  width: 46%;\n}\n\n.bs-fieldGroup.bs-half {\n  width: 49%;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
