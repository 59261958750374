// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-connected-favorite-user {
  color: #fa7251;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
}

.bs-connected-favorite-user-fullname {
  font-weight: bold;
}

.bs-connected-favorite-user-roles {
  font-style: italic;
  padding-left: 10px;
}

.bs-connected-favorite-user-drop {
  padding-left: 5px;
}

.bs-connected-favorite-user-drop:hover {
  cursor: pointer;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/hooks/useLoginAs/loginAs.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,UAAU;AACZ","sourcesContent":[".bs-connected-favorite-user {\n  color: #fa7251;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  text-align: left;\n}\n\n.bs-connected-favorite-user-fullname {\n  font-weight: bold;\n}\n\n.bs-connected-favorite-user-roles {\n  font-style: italic;\n  padding-left: 10px;\n}\n\n.bs-connected-favorite-user-drop {\n  padding-left: 5px;\n}\n\n.bs-connected-favorite-user-drop:hover {\n  cursor: pointer;\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
