// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bs-subscribe-group {
  margin-top: 20px;
  display: flex;
}
.bs-subscribe-subscription>div:first-child,
.bs-subscribe-group>div:first-child {
  flex-grow: 2;
  flex-basis: 0;
}

.bs-subscribe-subscription {
  margin-top: 5px;
  display: flex;
}

.bs-subscribe-channelSetterHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
}
.bs-subscribe-subscription>div:last-child .bs-input-checkbox-inline>*,
.bs-subscribe-channelSetterHeader>*{
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bs-subscribe-subscription>div:last-child .bs-input-checkbox-inline  .checkbox {
  padding: 0px;
}
.bs-subscribe-form-header-title {
  font-weight: bold;
}
.bs-subscribe-form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bs-subscribe-form-content {
  flex-grow: 1;
}

.bs-subscribe-form-footer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bs-subscribe-form-footer .fa {
  margin-left: 20px;
}`, "",{"version":3,"sources":["webpack://./../common/entities/subscription/components/subscribe.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,aAAa;AACf;AACA;;EAEE,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,aAAa;AACf;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,iBAAiB;AACnB;AACA;;EAEE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,YAAY;AACd;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,8BAA8B;AAChC;AACA;EACE,YAAY;AACd;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["\n.bs-subscribe-group {\n  margin-top: 20px;\n  display: flex;\n}\n.bs-subscribe-subscription>div:first-child,\n.bs-subscribe-group>div:first-child {\n  flex-grow: 2;\n  flex-basis: 0;\n}\n\n.bs-subscribe-subscription {\n  margin-top: 5px;\n  display: flex;\n}\n\n.bs-subscribe-channelSetterHeader {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-weight: bold;\n}\n.bs-subscribe-subscription>div:last-child .bs-input-checkbox-inline>*,\n.bs-subscribe-channelSetterHeader>*{\n  width: 80px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.bs-subscribe-subscription>div:last-child .bs-input-checkbox-inline  .checkbox {\n  padding: 0px;\n}\n.bs-subscribe-form-header-title {\n  font-weight: bold;\n}\n.bs-subscribe-form {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n}\n.bs-subscribe-form-content {\n  flex-grow: 1;\n}\n\n.bs-subscribe-form-footer {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.bs-subscribe-form-footer .fa {\n  margin-left: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
