import React from 'react';
import RepositoryService from '@uServices/repository';
import useService from './useService';

const defaultOn = (d) => d;

function useUpdatedData<T>(onUpdate: (data: T) => T = defaultOn, updatedData: T|null = null): [T|null, (data: T) => void, () => void]{
  const [data, setData] = React.useState<T|null>(updatedData);

  const open = React.useCallback((data: T) => setData(onUpdate(data)), [setData, onUpdate]);
  const close = React.useCallback(() => setData(null), [setData]);

  return [data, open, close];
}

export function useCreateUpdateData<T>(onCreate: () => T, onUpdate: (data: T) => T = defaultOn, updatedData: T|null = null): [T|null, () => void, (data: T) => void, () => void]{
    const [data, updateData, closeData] = useUpdatedData<T>(onUpdate, updatedData);
    const createData  = React.useCallback(() => {
      updateData(onCreate());
    },[updateData, onCreate]);
  return  [data, createData, updateData, closeData];
}

export function useCreateUpdateModelData<T>(modelName: string, onCreate: (data: T) => T = defaultOn, onUpdate: (data: T) => T = defaultOn, updatedData: T|null = null): [T|null, () => void, (data: T) => void, () => void]{
  const repository: RepositoryService = useService('repository');
  const model = React.useMemo(() => {
    return repository.get(modelName);
  }, [])
  const createData  = React.useCallback(() => {
    return onCreate(model.default as T);
  },[onCreate, model]);
  return  useCreateUpdateData<T>(createData, onUpdate, updatedData);
}

export default useUpdatedData;