import React, { FunctionComponent, PropsWithChildren } from "react";
import { Paginated } from "@cComponents/wizard";
import T             from "@uBehaviour/i18n";
import CTA           from "../../features/cta";
import Template      from "./template";
import PromotionalSubtitle from "./promotionalSubtitle";
import Text, { Style } from "@cComponents/text";
import Slot from "@universal/components/slot2";

import "./index.css";

const Image = Slot();

const TeamBase: FunctionComponent<PropsWithChildren> & { Image: typeof Image } = ({ children }) => {
  const image = Image.get(children);
  const restChildren = Image.not(children);

  return (
    <Template>
      <Template.Image>{ image }</Template.Image>
      <Template.Icon><span className="fa fa-user-o bs-featuresDiscovery-team-icon" /></Template.Icon>
      <Template.Title><Text style={ Style.huge.bold }><T>starter_featuresDiscovery_team_title</T></Text></Template.Title>
      { restChildren }
    </Template>
  );
};
TeamBase.Image = Image;

const TeamManageRoles: FunctionComponent = () => {
  const focusedText1 = <Text style={ Style.bold }> <T>starter_featuresDiscovery_team_manage_roles_focus_1</T> </Text>;
  const focusedText2 = <Text style={ Style.bold }> <T>starter_featuresDiscovery_team_manage_roles_focus_2</T> </Text>;

  return (
    <TeamBase>
      <Image><img src="/images/starter/featuresDiscovery/team_1.png" alt="Gestion des équipes"/></Image>
      <Text.Paragraph><T bind={{ focusedText1 }}>starter_featuresDiscovery_team_manage_roles_1</T></Text.Paragraph>
      <Text.Paragraph><T bind={{ focusedText2 }}>starter_featuresDiscovery_team_manage_roles_2</T></Text.Paragraph>
    </TeamBase>
  );
};


const TeamManageContacts: FunctionComponent = () => {
  const focusedText = <Text style={ Style.bold }><T>starter_featuresDiscovery_team_contacts_focus</T></Text>;

  return (
    <TeamBase>
      <Image><img src="/images/starter/featuresDiscovery/team_2.png" alt="Gestion des équipes"/></Image>
      <div className="bs-featuresDiscovery-content">
        <Text.Paragraph>
          <T bind={{ focusedText }}>starter_featuresDiscovery_team_contacts</T>
        </Text.Paragraph>
      </div>
    </TeamBase>
  );
};


const TeamUpsale: FunctionComponent = () => (
  <TeamBase>
    <Image><img src="/images/starter/featuresDiscovery/team_3.png" alt="Gestion des équipes"/></Image>
    <PromotionalSubtitle>
      <div className="bs-featuresDiscovery-content">      
        <Text>
          <T>starter_featuresDiscovery_team_upsale</T>
        </Text>
        <span className="bs-featuresDiscovery-content-CTA">
          <CTA.DiscoverOffers.Orange />
        </span>
      </div>     
    </PromotionalSubtitle>
  </TeamBase>
);

type TeamProps = {
  close: () => void,
};
const Team: FunctionComponent<TeamProps> = ({ close }) => (
  <Paginated close={ close } style={{ width: "920px", height: "540px" }}>
    <Paginated.Step>
      <TeamManageRoles />
    </Paginated.Step>
    <Paginated.Step>
      <TeamManageContacts />
    </Paginated.Step>
    <Paginated.Step>
      <TeamUpsale />
    </Paginated.Step>
  </Paginated>
);

export default Team;