import { Service }        from "@uLib/application";
import UrlBuilder         from "@uLib/urlBuilder";

export default class WeatherService extends Service{
  constructor(){
    super("weather", ["currentTenant", "http", "i18n"]);
  }
  start(){
    return this.waitReady(["currentTenant", "i18n", "http"]).then(([currentTenant, i18n, http]) => {
      this._request = http.create(() => Promise.resolve({
        url:'https://api.openweathermap.org/data/2.5/forecast/daily',
        mode: 'cors'
      }), "")
      .method("GET")
      .type("application/json")
      .call((units, appid, lon, lat, lang ) => {
        return {
          query: { units, appid, lon, lat, lang }
        }
      })
      .build();
      currentTenant.onServiceUpdated.addListener(this);
      return this._load(currentTenant, i18n)
    });
  }
  handleEvent = () => {
    this._load(
      this.application.getService("currentTenant"),
      this.application.getService("i18n")
    );
  }
  _load(currentTenant, i18n){
    if(currentTenant.isSelected()){
      return this._request.execute(
        "metric",
        "3d76a2ce7d0c28f007346ac6bea7fd6e",
        currentTenant.current.settings.defaultMap.position.coordinates[0],
        currentTenant.current.settings.defaultMap.position.coordinates[1],
        i18n.currentLanguage.bs
      ).then(result => {
        this._datas = result.list;
      });
    }else{
      this._datas = null;
      return Promise.resolve();
    }
  }
  has(){
    return !!this._datas;
  }
  get datas(){
    return this._datas.slice();
  }
}