import Text, { Style } from '@common/components/text';
import Slot from '@universal/components/slot2';
import React, { FunctionComponent, PropsWithChildren } from 'react';

const Before = Slot();
const After = Slot();

const Change: FunctionComponent<PropsWithChildren> & { Before: typeof Before, After: typeof After }= ({ children }) => (
  <>
    <Text>{ Before.get(children) }</Text>
    <Text style={ Style.bold }>&nbsp;&gt;&nbsp;</Text>
    <Text>{ After.get(children) }</Text>
  </>
);
Change.Before = Before;
Change.After = After;

export default Change;