import React            from 'react';
import Resource         from '@cLib/resource';
import CommonFile       from '@cComponents/file';
import Application       from "@uBehaviour/application";
import AsyncDisplay     from "@cComponents/asyncDisplay";


const RessourceContext         = React.createContext();

class RessourceManager extends React.Component {
  constructor(props){
    super(props);
    this._promises = [Resource.waitLoadingPromise];
  }
  ready(){
    Promise.all(this._promises).then(() => {
      if(this.props.onLoaded){
        this.props.onLoaded();
      }
    });
  }
  addRessource(ressource){
    this._promises.push(ressource.loadedPromise);
  }
  render(){
    return (
      <RessourceContext.Provider value={ this }>
        { this.props.children }
      </RessourceContext.Provider>
    )
  }
}
export class Ressource extends React.Component{
  constructor(props){
    super(props);
    this.loadedPromise = new Promise(resolve => {
      this._triggerLoaded = resolve;
    });
  }
  render(){
    return (
      <RessourceContext.Consumer>
      {(manager) => {
        manager.addRessource(this);
        return this.props.children(this._triggerLoaded);
      }}
      </RessourceContext.Consumer>
    );
  }
}

export const Image = (props) => React.createElement(Ressource, {} , loaded => React.createElement("img", {
  onLoad: loaded,
  onError: loaded,
  src: props.src
}));

export const File = (props) => React.createElement(Ressource, {} , loaded => React.createElement(CommonFile, {
  ...props,
  onLoad: loaded,
  onError: loaded
}));

const sequenceGeneatror = function*(nbr){ for(let i = 0; i < nbr; ++i) yield i; }
class PdfRenderer extends React.Component{
  state = {
    nbrPages: null
  }
  constructor(props){
    super(props);
    this.promises   = [];
    this._resolved  = false;
    this._nbrPages = null;
    this.props.api.service("files", "meta").execute(this.props.file._id)
    .then(result => this.setState({ nbrPages: result.page }));
  }
  _resolved(){
    if(!this._resolved){
      this._resolved = true;
      Promise.all(this.promises).then()
    }
  }
  onLoad(){
    if(this.props.onLoad){
      this.props.onLoad();
    }
  }
  onError(){
    if(this.props.onError){
      this.props.onError();
    }
  }
  render(){
    if(this.state.nbrPages === null){
      return null;
    }

    return (
      <AsyncDisplay whenReady={() => this.onLoad()}>
      {
        Array.from(sequenceGeneatror(this.state.nbrPages)).map(page => (
          <div className="bs-print-book-pdf">
            <AsyncDisplay.WaitMe key={ this.props.file.storageId + "_" + page }>
            {ready => React.createElement("img", {
              onLoad: ready,
              onError: ready,
              src: `${this.props.fileHelper.makeStorageUrl(this.props.file, {
                width: this.props.width,
                height: this.props.maxHeight,
                format:"jpg"
              })}?page=${page+1}`
            })}
            </AsyncDisplay.WaitMe>
          </div>
        ))
      }
      </AsyncDisplay>
    )
  }
}

export const Pdf = Application.forward(["api", "configuration"], [["file", "fileHelper"]], (props) => React.createElement(Ressource, {} , loaded => {
  return React.createElement(PdfRenderer, {
      ...props,
      onLoad: loaded,
      onError: loaded
  });
}));

export default RessourceManager;
