import React from 'react'
import T from 'i18n-react'
import FontAwesome from 'react-fontawesome'
import { formDays, formMonths, fourOrdinals } from 'client/features/issues/lib'


const renderRecurrenceInfo = (recurrence) => {
    let pre = T.translate('all_the')
    let label = ''
    let date = ''
    const day = formDays()[recurrence.repeat.day - 1 >= 0 ? recurrence.repeat.day - 1 : 6].label
    if (recurrence.type === 'weekly') {
        pre = T.translate('all_the_f')
        if (recurrence.repeat.recurrence !== 1) {
            label = `${recurrence.repeat.recurrence} `
        }
        label += T.translate('weeks_the')
        date = day
    } else if (recurrence.type === 'monthly') {
        label = T.translate('month_the')
        if (recurrence.repeat.recurrence !== 1)
            label = `${recurrence.repeat.recurrence} ${label}`
        if (recurrence.repeat.mode === 'monthlyDate')
            date += recurrence.repeat.dayDate
        else if (recurrence.repeat.mode === 'monthlyDay')
            date += `${fourOrdinals()[recurrence.repeat.dayNumber - 1].label} ${day}`
    } else if (recurrence.type === 'annual') {
        label = T.translate('year_the')
        if (recurrence.repeat.recurrence !== 1)
            label = `${recurrence.repeat.recurrence} ${label}`
        if (recurrence.repeat.mode === 'annualDate')
            date += `${recurrence.repeat.dayDate} ${formMonths()[recurrence.repeat.month].label}`
        if (recurrence.repeat.mode === 'annualDay')
            date += `${fourOrdinals()[recurrence.repeat.dayNumber - 1].label} ${day} ${T.translate('of_date')} ${formMonths()[recurrence.repeat.month].label}`
    }
    
    return (
        <span>{ pre } { label } { date }</span>
    )
}

const IssueRecurrenceTitle = ({ recurrence = {}, hideIcon, showInfo, infoAtTheLine }) => (
    <span>
        {
            !hideIcon && 
                <span>
                    <FontAwesome
                        name='repeat'
                        title={ T.translate('recurrence') }
                    />&nbsp;
                </span>
        }
        { T.translate('recurrence') } { T.translate(recurrence.type).toLowerCase() }
        {
            showInfo && infoAtTheLine ?
                 <br />
            : ' - '
        }
        {
            showInfo && !recurrence.finished && !recurrence.deleted &&
                renderRecurrenceInfo(recurrence)
        }
        {
            showInfo && !recurrence.finished && recurrence.deleted &&
                <small>({ T.translate('deleteIssueRecurrence') })</small>
        }
        {
            showInfo && recurrence.finished &&
                <small>({ T.translate('recurrence_finished') })</small>
        }
    </span>
)

export default IssueRecurrenceTitle