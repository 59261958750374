// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-issues-starter-history-item-container {
  position: relative;
  width: 100%;
  padding: var(--std-padding-light);
}

.bs-issues-starter-history-item-container-state {
  position: absolute;
  right: 0px;
  top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/entities/issues/components/starter/history.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,WAAW;EACX,iCAAiC;AACnC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,QAAQ;AACV","sourcesContent":[".bs-issues-starter-history-item-container {\n  position: relative;\n  width: 100%;\n  padding: var(--std-padding-light);\n}\n\n.bs-issues-starter-history-item-container-state {\n  position: absolute;\n  right: 0px;\n  top: 0px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
