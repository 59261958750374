// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-input-radio>div {
    display: flex;
}
.bs-input-radio-display>label{
  margin: 0px;
}
.bs-input-radio-inline{
  display: flex;
}
.bs-input-radio-column{
  display       : flex;
  flex-direction: column;
}

.bs-input-radio-withFrame label{
  border-color: grey;
  border-width: 1px;
}
.bs-input-radio-withFrame .bs-input-radio-column>label{
  display     : block;
  border-right-style: solid;
}
.bs-input-radio-withFrame .bs-input-radio-display>label{
  border-left-style  : solid;
  border-bottom-style: solid;
}
.bs-input-radio-withFrame .bs-input-radio-column>label:first-child,
.bs-input-radio-withFrame .bs-input-radio-inline>label {
  border-top-style: solid;
}
.bs-input-radio-withFrame .bs-input-radio-inline>label:first-child {
  border-top-left-radius   : 5px;
  border-bottom-left-radius: 5px;
}
.bs-input-radio-withFrame .bs-input-radio-inline>label:last-child {
  border-right-style        : solid;
  border-top-right-radius   : 5px;
  border-bottom-right-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./../common/components/input/radio.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;EACE,WAAW;AACb;AACA;EACE,aAAa;AACf;AACA;EACE,oBAAoB;EACpB,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;AACnB;AACA;EACE,mBAAmB;EACnB,yBAAyB;AAC3B;AACA;EACE,0BAA0B;EAC1B,0BAA0B;AAC5B;AACA;;EAEE,uBAAuB;AACzB;AACA;EACE,8BAA8B;EAC9B,8BAA8B;AAChC;AACA;EACE,iCAAiC;EACjC,+BAA+B;EAC/B,+BAA+B;AACjC","sourcesContent":[".bs-input-radio>div {\n    display: flex;\n}\n.bs-input-radio-display>label{\n  margin: 0px;\n}\n.bs-input-radio-inline{\n  display: flex;\n}\n.bs-input-radio-column{\n  display       : flex;\n  flex-direction: column;\n}\n\n.bs-input-radio-withFrame label{\n  border-color: grey;\n  border-width: 1px;\n}\n.bs-input-radio-withFrame .bs-input-radio-column>label{\n  display     : block;\n  border-right-style: solid;\n}\n.bs-input-radio-withFrame .bs-input-radio-display>label{\n  border-left-style  : solid;\n  border-bottom-style: solid;\n}\n.bs-input-radio-withFrame .bs-input-radio-column>label:first-child,\n.bs-input-radio-withFrame .bs-input-radio-inline>label {\n  border-top-style: solid;\n}\n.bs-input-radio-withFrame .bs-input-radio-inline>label:first-child {\n  border-top-left-radius   : 5px;\n  border-bottom-left-radius: 5px;\n}\n.bs-input-radio-withFrame .bs-input-radio-inline>label:last-child {\n  border-right-style        : solid;\n  border-top-right-radius   : 5px;\n  border-bottom-right-radius: 5px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
