// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-background{
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
}
.bs-background-header{
  flex-grow: 0;
}
.bs-background-content{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
}
.bs-background-content>div{
  
}
@media print {
  .bs-no-print{
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/print/background.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,YAAY;EACZ,aAAa;EACb,sBAAsB;AACxB;AACA;EACE,YAAY;AACd;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;AACd;AACA;;AAEA;AACA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".bs-background{\n  height: 100vh;\n  width: 100vw;\n  display: flex;\n  flex-direction: column;\n}\n.bs-background-header{\n  flex-grow: 0;\n}\n.bs-background-content{\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-grow: 1;\n}\n.bs-background-content>div{\n  \n}\n@media print {\n  .bs-no-print{\n    display: none;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
