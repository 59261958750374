import React        from "react";
import moment       from "moment";
import Data         from "@uBehaviour/data";
import Application  from '@uBehaviour/application';
import Display      from "@cComponents/displayIf";
import Book         from "./book";
import {
  BlockDescription,
  BlockPictures,
  BlockPdf
}                   from './components';   

import "./assignments.css";

const repeat = (c, n) => {
  let s = "";
  for(let i = 0; i < n; ++i){
    s += c;
  }
  return s;
}

const BlockWorkOrderReturn = () => (
  <div className="bs-print-issue-workOrderReturn">
    <p>
      <div className="bs-print-issue-workOrderReturn-state">
        <div><div><span className="fa fa-square-o" />&nbsp;Fait</div></div>&nbsp;
        <div><span><span className="fa fa-square-o" />&nbsp;Démarré</span></div>&nbsp;
        <div className="bs-print-issue-workOrderReturn-state-date">
          <div>Date de réalisation: _ _/_ _/_ _ _ _</div>
        </div>
      </div>
    </p>
    <p>Réalisé par:&nbsp;&nbsp;{ repeat("_ ", 41)}</p>
    <p>Commentaires:&nbsp;&nbsp;&nbsp;{ repeat("_ ", 87)}</p>
  </div>
);

const issuesQueryToAssignmentsQuery = (query) => {
  if(!(query instanceof Object)){
    return query;
  }
  if(query instanceof Array){
    return query.map(q => issuesQueryToAssignmentsQuery(q));
  }
  const newQuery = {};
  for(var key in query){
    let newKey = key;
    if(newKey.includes("assignments")){
      newKey = newKey.replace("assignments", "assignment");
    }
    newQuery[newKey] = issuesQueryToAssignmentsQuery(query[key]);
  }
  return newQuery;
}

export default Application.Service.forward(["api", "i18n"], props => {
  const oneByPage           = (props.oneByPage && props.oneByPage === "true");
  const withPageBreak       = oneByPage;
  const withMap             = oneByPage
  const withDeadline        = oneByPage;
  const withManagerLabel    = oneByPage;
  const withIllustration    = !oneByPage;
  const withAllPictures     = oneByPage;
  const withPdf             = props.pdf === "true";

  const query               = props.query ? issuesQueryToAssignmentsQuery(JSON.parse(props.query)) : {};
  const sort                = props.sort  ? JSON.parse(props.sort)  : { createdAt: -1 };
  const limit               = props.limit ? parseInt(props.limit) : 50;

  const book                = React.createRef();



  const title = `BS_${moment().format("YYYYMMDD")}_${props.i18n.translate(typeof query.issue === "string" 
    ? "print_assignment_title" 
    : oneByPage 
      ? "print_assignments_title_one_per_page"
      : "print_assignments_title_several_per_page"
  )}`;
  return React.createElement(Book, 
    { 
      breakBetweenArticle: withPageBreak,
      ref: book, 
      title
    },
    React.createElement(Data.List, {
      model: "Assignment",
      query: query,
      sort: sort,
      pageSize: limit,
      load: {
        files: true,
        category: true,
        manager: true,
        "location.building" : true,
        "assignment.agents": true,
        "assignment.team": true,
        equipment: true,
        "equipment.location.building": true,
        "assignment.necessariesEquipments": true,
        "assignment.necessariesSupplies.supply": true,
        createdBy: true
      },
      onLoaded: () => {
        props.api.service("logs", "assignmentsPrint").execute(query, sort, limit);
        book.current.print();
      }
    }, (issue, idx) => {
      const file = issue.files.find(file => {
        return file.mimeType.startsWith("image") && file.storageId;
      });

      const content = (
        <>
          <div className="bs-print-issue">
            <BlockDescription
              withIllustration={ withIllustration }
              illustration={ file }
              issue={ issue }
              withManagerLabel={ withManagerLabel }
              withMap={ withMap }
              withDeadline={ withDeadline }
            />
            <BlockWorkOrderReturn />
          </div>
          <Display.If condition={issue.files.length && withAllPictures}>
            <BlockPictures files={ issue.files } />
          </Display.If>
        </>
      )

      return (
        <>
          <Book.Article>
            <Display.If condition={ withPageBreak }>
              <Display.Then>
                <div className="bs-print-issue-container">
                  { content }
                </div>
              </Display.Then>
              <Display.Else> 
                { content }
              </Display.Else>
            </Display.If>
          </Book.Article>
          <BlockPdf withPdf={ withPdf } files={ issue.files } />
        </>
      );
    })
  );
});