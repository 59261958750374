import React from "react";

/**
 * @deprecated ne plus utiliser, à remplacer par @cComponents/field
 */
const Field = (props) => (
  <div className="bs-field-wa">
    <div className="bs-field-wa-label">{ props.label }</div>
    <div className="bs-field-wa-input">{ props.children }</div>
  </div>
)

export default Field;