// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-flow-animate{
  height: 100%;
  position: relative;
}
.bs-flow-animate-container{
  height: 100%;
  width: 100%;
}
.flow-slide-enter {
  position: absolute;
  top: 0px;
  transform: translateX(100%);
}
.flow-slide-enter-active {
  position: absolute;
  top: 0px;
  transform: translateX(0%);
  transition: transform 1000ms  ease-in;
}
.flow-slide-exit-active {
  transform: translateX(-100%);
  transition: transform 1000ms  ease-in;
}
.flow-slide-exit-done{
  display: none;
}`, "",{"version":3,"sources":["webpack://./../common/components/flow.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,2BAA2B;AAC7B;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,yBAAyB;EACzB,qCAAqC;AACvC;AACA;EACE,4BAA4B;EAC5B,qCAAqC;AACvC;AACA;EACE,aAAa;AACf","sourcesContent":[".bs-flow-animate{\n  height: 100%;\n  position: relative;\n}\n.bs-flow-animate-container{\n  height: 100%;\n  width: 100%;\n}\n.flow-slide-enter {\n  position: absolute;\n  top: 0px;\n  transform: translateX(100%);\n}\n.flow-slide-enter-active {\n  position: absolute;\n  top: 0px;\n  transform: translateX(0%);\n  transition: transform 1000ms  ease-in;\n}\n.flow-slide-exit-active {\n  transform: translateX(-100%);\n  transition: transform 1000ms  ease-in;\n}\n.flow-slide-exit-done{\n  display: none;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
