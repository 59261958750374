import React, { ComponentType, FunctionComponent, PropsWithChildren } from 'react';
import Mapper from '../types/technic/Mapper';
import OmitOrNot from '../types/technic/OmitOrNot';
import map from '../lib/map';


interface WithIfElseProps {
  condition: boolean;
}

type WithIfElseComponentProps<P1, P2, Mapped> = P1 & OmitOrNot<P2, Mapped> & WithIfElseProps;

type WithIfElseComponent<P1, P2, Mapped> = FunctionComponent<PropsWithChildren<WithIfElseComponentProps<P1, P2, Mapped>>>;

function withIfElse <P1, P2, Mapped extends Mapper<P2, P1>>(ComponentThen: ComponentType<P1>, ComponentElse: ComponentType<P2> | null, mapper?: Mapped): WithIfElseComponent<P1, P2, Mapped> {
  return ({ condition, children, ...props }) => {
    if(condition) {
      return (<ComponentThen { ...(props as P1) }>{ children }</ComponentThen>);
    }
    if(ComponentElse){
      const props2 = mapper ? map(mapper, props as P1 & OmitOrNot<P2, Mapped>) : props;
      return (<ComponentElse { ...(props2 as P2) }>{ children }</ComponentElse>);
    }
    return null;
  };
};


type Callback<P1, P2, Mapped> = (props: PropsWithChildren<P1 & OmitOrNot<P2, Mapped>>)  => boolean;

type WithIfElseCallbackComponent<P1, P2, Mapped> = FunctionComponent<PropsWithChildren<P1 & OmitOrNot<P2, Mapped>>>;

export const withIfElseCallback = <P1, P2, Mapped extends Mapper<P2, P1>>(ComponentThen: ComponentType<P1>, ComponentElse: ComponentType<P2> | null, callback: Callback<P1, P2, Mapped>, mapper?: Mapped): WithIfElseCallbackComponent<P1, P2, Mapped> => {
  const Component = withIfElse(ComponentThen, ComponentElse, mapper);
  return (props) => (
    <Component { ...props } condition={ callback(props) } />
  );
}

export default withIfElse;
