import React        from "react";
import Application  from "@uBehaviour/application";

import "./tooltip.css";

class Tooltip extends React.Component {
  render(){
    return (
      <Application.Listen services={[ this.props.tooltip ]}>
      {() => {
        const tooltips = this.props.tooltip.get();
        return tooltips.map(tooltip => {
          let el = tooltip.relativeTo;
          while(!el.getBoundingClientRect) el = el.parentElement;
          const rect = el.getBoundingClientRect();
          const position = { left: rect.left };
          if(tooltip.position === "top"){
            position.bottom = window.innerHeight - rect.top;
          }
          if(tooltip.position === "bottom"){
            position.top = rect.bottom;
          }
          return (
            <div className="bs-tooltip" style={position}>
              { tooltip.component }
            </div>
          );
        });
      }}
      </Application.Listen>
    )
  }
}

export default Application.forward(["tooltip"], [], Tooltip);