import React, { FunctionComponent } from 'react';
import T from '@universal/behaviour/i18n';
import Action from "@cComponents/action";
import Nudge from "@cComponents/nudge";
import _ from 'lodash';
import UserType from '@uTypes/business/User';
import useHelper from '@universal/behaviour/hooks/useHelper';
import UserHelper from '@universal/helpers/users';
import ObjectId from '@universal/types/technic/ObjectId';
import CalendarHelper from '@universal/helpers/calendar';

type InviteProps = {
  user: UserType
}
const Invite: FunctionComponent<InviteProps> = ({ user }) => {
  const usersHelper = useHelper<UserHelper>("users");
  const sendInvitation = React.useCallback(_.throttle(() => usersHelper.sendInvitation(user), 5000), [ usersHelper, user ]);
  return (
    <Action
      id="invite_user"
      icon="envelope-o"
      onClick={ sendInvitation }
    >
      <Action.Tooltip>
        <div className="bs-user-item-action-tooltip-container">
          <Nudge>
            <T>invite</T>
          </Nudge>
        </div>
      </Action.Tooltip>
    </Action>
  );
}

type ResetPasswordProps = {
  onClick: () => void
}
const ResetPassword: FunctionComponent<ResetPasswordProps> = ({ onClick }) => {
  return (
    <Action
      id="reset_password"
      icon="repeat"
      onClick={ onClick }
    >
      <Action.Tooltip>
        <div className="bs-user-item-action-tooltip-container">
          <Nudge>
            <T>reset_password</T>
          </Nudge>
        </div>
      </Action.Tooltip>
    </Action>
  );
}

type ResetPinProps = {
  userId: ObjectId
}
const ResetPin: FunctionComponent<ResetPinProps> = ({ userId }) => {
  const usersHelper = useHelper<UserHelper>("users");
  const resetPin = React.useCallback(() => usersHelper.resetPin(userId), [ usersHelper, userId ]);
  return (
    <Action
      id="reset_pin"
      icon="refresh"
      onClick={ resetPin }
    >
      <Action.Tooltip>
        <div className="bs-user-item-action-tooltip-container">
          <Nudge>
            <T>reset_pin</T>
          </Nudge>
        </div>
      </Action.Tooltip>
    </Action>
  );
}

type CopyProps = {
  onClick: () => void
}
const Copy: FunctionComponent<CopyProps> = ({ onClick }) => {
  return (
    <Action 
      id="user_item_copy"
      icon="file-o"
      onClick={ onClick }
    >
      <Action.Tooltip>
        <div className="bs-user-item-action-tooltip-container">
          <Nudge>
            <T>user_item_copy</T>
          </Nudge>
        </div>
      </Action.Tooltip>
    </Action>
  );
}

type CopyCalendarLinkProps = {
  user: UserType
}
const CopyCalendarLink: FunctionComponent<CopyCalendarLinkProps> = ({ user }) => {
  const calendarHelper = useHelper<CalendarHelper>("calendar");
  const copyCalendarLink = React.useCallback(() => {
    calendarHelper.copyUserCalendarLink(user);
  }, [ user, calendarHelper ])  

  return (
    <Action
      id="copy_user_calendar_link"
      icon="copy"
      onClick={ copyCalendarLink }>
        <Action.Tooltip>
          <div className="bs-user-item-action-tooltip-container">
            <Nudge>
              <T>copy_user_calendar_link</T>
            </Nudge>
          </div>
        </Action.Tooltip>
    </Action>
  );
}

const Actions = {
  Invite,
  ResetPassword,
  ResetPin,
  Copy,
  CopyCalendarLink
}

export default Actions;