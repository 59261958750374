import { fork, put, takeEvery } from 'redux-saga/effects'
import * as ActionTypes from 'client/features/notifications/actions'
import URLS from 'constants/URLS'
import RequestSagas from 'client/core/RequestSagas'

/*Fetch notifications*/
export const getNotificationsSagas = new RequestSagas(ActionTypes.NotificationsRequest.get, { method: 'GET', endpoint: URLS.notifications.get })
getNotificationsSagas.success = function* () {

}
getNotificationsSagas.failure = function* () {

}

/*Put notificaitons*/
export const putNotificationsSagas = new RequestSagas(ActionTypes.NotificationsRequest.put, { method: 'PUT', endpoint: URLS.notifications.putMany })
putNotificationsSagas.success = function* () {

}
putNotificationsSagas.failure = function* () {

}

/*Fetch notification*/
export const getNotificationSagas = new RequestSagas(ActionTypes.NotificationRequest.get, { method: 'GET', endpoint: URLS.notifications.getOne })
getNotificationSagas.success = function* () {
}
getNotificationSagas.failure = function* () {

}

/*Put notificaiton*/
export const putNotificationSagas = new RequestSagas(ActionTypes.NotificationRequest.put, { method: 'PUT', endpoint: URLS.notifications.put })
putNotificationSagas.success = function* () {

}
putNotificationSagas.failure = function* () {

}

/*Check*/

const callNotification = function * (action) {
  yield put(ActionTypes.NotificationRequest.get.action({ params: { id: action.payload.notification } }))
}

export const checkNotification = function * () {
  yield takeEvery(ActionTypes.CHECK_NOTIFICATION, callNotification)
}

const sagas = [
  fork(getNotificationSagas.saga),
  fork(getNotificationsSagas.saga),
  fork(putNotificationsSagas.saga),
  fork(putNotificationSagas.saga),
  fork(checkNotification)
]

export default sagas