const URLS = {
  buildings: {
    show: '/**/building/:id',
    index: '/console/buildings',
    new: '/**/building/new'
  },
  patrimonies: {
    show: '/**/patrimony/:id',
    index: '/console/patrimonies',
    new: '/**/patrimony/new'
  },
  supplies: {
    show: '/**/supply/:id',
    index: '/console/supplies',
    post: '/supplies',
    put: '/supplies/:id',
    get: '/supplies',
    new: '/**/supply/new'
  },
  tags: {
    show: '/**/tag/:id',
    index: '/console/tags',
    new: '/**/tag/new',
    indexPublicSpace: '/console/tags/publicSpace',
    indexBuilding: '/console/tags/building',
    indexPatrimony: '/console/tags/patrimony',
  },
  teams: {
    show: '/**/team/:id',
    index: '/console/teams',
    new: '/**/team/new'
  },
  contacts: {
    show: '/**/contact/:id',
    index: '/console/contacts',
    new: '/**/contact/new'
  },
  tenants: {
    show: '/**/tenants/:id',
    new: '/**/tenants/new',
    options: '/console/tenants/:id/options',
    infos: '/console/tenants/:id/infos',
    emails: '/console/tenants/:id/emails',
    workorders: '/console/tenants/:id/workorders',
    valorization: '/console/tenants/:id/valorization'
  },
  userPros: {
    show: '/**/user-pro/:id',
    item: '/user-pros/:id',
    itemAction: '/user-pros/:id/:action',
    index: '/console/user-pros'
  },
  home: {
    index: '/',
    show: '/home',
    weather: '/weather',
    activities: '/home/activities'
  },
  session: {
    login: '/login',
    logout: '/logout',
    apiLogout: '/logout',
    isAuth: '/isAuth',
    magicLogin: '/magicLogin',
    magicAuth: '/auth/magic',
    updateCurrentUser: '/me',
    updatePassword: '/password/update',
    resetPassword: '/resetPassword',
    setPassword: '/setPassword',
    requestPassword: '/password/forgot',
    language: '/language/:lang',
    whoIsOnline: '/whoIsOnline',
    auth: '/auth',
    data: '/config',
    me: '/me'
  },
  users: {
    new: '/users',
    get: '/users',
    put: '/users/:id/:type'
  },
  issues: {
    index: '/issues',
    new: '/newissue',
    create: '/issues',
    show: '/**/issue/:id',
    nears: '/issues/nears',
    get: '/issues',
    getOne: '/issues/:id',
    list: '/issues/list',
    light: '/issues/list-light',
    map: '/issues/map',
    put: '/issues/:id/:type',
    saveFilters: '/issuesFilters',
    updateFilters: '/issuesFilters/:id',
    autocomplete: '/issues/autocomplete',
    recurrencePreview: '/recurrence',
    starterShow: '/starter/issues/:id/:accessToken',
    getOneStarter: '/starter/issues/:id/:accessToken',
    exportCSV: '/issues/export/csv',
    messenger: '/issues/live/messenger/:id'
  },
  recurrence: {
    delete: '/recurrence/:id',
    update: '/recurrence/:id'
  },
  calendar: {
    show: '/calendar',
    issues: '/calendar/issues'
  },
  statistics: {
    index: '/statistics',
    get: '/statistics'
  },
  files: {
    fileStorage: '/files/fileStorage',
    all: '/files/:fileName'
  },
  console: {
    index: '/console'
  },
  settings: {
    index: '/settings'
  },
  dashboard: {
    index: '/dashboard'
  },
  logs: {
    index: '/logs'
  },
  notifications: {
    get: '/notifications',
    getOne: '/notifications/:id',
    put: '/notifications/:id/:type',
    putMany: '/notifications/:type'
  }
}

export default URLS