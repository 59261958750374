import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import _ from 'lodash'

import { getIssueFromAll, goToIssue, printIssueWorkOrder } from 'client/features/issues/lib'

import PrintModal from 'client/features/issues/components/modals/PrintModal.jsx'
const __CLIENT = window.__CLIENT;

const IssueWrapper = (WrappedComponent) => {
    class IssueHOC extends React.PureComponent {
        constructor(props) {
            super(props)
            this.goToIssue = this.goToIssue.bind(this)
            this.state = {
                print: null
            }
        }

        goToIssue(issue) {
            this.setState({
                showMapModal: false,
                showRecurrenceModal: false
            })
            goToIssue(issue, this.props.dispatch, this.props.user)
        }

        closePdfModal = () => {
          this.setState({
            print: null
          })
        }

        asPdf = () => this.props.issue?.pdfs && this.props.issue?.pdfs[0]; 

        printWorkOrder = (withPdf, oneByPage) => {
          if (this.state.print) {
            this.closePdfModal();
          }            
          printIssueWorkOrder(this.props.issue, withPdf, oneByPage);
        }

        printWorkOrderMulti = () => {
          this.printWorkOrder(false, false);
        }

        printWorkOrderMultiWithPdf = () => {
          this.setState({
            print: (withPdf) => {
              this.printWorkOrder(withPdf, false);
            }
          });
        }

        printWorkOrderOne = () => {
          this.printWorkOrder(true, false);
        }

        printWorkOrderOneWithPdf = () => {
          this.setState({
            print: (withPdf) => {
              this.printWorkOrder(withPdf, true);
            }
          })
        }

        render() {
          const printWorkOrderMulti = this.asPdf() ? this.printWorkOrderMultiWithPdf : this.printWorkOrderMulti;
          const printWorkOrderOne = this.asPdf() ? this.printWorkOrderOneWithPdf : this.printWorkOrderOne;

          return (
              <>
                  <WrappedComponent
                      goToIssue={ this.goToIssue }
                      printWorkOrderMulti={ printWorkOrderMulti }
                      printWorkOrderOne={ printWorkOrderOne }
                      { ...this.props }
                  />
                  <PrintModal
                      onHide={ () => this.closePdfModal() }
                      show={ this.state.print }
                      print={this.state.print}
                  />
              </>
          )
        }
    }

    return IssueHOC
}

const mapStateToProps = (state, props) => {
    const { issues, session } = state
    let issue = props.issue || getIssueFromAll(issues.all, issues.current)
    const currentNear = issues.currentNear && getIssueFromAll(issues.all, issues.currentNear)
    if(issues.lastAssignment && issue?.assignments){
      issue = JSON.parse(JSON.stringify(issue));
      issue.assignments = issue.assignments.filter(a => a._id === issues.lastAssignment);
    }
    return {
        assignmentId: issues.lastAssignment,
        issues,
        issue,
        issueId: props.issue ? props.issue._id : issues.current,
        session,
        user: session.user,
        confirmations: issue ? issues.confirmations[issue._id] || {} : {},
        currentNear
    }
}

export default compose(connect(mapStateToProps), IssueWrapper)