// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-clickable{

}
.bs-hoverable{
  
}
.bs-listable{
  border-right: solid 1px#cecbc8;
  border-left:  solid 1px#cecbc8;
}
.bs-listable>.bs-listable-item{
  border-top: solid 1px#cecbc8;
  padding: 12px;
}
.bs-listable>.bs-listable-item:last-child{
  border-bottom: solid 1px#cecbc8;
}`, "",{"version":3,"sources":["webpack://./../common/components/decorator.css"],"names":[],"mappings":"AAAA;;AAEA;AACA;;AAEA;AACA;EACE,8BAA8B;EAC9B,8BAA8B;AAChC;AACA;EACE,4BAA4B;EAC5B,aAAa;AACf;AACA;EACE,+BAA+B;AACjC","sourcesContent":[".bs-clickable{\n\n}\n.bs-hoverable{\n  \n}\n.bs-listable{\n  border-right: solid 1px#cecbc8;\n  border-left:  solid 1px#cecbc8;\n}\n.bs-listable>.bs-listable-item{\n  border-top: solid 1px#cecbc8;\n  padding: 12px;\n}\n.bs-listable>.bs-listable-item:last-child{\n  border-bottom: solid 1px#cecbc8;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
