// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-genially-iframe { 
  width: 100%;
  height: 100%;
  border: none 
}
`, "",{"version":3,"sources":["webpack://./../common/components/genially.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ;AACF","sourcesContent":[".bs-genially-iframe { \n  width: 100%;\n  height: 100%;\n  border: none \n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
