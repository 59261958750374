import Equipment from "@uTypes/business/Equipment";
import { FunctionComponent } from "react";
import Subject from "./subject";
import Data from "@universal/behaviour/data";

interface EquipmentSubjectProps {
  equipment: Equipment;
  del?: null | (() => void);
};
const EquipmentSubject: FunctionComponent<EquipmentSubjectProps> = ({ equipment, del = null }) => (
  <Data.Synchronizer entity={ equipment }>
  {(equipment) => (
    <Subject icon="wrench" del={ del }>{ equipment.name }</Subject>
  )}
  </Data.Synchronizer>
);

export default EquipmentSubject;