import moment        from "moment";
import { Helper }    from "@uLib/application";
import UrlBuilder    from "@uLib/urlBuilder";
import StringBuilder from "@uLib/stringBuilder";
import Query         from '@uLib/query';
import { deepReplace } from '@uLib/objectTool';

export default class PrintHelper extends Helper {
  constructor(){
    super("print", ["api", "currentTenant"]);
    this._windowParams = StringBuilder.create(",")
                                      .add("scrollbars=1")
                                      .add("directories=no")
                                      .add("titlebar=no")
                                      .add("toolbar=no")
                                      .add("location=no")
                                      .add("status=no")
                                      .add("menubar=no")
                                      .add("resizable=yes")
                                      .add("width=1024")
                                      .add("height=800")
                                      .build()
  }

  calendar = (start, end, type, teams = [], issueTypes, states, compress) => {
    const query = { $and: [
      { "assignment.scheduledFrom": { $lt: moment(end).toISOString() }},
      { "assignment.scheduledTo":   { $gt: moment(start).toISOString() }}
    ]};
    if(issueTypes && issueTypes.length && issueTypes.length !== 3){
      const $or = [];
      if(issueTypes.includes("public_space")){
        $or.push({
          "location.building": null,
          equipment: null
        });
      }
      if(issueTypes.includes("building")){
        $or.push({
          "location.building": { $ne: null }
        });
      }
      if(issueTypes.includes("equipment")){
        $or.push({
          equipment: { $ne: null }
        });
      }
      if($or.length === 1){
        query.$and.push($or[0]);
      }else{
        query.$and.push({ $or: $or });
      }
    }
    if(states && states.length === 1){
      query.$and.push({ state: states[0] });
    }else{
      query.$and.push({ state: { $in: ["planned", "resolved"]}});
    }
    let p = Promise.resolve();
    if(teams && teams.length){
      p = this.application.getService("api").service("users", "get").execute({ team: { $in: teams.map(t => t._id ) }}).then(users => {
        query.$and.push({ $or: [
          { "assignment.agents":  { $in: users.map(user => user._id) }},
          { "assignment.team":    { $in: teams.map(team => team._id) }}
        ]});
      });
    }
    return p.then(() => {
      const builder = UrlBuilder.create("/print/calendar/")
                                .addPath(type === "agent" ? "agents" : "teams")
                                .addParam("start", moment(start).toISOString())
                                .addParam("end", moment(end).toISOString())
                                .addParam("query", JSON.stringify(query))
                                .addParam("compress", compress ? "true" : "false");

      return this.stdPrint(builder.build());
    });
  }

  calendarWorkOrder = (start, end, type, teams) => {
    const query = { $and: [
      { "assignment.scheduledFrom": { $lt: moment(end).toISOString() }},
      { "assignment.scheduledTo":   { $gt: moment(start).toISOString() }}
    ]};
    let p = Promise.resolve();
    if(teams && teams.length){
      p = this.application.getService("api").service("users", "get").execute({ team: { $in: teams }}).then(users => {
        query.$and.push({ $or: [
          { "assignment.agents":  { $in: users.map(user => user._id) }},
          { "assignment.team":    { $in: teams }}
        ]});
      });
    }
    return p.then(() => {
      return this.workOrder(query, { "assignment.team": 1, "assignment.agent": 1, "assignment.scheduledFrom": 1, "assignment.scheduledTo": 1 }, type === "one", 500);
    });
  }

  workOrder = (query, sort, oneByPage, limit = 50, pdf = false) => {
    query = deepReplace(query, "assignments", "assignment");
    const builder = UrlBuilder.create("/print/assignments")
                              .addParam("query", JSON.stringify(Query.joinWithOptimizer(query, { tenant: this.application.getService("currentTenant").currentId })))
                              .addParam("sort", JSON.stringify(sort))
                              .addParam("oneByPage", oneByPage ? "true" : "false")
                              .addParam("pdf", pdf ? "true" : "false")
                              .addParam("limit", limit);

    return this.stdPrint(builder.build());
  } 

  stdPrint(url){
    window.open(url, 'EditEvents', this._windowParams);
  }
}