import { Statement } from "@universal/types/business/Valorization";
import { FunctionComponent, PropsWithChildren } from "react";
import { Grid, SemanticWIDTHS } from "semantic-ui-react";
import Slot from "@universal/components/slot2";
import Money from "@cComponents/money";
import useService from "@universal/behaviour/hooks/useService";
import AclService from "@universal/services/acl";
import Display from "@uComponents/displayIf";

const Complement = Slot();

const plus3 = (value, plus3) => plus3 ? value + 3 : value;

interface TitleStatementsProps {
  displayCostResource: string;
  displayCostAction: string;
  statements: Array<Statement>;
};
const TitleStatements: FunctionComponent<PropsWithChildren<TitleStatementsProps>> & { Complement }= ({ statements, displayCostResource, displayCostAction, children }) => {
  const complement = Complement.get(children);
  const title = Complement.not(children);
  const acl = useService<AclService>('acl');
  const displayCost = acl.connectedUserIsAllow(displayCostResource, displayCostAction);
  return (
    <Grid.Row>
      <Grid.Column width={ ((complement ? 7 : plus3(13, !displayCost))) as SemanticWIDTHS }><b>{ title }</b></Grid.Column>
      {
        complement
          ? <><Grid.Column width={ plus3(3, !displayCost) }>{ complement }</Grid.Column><Grid.Column width={ 3 } /></>
          : null
      }
      <Display.If condition={ displayCost }>
        <Grid.Column width="3" textAlign='center'><b><Money value={statements.reduce<number>((total, statement) => total + statement.cost, 0) } /></b></Grid.Column>
      </Display.If>
    </Grid.Row>
  );
};

TitleStatements.Complement = Complement;

export default TitleStatements;