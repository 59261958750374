import React, { FunctionComponent, ReactNode }              from "react";
import T                  from "@cBehaviour/i18n";
import Button             from "@cComponents/button";
import DropDown           from "@cComponents/dropDown";
import Modal              from "@cComponents/modal";
import Avatar             from "@cFeatures/user/avatar";

import useService         from "@uBehaviour/hooks/useService";
import SessionService     from "@uServices/session";
import Text from "@common/components/text";
import useLoginAs from "@hooks/useLoginAs";
import useModel from "@universal/behaviour/data/hooks/useModel";
import { BusinessEntity, Loader } from "@universal/types/technic/Entityable";
import User from "@universal/types/business/User";
import CommunicationKit from '@features/communicationKit';
import useOpenCloseToggle from "@universal/behaviour/hooks/useOpenCloseToggle";
import UnsubscribeWizard, {
  StepValidation,
  StepReason,
  StepConfirmation
} from "@root/applications/unsubscribe";
import Flow from "@universal/components/flow";

const Logout: FunctionComponent = () => {
  const session = useService<SessionService>("session");
  const logout = React.useCallback(() => {
    session.logout();
  }, [session]);
  return (
    <Button.Display onClick={ logout }>
      <Text><T>starter_f_session_userConnected_logout</T></Text>
    </Button.Display>
  );
}

const Unsubscribe: FunctionComponent = () => {
  const session = useService<SessionService>("session");
  const logout = React.useCallback(() => {
    session.logout();
  }, [session]);
  const [displayUnsubscribeModal, openUnsubscribeModal, closeUnsubscribeModal] = useOpenCloseToggle();
  return (
    <>
      <Button.Display onClick={ openUnsubscribeModal }>
        <Text><T>starter_f_session_userConnected_unsubscribe</T></Text>
      </Button.Display>
      { displayUnsubscribeModal && (
        <Modal.Show close={ closeUnsubscribeModal } style={{ width: "60vw", height: "80vh"  }} userCanClose={ false }>
          <UnsubscribeWizard>
            <Flow>
              <Flow.Step>
                {(context, flow) => (
                  <StepValidation next={ () => flow.next() } cancel={ closeUnsubscribeModal } />
                )}
                </Flow.Step>
                <Flow.Step>
                {(context, flow) => (
                  <StepReason next={ () => flow.next() } cancel={ closeUnsubscribeModal } />
                )}
                </Flow.Step>
                <Flow.Step>
                {(context, flow) => (
                  <StepConfirmation confirm={ logout }/>
                )}
                </Flow.Step>
            </Flow>
          </UnsubscribeWizard>
        </Modal.Show>
      )}
    </>
  );
}

const ButtonSubscription = Modal.withUrlRequestButton(Button.Display);
const Notification: FunctionComponent = () => {
  return (
    <ButtonSubscription displayModalValue="notificationSettings">
      <Text><T>starter_f_session_userConnected_notificationSettings</T></Text>
    </ButtonSubscription>
  );
}

const DisplayAvatar: FunctionComponent = () => {
  const session = useService<SessionService>("session", ["onServiceUpdated"]);
  const [ user, setUser ] = React.useState<BusinessEntity<User,{ avatar: Loader; } > | null>(null);
  const model = useModel("User");

  React.useEffect(() => {
    if (!session.isLogged()) {
      return;
    }
    model.repository.findOne(session.user._id, { avatar: true })
    .then((user: BusinessEntity<User, { avatar: Loader; }>) => setUser(user));
  }, [ model, session, session.isLogged() && session.user._id ]);

  if(!user){
    return null;
  }
  return (
    <Avatar user={ user } />
  );
}

const UserConnected: React.FunctionComponent = () => {
  const loginAs = useLoginAs();

  const nodes: ReactNode[] = React.useMemo(() => {
    return [<Notification />, <CommunicationKit />, <Unsubscribe />, <Logout />, ...loginAs];
  }, [loginAs])
  
  return (
    <DropDown.List alignRight={true}>
      <DropDown.List.Main>
        <DisplayAvatar />
      </DropDown.List.Main>
      <DropDown.List.List>
      { 
        nodes.map((node, index) => (
          <div key={ index }>
          { node }
          </div>
        ))
      }
      </DropDown.List.List>
    </DropDown.List>
  );
}

export default UserConnected;