import React from 'react'
import classNames from 'classnames'

const Loader = ({ small, className }) => (
    <div>
        <div className={ classNames('aligned bs-loader', className) }>
            <div className={ small ? 'loader-points small' : 'loader-points' }>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
)

export default Loader