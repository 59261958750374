import React        from 'react';
import Filter       from '@cComponents/filter';
import T            from '@uBehaviour/i18n';
import Collapsable  from "@cComponents/collapsable";
import Input        from "@cComponents/input";

const CreatorType = () => (
  <Filter.Generic
    multiple
    name="creatorTypes"
    buildQuery={values => ({ "requestor.type": { $in: values.reduce((acc, v) => acc.concat(v === "internal" ? ["citizen", "internal", "userPro"] : ["bsCitizen"]), []) }})}
    stringify={value => (<><T>issue_filter_creatorType_label</T> : <T>{"issue_filter_creatorType_" + value}</T></>)}
  >
    {(values, add, drop, clear) => (
      <Collapsable>
        <Collapsable.Title><T>issue_filter_creatorType</T></Collapsable.Title>
        <Collapsable.Content>
          <Input.Checkbox.BtnFilter onChange={(values, value, check) => { if (check) add(value); else drop(value); }} value={values}>
            <Input.Checkbox.Value value="internal">
              <span><T>issue_filter_creatorType_internal</T></span>
            </Input.Checkbox.Value>
            <Input.Checkbox.Value value="citizen">
              <span><T>issue_filter_creatorType_citizen</T></span>
            </Input.Checkbox.Value>
          </Input.Checkbox.BtnFilter>
        </Collapsable.Content>
      </Collapsable>
    )}
  </Filter.Generic>
);

export default CreatorType;