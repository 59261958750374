import Building   from "./building";
import Category   from "./category";
import Equipment  from "./equipment";
import NewIssue   from "./newIssue";
import Planning   from "./planning";
import Statistics from "./statistics";
import Team       from "./team";

export {
  Building,
  Category,
  Equipment,
  NewIssue,
  Planning,
  Statistics,
  Team
};

const FeaturesDiscovery = {
  Building,
  Category,
  Equipment,
  NewIssue,
  Planning,
  Statistics,
  Team
};

export default FeaturesDiscovery;
