import React, { FunctionComponent } from "react";
import Text, { Style } from "@cComponents/text";
import T from "@uBehaviour/i18n";

import "./congratulations.css";

const Congratulations: FunctionComponent = () => (
  <div className="bs-pp-congratulations-container">
    <span className="bs-pp-congratulations-separator-pp">&nbsp;</span>
    <div className="bs-pp-congratulations-header">
      <img src={`/images/logos/panneaupocket_color_text_horizontal.svg`} className="bs-pp-congratulations-pp-logo" alt="Logo PanneauPocket" />
      <span className="bs-pp-congratulations-ampersand"> & </span>
      <img src={`/images/logos/betterstreet_color_text_vertical.svg`}    className="bs-pp-congratulations-bs-logo" alt="Logo BetterStreet" />
    </div>
    <span className="bs-pp-congratulations-separator-bs">&nbsp;</span>

    <div className="bs-pp-congratulations-content">
      <Text.Paragraph style={ Style.bold.huge.black }>
        <T>pp_congratulations_title</T>   
      </Text.Paragraph>
      <Text.Paragraph style={ Style.large.black }>
        <T>pp_congratulations_content</T>
      </Text.Paragraph>
    </div>
  </div>
);

export default Congratulations;
