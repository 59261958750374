import MapComponent from "@cComponents/map2";
import Issue        from "@uTypes/business/Issue";

type StarterMapProps = {
  issue: Issue;
};

const Map: React.FunctionComponent<StarterMapProps> = ({ issue }) => (
  <MapComponent position={ issue.location.position.toGeoJSON() } zoom={ 16 }>
    <MapComponent.GeoJSON 
      position={ issue.location.position.toGeoJSON() }
      key={ issue._id }
      className={ `fa fa-map-marker bs-issue-mapMarker bs-${ issue.state }`}
    />
  </MapComponent>
);

export default Map;
