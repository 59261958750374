import React          from "react";
import Display        from '@cComponents/displayIf';
import Input          from '@cComponents/old/input';


import './selectCurrentTenant.css';
import useService from "@universal/behaviour/hooks/useService";
import useHelper from "@universal/behaviour/hooks/useHelper";

export default {
  Unlimited: ({}) => {
    const currentTenant = useService('currentTenant');
    const i18n = useService('i18n');
    const tenantHelper = useHelper('tenant');

    return (
      <div className="bs-selectTenant">
        <Display.If condition={currentTenant.isSelected()}>
          <Display.Then>
            <div className="bs-selectedTenant">
                <span>{ tenantHelper.currentTenantLabel }</span>
                <span className="bs-close fa fa-close" onClick={()=>{ currentTenant.currentId = null }} />
            </div>
          </Display.Then>
          <Display.Else>
            <div className="bs-selectCurrentTenant-autocomplete">
              <Input.Autocomplete.Model
                onChange={input => { currentTenant.currentId = input.value; }}
                name={i18n.queryProperty("name")}
                model={"Tenant"}
                querying={(value) => ({ [i18n.queryProperty("name")]: { '$regex': value, '$options': 'i' } })}
                labelize={(tenant) => tenantHelper.getTenantLabel(tenant)}
              />
            </div>
          </Display.Else>
        </Display.If>
      </div>
    );
  },
  Limited: ({}) => {
    const currentTenant = useService('currentTenant');
    const i18n = useService('i18n');
    const tenantHelper = useHelper('tenant');
    
    return (
      <div className="bs-selectTenant">
        <Input.Select onChange={(input) => { currentTenant.currentId = input.value; }} value={currentTenant.currentId}>
        {currentTenant.allTenants.sort((t1, t2) => i18n.translate(t1.name).localeCompare(i18n.translate(t2.name))).map(tenant => (
          <Input.Select.Value key={`input_selectCurrentTenant_${tenant._id}`} value={tenant._id} >
            <span>{ tenantHelper.getTenantLabel(tenant) }</span>
          </Input.Select.Value>
        ))}
        </Input.Select>
      </div>
    );
  }
}