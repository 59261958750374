// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../admin/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-paginated-wizard-content {
  display: flex;
}

.bs-paginated-wizard-steps {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  height: 75px;
  gap: 15px;
}

.bs-paginated-wizard-step {
  font-size: 10px;
}

.bs-paginated-wizard-step-selected {
  color: #D94C0B;
  font-size: 10px;
}

.bs-paginated-wizard-navigation-container {
  min-width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bs-paginated-wizard-navigation-container:hover {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./../common/components/wizard/paginated.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,YAAY;EACZ,SAAS;AACX;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".bs-paginated-wizard-content {\n  display: flex;\n}\n\n.bs-paginated-wizard-steps {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 5px;\n  height: 75px;\n  gap: 15px;\n}\n\n.bs-paginated-wizard-step {\n  font-size: 10px;\n}\n\n.bs-paginated-wizard-step-selected {\n  color: #D94C0B;\n  font-size: 10px;\n}\n\n.bs-paginated-wizard-navigation-container {\n  min-width: 60px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.bs-paginated-wizard-navigation-container:hover {\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
