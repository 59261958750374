import React, { FunctionComponent } from "react";
import { Route }        from "@cFeatures/router";
import Assignments      from "@print/assignments";
import useService       from "@uBehaviour/hooks/useService";
import UrlService       from "@cServices/url";

import "./print.css"; 
import "@cAssets/css/cursor-update.css";

const Print: FunctionComponent = () => {
  const url = useService<UrlService>("url");
  const p = url.params;
  p.workOrder = "true";
  
  return (
    <>
      <Route path="/assignments">
        <Assignments { ...p } />
      </Route>
    </>
  );
};

export default Print;