import React  from "react";
import Form           from "@uBehaviour/form";
import Field          from "@cComponents/field";
import Input          from "@cComponents/input";
import T              from "@uBehaviour/i18n";
import Application    from "@uBehaviour/application";
import Tenant         from "@entities/tenant";
import User           from "@entities/user";
import Display        from "@uComponents/displayIf";
import FormError      from "@cComponents/formError";
import FormLayout     from "@components/formLayout";

import "./formMultiStructure.css";

class FormMultiStructure extends React.Component {
  _presubmit(form, value){
    if(value.type !== "publicSpace" && value.public !== undefined){
      delete value.public;
    }

    value.tenantManager   = value.tenantManager[0]._id;
    value.manager         = value.manager[0]._id;
    value.dependantTenant = value.dependantTenant.map(v => v._id);
    
    return value;
  }
  _submit = (form, value) => {
    return this.props.api.service("categories", "createMany").execute(value).then(() => {
      if(this.props.close){
        this.props.close();
      }
    });
  }
  render(){
    const { i18n, repository, currentTenant, acl, forwardedRef } = this.props;
    return (
      <FormLayout>
        <FormLayout.Title><T>category_multiStructure_form_title</T></FormLayout.Title>
        <FormLayout.Form>
          <Form.Simple 
            ref={forwardedRef}
            value={ { tenantManager: [] } }
            presubmit={ this._presubmit }
            submit={ this._submit }
          >
          {(ctx, value, error, form) => {
            const accessibleTenant =  acl.connectedUserIsAllow("application", "administrate") 
            ? { "settings.commercialOffer": { $ne: null }}
            : { _id: { $in: currentTenant.configurableTenants.map(t => t._id )}};
            const tenantMasterQuery = accessibleTenant;
            const userQuery =  value.tenantManager.length
              ? { tenants: { $elemMatch: { tenant: value.tenantManager[0]._id, roles: { $in: ["admin", "manager", "categoryManager"]}}}}
              : null;
            const tenantSlaveQuery = value.tenantManager.length
              ? { $and: [
                accessibleTenant,
                { _id: { $ne: value.tenantManager[0]._id }}
              ]}
              : accessibleTenant;
              
            return (
              <FormError errors={error.global}>
                <div className="bs-category-multiStructure-form">  
                  <Field.ShortLabelContext prefix="category_multiStructure_form">
                    <div>
                      <Field.Short name="label" required><Input.Text /></Field.Short>
                      <Field.Short name="type" required>
                        <Input.Radio.Btn>
                          <Input.Radio.Value value="publicSpace"><T>publicSpace</T></Input.Radio.Value>
                          <Input.Radio.Value value="building"><T>building</T></Input.Radio.Value>
                          <Input.Radio.Value value="equipment"><T>equipment</T></Input.Radio.Value>
                        </Input.Radio.Btn>
                      </Field.Short>
                      <Display.If condition={ value.type === "publicSpace"}>
                        <Field.Short name="citizen" required>
                          <Input.Radio.Btn>
                            <Input.Radio.Value value={ false }><T>no</T></Input.Radio.Value>
                            <Input.Radio.Value value={ true }><T>yes</T></Input.Radio.Value>
                          </Input.Radio.Btn>
                        </Field.Short>
                      </Display.If>
                      <Field.Short name="tenantManager" required>
                        <Input.Selectable 
                          limit={ 1 }
                          model={ repository.get("Tenant") } load={Tenant.Item.Load}
                          query={ tenantMasterQuery }
                          textify={ tenant => <T>{tenant.name}</T> }
                          sort={{ [i18n.queryProperty("name")] : 1}}
                          filterQuery={ value => ({ [i18n.queryProperty("name")]: { '$regex': value, '$options': 'i' } }) }
                        >
                          <Tenant.Item />
                        </Input.Selectable>
                      </Field.Short>
                      <Display.If condition={value.tenantManager && value.tenantManager.length}>
                      {() => (
                        <>
                          <Field.Short name="manager" required>
                            <Input.Selectable 
                              limit={ 1 }
                              model={ repository.get("User") } load={{ avatar: true }}
                              query={ userQuery }
                              textify={ user => user.fullname }
                              sort={{ fullname: 1 }}
                              filterQuery={ value => ({ "fullname": { '$regex': value, '$options': 'i' } }) }
                            >
                              <User.Item />
                            </Input.Selectable>
                          </Field.Short>
                          <Field.Short name="dependantTenant" required>
                            <Input.Selectable 
                              model={ repository.get("Tenant") } load={{}}
                              query={ tenantSlaveQuery }
                              textify={ tenant => <T>{tenant.name}</T> }
                              sort={{ [i18n.queryProperty("name")] : 1}}
                              filterQuery={ value => ({ [i18n.queryProperty("name")]: { '$regex': value, '$options': 'i' } }) }
                            >
                              <Tenant.Item />
                            </Input.Selectable>
                          </Field.Short>
                        </>
                      )}
                      </Display.If>
                    </div>
                  </Field.ShortLabelContext>
                </div>
              </FormError>
            );
          }}
          </Form.Simple> 
        </FormLayout.Form>
      </FormLayout>
    );
  }
}
export default Application.forward(["i18n", "repository", "api", "currentTenant", "acl"], [], FormMultiStructure, "forwardedRef");