// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bs-form-tenant{
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
}

.bs-form-tenant .bs-form-footer {
    background-color: #f5f5f5;
    padding:10px 10px;
    border-top: 1px solid #dddddd;
    display: flex;
    justify-content: flex-end;
  }`, "",{"version":3,"sources":["webpack://./src/views/settings/tenant/formContent.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;AACd;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,6BAA6B;IAC7B,aAAa;IACb,yBAAyB;EAC3B","sourcesContent":[".bs-form-tenant{\n    height: 100%;\n    margin-left: auto;\n    margin-right: auto;\n    width: 60%;\n}\n\n.bs-form-tenant .bs-form-footer {\n    background-color: #f5f5f5;\n    padding:10px 10px;\n    border-top: 1px solid #dddddd;\n    display: flex;\n    justify-content: flex-end;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
